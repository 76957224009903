/* eslint-disable import/prefer-default-export */
import { API_CATEGORIES_PERCENT, API_CATEGORIES_SELLER } from 'constants/urls'
import {
  putCategoriesPercentById,
  postCategoriesPercentByIdSeller,
} from 'fetchAxios/commissions/commissions'

const apicategoriesPercentById = async (category) => {
  const url = `${API_CATEGORIES_PERCENT}/${category.id}`
  const result = await putCategoriesPercentById(url, category)
  return result
}
const apicategoriesPercentByIdSeller = async (category) => {
  const url = `${API_CATEGORIES_SELLER}`
  const result = await postCategoriesPercentByIdSeller(url, category)
  return result
}
export { apicategoriesPercentById, apicategoriesPercentByIdSeller }
