import React, { useEffect, useContext } from 'react'

import ContentAccounStatus from './ContentAccounStatus'
import InContruction from 'components/InConstruction'

//context
import useAuthContext from 'contextApi/AuthContext'

export default function AccountStatus() {
  const { userLoggedin } = useContext(useAuthContext)

  return (
    // La visualización de dicho componente es provisional para los Seller 29 y el 1.
    userLoggedin.sellerId === "29" || userLoggedin.sellerId === "1" || userLoggedin.sellerId === "1572"?
      <ContentAccounStatus /> :
      <InContruction />
  )
}
