/* eslint-disable no-nested-ternary */
/* eslint-disable react/react-in-jsx-scope */
import React from 'react'

export const DESCRIPTION_LENGHT = 10000

export const PRODUCT_TYPES = [
  {
    label: 'Producto Simple',
    description: 'Reloj, Celular, etc.',
    value: 'simple',
    icon: (isActive, isDisabled) => (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="48"
        height="48"
        viewBox="0 0 48 48"
        fill="none"
      >
        <path
          d="M33.4821 6C35.4911 6 37.3393 7.20536 38.1429 9.13393L41.5179 16.6071C41.8393 17.25 42 17.9732 42 18.6964V36.8571C42 39.75 39.6696 42 36.8571 42H11.1429C8.25 42 6 39.75 6 36.8571V18.6964C6 17.9732 6.08036 17.25 6.40179 16.6071L9.77679 9.13393C10.5804 7.20536 12.4286 6 14.4375 6H33.4821ZM33.4821 9.85714H25.9286V16.2857H37.1786L34.6875 10.6607C34.4464 10.1786 33.9643 9.85714 33.4821 9.85714ZM38.1429 20.1429H9.85714V36.8571C9.85714 37.5804 10.4196 38.1429 11.1429 38.1429H36.8571C37.5 38.1429 38.1429 37.5804 38.1429 36.8571V20.1429ZM10.7411 16.2857H22.0714V9.85714H14.4375C13.9554 9.85714 13.4732 10.1786 13.2321 10.6607L10.7411 16.2857Z"
          fill={isActive ? '#6064FF' : isDisabled ? '#E2E2E2' : '#303031'}
        />
      </svg>
    ),
  },
  {
    label: 'Producto Variable',
    description: 'Camisas, Zapatos, etc.',
    value: 'variable',
    icon: (isActive, isDisabled) => (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="48"
        height="48"
        viewBox="0 0 48 48"
        fill="none"
      >
        <path
          d="M33.4821 6C35.4911 6 37.3393 7.20536 38.1429 9.13393L41.5179 16.6071C41.8393 17.25 42 17.9732 42 18.6964V36.8571C42 39.75 39.6696 42 36.8571 42H11.1429C8.25 42 6 39.75 6 36.8571V18.6964C6 17.9732 6.08036 17.25 6.40179 16.6071L9.77679 9.13393C10.5804 7.20536 12.4286 6 14.4375 6H33.4821ZM33.4821 9.85714H25.9286V16.2857H37.1786L34.6875 10.6607C34.4464 10.1786 33.9643 9.85714 33.4821 9.85714ZM38.1429 20.1429H9.85714V36.8571C9.85714 37.5804 10.4196 38.1429 11.1429 38.1429H36.8571C37.5 38.1429 38.1429 37.5804 38.1429 36.8571V20.1429ZM10.7411 16.2857H22.0714V9.85714H14.4375C13.9554 9.85714 13.4732 10.1786 13.2321 10.6607L10.7411 16.2857Z"
          fill={isActive ? '#6064FF' : isDisabled ? '#E2E2E2' : '#303031'}
        />
      </svg>
    ),
  },
  {
    label: 'Producto Virtual',
    description: 'Pines, Ofimatica, etc.',
    value: 'virtual',
    icon: (isActive, isDisabled) => (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="48"
        height="48"
        viewBox="0 0 48 48"
        fill="none"
      >
        <path
          d="M21.9941 6.79179C23.1202 5.73607 24.8798 5.73607 26.0059 6.79179L41.2082 21.9941C42.2639 23.1202 42.2639 24.8798 41.2082 26.0059L26.0059 41.2082C24.8798 42.2639 23.1202 42.2639 21.9941 41.2082L6.79179 26.0059C5.73607 24.8798 5.73607 23.1202 6.79179 21.9941L21.9941 6.79179ZM24.0352 9.60704L9.60704 23.9648L24.0352 38.393L38.393 23.9648L24.0352 9.60704Z"
          fill={isActive ? '#6064FF' : isDisabled ? '#E2E2E2' : '#303031'}
        />
      </svg>
    ),
  },
  {
    label: 'Servicio',
    description: 'Salud, comida, etc.',
    value: 'service',
    icon: (isActive, isDisabled) => (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="48"
        height="48"
        viewBox="0 0 48 48"
        fill="none"
      >
        <path
          d="M6 9.85714C6 7.76786 7.6875 6 9.85714 6H17.5714C19.6607 6 21.4286 7.76786 21.4286 9.85714V17.5714C21.4286 19.7411 19.6607 21.4286 17.5714 21.4286H9.85714C7.6875 21.4286 6 19.7411 6 17.5714V9.85714ZM9.85714 17.5714H17.5714V9.85714H9.85714V17.5714ZM6 30.4286C6 28.3393 7.6875 26.5714 9.85714 26.5714H17.5714C19.6607 26.5714 21.4286 28.3393 21.4286 30.4286V38.1429C21.4286 40.3125 19.6607 42 17.5714 42H9.85714C7.6875 42 6 40.3125 6 38.1429V30.4286ZM9.85714 38.1429H17.5714V30.4286H9.85714V38.1429ZM38.1429 6C40.2321 6 42 7.76786 42 9.85714V17.5714C42 19.7411 40.2321 21.4286 38.1429 21.4286H30.4286C28.2589 21.4286 26.5714 19.7411 26.5714 17.5714V9.85714C26.5714 7.76786 28.2589 6 30.4286 6H38.1429ZM38.1429 9.85714H30.4286V17.5714H38.1429V9.85714ZM26.5714 30.4286C26.5714 28.3393 28.2589 26.5714 30.4286 26.5714H38.1429C40.2321 26.5714 42 28.3393 42 30.4286V38.1429C42 40.3125 40.2321 42 38.1429 42H30.4286C28.2589 42 26.5714 40.3125 26.5714 38.1429V30.4286ZM30.4286 38.1429H38.1429V30.4286H30.4286V38.1429Z"
          fill={isActive ? '#6064FF' : isDisabled ? '#E2E2E2' : '#303031'}
        />
        <path
          d="M34.2694 15.2391L36.4826 13.1888C36.7836 12.9233 36.7853 12.4614 36.4875 12.1938C36.2154 11.934 35.7722 11.9354 35.502 12.198L33.7704 13.7953L33.158 13.228C32.8878 12.965 32.4443 12.9635 32.1721 13.2235C31.8743 13.491 31.876 13.953 32.177 14.2184L33.2787 15.2391C33.5506 15.5036 33.9976 15.5036 34.2694 15.2391Z"
          fill={isActive ? '#6064FF' : isDisabled ? '#E2E2E2' : '#303031'}
        />
      </svg>
    ),
  },
]

export const PRODUCT_CONDITIONS = [
  {
    label: 'Nuevo',
    value: {
      isUsed: false,
      isReconditioned: false,
    },
    icon: (isActive) => (
      <svg
        width="48"
        height="48"
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="Icons/OutLine/NewProduct/Default">
          <g id="Union">
            <path
              d="M29.3672 34.9453C29.8184 35.3965 30.5156 35.3965 30.9668 34.9453L36.2168 29.6953C36.668 29.2441 36.668 28.5469 36.2168 28.0957C35.7656 27.6445 35.0684 27.6445 34.6172 28.0957L30.1875 32.5254L28.3418 30.7207C27.8906 30.2695 27.1934 30.2695 26.7422 30.7207C26.291 31.1719 26.291 31.8691 26.7422 32.3203L29.3672 34.9453Z"
              fill={isActive ? '#6064FF' : '#303031'}
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M33.4821 6C35.4911 6 37.3393 7.20536 38.1429 9.13393L41.5179 16.6071C41.8393 17.25 42 17.9732 42 18.6964V36.8571C42 39.75 39.6696 42 36.8571 42H11.1429C8.25 42 6 39.75 6 36.8571V18.6964C6 17.9732 6.08036 17.25 6.40179 16.6071L9.77679 9.13393C10.5804 7.20536 12.4286 6 14.4375 6H33.4821ZM23.3514 38.1429C21.8797 36.3366 21 34.0263 21 31.5C21 25.7168 25.6758 21 31.5 21C34.0164 21 36.331 21.8931 38.1429 23.3785V20.1429H9.85714V36.8571C9.85714 37.5804 10.4196 38.1429 11.1429 38.1429H23.3514ZM33.4821 9.85714H25.9286V16.2857H37.1786L34.6875 10.6607C34.4464 10.1786 33.9643 9.85714 33.4821 9.85714ZM10.7411 16.2857H22.0714V9.85714H14.4375C13.9554 9.85714 13.4732 10.1786 13.2321 10.6607L10.7411 16.2857ZM22.9688 31.5C22.9688 26.8242 26.7832 22.9688 31.5 22.9688C36.1758 22.9688 40.0312 26.8242 40.0312 31.5C40.0312 36.2168 36.1758 40.0312 31.5 40.0312C26.7832 40.0312 22.9688 36.2168 22.9688 31.5Z"
              fill={isActive ? '#6064FF' : '#303031'}
            />
          </g>
        </g>
      </svg>
    ),
  },
  {
    label: 'Usado',
    value: {
      isUsed: true,
      isReconditioned: false,
    },
    icon: (isActive) => (
      <svg
        width="48"
        height="48"
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="Icons/OutLine/UsedProduct/Default">
          <path
            id="Union"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M38.1429 9.13393C37.3393 7.20536 35.4911 6 33.4821 6H14.4375C12.4286 6 10.5804 7.20536 9.77679 9.13393L6.40179 16.6071C6.08036 17.25 6 17.9732 6 18.6964V36.8571C6 39.75 8.25 42 11.1429 42H22.125L22.1248 41.9977C22.6954 41.9625 23.0956 41.5365 23.0956 40.95V36.4875C24.842 39.4188 28.0291 41.3 31.4782 41.3C35.8004 41.3 39.6424 38.4125 40.8649 34.2562C41.0395 33.6875 40.7339 33.1187 40.1663 32.9437C39.5988 32.7687 39.0312 33.075 38.8565 33.6437C37.9397 36.925 34.8836 39.2 31.4782 39.2C28.553 39.2 25.9335 37.5375 24.6237 35H29.0333C29.6008 35 30.0811 34.5625 30.0811 33.95C30.0811 33.3813 29.6445 32.9 29.0333 32.9H22.0478C21.4802 32.9 21 33.3813 21 33.95V38.1429H11.1429C10.4196 38.1429 9.85714 37.5804 9.85714 36.8571V20.1429H38.1429V24.3681C36.369 22.7049 34.0138 21.7 31.5218 21.7C27.1996 21.7 23.3139 24.6313 22.0915 28.7875C21.9605 29.3125 22.2661 29.925 22.8337 30.0563C23.4012 30.2313 23.9688 29.925 24.0998 29.3563C25.0603 26.1188 28.1164 23.8 31.5218 23.8C34.4033 23.8 37.0229 25.5063 38.3326 28H33.9667C33.3555 28 32.9189 28.4813 32.9189 29.05C32.9189 29.6625 33.3555 30.1 33.9667 30.1H40.9522C41.5197 30.1 42 29.6625 42 29.05V18.6964C42 17.9732 41.8393 17.25 41.5179 16.6071L38.1429 9.13393ZM25.9286 9.85714H33.4821C33.9643 9.85714 34.4464 10.1786 34.6875 10.6607L37.1786 16.2857H25.9286V9.85714ZM22.0714 16.2857H10.7411L13.2321 10.6607C13.4732 10.1786 13.9554 9.85714 14.4375 9.85714H22.0714V16.2857Z"
            fill={isActive ? '#6064FF' : '#303031'}
          />
        </g>
      </svg>
    ),
  },
  {
    label: 'Reacondicionado',
    value: {
      isUsed: true,
      isReconditioned: true,
    },
    icon: (isActive) => (
      <svg
        width="48"
        height="48"
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="Icons/OutLine/ReconditionedProduct/Default">
          <path
            id="Union"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M33.4821 6C35.4911 6 37.3393 7.20536 38.1429 9.13393L41.5179 16.6071C41.8393 17.25 42 17.9732 42 18.6964V36.8571C42 39.75 39.6696 42 36.8571 42H11.1429C8.25 42 6 39.75 6 36.8571V18.6964C6 17.9732 6.08036 17.25 6.40179 16.6071L9.77679 9.13393C10.5804 7.20536 12.4286 6 14.4375 6H33.4821ZM33.4821 9.85714H25.9286V16.2857H37.1786L34.6875 10.6607C34.4464 10.1786 33.9643 9.85714 33.4821 9.85714ZM38.1429 20.1429H9.85714V36.8571C9.85714 37.5804 10.4196 38.1429 11.1429 38.1429H36.8571C37.5 38.1429 38.1429 37.5804 38.1429 36.8571V20.1429ZM10.7411 16.2857H22.0714V9.85714H14.4375C13.9554 9.85714 13.4732 10.1786 13.2321 10.6607L10.7411
              16.2857Z"
            fill={isActive ? '#6064FF' : '#303031'}
          />
        </g>
      </svg>
    ),
  },
]
