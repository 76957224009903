/* eslint-disable react/prop-types */
import React, { useState } from 'react'

import { Grid, InputLabel, TextField } from '@material-ui/core'

import useStyles from './editComisions.styles'

import Button from 'components/CustomButtons/Button'

const ModalConfigurationGeneral = ({
  addInfo,
  setAddInfo,
  onCancel,
  createUpdateSettingsGroup,
}) => {
  const classes = useStyles()

  const [alert, setAlert] = useState(null)

  const onSubmit = async () => {
    createUpdateSettingsGroup()
  }

  return (
    <Grid container xl={12}>
      <Grid md={8} xs={10} className={classes.center}>
        <Grid md={12} sm={8} xs={12} className={classes.center}>
          <InputLabel htmlFor="name" className={classes.labelInput}>
            Nombre
          </InputLabel>

          <TextField
            className={classes.input}
            id="name"
            formControlProps={{
              fullWidth: true,
            }}
            value={addInfo.name || ''}
            variant="outlined"
            InputProps={{
              name: 'value',
              onChange: (e) => {
                setAddInfo({ ...addInfo, name: e.target.value })
              },
            }}
          />
        </Grid>
        <Grid
          container
          xl={12}
          sm={8}
          md={12}
          xs={12}
          justify="center"
          className={classes.spaccingButtons}
        >
          <Button
            color="error"
            onClick={() => {
              onCancel()
            }}
          >
            Cancelar
          </Button>

          <Button
            color="secondary"
            onClick={onSubmit}
            type="button"
            style={{ marginLeft: '1rem' }}
          >
            Guardar
          </Button>
        </Grid>
      </Grid>
      {alert}
    </Grid>
  )
}

export default ModalConfigurationGeneral
