/* eslint-disable react/prop-types */
import React, { useState } from 'react'

import {
  Button,
  Typography,
  FormGroup,
  Grid,
  IconButton,
  InputLabel,
  GridList,
  List,
  ListItem,
  ListItemText,
  TextField,
  Tooltip,
  Divider,
} from '@material-ui/core'

import useStyles from '../../products.styles'

import { DeleteIcon } from 'components/icons/personalizedIcons'
import ModalContainer from 'components/ModalContainer'
import useCustomDialogAlert from 'components/useCustomDialogAlert'
import { useProduct } from 'contextApi/ProductContext'

const AttributeOptions = ({
  setCategoriesWithAtributtes,
  categoriesWithAtributtes,
  optionList,
  indexCat,
  setOptionList,
  onClose,
  add,
}) => {
  const classes = useStyles()
  const { product, setProduct } = useProduct()
  const showDialogAlert = useCustomDialogAlert()

  const [optionError, setOptionError] = useState(false)
  const [newOption, setNewOption] = useState('')

  const addOption = (event) => {
    event.preventDefault()
    if (newOption.trim() === '') {
      showDialogAlert(true, '¡Advertencia!', `El campo no puede estar vacío.`, 'Cerrar')
      setOptionError(true)
      return false
    }
    const temp = { ...optionList }
    const founded = temp.value.findIndex((option) => {
      return option === newOption.trim()
    })
    if (founded === -1) {
      temp.value.push(newOption.trim())
      setOptionList(temp)
      setNewOption('')
    } else {
      showDialogAlert(true, '¡Advertencia!', `La opción ya se encuentra registrada.`, 'Cerrar')
      setNewOption('')
    }
  }

  const DeleteOption = (index) => {
    const temp = { ...optionList }
    temp.value.splice(index, 1)
    setOptionList(temp)
  }

  const saveChanges = () => {
    if (optionList.value.length === 0) {
      showDialogAlert(true, '¡Advertencia!', `Se debe agregar mínimo una opción.`, 'Cerrar')
      return false
    }
    const tempCategories = categoriesWithAtributtes ? [...categoriesWithAtributtes] : []
    const temProductAttributes = product.attributes ? [...product.attributes] : []
    if (add) {
      if (tempCategories.length > 0) {
        tempCategories[indexCat].atributtes.push(optionList)
      } else {
        product.categories.map((category, index) => {
          tempCategories.push({
            id: category.id,
            name: category.name,
            atributtes: index === indexCat ? [optionList] : [],
          })
        })
      }

      temProductAttributes.push(optionList)
    } else {
      const indexAttr = tempCategories[indexCat].atributtes.findIndex(
        (list) => list.name === optionList.name
      )
      if (indexAttr !== -1) {
        tempCategories[indexCat].atributtes.splice(indexAttr, 1, optionList)
        temProductAttributes.splice(indexAttr, 1, optionList)
      }
    }

    setCategoriesWithAtributtes(tempCategories)
    setProduct({
      ...product,
      categoriesWithAtributtes: tempCategories,
      attributes: temProductAttributes.filter((temp) => !temp.new),
    })
    onClose(add)
  }

  return (
    <ModalContainer
      title={`Agregar opciones para ${optionList.name}`}
      open
      onClose={onClose}
      size="md"
    >
      <Grid container className={classes.root} justify="flex-start">
        <Grid item xs={12} sm={12} md={12}>
          <Grid container alignItems="center" justify="flex-start" style={{ marginTop: 20 }}>
            <FormGroup className={classes.formInput}>
              <InputLabel className={classes.inputOption} error={optionError}>
                Opción
              </InputLabel>
              <TextField
                fullWidth
                id="name"
                name="name"
                variant="outlined"
                onChange={(e) => {
                  setOptionError(false)
                  setNewOption(e.target.value)
                }}
                value={newOption}
                error={optionError}
              />
            </FormGroup>

            <Button
              className={classes.btnBackgroundPrimary}
              variant="contained"
              color="secondary"
              style={{ height: 40, marginLeft: 20 }}
              onClick={addOption}
            >
              Añadir opción
            </Button>
          </Grid>
          <Typography variant="subtitle1" className={classes.ProductPartTypo}>
            Opciones
          </Typography>
          <GridList cellHeight={50} cols={1} style={{ maxHeight: '40vh', height: '65%' }}>
        
            {optionList.value.map((element, index) => {
              return (
                <List component="nav">
                  <ListItem key={index}>
                    <ListItemText primary={element} />
                    <Tooltip title="Eliminar opción">
                      <IconButton
                        color="primary"
                        component="span"
                        onClick={() => DeleteOption(index)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </ListItem>
                  <Divider />
                </List>
              )
            })}
          </GridList>
        </Grid>
      </Grid>

      <Grid
        container
        alignItems="center"
        justifyContent="center"
        className={classes.gridButtonsAttributesOptions}
      >
        <Button className={classes.btnBackgroundError} onClick={onClose}>
          Cancelar
        </Button>
        <Button
          className={classes.btnBackgroundPrimary}
          variant="contained"
          color="secondary"
          onClick={saveChanges}
        >
          Guardar
        </Button>
      </Grid>
    </ModalContainer>
  )
}

export default AttributeOptions
