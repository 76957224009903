/* eslint-disable import/prefer-default-export */
import axios from 'axios'

const getReportOrders = async (url) => {
  const result = await axios.get(url, {
    responseType: 'blob',
  })
  return result
}
export { getReportOrders }
