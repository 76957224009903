import { makeStyles } from '@material-ui/core/styles'

export default makeStyles((theme) => ({
  backdrop: {
    zIndex: 5000,
    color: '#fff',
  },
  bottom: {
    color: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  top: {
    color: '#6064FF',
    animationDuration: '550ms',
    position: 'relative',
    left: 0,
  },
  circle: {
    strokeLinecap: 'round',
  },
}))
