/* eslint-disable react/prop-types */
import React, { useState } from 'react'

import { FormControl, Grid, MenuItem, Select, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import FilterButtons from 'components/FilterButtons'
import SearchBox from 'components/SearchBox'

const useStyles = makeStyles(() => ({
  container: {
    marginBottom: 30,
  },
  listActions: {
    width: 151,
  },
  divActionsAndFilters: {
    display: 'flex',
    gap: '15px',
  },
  divStates: {
    display: 'flex',
    columnGap: '10px',
  },
}))

const FiltersUsers = ({ getUsers, filterSelected, setFilterSelected }) => {
  const classes = useStyles()

  const [avalaibleSetTextValueEmpty, setAvalaibleSetTextValueEmpty] = useState(false)

  const handlerOnClick = (index, status) => {
    setFilterSelected(index)
    setAvalaibleSetTextValueEmpty(true)
    getUsers(status)
  }

  const handlerSearch = (text) => {
    setAvalaibleSetTextValueEmpty(false)
    getUsers(true, text)
    setFilterSelected(0)
  }

  return (
    <Grid container justify="space-between" alignItems="flex-end" className={classes.container}>
      <Grid className={classes.divActionsAndFilters}>
        <Grid>
          <Typography variant="subtitle1">Filtrar por:</Typography>
          <Grid className={classes.divStates}>
            <FilterButtons
              label="Todos"
              onClick={() => handlerOnClick(0)}
              active={filterSelected === 0}
            />
            <FilterButtons
              label="Activos"
              onClick={() => handlerOnClick(1, true)}
              active={filterSelected === 1}
            />
            <FilterButtons
              label="Nuevos"
              onClick={() => handlerOnClick(2, false)}
              active={filterSelected === 2}
            />
          </Grid>
        </Grid>
      </Grid>
      <SearchBox
        handlerSearch={handlerSearch}
        avalaibleSetTextValueEmpty={avalaibleSetTextValueEmpty}
        setAvalaibleSetTextValueEmpty={setAvalaibleSetTextValueEmpty}
      />
    </Grid>
  )
}

export default FiltersUsers
