import React, { useState } from 'react'

import { Grid, InputLabel, InputAdornment } from '@material-ui/core'
import CurrencyTextField from '@unicef/material-ui-currency-textfield'
import axios from 'axios'
import PropTypes from 'prop-types'

// Material ui core

// Template components
import styles from '../products.styles'
import BackDrop from 'components/BackDrop'
import Button from 'components/CustomButtons/Button'
import useCustomDialogAlert from 'components/useCustomDialogAlert'

// Constants

import { MICROSERVICES_PRODUCTS } from 'constants/urls'


const EditQuantity = ({ onCancel, dataToEdit, productData, setData, getFilterQuantuity }) => {
  const defaultModel = {
    id: dataToEdit.id,
    stockQuantity: dataToEdit.value,
  }
  const [isLoading, setIsLoading] = useState(false)
  const [model, setModel] = useState(defaultModel)

  const classes = styles()
  const showDialogAlert = useCustomDialogAlert()

  const onSubmit = async (event) => {
    event.preventDefault()
    if (model.stockQuantity < 0 || model.stockQuantity === '') {
      showDialogAlert(true, '¡Advertencia!', `La cantidad esta vacía`, 'Cerrar')
      return false
    }
    let productOject = productData.find((product) => product.id === defaultModel.id)
    productOject.stockQuantity = model.stockQuantity.split(',').join('')
    setIsLoading(true)
    try {
      const result = await axios({
        url: `${MICROSERVICES_PRODUCTS}/${defaultModel.id}`,
        method: 'PUT',
        data: productOject,
      })
      if (result) {
        setIsLoading(false)
      }
      if (result.status === 200 || result.status === 201) {
        getFilterQuantuity()
        showDialogAlert(
          true,
          '¡Cambio exitoso!',
          `Se actualizó correctamente la cantidad`,
          'Cerrar',
          '',
          true
        )
        const newData = [...productData]
        const found = newData.find((product) => product.id === defaultModel.id)
        found.stockQuantity = model.stockQuantity.split(',').join('')
        setData(newData)
        onCancel()
      }
    } catch (error) {
      setIsLoading(false)
      console.log(error)
      showDialogAlert(
        true,
        '¡Advertencia!',
        `Ha ocurrido un error al actualizar la cantidad, valida que el producto tenga una bodega asignada`,
        'Cerrar'
      )
    }

    return true
  }
  return (
    <form noValidate onSubmit={onSubmit} autoComplete="off">
       <BackDrop open={isLoading} />
      <Grid container className={classes.root} justify="center">
        <Grid item xs={12} sm={12} md={6}>
          <InputLabel className={classes.labelSize}>Stock Total</InputLabel>
          <CurrencyTextField
            currencySymbol="#"
            textAlign="left"
            decimalPlaces={0}
            outputFormat="string"
            variant="outlined"
            inputProps={{
              defaultValue: defaultModel.stockQuantity,
              startAdornment: <InputAdornment position="start">#</InputAdornment>,
              inputProps: { min: 0 },
              onChange: ({ target }) => setModel({ ...model, stockQuantity: target.value })
            }}
          />
        </Grid>
      </Grid>
      <Grid container alignItems="center" justifyContent="center">
        <div className={classes.wrapper}>
          <Button
            variant="contained"
            className={`${classes.btnBackgroundErrorPrice} ${classes.btnUpload}`}
            btnErrorPrice
            disabled={isLoading}
            onClick={onCancel}
          >
            Cancelar
          </Button>
        </div>
        <Button
          type="submit"
          className={`${classes.btnNewProduct} ${classes.btnUpload}`}
          isLoading={isLoading}
          label="Actualizar"

        >
          Actualizar
        </Button>
      </Grid>
    </form>
  )
}
EditQuantity.propTypes = {
  onCancel: PropTypes.func.isRequired,
}

// EditQuantity.defaultProps = {
//   modelId: null,
// }

export default EditQuantity
