import React from 'react'

import { IconButton, Tooltip } from '@material-ui/core'
import PropTypes from 'prop-types'

// Material ui core

// Material ui icons

const CustomToolbar = ({ tooltip, onClick, icon }) => {
  return (
    <Tooltip title={tooltip}>
      <IconButton onClick={onClick}>{icon}</IconButton>
    </Tooltip>
  )
}

CustomToolbar.propTypes = {
  tooltip: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
}
export default CustomToolbar
