import React, { useEffect, useState } from 'react'

import { Typography, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import MUIDataTable from 'mui-datatables'

import Button from 'components/CustomButtons/Button'

const useStyles = makeStyles((theme) => ({
  gridButtons: {
    marginTop: 20,
    gap: '20px',
  },
  btnBackgroundError: {
    background: theme.palette.secondary.main,
    width: 150,
    height: 35,
    borderRadius: 6,

    '&:hover': {
      background: theme.palette.error.main,
    },
  },
  btnBackgroundPrimary: {
    background: theme.palette.secondary.main,
    width: 150,
    height: 35,
    borderRadius: 6,

    '&:hover': {
      background: theme.palette.primary.main,
    },
  },
}))

function ModalUpdatePaymentMethodStatus(props) {
  const classes = useStyles()
  const { buildData, onCancel, updateMethodState } = props

  const [ColumnsData, setColumsData] = useState([])
  const loadColumns = () => {
    let listMethods = []
    listMethods = Object.keys(buildData)
    listMethods.splice(listMethods.length - 1, 1)
    const buildColumns = listMethods.map((item) => {
      const newColum = {
        name: `${item}`,
        label: `${item}`,
        options: {
          filter: true,
          sort: false,
          filterOptions: { fullWidth: true },
        },
      }
      return newColum
    })
    setColumsData(buildColumns)
  }
  useEffect(() => {
    loadColumns()
  }, [])

  const columns = ColumnsData

  const options = {
    responsive: 'vertical',
    print: false,
    sort: false,
    download: false,
    viewColumns: false,
    selectableRows: 'none',
    filter: false,
    pagination: false,
    serverSide: true,
    search: false,
    columns: 'center',
    resizableColumns: false,
    sortThirdClickReset: true,
    sortDescFirst: true,
  }

  return (
    <Grid container direction="column">
      <Typography variant="h6">{buildData.serviceName}</Typography>
      <MUIDataTable options={options} columns={columns} data={[buildData]} />
      <Grid container justify="center" className={classes.gridButtons}>
        <Button color="error" onClick={onCancel}>
          Cancelar
        </Button>
        <Button color="secondary" onClick={updateMethodState}>
          Guardar
        </Button>
      </Grid>
    </Grid>
  )
}

export default ModalUpdatePaymentMethodStatus
