/* eslint-disable react/prop-types */
import React, { useState, useContext } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { TextField, InputLabel, Button, Typography, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import axios from 'axios'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'

import ConfirmEmail from './ConfirmEmail'
import styles from './loginPageStyle'

import DialogAlert from 'components/DialogAlert'
import { USER_VERIFY_TWO_FACTOR_TOKEN } from 'constants/urls'
import useAuthContext from 'contextApi/AuthContext'
import { onKeyPressJustNumbers, onlyNumber } from 'utils/utils'

const useStyles = makeStyles(styles)
const schema = yup.object().shape({
  codeVerification: yup
    .number()
    .min(100000, 'Ingrese el codigo de 6 caracteres')
    .max(999999, 'Ingrese mínimo 6 caracteres')
    .positive()
    .typeError('Campo requerido.')
    .required('Campo requerido.'),
})

export default function VerifyCode({ userEmail, code, handlerClosed }) {
  const classes = useStyles()
  const { register, handleSubmit, errors } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
  })
  const { dialogAlert, setDialogAlert } = useContext(useAuthContext)

  const [model, setModel] = useState({
    codeVerification: '',
  })

  const handleCloseDialog = () => {
    setDialogAlert({ ...dialogAlert, open: false })
  }
  const [lblMessage, setLblMessage] = useState('')
  const [isVerified, setIsVerified] = useState(false)

  const handleChange = (e) => {
    setLblMessage('')
    if (onlyNumber(e.target.value)) {
      setModel({ ...model, codeVerification: e.target.value })
      return true
    }
    return false
  }

  const onSubmit = async () => {
    await axios
      .post(`${USER_VERIFY_TWO_FACTOR_TOKEN}`, {
        code: model.codeVerification,
        email: userEmail,
      })
      .then((response) => {
        if (response.status === 200) {
          setIsVerified(true)
          return true
        }
        return false
      })
      .catch((e) => {
        if (e.response.data.errors.includes('Código Incorrecto.')) {
          setLblMessage('Código Incorrecto.')
        } else {
          setLblMessage('Ha ocurrido un error')
        }
        return false
      })
    return false
  }

  return (
    <>
      <DialogAlert
        open={dialogAlert.open}
        title={dialogAlert.title}
        message={dialogAlert.message}
        textButtonConfirm={dialogAlert.textButtonConfirm}
        textButtonCancel={dialogAlert.textButtonCancel}
        successful={dialogAlert.successful}
        handleClose={handleCloseDialog}
      />
      {!isVerified ? (
        <>
          <Typography variant="body1">Ingresa el código de seguridad</Typography>
          <form className={classes.formVerifyCode} onSubmit={handleSubmit(onSubmit)}>
            <InputLabel
              error={!!errors.codeVerification}
              htmlFor="storeName"
              className={classes.labelInput}
            >
              Código de seguridad
            </InputLabel>

            <TextField
              className={classes.input}
              id="codeVerification"
              formControlProps={{
                fullWidth: true,
              }}
              type="tel"
              name="codeVerification"
              inputRef={register}
              onKeyPress={onKeyPressJustNumbers}
              error={!!errors.codeVerification}
              helperText={errors?.codeVerification?.message}
              variant="outlined"
              inputProps={{
                maxLength: 6,
                minLength: 6,
              }}
              onChange={handleChange}
            />

            <Typography className={classes.caption} variant="caption">
              {lblMessage}
            </Typography>
            <Grid container justify="center" className={classes.divButtons}>
              <Button
                className={classes.btnBackgroundError}
                variant="contained"
                color="secondary"
                onClick={handlerClosed}
              >
                Cancelar
              </Button>

              <Button
                variant="contained"
                color="secondary"
                className={classes.btnBackgroundPrimary}
                type="submit"
              >
                Verificar
              </Button>
            </Grid>
          </form>
        </>
      ) : (
        <ConfirmEmail code={code} userEmail={userEmail} handlerClosed={handlerClosed} />
      )}
    </>
  )
}
