import { useContext } from 'react'

import { API_COUPON, API_RULE, API_SUPPLIER, API_MULTIPLE_COUPONS } from 'constants/urls'
import useAuthContext from 'contextApi/AuthContext'

const useCouponsInterceptor = () => {
  const { getAccessToken } = useContext(useAuthContext)
  const headers = {
    accept: '*/*',
    'Content-Type': 'application/json',
    Authorization: `Bearer ${getAccessToken()}`,
  }

  const getDiscountTypes = async () => {
    const response = await fetch(`${API_COUPON}/GetDiscountTypes`, {
      method: 'GET',
      headers,
    })
    const data = await response.json()
    return data
  }

  const getCoupons = async (body) => {
    const response = await fetch(`${API_COUPON}/GetCoupons`, {
      method: 'POST',
      headers,
      body: JSON.stringify(body),
    })
    const data = await response.json()
    return data
  }

  const getUseTypes = async () => {
    const response = await fetch(`${API_COUPON}/GetUseTypes`, {
      method: 'GET',
      headers,
    })
    const data = await response.json()
    return data
  }

  const getRuleTypes = async () => {
    const response = await fetch(`${API_RULE}/GetRuleTypes`, {
      method: 'GET',
      headers,
    })
    const data = await response.json()
    return data
  }

  const getRuleConditions = async () => {
    const response = await fetch(`${API_RULE}/GetConditions`, {
      method: 'GET',
      headers,
    })
    const data = await response.json()
    return data
  }

  const createCoupon = async (body) => {
    const response = await fetch(`${API_COUPON}/CreateCoupon`, {
      method: 'POST',
      headers,
      body: JSON.stringify(body),
    })
    const data = await response.json()
    return data
  }

  const updateCoupon = async (body, newRules) => {
    const { id, couponCode, rules, ...rest } = body
    const response = await fetch(`${API_COUPON}/UpdateCoupon?couponCode=${couponCode}`, {
      method: 'PUT',
      headers,
      body: JSON.stringify({ ...rest, rules: newRules }),
    })
    const data = await response.json()
    return data
  }

  const changeCouponStatus = async (couponCode, status) => {
    const response = await fetch(`${API_COUPON}/ChangeCouponStatus`, {
      method: 'PATCH',
      headers,
      body: JSON.stringify({ couponCode, status }),
    })
    const data = await response.json()
    return data
  }

  const getCouponsApplied = async () => {
    const response = await fetch(`${API_COUPON}/GetCouponsApplied`, {
      method: 'GET',
      headers,
    })
    const { data } = await response.json()
    return data
  }

  const createMultipleCoupons = async (body) => {
    const response = await fetch(`${API_MULTIPLE_COUPONS}/CreateMultipleCoupons`, {
      method: 'POST',
      headers,
      body: JSON.stringify(body),
    })
    const data = await response.json()
    return data
  }

  const activateMultipleCoupons = async (formData) => {
    const response = await fetch(`${API_MULTIPLE_COUPONS}/EnableCoupons`, {
      method: 'POST',
      body: formData,
    })
    const data = await response.json()
    return data
  }

  const getSuppliers = async () => {
    const response = await fetch(`${API_SUPPLIER}/GetSuppliers`, {
      method: 'GET',
      headers,
    })
    const { data } = await response.json()
    return data
  }

  return {
    getDiscountTypes,
    getSuppliers,
    getCoupons,
    getUseTypes,
    getRuleTypes,
    getRuleConditions,
    createCoupon,
    updateCoupon,
    changeCouponStatus,
    getCouponsApplied,
    createMultipleCoupons,
    activateMultipleCoupons,
  }
}

export default useCouponsInterceptor
