/* eslint-disable consistent-return */
import React, { useState, useEffect, useContext } from 'react'

import {
  Button,
  Grid,
  Typography,
  Stepper,
  StepLabel,
  Step,
  FormGroup,
  withStyles,
  StepConnector,
  InputLabel,
  TextField,
  DialogTitle,
  DialogContent,
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import axios from 'axios'
import { useHistory } from 'react-router-dom'

import { routeBase } from '../../constants/environments.constants'

import HomologateAttributes from './HomologateAttributes'
import useStyles from './products.styles'
import CategoryShopifySync from './shopifyStepper/CategoryShopifySync'
import LoadProducts from './shopifyStepper/LoadProducts'
import ProductsShopifySync from './shopifyStepper/ProductsShopifySync'

import BackDrop from 'components/BackDrop'
import DialogAlert from 'components/DialogAlert'
import useCustomDialogAlert from 'components/useCustomDialogAlert'
import { routesLink } from 'constants/routesConsts'
import { API_STORE_INFO, SHOPIFY_ALL, API_PRODUCTS, API_CATEGORIES_ATTRIBUTE } from 'constants/urls'
import useAuthContext from 'contextApi/AuthContext'
import { useProduct } from 'contextApi/ProductContext'

const CircleConnector = withStyles({
  alternativeLabel: {
    top: 20,
    left: 'calc(-50% + 30px)',
    right: 'calc(50% + 30px)',
  },
  active: {
    '& $line': {
      borderColor: '#3ad399',
    },
  },
  completed: {
    '& $line': {
      borderColor: '#3ad399',
    },
  },
  line: {
    borderColor: '#eaeaf0',
    borderTopWidth: 4,
    borderRadius: 1,
  },
})(StepConnector)

const ProductsShopify = () => {
  const [activeStep, setActiveStep] = useState(0)
  const showDialogAlert = useCustomDialogAlert()
  const history = useHistory()
  const { listProductsShopify, setListProductShopify } = useProduct()
  const classes = useStyles()
  const [warehouses, setWarehouses] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [messageWarehouse, setMessageWarehouse] = useState('')
  const [nameSkuRepeated, setNameRepeated] = useState([])
  const [skusRepeated, setSkusRepeated] = useState([])
  const [dialogAlert, setDialogAlert] = useState({
    open: false,
    title: '',
    message: '',
    actionConfirm: null,
    textButtonConfirm: '',
    textButtonCancel: '',
  })
  const [dialogAlertSkuRepeated, setDialogAlertSkuRepeated] = useState({
    open: false,
    title: '',
    message: '',
    actionConfirm: null,
    textButtonConfirm: '',
    textButtonCancel: '',
  })
  const [dialogAlertVariation, setDialogAlertVariation] = useState({
    open: false,
  })
  const { userLoggedin } = useContext(useAuthContext)
  const [listCategoriesShopify, setListCategoriesShopify] = useState([])
  const [rowcategoryshopify, setRowcategoryshopify] = useState()
  const [valueWarehouse, setValueWarehouse] = useState({})
  const [idWarehouse, setIdWarehouse] = useState(0)
  const [isOpenCategories, setIsOpenCategories] = useState(false)
  const [isOpenPanalProducts, setIsOpenPanalProducts] = useState(false)
  const [allProductShopifySelectedNum, setAllProductShopifySelectedNum] = useState([])
  const [totalProduct, setTotalProduct] = useState(0)
  const [rowSelected, setRowSelected] = useState(null)
  const [allHomologatesAttributes, setAllHomologatesAttributes] = useState([])
  const [paginationShopify, setPaginationShopify] = useState({
    page: 0,
    perPage: 10,
    prevPage: '',
    nextPage: '',
    typeFilter: 0, // All: 0, Active : 1, Inactive: 2
  })
  const [attributeShopify, setAttributeShopify] = useState([])
  const [valueAttributeShopify, setValueAttributeShopify] = useState({})
  const [attributeSellerCenter, setAttributeSellerCenter] = useState([])
  const [valueAttributeSellerCenter, setValueAttributeSellerCenter] = useState({})
  const [chipData, setChipData] = useState([])
  const [urlNext, setUrlNext] = useState('')
  const [urlPrevious, setUrlPrevious] = useState('')
  const getSteps = () => {
    return ['Productos de shopify', 'Homologación de categorías', 'Cargar productos']
  }
  const headersShopify = routeBase[process.env.REACT_APP_ENVIRONMENT].routeShopify
  const getAttributesShopify = (id) => {
    const productId = allProductShopifySelectedNum.find((item) => item.id === id)
    setAttributeShopify(productId.options)
  }
  const getAttributes = (idCategory) => {
    if (idCategory.length > 0) {
      axios
        .post(`${API_CATEGORIES_ATTRIBUTE}`, idCategory)
        .then(function (response) {
          setAttributeSellerCenter(response.data.data)
          console.log(response)
        })
        .catch(function (error) {
          console.log(error)
        })
    }
  }
  const handleCloseDialogAlert = () => {
    setDialogAlert({ ...dialogAlert, open: false })
  }
  const urlNetVerification = (params, position) => {
    const paginationNext = params[position].trim()

    const urlShopify = paginationNext.substr(1, paginationNext.indexOf('>') - 1)
    const urlPetition = `&${urlShopify.split('&')[1]}`
    return urlPetition
  }
  const defaultProps = {
    options: warehouses,
    getOptionLabel: (option) => (option.title === null ? '' : option.title),
  }
  const saveProductsInSellerCenter = (arrayProduct) => {
    if (arrayProduct.length > 0) {
      setIsLoading(true)
      axios
        .post(`${API_PRODUCTS}/ProductBatch`, arrayProduct)
        .then(function (response) {
          setIsLoading(false)
          console.log(response)
          let productError = []
          if (response.data.length >= 1) {
            productError = response.data.map((product) => {
              return product.name !== null ? `${product.name}` : null
            })
            setNameRepeated(productError.filter((nameProduct) => nameProduct))
            setDialogAlertSkuRepeated({
              open: true,
              title: 'nombres de skus repetidos',
              // actionConfirm: () => saveProductConfirm(),
              textButtonConfirm: null,
              textButtonCancel: null,
            })
            handleCloseDialogAlert()
          } else {
            history.push(`${routesLink.products}`)
          }
        })
        .catch(function (error) {
          setIsLoading(false)
          console.log(error)
        })
    }
  }
  const onCancelVariations = () => {
    setDialogAlertVariation({ open: false })
    setValueAttributeSellerCenter({})
    setValueAttributeShopify({})
    setAllHomologatesAttributes([])
    setChipData([])
  }
  const onCancelSkuRepeated = () => {
    setDialogAlertSkuRepeated({ ...dialogAlertSkuRepeated, open: false })
  }
  const saveAttributesHomologados = () => {
    const productSelected = { ...allProductShopifySelectedNum[rowSelected] }
    let optionsNewData = []
    let productSeller = {}
    if (allHomologatesAttributes.length !== 0) {
      productSelected.options.map((option) => {
        productSeller = allHomologatesAttributes.find((item) => item.shopify.id === option.id)
        const optionsProduct = productSelected.options.find(
          (item) => item.id === productSeller?.shopify.id
        )
        if (optionsProduct) {
          optionsProduct.name = productSeller?.seller?.name
          optionsNewData = [...optionsNewData, optionsProduct]
        }
      })
      allProductShopifySelectedNum[rowSelected].options = optionsNewData
    }

    onCancelVariations()
    showDialogAlert(
      true,
      '¡Carga exitosa!',
      `Se asociaron los atributos correctamente`,
      'Cerrar',
      '',
      true
    )
  }
  const saveProductConfirm = () => {
    if (idWarehouse === 0) {
      setMessageWarehouse('Seleccione la bodega')
      return true
    }
    setMessageWarehouse('')
    const productsSeller = allProductShopifySelectedNum.map((product, index) => {
      const productSimple = product.variants[0]
      if (productSimple.title === 'Default Title') {
        return {
          name: product.title || '',
          status: 'draft',
          description: product.body_html,
          checkTransport: false,
          warehouse_id: idWarehouse,
          attributes: [],
          variations: [],
          dimensions: {
            height: 1,
            width: 1,
            length: 1,
          },
          categories: listCategoriesShopify[index].categoryLugoPago,
          sku: `${productSimple.sku.toUpperCase()}`.replace(
            /[()#$%&/ñ\|°¿?¡!'" ́ ̈*+~{}=^`.,[;_<>. ]/g,
            ''
          ),
          weight: productSimple.weight === 0 ? 1 : productSimple.weight,
          regularPrice: productSimple.price,
          sale_price: '',
          type: 'simple',
          images: product.images.map((image) => {
            return {
              id: 0,
              src: image.src || '',
              name: '',
            }
          }),
          assumedValue: 0,
          stockQuantity: productSimple.inventory_quantity || 0,
        }
      }
      const arrayCategory = listCategoriesShopify[index].categoryLugoPago
      const objectAtributesCategories =
        listCategoriesShopify[index].categoryLugoPago[
          listCategoriesShopify[index].categoryLugoPago.length - 1
        ]
      const categoryWithAtributes = {
        ...objectAtributesCategories,
        attributes: product.options.map((option, index) => {
          return {
            name: option.name,
            new: true,
            position: 2,
            visible: true,
            variation: true,
            options: option.values,
            isChecked: true,
          }
        }),
      }
      arrayCategory[arrayCategory.length - 1] = categoryWithAtributes
      return {
        name: product.title,
        status: 'draft',
        description: product.body_html,
        checkTransport: false,
        warehouse_id: idWarehouse,
        attributes: product.options.map((attribute) => {
          return {
            name: attribute.name,
            visible: true,
            variation: true,
            options: attribute.values,
          }
        }),
        variations: product.variants.map((variation) => {
          const imageVariation = product.images.find((element) => element.id === variation.image_id)
          return {
            description: product.body_html,
            attributes: product.options.map((option, index) => {
              return {
                name: option.name,
                option: variation[`option${index + 1}`],
              }
            }),
            type: 'add',
            sku: `${variation.sku.toUpperCase()}`.replace(
              /[()#$%&/ñ\|°¿?¡!'" ́ ̈*+~{}=^`.,[;_<>. ]/g,
              ''
            ),
            images: [],
            rowIndex: -1,
            isActivePromotionPrice: false,
            stockQuantity: variation.inventory_quantity,
            dimensions: {
              height: variation.height,
              width: variation.width,
              length: variation.length,
              weight: variation.weight || 1,
            },
            warehouse_id: idWarehouse,
            regularPrice: variation.price,
            assumedValue: 0,
            image: {
              file: {},
              id: 0,
              name: imageVariation?.admin_graphql_api_id || '',
              showOptions: false,
              src:
                imageVariation?.src ||
                product?.image?.src ||
                'https://luegopago.com/wp-content/uploads/woocommerce-placeholder-150x150.png',
            },
          }
        }),
        dimensions: {
          height: 1,
          width: 1,
          length: 1,
        },
        categories: arrayCategory,
        sku: `${product.handle.toUpperCase()}`.replace(
          /[()#$%&/ñ\|°¿?¡!'" ́ ̈*+~{}=^`.,[;_<>. ]/g,
          ''
        ),
        weight: 1,
        regularPrice: 0,
        sale_price: '',
        type: 'variable',
        images: [
          {
            id: 0,
            src: product?.image?.src || '',
            name: product?.image?.admin_graphql_api_id || '',
          },
        ],
        assumedValue: 0,
        categoriesWithAtributtes: arrayCategory,
      }
    })
    saveProductsInSellerCenter(productsSeller)
  }
  const saveProduct = () => {
    const selectedCategory = listCategoriesShopify.find((category) => !category.categoryLugoPago)
    if (selectedCategory) {
      showDialogAlert(
        true,
        '¡Advertencia!',
        `Seleccione todas las categorías de luegoPago para continuar`,
        'Cerrar',
        '',
        true
      )
      return
    }

    setDialogAlert({
      open: true,
      title: 'Asignar bodega',
      textButtonConfirm: null,
      textButtonCancel: null,
    })
  }
  const onCancel = () => {
    setDialogAlert({
      ...dialogAlert,
      open: false,
    })
  }

  const getProductsShopify = (page = null, perPage = null, nextPage = null) => {
    setIsLoading(true)
    axios
      .get(
        `${SHOPIFY_ALL}/products?limit=${perPage || paginationShopify.perPage}${nextPage || ''}`,
        {
          headers: {
            SCOrigen: headersShopify.SCOrigen,
            country: headersShopify.country,
            'Ocp-Apim-Subscription-Key': headersShopify.subscriptionId,
          },
        }
      )
      .then((response) => {
        setTotalProduct(response.headers['x-shopify-count'] || 0)
        if (response.headers?.link) {
          const params = response.headers.link.split(',')
          const verificationPageRel = params[0].trim().split('rel=')[1].slice(1, -1)
          if (params.length === 1 && verificationPageRel === 'next') {
            setUrlPrevious('')
            setUrlNext(urlNetVerification(params, 0))
          } else if (params.length === 1 && verificationPageRel === 'previous') {
            setUrlNext('')
            setUrlPrevious(urlNetVerification(params, 0))
          } else if (params.length === 2) {
            setUrlPrevious(urlNetVerification(params, 0))
            setUrlNext(urlNetVerification(params, 1))
          }
        } else {
          setUrlPrevious('')
          setUrlNext('')
        }
        const addNumVariation = response.data.map((productVariations) => {
          return {
            ...productVariations,
            NumVariation:
              productVariations.variants[0].option1 !== 'Default Title'
                ? `${productVariations.variants.length} Variaciones`
                : '-',
          }
        })
        const skus = response.data.map((product) => {
          const productArraysku = product.variants ? product.variants[0] : null
          if (productArraysku) {
            if (productArraysku.title === 'Default Title') {
              return productArraysku.sku && productArraysku.sku !== ''
                ? `${userLoggedin.name.substring(0, 5)}-${productArraysku.sku
                    .replace(/\s/g, '')
                    .toUpperCase()}`
                : null
            }
            return `${userLoggedin.name.substring(0, 5)}-${product.handle.toUpperCase()}`
          }

        })
        setListProductShopify(addNumVariation)
        setIsLoading(false)
      })
      .catch((e) => {
        setIsLoading(false)
        console.log(e)
      })
  }
  const openModalCategories = (event, row) => {
    setRowSelected(row)
    event.preventDefault()
    // if (row?.data?.length) {
    //   return
    // }
    setRowcategoryshopify(row)
    setIsOpenCategories(true)
  }
  const handleReset = () => {
    setActiveStep(0)
  }

  const selectedProductsShopify = (rowsSelected) => {
    if (rowsSelected) {
      let newSelectedProduct = []
      let newCategoryShopify = []
      rowsSelected.forEach((Selected, index) => {
        newSelectedProduct = [...newSelectedProduct, Selected]
        newCategoryShopify = [
          ...newCategoryShopify,
          {
            idShopify: Selected.id,
            shopifyCategory: Selected?.tags || Selected?.product_type || 'No tiene',
          },
        ]
      })
      setAllProductShopifySelectedNum(newSelectedProduct)
      setListCategoriesShopify(newCategoryShopify)
    }
  }
  const handleOnClickPreviousPage = async () => {
    getProductsShopify(null, null, urlPrevious)
  }

  const handleOnClickNextPage = async () => {
    getProductsShopify(null, null, urlNext)
  }
  const onChangeRowsPerPage = (value) => {
    setPaginationShopify({ ...paginationShopify, perPage: value })
    getProductsShopify(value, paginationShopify.page)
  }
  const changePerPageTable = (perPage) => {
    setPaginationShopify({ ...paginationShopify, page: 0, perPage: perPage })
    getProductsShopify(null, perPage, null)
  }

  const columnsCategory = [
    {
      name: 'shopifyCategory',
      label: 'Categoria shopify',
      options: {
        customBodyRender: (value, tableMeta) => {
          return <div className={classes.adjustTable}>{value}</div>
        },
      },
    },
    {
      name: 'lugopagoCategory',
      label: 'Categoria luego pago',
      options: {
        customBodyRender: (value, tableMeta) => {
          return <div className={classes.adjustTable}>{value}</div>
        },
      },
    },
    {
      name: ' ',
      label: ' ',
      options: {
        customBodyRender: (value, tableMeta) => {
          return (
            <>
              <Button
                variant="contained"
                color="secondary"
                className={classes.buttonOptionCategory}
                onClick={(event) => openModalCategories(event, tableMeta.rowIndex)}
              >
                Homologar categoría
              </Button>
            </>
          )
        },
      },
    },
  ]
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }
  const getWarehousesBySellerId = async (sellerId) => {
    // setIsLoading(true)
    await axios
      .get(`${API_STORE_INFO}/Warehouse/${sellerId}`)
      .then((response) => {
        if (response.status === 200) {
          setWarehouses(response.data.data)
        }
        // setIsLoading(false)
      })
      .catch(() => {
        // setIsLoading(false)
      })
  }
  const exportProduct = async () => {
    try {
      const newArrayCategoriesChild = []
      listCategoriesShopify.map((categorie) => {
        return categorie.categoryLugoPago.map((child, index) => {
          if (index === categorie.categoryLugoPago.length - 1) {
            return newArrayCategoriesChild.push({ id: child.id, name: child.name })
          }
        })
      })
      // setIsLoadingBackDrop(true)
      const response = await axios({
        url: `${API_PRODUCTS}/ExportProductsWithWarehouse/${userLoggedin.sellerId}`,
        method: 'POST',
        data: {
          categoriesExport: newArrayCategoriesChild,
        },
        responseType: 'blob',
      })
      if (response) {
        if (response.status === 200) {
          const link = document.createElement('a')
          link.href = window.URL.createObjectURL(new Blob([response.data]))
          link.setAttribute('download', `exportaciónMasiva${Date.now()}.xlsx`)
          document.body.appendChild(link)
          link.click()
          // setIsLoadingBackDrop(false)
          onCancel()
        }
      }
    } catch (error) {
      // setIsLoadingBackDrop(false)
    }
  }
  useEffect(() => {
    getProductsShopify()
    getWarehousesBySellerId(userLoggedin.sellerId)
  }, [])
  useEffect(() => {}, [warehouses, idWarehouse])
  const steps = getSteps()
  function getStepContent(stepIndex) {
    switch (stepIndex) {
      case 0:
        return (
          <ProductsShopifySync
            listProductsShopify={listProductsShopify}
            setListProductShopify={setListProductShopify}
            getProductsShopify={getProductsShopify}
            allProductShopifySelectedNum={allProductShopifySelectedNum}
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            selectedProductsShopify={selectedProductsShopify}
            paginationShopify={paginationShopify}
            totalProduct={totalProduct}
            // changePageTable={changePageTable}
            onChangeRowsPerPage={onChangeRowsPerPage}
            urlNext={urlNext}
            urlPrevious={urlPrevious}
            changePerPageTable={changePerPageTable}
            handleOnClickPreviousPage={handleOnClickPreviousPage}
            handleOnClickNextPage={handleOnClickNextPage}
            skusRepeated={skusRepeated}
          />
        )
      case 1:
        return (
          <CategoryShopifySync
            columnsCategory={columnsCategory}
            listCategoriesShopify={listCategoriesShopify}
            setListCategoriesShopify={setListCategoriesShopify}
            isOpenCategories={isOpenCategories}
            setIsOpenCategories={setIsOpenCategories}
            rowcategoryshopify={rowcategoryshopify}
            isOpenPanalProducts={isOpenPanalProducts}
            setIsOpenPanalProducts={setIsOpenPanalProducts}
            setRowcategoryshopify={setRowcategoryshopify}
            handleBack={handleBack}
            saveProduct={saveProduct}
            openModalCategories={openModalCategories}
            setDialogAlertVariation={setDialogAlertVariation}
            getAttributes={getAttributes}
            allProductShopifySelectedNum={allProductShopifySelectedNum}
            getAttributesShopify={getAttributesShopify}
            activeStep={activeStep}
            setActiveStep={setActiveStep}
          />
        )
      case 2:
        return (
          <LoadProducts
            saveProduct={saveProduct}
            allProductShopifySelectedNum={allProductShopifySelectedNum}
            exportProduct={exportProduct}
          />
        )
      default:
        return 'stepIndex desconocido'
    }
  }
  return (
    <>
      <HomologateAttributes
        open={dialogAlertVariation.open}
        onCancel={onCancelVariations}
        attributeShopify={attributeShopify}
        attributeSellerCenter={attributeSellerCenter}
        setAttributeShopify={setAttributeShopify}
        setAttributeSellerCenter={setAttributeSellerCenter}
        valueAttributeShopify={valueAttributeShopify}
        setValueAttributeShopify={setValueAttributeShopify}
        valueAttributeSellerCenter={valueAttributeSellerCenter}
        setValueAttributeSellerCenter={setValueAttributeSellerCenter}
        allHomologatesAttributes={allHomologatesAttributes}
        setAllHomologatesAttributes={setAllHomologatesAttributes}
        chipData={chipData}
        setChipData={setChipData}
        saveAttributesHomologados={saveAttributesHomologados}
      />
      <DialogAlert
        open={dialogAlertSkuRepeated.open}
        title={dialogAlertSkuRepeated.title}
        handleClose={onCancelSkuRepeated}
        personalized
        message={dialogAlertSkuRepeated.message}
        textButtonConfirm={dialogAlertSkuRepeated.textButtonConfirm}
        actionConfirm={dialogAlertSkuRepeated.actionConfirm}
        textButtonCancel={dialogAlertSkuRepeated.textButtonCancel}
      >
        <DialogTitle className={classes.title} disableTypography>
          <Typography variant="h6">SKU no válido o duplicado.</Typography>
        </DialogTitle>
        <DialogContent className={classes.variantContent}>
          <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start">
            {nameSkuRepeated.map((nameSku) => (
              <div>{nameSku.toUpperCase()}</div>
            ))}
          </Grid>

          <Grid container justify="center" className={classes.divButtonsSkuRepeated}>
            <Button
              className={classes.btnBackgroundError}
              variant="contained"
              color="secondary"
              onClick={onCancelSkuRepeated}
            >
              Cancelar
            </Button>
          </Grid>
        </DialogContent>
      </DialogAlert>
      <DialogAlert
        open={dialogAlert.open}
        title={dialogAlert.title}
        handleClose={handleCloseDialogAlert}
        personalized
        message={dialogAlert.message}
        textButtonConfirm={dialogAlert.textButtonConfirm}
        actionConfirm={dialogAlert.actionConfirm}
        textButtonCancel={dialogAlert.textButtonCancel}
      >
        <Grid container justify="center" direction="column" className={classes.gridInfo}>
          <Typography variant="body1">
            La bodega que seleccione se le asignará a todos los productos
          </Typography>
          <FormGroup className={classes.formInputWarehouses}>
            <InputLabel className={classes.labelSizeWarehouses}>Bodega</InputLabel>
            <Autocomplete
              {...defaultProps}
              id="warehouse_id"
              name="warehouse_id"
              className={classes.autocompletePadding}
              value={valueWarehouse || {}}
              helperText={messageWarehouse || ''}
              onChange={(event, newValue) => {
                setValueWarehouse(newValue)
                if (newValue) setMessageWarehouse('')
                setIdWarehouse(newValue?.id || 0)
              }}
              renderInput={(params) => (
                <TextField
                  id="warehouse_id_name"
                  name="warehouse_id_name"
                  {...params}
                  label=""
                  margin="normal"
                  variant="outlined"
                  className={classes.autocompleteDetailProduct}
                />
              )}
            />
            <Typography variant="subtitle2" gutterBottom>
              {messageWarehouse}
            </Typography>
          </FormGroup>
          <Grid container justify="center" className={classes.divButtons}>
            <Button
              className={classes.btnBackgroundError}
              variant="contained"
              color="secondary"
              disabled={isLoading}
              onClick={onCancel}
            >
              Cancelar
            </Button>
            <Button
              className={classes.btnBackgroundPrimary}
              variant="contained"
              color="secondary"
              type="submit"
              onClick={saveProductConfirm}
              disabled={isLoading}
            >
              Asignar
            </Button>
          </Grid>
        </Grid>
      </DialogAlert>
      <BackDrop open={isLoading} />
      <Grid container justify="space-between">
        <Typography variant="h6">Sincronización de productos shopify</Typography>
      </Grid>
      <Stepper activeStep={activeStep} alternativeLabel connector={<CircleConnector />}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel
              classes={{
                label: classes.labelStepLabel,
                active: classes.activeStepLabel,
                completed: classes.completedStepLabel,
              }}
              StepIconProps={{
                classes: {
                  root: classes.rootStepIcon,
                  text: classes.text,
                  active: classes.active,
                  completed: classes.completed,
                },
              }}
            >
              {label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
      <div>
        {activeStep === steps.length ? (
          <div>
            <Typography className={classes.instructions}>Todos los pasos completados</Typography>
            <Button onClick={handleReset}>Reiniciar</Button>
          </div>
        ) : (
          <div>{getStepContent(activeStep)}</div>
        )}
      </div>
    </>
  )
}
export default ProductsShopify

