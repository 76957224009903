/*eslint-disable*/
import React from 'react'
import PropTypes from 'prop-types'
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from 'perfect-scrollbar'
import { NavLink } from 'react-router-dom'
import cx from 'classnames'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Hidden from '@material-ui/core/Hidden'
import Collapse from '@material-ui/core/Collapse'
import Icon from '@material-ui/core/Icon'
import { LogoSellerCenter } from 'components/icons/personalizedIcons'

// core components
import AdminNavbarLinks from 'components/Navbars/AdminNavbarLinks.js'

import sidebarStyle from 'assets/jss/material-dashboard-pro-react/components/sidebarStyle.js'

var ps

// We've created this component so we can have a ref to the wrapper of the links that appears in our sidebar.
// This was necessary so that we could initialize PerfectScrollbar on the links.
// There might be something with the Hidden component from material-ui, and we didn't have access to
// the links, and couldn't initialize the plugin.
class SidebarWrapper extends React.Component {
  sidebarWrapper = React.createRef()
  componentDidMount() {
    if (navigator.platform.indexOf('Win') > -1) {
      ps = new PerfectScrollbar(this.sidebarWrapper.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      })
    }
  }
  componentWillUnmount() {
    if (navigator.platform.indexOf('Win') > -1) {
      ps.destroy()
    }
  }
  render() {
    const { className, headerLinks, links } = this.props
    return (
      <div className={className} ref={this.sidebarWrapper}>
        {headerLinks}
        {links}
      </div>
    )
  }
}

class Sidebar extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      miniActive: true,
      ...this.getCollapseStates(props.routes),
    }
  }
  mainPanel = React.createRef()
  // this creates the initial state of this component based on the collapse routes
  // that it gets through this.props.routes
  getCollapseStates = (routes) => {
    let initialState = {}
    routes.map((prop) => {
      if (prop.collapse) {
        initialState = {
          [prop.state]: this.getCollapseInitialState(prop.views),
          ...this.getCollapseStates(prop.views),
          ...initialState,
        }
      }
      return null
    })
    return initialState
  }
  // this verifies if any of the collapses should be default opened on a re render of this component
  // for example, on the refresh of the page,
  // while on the src/views/forms/RegularForms.jsx - route /admin/regular-forms
  getCollapseInitialState(routes) {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse && this.getCollapseInitialState(routes[i].views)) {
        return true
      }
      if (
        window.location.href.indexOf(routes[i].path) !== -1 ||
        window.location.href.includes(
          routes[i]?.child?.filter((child) => child === window.location.href.split('/')[4])
        )
      )
        return true
    }
    return false
  }
  // verifies if routeName is the one active (in browser input)
  activeRoute = (route) => {
    const childFiltered = route?.child?.filter(
      (child) => child === window.location.href.split('/')[4]
    )
    if (
      window.location.href.includes(
        childFiltered && childFiltered.length > 0 ? childFiltered : null
      )
    ) {
      return 'active'
    } else {
      return window.location.href.indexOf(route.path) > -1 ? 'active' : ''
    }
  }
  openCollapse(collapse) {
    var st = {}
    st[collapse] = !this.state[collapse]
    this.setState(st)
  }
  // this function creates the links and collapses that appear in the sidebar (left menu)
  createLinks = (routes) => {
    const { classes, color } = this.props
    return routes.map((prop, key) => {
      if (prop.redirect) {
        return null
      }
      if (prop.hide) return null
      if (prop.collapse) {
        var st = {}
        st[prop['state']] = !this.state[prop.state]
        const navLinkClasses =
          classes.itemLink +
          ' ' +
          cx({
            [' ' + classes.collapseActive]: this.getCollapseInitialState(prop.views),
          })
        const itemText =
          classes.itemText +
          ' ' +
          cx({
            [classes.itemTextMini]: this.props.miniActive && this.state.miniActive,
          })
        const collapseItemText =
          classes.collapseItemText +
          ' ' +
          cx({
            [classes.collapseItemTextMini]: this.props.miniActive && this.state.miniActive,
          })
        const itemIcon = classes.itemIcon
        const caret = classes.caret
        const collapseItemMini = classes.collapseItemMini
        if (prop.name === 'Productos') {
          return (
            <ListItem
              key={key}
              className={cx(
                { [classes.collapse] : classes.collapse},
                { [classes.item]: prop.icon !== undefined },
                { [classes.collapseItem]: prop.icon === undefined }
              )}
            >
              <NavLink
                to={'#'}
                className={navLinkClasses}
                onClick={(e) => {
                  e.preventDefault()
                  this.setState(st)

                  if (e.target.id !== 'showItems') {
                    this.props.history.push('/admin/products')
                  }
                }}
              >
                {prop.icon !== undefined ? (
                  typeof prop.icon === 'string' ? (
                    <Icon className={itemIcon}>{prop.icon}</Icon>
                  ) : (
                    <prop.icon />
                  )
                ) : (
                  <span className={collapseItemMini}>ddd</span>
                )}
                <ListItemText
                  primary={prop.name}
                  secondary={
                    <b
                      id="showItems"
                      className={caret + ' ' + (this.state[prop.state] ? classes.caretActive : '')}
                    />
                  }
                  disableTypography={true}
                  className={cx(
                    { [itemText]: prop.icon !== undefined },
                    { [collapseItemText]: prop.icon === undefined }
                  )}
                />
              </NavLink>
              <Collapse className={classes.collapseProduct} in={this.state[prop.state]} unmountOnExit >
                <List style={{ fontFamily: 'Red Hat Display Regular' }}>
                  {this.createLinks(prop.views)}
                </List>
              </Collapse>
            </ListItem>
          )
        }

        return (
          <ListItem
            key={key}
            className={cx(
              { [classes.item]: prop.icon !== undefined },
              { [classes.collapseItem]: prop.icon === undefined }
            )}
          >
            <NavLink
              to={'#'}
              className={navLinkClasses}
              onClick={(e) => {
                e.preventDefault()
                this.setState(st)
              }}
            >
              {prop.icon !== undefined ? (
                typeof prop.icon === 'string' ? (
                  <Icon className={itemIcon}>{prop.icon}</Icon>
                ) : (
                  <prop.icon />
                )
              ) : (
                <span className={collapseItemMini}>{prop.mini}</span>
              )}
              <ListItemText
                primary={prop.name}
                secondary={
                  <b
                    className={caret + ' ' + (this.state[prop.state] ? classes.caretActive : '')}
                  />
                }
                disableTypography={true}
                className={cx(
                  { [itemText]: prop.icon !== undefined },
                  { [collapseItemText]: prop.icon === undefined }
                )}
              />
            </NavLink>
            <Collapse in={this.state[prop.state]} unmountOnExit>
              <List className={classes.list + ' ' + classes.collapseList}>
                {this.createLinks(prop.views)}
              </List>
            </Collapse>
          </ListItem>
        )
      }

      const itemIsLinkClassNowGrap =
        prop.name === 'Configuración de la tienda' ? classes.itemLinkSetupLink : classes.itemLink
      const innerNavLinkClasses =
        classes.collapseItemLink +
        ' ' +
        cx({
          [' ' + classes[color]]: this.activeRoute(prop),
        })
      const collapseItemMini = classes.collapseItemMini
      const isActive = this.activeRoute(prop)
      const activeSetupSellerItemTemp = isActive === 'active' ? '' : classes.activeSetupSeller
      const navLinkClasses =
        itemIsLinkClassNowGrap +
        ' ' +
        activeSetupSellerItemTemp +
        ' ' +
        cx({
          [' ' + classes[color]]: this.activeRoute(prop),
        })
      const itemIsTextClassWidth =
        prop.name === 'Configuración de la tienda' ? classes.itemText : classes.itemTextSetupLink

      const itemText =
        itemIsTextClassWidth +
        ' ' +
        cx({
          [classes.itemTextMini]: this.props.miniActive && this.state.miniActive,
        })
      const collapseItemText =
        classes.collapseItemText +
        ' ' +
        cx({
          [classes.collapseItemTextMini]: this.props.miniActive && this.state.miniActive,
        })
      const itemIcon = classes.itemIcon
      return (
        <ListItem
          key={key}
          className={cx(
            { [classes.item]: prop.icon !== undefined },
            { [classes.collapseItem]: prop.icon === undefined }
          )}
        >
          <NavLink
            to={prop.layout + prop.path}
            onClick={(e) => {
              if (this.props.open) {
                this.props.handleDrawerToggle()
              }
            }}
            className={cx(
              { [navLinkClasses]: prop.icon !== undefined },
              { [innerNavLinkClasses]: prop.icon === undefined }
            )}
          >
            {prop.icon !== undefined ? (
              typeof prop.icon === 'string' ? (
                <Icon className={itemIcon}>{prop.icon}</Icon>
              ) : (
                <prop.icon />
              )
            ) : (
              <span className={collapseItemMini}>{prop.mini}</span>
            )}
            <ListItemText
              primary={prop.name}
              disableTypography={true}
              className={cx(
                { [itemText]: prop.icon !== undefined },
                { [collapseItemText]: prop.icon === undefined }
              )}
            />
            {prop.name === 'Devoluciones' && this.props.returnsCount > 0 && (
              <span style={{
                borderRadius: '50%',
                background: '#6064FF',
                color: 'white',
                lineHeight: '0.5',
                padding: '10px',
                fontSize: '10px',
                fontWeight: 'bold',
              }}>{this.props.returnsCount}</span>
            )}
          </NavLink>
        </ListItem>
      )
    })
  }
  render() {
    const { classes, logo, image, routes, bgColor } = this.props
    var links = <List className={classes.list}>{this.createLinks(routes)}</List>

    const logoMini = classes.logoMini

    var brand = (
      <div className={classes.logo}>
        <NavLink to="/admin/dashboard" className={classes.colorLogoSellerCenter}>
          <LogoSellerCenter />
        </NavLink>
      </div>
    )
    const drawerPaper =
      classes.drawerPaper +
      ' ' +
      cx({
        [classes.drawerPaperMini]: this.props.miniActive && this.state.miniActive,
      })
    const sidebarWrapper =
      classes.sidebarWrapper +
      ' ' +
      cx({
        [classes.drawerPaperMini]: this.props.miniActive && this.state.miniActive,
        [classes.sidebarWrapperWithPerfectScrollbar]: navigator.platform.indexOf('Win') > -1,
      })

    return (
      <div ref={this.mainPanel}>
        <Hidden mdUp implementation="css">
          <Drawer
            variant="temporary"
            anchor={'left'}
            open={this.props.open}
            classes={{
              paper: drawerPaper + ' ' + classes[bgColor + 'Background'],
            }}
            onClose={this.props.handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {brand}
            <SidebarWrapper
              className={sidebarWrapper}
              headerLinks={<AdminNavbarLinks />}
              links={links}
            />
            {image !== undefined ? (
              <div
                className={classes.background}
                style={{ backgroundImage: 'url(' + image + ')' }}
              />
            ) : null}
          </Drawer>
        </Hidden>
        <Hidden smDown implementation="css">
          <Drawer
            onMouseOver={() => this.setState({ miniActive: false })}
            onMouseOut={() => this.setState({ miniActive: true })}
            anchor={'left'}
            variant="permanent"
            open
            classes={{
              paper: drawerPaper + ' ' + classes[bgColor + 'Background'],
            }}
          >
            {brand}
            <SidebarWrapper className={sidebarWrapper} links={links} />
            {image !== undefined ? (
              <div
                className={classes.background}
                style={{ backgroundImage: 'url(' + image + ')' }}
              />
            ) : null}
          </Drawer>
        </Hidden>
      </div>
    )
  }
}

Sidebar.defaultProps = {
  bgColor: 'blue',
}

Sidebar.propTypes = {
  classes: PropTypes.object.isRequired,
  bgColor: PropTypes.oneOf(['white', 'black', 'blue']),
  color: PropTypes.oneOf(['white', 'red', 'orange', 'green', 'blue', 'purple', 'rose']),
  logo: PropTypes.string.isRequired,
  userEmail: PropTypes.string.isRequired,
  image: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object),
  miniActive: PropTypes.bool,
  open: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
}

SidebarWrapper.propTypes = {
  className: PropTypes.string,
  headerLinks: PropTypes.object,
  links: PropTypes.object,
}

export default withStyles(sidebarStyle)(Sidebar)
