/* eslint-disable no-shadow */
import React, { useEffect, useContext, useState } from 'react'

// import useUser from 'hooks/useUser'
import axios from 'axios'
import { useLocation } from 'react-router-dom'

import { routeBase } from '../../constants/environments.constants'

import BackDrop from 'components/BackDrop'
import { SHOPIFY_ALL, FRONT } from 'constants/urls'
import useAuthContext from 'contextApi/AuthContext'

const AuthorizationShopify = () => {
  const { logout } = useContext(useAuthContext)
  const [isLoading, setIsLoading] = useState(false)
  const headersShopify = routeBase[process.env.REACT_APP_ENVIRONMENT].routeShopify

  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const shopName = params.get('shop')
  const redirectToAuthorizePage = (shopNameParams) => {
    setIsLoading(true)
    axios
      .post(
        `${SHOPIFY_ALL}/get-authorization-url`,
        {
          shopName: shopNameParams
            .replace('http://', '')
            .replace('https://', '')
            .replace('.myshopify.com', '')
            .replace('.myshopify.com/', ''),
          redirectUrl: `${FRONT}/login`,
        },
        {
          headers: {
            origen: headersShopify.origen,
            country: headersShopify.country,
            'Ocp-Apim-Subscription-Key': headersShopify.subscriptionId,
          },
        }
      )
      .then(function (response) {
        setIsLoading(false)
        window.location.href = response.data.url
      })
      .catch(function (error) {
        setIsLoading(false)
        console.log(error)
      })
  }
  useEffect(() => {
    if (shopName) {
      logout('login')
      redirectToAuthorizePage(shopName)
    }
  }, [])

  return <BackDrop open={isLoading} />
}

export default AuthorizationShopify
