import React, { useState } from 'react'

import { Grid, makeStyles } from '@material-ui/core'

import CategoriesListBreadCrumb from '../Products/categoriesListBreadCrumb'

import BackDrop from 'components/BackDrop'

const useStyle = makeStyles((theme) => ({
  table: {
    background: 'white',
  },
  center: {
    margin: 'auto',
    position: 'relative',
  },
  delete: {
    background: '#bd081c',
  },
  icon: {
    color: 'white',
  },
  padding: {
    marginTop: '1vw',
  },
  root: {
    paddingTop: '1vw',
  },
  cancel: {
    textAlign: 'right',
  },
  success: {
    float: 'right',
  },
  spaccingButtons: {
    marginTop: '2vw',
    position: 'relative',
    textAlign: 'center',
  },
  buttonSuccess: {
    background: '#4caf50',
    color: 'white',
  },
  buttonDanger: {
    background: '#cc2127',
    color: 'white',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}))

export default function Categories() {
  const style = useStyle()
  const [isLoadingBackDrop, setIsLoadingBackDrop] = useState(false)
  return (
    <>
      <BackDrop open={isLoadingBackDrop} />
      <Grid container xl={12} md={12} sm={12}>
        <Grid xs={11} md={11} sm={12} className={style.center}>
          <CategoriesListBreadCrumb
            isTemplate={false}
            listHeight="70vh"
            setIsLoadingBackDrop={setIsLoadingBackDrop}
            onCancel={null}
          />
        </Grid>
      </Grid>
    </>
  )
}
