import React from 'react'

import { Grid, Typography, Link } from '@material-ui/core'

import styles from '../bonuses.styles'

import { LuegopagoIconWhite, SistecreditoIconWhite } from 'components/icons/personalizedIcons'

const Footer = () => {
  const classes = styles()
  return (
    <div className={classes.footer}>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        spacing={2}
      >
        <Grid item xs={10} md={3}>
          <Link target="_blank" className={classes.white} href="https://luegopago.com/">
            <LuegopagoIconWhite footer/>
          </Link>

          <div style={{ marginTop: '5%' }}>
            <Link target="_blank" className={classes.white} href="https://www.sistecredito.com/">
              <SistecreditoIconWhite />
            </Link>
          </div>
        </Grid>
        <Grid item xs={6} md={3}>
          <Typography variant="subtitle2" className={classes.white}>
            <b>Acerca de</b>
          </Typography>
          <div style={{ marginTop: '2%' }}>
            <Link className={classes.white} href="https://luegopago.com/about-us" target="_blank">
              LuegopaGO
            </Link>
            <br />
            <Link className={classes.white} href="https://luegopago.com/contact" target="_blank">
              Contacto
            </Link>
            <br />
            <Link className={classes.white} href="https://luegopago.com/join-us" target="_blank">
              Trabaja con nosotros
            </Link>
          </div>
        </Grid>
        <Grid item xs={6} md={3}>
          <Typography variant="subtitle2" className={classes.white}>
            <b>Ayuda / PQR</b>
          </Typography>
          <div style={{ marginTop: '2%' }}>
            <Link
              className={classes.white}
              href="https://luegopago.com/buy-now-pay-later"
              target="_blank"
            >
              ¿Cómo comprar?
            </Link>
            <br />
            <Link className={classes.white} href="https://luegopago.com/faq" target="_blank">
              Preguntas frecuentes
            </Link>
            <br />
            <Link className={classes.white} href="https://luegopago.com/help" target="_blank">
              Sugerencias y reclamos
            </Link>
            <br />
            <Link className={classes.white} href="https://luegopago.com/withdrawal" target="_blank">
              Derecho de retracto
            </Link>
          </div>
        </Grid>
        <Grid item xs={12} md={3}>
          <Typography variant="subtitle2" className={classes.white}>
            <b>Redes sociales</b>
          </Typography>
          <div style={{ marginTop: '2%' }}>
            <Link
              className={classes.white}
              href="https://www.instagram.com/luegopagocol/"
              target="_blank"
            >
              Instagram
            </Link>
            <br />
            <Link
              className={classes.white}
              href="https://www.facebook.com/luegopagocol.col"
              target="_blank"
            >
              Facebook
            </Link>
            <br />
            <Link className={classes.white} href="https://twitter.com/luegopago" target="_blank">
              Twitter
            </Link>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

export default Footer
