import React from 'react'

import { Backdrop, Button, Fade, Modal, makeStyles } from '@material-ui/core'
import { useHistory } from 'react-router-dom'

import { ExclamationIcon } from 'components/icons/personalizedIcons'
import { sendRefundPayment } from 'services/Returns'

const useStyles = makeStyles((theme) => ({
  'label.Mui-focused': {
    color: 'green',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: '16px',
    width: '90%',
    maxWidth: '600px',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  btnBackgroundErrorPriceSmall: {
    padding: '0.5rem 2rem',
    borderRadius: '50px',
    border: '1px solid #6064ff',
    color: '#6064ff',
    fontWeight: '500',
    backgroundColor: '#ffffff',
    '&:hover': {
      color: '#FF40FC',
      border: '1px solid #FF40FC',
      backgroundColor: '#ffffff',
    },
  },
  btnNewProductsmall: {
    padding: '0.5rem 2rem',
    borderRadius: '50px',
    border: 'none',
    backgroundColor: '#5e44ff',
    color: 'white',
    fontWeight: '500',
    '&:hover': {
      fontWeight: 'bolder',
      backgroundColor: '#ef0bef',
      boxShadow: '-7px 7px 0px #000000',
      border: '2px solid #000000',
      borderRadius: '50px',
      opacity: '1',
    },
  },
}))

const RefundModal = ({ isOpen, data, handleClose, secondRefundOption, showDialogAlert }) => {
  const history = useHistory()
  const classes = useStyles()

  const onAcceptRefund = async () => {
    const { productName, ...rest } = data
    const newData = {
      ...rest,
      refundType: secondRefundOption.type,
      refundUniqueId: secondRefundOption.uniqueId,
    }
    const response = await sendRefundPayment(newData)
    console.log(response)
    if (response.isSuccessful) {
      showDialogAlert(
        true,
        'Devolución de dinero',
        'El reembolso de dinero será solicitado a la entidad financiera involucrada, por favor espere a que el proceso sea completado. Si presenta algún inconveniente por favor comuníquese con el administrador.',
        'Aceptar',
        ''
      )
    } else {
      showDialogAlert(
        true,
        'Error',
        'Ocurrió un error al realizar la solicitud, por favor intente nuevamente. Si el problema persiste comuníquese con el administrador.',
        'Aceptar',
        ''
      )
    }
    handleClose()
  }
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={isOpen}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={isOpen}>
        <div className={classes.paper}>
          <div style={{ display: 'flex', justifyContent: 'center', margin: '24px 0' }}>
            <ExclamationIcon />
          </div>
          <h2
            id="transition-modal-title"
            style={{ textAlign: 'center', fontSize: '26px', marginBottom: '24px' }}
          >
            ¡Producto sin stock!
          </h2>
          <p
            style={{
              fontFamily: '"Roboto", "Helvetica", "Arial", "sans-serif"',
              margin: '2rem 0',
              textAlign: 'center',
            }}
          >
            El producto
            <strong>{` ${data.productName} `}</strong>
            no cuenta con stock suficiente para llevar a cabo el reemplazo. Por favor, verifica la
            disponibilidad del producto y actualiza el stock si es posible. En caso contrario,
            considera solicitar la devolución del dinero al cliente.
          </p>
          <div style={{ marginTop: '1rem', display: 'flex', justifyContent: 'space-between' }}>
            <Button
              onClick={() => {
                history.push(`/admin/products?product=${data.productName}`)
              }}
              color="secondary"
              variant="outlined"
              className={classes.btnBackgroundErrorPriceSmall}
            >
              Ver producto
            </Button>
            <Button
              onClick={onAcceptRefund}
              className={classes.btnNewProductsmall}
              color="primary"
              variant="contained"
            >
              Reembolsar dinero
            </Button>
          </div>
        </div>
      </Fade>
    </Modal>
  )
}

export default RefundModal
