import { makeStyles } from '@material-ui/core/styles'

import buttonStyle from 'assets/jss/material-dashboard-pro-react/components/buttonStyle'
import switchStyles from 'assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch'

export default makeStyles((theme) => ({
  ...switchStyles,
  root: {
    flexGrow: 1,
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  formControl: {
    marginTop: '11px',
    width: '200px ',
  },
  inputBorder: {
    borderColor: '#FF0000 !important',
    borderWidth: '1px !important',
  },
  buttonAction: {
    marginLeft: 10,
  },
  ...buttonStyle,
  chip: {
    paddingTop: 17,
    paddingBottom: 17,
    marginLeft: 30,
    fontWeight: 'bold',
    fontSize: 15,
  },
  avatar: {
    padding: 17,
  },
  card: {
    minHeight: 100,
    padding: 20,
  },
  iconButton: {
    padding: 10,
  },
  divBuscador: {
    border: `1px solid ${theme.palette.primary.main}`,
    display: 'flex',
    padding: '2px 4px',
    width: 406,
    [theme.breakpoints.down('sm')]: {
      width: 300,
    },
    margin: '10px 10px 22px auto',
  },
  input: {
    flex: 1,
    marginLeft: '9px',
    fontSize: '14px',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  typeavatar: {
    margin: '9px',
  },
  btnBackgroundPrimary: {
    width: 100,
    height: 38,
    marginRight: 15,
    marginBottom: 15,
    marginTop: 5,
    borderRadius: '50px',
    border: 'none',
    backgroundColor: '#5e44ff',
    color: 'white',
    fontWeight: '500',
    '&:hover': {
      fontWeight: 'bolder',
      backgroundColor: '#ef0bef',
      boxShadow: '-7px 7px 0px #000000',
      border: '2px solid #000000',
      borderRadius: '50px',
      opacity: '1',
    },
  },
  backdropSnackbar: {
    background: '#FFFFFF',
    color: '#000000',
    padding: '10px',
    borderRadius: 7,
    border: 3,
    borderColor: '#3355FFe',
  },
  exportButton: {
    marginRight: 10,
    width: '23%',
  },
  actionsButton: {
    marginRight: 10,
    width: '23%',
  },
  chipButton: {
    marginRight: 0,
  },
  titleFilters: {
    marginRight: 10,
  },
  btnApply: {
    width: 100,
    marginBottom: 5,
    height: 38,
    borderRadius: '50px',
    border: 'none',
    backgroundColor: '#5e44ff',
    color: 'white',
    fontWeight: '500',
    '&:hover': {
      fontWeight: 'bolder',
      backgroundColor: '#ef0bef',
      boxShadow: '-7px 7px 0px #000000',
      border: '2px solid #000000',
      borderRadius: '50px',
      opacity: '1',
    },
  },
  toolBarSelect: {
    overflowX: 'auto',
  },
  iconEye: {
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      fill: theme.palette.white.main,
    },
  },
  iconSend: {
    '&:hover': {
      backgroundColor: theme.palette.green.main,
      fill: theme.palette.white.main,
    },
  },
  iconCancel: {
    '&:hover': {
      backgroundColor: theme.palette.error.main,
      fill: theme.palette.white.main,
    },
  },
  divContent: {
    paddingLeft: 30,
    '& .MuiTableCell-root': {
      position: 'relative',
    },
  },
  titleOrders: {
    marginBottom: 15,
  },
  stateCancelled: {
    marginLeft: 12,
  },
  contdad: {
    position: 'relative',
    width: '100%',
    height: '100%',
    background: 'red',
  },
  afterSameDay: {
    '&::after': {
      content: '"Entrega rápida"',
      color: '#6064FF',
      textDecoration: 'none',
      backgroundColor: '#DBDCFF',
      borderRadius: '0 0 4px 4px',
      padding: '1px 4px',
      fontWeight: 'bold',
      position: 'absolute',
      top: 0,
      fontSize: '12px',
      left: '40%',
    },
  },
}))
