/* eslint-disable no-param-reassign */
/* eslint-disable no-nested-ternary */
import React, { useState } from 'react'

import {
  Button,
  Grid,
  FormGroup,
  InputLabel,
  TextField,
  FormControlLabel,
  Checkbox,
  Typography,
  Hidden,
  InputAdornment,
  Link,
} from '@material-ui/core'
import axios from 'axios'
import { useForm } from 'react-hook-form'

import Info from './components/Info'
import styles from './StoreRegister.styles'

import BackDrop from 'components/BackDrop'
import DialogAlert from 'components/DialogAlert'
import {
  LogoSellerCenterWhite,
  FlagIcon,
  EmailTextFieldIcon,
  LoopIcon,
  CreditCardIcon,
  PercentIcon,
  DobleHandIcon,
  LikeIcon,
  SpeedIcon,
  IdentificationIcon,
  LuegopagoIconWhite,
  CheckCorrect,
  SistecreditoIconWhite,
} from 'components/icons/personalizedIcons'
import { API_SELLERS_EMAIL_REGISTER } from 'constants/urls'
import { isFormatCorrectEmail } from 'utils/utils'

const RegisterStore = () => {
  const { register, errors, setError } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  })
  const classes = styles()
  const [isLoading, setIsLoading] = useState(false)
  const [dialogAlert, setDialogAlert] = useState({
    open: false,
    title: '',
    message: '',
    actionConfirm: null,
    textButtonConfirm: '',
    textButtonCancel: '',
  })
  const [dialogNotPersonalized, setDialogNotPersonalized] = useState({
    open: false,
    title: '',
    message: '',
    actionConfirm: null,
    textButtonConfirm: '',
    textButtonCancel: '',
  })
  const [postSendEmailRegister, setPostSendEmailRegister] = useState('')
  const [checkAcceptance, setCheckAcceptance] = useState(false)

  const notificationStore = () => {
    setDialogAlert({
      open: true,
      actionConfirm: () => handleCloseDialogAlert,
      textButtonConfirm: 'Confirmar',
      textButtonCancel: null,
    })
  }

  const onSubmit = async (data, e) => {
    if (!isFormatCorrectEmail(postSendEmailRegister)) {
      setError('storeEmail', 'notMatch', 'Email no valido')
      return true
    }
    try {
      setIsLoading(true)
      const result = await axios.post(`${API_SELLERS_EMAIL_REGISTER}`, {
        storeEmail: postSendEmailRegister,
      })
      if (result) {
        if (result.status === 200) {
          if (result.data.data) {
            setIsLoading(false)
            notificationStore()

            e.target.reset()
          }
        }
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.errors) {
        const errorResponse = error.response.data
        if (errorResponse.errors[0] === "Value cannot be null. (Parameter 'Email duplicado')") {
          setDialogNotPersonalized({
            open: true,
            title: '¡Importante!',
            message: 'Tu correo electrónico ya se encuentra registrado',
            textButtonConfirm: 'Cerrar',
            textButtonCancel: null,
          })
        } else if (
          errorResponse.errors[0] ===
          "Value cannot be null. (Parameter 'Nombre de la tienda duplicado')"
        ) {
          setDialogNotPersonalized({
            open: true,
            title: '¡Importante!',
            message: 'Tu razón social ya se encuentra registrada',
            textButtonConfirm: 'Cerrar',
            textButtonCancel: null,
          })
        }

        setIsLoading(false)
      }
    }
  }
  const handleCloseDialogAlert = () => {
    setDialogAlert({ ...dialogAlert, open: false })
    setIsLoading(false)
  }
  const handleCloseDialogNotPersonalized = () => {
    setDialogNotPersonalized({ ...dialogNotPersonalized, open: false })
    setIsLoading(false)
  }
  const handleChange = (event) => {
    setCheckAcceptance(event.target.checked)
  }

  return (
    <>
      <BackDrop open={isLoading} />
      <DialogAlert
        open={dialogNotPersonalized.open}
        title={dialogNotPersonalized.title}
        handleClose={handleCloseDialogNotPersonalized}
        textButtonConfirm={dialogNotPersonalized.textButtonConfirm}
        message={dialogNotPersonalized.message}
        actionConfirm={dialogNotPersonalized.actionConfirm}
        textButtonCancel={dialogNotPersonalized.textButtonCancel}
      />
      <DialogAlert
        open={dialogAlert.open}
        title={dialogAlert.title}
        handleClose={handleCloseDialogAlert}
        personalized
        message={dialogAlert.message}
        textButtonConfirm={dialogAlert.textButtonConfirm}
        actionConfirm={dialogAlert.actionConfirm}
        textButtonCancel={dialogAlert.textButtonCancel}
      >
        <Grid
          container
          justify="center"
          direction="column"
          alignItems="center"
          className={classes.gridInfo}
        >
          <CheckCorrect />
          <Typography variant="subtitle2">¡Envío exitoso!</Typography>

          <Typography variant="body1">
            Te hemos enviado un correo a 
            {' '}
            {postSendEmailRegister}
            {' '}
            para que confirmes tu cuenta.
          </Typography>
        </Grid>
      </DialogAlert>
      <BackDrop open={isLoading} />
      <div className={classes.mainContainer}>
        <Grid container direction="row" justify="flex-start">
          <Grid item md={8} xs={12} spacing={4}>
            <Grid item style={{ maxWidth: 400 }}>
              <Link target="_blank" className={classes.white} href="https://luegopago.com/">
                <LogoSellerCenterWhite />
              </Link>
            </Grid>
            <Grid item md={12}>
              <Typography variant="h6" style={{ color: 'white' }}>
                ¡Es hora de hacer parte de un Marketplace diferente!
              </Typography>
            </Grid>
            <Grid item md={7} style={{ marginTop: '4%' }}>
              <Typography variant="body1" style={{ color: 'white' }}>
                Somos la
                <b> primera tienda virtual en Colombia </b>
                con sistema de crédito propio, donde tus clientes podrán
                <b> comprar lo que quieran YA, </b>
                recibirlo en la puerta de su casa,
                <b> y pagarlo luego.</b>
              </Typography>
            </Grid>
            <Grid item md={6} xs={12}>
              <Grid className={classes.wrapInput}>
                <InputLabel className={classes.topInputLabel} error={errors.storeEmail}>
                  Danos tu correo electrónico
                </InputLabel>
                <TextField
                  name="storeEmail"
                  fullWidth
                  variant="outlined"
                  error={errors.storeEmail}
                  inputRef={register({
                    required: 'Required',
                    pattern:
                      /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i,
                  })}
                  placeholder="micorreo@micorreo.com"
                  InputProps={{
                    className: classes.inputWhite,
                    startAdornment: (
                      <InputAdornment position="start">
                        <EmailTextFieldIcon />
                      </InputAdornment>
                    ),
                  }}
                  helperText={errors.storeEmail && 'Ingrese un correo válido'}
                  onChange={(event) => {
                    setPostSendEmailRegister(event.target.value.trim())
                  }}
                  value={postSendEmailRegister}
                />
              </Grid>
              <FormGroup className={classes.formInput}>
                <FormControlLabel
                  className={classes.formInputLabel}
                  control={
                    <Checkbox
                      checked={checkAcceptance}
                      onChange={handleChange}
                      id="check_acceptance"
                      name="checkedA"
                    />
                  }
                  label={
                    <Typography style={{ color: '#ffffff' }}>
                      Aceptación de tratamiento de datos
                    </Typography>
                  }
                />
              </FormGroup>
              <Grid className={classes.wrapInput}>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={isLoading || !checkAcceptance || postSendEmailRegister === ''}
                  className={classes.btnAceptar}
                  onClick={onSubmit}
                >
                  Empieza a vender
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Hidden smDown>
            <Grid item md={4} style={{ zIndex: 2 }}>
              <img
                src="https://luegopago.blob.core.windows.net/luegopago-uploads/sellerCenter/site/img/registro.png"
                alt="prueba"
              />
            </Grid>
          </Hidden>
        </Grid>
      </div>
      <div className={classes.sendInfo}>
        <Grid container direction="row" justify="flex-start">
          <Grid item md={12}>
            <Typography variant="h4">
              Vende con
              <br />
              <b>luegopago.com</b>
            </Typography>
          </Grid>
        </Grid>
        <Grid container direction="row" justify="center" className={classes.creditInfo}>
          <Grid item md={3} xs={12}>
            <Info
              icon={<FlagIcon />}
              text="Nuestro seller center te ayuda a alcanzar tus metas. Es una plataforma práctica donde podrás administrar tus pedidos, tus ventas y tus productos."
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <Info
              icon={<DobleHandIcon />}
              title="¡Crédito en 30 segundos!"
              text="Contamos con el respaldo de Sistecrédito, con más de 20 años de experiencia otorgando créditos de consumo y más de 18.000 aliados que respaldan su servicio."
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <Info
              icon={<PercentIcon />}
              title="Créditos solo con la cédula"
              text="Comisiones súper bajitas, con esto cubrimos gastos de recaudo, riesgo de cartera perdida, inversiones de marketing y tecnología que nos permitirán seguir creciendo juntos."
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <Info
              icon={<LikeIcon />}
              title="85% de aprobación"
              text="Del crédito nos encargamos nosotros. Tu recibes tu pago a un máximo de 60 días mientras tus clientes pagan a su ritmo."
            />
          </Grid>
        </Grid>
      </div>
      <div className={classes.divCreditInfo}>
        <Grid container direction="row" justify="center" alingItems="alignItems">
          <Hidden smDown>
            <Grid item md={3}>
              <img
                src="https://luegopago.blob.core.windows.net/luegopago-uploads/images/Icons/banner-2.png"
                alt="prueba"
                className={classes.imgGirl}
              />
            </Grid>
          </Hidden>

          <Grid item md={4} xs={12} className={classes.divPayLater}>
            <Typography variant="h4" style={{ color: '#5e44ff' }}>
              Compra ahora, paga después
              <br />
              (Buy Now Pay Later)
            </Typography>
            <br />
            <Typography variant="body1">
              Uno de los formatos de
              <b> compra más populares a nivel mundial llega para marcar diferencia </b> y ganar el
              lujo de dinero para nuestros aliados y además se ajusta a las preferencias de crédito
              fácil y hábitos de compra de las nuevas generaciones.
            </Typography>
          </Grid>
        </Grid>
      </div>
      <div className={classes.divHowWorks}>
        <Grid container justify="flex-start" alignItems="center">
          <Grid item md={4} xs={12} className={classes.borderLeftBlue}>
            <Typography variant="h6" align="center" style={{ zIndex: 2, color: '#fff' }}>
              ¿Cómo funciona para tus clientes?
            </Typography>
          </Grid>
          <Grid item md={8} xs={12}>
            <Grid container justify="flex-start" alignItems="center">
              <Grid item md={4} xs={12}>
                <Info
                  center
                  number
                  icon={
                    <Typography variant="h4" style={{ color: '  #5e44ff' }} align="center">
                      1.
                    </Typography>
                  }
                  text={
                    <Typography variant="body1">
                      Se antojan de
                      <br />
                      sus productos favoritos
                    </Typography>
                  }
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <Info
                  center
                  number
                  icon={
                    <Typography variant="h4" style={{ color: '#337DF0 ' }} align="center">
                      2.
                    </Typography>
                  }
                  text={
                    <Typography variant="body1">
                      Si no tienen cupo de crédito lo aprobamos en 30 segundos
                    </Typography>
                  }
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <Info
                  center
                  number
                  icon={
                    <Typography variant="h4" style={{ color: '#2AA0C6' }} align="center">
                      3.
                    </Typography>
                  }
                  text={
                    <Typography variant="body1">
                      Pagan a 4 cuotas sin interés. Fácil y rápido
                    </Typography>
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
      <div className={classes.divHowConect}>
        <Grid container justify="flex-start">
          <Grid item md={3} sm={12} xs={12}>
            <Typography variant="h5">
              ¿Cómo conectamos tu marca con nuestros compradores?
            </Typography>
          </Grid>
          <Grid item md={9} sm={12} xs={12}>
            <Grid container direction="row" justify="center" className={classes.conectInfo}>
              <Grid item md={3} xs={12}>
                <Info
                  withFloat
                  icon={<SpeedIcon />}
                  text={
                    <Typography variant="body1">
                      <b>Más tráfico</b>
                      <br />
                      Aprovecha nuestra red de
                      <b> más de 3 millones </b>
                      de usuarios.
                    </Typography>
                  }
                />
              </Grid>
              <Grid item md={3} xs={12}>
                <Info
                  withFloat
                  icon={<CreditCardIcon />}
                  text={
                    <Typography variant="body1">
                      <b>Más opciones de libertad</b>
                      <br />
                      Otros
                      <b> medios de pago </b>
                      para que el cliente elija.
                    </Typography>
                  }
                />
              </Grid>
              <Grid item md={3} xs={12}>
                <Info
                  withFloat
                  icon={<IdentificationIcon />}
                  text={
                    <Typography variant="body1">
                      <b>Crédito sin fricción</b>
                      <br />
                      Solo con la cédula.
                      <b> 85% índice </b>
                      de aprobación.
                    </Typography>
                  }
                />
              </Grid>
              <Grid item md={3} xs={12}>
                <Info
                  withFloat
                  icon={<LoopIcon />}
                  text={
                    <Typography variant="body1">
                      <b>Venta cruzada</b>
                      <br />
                      Un gran ecosistema digital con
                      <b> múltiples categorías </b>
                      para enamorar tus clientes.
                    </Typography>
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
      <div className={classes.secondForm}>
        <Grid container direction="row" justify="center" spacing={2}>
          <Grid item md={2} xs={12}>
            <Typography variant="body1" style={{ fontSize: 24 }}>
              Comienza tu la experiencia
              <b> Luegopago</b>
            </Typography>
          </Grid>
          <Grid item md={3} xs={12}>
            <InputLabel error={errors.storeEmail} style={{ marginBottom: 5 }}>
              Danos tu correo electrónico
            </InputLabel>
            <TextField
              name="storeEmail"
              fullWidth
              variant="outlined"
              error={errors.storeEmail}
              inputRef={register({
                required: 'Required',
                pattern:
                  /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i,
              })}
              placeholder="micorreo@micorreo.com"
              InputProps={{
                className: classes.inputWhite,
                startAdornment: (
                  <InputAdornment position="start">
                    <EmailTextFieldIcon />
                  </InputAdornment>
                ),
              }}
              helperText={errors.storeEmail && 'Ingrese un correo válido'}
              onChange={(event) => {
                setPostSendEmailRegister(event.target.value.trimStart())
              }}
              value={postSendEmailRegister}
            />
            <FormGroup className={classes.formInput}>
              <FormControlLabel
                className={classes.formInputLabel}
                control={
                  <Checkbox
                    checked={checkAcceptance}
                    onChange={handleChange}
                    id="check_acceptance"
                    name="checkedA"
                  />
                }
                label={<Typography>Aceptación de tratamiento de datos</Typography>}
              />
            </FormGroup>
            <Grid className={classes.wrapInput}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                disabled={isLoading || !checkAcceptance || postSendEmailRegister === ''}
                type="submit"
                className={classes.btnAceptarSecond}
                onClick={onSubmit}
              >
                Empieza a vender
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </div>
      <div className={classes.footer}>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={2}
        >
          <Grid item xs={10} md={3}>
            <Link target="_blank" className={classes.white} href="https://luegopago.com/">
              <LuegopagoIconWhite footer />
            </Link>

            <div style={{ marginTop: '5%' }}>
              <Link target="_blank" className={classes.white} href="https://www.sistecredito.com/">
                <SistecreditoIconWhite />
              </Link>
            </div>
          </Grid>
          <Grid item xs={6} md={3}>
            <Typography variant="subtitle2" className={classes.white}>
              <b>Acerca de</b>
            </Typography>
            <div style={{ marginTop: '2%' }}>
              <Link className={classes.white} href="https://luegopago.com/about-us" target="_blank">
                LuegopaGO
              </Link>
              <br />
              <Link className={classes.white} href="https://luegopago.com/contact" target="_blank">
                Contacto
              </Link>
              <br />
              <Link className={classes.white} href="https://luegopago.com/join-us" target="_blank">
                Trabaja con nosotros
              </Link>
            </div>
          </Grid>
          <Grid item xs={6} md={3}>
            <Typography variant="subtitle2" className={classes.white}>
              <b>Ayuda / PQR</b>
            </Typography>
            <div style={{ marginTop: '2%' }}>
              <Link
                className={classes.white}
                href="https://luegopago.com/buy-now-pay-later"
                target="_blank"
              >
                ¿Cómo comprar?
              </Link>
              <br />
              <Link className={classes.white} href="https://luegopago.com/faq" target="_blank">
                Preguntas frecuentes
              </Link>
              <br />
              <Link className={classes.white} href="https://luegopago.com/help" target="_blank">
                Sugerencias y reclamos
              </Link>
              <br />
              <Link
                className={classes.white}
                href="https://luegopago.com/withdrawal"
                target="_blank"
              >
                Derecho de retracto
              </Link>
            </div>
          </Grid>
          <Grid item xs={12} md={3}>
            <Typography variant="subtitle2" className={classes.white}>
              <b>Redes sociales</b>
            </Typography>
            <div style={{ marginTop: '2%' }}>
              <Link
                className={classes.white}
                href="https://www.instagram.com/luegopagocol/"
                target="_blank"
              >
                Instagram
              </Link>
              <br />
              <Link
                className={classes.white}
                href="https://www.facebook.com/luegopagocol.col"
                target="_blank"
              >
                Facebook
              </Link>
              <br />
              <Link className={classes.white} href="https://twitter.com/luegopago" target="_blank">
                Twitter
              </Link>
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  )
}

export default RegisterStore
