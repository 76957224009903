import React, { useState, useEffect } from 'react'

import { Grid, Box, Divider, Card, Typography } from '@material-ui/core'

import useStyles from '../ReportsAccountStyle'

import convertToCurrencyCop from 'utils/convertToCurrencyCop'
import { getMonthDate } from 'utils/utils'

const InitialSections = ({ data }) => {
  const { periodGeneralBalance, initialBalance } = data
  const [month, setMonth] = useState(getMonthDate())
  // useEffect(() => {
  //   data.initialBalance = 98777
  // }, [data])
  const classes = useStyles()
  const getActualStatus = () => {
    switch (periodGeneralBalance?.status) {
      case 'Procesando':
        return (
          <Typography variant="body2" className={classes.greyTextStatus}>
            {periodGeneralBalance?.status}
          </Typography>
        )
      case 'Pendiente por pago':
        return (
          <Typography variant="body2" className={classes.warningTextStatus}>
            {periodGeneralBalance?.status || ''}
          </Typography>
        )
      case 'Pagado':
        return (
          <Typography variant="body2" className={classes.successTextStatus}>
            {periodGeneralBalance?.status || ''}
          </Typography>
        )

      default:
        return <Typography variant="body2">{periodGeneralBalance?.status || ''}</Typography>
    }
  }

  return (
    <>
      <Card className={classes.TopPosition}>
        <Grid container justify="flex-start" alignItems="flex-start">
          <Grid
            container
            direction="column"
            justify="flex-start"
            alignItems="baseline"
            className={classes.widthPeriod}
          >
            <Typography variant="subtitle1">Periodo</Typography>
            <Typography variant="body1">
              {`
                ${new Date(periodGeneralBalance?.startDatePeriod).getDate()}
                ${month[new Date(periodGeneralBalance?.startDatePeriod).getMonth()]} 
                ${new Date(periodGeneralBalance?.startDatePeriod).getFullYear()}
                - 
                ${new Date(periodGeneralBalance?.endDatePeriod).getDate() || ''}
                ${month[new Date(periodGeneralBalance?.endDatePeriod).getMonth()]} 
                ${new Date(periodGeneralBalance?.endDatePeriod).getFullYear()}`}
              {/* {`${data?.startDatePeriod || ''} - ${data?.endDatePeriod || ''}`} */}
            </Typography>
          </Grid>
          <Grid
            container
            direction="column"
            justify="flex-start"
            alignItems="baseline"
            className={classes.widthPeriod}
          >
            <Typography variant="subtitle1">Estado</Typography>
            <Typography
              variant="body1"
              className={
                periodGeneralBalance?.status === 'Abierto'
                  ? classes.greyTextStatus
                  : periodGeneralBalance?.status === 'Pagado'
                  ? classes.successTextStatus
                  : periodGeneralBalance?.status === 'Pendiente de pago'
                  ? classes.warningTextStatus
                  : null
              }
            >
              {periodGeneralBalance?.status}
            </Typography>
          </Grid>
        </Grid>
      </Card>
      <Grid
        container
        direction="column"
        justify="flex-start"
        alignItems="flex-start"
        className={classes.marginGrid}
      >
        <Typography variant="subtitle2" className={classes.widthInitialBalance}>
          Balance inicial
        </Typography>
        <Grid
          container
          justify="space-between"
          alignItems="flex-start"
          className={classes.InitialBalancePadding}
        >
          <Typography align="left" className={classes.widthCloseInitialBalance}>
            Cierre negativo de estados de cuenta anteriores
          </Typography>
          <Typography align="right" className={classes.widthValueInitialBalance}>
            {`$${convertToCurrencyCop(Number(initialBalance || 0))} COP`}
          </Typography>
          <Divider />
        </Grid>

        <Grid container direction="row" justify="flex-end" alignItems="flex-start">
          <Typography variant="subtitle1">Subtotal</Typography>
          <Typography align="right" className={classes.widthValueInitialBalance}>
            {`$${convertToCurrencyCop(Number(initialBalance || 0))} COP`}
          </Typography>
        </Grid>
      </Grid>
    </>
  )
}
export default InitialSections
