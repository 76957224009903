/* eslint-disable react/prop-types */
import React, { useEffect } from 'react'

import { Backdrop } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'

import useStyles from './BackDrop.styles'

function BackDrop({ open, successful, error }) {
  const waitIcon = (
    <img
      src="https://luegopago.blob.core.windows.net/luegopago-uploads/Soat/images/manito-loop.gif"
      alt="loading"
      style={{ margin: '0 auto' }}
      width={90}
      height={90}
    />
  )
  const successfulIcon = (
    <img
      src="https://luegopago.blob.core.windows.net/luegopago-uploads/PaymentMethods/Images/checked.svg"
      alt="loading"
      style={{ margin: '0 auto' }}
      width={90}
      height={90}
    />
  )
  const errorIcon = (
    <img
      src="https://luegopago.blob.core.windows.net/luegopago-uploads/PaymentMethods/Images/error-rechazado.svg"
      alt="loading"
      style={{ margin: '0 auto' }}
      width={90}
      height={90}
    />
  )

  const classes = useStyles()
  return (
    <Backdrop className={classes.backdrop} open={open}>
      <div
        style={{
          width: '100%',
          marginLeft: '50%',
          transform: 'translateX(-50%)',
          textAlign: 'center',
        }}
      >
        {successful !== undefined && successfulIcon}
        {error !== undefined && errorIcon}
        {successful === undefined && error === undefined && waitIcon}
        <Typography variant="h6" style={{ color: 'white' }} gutterBottom>
          {successful !== undefined
            ? successful
            : `${error !== undefined ? error : 'Cargando . . .'}`}
        </Typography>
      </div>
    </Backdrop>
  )
}

Backdrop.propTypes = {
  open: PropTypes.bool.isRequired,
}

export default BackDrop
