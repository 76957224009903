import axios from 'axios'

import { API_ORDERS } from 'constants/urls'
import { translateNameToFilterId } from 'utils/filterState'

// eslint-disable-next-line import/prefer-default-export
export const OrderPdfList = async (
  allOrdersSelectedNum,
  setAllOrdersSelectedNum,
  setAllRowsSelectedContext,
  setIsLoadingBackDrop,
  showDialogAlert
) => {
  const tempOrder = allOrdersSelectedNum.map((order) => {
    return order.pedido
  })
  await axios
    .post(
      `${API_ORDERS}/OrderPdfList`,
      {
        type: 2,
        orderIdList: tempOrder,
      },
      {
        responseType: 'blob',
      }
    )
    .then((response) => {
      if (response.status === 200) {
        const link = document.createElement('a')
        const blobTemp = new Blob([response.data], {
          type: 'application/pdf',
          name: `Ordenes${Date.now()}.pdf`,
        })
        blobTemp.name = `Ordenes${Date.now()}.pdf`
        link.href = window.URL.createObjectURL(blobTemp)
        link.target = '_blank'
        document.body.appendChild(link)
        link.click()
        setAllOrdersSelectedNum([])
        setAllRowsSelectedContext(null)
      }
      setIsLoadingBackDrop(false)
    })
    .catch((error) => {
      setAllOrdersSelectedNum([])
      setAllRowsSelectedContext([])
      setIsLoadingBackDrop(false)
      if (error.response.status === 400) {
        showDialogAlert(true, '', 'No se encuentra información para descargar', 'Cerrar')
      }
    })
}
// eslint-disable-next-line import/prefer-default-export
export const PrintOrders = async (
  currentFilterOrders,
  setIsLoadingBackDrop,
  allOrdersSelectedNum,
  setAllOrdersSelectedNum,
  setAllRowsSelectedContext,
  apiString
) => {
  const status = translateNameToFilterId(currentFilterOrders)
  const typeFilter = Number.isInteger(status) ? status : translateNameToFilterId(status)
  setIsLoadingBackDrop(true)
  const tempOrder = allOrdersSelectedNum.map((order) => {
    return order?.pedido
  })
  await axios
    .post(
      `${API_ORDERS}/${apiString}`,
      {
        type: typeFilter,
        orderIdList: tempOrder,
      },
      { responseType: 'blob' }
    )
    .then((response) => {
      setAllOrdersSelectedNum([])
      setAllRowsSelectedContext([])
      if (response.status === 200) {
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(new Blob([response.data], { type: 'text/xlsx' }))
        link.setAttribute('download', `Pedidos${Date.now()}.xlsx`)
        document.body.appendChild(link)
        link.click()
      }

      setIsLoadingBackDrop(false)
    })
    .catch(() => {
      setAllOrdersSelectedNum([])
      setIsLoadingBackDrop(false)
    })
}

export const PrintOrdersSelectedGuide = async (
  currentFilterOrders,
  setIsLoadingBackDrop,
  allOrdersSelectedNum,
  setAllOrdersSelectedNum,
  setAllRowsSelectedContext
) => {
  const status = translateNameToFilterId(currentFilterOrders)
  const typeFilter = Number.isInteger(status) ? status : translateNameToFilterId(status)
  setIsLoadingBackDrop(true)
  const tempOrder = allOrdersSelectedNum.map((order) => {
    return order.props.children
  })
  await axios
    .post(
      `${API_ORDERS}/PrintOrdersSelectedGuide`,
      {
        type: typeFilter,
        orderIdList: tempOrder,
      },
      { responseType: 'blob' }
    )
    .then((response) => {
      setAllOrdersSelectedNum([])
      setAllRowsSelectedContext([])
      if (response.status === 200) {
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(new Blob([response.data], { type: 'text/xlsx' }))
        link.setAttribute('download', `Pedidos${Date.now()}.xlsx`)
        document.body.appendChild(link)
        link.click()
      }

      setIsLoadingBackDrop(false)
    })
    .catch(() => {
      setAllOrdersSelectedNum([])
      setIsLoadingBackDrop(false)
    })
}
