import { useContext } from 'react'

import PropTypes from 'prop-types'

import useAuthContext from 'contextApi/AuthContext'

const useCustomDialogAlert = () => {
  const { dialogAlert, setDialogAlert } = useContext(useAuthContext)

  const showDialogAlert = (
    open = false,
    title = '',
    message = '',
    textButtonConfirm = '',
    textButtonCancel = '',
    successful = false,
    actionConfirm = null,
    actionCancel = null,
    personalized = false,
    children = null,
    handleClose = null
  ) => {
    setDialogAlert({
      ...dialogAlert,
      open: open,
      title: title,
      handleClose: handleClose,
      message: message,
      personalized: personalized,
      textButtonConfirm: textButtonConfirm,
      actionConfirm: actionConfirm,
      actionCancel: actionCancel,
      textButtonCancel: textButtonCancel,
      children: children,
      successful: successful,
    })
  }

  return showDialogAlert
}
useCustomDialogAlert.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  message: PropTypes.string,
  textButtonConfirm: PropTypes.string,
  actionConfirm: PropTypes.func,
  textButtonCancel: PropTypes.string,
  personalized: PropTypes.bool,
}

useCustomDialogAlert.defaultProps = {
  message: undefined,
  textButtonConfirm: undefined,
  actionConfirm: undefined,
  textButtonCancel: undefined,
  personalized: null,
}
export default useCustomDialogAlert
