import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(() => ({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    display: 'block',
    padding: '0 5px',
    backgroundColor: '#ffffff',
    position: 'relative',
    zIndex: 2,
    marginBottom: 7,
  },
  margin: {
    marginBottom: 12,
  },
  declared: {
    marginTop: 5,
  },
  titleShipping: {
    textAlign: 'left',
    color: '#0D0C0E',
    font: 'normal normal bold 16px/25px Poppins',
  },
  infoTarif: {
    textAlign: 'left',
    fontWeight: 'normal',
    textTransform: 'none',
    marginTop: 10,
  },
  gridInfo: {
    marginTop: 15,
    marginBottom: 15,
  },
  spanTarif: {
    color: 'black',
    fontWeight: 500,
  },
  categoryPrimary: {
    display: 'block',
    padding: '0 1%',
    position: 'relative',
    zIndex: 2,
  },
  containerGrid: {
    width: '100%',
    position: 'relative',
    margin: '0 0 2%',
    '&:after': {
      content: '" "',
      left: 0,
      dropRight: 0,
      width: '90%',
      height: '2px',
      border: 0,
      borderBottom: '1px dashed black',
      position: 'absolute',
      bottom: '15px',
    },
  },
}))
