import React from 'react'

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  tooltipContent: {
    display: 'none',
    zIndex: 10,
    marginLeft: '5px',
    position: 'absolute',
    width: '250px',
    minHeight: '100px',
    right: '-265px',
    top: '96px',
    transform: 'translateY(-50%)',
    backgroundColor: '#fff',
    boxShadow: '0px 3px 6px #0000001a',
    padding: '20px 15px 0 15px',
    borderRadius: '8px',
    '@media (min-width: 1080px)': {
      display: 'block',
    },
    '&:before': {
      content: '""',
      position: 'absolute',
      top: '20%',
      right: '100%',
      marginTop: '-5px',
      borderWidth: '8px',
      borderStyle: 'solid',
      borderColor: 'transparent #0000001a transparent transparent',
    },
  },
}))

const TooltipErrorImages = ({ children }) => {
  const classes = useStyles()

  return <div className={classes.tooltipContent}>{children}</div>
}

export default TooltipErrorImages
