import { makeStyles } from '@material-ui/core/styles'

import { whiteColor } from 'assets/jss/material-dashboard-pro-react'
import buttonStyle from 'assets/jss/material-dashboard-pro-react/components/buttonStyle'
import switchStyles from 'assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch'

export default makeStyles((theme) => ({
  ...switchStyles,
  root: {
    flexGrow: 1,
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  formControl: {
    margin: theme.spacing(1),
    width: '200px ',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  inputBorder: {
    borderColor: '#FF0000 !important',
    borderWidth: '1px !important',
  },
  formPop: {
    cursor: 'pointer',
    textDecoration: 'underline',
  },
  formDeclaration: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  button: {
    margin: theme.spacing(1),
  },
  buttonAction: {
    marginLeft: 10,
  },
  ...buttonStyle,
  chip: {
    marginLeft: 30,
  },
  card: {
    minHeight: 100,
    padding: 20,
  },
  alert: { position: 'fixed', zIndex: 1, marginBottom: 20 },
  gridList: {
    flexWrap: 'nowrap',
    width: '100%',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
  },
  gridListIndividual: {
    width: '100%',
    // height: 450,
  },
  iconDelete: {
    color: 'rgba(244, 67, 54)',
  },
  iconAdd: {
    marginTop: '10%',
  },
  rootList: {
    width: '850px',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    backgroundColor: theme.palette.background.paper,
  },
  rootListIndividual: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  input: {
    display: 'none',
  },
  imageProduct: {
    width: '150px',
    marginLeft: '25%',
  },
  cardList: {
    flexWrap: 'nowrap',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
  },
  imageTitle: {
    color: 'white',
  },
  inputTop: {
    marginTop: '20px',
  },
  tableContainer: {
    width: 'auto',
  },
  center: {
    margin: `${theme.spacing(1)}px auto`,
  },
  margin: {
    margin: theme.spacing(1),
  },
  divTitle: {
    borderBottom: '1px solid #949494',
    paddingBottom: '1px',
    marginBottom: '30px',
    webkitBoxShadow: '0px 2px 5px 1px rgba(0,0,0,0.36)',
    mozBoxShadow: '0px 2px 5px 1px rgba(0,0,0,0.36)',
    boxShadow: '0px 2px 5px -2px rgba(0,0,0,0.36)',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  btnGuardar: {
    color: whiteColor,
  },
  TopPosition: {
    marginTop: 20,
  },
  inputsManager: {
    '& .MuiInputBase-root': {
      height: theme.spacing(5),
      color: theme.palette.white.main,
    },
  },
  btnBackgroundPrimary: {
    width: '248px',
    height: '56px',
    borderRadius: '50px',
    border: 'none',
    backgroundColor: '#5e44ff',
    color: 'white',
    fontWeight: '500',
    '&:hover': {
      fontWeight: 'bolder',
      backgroundColor: '#ef0bef',
      boxShadow: '-7px 7px 0px #000000',
      border: '2px solid #000000',
      borderRadius: '50px',
      opacity: '1',
    },
  },
  btnBackgroundError: {
    left: '2%',
    background: theme.palette.error.main,
    height: 35,
    borderRadius: 6,
    color: theme.palette.white.main,

    '&:hover': {
      background: theme.palette.error.main,
    },
  },
  buttonCancel: {
    width: '248px',
    height: '56px',
    borderRadius: '50px',
    border: '1px solid #6064ff',
    color: '#6064ff',
    fontWeight: '500',
    '&:hover': {
      color: '#FF40FC',
      border: '1px solid #FF40FC',
    },
  },
  buttons: {
    width: '248px',
    height: '56px',
    borderRadius: '50px',
    border: 'none',
    backgroundColor: '#5e44ff',
    color: 'white',
    fontWeight: '500',
    '&:hover': {
      fontWeight: 'bolder',
      backgroundColor: '#ef0bef',
      boxShadow: '-7px 7px 0px #000000',
      border: '2px solid #000000',
      borderRadius: '50px',
      opacity: '1',
    },
  },
  buttonHoverRed: {
    minWidth: 180,

    '&:hover': {
      background: theme.palette.error.main,
    },
  },
  divButtonsGeneral: {
    marginTop: 40,
    columnGap: 20,
  },
  divTermsAndConditions: {
    marginLeft: 20,
    marginTop: 10,
  },
  textLink: {
    textDecoration: 'underline',

    '&:hover': {
      color: theme.palette.primary.main,
      cursor: 'pointer',
    },
  },
  container: {
    gap: '45px',
  },
  gridCertificateBank: {
    gap: '15px',
  },
  divContent: {
    paddingLeft: 30,
  },
  btnNewUser: {
    width: '248px',
    height: '56px',
    borderRadius: '50px',
    border: 'none',
    backgroundColor: '#5e44ff',
    color: 'white',
    fontWeight: '500',
    '&:hover': {
      fontWeight: 'bolder',
      backgroundColor: '#ef0bef',
      boxShadow: '-7px 7px 0px #000000',
      border: '2px solid #000000',
      borderRadius: '50px',
      opacity: '1',
    },
  },
  iconButtons: {
    width: 50,
    height: 28,
  },
  labelInputTop: {
    marginBottom: 6,
  },
  textFileToUpload: {
    marginLeft: '1%',
    marginTop: '1%',
  },
  btnDelete: {
    '&:hover': {
      background: theme.palette.error.main,
      fill: theme.palette.white.main,
    },
  },
  btnEdit: {
    '&:hover': {
      background: theme.palette.primary.main,
      fill: theme.palette.white.main,
    },
  },
  spanStrong: {
    fontWeight: 700,
    margin: '0 3px',
  },
  marginField: {
    marginBottom: '1.5rem',
  },
}))
