/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'

import {
  Button,
  DialogActions,
  Grid,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
} from '@material-ui/core'
import { useForm } from 'react-hook-form'

import InputField from 'components/InputField'
import { VOUCHER } from 'constants/rolesConst'
import useStyles from 'views/Products/products.styles'

const MultiInfoStep = ({ discountTypes, handleBack, handleSubmitStep, info, sellerInfo }) => {
  const classes = useStyles()
  const [discountType, setDiscountType] = useState(null)
  const {
    register,
    handleSubmit,
    setValue,
    trigger,
    formState: { isValid, errors },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      ...info,
      percentLuegopago: Number(100),
    },
  })

  const onSubmit = (data) => {
    const couponValue = Number(data.couponValue)
    const quantity = Number(data.quantity)
    handleSubmitStep({
      ...data,
      couponValue,
      discountType,
      quantity,
      percentLuegopago: Number(data.percentLuegopago),
    })
  }

  useEffect(() => {
    if (info.discountType) {
      setDiscountType(() => info.discountType)
    }
    if (sellerInfo.role === VOUCHER) {
      setDiscountType(discountTypes.find((item) => item.uniqueId === 1).id)
    }
  }, [])

  useEffect(() => {
    if (discountType && sellerInfo.role !== VOUCHER) {
      trigger('couponValue')
    }
  }, [discountType])

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <InputField
          label="Cantidad"
          name="quantity"
          type="number"
          error={errors?.quantity && errors.quantity.message}
          ref={register({
            validate: {
              isNumeric: (value) =>
                (!Number.isNaN(value) && /[0-9]+/.test(value)) || 'Debe contener un valor numérico',
              isPositive: (value) => value > 1 || 'Debe ser mayor a 1',
            },
          })}
        />
        <Grid container direction="row" spacing={3}>
          <Grid item xs={6}>
            <InputField
              label="Descripción"
              name="couponName"
              error={errors?.couponName && errors.couponName.message}
              ref={register({ required: 'El nombre del cupón es requerido' })}
            />
          </Grid>
          <Grid item xs={6}>
            <InputField
              label="Valor asumido por Luegopago"
              error={errors.percentLuegopago && errors.percentLuegopago.message}
            >
              <OutlinedInput
                name="percentLuegopago"
                endAdornment={<InputAdornment position="start">%</InputAdornment>}
                type="number"
                inputRef={register({
                  validate: {
                    required: (value) => value >= 0 || 'El valor asumido es requerido',
                    between: (value) => {
                      return (
                        (value >= 0 && value <= 100) || 'El valor asumido debe estar entre 0 y 100%'
                      )
                    },
                  },
                })}
              />
            </InputField>
          </Grid>
        </Grid>
        <Grid container direction="row" spacing={3}>
          <Grid item xs={6} style={{ paddingRight: '12px' }}>
            <InputField label="Tipo de descuento">
              <Select
                name="discountType"
                value={discountType}
                disabled={sellerInfo.role === VOUCHER}
                variant="outlined"
                onChange={(event) => {
                  setDiscountType(event.target.value)
                  setValue('couponValue', null)
                  trigger('couponValue')
                }}
                fullWidth
              >
                {discountTypes.map((type) => (
                  <MenuItem key={type.id} value={type.id}>
                    {type.name}
                  </MenuItem>
                ))}
              </Select>
            </InputField>
          </Grid>
          <Grid item xs={6}>
            <InputField
              label="Valor de descuento"
              error={errors?.couponValue && errors.couponValue.message}
            >
              <OutlinedInput
                startAdornment={
                  discountType === 3 && <InputAdornment position="start">$</InputAdornment>
                }
                name="couponValue"
                disabled={!discountType}
                error={errors?.couponValue && errors.couponValue.message}
                placeholder={!discountType && 'Seleccione un tipo de descuento'}
                endAdornment={
                  discountType === 4 && <InputAdornment position="start">%</InputAdornment>
                }
                inputRef={register({
                  validate: {
                    required: (value) => value > 0 || 'El valor de descuento es requerido',
                    between: (value) => {
                      if (discountType === 3) {
                        return value > 0 || 'El valor de descuento debe ser mayor a 0'
                      }
                      return (
                        (value >= 0 && value <= 100) ||
                        'El valor de descuento debe estar entre 0 y 100%'
                      )
                    },
                  },
                })}
                type="number"
              />
            </InputField>
          </Grid>
        </Grid>
        <DialogActions>
          <Button onClick={handleBack} disabled className={classes.btnBackgroundErrorPriceSmall}>
            Atrás
          </Button>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            className={classes.btnNewProductsmall}
            disabled={!isValid}
          >
            Siguiente
          </Button>
        </DialogActions>
      </form>
    </div>
  )
}

export default MultiInfoStep
