import React, { useEffect, useRef, useState } from 'react'
// nodejs library to set properties for components
// @material-ui/core components
import AppBar from '@material-ui/core/AppBar'
import Hidden from '@material-ui/core/Hidden'
import { makeStyles } from '@material-ui/core/styles'
import Toolbar from '@material-ui/core/Toolbar'
// material-ui icons
import Menu from '@material-ui/icons/Menu'
import MoreVert from '@material-ui/icons/MoreVert'
import ViewList from '@material-ui/icons/ViewList'
import cx from 'classnames'
import PropTypes from 'prop-types'
// core components
import AdminNavbarLinks from './AdminNavbarLinks'
import defaultAvatar from 'assets/img/default-avatar.png'
import styles from 'assets/jss/material-dashboard-pro-react/components/adminNavbarStyle'
import Button from 'components/CustomButtons/Button'
const useStyles = makeStyles(styles)

export default function AdminNavbar(props) {
  const [img, setImg] = useState(defaultAvatar)
  const divRef = useRef(null)
  const classes = useStyles()
  const { color, brandText, handleDrawerToggle, contextPrueba } = props
  const appBarClasses = cx({
    [` ${classes[color]}`]: color,
  })

  useEffect(() => {
    setImg(props.contextPrueba.picture ? props.contextPrueba.picture : defaultAvatar)
  }, [contextPrueba.picture])

  const { sidebarMinimize } = classes

  return (
    <AppBar className={classes.appBar + appBarClasses}>
      <Toolbar className={classes.container}>
        <div className={classes.flex} ref={divRef}>
          {/* Here we create navbar brand, based on route name */}
          <Button href="#" className={classes.title} color="transparent">
            {brandText}
          </Button>
        </div>
        <Hidden smDown implementation="css">
          <AdminNavbarLinks imagenContext={img} />
        </Hidden>
        <Hidden mdUp implementation="css">
          <Button
            className={classes.appResponsive}
            color="transparent"
            justIcon
            aria-label="open drawer"
            onClick={handleDrawerToggle}
          >
            <Menu />
          </Button>
        </Hidden>
      </Toolbar>
    </AppBar>
  )
}
AdminNavbar.propTypes = {
  color: PropTypes.oneOf(['primary', 'info', 'success', 'warning', 'danger', 'black']).isRequired,
  brandText: PropTypes.string.isRequired,
}
