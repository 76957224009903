import axios from 'axios'

import {
  PAYMENTS_API_CREATE_TOKEN,
  PAYMENTS_API_GET_METHODS_PLATFORM,
  PAYMENTS_API_CREATE_NEW_PLATFORM,
  PAYMENTS_API_UPDATE_METHODS_PLATFORM,
} from 'constants/urls'

export const refreshToken = () => {
  return axios.post(PAYMENTS_API_CREATE_TOKEN, null, {
    headers: {},
  })
}

export const getToken = () => {
  return localStorage.getItem('luegopago_data_token')
}

export const getEnabledMethodsPlatform = (platform) => {
  return axios.get(PAYMENTS_API_GET_METHODS_PLATFORM, { params: platform, headers: {} })
}

export const createPlatformNew = (newPlatform) => {
  return axios.post(PAYMENTS_API_CREATE_NEW_PLATFORM, newPlatform, {
    headers: {},
  })
}

export const updateMethodStateService = (updatePlatform) => {
  return axios.put(PAYMENTS_API_UPDATE_METHODS_PLATFORM, updatePlatform, { headers: {} })
}
