import React from 'react'

import { Grid, Box, Divider } from '@material-ui/core'
import PropTypes from 'prop-types'

import useStyles from './ReportsAccountStyle'
import InitialSections from './sections/InitialSections'
import OrdersSection from './sections/OrdersSection'
import Payment from './sections/Payment'
import Refund from './sections/Refund'
import SectionOthers from './sections/SectionOthers'
import Stocktaking from './sections/Stocktaking'

const BalancesSection = ({ data }) => {
  return (
    <>
      <Grid item md={5} style={{ minWidth: '48%', marginLeft: '2vh' }}>
        <InitialSections data={data} />
        <OrdersSection dataOrders={data} />
        <Refund dataOrders={data} />
        <SectionOthers dataOrders={data} />
        <Stocktaking dataOrders={data} />
        <Payment dataOrders={data} />
      </Grid>
    </>
  )
}
export default BalancesSection
