import React, { useEffect, useState } from 'react'

import { Button, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import axios from 'axios'
import { useHistory, useParams } from 'react-router-dom'

import styles from 'assets/jss/material-dashboard-pro-react/views/loginPageStyle'
import BackDrop from 'components/BackDrop'
import Card from 'components/Card/Card'
import CardBody from 'components/Card/CardBody'
import CardFooter from 'components/Card/CardFooter'
import GridItem from 'components/Grid/GridItem'
import { USER_CONFIRM_ACCOUNT } from 'constants/urls'

const useStyles = makeStyles(styles)

export default function ConfirmUser() {
  const { userEmail, code } = useParams()
  const [isLoading, setIsLoading] = useState(false)
  const [textCode, setTextCode] = useState('')
  const [codeValidate, setCodeValidate] = useState(false)

  const history = useHistory()


  const validateCode = async () => {
    setIsLoading(true)
    await axios
      .post(`${USER_CONFIRM_ACCOUNT}`, {
        code: code,
        email: userEmail,
      })
      .then((response) => {
        if (response.status === 200) {
          setCodeValidate(true)
          if (response.data.data) {
            setTextCode('Su cuenta ha sido activada sastifactoriamente.')
          } else {
            setTextCode('Su cuenta ya ha sido activada con anterioridad.')
          }
        }

        setIsLoading(false)
      })
      .catch((e) => {
        setIsLoading(false)
        setTextCode(`${e.response.data.errors}, comuniquese con el administrador.`)
        setCodeValidate(false)
        console.log(e)
      })
  }

  const goToLogin = () => {
    history.push('/login')
  }

  useEffect(() => {
    validateCode()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const classes = useStyles()
  return (
    <div className={classes.container}>
      <BackDrop open={isLoading} />

      <GridItem xs={12} sm={6} md={4}>
        {/* <form onSubmit={onSubmit}> */}
        <Card login className={classes.CardBodyCode}>
          <CardBody>
            <Typography variant="h4" align="center" gutterBottom>
              {textCode}
            </Typography>
          </CardBody>
          <CardFooter className={classes.justifyContentCenter}>
            <div className={classes.wrapper}>
              {codeValidate ? (
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={goToLogin}
                  className={classes.btnAccept}
                  type="submit"
                >
                  Aceptar
                </Button>
              ) : null}

              {/* {loading && <CircularProgress size={24} className={classes.buttonProgress} />} */}
            </div>
          </CardFooter>
        </Card>
        {/* </form> */}
      </GridItem>
    </div>
  )
}
