import React, { useState, useEffect, useRef } from 'react'

import {
  Grid,
  FormControl,
  FormControlLabel,
  InputLabel,
  TextField,
  Typography,
  Button,
  Checkbox,
  FormHelperText,
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import CurrencyTextField from '@unicef/material-ui-currency-textfield'
import axios from 'axios'
/** recaptcha */
import { useForm, Controller } from 'react-hook-form'

import styles from '../bonuses.styles'

import DialogAlert from 'components/DialogAlert'
import { API_SELLERS } from 'constants/urls'
import { onKeyPressJustNumbers } from 'utils/utils'

const BonusForm = ({ setIsLoading, showPaymentMethods, setData, data }) => {
  const classes = styles()
  const [aceptTerms, setAceptTerms] = useState(false)
  const [identificationTypes, setIdentificationsTypes] = useState([])
  const [identificationTypeSelected, setIdentificationTypeSelected] = useState(null)
  const [isNotRobot, setIsNotRobot] = useState(null)
  const [dialogAlertChange, setDialogAlertChange] = useState({
    open: false,
    title: '',
    message: '',
    actionConfirm: null,
    textButtonConfirm: '',
    textButtonCancel: '',
  })
  const { register, handleSubmit, errors, control, setValue, clearErrors } = useForm({
    mode: 'onChange',
  })
  /** References */
  const captcha = useRef(null)

  const defaultPropsIdentificationTypes = {
    options: identificationTypes,
    getOptionLabel: (option) => (option.name === null ? '' : option.name),
  }
  const getIdentificationTypes = async () => {
    setIsLoading(true)
    axios
      .get(`${API_SELLERS}/SellerFormOptions`)
      .then(function (response) {
        setIdentificationsTypes(response.data.identificationTypes)
        setIsLoading(false)
      })
      .catch(function (error) {
        // handle error
        setIsLoading(false)
        console.log(error)
      })
  }

  useEffect(() => {
    getIdentificationTypes()

    if (data?.email) {
      setValue('value', data.value)
      setValue('phone', data.phone)
      setValue('email', data.email)
      setValue('idDocument', data.idDocument)
      setValue('name', data.name)
      setValue('IsAuthorizeData', data.IsAuthorizeData)
    }
  }, [])

  const onChangeRecapcha = () => {
    if (captcha.current.getValue()) {
      setIsNotRobot(true)
    } else {
      setIsNotRobot(false)
    }
  }

  const onChangeValue = (val) => {
    setData({ ...data, value: val })
  }

  const getObjectCampo = (id, array) => {
    return array.find((object) => object.id === id)
  }

  const preventDefault = () => {}

  const onSubmit = async (dataForm) => {
    if (!isNotRobot) {
      setIsNotRobot(false)
    } else {
      const formatedValue = Number(dataForm?.value?.replace(/\D/g, ''))
      if (formatedValue > 79999) {
        const newDataform = { ...dataForm }
        newDataform.identificationType = identificationTypeSelected
        setData(newDataform)
        showPaymentMethods()
      } else {
        setDialogAlertChange({
          open: true,
          title: '¡Advertencia!',
          message: 'El precio debe ser mayor a $80.000',
          actionConfirm: null,
          textButtonConfirm: 'cerrar',
          textButtonCancel: null,
        })
      }
    }
  }
  const handleCloseDialogChange = () => {
    setDialogAlertChange({ ...dialogAlertChange, open: false })
  }

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
        <Grid
          className={classes.form}
          direction="Column"
          justify="space-between"
          alignItems="flex-start"
        >
          <Grid container justify="space-between" spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h5">Crea tu bono</Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel className={classes.topInputLabel} error={errors?.value}>
                Valor del bono
              </InputLabel>
              <Controller
                render={({ value, onChange }) => {
                  return (
                    <CurrencyTextField
                      value={value}
                      currencySymbol="$"
                      textAlign="left"
                      fullWidth
                      decimalPlaces={0}
                      outputFormat="number"
                      variant="outlined"
                      inputProps={{
                        inputProps: { min: 0, maxLength: 11 },
                      }}
                      onChange={(e) => {
                        onChangeValue(e.target.value)
                        onChange(e.target.value)
                      }}
                      error={errors?.value}
                      helperText={!errors?.value ? null : errors?.value.message}
                    />
                  )
                }}
                name="value"
                control={control}
                rules={{
                  required: 'Campo requerido',
                  min: 80000,
                }}
                value={data?.value}
                onChange={(val) => {
                  return val
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel className={classes.topInputLabel} error={errors?.phone}>
                Número de teléfono
              </InputLabel>
              <TextField
                name="phone"
                variant="outlined"
                fullWidth
                error={errors?.phone}
                inputRef={register({
                  required: 'Campo requerido',
                  pattern: {
                    value: /^([0-9]+(?:[\][0-9]*)?|\.[0-9]+)$/i,
                    message: 'Solo acepta números',
                  },
                  maxLength: {
                    value: 12,
                    message: 'Máximo 12 números',
                  },
                  minLength: {
                    value: 7,
                    message: 'Minimo 7 números',
                  },
                })}
                inputProps={{
                  maxLength: 12,
                  minLength: 7,
                }}
                onKeyPress={onKeyPressJustNumbers}
                helperText={errors?.phone?.message}
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel className={classes.topInputLabel} error={errors?.email}>
                Correo electrónico
              </InputLabel>
              <TextField
                name="email"
                fullWidth
                variant="outlined"
                error={errors?.email}
                inputRef={register({
                  required: 'Campo requerido',
                  pattern: {
                    value: /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i,
                    message: 'Ingrese un correo válido',
                  },
                })}
                inputProps={{
                  maxLength: 50,
                }}
                helperText={errors?.email?.message}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel error={!!errors?.identificationType} className={classes.topInputLabel}>
                Tipo de documento
              </InputLabel>
              <Autocomplete
                {...defaultPropsIdentificationTypes}
                id="identificationType"
                name="identificationType"
                className={classes.autocompleteMargin}
                helperText={errors?.identificationType?.message}
                onChange={(e, value) => {
                  if (e.target.value !== '') {
                    setValue(value.id)
                    setIdentificationTypeSelected(value.id)
                    clearErrors('identificationType')
                  }
                }}
                value={
                  data?.identificationType &&
                  getObjectCampo(data?.identificationType, identificationTypes)
                }
                renderInput={(params) => (
                  <TextField
                    id="identificationType"
                    name="identificationType"
                    {...params}
                    inputRef={register({
                      required: 'Campo requerido',
                    })}
                    error={!!errors?.identificationType}
                    placeholder="Seleccione..."
                    helperText={errors?.identificationType?.message}
                    margin="normal"
                    variant="outlined"
                    className={classes.autocompleteDetailProduct}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel className={classes.topInputLabel} error={errors?.idDocument}>
                Número de documento
              </InputLabel>
              <TextField
                name="idDocument"
                variant="outlined"
                fullWidth
                error={errors?.idDocument}
                inputRef={register({
                  required: {
                    value: true,
                    message: 'Este campo es requerido.',
                  },
                  minLength: {
                    value: 4,
                    message: 'Debe de tener un mínimo de 4 digitos.',
                  },
                  maxLength: {
                    value: 20,
                    message: 'Debe de tener un mínimo de 20 digitos.',
                  },
                  pattern: {
                    value: /^[a-zA-Z0-9\s#]*$/,
                    message: 'No se permiten caracteres especiales.',
                  },
                })}
                inputProps={{
                  maxLength: 20,
                  minLength: 4,
                }}
                onKeyPress={onKeyPressJustNumbers}
                helperText={errors?.idDocument?.message}
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel className={classes.topInputLabel} error={errors?.name}>
                Nombre Completo
              </InputLabel>
              <TextField
                name="name"
                fullWidth
                variant="outlined"
                error={errors?.name}
                inputRef={register({
                  required: 'Campo requerido',
                  pattern: {
                    value: /^[A-Za-z0-9-'0-9a-zÀ-ÿ,\s*/%$/.,&-+#!¡]+$/,
                    message: 'Ingrese una direccion válida',
                  },
                })}
                inputProps={{
                  maxLength: 50,
                }}
                helperText={errors?.name?.message}
                onPaste={preventDefault}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="IsAuthorizeData"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: 'Debes aceptar los términos y condiciones de servicio para continuar.',
                  },
                }}
                render={({ field }) => (
                  <FormControl error={!!errors.IsAuthorizeData}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          {...field}
                          onChange={(e) => {
                            setValue('IsAuthorizeData', e.target.checked)
                            setAceptTerms(e.target.checked)
                          }}
                          checked={aceptTerms}
                        />
                      }
                      label=" Acepto términos y condiciones del servicio."
                    />
                    <FormHelperText>{errors?.IsAuthorizeData?.message}</FormHelperText>
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} style={{ textAlign: 'end' }}>
              {isNotRobot === false && (
                <Typography variant="body1" color="error" gutterBottom>
                  Por favor completa el captcha
                </Typography>
              )}
              <Button
                color="secondary"
                type="submit"
                variant="contained"
                style={{ marginTop: '2%' }}
                className={classes.btnBackgroundPrimary}
              >
                Continuar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
      <DialogAlert
        open={dialogAlertChange.open}
        title={dialogAlertChange.title}
        handleClose={handleCloseDialogChange}
        message={dialogAlertChange.message}
        textButtonConfirm={dialogAlertChange.textButtonConfirm}
        actionConfirm={dialogAlertChange.actionConfirm}
        textButtonCancel={dialogAlertChange.textButtonCancel}
      />
    </div>
  )
}
export default BonusForm
