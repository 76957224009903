/* eslint-disable react/prop-types */
import React from 'react'

import { makeStyles } from '@material-ui/core'
import { SnackbarProvider } from 'notistack'

const useStyles = makeStyles(() => ({
  success: { backgroundColor: '#ABF29D !important', color: '#0D0C0E !important' }, // Se usa el important ya que es un issue de la libreria para mas info ver https://github.com/iamhosseindhv/notistack/issues/331
  warning: { backgroundColor: '#FFD700 !important', color: '#0D0C0E !important' },
  error: { backgroundColor: '#F35B69 !important', color: '#FFFFFF !important' },
  info: { backgroundColor: '#93B3DF !important', color: '#FFFFFF !important' },
}))

const NotificationsProvider = ({ children }) => {
  const classes = useStyles()

  return (
    <SnackbarProvider
      hideIconVariant={false}
      maxSnack={4}
      classes={{
        variantSuccess: classes.success,
        variantWarning: classes.warning,
        variantError: classes.error,
        variantInfo: classes.info,
      }}
    >
      {children}
    </SnackbarProvider>
  )
}

export default NotificationsProvider
