import { makeStyles } from '@material-ui/core/styles'

export default makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  spanError: {
    color: 'red',
  },
  divBottoms: {
    marginTop: '20px',
  },
  addAtributtes: {
    marginRight: '2%',
  },
  btnBackgroundPrimary: {
    minWidth: 180,
    maxWidth: 246,
    fill: theme.palette.white.main,

    '&:hover': {
      background: theme.palette.primary.main,
    },
  },
  btnActivatedCancel: {
    background: theme.palette.grey.dark,
  },
  btnBackgroundError: {
    minWidth: 180,
    margin: 5,
    color: theme.palette.white.main,
    background: theme.palette.grey.dark,
    '&:hover': {
      background: theme.palette.error.main,
      color: theme.palette.white.main,
    },
  },
  labelSize: {
    fontSize: 15,
    marginBottom: 8,
  },
  modalWidth: {
    width: 'calc(50% - 64px);',
  },
  activateCancel: {
    marginRight: 5,
  },
}))
