import React from 'react'

import { Typography, Grid, Button } from '@material-ui/core'

import styles from './bonuses.styles'
import Footer from './components/Footer'
import Header from './components/Header'

import { ThankImageIcon } from 'components/icons/personalizedIcons'

const ThanksBonus = () => {
  const classes = styles()
  const returnToLuegoPagoSite = () => {
    window.location.href = 'https://luegopago.com/'
  }
  return (
    <div className={classes.main}>
      <div>
        <Header />
        <Grid container direction="column" justify="center" alignItems="center">
          <Grid item xs={6} style={{ marginTop: '5%', textAlign: 'center' }}>
            <ThankImageIcon />
            <Typography variant="h5" style={{ color: '#3AD399', marginTop: '7%' }} aling="center">
              ¡Tu Bono ha sido creado exitosamente!
            </Typography>
            <Typography variant="body1" aling="center" style={{ marginTop: '7%' }}>
              Se ha enviado el codigo QR de tu bono a tu correo electronico
            </Typography>

            <Button
              variant="contained"
              color="primary"
              style={{ marginTop: '7%' }}
              className={classes.btnAceptar}
              onClick={returnToLuegoPagoSite}
            >
              Ir a Luegopago
            </Button>
          </Grid>
        </Grid>
      </div>
      <Footer email="thanks" />
    </div>
  )
}
export default ThanksBonus
