import { makeStyles } from '@material-ui/core/styles'

import buttonStyle from 'assets/jss/material-dashboard-pro-react/components/buttonStyle'
import switchStyles from 'assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch'

export default makeStyles((theme) => ({
  ...switchStyles,
  root: {
    flexGrow: 1,
  },
  card: {
    minHeight: 100,
    padding: 20,
    fontFamily: 'Red Hat Display Regular',
  },
  titleConfig: {
    marginBottom: 10,
    fontFamily: 'Red Hat Display Bold',
  },
  select: {
    fontFamily: 'Red Hat Display Regular',
  },
}))
