/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState } from 'react'

import {
  List,
  ListItem,
  ListItemText,
  Divider,
  Typography,
  Breadcrumbs,
  Link,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import ChevronRight from '@material-ui/icons/ChevronRight'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import axios from 'axios'

import { API_CATEGORIES } from 'constants/urls'

const useStyles = makeStyles({
  list: {
    maxHeight: '60vh',
    overflow: 'scroll',
  },
})

const CategorySelection = ({ onFinishSelection, setIsLoading }) => {
  const classes = useStyles()
  const [categories, setCategories] = useState([])
  const [selectedCategory, setSelectedCategory] = useState([])

  const getCategoryEndPoint = () => {
    setIsLoading(true)
    axios.get(`${API_CATEGORIES}`).then(function (response) {
      setCategories(response.data)
      setSelectedCategory([])
      setIsLoading(false)
    })
  }

  const getCategoryById = (category, isSelected = true) => {
    if (isSelected) {
      setSelectedCategory((prev) => [
        ...prev,
        {
          id: category.id,
          name: category.name,
          slug: category.slug,
        },
      ])
    }
    setIsLoading(true)
    axios.get(`${API_CATEGORIES}/CategoriesByParentId/${category.id}`).then(function (response) {
      if (response.data.data.length === 0) {
        onFinishSelection([
          ...selectedCategory,
          {
            id: category.id,
            name: category.name,
            slug: category.slug,
          },
        ])
      }
      setCategories(response.data.data)
      setIsLoading(false)
    })
  }

  useEffect(() => {
    getCategoryEndPoint()
  }, [])

  return (
    <>
      <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
        {selectedCategory.length > 0 && (
          <Link
            color="inherit"
            component="button"
            onClick={() => {
              setCategories([])
              setSelectedCategory([])
              getCategoryEndPoint()
            }}
          >
            Inicio
          </Link>
        )}
        {selectedCategory.map((category, index) => (
          <Link
            key={category.id}
            color="inherit"
            component={selectedCategory.length - 1 === index ? 'typography' : 'button'}
            underline={selectedCategory.length - 1 === index ? 'none' : 'hover'}
            unselectable={selectedCategory.length - 1 === index ? 'on' : 'off'}
            onClick={() => {
              if (selectedCategory.length - 1 === index) return
              const newSelectedCategory = selectedCategory.slice(0, index + 1)
              setSelectedCategory(newSelectedCategory)
              if (newSelectedCategory.length === 0) {
                getCategoryEndPoint()
              } else {
                getCategoryById(newSelectedCategory[newSelectedCategory.length - 1], false)
              }
            }}
          >
            {category.name}
          </Link>
        ))}
      </Breadcrumbs>
      <List className={classes.list}>
        {categories.map((item) => (
          <Fragment key={item.id}>
            <ListItem
              button
              onClick={() => {
                getCategoryById(item)
              }}
            >
              <ListItemText primary={<Typography variant="paragraph">{item.name}</Typography>} />
              <ChevronRight fontSize="small" />
            </ListItem>
            <Divider />
          </Fragment>
        ))}
      </List>
    </>
  )
}

export default CategorySelection
