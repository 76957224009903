/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react'

import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  Button,
  FormGroup,
  InputLabel,
  TextField,
  Paper,
  Chip,
} from '@material-ui/core'
import TagFacesIcon from '@material-ui/icons/TagFaces'
import Autocomplete from '@material-ui/lab/Autocomplete'

import useStyles from './products.styles'

const HomologateAttributes = ({
  open,
  onCancel,
  attributeShopify,
  attributeSellerCenter,
  setAttributeShopify,
  setAttributeSellerCenter,
  valueAttributeShopify,
  setValueAttributeShopify,
  valueAttributeSellerCenter,
  setValueAttributeSellerCenter,
  allHomologatesAttributes,
  setAllHomologatesAttributes,
  chipData,
  setChipData,
  saveAttributesHomologados,
}) => {
  const classes = useStyles()
  // useEffect(() => {
  //   setChipData()
  // }, [])
  const associationVariations = () => {
    if (
      Object.keys(valueAttributeShopify).length === 0 ||
      Object.keys(valueAttributeSellerCenter).length === 0
    ) {
      return
    }
    if (valueAttributeShopify && valueAttributeSellerCenter) {
      const objectUnion = { shopify: valueAttributeShopify, seller: valueAttributeSellerCenter }
      setAllHomologatesAttributes([...allHomologatesAttributes, objectUnion])
      setValueAttributeShopify({})
      setValueAttributeSellerCenter({})
      const filterAttributesShopify = attributeShopify.filter(
        (item) => item.id !== valueAttributeShopify.id
      )
      const filterAttributesSeller = attributeSellerCenter.filter(
        (item) => item.id !== valueAttributeSellerCenter.id
      )
      setAttributeShopify(filterAttributesShopify)
      setAttributeSellerCenter(filterAttributesSeller)
      const findAttributesSeller = attributeSellerCenter.find(
        (item) => item.id === valueAttributeSellerCenter.id
      )
      setChipData([...chipData, findAttributesSeller])
    }
  }
  const defaultAttribute = {
    options: attributeSellerCenter,
    getOptionLabel: (option) => (option.name === null ? '' : option.name),
  }
  const defaultAttributeShopify = {
    options: attributeShopify,
    getOptionLabel: (option) => (option.name === null ? '' : option.name),
  }
  return (
    <>
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        fullWidth
        maxWidth="md"
        classes={{ paperWidthXl: classes.modalVariationForm }}
        // onClose={onClose}
        open={open}
      >
        <DialogTitle className={classes.title} disableTypography>
          <Typography variant="h6">Asociación de atributos</Typography>
        </DialogTitle>
        <DialogContent className={classes.variantContent}>
          <Grid container direction="row" justifyContent="flex-start" alignItems="center">
            <FormGroup className={classes.widthAtttribute}>
              <InputLabel className={classes.labelSize}>Atributos shopify</InputLabel>
              <Autocomplete
                {...defaultAttributeShopify}
                id="warehouse_id"
                name="warehouse_id"
                className={classes.autocompletePadding}
                value={valueAttributeShopify}
                // helperText={errors?.warehouse_id?.message}
                onChange={(event, newValue) => {
                  setValueAttributeShopify(newValue)
                  // handleChangeAutocomplete(event, newValue)
                }}
                renderInput={(params) => (
                  <TextField
                    id="warehouse_id_name"
                    name="warehouse_id_name"
                    {...params}
                    // inputRef={register}
                    // error={!!errors.warehouse_id_name}
                    label=""
                    margin="normal"
                    variant="outlined"
                    className={classes.autocompleteDetailProduct}
                  />
                )}
              />
            </FormGroup>
            <FormGroup className={classes.widthAtttribute}>
              <InputLabel className={classes.labelSize}>Atributos sellerCenter</InputLabel>
              <Autocomplete
                {...defaultAttribute}
                id="warehouse_id"
                name="warehouse_id"
                className={classes.autocompletePadding}
                value={valueAttributeSellerCenter}
                // helperText={errors?.warehouse_id?.message}
                onChange={(event, newValue) => {
                  setValueAttributeSellerCenter(newValue)
                  // handleChangeAutocomplete(event, newValue)
                }}
                renderInput={(params) => (
                  <TextField
                    id="warehouse_id_name"
                    name="warehouse_id_name"
                    {...params}
                    // inputRef={register}
                    // error={!!errors.warehouse_id_name}
                    label=""
                    margin="normal"
                    variant="outlined"
                    className={classes.autocompleteDetailProduct}
                  />
                )}
              />
            </FormGroup>

            <Button
              className={classes.btnBackgroundAssociation}
              variant="contained"
              color="secondary"
              type="submit"
              onClick={associationVariations}
            >
              Asociar
            </Button>
          </Grid>
          <Typography variant="subtitle2">Atributos asociados</Typography>
          <div className={classes.rootChips}>
            {chipData.map((data) => {
              return (
                <div className={classes.chipsAttributes} key={data.id}>
                  <Chip label={data.name} onDelete={data.label} />
                </div>
              )
            })}
          </div>
          <Grid container justify="center" className={classes.divButtons}>
            <Button
              className={classes.btnBackgroundPrimary}
              variant="contained"
              color="secondary"
              onClick={saveAttributesHomologados}
            >
              Guardar
            </Button>
            <Button
              className={classes.btnBackgroundError}
              variant="contained"
              color="secondary"
              onClick={onCancel}
            >
              Cancelar
            </Button>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default HomologateAttributes
