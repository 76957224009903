/* eslint-disable import/named */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'

import {
  Grid,
  Typography,
  Breadcrumbs,
  Link,
  Tooltip,
  IconButton,
  TextField,
} from '@material-ui/core'
import CheckIcon from '@material-ui/icons/Check'
import NavigateNextIcon from '@material-ui/icons/ChevronRight'
import CloseIcon from '@material-ui/icons/Close'
import { useRouteMatch } from 'react-router-dom'

import Style from './validateCategorieVariable.style'

import BackDrop from 'components/BackDrop'
import { PencilIcon } from 'components/icons/personalizedIcons'
import useCustomDialogAlert from 'components/useCustomDialogAlert'
import {
  apicategoriesPercentById,
  apicategoriesPercentByIdSeller,
} from 'services/commissions/commissions.services'

const CategorPercentage = ({
  renderBackToList,
  getCategoriesById,
  categoriesBreadCrumb,
  idSeller,
  setIsOpenModalCategories,
  categoriesPercentModified,
}) => {
  const isConfigSeller = useRouteMatch('/admin/configSeller')?.isExact
  const showDialogAlert = useCustomDialogAlert()
  const classes = Style()
  const [loading, setLoading] = useState(false)
  const [editPercent, setEditPercent] = useState(false)
  const [infoPercent, setInfoPercent] = useState(
    categoriesBreadCrumb[categoriesBreadCrumb.length - 1]?.percentage
  )
  const categoriesPercent = (categories) => {
    const categoriesSellerId = {
      id: categories.id,
      percent: infoPercent,
    }
    const categoryModified = {
      sellerId: idSeller.id,
      categories: [categoriesSellerId],
    }
    categoriesPercentModified(categoryModified)
  }
  const updeteCategory = (category) => {
    const categoryModified = {
      id: category.id,
      percent: infoPercent,
    }
    setLoading(true)
    apicategoriesPercentById(categoryModified)
      .then((response) => {
        showDialogAlert(
          true,
          '¡Cambio exitoso!',
          `Se ha guardado correctamente la información`,
          'Cerrar',
          '',
          true
        )
        setEditPercent(false)
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }
  return (
    <Grid container justify="flex-start" className={classes.container}>
      <BackDrop open={loading} />
      <Breadcrumbs
        maxItems={5}
        className={classes.positionElements}
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
      >
        {categoriesBreadCrumb.length > 0 && (
          <Link
            color="inherit"
            component="button"
            underline="always"
            onClick={() => {
              categoriesBreadCrumb.splice(0, categoriesBreadCrumb.length)
              renderBackToList()
            }}
          >
            Categorías
          </Link>
        )}
        {categoriesBreadCrumb.map((categorie, index) => {
          return (
            <Link
              component={categoriesBreadCrumb.length - 1 === index ? 'typography' : 'button'}
              color="inherit"
              underline={categoriesBreadCrumb.length - 1 === index ? 'none' : 'always'}
              onClick={
                categoriesBreadCrumb.length - 1 === index
                  ? null
                  : (e) =>
                      getCategoriesById(e, categorie.id, categorie.name, categorie.percentage, true)
              }
            >
              {categorie.name}
            </Link>
          )
        })}
      </Breadcrumbs>
      <div className={classes.borderTable}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          className={classes.justifyCategories}
        >
          <Typography variant="body1">Categoría</Typography>
          <Typography variant="body1">Porcentaje</Typography>
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          className={classes.justify}
        >
          <Typography variant="body1" style={{ marginRight: 8 }}>
            {categoriesBreadCrumb[categoriesBreadCrumb.length - 1]?.name || ''}
          </Typography>
          <div className={classes.editPercentage}>
            {editPercent ? (
              <>
                <TextField
                  fullWidth
                  id="percent"
                  name="percent"
                  // helperText={<p className={classes.messageError}>{messageError}</p>}
                  value={infoPercent || ''}
                  variant="outlined"
                  onChange={(event) => {
                    setInfoPercent(event.target.value)
                  }}
                />
                <Tooltip title="Guardar">
                  <IconButton
                    className={classes.btnEdit}
                    onClick={(event) => {
                      event.preventDefault()
                      if (isConfigSeller) {
                        categoriesPercent(categoriesBreadCrumb[categoriesBreadCrumb.length - 1])
                      } else {
                        updeteCategory(categoriesBreadCrumb[categoriesBreadCrumb.length - 1])
                      }
                    }}
                  >
                    <CheckIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Cancelar">
                  <IconButton
                    className={classes.btnDelete}
                    onClick={(event) => {
                      event.preventDefault()
                      setEditPercent(false)
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Tooltip>
              </>
            ) : (
              <>
                {!isConfigSeller && (
                  <Typography variant="body1">{`${infoPercent || ''} %`}</Typography>
                )}
                <Tooltip title="Editar">
                  <IconButton
                    className={classes.btnEdit}
                    onClick={(event) => {
                      event.preventDefault()
                      setEditPercent(true)
                    }}
                  >
                    <PencilIcon />
                  </IconButton>
                </Tooltip>
              </>
            )}
          </div>
        </Grid>
      </div>
    </Grid>
  )
}
export default CategorPercentage
