/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import {
  Grid,
  TextField,
  FormHelperText,
  Typography,
  Divider,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  IconButton,
  Button,
  Switch,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined'
import Axios from 'axios'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'

import useStyles from '../generalConfig.styles'

import ImageUpload from 'components/CustomUpload/ImageUpload'
import { UploadIcon } from 'components/icons/personalizedIcons'
import useCustomDialogAlert from 'components/useCustomDialogAlert'
import { API_STORE_INFO, API_SELLERS } from 'constants/urls'
import { onKeyPressJustNumbers, onlyNumber, isAlphanumeric } from 'utils/utils'
import { removeDoubleSpaces } from 'utils/utils'

const schema = yup.object().shape({
  storePhone: yup
    .number()
    .min(3, 'Ingrese mínimo 3 caracteres')
    .positive()
    .typeError('Campo requerido.')
    .required('Campo requerido.'),
  storeName: yup
    .string()
    .min(2, 'Ingrese mínimo 2 caracteres')
    .max(50, 'Ingrese máximo 50 caracteres')
    .trim()
    .typeError('Campo requerido.'),
  storeEmail: yup
    .string()
    .email('email incorrecto.')
    .min(2, 'Ingrese mínimo 2 caracteres')
    .max(50, 'Ingrese máximo 50 caracteres')
    .trim()
    .typeError('Campo requerido.'),
  contactName: yup
    .string()
    .min(2, 'Ingrese mínimo 2 caracteres')
    .max(50, 'Ingrese máximo 50 caracteres')
    .trim()
    .typeError('Campo requerido.')
    .required('Campo requerido.'),
  termsAndConditions: yup
    .string()
    .min(6, 'Ingrese mínimo 6 caracteres')
    .max(1000000, 'Ingrese máximo 1000000 caracteres')
    .trim()
    .typeError('Campo requerido.')
    .required('Campo requerido.'),
  address: yup
    .string()
    .min(5, 'Ingrese mínimo 5 caracteres')
    .trim()
    .typeError('Campo requerido.')
    .required('Campo requerido.'),
  // managerOperational: yup
  //   .string()
  //   .min(2, 'Ingrese mínimo 2 caracteres')
  //   .max(50, 'Ingrese máximo 50 caracteres')
  //   .trim()
  //   .typeError('Campo requerido.')
  //   .required('Campo requerido.'),
})

const requiredFields = {
  storePhone: 'Número de teléfono',
  title: 'Titulo de la bodega',
  phoneNumber: 'Número Teléfono',
  warehouseAddress: 'Dirección Bodega',
  postalCode: 'Código Postal',
  cityId: 'Ciudad',
  department: 'Departamento',
  accountAdministratorEmail: 'Correo Electrónico Administrador Cuenta',
  accountAdministratorPhone: 'Teléfono Electrónico Administrador Cuenta',
  operationalContactEmail: 'Correo Electrónico Administrador Contacto Operacional',
  operationalContactPhone: 'Teléfono Contacto Operacional',
  contactSacEmail: 'Correo Electrónico Administrador Contacto SAC',
  contactSacPhone: 'Teléfono Contacto SAC',
  contactFinancialEmail: 'Correo Electrónico Administrador Contacto Contabilidad',
  contactFinancialPhone: 'Teléfono Contacto Contabilidad',
  termsAndConditions: 'Terminos y Condiciones',
}

export default function GeneralStep({ data, isRegistered, getModel, setIsLoading, isSellerAdmin }) {
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up('md'))
  const classes = useStyles()
  const showDialogAlert = useCustomDialogAlert()
  const [generalInfo, setGeneralInfo] = useState(data)

  const { register, handleSubmit, errors } = useForm({
    defaultValues: data,
    mode: 'onBlur',
    resolver: yupResolver(schema),
  })

  const [file, setFile] = useState(null)
  const [src, setSrc] = useState(data.storeLogo)
  const [dataUpload, setDataUpload] = useState({})
  const [customerTypes, setCustomerTypes] = useState([])
  const [dataCertificate, setDataCertificate] = useState({
    name: '',
  })

  const getCustomerType = async () => {
    await Axios.get(`${API_SELLERS}/CustomerType`)
      .then((response) => {
        setCustomerTypes(response.data.data)
      })
      .catch((e) => {
        console.log(e)
      })
  }

  useEffect(() => {
    getCustomerType()
  }, [])

  useEffect(() => {}, [dataCertificate])

  const handleChange = (e) => {
    if (isAlphanumeric(e.target.value)) {
      setGeneralInfo({ ...generalInfo, [e.target.id]: e.target.value })
      setDataUpload({ ...dataUpload, [e.target.id]: e.target.value })
      return true
    }
    return false
  }

  const handleChangeJustNumber = (e) => {
    if (onlyNumber(e.target.value)) {
      setGeneralInfo({ ...generalInfo, [e.target.id]: e.target.value })
      setDataUpload({ ...dataUpload, [e.target.id]: e.target.value })
      return true
    }
    return false
  }

  const save = async () => {
    const formData = new FormData()

    const listError = []
    const errorEmailDir = []
    setIsLoading(true)

    const imageFiles = file

    if (imageFiles) {
      formData.append('fileStoreLogo', imageFiles)
    }

    formData.append('sellerId', generalInfo.sellerId)
    Object.keys(dataUpload).map(function (objectKey) {
      const value = dataUpload[objectKey]
      if (requiredFields[objectKey]) {
        const valueColumn = value
        if (!valueColumn) {
          listError.push(requiredFields[objectKey])
        }
      }
      formData.append(objectKey, value)
      return true
    })
    if (errorEmailDir.length > 0) {
      showDialogAlert(
        true,
        '¡Advertencia!',
        `Algunos de los correos digitados no son válido`,
        'Cerrar'
      )
      return false
    }
    if (listError.length > 0) {
      showDialogAlert(
        true,
        '¡Advertencia!',
        `No se puede guardar, faltan los campos: ${listError.join(', ')}`,
        'Cerrar'
      )
      return false
    }
    try {
      const response = await Axios({
        url: `${API_STORE_INFO}`,
        method: !isRegistered ? 'post' : 'put',
        data: formData,

        config: {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      })
      if (response) {
        setIsLoading(false)
        if (response.status === 200) {
          showDialogAlert(
            true,
            '¡Cambio exitoso!',
            `Se ha guardado correctamente la información`,
            'Cerrar',
            '',
            true
          )
          getModel()
          setDataCertificate({
            name: '',
          })
          setDataUpload({ ...dataUpload, fileBankCertificate: null })
        }
      }
    } catch (error) {
      setIsLoading(false)
      console.error(error)
    }

    return true
  }

  const handleAddressChange = (e) => {
    const value = removeDoubleSpaces(e.target.value)
    setGeneralInfo({ ...generalInfo, [e.target.id]: value })
    setDataUpload({ ...dataUpload, [e.target.id]: value })
  }

  useEffect(() => {
    setGeneralInfo(data)
    setSrc(data.storeLogo)
  }, [data])

  return (
    <form onSubmit={handleSubmit(save)}>
      <Grid container xs={12} spacing={4} alignItems="flex-start">
        <Grid container item xs={12} md={7} spacing={4}>
          <Grid item md={12}>
            <Typography variant="subtitle1" gutterBottom>
              Información básica y de facturación
            </Typography>
          </Grid>
          <Grid item md={6}>
            <InputLabel
              error={!!errors.storeName}
              htmlFor="storeName"
              className={classes.labelInputTop}
            >
              Nombre de la tienda
            </InputLabel>
            <TextField
              className={classes.marginField}
              id="storeName"
              value={generalInfo.storeName ? generalInfo.storeName : ''}
              type="text"
              variant="outlined"
              onChange={handleChange}
              fullWidth
              disabled
              name="storeName"
              inputRef={register}
              error={!!errors.storeName}
              helperText={errors?.storeName?.message}
            />
            <InputLabel
              error={!!errors.storeEmail}
              htmlFor="storeName"
              className={classes.labelInputTop}
            >
              Correo electrónico
            </InputLabel>
            <TextField
              className={classes.marginField}
              id="storeEmail"
              value={generalInfo.storeEmail ? generalInfo.storeEmail : ''}
              type="email"
              variant="outlined"
              onChange={handleChange}
              fullWidth
              disabled
              name="storeEmail"
              inputRef={register}
              error={!!errors.storeEmail}
              helperText={errors?.storeEmail?.message}
            />
            <InputLabel
              error={!!errors.sellerTypeId}
              htmlFor="sellerTypeId"
              className={classes.labelInputTop}
            >
              Identificación tributaria
            </InputLabel>
            <FormControl
              className={classes.marginField}
              style={{ minWidth: '100%' }}
              error={!!errors.sellerTypeId}
              variant="outlined"
            >
              <Select
                onChange={handleChange}
                name="sellerTypeId"
                disabled
                inputRef={register}
                defaultValue={generalInfo.sellerType?.id || ''}
                value={generalInfo.sellerType?.id || ''}
              >
                {customerTypes.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.description}
                  </MenuItem>
                ))}
              </Select>
              {errors?.sellerTypeId?.message ? (
                <FormHelperText error>{errors?.sellerTypeId?.message}</FormHelperText>
              ) : null}
            </FormControl>
          </Grid>
          <Grid item md={6}>
            <InputLabel
              error={!!errors.contactName}
              htmlFor="contactName"
              className={classes.labelInputTop}
            >
              Nombre del contacto
            </InputLabel>
            <TextField
              className={classes.marginField}
              id="contactName"
              value={generalInfo.contactName ? generalInfo.contactName : ''}
              type="text"
              variant="outlined"
              onChange={handleChange}
              fullWidth
              disabled={!isSellerAdmin}
              name="contactName"
              inputProps={{ ref: register }}
              error={!!errors.contactName}
              helperText={errors?.contactName?.message}
            />
            <InputLabel
              error={!!errors.storePhone}
              htmlFor="storePhone"
              className={classes.labelInputTop}
            >
              Número de teléfono
            </InputLabel>
            <TextField
              className={classes.marginField}
              id="storePhone"
              value={generalInfo.storePhone ? generalInfo.storePhone : ''}
              variant="outlined"
              onChange={handleChangeJustNumber}
              fullWidth
              inputProps={{ max: 10 }}
              disabled={!isSellerAdmin}
              onKeyPress={onKeyPressJustNumbers}
              type="tel"
              name="storePhone"
              inputRef={register}
              error={!!errors.storePhone}
              helperText={errors?.storePhone?.message}
            />
            <InputLabel
              error={!!errors.address}
              htmlFor="address"
              className={classes.labelInputTop}
            >
              Dirección
            </InputLabel>
            <TextField
              className={classes.marginField}
              id="address"
              value={generalInfo.address ? generalInfo.address : ''}
              variant="outlined"
              onChange={handleAddressChange}
              fullWidth
              disabled={!isSellerAdmin}
              name="address"
              inputRef={register}
              error={!!errors.address}
              helperText={errors?.address?.message}
            />
          </Grid>
        </Grid>

        {isSellerAdmin ? (
          <>
            <Divider orientation={matches ? 'vertical' : 'horizontal'} flexItem />
            <Grid container item xs={12} md={5}>
              <Grid item xs={12}>
                <div className={classes.root}>
                  <Typography variant="subtitle1" gutterBottom>
                    Imagen tienda
                  </Typography>

                  <ImageUpload avatar loadImage={src} setFilesComponent={setFile} />
                </div>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle1">Descripción</Typography>
                <TextField
                  className={classes.marginField}
                  style={{
                    borderRadius: '10px 10px 0px 0px',
                    padding: '5px',
                    background: '#F5F5F5',
                  }}
                  id="storeDescription"
                  fullWidth
                  disabled={!isSellerAdmin}
                  multiline
                  maxRows={15}
                  type="text"
                  value={generalInfo.storeDescription}
                  onChange={(e) => {
                    setGeneralInfo({ ...generalInfo, [e.target.id]: e.target.value })
                    setDataUpload({ ...dataUpload, [e.target.id]: e.target.value })
                  }}
                  name="storeDescription"
                  inputRef={register}
                  error={!!errors.storeDescription}
                  helperText={errors?.storeDescription?.message}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle1">Términos y condiciones</Typography>
                <Typography>Agrega tus términos y condiciones</Typography>
                <TextField
                  className={classes.marginField}
                  style={{
                    borderRadius: '10px 10px 0px 0px',
                    padding: '5px',
                    background: '#F5F5F5',
                  }}
                  id="termsAndConditions"
                  fullWidth
                  disabled={!isSellerAdmin}
                  multiline
                  maxRows={15}
                  type="text"
                  value={generalInfo.termsAndConditions}
                  onChange={(e) => {
                    setGeneralInfo({ ...generalInfo, [e.target.id]: e.target.value })
                    setDataUpload({ ...dataUpload, [e.target.id]: e.target.value })
                  }}
                  name="termsAndConditions"
                  inputRef={register}
                  error={!!errors.termsAndConditions}
                  helperText={errors?.termsAndConditions?.message}
                />
              </Grid>
            </Grid>
          </>
        ) : null}
      </Grid>
      <Grid
        container
        justify="center"
        alignItems="center"
        md={12}
        className={classes.divButtonsGeneral}
      >
        <Button
          // onClick={() => onClose()}
          className={classes.buttonCancel}
        >
          Cancelar
        </Button>
        <Button className={classes.buttons} type="submit" variant="contained" color="secondary">
          Guardar
        </Button>
      </Grid>
    </form>
  )
}
