/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react'

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  box: {
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(2),
    gap: theme.spacing(1.5),
    minWidth: '170px',
    maxWidth: '200px',
    textAlign: 'center',
    borderRadius: '8px',
    color: '#303031',
    border: '1px solid #E2E2E2',
    borderBottom: '4px solid #E2E2E2',
    background: '#FFF',
    transition: 'all 0.3s ease-in-out',
    '&:hover': {
      border: '1px solid #FF40FC',
      borderBottom: '4px solid #FF40FC',
    },
  },
  boxSelected: {
    color: '#6064FF',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(2),
    gap: theme.spacing(1.5),
    minWidth: '170px',
    maxWidth: '200px',
    textAlign: 'center',
    borderRadius: '8px',
    border: '1px solid #6064FF',
    borderBottom: '4px solid #6064FF',
    background: '#FFF',
    transition: 'all 0.3s ease-in-out',
  },
  boxDisabled: {
    cursor: 'not-allowed',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(2),
    gap: theme.spacing(1.5),
    minWidth: '170px',
    maxWidth: '200px',
    textAlign: 'center',
    borderRadius: '8px',
    color: '#E2E2E2',
    border: '1px solid #E2E2E2',
    borderBottom: '4px solid #E2E2E2',
    background: '#FFF',
  },
  title: {
    textAlign: 'center',
    fontSize: '14px',
    fontStyle: 'normal',
    lineHeight: '5px',
    fontWeight: '700',
  },
  description: {
    textAlign: 'center',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: '400',
  },
  label: {
    fontSize: '12px',
    lineHeight: '18px',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(0.5),
  },
  suggestionBox: {
    display: 'flex',
    cursor: 'pointer',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: theme.spacing(1),
    border: '1px solid #E2E2E2',
    borderRadius: '8px',
    transition: 'all 0.3s ease-in-out',
    '&:hover': {
      border: '1px solid #FF40FC',
      color: '#FF40FC',
    },
  },
  suggestionBoxSelected: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: theme.spacing(1),
    color: '#6064FF',
    border: '1px solid #6064FF',
    borderRadius: '8px',
    transition: 'all 0.3s ease-in-out',
  },
}))

const StyledRadio = ({
  variant,
  disabled,
  selected,
  label,
  description,
  icon,
  handleChange,
  value,
}) => {
  const classes = useStyles()
  if (variant === 'category') {
    return (
      <div
        className={selected ? classes.suggestionBoxSelected : classes.suggestionBox}
        onClick={() => {
          handleChange(value)
        }}
      >
        <p>{label}</p>
        <span
          style={{
            fontSize: '14px',
          }}
        >
          {description}
        </span>
      </div>
    )
  }
  return (
    <div
      className={selected ? classes.boxSelected : disabled ? classes.boxDisabled : classes.box}
      onClick={() => {
        if (disabled) return

        handleChange(value)
      }}
    >
      {icon}
      <div>
        <h3 className={classes.title}>{label}</h3>
        <span className={classes.description}>{description}</span>
      </div>
    </div>
  )
}

export default StyledRadio
