import React, { useState } from 'react'

import { Grid, InputAdornment, InputLabel } from '@material-ui/core'
import CurrencyTextField from '@unicef/material-ui-currency-textfield'
import axios from 'axios'
import PropTypes from 'prop-types'

import styles from '../products.styles'
import BackDrop from 'components/BackDrop'
import Button from 'components/CustomButtons/Button'
import useCustomDialogAlert from 'components/useCustomDialogAlert'
import { MICROSERVICES_PRODUCTS } from 'constants/urls'

const EditPrice = ({ onCancel, dataToEdit, productData, setData }) => {
  const defaultModel = {
    id: dataToEdit.id,
    regularPrice: dataToEdit.value,
    sku: dataToEdit.sku,
  }
  const [isLoading, setIsLoading] = useState(false)
  const [model, setModel] = useState(defaultModel)

  const classes = styles()
  const showDialogAlert = useCustomDialogAlert()

  const onSubmit = async (event) => {
    event.preventDefault()
    if (!model.regularPrice) {
      showDialogAlert(true, '¡Advertencia!', `El campo no puede estar vacio`, 'Cerrar')
      return false
    }
    let productOject = productData.find((product) => product.id === defaultModel.id)
    productOject.regularPrice = parseInt(model.regularPrice.replaceAll(',', ''))
    setIsLoading(true)
    try {
      const result = await axios({
        url: `${MICROSERVICES_PRODUCTS}/${defaultModel.id}`,
        method: 'PUT',
        data: productOject,
      })
      if (result) setIsLoading(false)
      if (result.status === 200 || result.status === 201) {
        showDialogAlert(
          true,
          '¡Cambio exitoso!',
          `Se actualizó correctamente el precio`,
          'Cerrar',
          '',
          true
        )
        const newData = [...productData]
        const found = newData.find((product) => product.id === defaultModel.id)
        found.regularPrice = model.regularPrice.split(',').join('')
        setData(newData)
        onCancel()
      }
    } catch (error) {
      setIsLoading(false)
      showDialogAlert(
        true,
        '¡Error!',
        `Hubo un error al actualizar el precio, valida que el producto tenga una bodega asignada`,
        'Cerrar'
      )
    }
    return true
  }
  return (
    <form noValidate onSubmit={onSubmit} autoComplete="off">
      <BackDrop open={isLoading} />
      <Grid container className={classes.root} justifyContent="center">
        <Grid item xs={12} sm={12} md={6} justifyContent="center">
          <InputLabel className={classes.labelSize}>Precio ($)</InputLabel>
          <CurrencyTextField
            currencySymbol="$"
            textAlign="left"
            decimalPlaces={0}
            outputFormat="string"
            variant="outlined"
            inputProps={{
              inputProps: { min: 0 },
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
              defaultValue: defaultModel.regularPrice,
              onChange: ({ target }) => {
                setModel({ ...model, regularPrice: target.value })
              }
            }}
          />
        </Grid>
      </Grid>
      <Grid container alignItems="center" justifyContent="center">
        <div className={classes.wrapper}>
          <Button
            variant="contained"
            className={`${classes.btnBackgroundErrorPrice} ${classes.btnUpload}`}
            btnErrorPrice
            disabled={isLoading}
            onClick={onCancel}
          >
            Cancelar
          </Button>
        </div>
        <Button
          type="submit"
          className={`${classes.btnNewProduct} ${classes.btnUpload}`}
          isLoading={isLoading}
        >
          {dataToEdit.id ? 'Actualizar' : 'Aceptar'}
        </Button>
      </Grid>
    </form>
  )
}
EditPrice.propTypes = {
  onCancel: PropTypes.func.isRequired,
}

export default EditPrice
