/* eslint-disable react/prop-types */
import React, { useState } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { FormGroup, Grid, InputLabel, Switch, TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import axios from 'axios'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'

import Button from 'components/CustomButtons/Button'
import useCustomDialogAlert from 'components/useCustomDialogAlert'
import { API_BASE } from 'constants/urls'

const useStyles = makeStyles((theme) => ({
  gridButtons: {
    marginTop: 20,
    gap: '20px',
  },
  btnBackgroundError: {
    width: 150,
    height: 35,
    borderRadius: '50px',
    border: '1px solid #6064ff',
    color: '#6064ff',
    fontWeight: '500',
    backgroundColor: '#ffffff',
    '&:hover': {
      color: '#FF40FC',
      border: '1px solid #FF40FC',
      backgroundColor: '#ffffff',
    },
  },
  btnBackgroundPrimary: {
    width: 150,
    height: 35,
    borderRadius: '50px',
    border: 'none',
    backgroundColor: '#5e44ff',
    color: 'white',
    fontWeight: '500',
    '&:hover': {
      fontWeight: 'bolder',
      backgroundColor: '#ef0bef',
      boxShadow: '-7px 7px 0px #000000',
      border: '2px solid #000000',
      borderRadius: '50px',
      opacity: '1',
    },
  },
  labelInput: {
    fontFamily: 'Red Hat Display Bold',
    marginTop: '10px',
    marginBottom: '10px',
  },
  input: {
    fontFamily: 'Red Hat Display Regular',
  },
  image: {
    width: '300px',
    height: '300px',
    borderRadius: '50%',
    border: '2px solid #000000',
    padding: '0.3em',
  },
}))

const schema = yup.object().shape({
  name: yup.string().required('Campo requerido'),
  imageSrc: yup.string().required('Campo requerido'),
  isActive: yup.bool().required('Campo requerido'),
})

const ModalBrands = ({ toEdit, onCancel, forImagesUpload, getData, setIsLoading }) => {
  const classes = useStyles()
  const showDialogAlert = useCustomDialogAlert()

  const [alert, setAlert] = useState(null)
  const [editBrands, setEditBrands] = useState({
    id: toEdit !== null ? toEdit[0] : '',
    name: toEdit !== null ? toEdit[2] : '',
    imageSrc: toEdit !== null ? toEdit[1] : '',
    isActive: toEdit !== null ? toEdit[3] : false,
  })

  const { register, handleSubmit, errors } = useForm({
    defaultValues: {
      id: editBrands.id,
      name: editBrands.name,
      imageSrc: editBrands.imageSrc,
      isActive: editBrands.isActive,
    },
    mode: 'onChange',
    resolver: yupResolver(schema),
  })

  const onSubmit = async (e) => {
    e.preventDefault()
    if (editBrands.imageSrc === '' || editBrands.name === '') {
      showDialogAlert(true, '¡Advertencia!', `Llena todos de todos los campos`, 'Cerrar')
      return false
    }
    setIsLoading(true)
    await axios({
      method: 'put',
      url: `${API_BASE}/Brands/id?id=${editBrands.id}`,
      data: editBrands,
    })
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          onCancel()
          setIsLoading(false)
          setAlert(
            showDialogAlert(true, '¡Advertencia!', `La Marca se actualizo con éxito`, 'Cerrar')
          )
          getData()
        }
      })
      .catch((e) => {
        setIsLoading(false)
        showDialogAlert(
          true,
          '¡Advertencia!',
          `El nombre de la marca ya existe, por favor intenta con otro`,
          'Cerrar'
        )
      })
    return true
  }
  const ImageUpload = async (e) => {
    const imageBrand = await forImagesUpload(e, true)
    setEditBrands({
      ...editBrands,
      imageSrc: imageBrand,
    })
  }
  const clickImage = () => {
    document.getElementById(`upload-Image-edit`).click()
  }

  return (
    <form onSubmit={(e) => handleSubmit(onSubmit(e))}>
      <Grid container justify="conten-between" direction="column">
        <Grid container justify="center">
          <img
            src={editBrands.imageSrc}
            width={300}
            alt={editBrands.name}
            className={classes.image}
          />
        </Grid>
        <FormGroup>
          <InputLabel className={classes.labelInput}>Nombre:</InputLabel>
          <TextField
            id="name"
            name="name"
            value={editBrands.name}
            inputRef={register}
            error={!!errors.name}
            helperText={errors?.name?.message}
            onChange={(event) =>
              setEditBrands({
                ...editBrands,
                name: event.target.value,
              })
            }
            className={classes.input}
          />
        </FormGroup>
        <FormGroup>
          <InputLabel className={classes.labelInput}>Activo:</InputLabel>
          <Switch
            inputRef={register}
            checked={editBrands.isActive}
            onChange={(event) =>
              setEditBrands({
                ...editBrands,
                isActive: event.target.checked,
              })
            }
            color="primary"
            name="isActive"
          />
        </FormGroup>
        <FormGroup>
          <InputLabel className={classes.labelInput}>Agregar Imagen:</InputLabel>
          <input
            accept=".png,.jpg,.jpeg"
            className={classes.input}
            onChange={(e) => ImageUpload(e.target.files)}
            id="upload-Image-edit"
            type="file"
            hidden
          />
          <Button
            onClick={() => clickImage()}
            className={classes.btnAdd}
            color="secondary"
            variant="contained"
          >
            Agregar
          </Button>
        </FormGroup>
      </Grid>
      <Grid container justify="center" className={classes.gridButtons}>
        <Button
          className={classes.btnBackgroundError}
          onClick={() => {
            onCancel(editBrands)
          }}
        >
          Cancelar
        </Button>
        <Button className={classes.btnBackgroundPrimary} color="primary" type="submit">
          Actualizar
        </Button>
      </Grid>
      {alert}
    </form>
  )
}

export default ModalBrands
