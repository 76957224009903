import React, { useEffect, useState } from 'react'

import { Button, Grid, IconButton, Tooltip, Typography } from '@material-ui/core'
import axios from 'axios'
import MUIDataTable from 'mui-datatables'

import FiltersUsers from './FiltersUsers'
import useStyles from './generalConfig.styles'

import BackDrop from 'components/BackDrop'
import { PencilIcon, DeleteIcon } from 'components/icons/personalizedIcons'
import ModalAddUsers from 'components/ModalAddUsers'
import ModalContainer from 'components/ModalContainer'
import useCustomDialogAlert from 'components/useCustomDialogAlert'
import { API_USERS } from 'constants/urls'
import textLabels from 'utils/MUIDataTableTextLabels'

const UserManagement = () => {
  const classes = useStyles()
  const defaultUserInfo = {
    id: null,
    email: null,
    name: null,
    role: null,
    isActive: false,
  }
  const showDialogAlert = useCustomDialogAlert()

  const [data, setData] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const [filterSelected, setFilterSelected] = useState(0)
  const [userInfo, setUserInfo] = useState(defaultUserInfo)
  const [openModalModel, setOpenModalModel] = useState(false)

  const handleCloseModalModel = () => {
    setOpenModalModel(false)
    setUserInfo(defaultUserInfo)
  }

  const getUsers = async (status, searchText) => {
    setIsLoading(true)

    let url =
      status === undefined ? `${API_USERS}/BySellerId` : `${API_USERS}/BySellerId?status=${status}`

    if (searchText !== undefined && searchText !== '') {
      url = `${API_USERS}/SearchUsers?search=${searchText}`
    }

    await axios
      .get(url)
      .then((response) => {
        if (response.status === 200) {
          setData(response.data.data)
        }

        setIsLoading(false)
      })
      .catch((e) => {
        setIsLoading(false)
        console.log(e)
      })
  }

  const textDelete = () => {
    return (
      <Grid container direction="column">
        <Typography variant="subtitle2">¿Estas seguro que deseas eliminar este usuario?</Typography>
        <Typography variant="body1">
          Recuerda que si das clic en
          <span className={classes.spanStrong}>eliminar </span>
          se borrará el usuario permanentemente.
        </Typography>
      </Grid>
    )
  }

  const columns = [
    {
      name: 'id',
      options: { display: false },
    },

    {
      name: 'name',
      label: 'NOMBRE DE USUARIO',
      options: {
        customBodyRender: (value) => <Typography variant="subtitle2">{value}</Typography>,
      },
    },

    {
      name: 'email',
      label: 'CORREO ELECTRÓNICO ',
    },

    {
      name: 'Roles',
      label: 'Rol ',
      options: {
        customBodyRender: (_, tableMeta) => {
          return tableMeta.rowData[5] ? 'Admin tienda' : 'Seller'
        },
      },
    },

    {
      name: 'isActive',
      label: 'Estado ',
      options: {
        customBodyRender: (value) => (
          <Typography variant="subtitle2">{value ? 'Activo' : 'Inactivo'}</Typography>
        ),
      },
    },
    {
      name: 'isParent',
      options: { display: false },
    },

    {
      name: '',
      label: '',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          return (
            !tableMeta.rowData[5] && (
              <div>
                <Tooltip title="Editar">
                  <IconButton
                    className={classes.btnEdit}
                    onClick={() => {
                      setUserInfo({
                        id: tableMeta.rowData[0],
                        email: tableMeta.rowData[2],
                        name: tableMeta.rowData[1],
                        role: tableMeta.rowData[3] || '',
                        isActive: tableMeta.rowData[4],
                      })
                      setOpenModalModel(true)
                    }}
                  >
                    <PencilIcon />
                  </IconButton>
                </Tooltip>
              </div>
            )
          )
        },
      },
    },
  ]

  useEffect(() => {
    getUsers()
  }, [])

  const options = {
    print: false,
    filter: false,
    sort: false,
    download: false,
    search: false,
    viewColumns: false,
    responsive: 'scrollMaxHeight',
    selectableRows: 'none',
    textLabels,
    rowsPerPageOptions: [10, 15, 100],
  }

  return (
    <>
      <Grid container justify="space-between" m={5}>
        <Typography variant="h6">Usuarios</Typography>
        <Grid className={classes.divButtonsHeader}>
          <Button
            onClick={(event) => {
              event.preventDefault()
              setOpenModalModel(true)
            }}
            className={classes.btnNewUser}
            variant="contained"
            color="secondary"
          >
            Nuevo usuario
          </Button>
        </Grid>
      </Grid>

      <Grid container direction="column" className={classes.divContent}>
        <FiltersUsers
          getUsers={getUsers}
          filterSelected={filterSelected}
          setFilterSelected={setFilterSelected}
        />

        <MUIDataTable data={data} columns={columns} options={options} />

        <BackDrop open={isLoading} />

        <ModalContainer
          title={`${userInfo.id ? 'Actualizar' : 'Agregar '} Usuario`}
          open={openModalModel}
          onClose={handleCloseModalModel}
          size="sm"
          disableBackdropClick
        >
          <ModalAddUsers userInfo={userInfo} getUsers={getUsers} onCancel={handleCloseModalModel} />
        </ModalContainer>
      </Grid>
    </>
  )
}

export default UserManagement
