import React, { useState, useRef } from 'react'

import { Backdrop, CircularProgress } from '@material-ui/core'
import axios from 'axios'
import { useParams, useHistory } from 'react-router-dom'

import styles from './bonuses.styles'
// componets
import BonusForm from './components/BonusForm'
import Footer from './components/Footer'
import Header from './components/Header'
// import PayBonus from './components/PayBonus'

import DialogAlert from 'components/DialogAlert'
import { VOUCHER } from 'constants/urls'

const CreateBonus = () => {
  const classes = styles()
  const history = useHistory()
  const { nit } = useParams()
  const formPayRef = useRef(null)
  const [isLoading, setIsLoading] = useState(false)
  const [hidePayment, setHidePayment] = useState(true)
  const [data, setData] = useState({
    value: '0',
  })
  const [dialogNotPersonalized, setDialogNotPersonalized] = useState({
    open: false,
    title: '',
    message: '',
    actionConfirm: null,
    textButtonConfirm: '',
    textButtonCancel: '',
  })

  const showPaymentMethods = () => {
    setHidePayment(false)
    formPayRef.current.scrollIntoView({ behavior: 'smooth' })
  }

  const submitBonus = async (e) => {
    e.preventDefault()
    setIsLoading(true)
    data.value = Number(data.value?.replace(/\D/g, ''))
    data.nit = nit
    await axios
      .post(`${VOUCHER}`, data)
      .then(function (response) {
        setIsLoading(false)
        if (response) {
          history.push('/bonos/thanks')
        }
      })
      .catch(function (error) {
        // handle error
        setIsLoading(false)
        if (error.response && error.response.data && error.response.data.errors) {
          const errorResponse = error.response.data
          if (errorResponse.errors[0] === 'El NIT no se encuentra registrado.') {
            setDialogNotPersonalized({
              open: true,
              title: '¡Importante!',
              message: 'El NIT no se encuentra registrado.',
              textButtonConfirm: 'Cerrar',
              textButtonCancel: null,
            })
          } else {
            setDialogNotPersonalized({
              open: true,
              title: '¡Importante!',
              message: 'Ha ocurrido un error inesperado',
              textButtonConfirm: 'Cerrar',
              textButtonCancel: null,
            })
          }
        }
      })
  }

  const handleCloseDialogNotPersonalized = () => {
    setDialogNotPersonalized({ ...dialogNotPersonalized, open: false })
  }

  return (
    <div className={classes.main}>
      <Backdrop className={classes.backdrop} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <DialogAlert
        open={dialogNotPersonalized.open}
        title={dialogNotPersonalized.title}
        handleClose={handleCloseDialogNotPersonalized}
        textButtonConfirm={dialogNotPersonalized.textButtonConfirm}
        message={dialogNotPersonalized.message}
        actionConfirm={dialogNotPersonalized.actionConfirm}
        textButtonCancel={dialogNotPersonalized.textButtonCancel}
      />
      <div>
        <Header />

        {/* payment pay */}
        <BonusForm
            setIsLoading={setIsLoading}
            showPaymentMethods={showPaymentMethods}
            setData={setData}
            data={data}
          />
      </div>
      <Footer />
    </div>
  )
}
export default CreateBonus
