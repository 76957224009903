import React, { useEffect, useState } from 'react'

import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import axios from 'axios'
import MUIDataTable from 'mui-datatables'
import PropTypes from 'prop-types'

import BackDrop from 'components/BackDrop'
import { API_COMMISIONS_SELLER } from 'constants/urls'
import textLabels from 'utils/MUIDataTableTextLabels'

const useStyles = makeStyles((theme) => ({
  bold: {
    fontWeight: 500,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}))

const CommisionStep = ({ sellerId }) => {
  const classes = useStyles()
  const [isLoading, setIsLoading] = useState(false)
  const [dataCommissions, setDataCommissions] = useState([])
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 5,
    typeFilter: 1,
    perPage: 5,
    prevPage: '',
    nextPage: '',
  })
  const [totalCount, setTotalCount] = useState()

  const getCommissions = async (text = '', pageSize = null, page = null) => {
    setIsLoading(true)
    await axios
      .get(
        `${API_COMMISIONS_SELLER}/${pageSize || pagination.pageSize}/${
          page || pagination.page
        }/${sellerId}/${text === '' ? ' /' : text}`
      )
      .then((response) => {
        if (response) {
          setDataCommissions(response.data.data)
          setTotalCount(response.data.pagination.totalCount)
          setPagination(...pagination, {
            page: response.data.pagination.pageNumber,
            pageSize: response.data.pagination.pageSize,
          })
          setIsLoading(false)
        }
      })
      .catch(() => {
        setIsLoading(false)
      })
  }

  useEffect(() => {
    getCommissions()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const columns = [
    {
      name: 'id',
      options: { display: false },
    },

    {
      name: 'commissionName',
      label: 'NOMBRE ',
      options: {
        customBodyRender: (value) => {
          return <Typography className={classes.bold}>{value}</Typography>
        },
      },
    },
    {
      name: 'commissionDescription',
      label: 'DESCRIPCIÓN  ',
    },
    {
      name: 'value',
      label: 'VALOR',
      options: {
        customBodyRender: (value, tableMeta) => {
          return <Typography>{`${tableMeta.rowData[3]}%`}</Typography>
        },
      },
    },
  ]

  const onChangeRowsPerPage = (pageSize) => {
    setPagination({
      ...pagination,
      page: 1,
      perPage: pageSize,
      pageSize: pageSize,
    })
    getCommissions('', pageSize, 1)
  }

  const onChangePage = async (page) => {
    setPagination({
      ...pagination,
      page: page + 1,
      prevPage: page,
      nextPage: page + 2,
    })
    getCommissions('', null, page + 1)
  }

  const options = {
    print: false,
    filter: false,
    sort: false,
    download: false,
    viewColumns: false,
    search: false,
    filterType: 'dropdown',
    responsive: 'scrollMaxHeight',
    rowHover: true,
    selectableRows: 'none',
    textLabels,
    pagination: true,
    serverSide: true,
    count: totalCount,
    rowsPerPage: pagination.perPage,
    rowsPerPageOptions: [5, 10, 20, 100],
    onChangeRowsPerPage,
    onChangePage,
  }

  return (
    <>
      <BackDrop open={isLoading} />
      <MUIDataTable data={dataCommissions} columns={columns} options={options} />
    </>
  )
}

CommisionStep.propTypes = {
  sellerId: PropTypes.string,
}

CommisionStep.defaultProps = {
  sellerId: 0,
}

export default CommisionStep
