import React from 'react'

const myHTML = `
<head>
  <meta charset="UTF-8">
  <meta http-equiv="X-UA-Compatible" content="IE=edge">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <script src="https://cdn.tailwindcss.com"></script>
  <title>Tranqui, pronto volveremos... - Luegopago</title>
  <!-- Global site tag (gtag.js) - Google Analytics -->
  <script async src="https://www.googletagmanager.com/gtag/js?id=UA-157420897-3"></script>
  <script>
    window.dataLayer = window.dataLayer || [];
    function gtag() { dataLayer.push(arguments); }
    gtag('js', new Date());

    gtag('config', 'UA-157420897-3');
  </script>
  <style type="text/css">
    *{
      font-family: 'Red Hat Display Regular';
    }
    .title{
      font-weight: 900;
      font-family: 'Red Hat Display Black';
    }
    .logolP{
      width: 50%;
      margin-bottom: 10%;
    }
    .subtitle{
      font-family: 'Red Hat Display Bold';
    }
    .paragraph{
      font-family: 'Red Hat Display Regular';
      font-size: 1.6rem;
      line-height: 1.8rem;
    }
  </style>
</head>
<body>
  <div class="relative">
    <div class="h-screen md:grid md:grid-cols-2">
      <div class="bg-[#FF40FC] h-1/2 md:h-screen relative overflow-hidden md:overflow-visible">
        <div class="py-4 flex flex-col justify-center px-4 md:px-36">
        </div>
        <img class="absolute -bottom-6 md:bottom-0 md:left-24"
          src="https://luegopago.blob.core.windows.net/luegopago-uploads/website/maintenance_person.png" alt="seller-center" title="seller-center">
      </div>
      <div class="bg-[#6064FF] h-auto py-8 md:h-screen text-white flex flex-col justify-center px-4 md:px-36">
        <img class="logolP"
          src="https://luegopago.blob.core.windows.net/luegopago-uploads/sellerCenter/site/img/Luegopago-sellerCenter.svg" alt="seller-center" title="seller-center">
        <h1 class="text-7xl title">Tranqui</h1>
        <h3 class="text-5xl my-3">pronto volveremos...</h3>
        <p class="my-6 paragraph">Estamos trabajando para ti en nuevas funcionalidades y mejorando los módulos existentes.</p>
        <div class="flex flex-col md:flex-row my-4">
        </div>
      </div>
    </div>
  </div>
</body>`

export default function InConstruction() {
  // eslint-disable-next-line react/no-danger
  return <div dangerouslySetInnerHTML={{ __html: myHTML }} />
}
