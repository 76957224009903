import React, { useState } from 'react'

import DateFnsUtils from '@date-io/date-fns'
import { Button, FormGroup, Grid, InputLabel, InputAdornment, Typography } from '@material-ui/core'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import axios from 'axios'
import { es } from 'date-fns/locale'
import moment from 'moment'
import { useForm } from 'react-hook-form'

import useStyles from './transactionStyle'

import BackDrop from 'components/BackDrop'
import DialogAlert from 'components/DialogAlert'
import { CalendarIcon } from 'components/icons/personalizedIcons'
import { API_ORDERS } from 'constants/urls'

const FormFechaTransaction = () => {
  const classes = useStyles()

  const [formDataAccounts, setFormDataAccounts] = useState({
    startDate: '',
    endDate: '',
  })
  const [fechaError, setFechaError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [message, setMessage] = useState('')
  const [dialogData, setDialogData] = useState({
    open: false,
    title: '',
    message: '',
    actionConfirm: null,
    textButtonConfirm: '',
    textButtonCancel: '',
  })
  const { handleSubmit } = useForm()

  const onBlurDate = () => {
    if (moment(formDataAccounts.startDate) <= moment(formDataAccounts.endDate)) {
      setFechaError(false)
      setMessage('')
    } else {
      setFechaError(true)
      setMessage('La fecha inicial debe de ser menor a la final')
    }
  }
  const handleCloseDialogData = () => {
    setDialogData({ ...dialogData, open: false })
    setIsLoading(false)
  }
  const onSubmit = async () => {
    const { startDate, endDate } = formDataAccounts
    if (startDate === '' && endDate === '') {
      setFechaError(true)
      return
    }
    if (moment(startDate) <= moment(endDate)) {
      setFechaError(false)
      setMessage('')
    } else {
      setFechaError(true)
      setMessage('La fecha inicial debe de ser menor a la final')
      return
    }
    setFechaError(false)
    setIsLoading(true)
    await axios
      .get(`${API_ORDERS}/ExportTransactions?StartDate=${startDate}&EndDate=${endDate}`, {
        responseType: 'blob',
      })
      .then((response) => {
        if (response.status === 203) {
          setDialogData({
            open: true,
            title: 'Transacción',
            // actionConfirm: returnToLuegoPagoSite,
            textButtonConfirm: 'Aceptar',
            textButtonCancel: null,
          })
        } else if (response.status === 200) {
          const link = document.createElement('a')
          link.href = window.URL.createObjectURL(new Blob([response.data], { type: 'text/xlsx' }))
          link.setAttribute('download', `Transacción_${new Date().toLocaleDateString()}.xlsx`)
          document.body.appendChild(link)
          link.click()
        }
        setIsLoading(false)
      })
      .catch((e) => {
        setIsLoading(false)

        console.log(e)
      })
  }

  return (
    <>
      <DialogAlert
        open={dialogData.open}
        title={dialogData.title}
        handleClose={handleCloseDialogData}
        personalized
        textButtonConfirm={dialogData.textButtonConfirm}
        message={dialogData.message}
        actionConfirm={dialogData.actionConfirm}
        textButtonCancel={dialogData.textButtonCancel}
      >
        <Grid container justify="center" direction="column" className={classes.gridInfo}>
          <Typography variant="body1">
            Este rango de fechas no tiene transacciones para mostrar.
          </Typography>
        </Grid>
      </DialogAlert>
      <Typography variant="subtitle1" align="left">
        Transacción
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <BackDrop open={isLoading} />
        <Grid
          container
          justify="flex-start"
          alignItems="flex-start"
          spacing={2}
          className={classes.inputTop}
        >
          <FormGroup className={classes.formGroupPriceStep}>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
              <InputLabel error={fechaError} className={classes.labelSize}>
                Fecha inicial
              </InputLabel>

              <DatePicker
                id="startDate"
                value={formDataAccounts.startDate ? formDataAccounts.startDate : null}
                error={fechaError}
                onChange={(value) => {
                  const date = new Date(value)

                  const month =
                    date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1

                  setFormDataAccounts({
                    ...formDataAccounts,
                    startDate: `${date.getFullYear()}-${month}-${date.getDate()} 00:00:00`,
                  })
                }}
                format="yyyy-MM-dd"
                inputVariant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <CalendarIcon />
                    </InputAdornment>
                  ),
                }}
                disablePast={false}
                KeyboardButtonProps={{ style: { padding: 0 } }}
              />
            </MuiPickersUtilsProvider>
          </FormGroup>

          <FormGroup className={classes.formGroupPriceStep}>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
              <InputLabel error={fechaError} className={classes.labelSize}>
                Fecha final
              </InputLabel>

              <DatePicker
                id="endDate"
                value={formDataAccounts.endDate ? new Date(formDataAccounts.endDate) : null}
                error={fechaError}
                onBlur={onBlurDate}
                onChange={(value) => {
                  const date = new Date(value)

                  const month =
                    date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1

                  setFormDataAccounts({
                    ...formDataAccounts,
                    endDate: `${date.getFullYear()}-${month}-${date.getDate()} 23:59:59`,
                  })
                }}
                format="yyyy-MM-dd"
                inputVariant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <CalendarIcon />
                    </InputAdornment>
                  ),
                }}
                disablePast={false}
                KeyboardButtonProps={{ style: { padding: 0 } }}
              />
            </MuiPickersUtilsProvider>
          </FormGroup>
        </Grid>
        <Typography variant="subtitle2" gutterBottom>
          {message}
        </Typography>
        <Grid
          container
          justify="flex-start"
          alignItems="flex-start"
          spacing={2}
          className={classes.inputTop}
        >
          <Button
            className={classes.btnBackgroundPrimary}
            id="generateCxp"
            name="generateCxp"
            disabled={isLoading}
            variant="contained"
            color="secondary"
            onClick={() => {
              onSubmit()
            }}
          >
            Descargar
          </Button>
        </Grid>
      </form>
    </>
  )
}

export default FormFechaTransaction
