/* eslint-disable import/order */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-case-declarations */
import React, { useContext, useEffect, useState } from 'react'

import {
  Button,
  Grid,
  IconButton,
  Link,
  TextField,
  Typography,
  Tooltip,
  Menu,
  MenuItem,
} from '@material-ui/core'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import axios from 'axios'
import MUIDataTable from 'mui-datatables'
import { useHistory } from 'react-router-dom'

import 'date-fns'

import FiltersOrders from './FiltersOrders'
import ModalProductsReadyToSend from './ModalProductsReadyToSend'
import useStyles from './orders.styles'
import { OrderPdfList, PrintOrders } from './service'

import BackDrop from 'components/BackDrop'
import DialogAlert from 'components/DialogAlert'
import { EyeIcon } from 'components/icons/personalizedIcons'
import ModalCancelationStepper from 'components/ModalCancelationStepper'
import ModalConfirmActionOrder from 'components/ModalConfirmActionOrder'
import useCustomDialogAlert from 'components/useCustomDialogAlert'
import { SUPER_ADMIN, ADMIN_SISTECREDITO } from 'constants/rolesConst'
import { routesLink } from 'constants/routesConsts'
import {
  TYPE,
  REASON_ORDER_CANCELATION,
  FILTER_TYPE_DIGITAL_CONTENT,
  FILTER_TYPE_PENDING_REVIEW,
} from 'constants/cancellationContantDigital'
import { API_ORDERS, API_PENDING_CONTENT_DIGITAL, API_ORDER_PENDING_REVIEW } from 'constants/urls'
import useAuthContext from 'contextApi/AuthContext'
import { useProduct } from 'contextApi/ProductContext'
import convertToCurrencyCop from 'utils/convertToCurrencyCop'
import {
  translateNameFiltertoType,
  translateNameToFilterId,
  validationNameFilterAdmin,
} from 'utils/filterState'
import { textLabels } from 'utils/functions'
import CheckIcon from '@material-ui/icons/Check'
import CloseIcon from '@material-ui/icons/Close'
import { currentDate } from 'utils/utils'

export default function Orders() {
  const audio = new Audio(
    'https://luegopago.blob.core.windows.net/luegopago-uploads/sellerCenter/sellerOrders/SILBIDO_RELASOL.mp3'
  )

  const history = useHistory()
  const classes = useStyles()
  const button = document.createElement('button')
  const showDialogAlert = useCustomDialogAlert()
  const { currentFilterOrders, getAccessToken, userLoggedin, setCurrentFilterOrders } =
    useContext(useAuthContext)
  const { setAllRowsSelectedContext, allRowsSelectedContext } = useProduct()
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingBackDrop, setIsLoadingBackDrop] = useState(false)
  const [data, setData] = useState([])
  const [allOrdersSelectedNum, setAllOrdersSelectedNum] = useState([])
  const [filterData, setFilterData] = useState(currentFilterOrders)
  const [valueSelectAccion, setValueSelectAccion] = useState('')
  const [firstLoad, setFirstLoad] = useState(true)
  const [isItemReadytoSendSelected, setIsItemReadytoSendSelected] = useState(false)
  const [isItemCompletedSelected, setIsItemCompletedSelected] = useState(false)
  const [total, setTotal] = useState(0)
  const [isOpenModalReadyToSend, setIsOpenModalReadyToSend] = useState(false)
  const [ordersReadyToSent, setOrdersReadyToSent] = useState([])
  const [filters, setFilters] = useState({})
  const [exportActionApply, setExportActionApply] = useState('')
  const [pagination, setPagination] = useState({
    page: 0,
    perPage: 10,
    prevPage: '',
    nextPage: '',
    typeFilter: 1,
  })
  const [dialogAlert, setDialogAlert] = useState({
    open: false,
    title: '',
    message: '',
    action: null,
  })
  const [modalActionConfirmOrders, setModalActionConfirmOrders] = useState({
    open: false,
    typeId: null,
    ordersId: [],
    actualTypeId: null,
  })
  const [optionsMenu, setOptionsMenu] = useState({ actualState: null, options: [], idOrder: null })
  const [open, setOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const ITEM_HEIGHT = 48
  const accessToken = getAccessToken()
  const getFilters = async (totalData, idFilter) => {
    await axios
      .get(`${API_ORDERS}/Filters`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          const getData = response.data
          setFilters(getData)
          if (!totalData) {
            setTotal(parseInt(getData.all, 10))
          } else {
            const filterName = translateNameToFilterId(idFilter)
            const totalFilter = getData[filterName]
            setTotal(parseInt(totalFilter, 10))
          }
        }
        setIsLoading(false)
      })
      .catch(() => {
        setIsLoading(false)
      })
  }

  const getModels = async (
    status = translateNameToFilterId(currentFilterOrders),
    page = null,
    perPage = null
  ) => {
    if (page === 0) {
      setPagination({
        ...pagination,
        page: page,
        perPage: perPage || 10,
      })
    }
    setIsLoadingBackDrop(true)
    setAllRowsSelectedContext([])
    setAllOrdersSelectedNum([])
    if (userLoggedin.role !== ADMIN_SISTECREDITO) setData([])
    const typeFilter = Number.isInteger(status) ? status : translateNameToFilterId(status)
    await axios
      .get(
        `${API_ORDERS}/filters/${typeFilter}/${perPage || pagination.perPage}/${
          page || pagination.page
        }`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          setData(response.data.data)
          const isDelivery = response.data.data.find((delivery) => delivery.fastDelivery)
          if (isDelivery && userLoggedin.role !== ADMIN_SISTECREDITO) {
            button.addEventListener('click', handleClick)
            button.click()
          }
          if (typeFilter !== 1) {
            getFilters(true, typeFilter)
          } else {
            getFilters(false)
          }
          setIsLoadingBackDrop(false)
        }
      })
      .catch((error) => {
        console.error(error)
        setIsLoadingBackDrop(false)
      })
  }
  const handleClick = () => {
    audio.play()
  }
  useEffect(() => {
    setFilterData(currentFilterOrders)
  }, [currentFilterOrders])

  const filterBy = (e, menu, status) => {
    setPagination({ ...pagination, page: 0 })
    setFilterData(status)
    if (status === 'Listo para enviar') {
      setIsItemReadytoSendSelected(true)
    } else if (
      [
        'Completado',
        'Entrega Fallida',
        'Cancelado',
        'Entrega Fallida',
        'Devuelto',
        'contentDigital',
        'PendingReview',
      ].includes(status)
    ) {
      setIsItemCompletedSelected(true)
    }
  }

  const handleCloseDialog = () => {
    setDialogAlert({ ...dialogAlert, open: false })
    setIsLoading(false)
  }

  const onClickOrderApplyAction = async (typeId, ordersId, actualTypeId) => {
    setModalActionConfirmOrders({
      open: true,
      typeId: typeId,
      ordersId: ordersId,
      actualTypeId: actualTypeId,
    })
  }

  const handleChangeSelectAccion = async (e, selectedRows) => {
    if (
      [
        '',
        'PrintAllItemSelected',
        'PrintGuideItemsSelected',
        'PrintListItemsSelected',
        'PrintDocsToDoOrders',
        'guia',
      ].includes(valueSelectAccion)
    ) {
      showDialogAlert(
        true,
        '¡Advertencia!',
        `Es necesario seleccionar una acción (cambio de estado) de la lista.`,
        'Cerrar',
        '',
        true
      )
    } else {
      e.preventDefault()
      const orderList = []
      selectedRows.data.forEach((Selected) => {
        const rowSelected = data.find((_, index) => index === Selected.index)
        const dataRow = rowSelected?.pedido
        orderList.push(dataRow)
      })
      onClickOrderApplyAction(
        translateNameFiltertoType(valueSelectAccion),
        orderList,
        translateNameToFilterId(filterData)
      )
    }
  }

  const openModalReadyToSend = (event, value) => {
    event.preventDefault()
    showDialogAlert(
      true,
      '¡Advertencia!',
      `¿Está seguro que desea cambiar el estado de 1 pedido(s) al estado Listo para enviar?`,
      'Confirmar',
      'Cancelar',
      false,
      () => {
        showDialogAlert(false)
        setOrdersReadyToSent([value])
        setIsOpenModalReadyToSend(true)
      }
    )
  }
  const handleClickListVerify = (orderState, idOrder) => {
    const stateArray = orderState.split('-')
    const response = { options: [], idOrder: idOrder }

    let nameState = ''
    if (stateArray.length > 1) {
      let priorityState = 0
      // eslint-disable-next-line array-callback-return
      stateArray.map((name) => {
        if (name.trim() === 'Procesando' && (!priorityState || priorityState > 1)) {
          nameState = name.trim()
          priorityState = 1
        } else if (name.trim() === 'Listo para enviar' && (!priorityState || priorityState > 2)) {
          nameState = name.trim()
          priorityState = 2
        } else if (name.trim() === 'Enviado' && (!priorityState || priorityState > 3)) {
          nameState = name.trim()
          priorityState = 3
        }
      })
    } else {
      const [name] = stateArray
      nameState = name
    }
    if (nameState.includes('Pendiente contenido digital')) {
      nameState = 'Pendiente contenido digital'
    }
    response.actualState = translateNameToFilterId(nameState)
    if (nameState === 'Procesando' && userLoggedin.role === SUPER_ADMIN) {
      return ['Cancelado']
    }
    if (nameState === 'Cancelado' && userLoggedin.role === SUPER_ADMIN) {
      return ['Completado']
    }
    if (nameState === 'Procesando') {
      return ['Por Enviar', 'Cancelado']
    }
    if (nameState === 'Listo para enviar' && userLoggedin.role === SUPER_ADMIN) {
      return ['Cancelado', 'Completado']
    }
    if (nameState === 'Enviado' && userLoggedin.role === SUPER_ADMIN) {
      return ['Completado', 'Cancelado']
    }
    if (nameState === 'Listo para enviar') {
      return ['Cancelado']
    }
    if (nameState === 'Enviado' || nameState === 'Pendiente contenido digital') {
      return ['Completado']
    }
    if (nameState === 'Posible Fraude' && userLoggedin.role === SUPER_ADMIN) {
      return ['Cancelado', 'procesando']
    }
    if (nameState === 'All' && userLoggedin.role === SUPER_ADMIN) {
      return []
    }
    if (nameState === 'Completado' && userLoggedin.role === SUPER_ADMIN) {
      return ['Cancelado']
    }
    return []
  }
  const handleClickList = (event, orderState, idOrder) => {
    const stateArray = orderState.split('-')
    const response = { options: [], idOrder: idOrder }
    let nameState = ''
    if (stateArray.length > 1) {
      let priorityState = 0
      // eslint-disable-next-line array-callback-return
      stateArray.map((name) => {
        if (name.trim() === 'Procesando' && (!priorityState || priorityState > 1)) {
          nameState = name.trim()
          priorityState = 1
        } else if (name.trim() === 'Listo para enviar' && (!priorityState || priorityState > 2)) {
          nameState = name.trim()
          priorityState = 2
        } else if (name.trim() === 'Enviado' && (!priorityState || priorityState > 3)) {
          nameState = name.trim()
          priorityState = 3
        }
      })
    } else {
      const [name] = stateArray
      nameState = name
    }
    if (nameState.includes('Pendiente contenido digital')) {
      nameState = 'Pendiente contenido digital'
    }
    response.actualState = translateNameToFilterId(nameState)
    if (nameState === 'Procesando' && userLoggedin.role === SUPER_ADMIN) {
      response.options = ['Cancelado']
    } else if (nameState === 'Cancelado' && userLoggedin.role === SUPER_ADMIN) {
      response.options = ['Completado']
    } else if (nameState === 'Listo para enviar' && userLoggedin.role === SUPER_ADMIN) {
      response.options = ['Cancelado', 'Completado']
    } else if (nameState === 'Completado' && userLoggedin.role === SUPER_ADMIN) {
      response.options = ['Cancelado']
    } else if (nameState === 'Enviado' && userLoggedin.role === SUPER_ADMIN) {
      response.options = ['Completado', 'Cancelado']
    } else if (nameState === 'Posible Fraude' && userLoggedin.role === SUPER_ADMIN) {
      response.options = ['Cancelado', 'Procesando']
    } else if (nameState === 'Procesando') {
      response.options = ['Por Enviar', 'Cancelado']
    } else if (nameState === 'Listo para enviar') {
      response.options = ['Cancelado']
    } else if (nameState === 'Enviado' || nameState === 'Pendiente contenido digital') {
      response.options = ['Completado']
    } else if (nameState === 'Posible Fraude') {
      response.options = ['Cancelado']
    } else {
      response.options = []
    }
    setOptionsMenu(response)
    setAnchorEl(event.currentTarget)
    setOpen(true)
  }

  const selectOption = (option, idOrder, event, actualState) => {
    if (option === 'Por Enviar') {
      openModalReadyToSend(event, idOrder)
    } else {
      onClickOrderApplyAction(translateNameFiltertoType(option), [idOrder], actualState)
    }
  }
  const cancellationOrders = async (numOrder, sellersList) => {
    setIsLoadingBackDrop(true)
    const sellerIdList = sellersList.map((item) => {
      return item.sellerId
    })
    const modelData = {
      filterType:
        filterData === 'contentDigital' ? FILTER_TYPE_DIGITAL_CONTENT : FILTER_TYPE_PENDING_REVIEW,
      type: TYPE,
      orderIdList: [numOrder],
      reasonOrderCancellation: REASON_ORDER_CANCELATION,
      reason: '',
      sellerIds: sellerIdList || [],
      notify: true,
      updateDate: currentDate(),
    }
    await axios
      .post(`${API_ORDERS}/UpdateOrderList`, modelData)
      .then((response) => {
        if (response.status === 200) {
          setIsLoadingBackDrop(false)
          showDialogAlert(
            true,
            '¡Cambio exitoso!',
            '¡Se rechazó correctamente la orden!',
            'Cerrar',
            '',
            true
          )
        }
      })
      .then(() => {
        getFilters()
        getModels()
      })
      .catch(() => {
        setIsLoadingBackDrop(false)
        showDialogAlert(
          true,
          '¡Error!',
          'Ocurrio un error al cambiar el estado de la orden',
          'Cerrar',
          '',
          true
        )
      })
  }
  const handleClose = async () => {
    setAnchorEl(null)
    setOpen(false)
  }

  const getSellersList = async (numOrder, filterType) => {
    try {
      const response = await axios.get(
        `${API_ORDERS}/GetSellersId?numberOrder=${numOrder}&orderFilterType=${filterType}`
      )

      if (response.status === 200) {
        return response.data
      }
      return null
    } catch (error) {
      console.error(error)
      return null
    }
  }

  const pendingProductsActions = (approved, numOrder) => {
    setIsLoadingBackDrop(true)
    getSellersList(
      numOrder,
      filterData === 'contentDigital' ? FILTER_TYPE_DIGITAL_CONTENT : FILTER_TYPE_PENDING_REVIEW
    ).then(async (res) => {
      if (res) {
        if (!approved) {
          cancellationOrders(numOrder, res)
        } else {
          await axios({
            url: `${
              filterData === 'contentDigital'
                ? API_PENDING_CONTENT_DIGITAL
                : API_ORDER_PENDING_REVIEW
            }?number=${numOrder}&approved=${approved}`,
            method: 'PUT',
          })
            .then((response) => {
              if (response.status === 200) {
                showDialogAlert(
                  true,
                  '¡Se aprobó correctamente la orden!',
                  null,
                  'Cerrar',
                  '',
                  true
                )
                getFilters()
                getModels()
              }
              setIsLoadingBackDrop(false)
            })
            .catch(() => {
              setIsLoadingBackDrop(false)
            })
        }
      } else {
        setIsLoadingBackDrop(false)
      }
    })
  }

  const columns = [
    {
      name: 'pedido',
      label: 'NÚMERO DE PEDIDO',
      options: {
        customHeadLabelRender: () => {
          return (
            <th style={{ fontFamily: 'Red Hat Display Black', fontSize: 13 }}>NÚMERO DE PEDIDO</th>
          )
        },
        customBodyRender: (value, tableMeta) => {
          return (
            <div>
              <Link
                href={`${routesLink.viewOrder}/${value}`}
                color="secondary"
                onClick={(event) => {
                  event.preventDefault()
                  history.push(`${routesLink.viewOrder}/${value}`)
                }}
              >
                <Typography variant="body1" style={{ marginLeft: '5%' }}>
                  {value}
                </Typography>
              </Link>
              {tableMeta.rowData[10] && userLoggedin.role !== ADMIN_SISTECREDITO && (
                <div className={classes.afterSameDay} />
              )}
            </div>
          )
        },
      },
    },
    {
      name: 'fecha',
      label: 'FECHA DE CREACIÓN',
      options: {
        customHeadLabelRender: () => {
          return (
            <th style={{ fontFamily: 'Red Hat Display Black', fontSize: 13 }}>FECHA DE CREACIÓN</th>
          )
        },
        customBodyRender: (value) => {
          return <div style={{ fontFamily: 'Red Hat Display Regular' }}>{value}</div>
        },
      },
    },
    {
      name: 'tiempolimiteDespacho',
      label: 'TIEMPO DE DESPACHO',
      options: {
        customHeadLabelRender: () => {
          return (
            <th style={{ fontFamily: 'Red Hat Display Black', fontSize: 13 }}>
              TIEMPO DE DESPACHO
            </th>
          )
        },
        customBodyRender: (value) => {
          return (
            <div style={{ fontFamily: 'Red Hat Display Regular', paddingLeft: '5%' }}>{value}</div>
          )
        },
      },
    },
    {
      name: 'metodoDePago',
      label: 'MÉTODO DE PAGO',
      options: {
        customHeadLabelRender: () => {
          return (
            <th style={{ fontFamily: 'Red Hat Display Black', fontSize: 13 }}>MÉTODO DE PAGO</th>
          )
        },
        customBodyRender: (value) => {
          return <div style={{ fontFamily: 'Red Hat Display Regular' }}>{value}</div>
        },
      },
    },
    {
      name: 'cantidadProductos',
      label: 'UNIDADES',
      options: {
        customBodyRender: (value) => {
          return (
            <div style={{ fontFamily: 'Red Hat Display Regular', paddingLeft: '10%' }}>
              {convertToCurrencyCop(Number(value))}
            </div>
          )
        },
        customHeadLabelRender: () => {
          return <th style={{ fontFamily: 'Red Hat Display Black', fontSize: 13 }}>UNIDADES</th>
        },
      },
    },
    {
      name: 'total',
      label: 'PRECIO',
      options: {
        customHeadLabelRender: () => {
          return <th style={{ fontFamily: 'Red Hat Display Black', fontSize: 13 }}>PRECIO</th>
        },
        customBodyRender: (value) => {
          return (
            <div style={{ fontFamily: 'Red Hat Display Regular' }}>
              {`$${convertToCurrencyCop(Number(value))}`}
            </div>
          )
        },
      },
    },
    {
      name: 'estado',
      label: 'ESTADO',
      options: {
        customHeadLabelRender: () => {
          return <th style={{ fontFamily: 'Red Hat Display Black', fontSize: 13 }}>ESTADO</th>
        },
        customBodyRender: (value, tableMeta) => {
          const text = value === 'Completado' ? 'Entregado' : value
          return (
            <div>
              {tableMeta.rowData[7] && (text === 'Cancelación Temporal' || text === 'Cancelado') ? (
                <>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={(e) => {
                      e.preventDefault()
                      onClickOrderApplyAction(
                        translateNameFiltertoType('Procesando'),
                        [tableMeta.rowData[0]],
                        translateNameFiltertoType('Devuelto')
                      )
                    }}
                    disabled={isLoading}
                    className={classes.btnApply}
                  >
                    Reactivar
                  </Button>
                </>
              ) : null}
              <Typography
                variant="body1"
                className={
                  text === 'Cancelado' && tableMeta.rowData[7] ? classes.stateCancelled : null
                }
              >
                {text}
              </Typography>
            </div>
          )
        },
      },
    },
    {
      name: 'reactivate',
      label: 'reactivate',
      options: { display: false },
    },

    {
      name: 'productCheckTransport',
      label: ' ',
      options: {
        display: !['Entrega Fallida', 'Devuelto'].includes(filterData),
        customBodyRender: (value, tableMeta) => {
          const filterValidation = validationNameFilterAdmin(currentFilterOrders)
          if (!filterValidation) {
            if (userLoggedin.role === ADMIN_SISTECREDITO) {
              return (
                <>
                  <Tooltip title="Aprobar">
                    <IconButton
                      className={classes.btnEdit}
                      onClick={(event) => {
                        event.preventDefault()
                        pendingProductsActions(true, tableMeta.rowData[0])
                      }}
                    >
                      <CheckIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Rechazar">
                    <IconButton
                      className={classes.btnDelete}
                      onClick={(event) => {
                        event.preventDefault()
                        pendingProductsActions(false, tableMeta.rowData[0])
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Tooltip>
                </>
              )
            }
            const clickList = handleClickListVerify(tableMeta.rowData[6], tableMeta.rowData[0])
            return tableMeta.rowData[6] !== 'Cancelado' ? (
              <div>
                <Tooltip title="Ver">
                  <IconButton
                    className={classes.iconEye}
                    onClick={(event) => {
                      event.preventDefault()
                      history.push(`${routesLink.viewOrder}/${tableMeta.rowData[0]}`)
                    }}
                  >
                    <EyeIcon />
                  </IconButton>
                </Tooltip>
                {/* empieza */}
                {clickList.length !== 0 &&
                  (tableMeta.rowData[6] === 'Enviado' ? (
                    tableMeta.rowData[8] || userLoggedin.role === SUPER_ADMIN ? (
                      <IconButton
                        onClick={(event) => {
                          handleClickList(event, tableMeta.rowData[6], tableMeta.rowData[0])
                        }}
                      >
                        <MoreVertIcon />
                      </IconButton>
                    ) : null
                  ) : tableMeta.rowData[6].includes('-') &&
                    userLoggedin.role === SUPER_ADMIN ? null : (
                    <IconButton
                      onClick={(event) => {
                        handleClickList(event, tableMeta.rowData[6], tableMeta.rowData[0])
                      }}
                    >
                      <MoreVertIcon />
                    </IconButton>
                  ))}

                {/* Termina */}
                <div>
                  <Menu
                    id="long-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={open}
                    onClose={handleClose}
                    PaperProps={{
                      style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: '38ch',
                        boxShadow: '-6px 6px #E8E8E8',
                        fontFamily: 'Red Hat Display Regular',
                      },
                    }}
                  >
                    {optionsMenu
                      ? optionsMenu?.options.map((option) => {
                          return (
                            // eslint-disable-next-line react/jsx-indent
                            <>
                              <MenuItem
                                selected={option === 'Pyxis'}
                                onClick={(event) => {
                                  event.preventDefault()
                                  selectOption(
                                    option,
                                    optionsMenu.idOrder,
                                    event,
                                    optionsMenu.actualState
                                  )
                                  handleClose()
                                }}
                                style={{ fontFamily: 'Red Hat Display Regular' }}
                              >
                                {option}
                              </MenuItem>
                            </>
                          )
                        })
                      : null}
                  </Menu>
                </div>
              </div>
            ) : (
              <div>
                {tableMeta.rowData[9] && (
                  <IconButton
                    onClick={(event) => {
                      handleClickList(event, tableMeta.rowData[6], tableMeta.rowData[0])
                    }}
                  >
                    <MoreVertIcon />
                  </IconButton>
                )}

                <div>
                  <Menu
                    id="long-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={open}
                    onClose={handleClose}
                    PaperProps={{
                      style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: '38ch',
                        boxShadow: '-6px 6px #E8E8E8',
                        fontFamily: 'Red Hat Display Regular',
                      },
                    }}
                  >
                    {optionsMenu
                      ? optionsMenu?.options.map((option) => {
                          return (
                            // eslint-disable-next-line react/jsx-indent
                            <>
                              <MenuItem
                                selected={option === 'Pyxis'}
                                onClick={(event) => {
                                  event.preventDefault()
                                  selectOption(
                                    option,
                                    optionsMenu.idOrder,
                                    event,
                                    optionsMenu.actualState
                                  )
                                  handleClose()
                                }}
                                style={{ fontFamily: 'Red Hat Display Regular' }}
                              >
                                {option}
                              </MenuItem>
                            </>
                          )
                        })
                      : null}
                  </Menu>
                </div>
              </div>
            )
          }
        },
      },
    },
    {
      name: 'isDigitalContent',
      label: 'isDigitalContent',
      options: { display: false },
    },
    {
      name: 'fastDelivery',
      label: 'fastDelivery',
      options: { display: false },
    },
  ]

  const exportAction = async () => {
    const status = translateNameToFilterId(currentFilterOrders)
    const typeFilter = Number.isInteger(status) ? status : translateNameToFilterId(status)
    setIsLoadingBackDrop(true)
    switch (exportActionApply) {
      case 'allOrders':
        await axios({
          url: `${API_ORDERS}/Export?productFilterTypeId=${typeFilter}`,
          method: 'GET',
          responseType: 'blob',
        })
          .then((response) => {
            setAllOrdersSelectedNum([])
            setAllRowsSelectedContext([])
            if (response.status === 200) {
              const link = document.createElement('a')
              link.href = window.URL.createObjectURL(new Blob([response.data]))
              link.setAttribute('download', `Pedidos${Date.now()}.xlsx`)
              document.body.appendChild(link)
              link.click()
            }
            setIsLoadingBackDrop(false)
          })
          .catch(() => {
            setAllOrdersSelectedNum([])
            setIsLoadingBackDrop(false)
          })
        break
      case 'checkOrders':
        const tempOrder = allOrdersSelectedNum.map((order) => {
          return order.pedido
        })
        await axios
          .post(
            `${API_ORDERS}/ExportSelectedOrders`,
            {
              type: typeFilter,
              orderIdList: tempOrder,
            },
            { responseType: 'blob' }
          )
          .then((response) => {
            setAllOrdersSelectedNum([])
            setAllRowsSelectedContext([])
            if (response.status === 200) {
              const link = document.createElement('a')
              link.href = window.URL.createObjectURL(
                new Blob([response.data], { type: 'text/xlsx' })
              )
              link.setAttribute('download', `Pedidos${Date.now()}.xlsx`)
              document.body.appendChild(link)
              link.click()
            }

            setIsLoadingBackDrop(false)
          })
          .catch(() => {
            setAllOrdersSelectedNum([])
            setIsLoadingBackDrop(false)
          })
        break
      default:
        setIsLoadingBackDrop(false)
        break
    }
  }

  const printOrderDocuments = async (typeFilter) => {
    switch (typeFilter) {
      case 'PrintDocsToDoOrders':
        setIsLoadingBackDrop(true)
        OrderPdfList(
          allOrdersSelectedNum,
          setAllOrdersSelectedNum,
          setAllRowsSelectedContext,
          setIsLoadingBackDrop,
          showDialogAlert
        )
        break
      case 'PrintListItemsSelected':
        setIsLoadingBackDrop(true)
        PrintOrders(
          currentFilterOrders,
          setIsLoadingBackDrop,
          allOrdersSelectedNum,
          setAllOrdersSelectedNum,
          setAllRowsSelectedContext,
          'PrintOrdersSelectedItem'
        )
        break
      case 'guia':
        setIsLoadingBackDrop(true)
        PrintOrders(
          currentFilterOrders,
          setIsLoadingBackDrop,
          allOrdersSelectedNum,
          setAllOrdersSelectedNum,
          setAllRowsSelectedContext,
          'PrintOrdersSelectedGuide'
        )
      // eslint-disable-next-line no-fallthrough
      default:
        break
    }
  }

  const showMenuItemsStatu = (status, selectedRows) => {
    const exportMenu = (
      <>
        <TextField
          id="export-native-select"
          select
          className={classes.exportButton}
          onChange={(e) => {
            e.preventDefault()
            setExportActionApply(e.target.value)
          }}
          SelectProps={{
            native: true,
          }}
          variant="outlined"
        >
          <option value="">Seleccione</option>
          <option value="allOrders">Exportar todos los pedidos.</option>
          <option value="checkOrders">Exportar todos los pedidos seleccionados.</option>
        </TextField>
      </>
    )

    const options = [<option value="">Seleccione</option>]
    switch (status) {
      case 'Procesando':
        options.push(
          <option value="PrintDocsToDoOrders">Imprimir documentos para despachar órdenes. </option>
        )
        options.push(<option value="Listo para enviar">Pasar a “Por enviar” </option>)

        break
      case 'Listo para enviar':
        options.push(
          <option value="PrintListItemsSelected">Imprimir lista de ítems seleccionados.</option>
        )
        options.push(<option value="guia">Imprimir guía para los items seleccionados </option>)
        break
      case 'Enviado':
        options.push(
          <option value="PrintListItemsSelected">Imprimir lista de ítems seleccionados.</option>
        )
        options.push(<option value="guia">Imprimir guía para los items seleccionados </option>)
        options.push(<option value="Completado">Cambiar el estado a “completado” </option>)

        break
      case 'Completado':
        options.push(
          <option value="PrintListItemsSelected">Imprimir lista de ítems seleccionados.</option>
        )
        break
      default:
        break
    }

    const actionsMenu = (
      <>
        <TextField
          id="actions"
          select
          className={classes.actionsButton}
          onChange={(e) => {
            e.preventDefault()
            setValueSelectAccion(e.target.value)
          }}
          SelectProps={{
            native: true,
          }}
          variant="outlined"
        >
          {options}
        </TextField>
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={(e) => {
            if (
              ['PrintDocsToDoOrders', 'PrintListItemsSelected', 'guia'].includes(valueSelectAccion)
            ) {
              printOrderDocuments(valueSelectAccion)
            } else {
              handleChangeSelectAccion(e, selectedRows)
            }
          }}
          disabled={isLoading}
          className={classes.btnBackgroundPrimary}
        >
          Aplicar
        </Button>
      </>
    )
    return (
      <>
        {filterData !== 'Cancelado' ? actionsMenu : null}
        {exportMenu}
      </>
    )
  }

  const changePageTable = (paginationpage) => {
    let pageInt = Number(paginationpage) + 1
    if (paginationpage === 1 && paginationpage === pagination.page) {
      pageInt = 1
    }
    setPagination({
      ...pagination,
      page: pageInt - 1,
      prevPage: pageInt - 1,
      nextPage: pageInt + 1,
    })
    getModels(translateNameToFilterId(filterData), pageInt)
  }

  const changePerPagetable = (value) => {
    getModels(filterData, 0, value)
    setPagination({ ...pagination, perPage: value })
  }

  const setResultadosVentanaBusqueda = (results) => {
    setData(results)
  }

  const MasiveContainer = (selectedRows = null, displayData = null) => {
    return (
      <>
        {selectedRows !== null && displayData !== null ? (
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={(e) => {
              exportAction()
            }}
            disabled={isLoading}
            className={classes.btnBackgroundPrimary}
          >
            Aplicar
          </Button>
        ) : null}
      </>
    )
  }

  const customToolbarSelect = (selectedRows, displayData) => {
    return (
      <Grid container className={classes.toolBarSelect}>
        {showMenuItemsStatu(filterData, selectedRows)}

        {MasiveContainer(selectedRows, displayData, 'clase')}
      </Grid>
    )
  }
  const selectedOrders = (rowsSelected) => {
    if (rowsSelected) {
      let newSelectedProduct = []
      rowsSelected.forEach((Selected) => {
        newSelectedProduct = [...newSelectedProduct, data[Selected]]
      })

      setAllOrdersSelectedNum(newSelectedProduct)
    }
  }
  const options = {
    print: false,
    search: false,
    serverSide: true,
    filter: false,
    sort: false,
    viewColumns: false,
    textLabels: textLabels(isLoading),
    download: false,
    page: pagination.page,
    rowsPerPage: pagination.perPage,
    filterType: 'multiselect',
    fixedSelectColumn: false,
    selectableRows:
      filterData === 'All' ||
      (filterData === 'Listo para enviar' && !isItemReadytoSendSelected) ||
      (filterData === 'Completado' && !isItemCompletedSelected) ||
      userLoggedin.role === ADMIN_SISTECREDITO ||
      userLoggedin.role === SUPER_ADMIN
        ? 'none'
        : 'multiple',
    responsive: 'scrollMaxHeight',
    count: total,
    onTableChange: (action, tableState) => {
      switch (action) {
        case 'changePage':
          changePageTable(tableState.page === 0 ? 1 : tableState.page)
          document.querySelector('#main').scrollTop = 0
          break
        case 'changeRowsPerPage':
          changePerPagetable(tableState.rowsPerPage)
          break
        default:
          break
      }
    },
    onRowSelectionChange: (currentRowsSelected, allRowsSelected, rowsSelected) => {
      selectedOrders(rowsSelected)
      setAllRowsSelectedContext(rowsSelected)
    },
    rowsSelected: allRowsSelectedContext,
    customToolbarSelect,
  }

  useEffect(() => {
    if (firstLoad) {
      setFirstLoad(false)
      if (userLoggedin.role === ADMIN_SISTECREDITO) {
        setFilterData('contentDigital')
        setCurrentFilterOrders('contentDigital')
      } else {
        getModels()
      }
    } else {
      setPagination({
        ...pagination,
        page: 0,
        perPage: 10,
      })
      getModels(filterData, 0, 10)
    }
    return () => {
      setAllRowsSelectedContext([])
      audio.pause()
    }
  }, [filterData])

  return (
    <>
      <BackDrop open={isLoadingBackDrop} />
      <Grid container justify="space-between" className={classes.titleOrders}>
        <Typography variant="h6" style={{ color: '#6064FF' }}>
          Listado de pedidos
        </Typography>
      </Grid>
      {isOpenModalReadyToSend && (
        <ModalProductsReadyToSend
          orders={ordersReadyToSent}
          onClose={() => setIsOpenModalReadyToSend(false)}
          getFilters={getFilters}
          getModels={getModels}
        />
      )}
      <Grid container direction="column" justify="space-between" className={classes.divContent}>
        <FiltersOrders
          getModels={getModels}
          filterBy={filterBy}
          filters={filters}
          filterData={filterData}
          setResultadosVentanaBusqueda={setResultadosVentanaBusqueda}
        />
        <MUIDataTable data={data} columns={columns} options={options} />
        <DialogAlert
          open={dialogAlert.open}
          title={dialogAlert.title}
          handleClose={handleCloseDialog}
          message={dialogAlert.message}
          textButtonConfirm="Aceptar"
          actionConfirm={dialogAlert.action}
          textButtonCancel="Cancelar"
        />
        {modalActionConfirmOrders.ordersId.length > 1 || userLoggedin.role !== 'SuperAdmin' ? (
          <ModalConfirmActionOrder
            open={modalActionConfirmOrders?.open}
            typeId={modalActionConfirmOrders?.typeId}
            ordersId={modalActionConfirmOrders?.ordersId}
            infoOrders={modalActionConfirmOrders}
            handleClose={setModalActionConfirmOrders}
            setIsLoadingBackDrop={setIsLoadingBackDrop}
            setOrdersReadyToSent={setOrdersReadyToSent}
            setIsOpenModalReadyToSend={setIsOpenModalReadyToSend}
            setValueSelectAccion={setValueSelectAccion}
            filterBy={filterBy}
            getModels={getModels}
            filterData={filterData}
          />
        ) : (
          <ModalCancelationStepper
            open={modalActionConfirmOrders.open}
            handleClose={setModalActionConfirmOrders}
            infoOrder={modalActionConfirmOrders}
            setIsLoadingBackDrop={setIsLoadingBackDrop}
            setOrdersReadyToSent={setOrdersReadyToSent}
            setIsOpenModalReadyToSend={setIsOpenModalReadyToSend}
            filterBy={filterBy}
            filterData={filterData}
            getModels={getModels}
            setValueSelectAccion={setValueSelectAccion}
          />
        )}
        {alert}
      </Grid>
    </>
  )
}
