import React from 'react'

/** Material UI Components */
import { AppBar, Toolbar, Hidden, Link } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import { LuegopagoIconWhite } from 'components/icons/personalizedIcons'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    [theme.breakpoints.down('sm')]: {
      marginLeft: '50%',
      transform: 'translateX(-50%)',
    },
    cursor: 'pointer',
  },
  navbar: {
    paddingLeft: '10%',
    paddingRight: '10%',
    backgroundColor: '#5E44FF',
  },
  logo: {
    fill: 'white',
  },
  white: {
    color: 'white',
  },
}))

export default function Header() {
  const classes = useStyles()
  /** App Storage */
  const goMain = () => {
    // window.location.assign(AppEnv.frontendBaseUrl)
  }

  return (
    <header>
      <div className={classes.root}>
        <AppBar position="fixed" color="inherit" className={classes.navbar}>
          <Toolbar>
            <div className={classes.title}>
              <button
                type="button"
                style={{
                  background: 'none',
                  color: 'inherit',
                  border: 'none',
                  padding: 0,
                  font: 'inherit',
                  cursor: 'pointer',
                  outline: 'inherit',
                }}
                onClick={goMain}
              >
                <LuegopagoIconWhite />
              </button>
            </div>
            <Hidden smDown>
              <Link
                component="a"
                target="_blank"
                className={classes.white}
                variant="subtitle2"
                href="https://luegopago.com/"
              >
                Ir a luegopaGO.com
              </Link>
            </Hidden>
          </Toolbar>
        </AppBar>
      </div>
    </header>
  )
}
