import React, { useEffect, useState } from 'react'

import {
  Button,
  Checkbox,
  DialogActions,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Tooltip,
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import MUIDataTable from 'mui-datatables'
import { useForm } from 'react-hook-form'

import InputField from 'components/InputField'
import { VOUCHER } from 'constants/rolesConst'
import useStyles from 'views/Products/products.styles'

const RulesStep = ({
  info,
  handleBack,
  handleSubmitStep,
  ruleConditions,
  ruleTypes,
  rules,
  addRule,
  removeRule,
  sellerInfo,
  useTypes,
}) => {
  const [selected, setSelected] = useState({
    ruleTypeId: '',
    conditionId: '',
  })
  const [isBond, setIsBond] = useState()
  const [userId, setUserId] = useState()
  const [isOptional, setIsOptional] = useState(false)
  const {
    register,
    handleSubmit,
    errors,
    reset,
    setValue,
    formState: { isValid },
  } = useForm({
    mode: 'onChange',
  })
  const classes = useStyles()

  const onSubmit = (data) => {
    addRule({
      ...data,
      isOptional,
      ruleTypeId: selected.ruleTypeId.id,
      conditionId: selected.conditionId.id,
    })
    reset()
    setIsOptional(false)
    setSelected({ ruleTypeId: null, conditionId: null })
    if (isBond) {
      setSelected({
        conditionId: null,
        ruleTypeId: userId,
      })
    }
  }

  useEffect(() => {
    const bondIdFind = useTypes.find((use) => use.uniqueId === 5)?.id
    const userIdFind = ruleTypes.find((rule) => rule.uniqueId === 4)
    if (bondIdFind === info.useType) {
      setIsBond(true)
      setUserId(userIdFind)
      setSelected({
        conditionId: null,
        ruleTypeId: userIdFind,
      })
    }
  }, [])

  return (
    <div>
      <Grid container direction="row" xs={12} spacing={3}>
        <Grid item xs={6}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <InputField
              label="Tipo de regla"
              error={errors.ruleTypeId && errors.ruleTypeId.message}
            >
              <Select
                variant="outlined"
                name="ruleTypeId"
                value={selected.ruleTypeId}
                disabled={isBond}
                onChange={(e) => {
                  setSelected({ conditionId: '', ruleTypeId: e.target.value })
                  setValue('value', '')
                }}
              >
                {ruleTypes.map((item) => {
                  if (sellerInfo.sellerId !== '1' && item.uniqueId === 6) return null
                  return (
                    <MenuItem key={item.id} value={item}>
                      {item.name}
                    </MenuItem>
                  )
                })}
              </Select>
            </InputField>
            <InputField label="Condición" error={errors.ruleTypeId && errors.ruleTypeId.message}>
              <Select
                variant="outlined"
                name="conditionId"
                value={selected.conditionId}
                onChange={(e) => {
                  setSelected({ ...selected, conditionId: e.target.value })
                }}
              >
                {ruleConditions.map((item) => (
                  <MenuItem
                    key={item.id}
                    disabled={
                      !selected.ruleTypeId ||
                      (selected.ruleTypeId?.conditionType !== item.type && item.type !== 3)
                    }
                    value={item}
                  >
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </InputField>
            <InputField
              label="Valor"
              name="value"
              error={errors.value && errors.value.message}
              ref={register({
                validate: {
                  required: (value) => value.length > 0 || 'El valor es requerido',
                  isNumber: (value) => {
                    if (selected.ruleTypeId?.conditionType === 1) {
                      // is number regex
                      return /^\d+$/.test(value) || 'El valor debe ser un número'
                    }
                    return true
                  },
                },
              })}
            />
            <FormControlLabel
              label="Definir regla como opcional"
              control={
                <Checkbox
                  color="primary"
                  checked={isOptional}
                  onChange={(e) => {
                    setIsOptional(e.target.checked)
                  }}
                />
              }
            />
            <br />
            <br />
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={!isValid || !selected.conditionId || !selected.ruleTypeId}
            >
              Añadir regla
            </Button>
          </form>
        </Grid>
        <Grid item xs={6}>
          <MUIDataTable
            options={{
              search: false,
              print: false,
              download: false,
              viewColumns: false,
              filter: false,
              selectableRows: 'none',
              pagination: false,
              tableBodyMaxHeight: '300px',
              responsive: 'vertical',
              textLabels: {
                body: {
                  noMatch: `No hay reglas para este ${
                    sellerInfo.role === VOUCHER ? 'bono' : 'cupón'
                  }`,
                },
              },
            }}
            columns={[
              {
                name: 'ruleTypeId',
                label: 'Tipo de regla',
                options: {
                  customBodyRender: (value) => ruleTypes.find((item) => item.id === value).name,
                },
              },
              {
                name: 'conditionId',
                label: 'Condición',
                options: {
                  customBodyRender: (value) =>
                    ruleConditions.find((item) => item.id === value).name,
                },
              },
              { name: 'value', label: 'Valor' },
              {
                name: 'isOptional',
                label: 'Opcional',
                options: {
                  customBodyRender: (value) => (value ? 'Si' : 'No'),
                },
              },
              {
                label: 'Acciones',
                name: 'Actions',
                options: {
                  customHeadLabelRender: () => '',
                  customBodyRender: (value, tableMeta) => {
                    return (
                      <Tooltip title="Eliminar">
                        <IconButton
                          onClick={(event) => {
                            removeRule(tableMeta.rowIndex)
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    )
                  },
                },
              },
            ]}
            data={rules}
          />
        </Grid>
      </Grid>
      <DialogActions>
        <Button onClick={handleBack} className={classes.btnBackgroundErrorPriceSmall}>
          Atrás
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            handleSubmitStep(rules)
          }}
          className={classes.btnNewProductsmall}
        >
          {/* {info?.id ? 'Actualizar ' : 'Crear '} */}
          Siguiente
        </Button>
      </DialogActions>
    </div>
  )
}

export default RulesStep
