/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react'

import axios from 'axios'

import BackDrop from 'components/BackDrop'
import { routeBase } from 'constants/environments.constants'
import { SUPER_ADMIN } from 'constants/rolesConst'
import { API_SELLERS } from 'constants/urls'
import useAuthContext from 'contextApi/AuthContext'
import { filterArrayNulls } from 'utils/functions'

const ReportsDashboard = () => {
  const { userLoggedin } = useContext(useAuthContext)
  const [isLoading, setIsLoading] = useState(false)
  const [microData, setMicroData] = useState({
    isAdmin: false,
    stores: [],
  })

  const loadStoresList = async (total = 3000) => {
    try {
      if (userLoggedin.role === SUPER_ADMIN) {
        setIsLoading(true)
        const response = await axios.get(`${API_SELLERS}?sellerFilterType=1&pageSize=${total}`)
        const filterData = filterArrayNulls(response.data.data, 'userName')
        setMicroData({
          isAdmin: userLoggedin.role === SUPER_ADMIN,
          stores: filterData,
        })
      }
    } catch (err) {
      console.error(err)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    const script = document.createElement('script')
    script.src = routeBase[process.env.REACT_APP_ENVIRONMENT].dashboard
    script.async = true
    loadStoresList().then(() => {
      document.getElementById('dashboard-micro').appendChild(script)
    })
    return () => {
      document.getElementById('dashboard-micro').removeChild(script)
    }
  }, [])
  return (
    <>
      <BackDrop open={isLoading} />
      <div
        id="dashboard-micro"
        data-isadmin={JSON.stringify(microData.isAdmin)}
        data-stores={JSON.stringify(microData.stores)}
      />
    </>
  )
}

export default ReportsDashboard
