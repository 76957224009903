export const baseRouteBackoffice = '/admin'

export const routesConst = {
  itineraries: '/itineraries',
  dashboard: '/dashboard',
  permissions: '/permissions',
  users: '/users',
  products: '/products',
  productsBySeller: '/productsBySeller/:idSeller',
  approvals: '/approvals',
  pendingApproval: '/pending-approval/:idSeller',
  importProducts: '/import-products',
  updateProducts: '/update-products',
  editProduct: '/edit-product/:id',
  adminEditProduct: '/admin-edit-product/:id/:idSeller',
  approveProduct: '/approve-proaccountsReceiveduct/:id/:idSeller',
  saleReport: '/sales-report',
  addProduct: '/add-product',
  productsImages: '/productimages/',
  roles: '/roles',
  orders: '/orders',
  returns: '/returns',
  viewOrder: '/view-order/:idorder',
  itineraryDetails: '/itinerary-details/:iditinerary',
  Shipping: '/Shipping',
  shopify: '/shopify-products',
  integrations: '/integrations',
  configuration: '/configuration',
  configSeller: '/configSeller',
  financialSummary: '/financialSummary',
  categories: '/categories',
  me: '/me',
  copyProduct: '/copy-product/:id',
  storeProfile: '/store-Profile',
  storeCommission: '/storeCommission',
  userManagement: '/user-Management',
  commisions: '/commisions',
  enabledMethods: '/enabled-methods',
  configNotifications: '/config-notifications',
  generalConfiguration: '/generalConfiguration',
  stores: '/stores',
  brands: '/brands',
  storeDetail: '/store-detail/:id',
  transaction: '/transaction',
  conciliator: '/conciliator',
  accountsToPay: '/accounts-to-pay',
  accountsReceive: '/accounts-receive',
  orderReport: '/order-report',
  accountStatus: '/accountStatus',
  sendGrid: '/sendGrid',
  error404: '/404',
  bonuses: '/bonuses',
  coupons: '/coupons',
  createCoupon: '/create-coupons',
  createRules: '/create-rules',
  error500: '/500',
  createBonus: '/bonos/:nit',
  salesManagement: '/sales-management',
  salesSummary: '/sales-summary',
  storeEnlistment: '/store-enlistment',
  soatOrders: '/soat-orders',
}

export const routesLink = {
  login: '/login',
  dashboard: `${baseRouteBackoffice}${routesConst.dashboard}`,
  approvals: `${baseRouteBackoffice}${routesConst.approvals}`,
  pendingApproval: `${baseRouteBackoffice}/pending-approval`,
  reports: `${baseRouteBackoffice}${routesConst.reports}`,
  products: `${baseRouteBackoffice}${routesConst.products}`,
  productsBySeller: `${baseRouteBackoffice}/productsBySeller`,
  productsImages: `${baseRouteBackoffice}${routesConst.productsImages}`,
  editProduct: `${baseRouteBackoffice}/edit-product`,
  adminEditProduct: `${baseRouteBackoffice}/admin-edit-product`,
  copyProduct: `${baseRouteBackoffice}/copy-product`,
  approveProduct: `${baseRouteBackoffice}/approve-product`,
  saleReport: `${baseRouteBackoffice}/sale-report`,
  addProduct: `${baseRouteBackoffice}${routesConst.addProduct}`,
  shopify: `${baseRouteBackoffice}${routesConst.shopify}`,
  integrations: `${baseRouteBackoffice}${routesConst.integrations}`,
  importProducts: `${baseRouteBackoffice}${routesConst.importProducts}`,
  updateProducts: `${baseRouteBackoffice}${routesConst.updateProducts}`,
  configSeller: `${baseRouteBackoffice}${routesConst.configSeller}`,
  financialSummary: `${baseRouteBackoffice}${routesConst.financialSummary}`,
  categories: `${baseRouteBackoffice}${routesConst.categories}`,
  viewOrder: `${baseRouteBackoffice}/view-order`,
  itineraryDetails: `${baseRouteBackoffice}/itinerary-details`,
  storeProfile: `${baseRouteBackoffice}/store-Profile`,
  storeCommission: `${baseRouteBackoffice}/storeCommission`,
  userManagement: `${baseRouteBackoffice}/user-Management`,
  commisions: `${baseRouteBackoffice}${routesConst.commisions}`,
  enabledMethods: `${baseRouteBackoffice}${routesConst.enabledMethods}`,
  configNotifications: `${baseRouteBackoffice}${routesConst.configNotifications}`,
  stores: `${baseRouteBackoffice}${routesConst.stores}`,
  brands: `${baseRouteBackoffice}${routesConst.brands}`,
  orders: `${baseRouteBackoffice}${routesConst.orders}`,
  storeDetail: `${baseRouteBackoffice}/store-detail`,
  accountStatus: `${baseRouteBackoffice}/accountStatus`,
  sendGrid: `${baseRouteBackoffice}/sendGrid`,
  me: `${baseRouteBackoffice}${routesConst.me}`,
  error404: `${baseRouteBackoffice}/404`,
  error500: `${baseRouteBackoffice}/500`,
  bonuses: `${baseRouteBackoffice}/${routesConst.bonuses}`,
  coupons: `${baseRouteBackoffice}/${routesConst.coupons}`,
  createCoupon: `${baseRouteBackoffice}/${routesConst.createCoupon}`,
  createRules: `${baseRouteBackoffice}/${routesConst.createRules}`,
  salesSummary: `${baseRouteBackoffice}/${routesConst.salesSummary}`,
  storeEnlistment: `${baseRouteBackoffice}${routesConst.storeEnlistment}`,
  soatOrders: `${baseRouteBackoffice}${routesConst.soatOrders}`,
}
