/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react'

import { Button, DialogActions, Grid, Typography } from '@material-ui/core'
import MUIDataTable from 'mui-datatables'

import { VOUCHER } from 'constants/rolesConst'
import useStyles from 'views/Products/products.styles'

const OverviewStep = ({
  handleBack,
  handleSubmitStep,
  info,
  ruleConditions,
  ruleTypes,
  useTypes,
  rules,
  sellerInfo,
  isMultiple,
}) => {
  const classes = useStyles()
  const productType = [5, 6].includes(useTypes.find((type) => type.id === info.useType)?.uniqueId)
    ? ' bono'
    : ' cupón'
  return (
    <div>
      <p>
        ¡Estás a punto de
        {info?.id ? ' modificar ' : ' crear '}
        un
        {productType}. Verifica los datos antes de continuar!
      </p>
      <br />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="subtitle1">Nombre del {productType}</Typography>
          <p>{info.couponName}</p>
        </Grid>
        <Grid item xs={6}>
          {isMultiple ? (
            <>
              <Typography variant="subtitle1">Cantidad</Typography>
              <p>{info.quantity}</p>
            </>
          ) : (
            <>
              <Typography variant="subtitle1">Codigo del {productType}</Typography>
              <p>{info.couponCode}</p>
            </>
          )}
        </Grid>
        <Grid item xs={6}>
          <Typography variant="subtitle1">Valor del {productType}</Typography>
          <p>
            {info.discountType === 3 && '$ '}
            {info.couponValue}
            {info.discountType === 4 && ' %'}
          </p>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="subtitle1">Fecha inicial</Typography>
          <p>
            {new Date(info.initialDate).toLocaleDateString('es-ES', {
              year: 'numeric',
              month: 'long',
              day: 'numeric',
              hour: 'numeric',
              minute: 'numeric',
              hour12: true,
            })}
          </p>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="subtitle1">Fecha final</Typography>
          <p>
            {new Date(info.finalDate).toLocaleDateString('es-ES', {
              year: 'numeric',
              month: 'long',
              day: 'numeric',
              hour: 'numeric',
              minute: 'numeric',
              hour12: true,
            })}
          </p>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="subtitle1">Tipo de uso</Typography>
          <p>{useTypes.find((type) => type.id === info.useType)?.name}</p>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="subtitle1">¿Es acumulable con otros cupones?</Typography>
          <p>{info.isCumulative ? 'Si' : 'No'}</p>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="subtitle1">¿Es exclusivo para usuarios registrados?</Typography>
          <p>{info.isForUsersRegistered ? 'Si' : 'No'}</p>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle1">Reglas</Typography>
          <MUIDataTable
            options={{
              search: false,
              print: false,
              download: false,
              viewColumns: false,
              filter: false,
              selectableRows: 'none',
              pagination: false,
              tableBodyMaxHeight: '300px',
              responsive: 'vertical',
              textLabels: {
                body: {
                  noMatch: `No hay reglas para este ${
                    sellerInfo.role === VOUCHER ? 'bono' : 'cupón'
                  }`,
                },
              },
            }}
            columns={[
              {
                name: 'ruleTypeId',
                label: 'Tipo de regla',
                options: {
                  customBodyRender: (value) => ruleTypes.find((item) => item.id === value).name,
                },
              },
              {
                name: 'conditionId',
                label: 'Condición',
                options: {
                  customBodyRender: (value) =>
                    ruleConditions.find((item) => item.id === value).name,
                },
              },
              { name: 'value', label: 'Valor' },
              {
                name: 'isOptional',
                label: 'Opcional',
                options: {
                  customBodyRender: (value) => (value ? 'Si' : 'No'),
                },
              },
            ]}
            data={rules}
          />
        </Grid>
      </Grid>
      <DialogActions>
        <Button onClick={() => handleBack(info)} className={classes.btnBackgroundErrorPriceSmall}>
          Atrás
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            handleSubmitStep(info)
          }}
          className={classes.btnNewProductsmall}
        >
          {info?.id ? 'Actualizar ' : 'Crear '}
        </Button>
      </DialogActions>
    </div>
  )
}

export default OverviewStep
