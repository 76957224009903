import React from 'react'

import ReactDOM from 'react-dom'

import './index.css'

import App from './App'
import ThemeAppProvider from './ThemeAppProvider'

import NotificationsProvider from 'NotificationsProvider'

const src =
  process.env.REACT_APP_ENVIRONMENT === 'prod'
    ? 'widgetPaymentMethodsProd.js'
    : 'widgetPaymentMethods.js'

// Sentry.init({
//   dsn: 'https://5e8553abfce645f69c829eee04c45886@o466341.ingest.sentry.io/5480533',
//   integrations: [new Integrations.BrowserTracing()],

//   // We recommend adjusting this value in production, or using tracesSampler
//   // for finer control
//   tracesSampleRate: 1.0,
// })

ReactDOM.render(
  <ThemeAppProvider>
    <NotificationsProvider>
      <App />
    </NotificationsProvider>
  </ThemeAppProvider>,
  document.getElementById('root')
)
