import React from 'react'

import { Grid, Box, Divider, Typography } from '@material-ui/core'

import useStyles from '../ReportsAccountStyle'

import convertToCurrencyCop from 'utils/convertToCurrencyCop'

const timeArea = {
  textAlign: 'right',
}
const SectionOthers = ({ dataOrders }) => {
  const { other } = dataOrders
  const classes = useStyles()
  return (
    <>
      <Grid
        container
        direction="column"
        justify="flex-start"
        alignItems="flex-start"
        className={classes.marginGrid}
      >
        <Typography variant="subtitle2" className={classes.widthInitialBalance}>
          Otros
        </Typography>
        <Grid
          container
          justify="space-between"
          alignItems="flex-start"
          className={classes.InitialBalancePadding}
        >
          <Typography align="left" className={classes.widthCloseInitialBalance}>
            Otros
          </Typography>
          <Typography align="right" className={classes.widthValueInitialBalance}>
            {`$${convertToCurrencyCop(Number(other?.total || 0))} COP`}
          </Typography>
        </Grid>
        <Divider />
        <Grid container justify="flex-end" alignItems="flex-start">
          <Typography variant="subtitle1">Subtotal</Typography>
          <Typography align="right" className={classes.widthValueInitialBalance}>
            {`$${convertToCurrencyCop(Number(other?.otherSubTotal || 0))} COP`}
          </Typography>
        </Grid>
      </Grid>
    </>
  )
}
export default SectionOthers
