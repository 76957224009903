import React, { useState } from 'react'

import {
  Grid,
  Typography,
  Button,
  Breadcrumbs,
  FormControlLabel,
  Switch,
  Link,
} from '@material-ui/core'
import NavigateNextIcon from '@material-ui/icons/ChevronRight'
import { useRouteMatch } from 'react-router-dom'

import Style from './validateCategorieVariable.style'

import DialogAlert from 'components/DialogAlert'
import { useProduct } from 'contextApi/ProductContext'

const ValidateCategoriesVariable = ({ renderBackToList, getCategoriesById, isApprove }) => {
  const isEdit = useRouteMatch('/admin/edit-product/:id')?.isExact
  const isEditAdmin = useRouteMatch('/admin/admin-edit-product/:id/:idSeller')?.isExact
  const classes = Style()
  const { product, setProduct } = useProduct()
  const [dialogAlert, setDialogAlert] = useState({
    open: false,
    title: '',
    message: '',
    actionConfirm: null,
    textButtonConfirm: null,
    textButtonCancel: null,
  })

  const onChangeIsVariant = (event) => {
    if (
      (isEdit || isEditAdmin) &&
      product.type === 'variable' &&
      event.target.name === 'isVirtual' &&
      event.target.checked
    ) {
      setDialogAlert({
        open: true,
        title: '¡Advertencia!',
        message:
          'El producto está catalogado como variable y no puede activarse como producto virtual',
        textButtonConfirm: 'Cerrar',
      })
      return
    }

    if (
      (isEdit || isEditAdmin) &&
      product.type === 'simple' &&
      event.target.name === 'isVirtual' &&
      event.target.checked
    ) {
      setDialogAlert({
        open: true,
        title: '¡Advertencia!',
        message:
          'El producto está catalogado como simple y no puede activarse como producto virtual',
        textButtonConfirm: 'Cerrar',
      })
      return
    }

    if (
      (isEdit || isEditAdmin) &&
      product.type === 'service' &&
      event.target.name === 'isVirtual' &&
      event.target.checked
    ) {
      setDialogAlert({
        open: true,
        title: '¡Advertencia!',
        message:
          'Este ítem está catalogado como servicio y no puede activarse como producto virtual',
        textButtonConfirm: 'Cerrar',
      })
      return
    }

    if (
      (isEdit || isEditAdmin) &&
      product.type === 'simple' &&
      event.target.name === 'isVariant' &&
      event.target.checked
    ) {
      setDialogAlert({
        open: true,
        title: '¡Advertencia!',
        message:
          'El producto está catalogado como simple y no puede activarse como producto variable',
        textButtonConfirm: 'Cerrar',
      })
      return
    }

    if (
      (isEdit || isEditAdmin) &&
      product.type === 'variable' &&
      event.target.name === 'isVariant' &&
      !event.target.checked
    ) {
      setDialogAlert({
        open: true,
        title: '¡Advertencia!',
        message:
          'El producto está catalogado como variable y no puede activarse como producto simple',
        textButtonConfirm: 'Cerrar',
      })
      return
    }

    if (
      (isEdit || isEditAdmin) &&
      product.type === 'service' &&
      event.target.name === 'isVariant' &&
      event.target.checked
    ) {
      setDialogAlert({
        open: true,
        title: '¡Advertencia!',
        message:
          'El ítem está catalogado como servicio y no puede activarse como producto variable',
        textButtonConfirm: 'Cerrar',
      })
      return
    }

    if (
      (isEdit || isEditAdmin) &&
      product.type === 'service' &&
      event.target.name === 'isService' &&
      !event.target.checked
    ) {
      setDialogAlert({
        open: true,
        title: '¡Advertencia!',
        message:
          'Este ítem está catalogado como servicio y no puede activarse como producto simple',
        textButtonConfirm: 'Cerrar',
      })
      return
    }

    if (
      (isEdit || isEditAdmin) &&
      product.type === 'simple' &&
      event.target.name === 'isService' &&
      event.target.checked
    ) {
      setDialogAlert({
        open: true,
        title: '¡Advertencia!',
        message: 'El producto está catalogado como simple y no puede activarse como un servicio',
        textButtonConfirm: 'Cerrar',
      })
      return
    }

    if (
      (isEdit || isEditAdmin) &&
      product.type === 'variable' &&
      event.target.name === 'isService' &&
      event.target.checked
    ) {
      setDialogAlert({
        open: true,
        title: '¡Advertencia!',
        message: 'El producto está catalogado como variable y no puede activarse como un servicio',
        textButtonConfirm: 'Cerrar',
      })
      return
    }

    if (
      (isEdit || isEditAdmin) &&
      product.type === 'virtual' &&
      event.target.name === 'isService' &&
      event.target.checked
    ) {
      setDialogAlert({
        open: true,
        title: '¡Advertencia!',
        message: 'El producto está catalogado como virtual y no puede activarse como un servicio',
        textButtonConfirm: 'Cerrar',
      })
      return
    }

    if (
      (isEdit || isEditAdmin) &&
      product.type === 'virtual' &&
      event.target.name === 'isVariant' &&
      event.target.checked
    ) {
      setDialogAlert({
        open: true,
        title: '¡Advertencia!',
        message:
          'El producto está catalogado como virtual y no puede activarse como un producto variable',
        textButtonConfirm: 'Cerrar',
      })
      return
    }

    if (
      (isEdit || isEditAdmin) &&
      product.type === 'virtual' &&
      event.target.name === 'isService' &&
      event.target.checked
    ) {
      setDialogAlert({
        open: true,
        title: '¡Advertencia!',
        message: 'El producto está catalogado como virtual y no puede activarse como un servicio',
        textButtonConfirm: 'Cerrar',
      })
      return
    }

    if (
      (isEdit || isEditAdmin) &&
      product.type === 'virtual' &&
      event.target.name === 'isVirtual' &&
      !event.target.checked
    ) {
      setDialogAlert({
        open: true,
        title: '¡Advertencia!',
        message:
          'El producto está catalogado como virtual y no puede activarse como producto simple',
        textButtonConfirm: 'Cerrar',
      })
      return
    }
    if (event.target.name === 'isVariant' && event.target.checked) {
      setProduct({
        ...product,
        type: 'variable',
        virtual: false,
      })
    } else if (event.target.name === 'isVirtual' && event.target.checked) {
      setProduct({
        ...product,
        type: 'virtual',
        stockQuantity: '0',
        dimensions: {
          height: '0',
          length: '0',
          width: '0',
          weight: '0',
        },
      })
    } else if (event.target.name === 'isService' && event.target.checked) {
      setProduct({
        ...product,
        type: 'service',
        stockQuantity: 1000,
        dimensions: {
          height: '0',
          length: '0',
          width: '0',
          weight: '0',
        },
      })
    } else {
      setProduct({
        ...product,
        type: 'simple',
        virtual: false,
      })
    }
  }

  const handleCloseDialogAlert = () => {
    setDialogAlert({ ...dialogAlert, open: false })
  }
  return (
    <>
      <DialogAlert
        open={dialogAlert.open}
        title={dialogAlert.title}
        handleClose={handleCloseDialogAlert}
        message={dialogAlert.message}
        textButtonConfirm={dialogAlert.textButtonConfirm}
        actionConfirm={dialogAlert.actionConfirm}
        textButtonCancel={dialogAlert.textButtonCancel}
      />
      <Grid container justify="flex-start" className={classes.container}>
        <Grid>
          <Typography variant="subtitle1">Verifica tu categoría</Typography>
          <Breadcrumbs
            maxItems={5}
            className={classes.positionElements}
            separator={<NavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb"
          >
            {product.categories.length > 0 ? (
              <Link
                color="inherit"
                component="button"
                underline="always"
                onClick={() => {
                  product.categories.splice(0, product.categories.length)
                  renderBackToList()
                }}
              >
                Categorías
              </Link>
            ) : null}
            {product.categories.map((categorie, index) => {
              return (
                <Link
                  component={product.categories.length - 1 === index ? 'typography' : 'button'}
                  color="inherit"
                  underline={product.categories.length - 1 === index ? 'none' : 'always'}
                  onClick={
                    product.categories.length - 1 === index
                      ? null
                      : (e) => getCategoriesById(e, categorie.id, categorie.name, true, true)
                  }
                >
                  {categorie.name}
                </Link>
              )
            })}
          </Breadcrumbs>
          <Typography className={classes.positionElements}>
            Es muy importante que la categoría sea la apropiada para que tus compradores encuentren
            tu producto. Si es así, haz clic en el botón continuar.
          </Typography>
          <Button
            onClick={(event) => {
              event.preventDefault()
              renderBackToList()
            }}
            color="secondary"
            variant="body1"
            className={`${classes.positionElements} ${classes.buttonPrimary}`}
          >
            Cambiar categoría
          </Button>
        </Grid>

        <Grid container alignItems="flex-start" direction="column">
          {isApprove ? (
            <Typography variant="subtitle1" color="secondary">
              {`El producto es de tipo: ${product?.type === 'variable' ? 'VARIABLE' : 'SIMPLE'}`}
            </Typography>
          ) : (
            <>
              <Typography variant="subtitle1">
                Selecciona esta opción si el producto tiene muchas variables, si es un producto
                virtual o si por otro lado, se trata de un servicio:
              </Typography>
              <FormControlLabel
                className={classes.positionElements}
                control={
                  <Switch
                    className={classes.colorswitch}
                    checked={product?.type === 'variable'}
                    onChange={onChangeIsVariant}
                    name="isVariant"
                    color="primary"
                    size="small"
                  />
                }
                label={<Typography>¿Es un producto variable?</Typography>}
              />
              <FormControlLabel
                className={classes.positionElements}
                control={
                  <Switch
                    checked={product?.type === 'virtual' || false}
                    onChange={onChangeIsVariant}
                    name="isVirtual"
                    color="primary"
                    size="small"
                  />
                }
                label={<Typography>Producto virtual</Typography>}
              />
              <FormControlLabel
                className={classes.positionElements}
                control={
                  <Switch
                    checked={product?.type === 'service'}
                    onChange={onChangeIsVariant}
                    name="isService"
                    color="primary"
                    size="small"
                  />
                }
                label={<Typography>Servicio</Typography>}
              />
            </>
          )}
        </Grid>
      </Grid>
    </>
  )
}

export default ValidateCategoriesVariable
