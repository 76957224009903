import React from 'react'

import { withStyles, makeStyles } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'

import defaultImage from 'assets/img/noimage_poster.png'

const styles = {
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 250,
    border: '1px solid #dadde9',
    marginTop: '-20%',
    webkitBoxShadow: '0px 0px 15px -1px rgba(0,0,0,0.75)',
    mozBoxShadow: '0px 0px 15px -1px rgba(0,0,0,0.75)',
    boxShadow: '0px 0px 15px -1px rgba(0,0,0,0.75)',
  },
}
const useStyles = makeStyles(() => ({
  imgMiniProduct: {
    width: 44,
    height: 44,
    borderRadius: '50%',
  },
}))
const CustomTooltip = withStyles(styles)(Tooltip)
const ImageTooltip = ({ textValue, image, showImage }) => {
  const classes = useStyles()
  return (
    <CustomTooltip
      title={<img alt="Imagen producto" src={image || defaultImage} width="200" height="250" />}
      placement="bottom-end"
    >
      {showImage && !textValue ? (
        <img alt="Imagen producto" src={image} className={classes.imgMiniProduct} />
      ) : (
        <Typography variant="subtitle2">{textValue}</Typography>
      )}
    </CustomTooltip>
  )
}

export default ImageTooltip
