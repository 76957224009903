import React, { useEffect, useContext } from 'react'

import { Button, Grid, Divider } from '@material-ui/core'
import Axios from 'axios'
import { useForm } from 'react-hook-form'

import useStyles from '../products.styles'

import Atributtes from './atributtesVariationsProduct/Atributtes'
import Variations from './atributtesVariationsProduct/Variations'

import { API_STORE_INFO } from 'constants/urls'
import useAuthContext from 'contextApi/AuthContext'
import { useProduct } from 'contextApi/ProductContext'

const AtributtesVariationsProduct = ({
  setIsLoadingBackDrop,
  saveAndPublish,
  selectApprovalMethod,
}) => {
  const classes = useStyles()
  const { userLoggedin } = useContext(useAuthContext)
  const { product, handleBack, handleCancel, setWarehouses, warehouses, isApprove, isModified } =
    useProduct()

  const getWarehouse = async () => {
    const { sellerId } = userLoggedin
    await Axios.get(`${API_STORE_INFO}/Warehouse/${sellerId}`)
      .then((response) => {
        if (response) {
          setWarehouses(response.data.data) // Save warehouses in product context
        }
      })
      .catch(() => {})
  }

  useEffect(() => {
    if (warehouses?.length <= 0) getWarehouse()
  }, [])
  useEffect(() => {}, [product.attributes])

  return (
    <>
      <Grid container className={classes.containerAttrVariations}>
        <Grid className={classes.divLoadAttributes}>
          <Atributtes setIsLoadingBackDrop={setIsLoadingBackDrop} />
        </Grid>

        <Divider orientation="vertical" flexItem className={classes.dividerVerticalAttributes} />

        <Grid className={classes.divListVariations}>
          <Variations setIsLoadingBackDrop={setIsLoadingBackDrop} />
        </Grid>
      </Grid>
      {/* buttons grid */}
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        className={classes.gridButtonsAttributesVariations}
      >
        <Button
          className={classes.btnBackgroundPrimary}
          variant="contained"
          color="secondary"
          onClick={handleBack}
        >
          Anterior
        </Button>
        <Button className={classes.btnBackgroundError} onClick={handleCancel}>
          {!isApprove ? 'Cancelar' : 'No hacer nada'}
        </Button>
        {isApprove && isModified && (
          <Button className={classes.btnBackgroundError} onClick={() => selectApprovalMethod(1)}>
            Rechazar
          </Button>
        )}

        <Button
          className={classes.btnBackgroundPrimary}
          variant="contained"
          color="secondary"
          onClick={(e) => {
            if (isApprove) {
              selectApprovalMethod(0)
            } else {
              saveAndPublish(e)
            }
          }}
        >
          {!isApprove ? 'Guardar' : 'Aprobar producto'}
        </Button>
      </Grid>
    </>
  )
}

export default AtributtesVariationsProduct
