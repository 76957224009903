export default {
  body: {
    noMatch: 'No hay registros',
    toolTip: 'Ordenar',
    columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
  },
  pagination: {
    next: 'Página siguiente',
    previous: 'Página anterior',
    rowsPerPage: 'Filas por página:',
    displayRows: 'de',
  },
  toolbar: {
    search: 'Buscar',
    downloadCsv: 'Descargar CSV',
  },
  selectedRows: {
    text: 'fila(s) seleccionadas',
    delete: 'Eliminar',
    deleteAria: 'Eliminar filas seleccionadas',
  },
}
