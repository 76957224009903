import { green } from '@material-ui/core/colors'

const styles = (theme) => ({
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    marginTop: 3,
  },
  input: {
    width: '100%',
    marginTop: 0,
    marginBottom: 19,

    '&:input': {
      padding: 5,
    },
  },

  labelInputCurrent: {
    marginBottom: 8,
    marginRight: '401px',
  },
  labelInputPassword: {
    marginBottom: 8,
    marginRight: '401px',
  },
  labelInputConfirm: {
    marginBottom: 8,
    marginRight: '316px',
  },
  divButtons: {
    marginTop: 15,
    columnGap: 15,
  },
  textConfirmPassword: {
    marginTop: 15,
    textAlign: 'center',
    mrginBottom: '20px',
  },
  btnBackgroundPrimary: {
    minWidth: 120,
    maxWidth: 125,
    marginTop: '5px',
    fill: theme.palette.white.main,

    '&:hover': {
      background: theme.palette.primary.main,
    },
  },

  btnBackgroundError: {
    minWidth: 120,
    maxWidth: 125,
    marginTop: '5px',
    color: theme.palette.white.main,
    background: theme.palette.grey.dark,
    '&:hover': {
      background: theme.palette.error.main,
      color: theme.palette.white.main,
    },
  },
})

export default styles
