import React, { useState, useContext, useEffect } from 'react'

import { Typography } from '@material-ui/core'
import { useParams } from 'react-router-dom'

import Tabs from 'components/Tabs'
import { ItinerariesContext } from 'contextApi/ItineraryContext'

const ItineraryDetils = () => {
  const { iditinerary } = useParams()
  const { allItineraries } = useContext(ItinerariesContext)
  const [tabs, setTabs] = useState([])
  const ThousandsPipe = (value) => {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
  }
  useEffect(() => {
    const findItinerary = allItineraries.find((iti) => {
      return iti.travelData.Id.toString() === iditinerary
    })
    if (findItinerary) {
      const date = new Date(findItinerary.createdAt)
      setTabs([
        {
          value: 0,
          name: 'Información del cliente',
          body: [
            { title: 'Número de itinerario', value: `${findItinerary.travelData.Id}` },
            {
              title: 'Fecha y hora',
              value: `${(date.getUTCMonth() + 1).toString().padStart(2, '0')}-${date
                .getUTCDate()
                .toString()
                .padStart(2, '0')}-${date.getUTCFullYear()}  ${date.getUTCHours()}:${date
                .getUTCMinutes()
                .toString()
                .padStart(2, '0')}`,
            },
            {
              title: 'Cliente',
              value: `${findItinerary.travelData.Reservations[0].Passengers[0].FirstName} ${findItinerary.travelData.Reservations[0].Passengers[0].LastName}`,
            },
            {
              title: 'Número de teléfono',
              value: findItinerary.travelData.Reservations[0].Passengers[0].Phone,
            },
            {
              title: 'Correo',
              value: findItinerary.travelData.Reservations[0].Passengers[0].Email,
            },
          ],
        },
        {
          value: 1,
          name: 'Información venta',
          body: [
            {
              title: 'Medio de pago',
              value: findItinerary.paymentData.isPaymentShared
                ? `${findItinerary.paymentData.paymentsResponses[0].paymentType.name}, ${findItinerary.paymentData.paymentsResponses[1].paymentType.name}`
                : findItinerary.paymentData.paymentType.name,
            },
            {
              title: 'Total pagado',
              value: `$ ${
                findItinerary.paymentData.isPaymentShared
                  ? ThousandsPipe(findItinerary.paymentData.transactionTotalValue)
                  : ThousandsPipe(findItinerary.paymentData.valueTotal)
              }`,
            },
            { title: 'Id de pago', value: findItinerary.paymentData.internaltransactionid },
          ],
        },
      ])
    }
  }, []) //eslint-disable-line

  const [value, setValue] = useState(0)
  return (
    <div className="lupa_seller_view_itidetails_container">
      <div>
        <Typography variant="h6">
          Información de itinerario:
          <span>{` Nº ${iditinerary}`}</span>
        </Typography>
        <Tabs value={value} tabs={tabs} onChange={(v) => setValue(v)} />
      </div>
      {tabs.length > 0 && (
        <div className="lupa_seller_view_itidetails_body">
          {tabs[value].body.map((e, i) => {
            return (
              <div key={i} className="lupa_seller_view_itidetails_item">
                <div className="itidetails_item_title">{e.title}</div>
                <div className="itidetails_item_value">{e.value}</div>
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
}

export default ItineraryDetils
