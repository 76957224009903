/* eslint-disable react/prop-types */
import React, { useState } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { FormGroup, Grid, InputLabel, Switch, TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import axios from 'axios'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'

import Button from 'components/CustomButtons/Button'
import useCustomDialogAlert from 'components/useCustomDialogAlert'
import { API_COMMISIONS } from 'constants/urls'

const useStyles = makeStyles((theme) => ({
  gridButtons: {
    marginTop: 20,
    gap: '20px',
  },
  btnBackgroundError: {
    width: 150,
    height: 35,
    borderRadius: '50px',
    border: '1px solid #6064ff',
    color: '#6064ff',
    fontWeight: '500',
    backgroundColor: '#ffffff',
    '&:hover': {
      color: '#FF40FC',
      border: '1px solid #FF40FC',
      backgroundColor: '#ffffff',
    },
  },
  btnBackgroundPrimary: {
    width: 150,
    height: 35,
    borderRadius: '50px',
    border: 'none',
    backgroundColor: '#5e44ff',
    color: 'white',
    fontWeight: '500',
    '&:hover': {
      fontWeight: 'bolder',
      backgroundColor: '#ef0bef',
      boxShadow: '-7px 7px 0px #000000',
      border: '2px solid #000000',
      borderRadius: '50px',
      opacity: '1',
    },
  },
  labelInput: {
    fontFamily: 'Red Hat Display Bold',
    marginTop: '10px',
    marginBottom: '10px',
  },
  input: {
    fontFamily: 'Red Hat Display Regular',
  },
}))

const schema = yup.object().shape({
  name: yup.string().required('Campo requerido'),
  description: yup.string().required('Campo requerido'),
  value: yup.number().typeError('Debe ingresar el valor').required('Campo requerido'),
  code: yup.string().required('Campo requerido'),
  isActive: yup.bool().required('Campo requerido'),
})

const ModalCommisions = ({ toEdit, onCancel }) => {
  const classes = useStyles()
  const showDialogAlert = useCustomDialogAlert()

  const [alert, setAlert] = useState(null)
  const [commission, setCommission] = useState({
    id: toEdit !== null ? toEdit[0] : '',
    name: toEdit !== null ? toEdit[1] : '',
    description: toEdit !== null ? toEdit[2] : '',
    value: toEdit !== null ? toEdit[3] : '',
    code: toEdit !== null ? toEdit[4] : '',
    isActive: toEdit !== null ? toEdit[5] : false,
  })

  const { register, handleSubmit, errors } = useForm({
    defaultValues: {
      id: commission.id,
      name: commission.name,
      description: commission.description,
      value: commission.value,
      code: commission.code,
      isActive: commission.isActive,
    },
    mode: 'onChange',
    resolver: yupResolver(schema),
  })

  const onSubmit = async () => {
    if (commission.description === '' || commission.value === '' || commission.name === '') {
      showDialogAlert(true, '¡Advertencia!', `Llena todos los campos`, 'Cerrar')
      return false
    }

    if (commission.description.length > 55 || commission.name.length > 55) {
      showDialogAlert(
        true,
        '¡Advertencia!',
        `La descripción y el nombre debe de tener menos de 55 caracteres`,
        'Cerrar'
      )
      return false
    }

    if (commission.value > 100 || commission.value < 0) {
      showDialogAlert(
        true,
        '¡Advertencia!',
        `El valor no debe ser ni mayor a 100% ni poner ser negativo`,
        'Cerrar'
      )
      return false
    }

    if (!toEdit) delete commission.id

    await axios({
      method: toEdit ? 'put' : 'post',
      url: `${API_COMMISIONS}`,
      data: commission,
    })
      .then((response) => {
        if (response.status === 200) {
          onCancel()
          setAlert(
            toEdit
              ? showDialogAlert(
                  true,
                  '¡Advertencia!',
                  `La comisión se actualizó con éxito`,
                  'Cerrar'
                )
              : showDialogAlert(true, '¡Advertencia!', `La comisión se agregó con éxito`, 'Cerrar')
          )
        }
      })
      .catch((e) => {
        console.log(e)
      })
    return true
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container direction="column">
        <FormGroup>
          <InputLabel className={classes.labelInput}>Nombre:</InputLabel>
          <TextField
            id="name"
            value={commission.name}
            variant="outlined"
            inputRef={register}
            name="name"
            error={!!errors.name}
            helperText={errors?.name?.message}
            onChange={(event) =>
              setCommission({
                ...commission,
                name: event.target.value,
              })
            }
            className={classes.input}
          />
        </FormGroup>

        <FormGroup>
          <InputLabel className={classes.labelInput}>
            Descripción (máximo 55 caracteres):
          </InputLabel>
          <TextField
            id="description"
            value={commission.description}
            variant="outlined"
            inputRef={register}
            name="description"
            error={!!errors.description}
            helperText={errors?.description?.message}
            onChange={(event) =>
              setCommission({
                ...commission,
                description: event.target.value,
              })
            }
            className={classes.input}
          />
        </FormGroup>

        <FormGroup>
          <InputLabel className={classes.labelInput}>Valor:</InputLabel>
          <TextField
            id="value"
            type="number"
            value={commission.value}
            variant="outlined"
            inputRef={register}
            name="value"
            error={!!errors.value}
            helperText={errors?.value?.message}
            onChange={(event) =>
              setCommission({
                ...commission,
                value: event.target.value,
              })
            }
            className={classes.input}
          />
        </FormGroup>

        <FormGroup>
          <InputLabel className={classes.labelInput}>Código:</InputLabel>
          <TextField
            id="code"
            value={commission.code}
            variant="outlined"
            inputRef={register}
            name="code"
            error={!!errors.code}
            helperText={errors?.code?.message}
            onChange={(event) =>
              setCommission({
                ...commission,
                code: event.target.value,
              })
            }
            className={classes.input}
          />
        </FormGroup>

        <FormGroup>
          <InputLabel className={classes.labelInput}>Activo:</InputLabel>
          <Switch
            inputRef={register}
            checked={commission.isActive}
            onChange={(event) =>
              setCommission({
                ...commission,
                isActive: event.target.checked,
              })
            }
            color="primary"
            name="isActive"
          />
        </FormGroup>
      </Grid>
      <Grid container justify="center" className={classes.gridButtons}>
        <Button
          className={classes.btnBackgroundError}
          onClick={() => {
            onCancel(commission)
          }}
        >
          Cancelar
        </Button>
        <Button className={classes.btnBackgroundPrimary} color="primary" type="submit">
          {toEdit ? 'Actualizar' : 'Guardar'}
        </Button>
      </Grid>
      {alert}
    </form>
  )
}

export default ModalCommisions
