import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },

  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },

  btnBackground: {
    background: theme.palette.secondary.main,
    color: theme.palette.white.main,
    '&:hover': {
      background: theme.palette.secondary.main,
      color: theme.palette.white.main,
    },
  },

  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}))

export default useStyles
