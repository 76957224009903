/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react'

import DateFnsUtils from '@date-io/date-fns'
import { Grid, InputAdornment, FormGroup, TextField, InputLabel } from '@material-ui/core'
import {
  KeyboardDatePicker,
  TimePicker,
  DatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import CurrencyTextField from '@unicef/material-ui-currency-textfield'
import axios from 'axios'
import { es } from 'date-fns/locale'
import moment from 'moment'
import PropTypes from 'prop-types'

// Material ui core

// Template components
import styles from '../products.styles'

import ButtonLoading from 'components/ButtonLoading'
import Button from 'components/CustomButtons/Button'
import { CalendarIcon } from 'components/icons/personalizedIcons'
import useCustomDialogAlert from 'components/useCustomDialogAlert'
// Constants
import { API_PRODUCTS } from 'constants/urls'
import convertToCurrencyCop from 'utils/convertToCurrencyCop'
import { onlyNumber } from 'utils/utils'

const EditSalePrice = ({ onCancel, dataToEdit, productData, setData }) => {
  const productActual = productData.find((product) => product.id === dataToEdit.id)
  const defaultModel = {
    id: dataToEdit.id,
    sale_price: dataToEdit.value,
    date_on_sale_from: dataToEdit.sale_price_date_from,
    date_on_sale_to: dataToEdit.sale_price_date_to,
    promotionPercent: productActual.sale_price
      ? Math.trunc(
          ((productActual.regularPrice - productActual.sale_price) / productActual.regularPrice) *
            100
        )
      : 0,
  }
  const [isLoading, setIsLoading] = useState(false)
  const [model, setModel] = useState(defaultModel)
  const [promotionPercent, setPromotionPercent] = useState(0)

  const classes = styles()
  const showDialogAlert = useCustomDialogAlert()

  useEffect(() => {
    if (productActual.sale_price !== '')
      setPromotionPercent(
        Math.trunc(
          ((productActual.regularPrice - productActual.sale_price) / productActual.regularPrice) *
            100
        )
      )
  }, [productActual.regularPrice])
  useEffect(() => {}, [promotionPercent, productActual.sale_price])

  const onSubmit = async (event) => {
    event.preventDefault()
    if (!model.sale_price && model.sale_price === '') {
      showDialogAlert(true, '¡Advertencia!', `El campo no puede estar vacio`, 'Cerrar')
      return false
    }
    const tempProductActual = productData.find((product) => product.id === dataToEdit.id)
    if (model.sale_price !== 0) {
      if (
        parseInt(model.sale_price?.split(',').join(''), 10) >=
        parseInt(productActual.regularPrice, 10)
      ) {
        showDialogAlert(
          true,
          '¡Advertencia!',
          `El precio rebajado debe ser menor al precio actual del producto $${convertToCurrencyCop(
            tempProductActual.regularPrice
          )}.`,
          'Cerrar'
        )
        return false
      }
    } else {
      setModel({
        ...model,
        sale_price: '0',
      })
    }
    const compareCurrentDate =
      moment().format('YYYY-MM-DD') <= moment(model.date_on_sale_from).format('YYYY-MM-DD')
    if (!compareCurrentDate) {
      showDialogAlert(
        true,
        '¡Advertencia!',
        `La Inicial debe se mayor o igual a la fecha actual`,
        'Cerrar'
      )
      return false
    }
    if (
      model.date_on_sale_to !== undefined &&
      model.date_on_sale_from !== undefined &&
      moment(model.date_on_sale_to) < moment(model.date_on_sale_from)
    ) {
      showDialogAlert(
        true,
        '¡Advertencia!',
        `La fecha final de promoción debe ser mayor a la fecha inicial`,
        'Cerrar'
      )
      return false
    }

    try {
      setIsLoading(true)
      const result = await axios({
        url: `${API_PRODUCTS}/${defaultModel.id}`,
        method: 'PUT',
        data: model,
      })
      if (result) setIsLoading(false)
      if (result.status === 200) {
        showDialogAlert(
          true,
          '¡Cambio exitoso!',
          `Se actualizó correctamente el precio promoción`,
          'Cerrar',
          '',
          true
        )
        const newData = [...productData]
        const found = newData.find((product) => product.id === defaultModel.id)
        found.sale_price = model?.sale_price?.split(',').join('')
        found.sale_price_date_from = model.date_on_sale_from
        found.sale_price_date_to = model.date_on_sale_to

        setData(newData)
        onCancel()
        setIsLoading(false)
      }
    } catch (error) {
      console.log(error)
      showDialogAlert(
        true,
        '¡Advertencia!',
        `Hubo un error al actualizar el precio, valida que el producto tenga una bodega asignada`,
        'Cerrar'
      )
      setIsLoading(false)
    }
    return true
  }
  return (
    <form noValidate onSubmit={onSubmit} autoComplete="off">
      {/* <Grid container className={classes.root} justify="center"> */}
      <Grid container spacing={2} justify="center" className={classes.root}>
        <Grid md={6}>
          <FormGroup className={classes.formeditPriceProduct}>
            <InputLabel className={classes.labelSize}>Promoción (%)</InputLabel>
            <TextField
              id="promotionPercent"
              name="promotionPercent"
              onChange={(event) => {
                if (
                  onlyNumber(event.target.value) &&
                  event.target.value >= 0 &&
                  event.target.value <= 100
                ) {
                  setPromotionPercent(event.target.value)
                  setModel({
                    ...model,
                    sale_price: Math.trunc(
                      productActual.regularPrice -
                        productActual.regularPrice * (event.target.value / 100)
                    ).toString(),
                  })
                  return true
                }
                return false
              }}
              value={parseInt(promotionPercent, 10) || 0}
              variant="outlined"
            />
          </FormGroup>

          <FormGroup className={classes.formeditPriceProduct}>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
              <InputLabel className={classes.labelSize}>Fecha inicial de promoción</InputLabel>

              <DatePicker
                id="date_on_sale_from"
                value={model.date_on_sale_from ? model.date_on_sale_from : null}
                disablePast={false}
                onChange={(value) => {
                  const date = new Date(value)

                  const month =
                    date.getMonth() < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1

                  setModel({
                    ...model,
                    date_on_sale_from: `${date.getFullYear()}-${month}-${
                      date.getDate().toString().length > 1 ? date.getDate() : `0${date.getDate()}`
                    }T00:00:00`,
                  })
                }}
                format="yyyy-MM-dd"
                inputVariant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <CalendarIcon />
                    </InputAdornment>
                  ),
                }}
                KeyboardButtonProps={{ style: { padding: 0 } }}
              />
            </MuiPickersUtilsProvider>
          </FormGroup>
        </Grid>
        <Grid md={6}>
          <FormGroup className={classes.formeditPriceProduct}>
            <InputLabel className={classes.labelSize}>Precio en promoción ($)</InputLabel>

            <CurrencyTextField
              currencySymbol="$"
              textAlign="left"
              decimalPlaces={0}
              outputFormat="string"
              variant="outlined"
              inputProps={{
                inputProps: { min: 0 },
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                onChange: ({ target }) => setModel({ ...model, sale_price: target.value }),
              }}
              onChange={(event) => {
                if (
                  onlyNumber(parseInt(event.target.value.split(',').join(''), 10)) &&
                  Number(event.target.value.split(',').join('')) <=
                    Number(productActual.regularPrice)
                ) {
                  setPromotionPercent(
                    Math.trunc(
                      ((productActual.regularPrice -
                        parseInt(event.target.value.split(',').join(''), 10)) /
                        productActual.regularPrice) *
                        100
                    )
                  )
                  setModel({
                    ...model,
                    sale_price: event.target.value.split(',').join(''),
                  })
                  return true
                }
                return false
              }}
              value={model.sale_price}
            />
          </FormGroup>
          <FormGroup className={classes.formeditPriceProduct}>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
              <InputLabel className={classes.labelSize}>Fecha final de promoción</InputLabel>

              <DatePicker
                id="date_on_sale_to"
                value={model.date_on_sale_to ? model.date_on_sale_to : null}
                onChange={(value) => {
                  const date = new Date(value)

                  const month =
                    date.getMonth() < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1

                  setModel({
                    ...model,
                    date_on_sale_to: `${date.getFullYear()}-${month}-${
                      date.getDate().toString().length > 1 ? date.getDate() : `0${date.getDate()}`
                    }T23:59:59`,
                  })
                }}
                format="yyyy-MM-dd"
                inputVariant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <CalendarIcon />
                    </InputAdornment>
                  ),
                }}
                disablePast={false}
                KeyboardButtonProps={{ style: { padding: 0 } }}
              />
            </MuiPickersUtilsProvider>
          </FormGroup>
        </Grid>
      </Grid>
      <Grid container justify="center">
        <div className={classes.wrapper}>
          <Button
            variant="contained"
            className={classes.btnBackgroundErrorPrice}
            disabled={isLoading}
            onClick={onCancel}
          >
            Cancelar
          </Button>
        </div>
        <ButtonLoading
          type="submit"
          isLoading={isLoading}
          color="secondary"
          label={dataToEdit.id ? 'Actualizar' : 'Aceptar'}
        />
      </Grid>
    </form>
  )
}
EditSalePrice.propTypes = {
  onCancel: PropTypes.func.isRequired,
}

export default EditSalePrice
