import React, { useEffect, useState } from 'react'

import { Dialog, DialogContent, IconButton, StepConnector } from '@material-ui/core'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import Stepper from '@material-ui/core/Stepper'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'

import SaleAlert from './SaleCreation/SaleAlert'
import SaleBasicInfo from './SaleCreation/SaleBasicInfo'
import SaleOverviewInfo from './SaleCreation/SaleOverviewInfo'
import SaleRulesInfo from './SaleCreation/SaleRulesInfo'
import SaleUsageInfo from './SaleCreation/SaleUsageInfo'

import { updateSale, createSale } from 'services/Sales'
import useStyles from 'views/Products/products.styles'

const CircleConnector = withStyles({
  alternativeLabel: {
    top: 20,
    left: 'calc(-50% + 30px)',
    right: 'calc(50% + 30px)',
  },
  active: {
    '& $line': {
      borderColor: '#6064FF',
    },
  },
  completed: {
    '& $line': {
      borderColor: '#6064FF',
    },
  },
  line: {
    borderColor: '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1,
  },
})(StepConnector)

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
})

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
})
const SaleModal = ({
  isOpen,
  handleClose,
  editData,
  discountTypes,
  ruleTypes,
  conditionTypes,
  onSubmitChanges,
  setIsLoading,
}) => {
  const classes = useStyles()
  const steps = [
    'Información básica',
    'Aplicación y vigencia',
    'Definición de reglas',
    'Resumen de la operación',
  ]
  const [activeStep, setActiveStep] = useState(0)
  const [info, setInfo] = useState({})
  const [rules, setRules] = useState([])
  const [saleAlertProps, setSaleAlertProps] = useState({
    isOpen: false,
    handleClose: () => {},
    response: {},
  })

  const addRule = (newRule) => {
    setRules([...rules, newRule])
  }

  const removeRule = (index) => {
    setRules(rules.filter((item, i) => i !== index))
  }

  const handleBack = (data) => {
    setInfo({ ...info, ...data })
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const handleSubmit = (data) => {
    setIsLoading(true)
    if (info.id) {
      updateSale({ data, saleCode: info.saleCode })
        .then((res) => {
          console.log(res)
          if (res.isSuccessful) {
            setSaleAlertProps({
              isOpen: true,
              handleClose: () => {
                setSaleAlertProps({ ...saleAlertProps, isOpen: false })
                onSubmitChanges()
              },
              response: res,
            })
          } else {
            setSaleAlertProps({
              isOpen: true,
              handleClose: () => {
                setSaleAlertProps({ ...saleAlertProps, isOpen: false })
              },
              response: res,
            })
          }
          setIsLoading(false)
        })
        .catch((err) => {
          console.log(err)
          setIsLoading(false)
        })
    } else {
      createSale(data)
        .then((res) => {
          if (res.isSuccessful) {
            setSaleAlertProps({
              isOpen: true,
              handleClose: () => {
                setSaleAlertProps({ ...saleAlertProps, isOpen: false })
                onSubmitChanges()
              },
              response: res,
            })
          } else {
            setSaleAlertProps({
              isOpen: true,
              handleClose: () => {
                setSaleAlertProps({ ...saleAlertProps, isOpen: false })
              },
              response: res,
            })
          }
          setIsLoading(false)
        })
        .catch((err) => {
          console.log(err)
          setIsLoading(false)
        })
    }
  }

  const handleNext = (data) => {
    setInfo({ ...info, ...data })
    if (activeStep === steps.length - 1) {
      handleSubmit({ ...info, rules })
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1)
    }
  }

  const getStepContent = (stepIndex) => {
    const commonProps = { info, handleBack, handleNext }
    switch (stepIndex) {
      case 0:
        return <SaleBasicInfo {...commonProps} discountTypes={discountTypes} />
      case 1:
        return <SaleUsageInfo {...commonProps} />
      case 2:
        return (
          <SaleRulesInfo
            {...commonProps}
            ruleTypes={ruleTypes}
            conditionTypes={conditionTypes}
            rules={rules}
            addRule={addRule}
            removeRule={removeRule}
          />
        )
      case 3:
        return (
          <SaleOverviewInfo
            {...commonProps}
            ruleConditions={conditionTypes}
            ruleTypes={ruleTypes}
            rules={rules}
          />
        )
      default:
        return 'Unknown stepIndex'
    }
  }

  useEffect(() => {
    if (!isOpen) {
      setActiveStep(0)
      setInfo({})
    } else {
      setInfo(editData || {})
      setRules(editData?.rules || [])
    }
  }, [isOpen])
  return (
    <Dialog open={isOpen} onClose={handleClose} fullWidth maxWidth="lg">
      <DialogContent>
        <DialogTitle onClose={handleClose}>
          <Stepper activeStep={activeStep} alternativeLabel connector={<CircleConnector />}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel
                  classes={{
                    label: classes.labelStepLabel,
                    active: classes.activeStepLabel,
                    completed: classes.completedStepLabel,
                  }}
                  StepIconProps={{
                    classes: {
                      root: classes.rootStepIcon,
                      text: classes.text,
                      active: classes.active,
                      completed: classes.completed,
                    },
                  }}
                >
                  {label}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </DialogTitle>
        {getStepContent(activeStep)}
      </DialogContent>
      <SaleAlert {...saleAlertProps} />
    </Dialog>
  )
}

export default SaleModal
