/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useContext, useEffect, useState } from 'react'

import {
  Grid,
  Tooltip,
  IconButton,
  makeStyles,
  FormControlLabel,
  Switch,
  Typography,
  withStyles,
  Select,
  MenuItem,
  Paper,
  Chip,
  InputBase,
  TableCell,
  TableRow,
  Divider,
  Card,
} from '@material-ui/core'
import { Autorenew } from '@material-ui/icons'
import { format } from 'date-fns'
import { es } from 'date-fns/locale'
import MUIDataTable from 'mui-datatables'

import CouponsStepper from './CouponsStepper/CouponsStepper'

import BackDrop from 'components/BackDrop'
import Button from 'components/CustomButtons/Button'
import { PencilIcon, SearchIcon } from 'components/icons/personalizedIcons'
import InputField from 'components/InputField'
import { SUPER_ADMIN, VOUCHER, DROPSHIPPER } from 'constants/rolesConst'
import AuthContext from 'contextApi/AuthContext'
import useCouponsInterceptor from 'hook/useCouponsInterceptor'
import { textLabels } from 'utils/functions'

const useStyles = makeStyles(() => ({
  button: {
    width: '248px',
    height: '56px',
    borderRadius: '50px',
    border: 'none',
    margin: '20px 0',
    backgroundColor: '#5e44ff',
    color: 'white',
    fontWeight: '500',
    '&:hover': {
      fontWeight: 'bolder',
      backgroundColor: '#ef0bef',
      boxShadow: '-7px 7px 0px #000000',
      border: '2px solid #000000',
      borderRadius: '50px',
      opacity: '1',
    },
  },
}))

const LuegoSwitch = withStyles({
  switchBase: {
    color: '#7e7e7e',
    '&$checked': {
      color: '#6064FF',
    },
    '&$checked + $track': {
      backgroundColor: '#6064FF',
    },
  },
  checked: {},
  track: {},
})(Switch)

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    width: '250px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}))(InputBase)

const CreateCoupons = () => {
  const [coupons, setCoupons] = useState({
    data: [],
    isSuccesful: false,
    message: '',
    totalRows: 0,
  })
  const [openProps, setOpenProps] = useState({
    open: false,
    type: 1,
  })
  const [editInfo, setEditInfo] = useState({})
  const classes = useStyles()
  const {
    getCoupons,
    changeCouponStatus,
    getSuppliers,
    getDiscountTypes,
    getUseTypes,
    getRuleConditions,
    getRuleTypes,
  } = useCouponsInterceptor()
  const [discountTypes, setDiscountTypes] = useState([])
  const [useTypes, setUseTypes] = useState([])
  const [ruleConditions, setRuleConditions] = useState([])
  const [ruleTypes, setRuleTypes] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [suppliers, setSuppliers] = useState([])
  const [selectedSupplier, setSelectedSupplier] = useState({
    id: 1,
    uniqueId: 1,
    name: 'Luegopago',
  })
  const [newFilter, setNewFilter] = useState({
    value: '',
    type: 0,
  })
  const { userLoggedin } = useContext(AuthContext)

  const [filters, setFilters] = useState([])

  const [request, setRequest] = useState({
    page: 1,
    size: 10,
    supplierId: 1,
    filters: [],
    orderType: 1,
  })
  const [pagination, setPagination] = useState(0)
  const [pageSizeReq, setPageSizeReq] = useState(10)

  const handleClose = () => {
    setOpenProps({
      ...openProps,
      open: false,
    })
    setEditInfo({})
  }

  const changeTablePage = (page) => {
    setPagination(page)
    setRequest({
      ...request,
      page: page + 1,
    })
  }

  const changeTableSize = (pageSize) => {
    setPagination(0)
    setRequest({
      ...request,
      page: 1,
      size: pageSize,
    })
    setPageSizeReq(pageSize)
  }

  const changeSupplier = (supplier) => {
    setRequest({
      page: 1,
      size: pageSizeReq,
      supplierId: supplier.id,
      filters: [],
      orderType: 1,
    })
    setPagination(0)
    setFilters([])
    setSelectedSupplier(supplier)
  }

  const getDefaultSupplier = (role, suppliersData) => {
    switch (role) {
      case SUPER_ADMIN:
        return selectedSupplier
      case DROPSHIPPER:
        return suppliersData.find((supplier) => supplier.uniqueId === 3)
      case VOUCHER:
        return suppliersData.find((supplier) => supplier.uniqueId === 5)
      default:
        return selectedSupplier
    }
  }

  const handleGetCoupons = () => {
    setIsLoading(true)
    const defaultSupplier = getDefaultSupplier(userLoggedin.role, suppliers).id
    getCoupons({ ...request, supplierId: defaultSupplier })
      .then((res) => {
        setCoupons(res)
        setIsLoading(false)
      })
      .catch((err) => {
        console.log(err)
        setIsLoading(false)
      })
  }

  const loadData = () => {
    setIsLoading(true)
    getSuppliers().then((res) => {
      setSuppliers(res)
      setSelectedSupplier(res[0])
      if (userLoggedin.role === SUPER_ADMIN) {
        getCoupons({
          ...request,
        })
          .then((response) => {
            setCoupons(response)
            setIsLoading(false)
          })
          .catch(() => {
            setIsLoading(false)
          })
      } else {
        const supplierId = getDefaultSupplier(userLoggedin.role, res)
        getCoupons({
          ...request,
          supplierId: supplierId.id,
        })
          .then((response) => {
            setCoupons(response)
            setIsLoading(false)
          })
          .catch(() => {
            setIsLoading(false)
          })
        setSelectedSupplier(supplierId)
      }
    })
    getDiscountTypes().then((data) => {
      setDiscountTypes(data)
    })
    getUseTypes().then((data) => {
      setUseTypes(data)
    })
    getRuleConditions().then((data) => {
      setRuleConditions(data)
    })
    getRuleTypes().then((data) => {
      setRuleTypes(data)
    })
  }

  const handleNewFilter = (e) => {
    if (e) {
      e.preventDefault()
    }
    if (newFilter.value.length > 0) {
      // eslint-disable-next-line no-nested-ternary
      const name = newFilter.type === 1 ? 'Código' : newFilter.type === 2 ? 'Estado' : 'Descripción'
      const addFilter = filters.some((f) => f.type === newFilter.type)
      if (addFilter) {
        const newFilters = filters.filter((f) => f.type !== newFilter.type)
        setFilters([...newFilters, { name, type: newFilter.type, value: newFilter.value }])
        setRequest({
          ...request,
          page: 1,
          filters: [...newFilters, { type: newFilter.type, value: newFilter.value }],
        })
      } else {
        setFilters([...filters, { name, type: newFilter.type, value: newFilter.value }])
        setRequest({
          ...request,
          page: 1,
          filters: [...filters, { type: newFilter.type, value: newFilter.value }],
        })
      }
      setNewFilter({
        value: '',
        type: 0,
      })
      setPagination(0)
    }
  }

  useEffect(() => {
    if (suppliers.length === 0) {
      loadData()
    } else {
      handleGetCoupons()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [request])

  const isVoucher = userLoggedin.role === VOUCHER

  return (
    <div>
      <BackDrop open={isLoading} />
      <Typography variant="h6" style={{ color: '#6064FF' }}>
        Listado de {isVoucher ? 'bonos' : 'cupones'}
      </Typography>
      <Grid container justifyContent="space-between">
        <Grid item xs={6}>
          {userLoggedin.role === SUPER_ADMIN && (
            <Grid container spacing={3} alignItems="center">
              <Grid item xs={12} lg={6}>
                <InputField label="Proveedor">
                  <Select
                    name="selectedSupplier"
                    value={selectedSupplier}
                    variant="outlined"
                    onChange={(event) => {
                      changeSupplier(event.target.value)
                    }}
                  >
                    {suppliers.map((supplier) => (
                      <MenuItem key={supplier.id} value={supplier}>
                        {supplier.name}
                      </MenuItem>
                    ))}
                  </Select>
                </InputField>
              </Grid>
              <Grid item xs={12} lg={6} />
            </Grid>
          )}
        </Grid>
        <Grid item>
          <Grid container spacing={1}>
            {[SUPER_ADMIN, VOUCHER].includes(userLoggedin.role) ? (
              (isVoucher || selectedSupplier.uniqueId !== 3) && (
                <>
                  <Grid item>
                    <Button
                      className={classes.button}
                      onClick={() => {
                        setOpenProps({
                          open: true,
                          type: 1,
                        })
                      }}
                    >
                      Crear {isVoucher ? 'bono' : 'cupón'} único
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      className={classes.button}
                      onClick={() => {
                        setOpenProps({
                          open: true,
                          type: 2,
                        })
                      }}
                    >
                      Crear multiples {isVoucher ? 'bonos' : 'cupones'}
                    </Button>
                  </Grid>
                </>
              )
            ) : (
              <Grid item>
                <Button
                  className={classes.button}
                  onClick={() => {
                    setOpenProps({
                      open: true,
                      type: 1,
                    })
                  }}
                >
                  Crear cupón único
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid container direction="column" xs={12}>
        <Grid item xs={12}>
          <Paper component="ul" style={{ width: '100%', marginBottom: '3px' }}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="flex-end"
              xs={12}
            >
              <Grid item>
                <label>Filtros</label>
                <Grid container>
                  <Select
                    variant="outlined"
                    value={newFilter.type}
                    input={<BootstrapInput />}
                    style={{ width: '150px' }}
                    onChange={(e) => {
                      setNewFilter({
                        type: e.target.value,
                        value: '',
                      })
                    }}
                  >
                    <MenuItem value={0} disabled>
                      Filtros
                    </MenuItem>
                    <MenuItem value={1}>Código</MenuItem>
                    <MenuItem value={2}>Estado</MenuItem>
                    <MenuItem value={3}>Descripción</MenuItem>
                    {suppliers.find((supp) => supp.id === selectedSupplier.id)?.uniqueId === 3 &&
                      userLoggedin.role === SUPER_ADMIN && <MenuItem value={4}>Tienda</MenuItem>}
                  </Select>
                  {newFilter.type === 2 ? (
                    <Select
                      variant="outlined"
                      value={newFilter.value}
                      input={<BootstrapInput />}
                      onChange={(e) => {
                        setNewFilter({
                          ...newFilter,
                          value: e.target.value,
                        })
                      }}
                    >
                      <MenuItem value="" disabled>
                        Seleccione
                      </MenuItem>
                      <MenuItem value="true">Activo</MenuItem>
                      <MenuItem value="false">Inactivo</MenuItem>
                    </Select>
                  ) : (
                    <BootstrapInput
                      name="filterInput"
                      type={newFilter.type === 4 ? 'number' : 'text'}
                      value={newFilter.value}
                      disabled={newFilter.type === 0}
                      onChange={(e) => {
                        setNewFilter({
                          ...newFilter,
                          value: e.target.value,
                        })
                      }}
                      placeholder={newFilter.type === 0 ? 'Seleccione un filtro' : ''}
                    />
                  )}
                  <IconButton onClick={handleNewFilter}>
                    <SearchIcon />
                  </IconButton>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container direction="row" alignItems="center" spacing={1}>
                  {filters.map(({ type, value, name }) => (
                    <Grid item key={type}>
                      <li>
                        <Chip
                          label={`${String(name).toLocaleUpperCase()}: ${String(
                            // eslint-disable-next-line no-nested-ternary
                            value === 'true' ? 'Activo' : value === 'false' ? 'Inactivo' : value
                          ).toLocaleUpperCase()}`}
                          onDelete={() => {
                            setFilters(filters.filter((filter) => filter.type !== type))
                            setRequest({
                              ...request,
                              page: 1,
                              filters: filters.filter((filter) => filter.type !== type),
                            })
                            setPagination(0)
                          }}
                        />
                      </li>
                    </Grid>
                  ))}
                  <Grid item>
                    <IconButton onClick={handleGetCoupons}>
                      <Autorenew />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
          <MUIDataTable
            options={{
              print: false,
              filter: false,
              download: false,
              viewColumns: false,
              serverSide: true,
              expandableRows: true,
              search: false,
              sort: false,
              count: coupons.totalRows,
              page: pagination,
              tableBodyMaxHeight: '50vh',
              expandableRowsHeader: false,
              renderExpandableRow: (rowData) => {
                const colSpan = rowData.length + 1
                const {
                  percentLuegopago,
                  percentSeller,
                  useName,
                  rules,
                  isCumulative,
                  isForUsersRegistered,
                } = coupons.data.find((item) => item.id === rowData[0])
                return (
                  <TableRow>
                    <TableCell
                      colSpan={colSpan}
                      style={{ backgroundColor: 'white', padding: '2%', paddingTop: '5px' }}
                    >
                      <Card variant="outlined">
                        <Grid container justifyContent="space-evenly">
                          <Grid item>
                            <Typography variant="subtitle1">Información Básica</Typography>
                            <Typography variant="body1">Valor asumido por Luegopago:</Typography>
                            <p>{percentLuegopago}%</p>
                            <Typography variant="body1">Valor asumido por la tienda:</Typography>
                            <p>{percentSeller}%</p>
                          </Grid>
                          <Divider orientation="vertical" flexItem style={{}} />
                          <Grid item>
                            <Typography variant="subtitle1">Información de uso</Typography>
                            <Typography variant="body1">Tipo de uso:</Typography>
                            <p>{useName}</p>
                            <Typography variant="body1">Acumulable:</Typography>
                            <p>{isCumulative ? 'Si' : 'No'}</p>
                            <Typography variant="body1">Exclusivo usuarios registrados:</Typography>
                            <p>{isForUsersRegistered ? 'Si' : 'No'}</p>
                          </Grid>
                          <Divider orientation="vertical" flexItem />
                          <Grid item xs={6}>
                            <Typography variant="subtitle1">Reglas</Typography>
                            <MUIDataTable
                              options={{
                                print: false,
                                filter: false,
                                download: false,
                                viewColumns: false,
                                search: false,
                                sort: false,
                                selectableRows: false,
                                customFooter: () => null,
                                textLabels: {
                                  body: {
                                    noMatch: 'No hay reglas para este cupón',
                                  },
                                },
                              }}
                              columns={[
                                {
                                  name: 'ruleTypeName',
                                  label: 'Tipo',
                                  options: {
                                    customHeadLabelRender: () => (
                                      <th style={{ fontFamily: 'Red Hat Display Black', fontSize: 13 }}>
                                        Tipo
                                      </th>
                                    ),
                                  },
                                },
                                {
                                  name: 'conditionName',
                                  label: 'Condición',
                                  options: {
                                    customHeadLabelRender: () => (
                                      <th style={{ fontFamily: 'Red Hat Display Black', fontSize: 13 }}>
                                        Condición
                                      </th>
                                    ),
                                  },
                                },
                                {
                                  name: 'value',
                                  label: 'Valor',
                                  options: {
                                    customHeadLabelRender: () => (
                                      <th style={{ fontFamily: 'Red Hat Display Black', fontSize: 13 }}>
                                        Valor
                                      </th>
                                    ),
                                  },
                                },
                                {
                                  name: 'isOptional',
                                  label: 'Opcional',
                                  options: {
                                    customHeadLabelRender: () => (
                                      <th style={{ fontFamily: 'Red Hat Display Black', fontSize: 13 }}>
                                        Opcional
                                      </th>
                                    ),
                                    customBodyRender: (value) => (value ? 'Si' : 'No'),
                                  },
                                },
                              ]}
                              data={rules}
                            />
                          </Grid>
                        </Grid>
                      </Card>
                    </TableCell>
                  </TableRow>
                )
              },
              onTableChange: (action, tableState) => {
                switch (action) {
                  case 'changePage':
                    changeTablePage(tableState.page)
                    break
                  case 'changeRowsPerPage':
                    changeTableSize(tableState.rowsPerPage)
                    break
                  default:
                    break
                }
              },
              textLabels: textLabels(isLoading),
              selectableRows: 'none',
              fixedHeader: true,
            }}
            columns={[
              { name: 'id', label: 'ID', options: { display: 'false' } },
              {
                name: 'sellerId',
                label: 'ID Tienda',
                options: {
                  display:
                    [3, 5].includes(
                      suppliers.find((supp) => supp.id === selectedSupplier.id)?.uniqueId
                    ) && userLoggedin.role === SUPER_ADMIN,
                  customHeadLabelRender: () => (
                    <th style={{ fontFamily: 'Red Hat Display Black', fontSize: 13 }}>ID TIENDA</th>
                  ),
                },
              },
              {
                name: 'couponCode',
                label: isVoucher ? 'Bono' : 'Cupón',
                options: {
                  customHeadLabelRender: () => (
                    <th style={{ fontFamily: 'Red Hat Display Black', fontSize: 13 }}>
                      {isVoucher ? 'BONO' : 'CUPÓN'}
                    </th>
                  ),
                  customBodyRender: (value) => {
                    return <td style={{ fontFamily: 'Red Hat Display Black', fontSize: 13 }}>{value}</td>
                  },
                },
              },
              {
                name: 'couponName',
                label: 'Descripción',
                options: {
                  customHeadLabelRender: () => (
                    <th style={{ fontFamily: 'Red Hat Display Black', fontSize: 13 }}>DESCRIPCIÓN</th>
                  ),
                },
              },
              {
                name: 'couponValue',
                label: 'Valor de descuento',
                options: {
                  customHeadLabelRender: () => (
                    <th style={{ fontFamily: 'Red Hat Display Black', fontSize: 13 }}>VALOR</th>
                  ),
                  customBodyRender: (value, tableMeta) => {
                    return tableMeta.rowData[5] === 3 ? `$${value}` : `${value}%`
                  },
                },
              },
              {
                name: 'discountType',
                label: 'Tipo de descuento',
                options: {
                  display: 'false',
                },
              },
              {
                name: 'initialDate',
                label: 'Fecha inicial',
                options: {
                  customHeadLabelRender: () => (
                    <th style={{ fontFamily: 'Red Hat Display Black', fontSize: 13 }}>DESDE</th>
                  ),
                  customBodyRender: (value) => format(new Date(value), 'PPPpp', { locale: es }),
                },
              },
              {
                name: 'finalDate',
                label: 'Fecha final',
                options: {
                  customHeadLabelRender: () => (
                    <th style={{ fontFamily: 'Red Hat Display Black', fontSize: 13 }}>HASTA</th>
                  ),
                  customBodyRender: (value) => format(new Date(value), 'PPPpp', { locale: es }),
                },
              },
              {
                name: 'timesApplied',
                label: 'Usos',
                options: {
                  customHeadLabelRender: () => (
                    <th style={{ fontFamily: 'Red Hat Display Black', fontSize: 13 }}>USOS</th>
                  ),
                  customBodyRender: (value, tableMeta) => {
                    if (
                      useTypes.find((type) => type.id === tableMeta.rowData[14])?.uniqueId === 5
                    ) {
                      if (Number(tableMeta.rowData[13]) === 0) {
                        return 'Sin usos'
                      }
                      return `$ ${tableMeta.rowData[13]}`
                    }
                    if (
                      useTypes.find((type) => type.id === tableMeta.rowData[14])?.uniqueId === 6
                    ) {
                      if (Number(tableMeta.rowData[13]) === 0) {
                        return 'Sin usos'
                      }
                      return 'Vendido'
                    }
                    return `${value}/${tableMeta.rowData[9] > 0 ? tableMeta.rowData[9] : '∞'}`
                  },
                },
              },
              {
                name: 'usageLimit',
                label: 'Límite de usos',
                options: {
                  display: 'false',
                },
              },
              {
                name: 'status',
                label: 'Estado',
                options: {
                  customHeadLabelRender: () => (
                    <th style={{ fontFamily: 'Red Hat Display Black', fontSize: 13 }}>ESTADO</th>
                  ),
                  customBodyRender: (value, tableMeta) => (
                    <FormControlLabel
                      control={
                        <LuegoSwitch
                          checked={value}
                          disabled={
                            (tableMeta.rowData[9] !== 0 &&
                              tableMeta.rowData[8] >= tableMeta.rowData[9]) ||
                            ([5, 6].includes(
                              useTypes.find((type) => type.id === tableMeta.rowData[14])?.uniqueId
                            ) &&
                              tableMeta.rowData[13] >= tableMeta.rowData[4])
                          }
                          onChange={() => {
                            setIsLoading(true)
                            changeCouponStatus(tableMeta.rowData[2], !value)
                              .then(() => {
                                loadData()
                              })
                              .catch((err) => {
                                console.log(err)
                                setIsLoading(false)
                              })
                          }}
                          name="checkedB"
                          color="primary"
                        />
                      }
                      style={{ margin: 0 }}
                      label={value ? 'Activo' : 'Inactivo'}
                      labelPlacement="bottom"
                    />
                  ),
                },
              },
              {
                name: 'actions',
                label: 'Editar',
                options: {
                  filter: false,
                  customHeadLabelRender: () => (
                    <th style={{ fontFamily: 'Red Hat Display Black', fontSize: 13 }}>EDITAR</th>
                  ),
                  customBodyRender: (value, tableMeta) => {
                    if (tableMeta.rowData[13] <= 0 && tableMeta.rowData[8] <= 0) {
                      return (
                        <div>
                          <Tooltip title="Editar">
                            <IconButton
                              onClick={() => {
                                setEditInfo(() =>
                                  coupons.data.find((item) => item.id === tableMeta.rowData[0])
                                )
                                setOpenProps({
                                  open: true,
                                  type: 1,
                                })
                              }}
                            >
                              <PencilIcon />
                            </IconButton>
                          </Tooltip>
                        </div>
                      )
                    }

                    return null
                  },
                },
              },
              {
                name: 'useName',
                status: 'Tipo de uso',
                options: {
                  display: 'false',
                },
              },
              {
                name: 'valueApplied',
                status: 'Valor aplicado',
                options: {
                  display: 'false',
                },
              },
              {
                name: 'useType',
                options: {
                  display: 'false',
                },
              },
            ]}
            data={coupons.data}
          />
        </Grid>
      </Grid>
      <CouponsStepper
        open={openProps}
        supplierId={selectedSupplier.id}
        sellerInfo={userLoggedin}
        handleClose={handleClose}
        editInfo={editInfo}
        getCoupons={handleGetCoupons}
        setIsLoading={setIsLoading}
        coupons={coupons.data}
        discountTypes={discountTypes}
        useTypes={useTypes}
        ruleConditions={ruleConditions}
        ruleTypes={ruleTypes}
      />
    </div>
  )
}

export default CreateCoupons
