/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable consistent-return */
import React, { useEffect, useState } from 'react'

import { Button, ButtonGroup, Grid, LinearProgress, Select, Typography } from '@material-ui/core'
import MaUTable from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { useTable, useRowSelect, useExpanded } from 'react-table'

// import OptionsOrdersSelected from '../OptionsOrdersSelected'

// import MaterialTable from 'material-table'

import useStyles from '../products.styles'

import useCustomDialogAlert from 'components/useCustomDialogAlert'

const ProductsShopifySync = ({
  listProductsShopify,
  setListProductShopify,
  getProductsShopify,
  allProductShopifySelectedNum,
  activeStep,
  setActiveStep,
  selectedProductsShopify,
  paginationShopify,
  totalProduct,
  // changePageTable,
  onChangeRowsPerPage,
  urlNext,
  urlPrevious,
  changePerPageTable,
  handleOnClickPreviousPage,
  handleOnClickNextPage,
  skusRepeated,
}) => {
  const classes = useStyles()
  const showDialogAlert = useCustomDialogAlert()

  function SubRows({ row, rowProps, visibleColumns, data, loading }) {
    if (loading) {
      return (
        <TableRow>
          <TableCell />
          <TableCell colSpan={visibleColumns.length - 1}>Loading...</TableCell>
        </TableRow>
      )
    }
    return (
      <>
        {data.map((x, i) => {
          return (
            <TableRow {...rowProps} key={`${rowProps.key}-expanded-${i}`}>
              {row.cells.map((cell) => {
                return (
                  <TableCell {...cell.getCellProps()}>
                    {cell.render(cell.column.SubCell ? 'SubCell' : 'Cell', {
                      value: cell.column.accessor && cell.column.accessor(x, i),
                      row: { ...row, original: x },
                    })}
                  </TableCell>
                )
              })}
            </TableRow>
          )
        })}
      </>
    )
  }
  function SubRowAsync({ row, rowProps, visibleColumns }) {
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState([])

    React.useEffect(() => {
      const timer = setTimeout(() => {
        setData(row.original.variants)
        setLoading(false)
      }, 500)

      return () => {
        clearTimeout(timer)
      }
    }, [])

    return (
      <SubRows
        row={row}
        rowProps={rowProps}
        visibleColumns={visibleColumns}
        data={data}
        loading={loading}
      />
    )
  }

  const columns = React.useMemo(
    () => [
      {
        // Make an expander cell
        Header: () => null, // No header
        id: 'expander', // It needs an ID
        Cell: ({ row }) => (
          // Use Cell to render an expander for each row.
          // We can use the getToggleRowExpandedProps prop-getter
          // to build the expander.
          <span {...row.getToggleRowExpandedProps()}>{row.isExpanded ? '^' : '>'}</span>
        ),
        // We can override the cell renderer with a SubCell to be used with an expanded row
        SubCell: () => null, // No expander on an expanded row
      },
      {
        Header: 'Nombre producto',
        accessor: 'title',
        SubCell: (cellProps) => {
          return <>{cellProps.value}</>
        },
      },
      { Header: 'Variaciones', accessor: 'NumVariation' },
      { Header: 'Tags Shopify', accessor: 'tags' },
    ],
    []
  )
  const renderRowSubComponent = React.useCallback(
    ({ row, rowProps, visibleColumns }) => (
      <SubRowAsync row={row} rowProps={rowProps} visibleColumns={visibleColumns} />
    ),
    []
  )
  const IndeterminateCheckbox = React.forwardRef(({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef()
    const resolvedRef = ref || defaultRef
    useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    return (
      <>
        <input type="checkbox" ref={resolvedRef} {...rest} />
      </>
    )
  })
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    visibleColumns,
    selectedFlatRows,
  } = useTable(
    {
      columns,
      data: listProductsShopify,
    },
    useExpanded,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => {
        return [
          // Let's make a column for selection
          {
            id: 'selection',
            // The header can use the table's getToggleAllRowsSelectedProps method
            // to render a checkbox
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <div>
                <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
              </div>
            ),
            // The cell can use the individual row's getToggleRowSelectedProps method
            // to the render a checkbox
            Cell: ({ row }) => {
              const rowOriginal = row.original
              if (rowOriginal.variants) {
                const skuValidate = skusRepeated.includes(
                  rowOriginal?.variants[0]?.option1 === 'Default Title'
                    ? rowOriginal?.variants[0].sku
                    : rowOriginal.handle
                )
              }

              return (
                <div>
                  <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
                </div>
              )
            },
          },
          ...columns,
        ]
      })
    }
  )

  const handleNext = () => {
    if (activeStep === 0 && listProductsShopify.length === 0) return
    if (allProductShopifySelectedNum && allProductShopifySelectedNum.length === 0) {
      showDialogAlert(true, '¡Advertencia!', `Selecciona mínimo un producto `, 'Cerrar')

      return false
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  useEffect(() => {
    selectedProductsShopify(selectedFlatRows.map((o) => o.original))
  }, [selectedFlatRows])
  return (
    <>
      <Typography variant="subtitle1" className={classes.marginMessage}>
        Lista de productos: Selecciona los productos que deseas integrar con LuegopaGO
      </Typography>
      <Button variant="contained" color="secondary" onClick={getProductsShopify}>
        Sincronizar productos de shopify
      </Button>
      <Grid container direction="column" className={classes.divContent}>
        <div style={{ maxWidth: '100%' }}>
          <MaUTable {...getTableProps()}>
            <TableHead>
              {headerGroups.map((headerGroup) => (
                <TableRow {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <TableCell {...column.getHeaderProps()}>{column.render('Header')}</TableCell>
                  ))}
                </TableRow>
              ))}
            </TableHead>
            <TableBody {...getTableBodyProps()}>
              {rows.map((row, i) => {
                prepareRow(row)
                const rowProps = row.getRowProps()
                return (
                  // Use a React.Fragment here so the table markup is still valid
                  <React.Fragment key={rowProps.key}>
                    <TableRow {...rowProps}>
                      {row.cells.map((cell) => {
                        const productOriginal = cell.row.original
                        return (
                          <TableCell
                            className={
                              skusRepeated.includes(
                                productOriginal?.variants[0].option1 === 'Default Title'
                                  ? productOriginal?.variants[0].sku.replace(/\s/g, '')
                                      .toUpperCase()
                                  : productOriginal.handle.replace(/\s/g, '').toUpperCase()
                              )
                                ? classes.tableCell
                                : null
                            }
                            {...cell.getCellProps()}
                          >
                            {cell.render('Cell')}
                          </TableCell>
                        )
                      })}
                    </TableRow>
                    {/* We could pass anything into this */}
                    {row.isExpanded && renderRowSubComponent({ row, rowProps, visibleColumns })}
                  </React.Fragment>
                )
              })}
            </TableBody>
          </MaUTable>
          {/* {isLoading && <LinearProgress />} */}
          <Grid container justify="space-between" className={classes.paginationRoot}>
            <Grid item>
              <Select
                native
                // value={rowsPerPage}
                onChange={(e) => {
                  changePerPageTable(Number(e.target.value))
                }}
                inputProps={{
                  name: 'rows-per-page',
                  id: 'outlined-age-native-simple',
                }}
                margin="dense"
              >
                {[10, 20, 50, 100].map((pageSize) => (
                  <option value={pageSize} key={pageSize}>
                    {pageSize}
                  </option>
                ))}
              </Select>
              <Typography variant="inherit" className={classes.labelRowsPerPage}>
                Pedidos por página
              </Typography>
            </Grid>
            <Grid item>
              <ButtonGroup aria-label="outlined button group" className={classes.paginationButtons}>
                <Button disabled={urlPrevious === ''} onClick={handleOnClickPreviousPage}>
                  {'<'}
                </Button>
                <Button disabled={urlNext === ''} onClick={handleOnClickNextPage}>
                  {'>'}
                </Button>
              </ButtonGroup>
            </Grid>
          </Grid>
        </div>
      </Grid>
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        className={classes.divContent}
      >
        <Button
          className={classes.btnBackgroundPrimary}
          variant="contained"
          color="secondary"
          onClick={handleNext}
        >
          Continuar
        </Button>
      </Grid>
    </>
  )
}
export default ProductsShopifySync
