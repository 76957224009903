/* eslint-disable import/prefer-default-export */
import axios from 'axios'

const postApproveAndRejectProducts = async (productList, url) => {
  const result = await axios.post(url, productList)
  return result
}
const deleteProductById = async (productList, url) => {
  const result = await axios.delete(url, productList)
  return result
}
const getProductsAdmin = async (url) => {
  const result = await axios.get(url)
  return result
}
export { postApproveAndRejectProducts, deleteProductById, getProductsAdmin }
