/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react'

import {
  Button,
  FormControlLabel,
  Grid,
  InputLabel,
  Switch,
  TextField,
  Typography,
} from '@material-ui/core'
import Axios from 'axios'

import useStyles from '../../products.styles'

import AttributeOptions from './AttributeOptions'

import DialogAlert from 'components/DialogAlert'
import useCustomDialogAlert from 'components/useCustomDialogAlert'
import { API_CATEGORIES_ATTRIBUTE } from 'constants/urls'
import { useProduct } from 'contextApi/ProductContext'

const Atributtes = ({ setIsLoadingBackDrop }) => {
  const classes = useStyles()
  const { product, setProduct, isApprove } = useProduct()
  const showDialogAlert = useCustomDialogAlert()
  const [openModalOptions, setOpenModalOptions] = useState({ open: false, add: true })
  const [categoriesWithAtributtes, setCategoriesWithAtributtes] = useState([])
  const [newAttributeInfo, setNewAttributeInfo] = useState({
    name: '',
  })
  const [nameError, setNameError] = useState(false)

  const [lastIndexCategorie, setLastIndexCategorie] = useState(null)
  const [optionsCorrect, setOptionsCorrect] = useState(false)
  const [optionList, setOptionList] = useState({
    idAttribute: null,
    value: [],

  })
  const [disableAdd, setDisableAdd] = useState(false)

  const [dialogAlert, setDialogAlert] = useState({
    open: false,
    title: '',
    message: '',
    actionConfirm: null,
    actionCancel: null,
    textButtonConfirm: null,
    textButtonCancel: null,
  })
  const handleCloseDialog = () => {
    setDialogAlert({ ...dialogAlert, open: false })
  }

  const getAtributtesByCategory = async () => {
    setIsLoadingBackDrop(true)
    let lastIndex = 0

    const allIdsCategories = product.categories
      .filter((element, index) => {
        if (index === product.categories.length - 1) {
          lastIndex = index
          setLastIndexCategorie(index)
          return true
        }
        return false
      })
      .map((cat) => {
        return Number(cat.id)
      })
    await Axios.post(`${API_CATEGORIES_ATTRIBUTE}`, allIdsCategories)
      .then(function (response) {
        setIsLoadingBackDrop(true)
        if (response.status === 200) {
          if (response.data?.data?.length === 0 && product?.attributes?.length === 0) {
            showDialogAlert(
              true,
              '¡Importante!',
              `No hay atributos asociados a esta categoría.`,
              'Agregar'
            )
            setNameError(true)
          } else {
            setDisableAdd(false)
            const allAtributtes = response.data.data
            // add custom attributes
            product.attributes.map((attribute) => {
              const exist = allAtributtes.find((a) => a.name === attribute.name)

              if (!exist) allAtributtes.push(attribute)
            })
            // assigner the atributtes to categories
            const temp = product.categories.map((element, index) => {
              if (index === lastIndex) {
                const tempElement = element
                if (element?.atributtes || !element.atributtes || element.atributtes.length === 0) {
                  const atributtes = []
                  allAtributtes.map((e) => {
                    // if (e.parent === Number(element.id)) {
                    let isAttributeChecked = false
                    if (product.attributes !== undefined) {
                      isAttributeChecked = product.attributes.filter((item) => item.name === e.name)
                    }
                    const tempAttribute = {
                      name: e.name,
                      value:
                        isAttributeChecked.length > 0 ? isAttributeChecked[0].value : e.options,
                      visible: true,
                      variation: true,
                      isChecked: isAttributeChecked.length > 0,
                    }

                    return atributtes.push(tempAttribute)
                    // }
                    // return true
                  })

                  if (product.attributes !== undefined) {
                    product.attributes.map((attributeAdd) => {
                      const existAttributeAdd = atributtes.filter(
                        (itemAttributeAdd) => itemAttributeAdd.name === attributeAdd.name
                      )
                      if (existAttributeAdd.length === 0) {
                        attributeAdd.isChecked = true
                        atributtes.push(attributeAdd)
                      }
                    })
                  }
                  tempElement.atributtes = atributtes
                }
                return tempElement
              }
              return element
            })

            setProduct({ ...product, categoriesWithAtributtes: temp })
            setCategoriesWithAtributtes(temp)
          }

          setIsLoadingBackDrop(false)
        }
      })
      .catch(function () {
        setIsLoadingBackDrop(false)
      })
  }

  const functionChange = (tempCategories, category, atributteName) => {
    setCategoriesWithAtributtes(tempCategories)
    const tempAtributtesChecked = []
    tempCategories.map((categoryItem) => {
      if (categoryItem.id === category.id) {
        categoryItem.atributtes.map((item) => {
          if (item.isChecked) {
            tempAtributtesChecked.push({
              name: item.name,
              visible: true,
              variation: true,
              value: item.value,
            })
          }
        })
      }
    })
    if (product.variations) {
      product.variations.map((variationObjet) => {
        const itemVariation = variationObjet

        const indexAttribute = itemVariation.attributes.findIndex((atrributeElement) => {
          return atrributeElement.name === atributteName
        })
        const foundAttribute = itemVariation.attributes.find((atrributeElement) => {
          return atrributeElement.name === atributteName
        })
        if (foundAttribute) {
          itemVariation.attributes.splice(indexAttribute, 1)
        } else {
          tempAtributtesChecked.map((tempItem) => {
            if (tempItem.name === atributteName) {
              itemVariation.attributes.push({
                name: tempItem.name,
                visible: true,
                variation: true,
                value: [],
              })
            }
          })
        }
      })
    }
    const newArray = product.attributes
    product.attributes.splice(0)
    tempAtributtesChecked.map((item) => {
      newArray.push(item)
    })
    setProduct({ ...product, attributes: newArray })
  }

  const handleCloseModalModel = (add = false) => {
    if (!add) {
      let atributtes = []
      let temp = null
      if (optionList.value.length > 0) {
        if (product.categoriesWithAtributtes !== undefined) {
          temp = product.categoriesWithAtributtes.map((item, index) => {
            atributtes = item.atributtes
            if (index === product.categoriesWithAtributtes.length - 1) {
              Object.values(item?.atributtes).map((activeAttr) => {
                if (optionList.name === activeAttr.name) {
                  // activeAttr.isChecked = true
                }
                return atributtes
              })
            }
            return item
          })
        }

        functionChange(
          categoriesWithAtributtes,
          categoriesWithAtributtes[categoriesWithAtributtes.length - 1]
        )
        setProduct({ ...product, categoriesWithAtributtes: temp })
      }
    }

    setOpenModalOptions({ open: false })
  }

  const handleChange = (event, category) => {
    const atributteName = event.target.name
    let attributeSelected = {}
    const tempAtributtes = category.atributtes.map((element) => {
      const item = element
      if (item.name.toString() === atributteName) {
        item.isChecked = event.target.checked
        attributeSelected = item
      }
      return item
    })

    const tempCategories = categoriesWithAtributtes.map((element) => {
      const item = element
      if (item.id === category.id) {
        item.atributtes = tempAtributtes
      }
      return item
    })
    if (event.target.checked) {
      if (attributeSelected.value.length > 0) {
        setDialogAlert({
          open: true,
          title: '¿Desea editar la configuración de opciones de este atributo?',
          textButtonConfirm: 'Si',
          textButtonCancel: 'No',
          actionConfirm: () => {
            setOptionList(attributeSelected)
            setOpenModalOptions({ open: true, add: false })
            functionChange(tempCategories, category, atributteName)
            handleCloseDialog()
          },
          actionCancel: () => {
            functionChange(tempCategories, category, atributteName)
            handleCloseDialog()
          },
        })
      } else {
        setOptionList(attributeSelected)
        categoriesWithAtributtes[categoriesWithAtributtes.length - 1].atributtes.map(
          (attribute) => {
            if (attribute.name === atributteName) {
              attribute.isChecked = false
            }
          }
        )
        setOpenModalOptions({ open: true, add: false })
        handleCloseDialog()
      }
    } else {
      functionChange(tempCategories, category, atributteName)
    }
  }

  const addAtribute = (event) => {
    event.preventDefault()
    // const date = new Date()
    // const dates = `${date.getMilliseconds()}${date.getDate()}${date.getMinutes()}`

    const { name } = newAttributeInfo
    if (!name.trim() || name.trim() === '') {
      showDialogAlert(true, '¡Advertencia!', `Ingrese el nombre del atributo.`, 'Cerrar')
      setNameError(true)
      return false
    }
    if (lastIndexCategorie !== null) {
      const itemList = {
        // id: Number(`${lastIndexCategorie}${dates}`),
        name: name,
        // isChecked: false,
        new: true,
        position: lastIndexCategorie,
        visible: true,
        variation: true,
        value: [],
      }
      const nameAtributte =
        categoriesWithAtributtes.length === 0
          ? undefined
          : categoriesWithAtributtes[lastIndexCategorie].atributtes.find(
              (atributtes) => atributtes.name === name
            )
      if (nameAtributte === undefined) {
        setOptionList(itemList)
        setNewAttributeInfo({
          name: '',
        })
        setOpenModalOptions({ open: true, add: true })
      } else {
        showDialogAlert(
          true,
          '¡Advertencia!',
          `Este nombre de atributo ya se encuentra en la categoría`,
          'Cerrar'
        )
      }
    }
  }

  useEffect(() => {
    if (
      product.categoriesWithAtributtes?.length > 0 &&
      product.categories[0].id === product.categoriesWithAtributtes[0]?.id
    ) {
      setCategoriesWithAtributtes(product.categoriesWithAtributtes)
    } else {
      getAtributtesByCategory()
    }
  }, [product.attributes])

  useEffect(() => {
    product.categories.filter((element, index) => {
      if (index === product.categories.length - 1) {
        setLastIndexCategorie(index)
        return true
      }
      return false
    })
  }, [])

  return (
    <div>
      <DialogAlert
        open={dialogAlert.open}
        title={dialogAlert.title}
        handleClose={handleCloseDialog}
        message={dialogAlert.message}
        textButtonConfirm={dialogAlert.textButtonConfirm}
        actionConfirm={dialogAlert.actionConfirm}
        actionCancel={dialogAlert.actionCancel}
        textButtonCancel={dialogAlert.textButtonCancel}
      />
      {openModalOptions.open && (
        <AttributeOptions
          optionList={optionList}
          add={openModalOptions.add}
          setOptionList={setOptionList}
          categoriesWithAtributtes={categoriesWithAtributtes}
          indexCat={lastIndexCategorie}
          setCategoriesWithAtributtes={setCategoriesWithAtributtes}
          onClose={handleCloseModalModel}
          setOptionsCorrect={setOptionsCorrect}
        />
      )}
      <Grid container direction="column" justify="space-between" alignItems="flex-start">
        <Typography variant="subtitle1" className={classes.ProductPartTypo}>
          Atributos
        </Typography>
        {categoriesWithAtributtes[lastIndexCategorie]?.atributtes?.length === 0 ||
        !categoriesWithAtributtes[lastIndexCategorie]?.atributtes ? (
          <Typography variant="body1" style={{ marginTop: '5%', marginBottom: '5%' }}>
            No hay atributos asociados a esta categoría
          </Typography>
        ) : null}
        {!isApprove && (
          <Grid container direction="column" justify="center" alignItems="flex-start">
            <InputLabel style={{ marginBottom: 10 }} error={nameError}>
              Nombre de atributo
            </InputLabel>
            <TextField
              fullWidth
              id="name"
              name="name"
              variant="outlined"
              onChange={(event) => {
                setNameError(false)
                setNewAttributeInfo({ newAttributeInfo, name: event.target.value })
              }}
              error={nameError}
              value={newAttributeInfo.name}
            />
            <Button
              className={[classes.btnBackgroundPrimary, classes.addAtributtes]}
              variant="contained"
              color="secondary"
              onClick={addAtribute}
              disabled={disableAdd}
            >
              Agregar
            </Button>
          </Grid>
        )}

        {product.categoriesWithAtributtes !== undefined &&
          product.categoriesWithAtributtes[lastIndexCategorie]?.atributtes?.length > 0 && (
            <>
              {!isApprove && (
                <Typography variant="subtitle1" className={classes.ProductPartTypo}>
                  Atributos por variable
                </Typography>
              )}
              {product.categoriesWithAtributtes
                ? product.categoriesWithAtributtes.map((category, index) => {
                    return (
                      <Grid className={classes.divAtributtesSelection}>
                        {product.categoriesWithAtributtes[index]?.atributtes
                          ? product.categoriesWithAtributtes[index].atributtes.map((item) => {
                              return (
                                <FormControlLabel
                                  control={
                                    <Switch
                                      checked={item.isChecked}
                                      onChange={(event) => handleChange(event, category)}
                                      name={item.name}
                                      color="primary"
                                      disabled={isApprove && !item.isChecked}
                                    />
                                  }
                                  label={item.name}
                                  style={{ pointerEvents: isApprove ? 'none' : 'all' }}
                                />
                              )
                            })
                          : null}
                      </Grid>
                    )
                  })
                : null}
            </>
          )}
      </Grid>
    </div>
  )
}

export default Atributtes
