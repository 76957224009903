import React, { useState } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  FormControl,
  TextField,
  CircularProgress,
  Button,
  InputLabel,
} from '@material-ui/core'
import axios from 'axios'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'

import useStyles from './modalTransportGuide.styles'

import DialogAlert from 'components/DialogAlert'
import { API_PRODUCTS } from 'constants/urls'
import { isAlphanumeric } from 'utils/utils'

const schema = yup.object().shape({
  guideNumber: yup
    .string()
    .matches(/^[A-Za-z0-9\s]+$/, 'No se permiten caracteres especiales')
    .min(3, 'Debes ingresar mínimo 3 caracteres')
    .max(50, 'Debes ingresar máximo 50 caracteres')
    .typeError('Debes de ingresar la altura')
    .required('Campo requerido'),
  transportEnterprise: yup
    .string()
    .matches(/^[A-Za-z0-9\s-]+$/, 'No se permiten caracteres especiales')
    .min(3, 'Debes ingresar mínimo 3 caracteres')
    .max(150, 'Debes ingresar máximo 150 caracteres')
    .typeError('Ingresa una descriptión correcta'),
})

const ModalTransportGuide = ({
  currentOrder,
  productsToGuide,
  onClose,
  orders,
  setIsLoadingBackDrop,
  isLoadingBackDrop,
  changeProductsAsReadyToSend,
}) => {
  const { register, handleSubmit, errors, control } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
  })
  const classes = useStyles()
  const [count, setCount] = useState(0)
  const temp = []
  productsToGuide.map((item) => {
    if (!item.transporterLuegopaGo) {
      temp.push(item)
    }
  })
  const [products, setProducts] = useState(temp)
  const [dialogAlert, setDialogAlert] = useState({
    open: false,
    title: '',
    message: '',
    actionConfirm: null,
    textButtonConfirm: null,
    textButtonCancel: null,
  })

  const onSubmit = async (e) => {
    setIsLoadingBackDrop(true)

    const productIds = []
    temp.map((prod) => {
      productIds.push(prod.id)
    })

    await axios
      .post(`${API_PRODUCTS}/TransportDataCapture`, {
        orderId: currentOrder,
        numberGuide: e.guideNumber,
        nameTransporter: e.transportEnterprise,
        products: productIds,
        wareHouseId: productsToGuide[0].origin,
      })
      .then((response) => {
        if (response.status === 200) {
          setDialogAlert({
            open: true,
            title: `El Pedido Nº${currentOrder} fue cambiado a estado Enviado`,
            message: null,
            actionConfirm: () => {
              handleCloseDialog()
              changeProductsAsReadyToSend(temp)
            },
            textButtonConfirm: orders.length > 1 ? 'Guardar y continuar ' : 'Aceptar',
            textButtonCancel: orders.length > 1 ? 'Cancelar' : null,
          })
          setIsLoadingBackDrop(false)
        }
      })
      .catch(() => {
        setIsLoadingBackDrop(false)
      })
  }

  const handleCloseDialog = () => {
    onClose()
    setDialogAlert({ ...dialogAlert, open: false })
    setIsLoadingBackDrop(false)
  }

  return (
    <>
      <Dialog disableBackdropClick disableEscapeKeyDown onClose={onClose} open>
        <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
          <DialogTitle className={classes.title} disableTypography>
            Información de Pedido: N° 
{' '}
{currentOrder}
          </DialogTitle>
          <DialogContent>
            <Grid md={12} sm={8} xs={12} className={[classes.center, classes.gridPadding]}>
              <div className={classes.titleProduct}>
                {products.length > 1
                  ? `Envío de ${products.length} productos`
                  : products[count].name}
              </div>
              <FormControl fullWidth className={classes.padding}>
                <InputLabel error={Boolean(errors.guideNumber)} className={classes.numGuia}>
                  Número de Guía
                </InputLabel>

                <TextField
                  name="guideNumber"
                  onKeyPress={(event) => {
                    if (isAlphanumeric(event.target.value)) {
                      return true
                    }
                    return false
                  }}
                  inputRef={register({
                    required: 'Required',
                  })}
                  error={Boolean(errors.guideNumber)}
                  type="text"
                  variant="outlined"
                  helperText={
                    errors?.guideNumber
                      ? errors?.guideNumber?.message
                      : 'Introducir el número de la guía con la que será enviado el producto'
                  }
                />

                <FormControl fullWidth className={classes.padding}>
                  <InputLabel
                    error={Boolean(errors.transportEnterprise)}
                    className={classes.numGuia}
                  >
                    Empresa Transportadora
                  </InputLabel>

                  <TextField
                    name="transportEnterprise"
                    inputRef={register({
                      required: 'Required',
                    })}
                    inputProps={{ maxLength: 150 }}
                    variant="outlined"
                    error={Boolean(errors.transportEnterprise)}
                    helperText={
                      errors?.transportEnterprise
                        ? errors?.transportEnterprise?.message
                        : 'Introducir la empresa transportadora con la que será enviado el producto'
                    }
                  />
                </FormControl>
              </FormControl>
            </Grid>

            <Grid container justify="center" className={classes.buttons}>
              <Button
                disabled={isLoadingBackDrop}
                onClick={() => onClose()}
                variant="contained"
                color="secondary"
                className={classes.buttonHoverRed}
              >
                Cancelar
              </Button>
              <Button
                disabled={isLoadingBackDrop}
                type="submit"
                variant="contained"
                color="secondary"
                className={classes.buttonshoverGreen}
              >
                Guardar y continuar
              </Button>
            </Grid>
          </DialogContent>
        </form>
      </Dialog>
      <DialogAlert
        open={dialogAlert.open}
        title={dialogAlert.title}
        handleClose={handleCloseDialog}
        message={dialogAlert.message}
        textButtonConfirm={dialogAlert.textButtonConfirm}
        actionConfirm={dialogAlert.actionConfirm}
        textButtonCancel={dialogAlert.textButtonCancel}
      />
    </>
  )
}

export default ModalTransportGuide
