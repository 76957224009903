/* eslint-disable no-shadow */
import React, { useState, useContext } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import SyncIcon from '@material-ui/icons/Sync'
import cx from 'classnames'
import PerfectScrollbar from 'perfect-scrollbar'
import { Switch, Route, Redirect, useHistory } from 'react-router-dom'

import styles from 'assets/jss/material-dashboard-pro-react/layouts/adminStyle'
import AssociationSeller from 'components/AssociationSeller'
import ChangePassword from 'components/ChangePassword'
import DialogAlert from 'components/DialogAlert'
import AdminNavbar from 'components/Navbars/AdminNavbar'
import Sidebar from 'components/Sidebar/Sidebar'
import TACModal from 'components/TACModal/Index'
import WelcomeSellerModal from 'components/WelcomeSellerModal'
import { DROPSHIPPER, SELLER } from 'constants/rolesConst'
import { routesConst } from 'constants/routesConsts'
import useAuthContext from 'contextApi/AuthContext'
import routesByRoleService from 'services/routesByRoleService'
import Integrations from 'views/Integrations'
import 'perfect-scrollbar/css/perfect-scrollbar.css'

const logo =
  'https://luegopago.blob.core.windows.net/luegopago-uploads/sellerCenter/site/img/LuegoPagoSellerCenterLogo.png'

let ps

const useStyles = makeStyles(styles)
export default function Dashboard(props) {
  const { userLoggedin, dialogAlert, setDialogAlert, isShopify, getAccessisShopify, returnsCount } =
    useContext(useAuthContext)
  const history = useHistory()
  const { ...rest } = props

  // states and functions
  const [mobileOpen, setMobileOpen] = React.useState(false)
  const [miniActive, setMiniActive] = React.useState(false)
  const [openWelcomeDialog, setOpenWelcomeDialog] = useState(false)
  const [openChangePassword, setOpenChangePassword] = useState(false)
  const [openAssociationSeller, setOpenAssociationSeller] = useState(false)
  const [openTermsAndConditionsModal, setOpenTermsAndConditionsModal] = useState(false)

  const color = 'blue'
  const bgColor = 'black'

  // styles
  const classes = useStyles()
  const mainPanelClasses = `${classes.mainPanel} ${cx({
    [classes.mainPanelSidebarMini]: miniActive,
    [classes.mainPanelWithPerfectScrollbar]: navigator.platform.indexOf('Win') > -1,
  })}`
  // ref for main panel div
  const mainPanel = React.createRef()

  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false)
    }
  }

  const restriction = () => {
    if (
      !userLoggedin.passwordAlreadyChanged ||
      !userLoggedin.isHadWarehouse ||
      !userLoggedin.picture ||
      !userLoggedin.isHadTermsAndConditions
    ) {
      if (history.location.pathname === '/admin/storeCommission') {
        setOpenWelcomeDialog(false)
      } else {
        setOpenWelcomeDialog(true)
      }
    } else {
      setOpenWelcomeDialog(false)
    }
  }
  const getIsFirstAccessRole = () => {
    if (userLoggedin.role === DROPSHIPPER || userLoggedin.role === SELLER) {
      return true
    }

    return false
  }

  React.useEffect(() => {
    const isFirstAccess = getIsFirstAccessRole()
    if (isFirstAccess) restriction()
  }, [mainPanel])

  React.useEffect(() => {
    if (navigator.platform.indexOf('Win') > -1) {
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      })
      document.body.style.overflow = 'hidden'
    }
    window.addEventListener('resize', resizeFunction)

    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf('Win') > -1) {
        ps.destroy()
      }
      window.removeEventListener('resize', resizeFunction)
    }
  }, [history, mainPanel])

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }
  const getRoute = () => {
    return window.location.pathname !== '/admin/full-screen-maps'
  }
  const handleCloseDialog = () => {
    setDialogAlert({ ...dialogAlert, open: false })
  }

  const getActiveRoute = (routes) => {
    const activeRoute = 'Default Brand Text'
    for (let i = 0; i < routes.length; i += 1) {
      if (routes[i].collapse) {
        const collapseActiveRoute = getActiveRoute(routes[i].views)
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute
        }
      } else if (routes[i].withParam) {
        const routeSplit = routes[i].path.split('/')
        routeSplit.pop()
        const finalPath = routeSplit.join('/')
        if (window.location.href.indexOf(routes[i].layout + finalPath) !== -1) {
          return routes[i].name
        }
      } else if (window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1) {
        return routes[i].name
      }
    }
    return activeRoute
  }
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views)
      }
      if (prop.layout === '/admin') {
        return <Route exact path={prop.layout + prop.path} component={prop.component} key={key} />
      }
      return null
    })
  }
  const sidebarMinimize = () => {
    setMiniActive(!miniActive)
  }

  const cancelAssociationModal = () => {
    setOpenAssociationSeller(false)
    setOpenWelcomeDialog(true)
    return true
  }
  const cancelPasswordModal = () => {
    setOpenChangePassword(false)
    setOpenWelcomeDialog(true)
    return true
  }
  const cancelTACModal = () => {
    setOpenTermsAndConditionsModal(false)
    setOpenWelcomeDialog(true)
  }

  const filterRoutes = () => {
    const filterProductByRole = routesByRoleService(userLoggedin.role)
    const routeShopify = getAccessisShopify() === 'true'
    if (isShopify && !routeShopify) {
      const routeProduct = filterProductByRole.find((element) => element.name === 'Productos')
      const routeShopify = routeProduct.views.find((product) => product.name === 'Sincronización')

      if (routeProduct && !routeShopify) {
        filterProductByRole[1].views.push({
          path: routesConst.integrations,
          name: 'Sincronización',
          component: Integrations,
          child: ['shopify-products'],
          layout: '/admin',
          icon: SyncIcon,
        })
      }
    }
    return filterProductByRole
  }

  const routesByRole = filterRoutes()

  return (
    <div className={classes.wrapper}>
      <DialogAlert
        open={dialogAlert.open}
        title={dialogAlert.title}
        handleClose={handleCloseDialog}
        message={dialogAlert.message}
        personalized={dialogAlert.personalized}
        textButtonConfirm={dialogAlert.textButtonConfirm}
        actionConfirm={dialogAlert.actionConfirm}
        actionCancel={dialogAlert.actionCancel}
        textButtonCancel={dialogAlert.textButtonCancel}
        children={dialogAlert.children}
        successful={dialogAlert.successful}
      />
      {userLoggedin.role !== SELLER && (
        <DialogAlert open={openWelcomeDialog} title="" personalized>
          <WelcomeSellerModal
            handlerClosed={setOpenWelcomeDialog}
            setOpenPasswordModal={setOpenChangePassword}
            setOpenAssociationModal={setOpenAssociationSeller}
            setOpenTermsAndConditionsModal={setOpenTermsAndConditionsModal}
          />
        </DialogAlert>
      )}

      <DialogAlert open={openTermsAndConditionsModal} title="" personalized>
        <TACModal handlerClosed={setOpenTermsAndConditionsModal} cancelTACModal={cancelTACModal} />
      </DialogAlert>
      <DialogAlert open={openChangePassword} title="Cambiar tu contraseña" personalized>
        <ChangePassword
          handlerClosed={setOpenChangePassword}
          cancelPasswordModal={cancelPasswordModal}
        />
      </DialogAlert>

      <DialogAlert open={openAssociationSeller} title="Llene los campos para avanzar" personalized>
        <AssociationSeller
          handlerClosed={setOpenAssociationSeller}
          cancelAssociationModal={cancelAssociationModal}
        />
      </DialogAlert>

      <Sidebar
        returnsCount={returnsCount}
        routes={routesByRole}
        logo={logo}
        userEmail={userLoggedin.email}
        handleDrawerToggle={handleDrawerToggle}
        open={mobileOpen}
        color={color}
        bgColor={bgColor}
        miniActive={miniActive}
        {...rest}
      />
      <div id="main" className={mainPanelClasses} ref={mainPanel}>
        <AdminNavbar
          color={bgColor}
          contextPrueba={userLoggedin}
          sidebarMinimize={sidebarMinimize}
          miniActive={miniActive}
          brandText={getActiveRoute(routesByRole)}
          handleDrawerToggle={handleDrawerToggle}
          {...rest}
        />
        <div className={classes.content}>
          <div>
            <Switch>
              {getRoutes(routesByRole)}
              <Redirect
                onClick={() => restriction()}
                from="/admin"
                to={`/admin${routesByRole[0].path}`}
              />
            </Switch>
          </div>
        </div>
      </div>
    </div>
  )
}
