/* eslint-disable import/prefer-default-export */
import axios from 'axios'

const putCategoriesPercentById = async (url, category) => {
  const result = await axios.put(url, category)
  return result
}

const postCategoriesPercentByIdSeller = async (url, commisions) => {
  const result = await axios.post(url, commisions)
  return result
}
export { putCategoriesPercentById, postCategoriesPercentByIdSeller }
