import React, { useState, useEffect, useRef } from 'react'

import DateFnsUtils from '@date-io/date-fns'
import {
  Paper,
  InputBase,
  IconButton,
  CircularProgress,
  Popover,
  FormGroup,
  InputLabel,
  Checkbox,
  FormControlLabel,
  InputAdornment,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import { es } from 'date-fns/locale'
import PropTypes from 'prop-types'

import { CalendarIcon, SearchIcon } from 'components/icons/personalizedIcons'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 410,
    height: 48,
    border: '2px solid #7A7A7A',
    borderRadius: 8,
    marginTop: 15,
    boxShadow: 'none',
  },
  formGroupDateFrom: {
    marginTop: '11px',
  },
  formGroupDateTo: {
    marginTop: '7px',
  },
  labelDates: {
    marginBottom: '5px',
  },
  popover: {
    padding: 19,
  },
  scroll: { overflowY: 'hidden', overflow: 'hidden' },
  paper: {
    padding: theme.spacing(1),
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    fontFamily: 'Red Hat Display Regular',
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}))

function SearchBoxFilter({
  placeholder,
  handlerSearch,
  avalaibleSetTextValueEmpty,
  setAvalaibleSetTextValueEmpty,
}) {
  const classes = useStyles()

  const [searchText, setSearchText] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [searchFilter, setSearchFilter] = useState(false)
  const [isFilterDate, setIsFilterDate] = useState(false)
  const [selectedDateFrom, setSelectedDateFrom] = useState(new Date())
  const [selectedDateTo, setSelectedDateTo] = useState(new Date())
  const [fechaError, setFechaError] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const inputRef = useRef()

  useEffect(() => {
    inputRef.current.focus()
    if (isLoading) {
      setAnchorEl(null)
      setIsLoading(false)
    } 
  }, [anchorEl, isLoading])

  const search = () => {
    setAvalaibleSetTextValueEmpty(false)
    handlerSearch(searchText.trimStart(), selectedDateFrom, selectedDateTo, isFilterDate)
  }
  useEffect(() => {
    if (avalaibleSetTextValueEmpty) {
      setSearchText('')
    }
  }, [searchText, avalaibleSetTextValueEmpty])

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  return (
    <>
      <Paper
        onClick={(e) => {
          // setAnchorEl(e.currentTarget)
          setSearchFilter(true)
        }}
        className={classes.root}
      >
        <InputBase
          inputRef={inputRef}
          className={classes.input}
          placeholder={placeholder}
          onKeyUp={(e) => {
            e.preventDefault()
            if (e.key === 'Enter' && searchText !== '') search()
          }}
          onChange={(e) => {
            setAvalaibleSetTextValueEmpty(false)
            setSearchText(e.target.value.trimStart())
          }}
          value={searchText}
        />
        {isLoading && (
          <CircularProgress
            sizstyle={{ marginLeft: 15, position: 'relative', top: 4 }}
            e={24}
            style={{ marginLeft: 15, position: 'relative', top: 4 }}
          />
        )}
        <IconButton
          onClick={(e) => {
            e.preventDefault()
            search()
            setAnchorEl(null)
            setSearchFilter(false)
            setIsLoading(true)
          }}
          className={classes.iconButton}
          aria-label="search"
        >
          <SearchIcon />
        </IconButton>
      </Paper>
      <Popover
        id={id}
        onClose={handleClose}
        open={open}
        classes={{
          paper: classes.popover,
        }}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {' '}
        <typography component="span" variant="body2">
          FILTRAR BÚSQUEDA
        </typography>
        <div style={{ width: 355, height: 200 }}>
          <FormControlLabel
            style={{ marginTop: '10px' }}
            control={
              <Checkbox
                name="filterDates"
                checked={isFilterDate}
                onChange={({ target }) => {
                  setIsFilterDate(target.checked)
                  const date = new Date()
                  const month =
                    date.getMonth() < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
                  setSelectedDateFrom(`${date.getFullYear()}/${month}/${date.getDate()} 00:00:00`)
                  setSelectedDateTo(`${date.getFullYear()}/${month}/${date.getDate()} 23:59:00`)
                }}
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            }
            label="Por fecha"
          />
          {isFilterDate ? (
            <>
              <FormGroup className={classes.formGroupDateFrom}>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
                  <InputLabel error={fechaError} className={classes.labelDates}>
                    Fecha desde
                  </InputLabel>

                  <DatePicker
                    id="date_on_sale_from"
                    value={selectedDateFrom}
                    onChange={(value) => {
                      const date = new Date(value)
                      const month =
                        date.getMonth() < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
                      setSelectedDateFrom(
                        `${date.getFullYear()}/${month}/${date.getDate()} 00:00:00`
                      )
                    }}
                    format="yyyy-MM-dd"
                    inputVariant="outlined"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <CalendarIcon />
                        </InputAdornment>
                      ),
                    }}
                    disablePast={false}
                    KeyboardButtonProps={{ style: { padding: 0 } }}
                  />
                </MuiPickersUtilsProvider>
              </FormGroup>
              <FormGroup className={classes.formGroupDateTo}>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
                  <InputLabel className={classes.labelDates}>Fecha hasta</InputLabel>

                  <DatePicker
                    id="date_on_sale_to"
                    value={selectedDateTo}
                    onChange={(value) => {
                      const date = new Date(value)
                      const month =
                        date.getMonth() < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
                      setSelectedDateTo(`${date.getFullYear()}/${month}/${date.getDate()} 23:59:59`)
                    }}
                    format="yyyy-MM-dd"
                    inputVariant="outlined"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <CalendarIcon />
                        </InputAdornment>
                      ),
                    }}
                    disablePast={false}
                    KeyboardButtonProps={{ style: { padding: 0 } }}
                  />
                </MuiPickersUtilsProvider>
              </FormGroup>
            </>
          ) : null}
        </div>
      </Popover>
    </>
  )
}

SearchBoxFilter.propTypes = {
  placeholder: PropTypes.string,
  handlerSearch: PropTypes.func.isRequired,
}

SearchBoxFilter.defaultProps = {
  placeholder: '¿Qué estás buscando?',
}

export default SearchBoxFilter
