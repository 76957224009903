import { EXPORT_ORDERS_SELLER } from 'constants/urls'
import { getReportOrders } from 'fetchAxios/Orders/Orders'

const exportOrders = async ({ startDate, endDate, idSeller }) => {
  const url = `${EXPORT_ORDERS_SELLER}?sellerId=${
    idSeller || ''
  }&StartDate=${startDate}&EndDate=${endDate}`
  const result = await getReportOrders(url)
  return result
}

export { exportOrders }
