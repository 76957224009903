import React, { useEffect, useState } from 'react'

import {
  CircularProgress,
  Grid,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import CheckIcon from '@material-ui/icons/Check'
import CloseIcon from '@material-ui/icons/Close'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import axios from 'axios'
import MUIDataTable from 'mui-datatables'
import PropTypes from 'prop-types'

import useStyles from './ListComisions.styles'

import Button from 'components/CustomButtons/Button'
import CustomToolbar from 'components/CustomToolbarMuiDatatable/CustomToolbarMuiDatatable'
import ModalAssignmentCommisions from 'components/ModalAssignmentCommisions'
import ModalContainer from 'components/ModalContainer'
import useCustomDialogAlert from 'components/useCustomDialogAlert'
import { API_COMMISIONS_SELLER } from 'constants/urls'
import textLabels from 'utils/MUIDataTableTextLabels'
import { onlyNumber } from 'utils/utils'

const ListCommissions = ({ idSeller }) => {
  const classes = useStyles()
  const sellerId = idSeller.id
  const showDialogAlert = useCustomDialogAlert()

  const [isLoading, setIsLoading] = useState(false)
  const [changeComission, setChangeComission] = useState({ index: '', flag: false })
  const [dataCommissions, setDataCommissions] = useState([])
  const [number, setNumber] = useState({ id: '', value: '' })
  const [isOpen, setIsOpen] = useState(null)
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 10,
    typeFilter: 1,
    perPage: 10,
    prevPage: '',
    nextPage: '',
  })
  const [searchText, setSearchText] = useState('')
  const [totalCount, setTotalCount] = useState()

  const getComissions = async () => {
    await axios
      .get(
        `${API_COMMISIONS_SELLER}/${pagination.pageSize}/${pagination.page}/${sellerId}/${
          searchText === '' ? ' /' : searchText
        }`
      )
      .then((response) => {
        if (response) {
          setDataCommissions(response.data.data)
          setTotalCount(response.data.pagination.totalCount)
          setPagination(...pagination, {
            page: response.data.pagination.pageNumber,
            pageSize: response.data.pagination.pageSize,
          })
          setIsLoading(false)
        }
      })
      .catch(() => {
        setIsLoading(false)
      })
  }

  useEffect(() => {
    getComissions()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination])

  const updateCommision = async (idCommision) => {
    if (number.value === '') {
      showDialogAlert(true, '¡Advertencia!', `El campo no puede estar vacío`, 'Cerrar')
      return false
    }

    if (number.value > 100 || number.value < 0) {
      showDialogAlert(
        true,
        '¡Advertencia!',
        `El valor no debe ser mayor a 100% ni poner números negativos`,
        'Cerrar'
      )
      return false
    }
    try {
      setIsLoading(true)
      const response = await axios.put(`${API_COMMISIONS_SELLER}/${idCommision}`, {
        id: idCommision,
        sellerId: sellerId.id,
        value: number.value,
      })

      if (response) {
        if (response.status === 200) {
          showDialogAlert(
            true,
            '¡Cambio exitoso!',
            `Se ha modficado correctamente la comisión`,
            'Cerrar',
            '',
            true
          )
          getComissions()
          setChangeComission({ index: '', flag: false })
        }
      }
    } catch (error) {
      setIsLoading(false)
    }

    return true
  }
  const deleteCategorieSeller = async (idComision) => {
    try {
      setIsLoading(true)
      const response = await axios.delete(`${API_COMMISIONS_SELLER}/${idComision}`)

      if (response) {
        if (response.status === 200) {
          showDialogAlert(
            true,
            '¡Cambio exitoso!',
            `Se ha eliminado correctamente la categoria`,
            'Cerrar',
            '',
            true
          )
          getComissions()
        }
      }
    } catch (error) {
      setIsLoading(false)
      console.error(error)
    }
  }

  const columns = [
    {
      name: 'id',
      options: { display: false },
    },

    {
      name: 'commissionName',
      label: 'Nombre ',
    },
    {
      name: 'commissionDescription',
      label: 'Descripción  ',
    },
    {
      name: 'value',
      label: 'Valor',
      options: {
        customBodyRender: (value, tableMeta) => {
          return (
            <>
              {changeComission.flag && changeComission.index === tableMeta.rowIndex ? (
                <Grid
                  container
                  direction="row"
                  justify="flex-start"
                  spacing={2}
                  alignItems="center"
                >
                  <Grid item>
                    <TextField
                      onChange={(e) => {
                        if (onlyNumber(e.target.value)) {
                          setNumber({ id: tableMeta.rowData[0], value: e.target.value })
                          return true
                        }
                        return false
                      }}
                      placeholder="Ingrese la comision"
                      InputProps={{
                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                      }}
                      type="number"
                      id="name"
                      min="0"
                      value={
                        number.id === tableMeta.rowData[0] ? number.value : tableMeta.rowData[3]
                      }
                      className={classes.inputSearchCommission}
                    />
                  </Grid>
                </Grid>
              ) : (
                <Typography
                  onClick={(event) => {
                    event.preventDefault()
                    // setChangeComission({ index: tableMeta.rowIndex, flag: true })
                  }}
                >
                  {`${tableMeta.rowData[3]}%`}
                </Typography>
              )}
            </>
          )
        },
      },
    },
    {
      name: '',
      label: 'Acciones',
      options: {
        customBodyRender: (value, tableMeta) => {
          return (
            <>
              {changeComission.flag && changeComission.index === tableMeta.rowIndex ? (
                <>
                  <Tooltip title="Cancelar">
                    <Button
                      onClick={(event) => {
                        event.preventDefault()

                        setChangeComission({ flag: false })
                      }}
                      color="pinterest"
                      justIcon
                      round
                    >
                      <CloseIcon />
                    </Button>
                  </Tooltip>
                  <Tooltip title="Guardar">
                    <Button
                      onClick={(event) => {
                        event.preventDefault()
                        updateCommision(tableMeta.rowData[0])
                      }}
                      color="success"
                      justIcon
                      round
                    >
                      <CheckIcon />
                    </Button>
                  </Tooltip>
                </>
              ) : (
                <>
                  <Tooltip title="Editar">
                    <Button
                      onClick={(event) => {
                        event.preventDefault()
                        setChangeComission({ index: tableMeta.rowIndex, flag: true })
                      }}
                      color="linkedin"
                      justIcon
                      round
                    >
                      <EditIcon />
                    </Button>
                  </Tooltip>

                  <Tooltip title="Eliminar">
                    <Button
                      onClick={(event) => {
                        event.preventDefault()
                        deleteCategorieSeller(tableMeta.rowData[0])
                      }}
                      color="pinterest"
                      justIcon
                      round
                    >
                      <DeleteIcon />
                    </Button>
                  </Tooltip>
                </>
              )}
            </>
          )
        },
      },
    },
  ]

  const onChangeRowsPerPage = (pageSize) => {
    setPagination({
      ...pagination,
      perPage: pageSize,
    })
  }

  const onChangePage = async (page) => {
    setPagination({
      ...pagination,
      page: page + 1,
      prevPage: page,
      nextPage: page + 2,
    })
    document.querySelector('#main').scrollTop = 0
  }

  const closeModal = () => {
    setIsOpen(false)
  }

  const handleChangeSearch = (e) => {
    setSearchText(e.currentTarget.value)
  }

  const handlerSearch = async () => {
    if (!searchText || searchText === ' ') return false
    setIsLoading(true)
    await getComissions()
    return true
  }

  const customSearchRender = (searchTexto, handleSearch, hideSearch) => {
    return (
      <Grid container alignItems="baseline">
        <TextField
          placeholder="Ingrese la comisión"
          type="text"
          id="searchText"
          onChange={handleChangeSearch}
          value={searchText}
          className={classes.inputSearchCommission}
          style={{ fontFamily: 'Red Hat Display Regular' }}
        />

        <Grid>
          <Button className={classes.btnSearchCommission} color="secondary" onClick={handlerSearch}>
            Buscar
          </Button>

          <Button color="pinterest" justIcon onClick={hideSearch} style={{ marginLeft: '.5rem' }}>
            <CloseIcon />
          </Button>
        </Grid>
      </Grid>
    )
  }

  const options = {
    print: false,
    filter: false,
    sort: false,
    download: false,
    viewColumns: false,
    filterType: 'dropdown',
    responsive: 'scrollMaxHeight',
    rowHover: true,
    selectableRows: 'none',
    textLabels,
    pagination: true,
    serverSide: true,
    count: totalCount,
    rowsPerPage: pagination.perPage,
    rowsPerPageOptions: [5, 10, 20, 100],
    onChangeRowsPerPage,
    onChangePage,
    customSearchRender,
    customToolbar: () => {
      return (
        <CustomToolbar
          icon={<AddIcon />}
          tooltip="Asignar comisión"
          onClick={() => {
            setIsOpen(!isOpen)
          }}
        />
      )
    },
  }

  return (
    <>
      <MUIDataTable
        title={
          <Typography variant="h6">
            Lista de comisiones
            {isLoading && (
              <CircularProgress
                size={24}
                style={{ marginLeft: 15, position: 'relative', top: 4 }}
              />
            )}
          </Typography>
        }
        data={dataCommissions}
        columns={columns}
        options={options}
      />
      <ModalContainer
        xl={8}
        md={6}
        sm={8}
        open={isOpen}
        onClose={closeModal}
        title="Asignar Comisión"
      >
        <ModalAssignmentCommisions
          userId={sellerId.id}
          getAllComissions={getComissions}
          onCancel={closeModal}
        />
      </ModalContainer>
    </>
  )
}

ListCommissions.propTypes = {
  search: PropTypes.objectOf(PropTypes.string).isRequired,
}

export default ListCommissions
