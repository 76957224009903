import React from 'react'

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  tooltipContent: {
    marginTop: '15px',
    display: 'block',
    zIndex: 10,
    width: '100%',
    minHeight: '100px',
    backgroundColor: '#fff',
    boxShadow: '0px 3px 6px #0000001a',
    padding: '20px 15px 0 15px',
    borderRadius: '8px',
    '@media (min-width: 960px)': {
      width: '450px',
    },
    '@media (min-width: 1080px)': {
      display: 'none',
    },
  },
}))

const CardErrorImages = ({ children }) => {
  const classes = useStyles()

  return <div className={classes.tooltipContent}>{children}</div>
}

export default CardErrorImages
