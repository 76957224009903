import * as yup from 'yup'

// eslint-disable-next-line import/prefer-default-export
export const schema = yup.object().shape({
  height: yup
    .number()
    .positive('El valor debe ser un número positivo.')
    .typeError('El alto no es válido')
    .required('Campo requerido'),
  width: yup
    .number()
    .positive('El valor debe ser un número positivo.')
    .typeError('El ancho no es válido')
    .required('Campo requerido'),
  length: yup
    .number()
    .positive('El valor debe ser un número positivo.')
    .typeError('El largo no es válido')
    .required('Campo requerido'),
  weight: yup.number().typeError('El peso no es válido').required('Campo requerido'),
})
