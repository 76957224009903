import { makeStyles } from '@material-ui/core/styles'

export default makeStyles((theme) => ({
  palette: {
    primary: 'blue',
    secondary: 'indigo',
  },
  formGroup: {
    marginTop: 20,
    '& label': {
      fontWeight: 'bold',
    },
  },
  quantity: {
    marginLeft: '50%',
    transform: 'translate(-50%)',
  },
  btnDelete: {
    '&:hover': {
      background: theme.palette.error.main,
      fill: theme.palette.white.main,
    },
  },
  input: {
    marginTop: 10,
    color: 'black',
    fontWeight: '400 !important',
  },
  gridInfoClient: {
    display: 'grid',
    gridAutoFlow: 'column',
    gridGap: '1px',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gridTemplateRows: 'repeat(6, auto)',
  },
  table: {
    minWidth: 650,
  },
  buttonBack: {
    marginBottom: theme.spacing(1),
  },
  stileHeigh: {
    minHeight: 271,
  },
  tabContent: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gridGap: 10,
    width: '80%',
  },
  numPedido: {
    fontWeight: 400,
  },
  divContent: {
    paddingLeft: 30,
    '& .MuiTableCell-root': {
      position: 'relative',
      padding: '16px',
    },
  },
  formField: {
    width: '60%',
    marginTop: '9px',
  },
  messageError: {
    color: 'red',
  },
  formInput: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
  },

  afterReturn: {
    '&::after': {
      content: '"Devolución"',
      color: '#6064FF',
      textDecoration: 'none',
      backgroundColor: '#DBDCFF',
      borderRadius: '0 0 4px 4px',
      padding: '1px 4px',
      fontWeight: 'bold',
      position: 'absolute',
      top: 0,
      fontSize: '12px',
      right: 0,
    },
  },
}))
