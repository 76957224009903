const error500Styles = (theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.white.main,
    minHeight: '90vh',
    textAlign: 'center',
    padding: '0 20%',
  },
  btnBackgroundPrimary: {
    minWidth: 180,
    maxWidth: 246,
    fill: theme.palette.white.main,

    '&:hover': {
      background: theme.palette.primary.main,
    },
  },
  text: {
    margin: '0.2rem 0 1rem 0',
  },
})

export default error500Styles
