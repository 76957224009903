import React from 'react'

import { Grid, Typography, Box, Card } from '@material-ui/core'

import useStyles from '../ReportsAccountStyle'

import convertToCurrencyCop from 'utils/convertToCurrencyCop'

const timeArea = {
  textAlign: 'right',
}
const Payment = ({ dataOrders }) => {
  const { payment } = dataOrders
  const classes = useStyles()
  return (
    <>
      <Card className={classes.totalPrice}>
        <Grid
          container
          justify="space-between"
          alignItems="flex-start"
          className={classes.InitialBalancePadding}
        >
          <Typography align="left" className={classes.widthPayment}>
            Pago
          </Typography>
          <Typography align="right" className={classes.widthValuePyment}>
            {`$${convertToCurrencyCop(Number(payment || 0))} COP`}
          </Typography>
        </Grid>
      </Card>
    </>
  )
}
export default Payment
