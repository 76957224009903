import React, { useEffect, useState } from 'react'

import DateFnsUtils from '@date-io/date-fns'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  Button,
  FormGroup,
  Grid,
  InputLabel,
  TextField,
  Typography,
  Checkbox,
  InputAdornment,
} from '@material-ui/core'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import CurrencyTextField from '@unicef/material-ui-currency-textfield'
import Axios from 'axios'
import { es } from 'date-fns/locale'
import moment from 'moment'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'

import useStyles from '../products.styles'

import BackDrop from 'components/BackDrop'
import { CalendarIcon } from 'components/icons/personalizedIcons'
import useCustomDialogAlert from 'components/useCustomDialogAlert'
import { PROMOTIONS, PROMOTIONS_IVA } from 'constants/promotions'
import { FEATURES_FLAGS } from 'constants/urls'
import { useProduct } from 'contextApi/ProductContext'
import convertToCurrencyCop from 'utils/convertToCurrencyCop'
import { onlyNumber, onKeyPressJustNumbers } from 'utils/utils'

const schema = yup.object().shape({
  regularPrice: yup
    .string()
    .typeError('Debe ingresar un precio válido')
    .required('Campo requerido'),
  sale_price: yup
    .number()
    .typeError('Debe ingresar un precio en promoción')
    .required('Campo requerido'),
})

const PriceProduct = ({ isAdd, isCopy, isEdit }) => {
  const classes = useStyles()
  const { product, setProduct, handleNext, handleBack, handleCancel, isApprove } = useProduct()
  const showDialogAlert = useCustomDialogAlert()
  const [isActivePromotionPrice, setIsActivePromotionPrice] = useState('')
  const [promotionPercent, setPromotionPercent] = useState(0)
  // const [priceWhitePercent, setPriceWhitePercent] = useState(0)
  const [isLoading, setIsLoading] = useState()
  const [fechaError, setFechaError] = useState(false)
  const [backIva, setBackIva] = useState(0)
  const [pricePromotionUser, setPricePromotionUser] = useState('')
  const { register, handleSubmit, errors } = useForm({
    defaultValues: {
      regularPrice: product.regularPrice,
      date_on_sale_from: product.date_on_sale_from,
      date_on_sale_to: product.date_on_sale_to,
      promotionPercent: product.sale_price
        ? Math.round(
            ((product.regularPrice -
              (product.promotions.find((promotion) => promotion.name === PROMOTIONS)?.value || 0)) /
              product.regularPrice) *
              100
          )
        : 0,
    },
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: yupResolver(schema),
  })

  useEffect(() => {
    const value = product.promotions.find((dateFrom) => dateFrom.name === PROMOTIONS)?.value

    setIsActivePromotionPrice(value)
    if (value) {
      setPricePromotionUser(product.regularPrice - parseInt(value, 10))
      const operation = Math.round((value * 100) / product.regularPrice)
      setPromotionPercent(operation)
    }
  }, [product.regularPrice])

  useEffect(() => {
    const valueInitial = product.regularPrice
    const ivaPromo = product.promotions.find((promo) => promo.name === PROMOTIONS_IVA)?.value
    // const value = product.promotions.find((dateFrom) => dateFrom.name === PROMOTIONS)?.value
    if (ivaPromo) {
      const operation = Math.ceil(
        (100 * product.regularPrice) / (product.regularPrice - ivaPromo) - 100
      )
      const calculateIva = Math.ceil(valueInitial / (1 + operation / 100))
      product.iva_discount_value = calculateIva
      product.iva_discount_percentage = operation
      product.is_iva_discount = true
      setProduct({
        ...product,
        is_iva_discount: true,
        iva_discount_percentage: operation,
        iva_discount_value: calculateIva,
      })
    }
    // setPriceWhitePercent(product.iva_discount_value || product.regularPrice)
    if (!isEdit) {
      setBackIva(product.isActivePromotionPrice || 0)
    }
  }, [])

  // función para aplicar el porcentage
  const aplicateIvaDiscount = () => {
    const valueInitial = product.regularPrice
    product.promotions.map((dato) => {
      if (dato.name == PROMOTIONS_IVA) {
        const calculateIva = Math.ceil(valueInitial / (1 + product.iva_discount_percentage / 100))
        dato.value = product.regularPrice - calculateIva
        setProduct({
          ...product,
          iva_discount_value: calculateIva,
        })
      }
      return dato
    })
    setBackIva(product.iva_discount_percentage)
  }
  const preventDefault = (e) => {
    e.preventDefault()
  }
  const onBlurDate = () => {
    const promotion = product.promotions.find((dateFrom) => dateFrom.name === PROMOTIONS)
    if (moment(promotion?.dateOnSaleTo) > moment(promotion?.dateOnSaleFrom)) {
      setFechaError(false)
    } else {
      setFechaError(true)
    }
  }
  const getFeaturesFlags = () => {
    setIsLoading(true)
    Axios.get(`${FEATURES_FLAGS}`)
      .then((response) => {
        const obajectFeatureFlag = response.data.find((ivaDay) => ivaDay.id === 155)
        const dateFeatureFlag = obajectFeatureFlag.value.split(',')
        const promotion = {
          name: PROMOTIONS_IVA,
          value: 0,
          dateOnSaleFrom: moment(dateFeatureFlag[0]).format(),
          dateOnSaleTo: moment(dateFeatureFlag[1]).format(),
        }
        setProduct({
          ...product,
          is_iva_discount: true,
          promotions: [...product.promotions, promotion],
        })
        setIsLoading(false)
      })
      .catch(function (error) {
        setIsLoading(false)
        console.log(error)
      })
  }
  const onChangePercenIva = (event) => {
    if (!event.target.checked) {
      const valueInitial = product.regularPrice
      const copyPromotion = product.promotions.filter(
        (promotion) => promotion.name !== PROMOTIONS_IVA
      )

      setBackIva(0)
      setProduct({
        ...product,
        regularPrice: valueInitial,
        is_iva_discount: event.target.checked,
        iva_discount_percentage: event.target.checked,
        iva_discount_value: event.target.checked,
        promotions: copyPromotion,
      })
      return false
    }

    setProduct({
      ...product,
      is_iva_discount: event.target.checked,
    })
    getFeaturesFlags()
  }
  const onChangeActivePromotionPrice = (event) => {
    setIsActivePromotionPrice(event.target.checked)
    if (!event.target.checked) {
      setPromotionPercent(0)
      setPricePromotionUser('')
      const copyPromotion = product.promotions.filter((promotion) => promotion.name !== PROMOTIONS)
      const copyiva = product.promotions.filter((promotion) => promotion.name !== PROMOTIONS_IVA)

      setProduct({
        ...product,
        promotions: copyPromotion,
      })
      if (isActivePromotionPrice && !!copyiva) {
        const valueInitial = product.regularPrice

        product.promotions.map((dato) => {
          if (dato.name == PROMOTIONS_IVA) {
            const calculateIva = Math.ceil(
              valueInitial / (1 + product.iva_discount_percentage / 100)
            )
            dato.value = calculateIva
            setProduct({
              ...product,
              iva_discount_value: calculateIva,
              promotions: [{ ...copyiva, value: calculateIva }],
            })
          }
          return dato
        })
      }
    }
  }

  return (
    <form onSubmit={handleSubmit(handleNext)}>
      <Grid
        container
        direction="column"
        justify="space-between"
        alignItems="center"
        spacing={2}
        className={classes.inputTop}
      >
        <BackDrop open={isLoading} />
        <Grid className={classes.gridPriceInputs}>
          <Typography variant="subtitle1" className={classes.priceTitle}>
            Precio del producto
          </Typography>
          <FormGroup className={classes.formGroupPriceStep}>
            <InputLabel error={!!errors.regularPrice} className={classes.labelSize}>
              Precio ($)
            </InputLabel>

            <CurrencyTextField
              currencySymbol="$"
              textAlign="left"
              decimalPlaces={0}
              minimumValue={0}
              outputFormat="string"
              onChange={(event) => {
                if (onlyNumber(parseInt(event.target.value.split(',').join(''), 10))) {
                  setProduct({
                    ...product,
                    regularPrice: event.target.value.split(',').join('').replace('-', ''),
                    iva_discount_percentage: 0,
                  })
                  if (backIva !== 0) {
                    setBackIva(0)
                  }
                  return true
                }
                setProduct({
                  ...product,
                  regularPrice: event.target.value,
                  iva_discount_percentage: 0,
                })

                if (backIva !== 0) {
                  setBackIva(0)
                }
                return false
              }}
              value={product.regularPrice}
              variant="outlined"
              inputProps={{ ref: register }}
              disabled={isApprove}
              name="regularPrice"
              id="regularPrice"
              error={!!errors.regularPrice}
              helperText={errors?.regularPrice?.message}
              onKeyPress={onKeyPressJustNumbers}
            />
            {product.iva_discount_value !== 0 &&
              product.is_iva_discount &&
              product.iva_discount_value && (
                <Typography variant="body1" className={classes.priceTitle}>
                  El precio le va quedar en&nbsp;
                  <Typography variant="subtitle1" className={classes.pricePercent}>
                    {` $${convertToCurrencyCop(product.iva_discount_value)}`}
                  </Typography>
                </Typography>
              )}
          </FormGroup>

          <FormGroup className={classes.formGroupPriceStepCheckBox}>
            <Grid container direction="row" alignItems="flex-start" style={{ marginTop: '-1px' }}>
              <Checkbox
                checked={isActivePromotionPrice}
                onChange={onChangeActivePromotionPrice}
                id="checkTransport"
                name="checkedA"
                style={{ marginLeft: '5px' }}
              />
              <InputLabel className={classes.labelCheckBox}>Activar precio de promoción</InputLabel>
            </Grid>
          </FormGroup>
          <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
            <FormGroup className={classes.formGroupPriceStepCheckBox}>
              <Grid container direction="row" alignItems="flex-start" spacing={5}>
                <Checkbox
                  checked={product.is_iva_discount || false}
                  onChange={onChangePercenIva}
                  id="is_iva_discount"
                  name="is_iva_discount"
                  style={{ marginLeft: '10px' }}
                />
                <InputLabel className={classes.labelCheckBox}>Descuento IVA</InputLabel>
              </Grid>
            </FormGroup>
            {product.is_iva_discount && (
              <>
                <FormGroup className={classes.formGroupPriceStep}>
                  <InputLabel className={classes.labelSize}>% IVA</InputLabel>
                  <TextField
                    id="iva_discount_percentage"
                    name="iva_discount_percentage"
                    onChange={(event) => {
                      if (
                        onlyNumber(event.target.value) &&
                        event.target.value >= 0 &&
                        event.target.value <= 19
                      ) {
                        setProduct({
                          ...product,
                          iva_discount_percentage: parseInt(event.target.value, 10),
                        })
                        return true
                      }
                      return false
                    }}
                    value={parseInt(product.iva_discount_percentage, 10) || 0}
                    variant="outlined"
                    inputRef={register}
                    error={!!errors.iva_discount_percentage}
                    helperText={errors?.iva_discount_percentage?.message}
                    onKeyPress={onKeyPressJustNumbers}
                    onCut={preventDefault}
                    onCopy={preventDefault}
                    onPaste={preventDefault}
                  />
                </FormGroup>
                <Button
                  className={classes.btnBackgroundPrimary}
                  variant="contained"
                  color="secondary"
                  disabled={isLoading}
                  onClick={() => {
                    if (!product.iva_discount_percentage || product.regularPrice === '') {
                      showDialogAlert(
                        true,
                        '¡Advertencia!',
                        `Por favor ingrese un precio e iva.`,
                        'Cerrar'
                      )
                      setFechaError(true)
                    } else {
                      aplicateIvaDiscount()
                    }
                  }}
                >
                  {!isLoading ? 'Aplicar' : 'Aplicando descuento...'}
                </Button>
              </>
            )}
          </Grid>

          {isActivePromotionPrice && (
            <>
              <FormGroup className={classes.formGroupPriceStep} style={{ marginTop: '10px' }}>
                <InputLabel className={classes.labelSize}>Promoción (%)</InputLabel>
                <TextField
                  id="promotionPercent"
                  name="promotionPercent"
                  onChange={(event) => {
                    if (
                      onlyNumber(event.target.value) &&
                      event.target.value >= 0 &&
                      event.target.value <= 99
                    ) {
                      if (promotionPercent == 0) {
                        setPromotionPercent(event.target.value.replace('0', ''))
                      } else {
                        setPromotionPercent(event.target.value)
                      }
                      const promoIva = product.promotions.find((iv) => iv.name === PROMOTIONS_IVA)
                      const promo = product.promotions.find((iv) => iv.name === PROMOTIONS)
                      let copyPromociones = []

                      const objectPromotion = {
                        name: PROMOTIONS,
                        value: Math.round(
                          product.regularPrice -
                            (product.regularPrice -
                              product.regularPrice * (event.target.value / 100))
                        ).toString(),
                      }
                      setPricePromotionUser(
                        (
                          product.regularPrice -
                          product.regularPrice * (event.target.value / 100)
                        ).toString()
                      )
                      if (!promoIva && !promo) {
                        copyPromociones = [objectPromotion]
                      } else if (!promoIva) {
                        copyPromociones = [
                          { ...promo, name: objectPromotion.name, value: objectPromotion.value },
                        ]
                      } else if (promo || promoIva) {
                        copyPromociones = [{ ...objectPromotion }, promoIva]
                      }
                      setProduct({
                        ...product,
                        promotions: [...copyPromociones],
                      })

                      return true
                    }
                    return false
                  }}
                  value={promotionPercent !== 0 ? promotionPercent : '0'}
                  variant="outlined"
                  onKeyPress={onKeyPressJustNumbers}
                  disabled={isApprove}
                />
              </FormGroup>

              <FormGroup className={classes.formGroupPriceStep} style={{ marginTop: '10px' }}>
                <InputLabel
                  error={!(pricePromotionUser !== '') ? !!errors.sale_price : null}
                  className={classes.labelSize}
                >
                  Precio en promoción ($)
                </InputLabel>

                <CurrencyTextField
                  currencySymbol="$"
                  textAlign="left"
                  decimalPlaces={0}
                  outputFormat="string"
                  onChange={(event) => {
                    if (onlyNumber(parseInt(event.target.value.split(',').join(''), 10))) {
                      if (
                        Number(event.target.value.split(',').join('')) <=
                        Number(product.regularPrice)
                      ) {
                        setPromotionPercent(
                          Math.round(
                            ((product.regularPrice -
                              parseInt(event.target.value.split(',').join(''), 10)) /
                              product.regularPrice) *
                              100
                          )
                        )
                      } else {
                        showDialogAlert(
                          true,
                          '¡Advertencia!',
                          'El precio de la promoción no debe superar el precio del producto',
                          'Cerrar'
                        )
                        setPromotionPercent(0)
                        return false
                      }
                      const promo = product.promotions.find(
                        (promotion) => promotion.name === PROMOTIONS
                      )
                      const promoIva = product.promotions.find(
                        (promotionIva) => promotionIva.name === PROMOTIONS_IVA
                      )
                      const objectPromocion = {
                        ...promo,
                        name: PROMOTIONS,
                        value: product.regularPrice - event.target.value.split(',').join(''),
                      }
                      setPricePromotionUser(event.target.value.split(',').join(''))
                      let copyPromociones = []
                      if (!promoIva && !promo) {
                        copyPromociones = [objectPromocion]
                      } else if (!promoIva) {
                        copyPromociones = [
                          { ...promo, name: objectPromocion.name, value: objectPromocion.value },
                        ]
                      } else if (promo || promoIva) {
                        copyPromociones = [{ ...objectPromocion }, promoIva]
                      }

                      setProduct({
                        ...product,
                        promotions: [...copyPromociones],
                      })
                      return true
                    }
                    return false
                  }}
                  value={pricePromotionUser !== '' ? pricePromotionUser : ''}
                  variant="outlined"
                  inputProps={{ ref: register }}
                  name="sale_price"
                  error={!(pricePromotionUser !== '') ? !!errors.sale_price : null}
                  helperText={!product.promotions ? errors?.sale_price?.message : null}
                  onKeyPress={onKeyPressJustNumbers}
                  disabled={isApprove}
                />
              </FormGroup>

              <FormGroup className={classes.formGroupPriceStep}>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
                  <InputLabel error={fechaError} className={classes.labelSize}>
                    Fecha inicial de promoción
                  </InputLabel>
                  <DatePicker
                    id="dateOnSaleFrom"
                    value={
                      product.promotions.find((dateFrom) => dateFrom.name === PROMOTIONS)
                        ?.dateOnSaleFrom
                        ? new Date(
                            moment(
                              product.promotions.find((dateFrom) => dateFrom.name === PROMOTIONS)
                                ?.dateOnSaleFrom
                            ).add(5, 'h')
                          )
                        : null
                    }
                    error={fechaError}
                    onBlur={onBlurDate}
                    onChange={(value) => {
                      const date = new Date(value)
                      const month =
                        date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
                      const promoIva = product.promotions.find((iv) => iv.name === PROMOTIONS_IVA)
                      const promo = product.promotions.find((iv) => iv.name === PROMOTIONS)
                      let copyPromociones = []
                      const objectPromotion = {
                        name: PROMOTIONS,
                        dateOnSaleFrom: moment(
                          `${date.getFullYear()}-${month}-${
                            date.getDate() < 10 ? `0${date.getDate()}` : `${date.getDate()}`
                          }T05:00:01`
                        )
                          .format()
                          .replace('-05:00', ''),
                      }
                      if (!promoIva && !promo) {
                        copyPromociones = [objectPromotion]
                      } else if (!promoIva) {
                        copyPromociones = [
                          {
                            ...promo,
                            name: objectPromotion.name,
                            dateOnSaleFrom: objectPromotion.dateOnSaleFrom,
                          },
                        ]
                      } else if (promo || promoIva) {
                        copyPromociones = [{ ...promo, ...objectPromotion }, promoIva]
                      }
                      setProduct({
                        ...product,
                        promotions: [...copyPromociones],
                      })
                    }}
                    format="yyyy-MM-dd"
                    inputVariant="outlined"
                    disabled={isApprove}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <CalendarIcon />
                        </InputAdornment>
                      ),
                    }}
                    disablePast={false}
                    KeyboardButtonProps={{ style: { padding: 0 } }}
                  />
                </MuiPickersUtilsProvider>
              </FormGroup>

              <FormGroup className={classes.formGroupPriceStep}>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
                  <InputLabel error={fechaError} className={classes.labelSize}>
                    Fecha final de promoción
                  </InputLabel>

                  <DatePicker
                    id="dateOnSaleTo"
                    value={
                      product.promotions.find((dateFrom) => dateFrom.name === PROMOTIONS)
                        ?.dateOnSaleTo
                        ? new Date(
                            product.promotions.find(
                              (dateFrom) => dateFrom.name === PROMOTIONS
                            )?.dateOnSaleTo
                          )
                        : null
                    }
                    error={fechaError}
                    onBlur={onBlurDate}
                    onChange={(value) => {
                      const date = new Date(value)

                      const month =
                        date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
                      const promoIva = product.promotions.find((iv) => iv.name === PROMOTIONS_IVA)
                      const promo = product.promotions.find((iv) => iv.name === PROMOTIONS)
                      let copyPromociones = []
                      const objectPromotion = {
                        name: PROMOTIONS,
                        dateOnSaleTo: moment(
                          `${date.getFullYear()}-${month}-${
                            date.getDate() < 10 ? `0${date.getDate()}` : `${date.getDate()}`
                          }T23:59:59`
                        )
                          .format()
                          .replace('-05:00', ''),
                      }
                      if (!promoIva && !promo) {
                        copyPromociones = [objectPromotion]
                      } else if (!promoIva) {
                        copyPromociones = [
                          {
                            ...promo,
                            name: objectPromotion.name,
                            dateOnSaleTo: objectPromotion.dateOnSaleTo,
                          },
                        ]
                      } else if (promo || promoIva) {
                        copyPromociones = [{ ...promo, ...objectPromotion }, promoIva]
                      }
                      setProduct({
                        ...product,
                        promotions: [...copyPromociones],
                      })
                    }}
                    format="yyyy-MM-dd"
                    inputVariant="outlined"
                    disabled={isApprove}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <CalendarIcon />
                        </InputAdornment>
                      ),
                    }}
                    disablePast={false}
                    KeyboardButtonProps={{ style: { padding: 0 } }}
                  />
                </MuiPickersUtilsProvider>
              </FormGroup>
            </>
          )}
        </Grid>
        <Grid container alignItems="center" justifyContent="center" className={classes.gridButtons}>
          <Button
            className={classes.btnBackgroundPrimary}
            variant="contained"
            color="secondary"
            onClick={handleBack}
          >
            Anterior
          </Button>
          <Button className={classes.btnBackgroundError} onClick={handleCancel}>
            {!isApprove ? 'Cancelar' : 'No hacer nada'}
          </Button>
          <Button
            className={classes.btnBackgroundPrimary}
            variant="contained"
            type="submit"
            color="secondary"
            disabled={isLoading}
            onClick={() => {
              if (
                isActivePromotionPrice &&
                product.promotions.find((promotion) => promotion.name === PROMOTIONS)?.value === ''
              ) {
                showDialogAlert(
                  true,
                  '¡Advertencia!',
                  `Por favor ingrese un precio en promoción.`,
                  'Cerrar'
                )
                setFechaError(true)
              } else {
                setFechaError(false)
                handleNext()
              }
            }}
          >
            Continuar
          </Button>
        </Grid>
      </Grid>
    </form>
  )
}

export default PriceProduct
