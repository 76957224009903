/* eslint-disable no-useless-escape */
/* eslint-disable no-shadow */
/* eslint-disable no-plusplus */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-param-reassign */
import React, { useRef, useEffect, useState, useContext } from 'react'

import { Checkbox, FormControlLabel, Grid, Tooltip, Typography } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import Axios from 'axios'
import { useForm } from 'react-hook-form'

import useStyles from './warehousesStyle'

import Button from 'components/CustomButtons/Button'
import GooglePlaces from 'components/GooglePlaces/GooglePlaces'
import InputField from 'components/GooglePlaces/InputField'
import useCustomDialogAlert from 'components/useCustomDialogAlert'
import { API_LOCATIONS, API_STORE_INFO } from 'constants/urls'
import useAuthContext from 'contextApi/AuthContext'

function NewWareHouseForm({
  setIsLoading,
  selectedWarehouse,
  sellerId,
  getModel,
  getWarehouses,
  options,
  setOptions,
}) {
  const classes = useStyles()
  const showDialogAlert = useCustomDialogAlert()
  const {
    register,
    handleSubmit,
    setValue,
    errors,
    reset,
    getValues,
    trigger,
    formState: { isValid },
  } = useForm({
    mode: 'onChange',
  })
  const { setNeedAddWareHouse, userLoggedin, setLoggedin } = useContext(useAuthContext)
  const formRef = useRef(null)
  const [selectedDepartment, setSelectedDepartment] = useState(null)
  const [selectedRetCity, setSelectedRetCity] = useState(null)
  const [selectedRetDepartment, setSelectedRetDepartment] = useState(null)
  const [selectedCity, setSelectedCity] = useState(null)
  const [checked, setChecked] = useState(false)

  const handleCheckChange = (e) => {
    setChecked(!checked)
    const returnAddress = getValues('warehouseAddress')
    setOptions({
      ...options,
      returnCity: options.principalCity,
    })
    setValue('returnAddress', !checked ? returnAddress : '')
    setSelectedRetDepartment(!checked ? selectedDepartment : null)
    setSelectedRetCity(!checked ? selectedCity : null)
    trigger('returnAddress')
  }

  const changeAddressData = (address, lat, lng) => {
    setValue('warehouseAddress', address)
    setValue('latitude', lat)
    setValue('longitude', lng)
    trigger('latitude')
  }

  const changeReturnAddressData = (address) => {
    setValue('returnAddress', address)
  }

  const getCities = async (departmentId) => {
    const response = await Axios.get(`${API_LOCATIONS}/Cities?departmentId=${departmentId}`)
    return response.data
  }

  const handleClear = () => {
    reset()
    setSelectedCity(null)
    setSelectedRetCity(null)
    setSelectedDepartment(null)
    setSelectedRetDepartment(null)
    setChecked(false)
  }
  const onSubmit = async (data) => {
    setIsLoading(true)
    data.cityId = selectedCity.id
    data.departmentId = selectedDepartment.id
    data.departmentReturnId = selectedRetDepartment.id
    data.cityReturnId = selectedRetCity.id
    data.countryId = 47
    let body
    let method
    let url = `${API_STORE_INFO}/Warehouses`
    if (selectedWarehouse) {
      body = {
        id: selectedWarehouse.id,
        sellerId,
        ...data,
      }
      url = `${API_STORE_INFO}/Warehouses/${selectedWarehouse.id}`
      method = 'PUT'
    } else {
      body = {
        ...data,
        sellerId,
      }
      method = 'POST'
    }

    const request = {
      method: method,
      data: body,
    }
    await Axios({
      url,
      ...request,
    })
      .then((res) => {
        if (res.status === 200) {
          setNeedAddWareHouse(false)
          const dataUser = userLoggedin
          if (!dataUser.isHadWarehouse) {
            dataUser.isHadWarehouse = true
          }
          setLoggedin(dataUser)
          getWarehouses()
          getModel()
          showDialogAlert(
            true,
            '¡Cambio exitoso!',
            `Se ha guardado correctamente la bodega`,
            'Cerrar',
            '',
            true
          )
          setIsLoading(false)
          handleClear()
          document.querySelector('#main').scrollTo(0, 0)
        }
      })
      .catch((err) => {
        console.log(err)
        setIsLoading(false)
      })
  }

  const editData = async (oldData) => {
    setIsLoading(true)
    const keys = Object.keys(oldData)
    for (let i = 0; i < keys.length; i++) {
      const value = oldData[keys[i]]
      setValue(keys[i], value)
    }
    const department = options.principalDepartment.find(
      (department) => department.id === oldData.departmentId
    )
    setSelectedDepartment(department)
    const retDepartment = options.returnDepartment.find(
      (department) => department.id === oldData.departmentReturnId
    )
    setSelectedRetDepartment(retDepartment)
    const cities = await getCities(oldData.departmentId)
    const city = cities.find((city) => city.id === oldData.cityId)
    setSelectedCity(city)
    const retCities = await getCities(oldData.departmentReturnId)
    const retCity = retCities.find((city) => city.id === oldData.cityReturnId)
    setSelectedRetCity(retCity)
    setIsLoading(false)
    trigger()
  }

  useEffect(() => {
    if (selectedWarehouse) {
      editData(selectedWarehouse)
    } else {
      handleClear()
    }
  }, [selectedWarehouse])

  useEffect(async () => {
    if (!selectedDepartment) return
    const cities = await getCities(selectedDepartment.id)
    setOptions({
      ...options,
      principalCity: cities,
    })
  }, [selectedDepartment])

  useEffect(async () => {
    if (!selectedRetDepartment) return
    const cities = await getCities(selectedRetDepartment.id)
    setOptions({
      ...options,
      returnCity: cities,
    })
  }, [selectedRetDepartment])

  return (
    <div style={{ margin: '3rem' }} ref={formRef}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Typography
          variant="subtitle1"
          gutterBottom
          className={classes.infoWarehouses}
          error={errors.title && errors.title.message}
        >
          Información principal de la bodega
        </Typography>
        <Grid container spacing={3} style={{ marginLeft: '1rem' }}>
          <Grid item xs={6}>
            <InputField
              label="Titulo de la bodega"
              name="title"
              error={errors.title && errors.title.message}
              ref={register({
                required: 'El titulo es requerido',
                maxLength: {
                  value: 50,
                  message: 'El titulo no puede tener mas de 50 caracteres',
                },
                minLength: {
                  value: 2,
                  message: 'El titulo debe tener al menos 2 caracteres',
                },
              })}
            />
            <InputField
              label="Número Teléfono"
              type="number"
              error={errors.phoneNumber && errors.phoneNumber.message}
              name="phoneNumber"
              ref={register({
                required: 'El número de teléfono es requerido',
                maxLength: {
                  value: 13,
                  message: 'El número de teléfono no puede tener mas de 13 caracteres',
                },
                minLength: {
                  value: 7,
                  message: 'El número de teléfono debe tener al menos 7 caracteres',
                },
              })}
            />
            <InputField
              label="Nombre Contacto"
              error={errors.contactName && errors.contactName.message}
              name="contactName"
              ref={register({
                required: 'El nombre del contacto es requerido',
                maxLength: {
                  value: 50,
                  message: 'El nombre del contacto no puede tener mas de 50 caracteres',
                },
                minLength: {
                  value: 2,
                  message: 'El nombre del contacto debe tener al menos 2 caracteres',
                },
              })}
            />
            <GooglePlaces
              id="wareHouseAddress"
              label="Dirección bodega"
              placeholder="Digite la dirección de la bodega"
              onBlur={(e) => {
                trigger('latitude')
              }}
              handleSelect={changeAddressData}
              onChange={(e) => {
                changeAddressData(e.target.value, 0, 0)
              }}
              error={errors.warehouseAddress && errors.warehouseAddress.message}
              helperText={errors.warehouseAddress ? '' : 'Selecciona una dirección'}
              name="warehouseAddress"
              ref={register({
                required: 'La dirección es requerida',
                pattern: {
                  value: /^[a-zA-Z0-9\s\.,#-áéíóúÁÉÍÓÚ]{1,200}$/,
                  message: 'La dirección no es válida',
                },
              })}
            />
            <input type="hidden" name="latitude" ref={register} />
            <input type="hidden" name="longitude" ref={register} />
          </Grid>
          <Grid item xs={6}>
            <Autocomplete
              id="departmentId"
              options={options.principalDepartment}
              getOptionLabel={(option) => option.name}
              value={selectedDepartment}
              onChange={(event, newValue) => {
                setSelectedDepartment(newValue)
              }}
              renderInput={(params) => (
                <InputField
                  {...params}
                  label="Departamento"
                  name="departmentId"
                  error={errors.departmentId && errors.departmentId.message}
                  ref={register({ required: 'Departamento requerido' })}
                />
              )}
            />
            <Autocomplete
              id="cityId"
              options={options.principalCity}
              getOptionLabel={(option) => option.name}
              value={selectedCity}
              noOptionsText="Seleccione un departamento"
              onChange={(event, newValue) => {
                setSelectedCity(newValue)
              }}
              renderInput={(params) => (
                <InputField
                  {...params}
                  label="Ciudad"
                  name="cityId"
                  error={errors.cityId && errors.cityId.message}
                  ref={register({ required: 'Ciudad requerido' })}
                />
              )}
            />
            <InputField
              label="País"
              disabled
              value={options.principalCountry[46].name}
              error={errors.countryId && errors.countryId.message}
              name="countryId"
              ref={register({
                required: 'El país es requerido',
              })}
            />
            <InputField
              label="Código Postal"
              type="number"
              error={errors.postalCode && errors.postalCode.message}
              name="postalCode"
              ref={register({
                validate: {
                  // just numbers
                  isNumeric: (value) =>
                    /^\d+$/.test(value) || 'El código postal debe contener números',
                },
              })}
            />
          </Grid>
        </Grid>
        <FormControlLabel
          style={{ marginLeft: '1rem' }}
          label="Utilizar misma información para dirección de devolución"
          control={<Checkbox checked={checked} onChange={handleCheckChange} color="primary" />}
        />
        <Typography variant="subtitle1" gutterBottom className={classes.infoWarehouses}>
          Información para devoluciones
        </Typography>
        <Grid container spacing={3} style={{ marginLeft: '1rem' }}>
          <Grid item xs={6}>
            <GooglePlaces
              id="returnAddress"
              label="Dirección de devoluciones"
              placeholder="Digite la dirección de devoluciones"
              handleSelect={changeReturnAddressData}
              error={errors.returnAddress && 'Seleccione una direccion valida'}
              name="returnAddress"
              ref={register({
                required: 'La dirección es requerida',
                pattern: {
                  value: /^[a-zA-Z0-9\s\.,#-áéíóúÁÉÍÓÚ]{1,200}$/,
                  message: 'La dirección no es válida',
                },
              })}
            />
          </Grid>
          <Grid item xs={6}>
            <Autocomplete
              id="departmentReturnId"
              options={options.principalDepartment}
              getOptionLabel={(option) => option.name}
              value={selectedRetDepartment}
              onChange={(event, newValue) => {
                setSelectedRetDepartment(newValue)
              }}
              renderInput={(params) => (
                <InputField
                  {...params}
                  label="Departamento de devoluciones"
                  name="departmentReturnId"
                  error={errors.departmentReturnId && errors.departmentReturnId.message}
                  ref={register({
                    required: 'El Departamento es requerido',
                  })}
                />
              )}
            />
            <Autocomplete
              id="cityReturnId"
              options={options.returnCity}
              getOptionLabel={(option) => option.name}
              value={selectedRetCity}
              noOptionsText="Seleccione un departamento"
              onChange={(event, newValue) => {
                setSelectedRetCity(newValue)
              }}
              renderInput={(params) => (
                <InputField
                  {...params}
                  label="Ciudad de devoluciones"
                  name="cityReturnId"
                  error={errors.cityReturnId && errors.cityReturnId.message}
                  ref={register({
                    required: 'La ciudad es requerida',
                  })}
                />
              )}
            />
          </Grid>
        </Grid>
        <Button className={classes.btnBackgroundPrimary} type="submit">
          {selectedWarehouse ? 'Editar ' : 'Crear '}
          bodega
        </Button>
      </form>
    </div>
  )
}

export default NewWareHouseForm
