/* eslint-disable react/prop-types */
import React, { useEffect } from 'react'

// core components
import { Grid, Button, Avatar, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import defaultImage from 'assets/img/image_placeholder.jpg'

const useStyles = makeStyles((theme) => ({
  avatar: {
    width: 79,
    height: 79,
    margin: '0 24px',
  },
  inputFile: {
    display: 'none',
  },
  btnAdd: {
    width: '180px',
    height: '40px',
    borderRadius: '50px',
    border: 'none',
    backgroundColor: '#5e44ff',
    color: 'white',
    fontWeight: '500',
    '&:hover': {
      fontWeight: 'bolder',
      backgroundColor: '#ef0bef',
      boxShadow: '-7px 7px 0px #000000',
      border: '2px solid #000000',
      borderRadius: '50px',
      opacity: '1',
    },
  },
  container: {
    margin: '12px 0',
  },
  btnRemove: {
    width: '180px',
    height: '40px',
    borderRadius: '50px',
    border: '1px solid #6064ff',
    color: '#6064ff',
    fontweight: '500',
    backgroundColor: '#ffffff',
    '&:hover': {
      color: '#FF40FC',
      border: '1px solid #FF40FC',
      backgroundColor: '#ffffff',
    },
  },
}))

export default function ImageUpload(props) {
  const classes = useStyles()

  const { loadImage, addButtonProps, changeButtonProps, setFilesComponent, removeButtonProps } =
    props
  const [file, setFile] = React.useState(null)
  const [imagePreviewUrl, setImagePreviewUrl] = React.useState(defaultImage)
  const fileInput = React.createRef()

  useEffect(() => {
    setImagePreviewUrl(loadImage || defaultImage)
    setFile(null)
  }, [loadImage])

  const handleImageChange = (e) => {
    e.preventDefault()
    const reader = new FileReader()
    const fileImage = e.target.files[0]
    // hecho para guardar la imagen del logo del dropshipper
    setFilesComponent(fileImage)
    reader.onloadend = () => {
      setFile(fileImage)
      setImagePreviewUrl(reader.result)
    }
    reader.readAsDataURL(fileImage)
  }

  const handleRemove = () => {
    setFile(null)
    setImagePreviewUrl(loadImage)
    fileInput.current.value = null
  }

  const handleClick = () => {
    handleRemove()
    fileInput.current.click()
  }

  return (
    <Grid container className={classes.container}>
      <input
        accept="image/jpeg,image/png"
        type="file"
        className={classes.inputFile}
        onChange={handleImageChange}
        ref={fileInput}
      />
      <Avatar alt="Imágen tienda" src={imagePreviewUrl} className={classes.avatar} />

      <Grid>
        <Typography>Logo</Typography>
        <Grid container wrap="nowrap">
          {file === null ? (
            <Button
              variant="contained"
              color="secondary"
              {...addButtonProps}
              onClick={() => handleClick()}
              className={classes.btnAdd}
            >
              Agregar
            </Button>
          ) : (
            <>
              <Button
                variant="contained"
                color="secondary"
                {...changeButtonProps}
                className={classes.btnAdd}
                onClick={() => handleClick()}
              >
                Cambiar
              </Button>
              <Button
                variant="contained"
                color="error"
                {...removeButtonProps}
                style={{ marginLeft: '2%' }}
                className={classes.btnRemove}
                onClick={() => handleRemove()}
              >
                <i className="fas fa-times" style={{ margin: '3px 3px 0 0' }} />
                remover
              </Button>
            </>
          )}
        </Grid>
      </Grid>
    </Grid>
  )
}
