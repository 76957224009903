import { makeStyles } from '@material-ui/core/styles'

import styles from 'assets/jss/material-dashboard-pro-react/views/dashboardStyle'

export default makeStyles((theme) => ({
  ...styles,
  bodyCard: {
    padding: '30px 0px 0px 0px',
  },
  content: {
    padding: 20,
    width: '80%',
    margin: '0 auto',
  },
  backdrop: {
    zIndex: '999999',
    color: '#fff',
  },
  title: {
    fontSize: '25px',
    fontWeight: 'bold',
  },
  titleCard: {
    marginTop: '0',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
  },
  headerTextCard: {
    width: '60%',
  },
  buttons: {
    marginBottom: '2%',
    // marginTop: '2%',
    gap: '12px',
  },
  buttonsProduct: {
    marginBottom: '0.5%',
    marginTop: '2%',
    gap: '12px',
  },
  ordenItems: {
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  buttonConsolidate: {
    marginRight: '15px',
  },
  spanError: {
    color: 'red',
    fontColor: 'red',
  },
  backdropSnackbar: {
    background: 'radial-gradient(circle, rgba(222,222,222,1) 0%, rgba(201,200,200,1) 100%)',
    color: '#000000',
    padding: '10px',
    borderRadius: 7,
    border: 3,
    borderColor: '#FFFFFF',
  },
  alingCenter: {
    textAlign: 'center',
  },
  buttonHoverRed: {
    minWidth: 120,
    height: '56px',
    borderRadius: '50px',
    border: '1px solid #6064ff',
    color: '#6064ff',
    fontWeight: '500',
    backgroundColor: '#ffffff',
    '&:hover': {
      color: '#FF40FC',
      border: '1px solid #FF40FC',
      backgroundColor: '#ffffff',
    },
  },
  labelSize: {
    marginBottom: 8,
  },
  buttonshoverGreen: {
    minWidth: 120,
    borderRadius: '50px',
    border: 'none',
    backgroundColor: '#5e44ff',
    color: 'white',
    fontWeight: '500',
    '&:hover': {
      fontWeight: 'bolder',
      backgroundColor: '#ef0bef',
      boxShadow: '-7px 7px 0px #000000',
      border: '2px solid #000000',
      borderRadius: '50px',
      opacity: '1',
    },
  },
  modalProducts: {
    height: 'auto',
  },
  modalDeliveryDriver: {
    width: '50%',
    margin: '0 auto',
  },

  fullWidth: {
    width: '100%',
  },
  formInputInfo: {
    width: '45%',
    padding: 0,
    marginBottom: 10,
  },
  formInputDetails: {
    padding: 0,
    marginBottom: 10,
  },
  containerDataConsolidate: {
    marginTop: '2%',
    paddingLeft: '5%',
    paddingRight: '5%',
  },
  typographyDeliveryDriver: {
    marginBottom: 8,
  }
}))
