/* eslint-disable react/prop-types */
import React, { useState, useEffect, useContext } from 'react'

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Button,
  Grid,
  FormControl,
  RadioGroup,
  Radio,
  FormControlLabel,
  TextareaAutosize,
  FormHelperText,
} from '@material-ui/core'
import axios from 'axios'
import PropTypes from 'prop-types'

import useStyles from './ModalConfirmActionOrder.styles'

import useCustomDialogAlert from 'components/useCustomDialogAlert'
import { API_ORDERS } from 'constants/urls'
import useAuthContext from 'contextApi/AuthContext'
import { translateTypeToNameFilter } from 'utils/filterState'

function ModalConfirmActionOrder({
  open,
  infoOrders,
  handleClose,
  setIsLoadingBackDrop,
  setOrdersReadyToSent,
  setIsOpenModalReadyToSend,
  setValueSelectAccion,
  filterBy,
  getModels,
  filterData,
}) {
  const classes = useStyles()

  const showDialogAlert = useCustomDialogAlert()
  const { currentFilterOrders } = useContext(useAuthContext)
  const [stateName, setStateName] = useState('')
  const [cancelationOptionsList, setCancelationOptionsList] = useState([])
  const [cancelOptionSelected, setcancelOptionSelected] = useState(null)
  const [reason, setReason] = useState(null)

  const getCancelationOptionsLists = async () => {
    let newCancelationOptionsListSeller = []
    await axios
      .get(`${API_ORDERS}/GetReasonOrderCancellation`)
      .then((response) => {
        if (response.status === 200) {
          newCancelationOptionsListSeller = [
            response.data.data.find((list) => list.name === 'Sin Stock'),
          ]
          setCancelationOptionsList(newCancelationOptionsListSeller)
        }
      })
      .catch(() => {
        setIsLoadingBackDrop(false)
      })
  }

  useEffect(() => {
    if (infoOrders?.typeId === 5) {
      getCancelationOptionsLists()
    }
    setStateName(translateTypeToNameFilter(infoOrders?.typeId))
  }, [infoOrders?.typeId])

  const handleSelectCancelOption = (event) => {
    setcancelOptionSelected(Number(event.target.value))
  }
  const closeModal = () => {
    handleClose({
      open: false,
      typeId: null,
      ordersId: [],
      actualTypeId: null,
    })
    setcancelOptionSelected(null)
    setReason(null)
  }
  const handleConfirm = async () => {
    if (reason && reason?.length > 250) {
      showDialogAlert(
        true,
        'Razon mayor a 250 caracteres',
        `El campo debe ser menor a 250 caracteres.`,
        'Cerrar',
        ''
      )
    } else {
      setIsLoadingBackDrop(true)

      // Validate if the action is 'Ready To Send' for show the modal
      if (infoOrders?.typeId === 2) {
        setOrdersReadyToSent(infoOrders?.ordersId)
        setIsOpenModalReadyToSend(true)
        return setIsLoadingBackDrop(false)
      }
      const modelData = {
        filterType: infoOrders?.actualTypeId,
        type: infoOrders?.typeId,
        orderIDList: infoOrders?.ordersId,
      }
      if (infoOrders?.typeId === 5) {
        modelData.reasonOrderCancellation = cancelOptionSelected
        modelData.reason = reason || ''
      }
      await axios
        .post(`${API_ORDERS}/UpdateOrderList`, modelData)
        .then((response) => {
          if (response.status === 200) {
            showDialogAlert(
              true,
              '¡Cambio exitoso!',
              `Se ha cambiado ${infoOrders?.ordersId.length} pedido(s) al estado ${stateName}.`,
              'Cerrar',
              '',
              true
            )
            filterBy([], false, filterData)
            setValueSelectAccion('')
          }
        })
        .then(() => {
          getModels(filterData)
        })
        .catch(() => {
          setIsLoadingBackDrop(false)
        })
      return closeModal()
    }
  }

  return (
    <Dialog
      open={open}
      disableBackdropClick
      disableEscapeKeyDown
      onClose={closeModal}
      className={classes.ordenItems}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <Grid container direction="row" justify="center" alignItems="center">
          <Grid item>
            <Typography variant="h5" className={classes.marginTitleContent}>
              ¡Advertencia!
            </Typography>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent className={classes.contentGuide}>
        <Typography variant="body1">{`¿Está seguro que desea cambiar el estado de ${infoOrders?.ordersId?.length} pedido(s) al estado ${stateName}?`}</Typography>
        <Grid className={classes.divCancelOptions}>
          {infoOrders?.typeId === 5 && cancelationOptionsList?.length > 0 ? (
            <>
              <Typography align="left" variant="subtitle2">
                Motivo de cancelación:
              </Typography>
              {cancelationOptionsList?.map((item, index) => {
                return (
                  <>
                    {currentFilterOrders === 'Enviado' || currentFilterOrders === 'Completado' ? (
                      (item.id === 37 || item.id === 54) && (
                        <>
                          <FormControl component="fieldset">
                            <RadioGroup
                              aria-label="optionCanel"
                              name="optionCancel"
                              value={cancelOptionSelected}
                              onChange={handleSelectCancelOption}
                            >
                              <FormControlLabel
                                id={index}
                                value={item.id}
                                control={<Radio />}
                                label={item.name}
                              />
                            </RadioGroup>
                          </FormControl>
                        </>
                      )
                    ) : (
                      <>
                        <FormControl component="fieldset">
                          <RadioGroup
                            aria-label="optionCanel"
                            name="optionCancel"
                            value={cancelOptionSelected}
                            onChange={handleSelectCancelOption}
                          >
                            <FormControlLabel
                              id={index}
                              value={item.id}
                              control={<Radio />}
                              label={item.name}
                            />
                          </RadioGroup>
                        </FormControl>
                      </>
                    )}
                  </>
                )
              })}
            </>
          ) : null}
        </Grid>

        {infoOrders?.typeId === 5 && cancelOptionSelected === 37 ? (
          <Grid container direction="row" justifyContent="center" alignItems="center">
            <Grid item md={12}>
              <TextareaAutosize
                inputProps={{
                  maxLength: 250,
                }}
                aria-label="minimum height"
                minRows={3}
                className={classes.reason}
                placeholder="Ingrese el motivo de cancelación"
                onChange={(e) => setReason(e.target.value)}
              />
              <FormHelperText>Maximo 250 carateres</FormHelperText>
            </Grid>
          </Grid>
        ) : null}
      </DialogContent>

      <DialogActions className={classes.ordenItems}>
        <Button
          onClick={() => closeModal()}
          variant="contained"
          color="secondary"
          className={classes.buttonHoverRed}
        >
          Cancelar
        </Button>
        <Button
          onClick={() => {
            handleConfirm()
          }}
          variant="contained"
          color="secondary"
          className={classes.buttonshoverGreen}
        >
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

ModalConfirmActionOrder.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
}

export default ModalConfirmActionOrder
