/* eslint-disable react/prop-types */
import React, { useContext, useEffect, useState } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  FormGroup,
  InputLabel,
  TextField,
  FormControlLabel,
  Checkbox,
  Typography,
  InputAdornment,
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import Axios from 'axios'
import { useForm } from 'react-hook-form'

import useStyles from './products.styles'
import { schema } from './schema'

import useCustomDialogAlert from 'components/useCustomDialogAlert'
import { API_PRODUCTS } from 'constants/urls'
import useAuthContext from 'contextApi/AuthContext'
import { isAlphanumeric, justNumbersDecimal } from 'utils/utils'

const PanelProducts = ({ defaultProps, valueWarehouse, setValueWarehouse, onClose }) => {
  const classes = useStyles()
  const { userLoggedin } = useContext(useAuthContext)
  const [isLoading, setIsLoading] = useState(false)
  const [dataAdditional, setDataAdditional] = useState({
    sku: '',
    height: '',
    width: '',
    length: '',
    warehouse_id: '',
  })
  const { register, handleSubmit, errors, control, setError, clearError } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',

    resolver: yupResolver(schema),
  })
  const [dialogAlert, setDialogAlert] = useState({
    open: false,
    title: '',
    message: '',
    actionConfirm: null,
    textButtonConfirm: null,
    textButtonCancel: null,
  })

  const showDialogAlert = useCustomDialogAlert()

  const checkDimensions = (dimensions, weight) => {
    if (!dimensions || !weight) {
      return false
    }
    const { height, length, width } = dimensions

    if (!height || !length || !width || !weight) {
      return false
    }

    const expreg = /^[0-9]{1,}$|^[0-9]+([.][0-9]+)?$/
    if (expreg.test(height) && expreg.test(width) && expreg.test(length) && expreg.test(weight)) {
      return true
    }
    return false
  }
  const handleChangeAutocomplete = (event, newValue) => {
    setDataAdditional({
      ...dataAdditional,
      warehouse_id: newValue ? newValue.id : 0,
    })
  }

  // const validateSku = async (sku) => {
  //   await Axios({
  //     url: `${API_PRODUCTS}/SkuList`,
  //     method: 'post',
  //     data: [`${userLoggedin.name}-${sku}`],
  //   })
  //     .then(function (response) {
  //       if (response?.data?.length === 0) {
  //         clearError('sku')
  //       } else {
  //         showDialogAlert(
  //           true,
  //           '¡Advertencia!',
  //           `El sku ${sku} ya se encuentra registrado.`,
  //           'Cerrar'
  //         )
  //         setError('sku', 'notMatch', 'Sku repetido')
  //         // isSkuRepeat = false
  //       }
  //     })
  //     .catch(() => {})
  // }
  const handleChange = (event) => {
    let { value } = event.target
    if (event.target.type === 'checkbox') {
      value = event.target.checked
      setDataAdditional({
        ...dataAdditional,
        [event.target.id]: value,
      })
      return false
    }
    if (isAlphanumeric(value))
      setDataAdditional({
        ...dataAdditional,
        [event.target.id]: value,
      })
  }

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      fullWidth
      maxWidth="xl"
      classes={{ paperWidthXl: classes.modalVariationForm }}
      onClose={onClose}
      open
    >
      <DialogTitle className={classes.title} disableTypography>
        <Typography variant="h6">Agregue información a los campos</Typography>
      </DialogTitle>
      <DialogContent className={classes.variantContent}>
        <Grid container direction="row" justify="center" alignItems="flex-start">
          <FormGroup className={classes.formInputInfo}>
            <InputLabel error={!!errors.sku} className={classes.labelSize}>
              SKU
            </InputLabel>
            <TextField
              fullWidth
              id="sku"
              name="sku"
              variant="outlined"
              onChange={(event) =>
                setDataAdditional({
                  ...dataAdditional,
                  sku: event.target.value,
                })
              }
              // onBlur={(event) => {
              //   if (event.target.value !== '') {
              //     validateSku(event.target.value)
              //   }
              // }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" variant="outlined">
                    {userLoggedin.name.substring(0, 5)}-
                  </InputAdornment>
                ),
              }}
              value={dataAdditional.sku
                ?.toLowerCase()
                .replace(`${userLoggedin.name.toLowerCase()}-`, '')}
              inputRef={register}
              error={!!errors.sku}
              helperText={errors?.sku?.message}
            />
          </FormGroup>
          <FormGroup className={classes.formInputInfo}>
            <InputLabel className={classes.labelSize} error={!!errors.height}>
              Altura(cm)
            </InputLabel>
            <TextField
              fullWidth
              variant="outlined"
              id="height"
              name="height"
              onChange={handleChange}
              value={dataAdditional.height || ''}
              inputRef={register}
              error={!!errors.height}
              helperText={errors?.height?.message}
              onKeyPress={justNumbersDecimal}
            />
          </FormGroup>
          <FormGroup className={classes.formInputInfo}>
            <InputLabel className={classes.labelSize} error={!!errors.width}>
              Ancho(cm)
            </InputLabel>
            <TextField
              fullWidth
              variant="outlined"
              id="width"
              name="width"
              onChange={handleChange}
              value={dataAdditional.width || ''}
              inputRef={register}
              error={!!errors.width}
              helperText={errors?.width?.message}
              onKeyPress={justNumbersDecimal}
            />
          </FormGroup>

          <FormGroup className={classes.formInputInfo}>
            <InputLabel className={classes.labelSize} error={!!errors.length}>
              Largo(cm)
            </InputLabel>
            <TextField
              fullWidth
              variant="outlined"
              id="length"
              name="length"
              onChange={handleChange}
              value={dataAdditional.length || ''}
              inputRef={register}
              error={!!errors.length}
              helperText={errors?.length?.message}
              onKeyPress={justNumbersDecimal}
            />
          </FormGroup>
          <FormGroup className={classes.formInput}>
            <InputLabel error={!!errors.warehouse_id_name} className={classes.labelSize}>
              Bodega de origen
            </InputLabel>
            <Autocomplete
              {...defaultProps}
              id="warehouse_id"
              name="warehouse_id"
              className={classes.autocompletePadding}
              value={valueWarehouse}
              helperText={errors?.warehouse_id?.message}
              onChange={(event, newValue) => {
                setValueWarehouse(newValue)
                handleChangeAutocomplete(event, newValue)
              }}
              renderInput={(params) => (
                <TextField
                  id="warehouse_id_name"
                  name="warehouse_id_name"
                  {...params}
                  inputRef={register}
                  error={!!errors.warehouse_id_name}
                  label=""
                  margin="normal"
                  variant="outlined"
                  className={classes.autocompleteDetailProduct}
                />
              )}
            />
          </FormGroup>
          <Grid
            container
            justify="space-between"
            direction="column"
            className={classes.divWarehouseDetailProduct}
          >
            <FormGroup className={classes.formInput}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={dataAdditional.isUsed}
                    onChange={handleChange}
                    id="isUsed"
                    name="checkedA"
                  />
                }
                label="El producto es usado"
              />
            </FormGroup>
            <FormGroup className={classes.formInput}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={dataAdditional.checkTransport}
                    onChange={handleChange}
                    id="checkTransport"
                    name="checkedA"
                  />
                }
                label="Transportadora Luegopago"
              />
            </FormGroup>
          </Grid>
        </Grid>
        <Grid container justify="center">
          <Button
            disabled={isLoading}
            onClick={() => onClose()}
            variant="contained"
            color="secondary"
            className={classes.btnBackgroundError}
          >
            Cancelar
          </Button>
          <Button
            disabled={isLoading}
            variant="contained"
            // onClick={() => onSave()}
            color="secondary"
            className={[classes.addAtributtes, classes.btnBackgroundPrimary]}
          >
            Guardar
          </Button>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}

export default PanelProducts
