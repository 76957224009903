import { makeStyles } from '@material-ui/core/styles'

export default makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  formControl: {
    marginTop: '11px',
  },
  inputBorder: {
    borderColor: '#FF0000 !important',
    borderWidth: '1px !important',
  },
  buttonAction: {
    marginLeft: 10,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  divButtons: {
    marginTop: 20,
    columnGap: 15,
  },
  btnBackgroundPrimary: {
    width: '248px',
    height: '56px',
    borderRadius: '50px',
    border: 'none',
    backgroundColor: '#5e44ff',
    color: 'white',
    fontWeight: '500',
    '&:hover': {
      fontWeight: 'bolder',
      backgroundColor: '#ef0bef',
      boxShadow: '-7px 7px 0px #000000',
      border: '2px solid #000000',
      borderRadius: '50px',
      opacity: '1',
    },

  },
  btnBackgroundError: {
    width: '248px',
    height: '56px',
    borderRadius: '50px',
    border: '1px solid #6064ff',
    color: '#6064ff',
    fontWeight: '500',
    '&:hover': {
      color: '#FF40FC',
      border: '1px solid #FF40FC',
    },
  },
  labelSize: {
    fontSize: 15,
    marginBottom: 8,
  },
  widthGroup: {
    width: '70%',
    marginTop: 5,
  },
  marginLeftCheck: {
    marginLeft: '15%',
  },
}))
