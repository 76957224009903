import React, { useCallback } from 'react'

import { Paper, Typography, Grid, Divider, List, IconButton } from '@material-ui/core'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { useDropzone } from 'react-dropzone'

import useStyles from './dropZone.styles'

import { ImportUploadIcon, DeleteIcon } from 'components/icons/personalizedIcons'
import { formatBytes } from 'utils/utils'

const DropZone = ({ archivos, setArchivos, loading, validarArchivos }) => {
  const classes = useStyles()

  const onDrop = useCallback((acceptedFiles) => {
    validarArchivos(acceptedFiles)
    if (!acceptedFiles.length) return
  })

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: '.csv, .xlsx',
    multiple: false,
  })

  const classNameDropZone = clsx({
    [classes.dropZone]: true,
    [classes.isDragActive]: isDragActive,
  })

  const eliminarArchivo = (archivo) => {
    const nuevaListaSoportes = [...archivos]
    nuevaListaSoportes.splice(nuevaListaSoportes.indexOf(archivo), 1)
    setArchivos(nuevaListaSoportes)
  }

  const mostrarArchivosAceptados = () =>
    archivos.map((file) => {
      const mensaje = file.mensajeError ? `*** ${file.mensajeError} ***` : ''
      return (
        <>
          <Grid>
            <Typography variant="body1">{`${file.path} - ${formatBytes(file.size, 2)}`}</Typography>
            <Typography variant="body2">{mensaje}</Typography>
            <IconButton
              color="secondary"
              aria-label="Eliminar soporte"
              onClick={() => eliminarArchivo(file)}
              disabled={loading}
              className={classes.deleteFileIcon}
            >
              <DeleteIcon />
            </IconButton>
          </Grid>
          <Divider className={classes.middleDivider} />
        </>
      )
    })
  return (
    <Grid item>
      <Paper {...getRootProps()} elevation={3} className={classNameDropZone}>
        <input {...getInputProps()} />
        <Typography variant="body1" align="center" style={{ margin: 20 }}>
          Arrastra o haz clic aquí para seleccionar el archivo XLSX
        </Typography>
        <Grid container className={classes.divDropIcon} justify="center">
          <IconButton aria-label="delete" disabled color="primary">
            <ImportUploadIcon className={classes.iconUpload} />
          </IconButton>
        </Grid>
      </Paper>

      {archivos.length > 0 && (
        <>
          <Grid container justify="center" style={{ minHeight: 50 }}>
            <Grid item sm={12} />
            <Grid item sm={12}>
              <List component="nav" aria-label="main mailbox folders">
                {mostrarArchivosAceptados()}
              </List>
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  )
}

DropZone.propTypes = {
  archivos: PropTypes.arrayOf(PropTypes.object).isRequired,
  setArchivos: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  customOnChange: PropTypes.func.isRequired,
  validarArchivos: PropTypes.func.isRequired,
}

DropZone.defaultProps = {
  archivo: [],
}

export default React.memo(DropZone)
