import React from 'react'

import {
    DialogContent,
    FormGroup,
    InputLabel,
    InputAdornment,
  } from '@material-ui/core'
  import { makeStyles } from '@material-ui/core/styles'
  import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
  import DateFnsUtils from '@date-io/date-fns'
  import { es } from 'date-fns/locale'
  import moment from 'moment'
  import { CalendarIcon } from 'components/icons/personalizedIcons'
  const useStyles = makeStyles((theme) => ({
    marginDate: {
        margin: '3px 20%'
    }
  }))
const ModalinputDate = ({inputDate, setInputDate, title}) => {
    const classes = useStyles()
  return (
    <div>
        <DialogContent className={classes.marginDate}>
            <FormGroup >
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
                  <InputLabel>
                    {title}
                  </InputLabel>
                  <DatePicker
                    id="dateOnSaleFrom"
                    value={inputDate || null}
                    onChange={(value) => {
                      const date = new Date(value)
                      const month =
                        date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
                        const dateCancel = moment(`${date.getFullYear()}-${month}-${date.getDate() < 10 ? `0${date.getDate()}` : `${date.getDate()}`}T01:00:00`).format().replace('-05:00', '')
                        setInputDate(dateCancel)
                    }}
                    format="yyyy-MM-dd"
                    inputVariant="outlined"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <CalendarIcon />
                        </InputAdornment>
                      ),
                    }}
                    disablePast={false}
                    KeyboardButtonProps={{ style: { padding: 0 } }}
                  />
                </MuiPickersUtilsProvider>
            </FormGroup>
        </DialogContent>
    </div>
  )
}

export default ModalinputDate