/* eslint-disable no-nested-ternary */
import React from 'react'

import { Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'

const useStyles = makeStyles((theme) => ({
  smallAll: {
    fontWeight: 400,
    backgroundColor: theme.palette.secondary.pink,
    color: theme.palette.white.main,
    border: 0,
    '&:hover': {
      backgroundColor: theme.palette.secondary.pink,
    },
  },
  smallGrey: {
    fontWeight: 400,
    backgroundColor: theme.palette.dimGrey.main,
    color: theme.palette.white.main,
    border: 0,

    '&:hover': {
      backgroundColor: theme.palette.dimGrey.main,
    },
  },
  smallSuccess: {
    fontWeight: 400,
    backgroundColor: theme.palette.mintGreen.main,
    color: theme.palette.white.main,
    border: 0,

    '&:hover': {
      backgroundColor: theme.palette.mintGreen.main,
    },
  },
  smallWarning: {
    fontWeight: 400,
    backgroundColor: theme.palette.error.main,
    color: theme.palette.white.main,
    border: 0,

    '&:hover': {
      backgroundColor: theme.palette.error.main,
    },
  },
  notActiveSmallGrey: {
    fontWeight: 400,

    '&:hover': {
      color: theme.palette.white.main,
      backgroundColor: theme.palette.dimGrey.main,
    },
  },
  notActiveSmallSuccess: {
    fontWeight: 400,

    '&:hover': {
      color: theme.palette.white.main,
      backgroundColor: theme.palette.mintGreen.main,
    },
  },
  notActiveSmallWarning: {
    fontWeight: 400,

    '&:hover': {
      color: theme.palette.white.main,
      backgroundColor: theme.palette.error.main,
    },
  },

  notActiveSmallAll: {
    fontWeight: 400,

    '&:hover': {
      color: theme.palette.white.main,
      backgroundColor: theme.palette.secondary.light,
    },
  },
}))

const FilterButtonsPeriod = ({ label, onClick, active }) => {
  const classes = useStyles()
  return (
    <>
      {active ? (
        <Button
          className={
            label === 'Abierto'
              ? classes.smallGrey
              : label === 'Pagado'
              ? classes.smallSuccess
              : label === 'Todos'
              ? classes.smallAll
              : classes.smallWarning
          }
          variant="outlined"
          onClick={onClick}
        >
          {label}
        </Button>
      ) : (
        <Button
          className={
            label === 'Abierto'
              ? classes.notActiveSmallGrey
              : label === 'Pagado'
              ? classes.notActiveSmallSuccess
              : label === 'Todos'
              ? classes.notActiveSmallAll
              : classes.notActiveSmallWarning
          }
          variant="outlined"
          onClick={onClick}
        >
          {label}
        </Button>
      )}
    </>
  )
}

FilterButtonsPeriod.defaultProps = {
  active: false,
  onClick: () => null,
  label: '',
}

FilterButtonsPeriod.propTypes = {
  active: PropTypes.bool,
  onClick: PropTypes.func,
  label: PropTypes.string,
}

export default FilterButtonsPeriod

// import React from 'react'
// import { Typography, Grid } from '@material-ui/core'
// import Button from 'components/CustomButtons/Button'
// import Link from '@material-ui/core/Link'
// import Avatar from '@material-ui/core/Avatar'
// import PropTypes from 'prop-types'
// import useStyles from './styles'

// const FilterButtonsPeriod = ({ label, onClick, active }) => {
//   const classes = useStyles()

//   return (
//     <>
//       {active ? (
//         <Button
//           className={
//             label === 'Todos' || label === 'Abierto' ? classes.leftButtons : classes.button
//           }
//           onClick={onClick}
//         >
//           {label !== 'Todos' ? (
//             <Avatar
//               alt="Remy Sharp"
//               className={
//                 label === 'Abierto'
//                   ? classes.smallGrey
//                   : label === 'Pagado'
//                   ? classes.smallWarning
//                   : classes.smallSuccess
//               }
//             >
//               {' '}
//             </Avatar>
//           ) : null}

//           <Typography variant="overline" gutterBottom>
//             {label}
//           </Typography>
//         </Button>
//       ) : (
//         <Button
//           simple
//           onClick={onClick}
//           className={label === 'Todos' || label === 'Abierto' ? classes.leftButtons : classes.link}
//           color="primary"
//         >
//           {label !== 'Todos' ? (
//             <Avatar
//               alt="Remy Sharp"
//               className={
//                 label === 'Abierto'
//                   ? classes.smallGrey
//                   : label === 'Pagado'
//                   ? classes.smallWarning
//                   : classes.smallSuccess
//               }
//             >
//               {' '}
//             </Avatar>
//           ) : null}

//           <Typography variant="overline">{label}</Typography>
//         </Button>
//         // <Link className={classes.link} underline="none" component="button" variant="body2" onClick={onClick}>

//         // </Link>
//       )}
//     </>
//   )
// }

// FilterButtonsPeriod.defaultProps = {
//   disabled: false,
//   active: false,
//   onClick: () => null,
//   isLoading: false,
//   color: 'behance',
//   label: '',
// }

// FilterButtonsPeriod.propTypes = {
//   disabled: PropTypes.bool,
//   active: PropTypes.bool,
//   onClick: PropTypes.func,
//   isLoading: PropTypes.bool,
//   color: PropTypes.string,
//   label: PropTypes.string,
// }
