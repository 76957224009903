import { makeStyles } from '@material-ui/core/styles'

export default makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  content: {
    width: '80%',
    display: 'flex',
    gap: '10px',
    flexWrap: 'wrap',
  },
  component: {
    width: '40%',
    marginBottom: 10,
  },
  btnBackgroundPrimary: {
    marginLeft: 5,
    minWidth: 280,
    height: 50,
    maxWidth: 346,
    fill: theme.palette.white.main,
    borderRadius: '50px',
    border: 'none',
    margin: '20px 0',
    backgroundColor: '#5e44ff',
    color: 'white',
    fontWeight: '500',
    '&:hover': {
      fontWeight: 'bolder',
      backgroundColor: '#ef0bef',
      boxShadow: '-7px 7px 0px #000000',
      border: '2px solid #000000',
      borderRadius: '50px',
      opacity: '1',
    },
  },
  sellersComponent: {
    width: '100%',
  },
}))
