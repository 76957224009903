import React, { useEffect, useState } from 'react'

import { Grid, Typography, Box, Divider } from '@material-ui/core'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import TreeItem from '@material-ui/lab/TreeItem'
import TreeView from '@material-ui/lab/TreeView'

import useStyles from '../ReportsAccountStyle'

import CardBody from 'components/Card/CardBody.js'
import convertToCurrencyCop from 'utils/convertToCurrencyCop'

const datatable = {
  id: 'root',
  name: 'Reembolso de comisiones',
  children: [
    {
      id: '1',
      name: 'Devoluciones Servicio de comisión',
    },
    {
      id: '2',
      name: 'Retefuente (Descuento Servicios de comisión)',
    },
  ],
}
const timeArea = {
  textAlign: 'right',
}
const Refund = ({ dataOrders }) => {
  useEffect(() => {
    if (dataOrders?.return) {
      const extraChargeLet = dataOrders?.return?.refundCommission || {}
      setData({
        id: 'root',
        name: 'Reembolso de comisiones',
        children: [
          {
            id: '1',
            name: 'Devoluciones Servicio de comisión',
            value: extraChargeLet?.returnCommisionService,
          },
          {
            id: '2',
            name: 'Retefuente (Descuento Servicios de comisión)',
            value: extraChargeLet?.returnReteFuente,
          },
        ],
      })
    }
  }, [dataOrders])
  const [data, setData] = useState(datatable)
  const renderTree = (nodes, classes) => {
    return (
      <>
        {nodes?.value || nodes.value !== 0 ? (
          <TreeItem
            key={nodes.id}
            nodeId={nodes.id}
            label={
              <Grid container justify="space-between" alignItems="center">
                <Typography
                  component="div"
                  className={
                    nodes.id === 'root' ? classes.letterSpacingRoot : classes.letterSpacing
                  }
                >
                  {nodes.name}
                </Typography>

                <Typography component="div" className={classes.letterSpacing}>
                  {nodes.value && `$${convertToCurrencyCop(Number(nodes.value || 0))} COP`}
                </Typography>
              </Grid>
            }
          >
            {Array.isArray(nodes.children)
              ? nodes.children.map((node) => renderTree(node, classes))
              : null}
          </TreeItem>
        ) : null}
      </>
    )
  }
  const classes = useStyles()
  return (
    <>
      <Grid container direction="row" justify="flex-start" alignItems="flex-start">
        <Typography variant="subtitle2" className={classes.widthInitialBalance}>
          Devoluciones
        </Typography>
        <Grid
          container
          justify="space-between"
          alignItems="flex-start"
          className={classes.InitialBalancePadding}
        >
          <Typography align="left" className={classes.widthCloseInitialBalance}>
            Pedidos devueltos o cancelados
          </Typography>
          <Typography align="right" className={classes.widthValueRigth}>
            {`$${convertToCurrencyCop(Number(dataOrders?.return?.orderReturnedCancel))} COP`}
          </Typography>
          <Typography align="left" className={classes.widthCloseInitialBalance}>
            Otros ingresos
          </Typography>
          <Typography align="right" className={classes.widthValueRigth}>
            {`$${convertToCurrencyCop(Number(dataOrders?.return?.debitOtherIncome || 0))} COP`}
          </Typography>
          <TreeView
            className={classes.root}
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpanded={['root']}
            defaultExpandIcon={<ChevronRightIcon />}
          >
            {renderTree(data, classes)}
          </TreeView>
        </Grid>
      </Grid>
      <Divider />
      <Grid container direction="row" justify="flex-end" alignItems="flex-start"
      >
        <Typography variant="subtitle1">Subtotal</Typography>
        <Typography align="right" className={classes.widthValueInitialBalance}>
          {`$${convertToCurrencyCop(Number(dataOrders?.return?.refundSubTotal || 0))} COP`}
        </Typography>
      </Grid>
    </>
  )
}
export default Refund
