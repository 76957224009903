import React, { useEffect, useState } from 'react'

import { DialogContent, DialogTitle, Typography } from '@material-ui/core'

import { VIRTUAL_ORDERS } from 'constants/commercialName'
import { translateNameFiltertoType } from 'utils/filterState'

const ModalCancelationConfirm = ({ cancelationInfo, typeid }) => {
  const { orderId, seller } = cancelationInfo
  const [typeOrder, setTypeOrder] = useState('')
  useEffect(() => {
    switch (typeid) {
      case 4:
        setTypeOrder('completado')
        break
      case 6:
        setTypeOrder('devuelto')
        break
      case 7:
        setTypeOrder('procesado')
        break
      default:
        setTypeOrder('cancelado')
    }
  }, [])
  const renderListItem = (commercialName) => {
    if (
      typeid === translateNameFiltertoType('Completado') &&
      commercialName.trim() === VIRTUAL_ORDERS
    ) {
      return (
        <li>
          <Typography>{commercialName}</Typography>
        </li>
      )
    }
  }
  return (
    <div>
      <DialogTitle align="center">¡Advertencia!</DialogTitle>
      <DialogContent>
        <Typography variant="body1">
          {`Está a punto de cambiar el estado de la orden #${orderId} a ${typeOrder} para ${
            seller.length > 1 ? 'las siguientes tiendas' : 'la siguiente tienda'
          }:`}
        </Typography>
        <ul style={{ margin: '10px 20px', listStyle: 'circle' }}>
          {seller.map(({ id, commercialName }) => {
            return renderListItem(commercialName)
          })}
        </ul>
      </DialogContent>
    </div>
  )
}

export default ModalCancelationConfirm
