import React, { useEffect, useState } from 'react'

import {
  CircularProgress,
  Grid,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import CheckIcon from '@material-ui/icons/Check'
import CloseIcon from '@material-ui/icons/Close'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import axios from 'axios'
import MUIDataTable from 'mui-datatables'

import ModalCategoriesImport from '../../views/UploadProducts/ModalCategoriesImport'

import useStyles from './ListComisions.styles'

import Button from 'components/CustomButtons/Button'
import CustomToolbar from 'components/CustomToolbarMuiDatatable/CustomToolbarMuiDatatable'
import useCustomDialogAlert from 'components/useCustomDialogAlert'
import { API_CATEGORIES_SELLER } from 'constants/urls'
import textLabels from 'utils/MUIDataTableTextLabels'
import { onlyNumber } from 'utils/utils'

const ListCategoriesCommissions = ({
  idSeller,
  setIsSearch,
  dataCategories,
  setDataCategories,
}) => {
  const classes = useStyles()
  const showDialogAlert = useCustomDialogAlert()
  const [isLoading, setIsLoading] = useState(false)
  const [changeComission, setChangeComission] = useState({ index: '', flag: false })
  const [number, setNumber] = useState({ id: '', value: '' })
  const [totalCount, setTotalCount] = useState(0)
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 10,
    typeFilter: 1,
    perPage: 10,
    prevPage: '',
    nextPage: '',
  })
  const [isOpenModalCategories, setIsOpenCategories] = useState(false)
  const [categoriesSeller, setCategoriesSeller] = useState({})
  const [treeCategories, setTreeCategories] = useState([])
  const [searchText, setSearchText] = useState('')

  useEffect(() => {
    if (treeCategories.length > 0) {
      let stringNameCategories = ''
      treeCategories.map((tree) => {
        stringNameCategories = `${stringNameCategories + tree.name.toString()}> `
      })
    }
  }, [treeCategories])
  const getCategoriesSellerData = async (pageSize = null, page = null) => {
    await axios
      .get(
        `${API_CATEGORIES_SELLER}/${idSeller.id}?pageSize=${pageSize || pagination.pageSize}&page=${
          page || pagination.page
        }`
      )
      .then((response) => {
        if (response) {
          setDataCategories(response.data.data)
          setTotalCount(response.data.pagination.totalCount)
          setPagination(...pagination, {
            page: response.data.pagination.pageNumber,
            pageSize: response.data.pagination.pageSize,
          })
          setIsSearch(true)
          setIsLoading(false)
        }
      })
      .catch(() => {
        setIsLoading(false)
      })
  }
  useEffect(() => {
    if (idSeller) {
      getCategoriesSellerData()
    }
  }, [pagination])
  const updateCommision = async (idCategory, sellerId) => {
    if (number.value === '') {
      showDialogAlert(true, '¡Advertencia!', `El campo no puede estar vacio.`, 'Cerrar')
      return false
    }
    try {
      setIsLoading(true)
      const response = await axios.put(`${API_CATEGORIES_SELLER}/${idCategory}`, {
        percent: number.value,
        sellerId: sellerId,
      })

      if (response) {
        if (response.status === 200) {
          setIsLoading(false)
          showDialogAlert(
            true,
            '¡Cambio exitoso!',
            `Se ha modficado correctamente la comisión`,
            'Cerrar',
            '',
            true
          )
          getCategoriesSellerData()
          setChangeComission({ index: '', flag: false })
        }
      }
    } catch (error) {
      setIsLoading(false)
      console.error(error)
    }
  }
  const deleteCategorieSeller = async (idCategory) => {
    try {
      setIsLoading(true)
      const response = await axios.delete(`${API_CATEGORIES_SELLER}/${idCategory}`)

      if (response) {
        if (response.status === 200) {
          setIsLoading(false)
          showDialogAlert(
            true,
            '¡Cambio exitoso!',
            `Se ha eliminado correctamente la categoría`,
            'Cerrar',
            '',
            true
          )

          getCategoriesSellerData()
        }
      }
    } catch (error) {
      setIsLoading(false)
      console.error(error)
    }
  }

  const columns = [
    {
      name: 'id',
      options: { display: false },
    },
    {
      name: 'sellerId',
      options: { display: false },
    },
    {
      name: 'nameCategory',
      label: 'Categoría',
    },
    {
      name: 'percent',
      label: 'Comisión',
      options: {
        customBodyRender: (value, tableMeta) => {
          return (
            <>
              {changeComission.flag && changeComission.index === tableMeta.rowIndex ? (
                <Grid
                  container
                  direction="row"
                  justify="flex-start"
                  spacing={2}
                  alignItems="center"
                >
                  <Grid item>
                    <TextField
                      onChange={(e) => {
                        if (onlyNumber(e.target.value)) {
                          if (e.target.value > 100) return false
                          setNumber({ id: tableMeta.rowData[0], value: e.target.value })
                        } else {
                          return false
                        }
                      }}
                      placeholder="ingrese la comision"
                      InputProps={{
                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                      }}
                      type="text"
                      id="name"
                      min="0"
                      max="100"
                      value={
                        number.id === tableMeta.rowData[0] ? number.value : tableMeta.rowData[3]
                      }
                    />
                  </Grid>
                </Grid>
              ) : (
                <Typography
                  onClick={(event) => {
                    event.preventDefault()
                    setChangeComission({ index: tableMeta.rowIndex, flag: true })
                  }}
                >
                  {`${tableMeta.rowData[3]}%`}
                </Typography>
              )}
            </>
          )
        },
      },
    },
    {
      name: '',
      label: 'Acciones',
      options: {
        customBodyRender: (value, tableMeta) => {
          return (
            <>
              {changeComission.flag && changeComission.index === tableMeta.rowIndex ? (
                <>
                  <Tooltip title="Cancelar">
                    <Button
                      onClick={(event) => {
                        event.preventDefault()

                        setChangeComission({ flag: false })
                      }}
                      color="pinterest"
                      justIcon
                      round
                    >
                      <CloseIcon />
                    </Button>
                  </Tooltip>
                  <Tooltip title="Guardar">
                    <Button
                      onClick={(event) => {
                        event.preventDefault()
                        updateCommision(tableMeta.rowData[0], tableMeta.rowData[1])
                      }}
                      color="success"
                      justIcon
                      round
                    >
                      <CheckIcon />
                    </Button>
                  </Tooltip>
                </>
              ) : (
                <>
                  <Tooltip title="Editar">
                    <Button
                      onClick={(event) => {
                        event.preventDefault()
                        setChangeComission({ index: tableMeta.rowIndex, flag: true })
                      }}
                      color="linkedin"
                      justIcon
                      round
                    >
                      <EditIcon />
                    </Button>
                  </Tooltip>

                  <Tooltip title="Eliminar">
                    <Button
                      onClick={(event) => {
                        event.preventDefault()
                        deleteCategorieSeller(tableMeta.rowData[0])
                      }}
                      color="pinterest"
                      justIcon
                      round
                    >
                      <DeleteIcon />
                    </Button>
                  </Tooltip>
                </>
              )}
            </>
          )
        },
      },
    },
  ]
  const onChangeRowsPerPage = (pageSize) => {
    setPagination({
      ...pagination,
      perPage: pageSize,
    })
    // getCategoriesSellerData(pageSize)
  }
  const onChangePage = async (page) => {
    setPagination({
      ...pagination,
      page: page + 1,
      prevPage: page,
      nextPage: page + 2,
    })
    document.querySelector('#main').scrollTop = 0
    // getCategoriesSellerData(null, page + 1)
  }

  const getCategories = async (pageSize = null, page = null, cleanSeachText = false) => {
    await axios
      .get(
        `${API_CATEGORIES_SELLER}/${idSeller.id}?filterText=${
          cleanSeachText ? '' : searchText
        }&pageSize=${pageSize || pagination.pageSize}&page=${page || pagination.page}`
      )
      .then((response) => {
        if (response) {
          setDataCategories(response.data.data)
          setTotalCount(response.data.pagination.totalCount)
          setPagination(...pagination, {
            page: response.data.pagination.pageNumber,
            pageSize: response.data.pagination.pageSize,
          })
          setIsSearch(true)
          setIsLoading(false)
        }
      })
      .catch(() => {
        setIsLoading(false)
      })
  }

  const handleChangeSearch = (e) => {
    setSearchText(e.currentTarget.value)
  }

  const handlerSearch = async () => {
    setIsLoading(true)
    await getCategories()
    return true
  }

  const handlerCleanSearch = async () => {
    setSearchText('')
    setIsLoading(true)
    await getCategories(pagination.pageSize, pagination.page, true)
    return true
  }

  const customSearchRender = (searchTexto, handleSearch, hideSearch) => {
    return (
      <Grid container alignItems="baseline" style={{ padding: '0.5rem' }}>
        <TextField
          placeholder="Ingrese la categoría"
          type="text"
          id="searchText"
          onChange={handleChangeSearch}
          value={searchText}
          className={classes.inputSearchCommission}
          style={{ fontFamily: 'Red Hat Display Regular' }}
        />

        <Grid>
          <div style={{ display: 'flex', gap: '0.5rem' }}>
            <Button
              className={classes.btnSearchCommission}
              color="secondary"
              onClick={handlerSearch}
            >
              Buscar
            </Button>
            <Button
              className={classes.btnSearchCommission}
              color="secondary"
              onClick={handlerCleanSearch}
            >
              Limpiar
            </Button>
            <Button color="pinterest" justIco onClick={hideSearch} style={{ marginLeft: '.5rem' }}>
              <CloseIcon />
            </Button>
          </div>
        </Grid>
      </Grid>
    )
  }

  const options = {
    print: false,
    filter: false,
    download: false,
    viewColumns: false,
    filterType: 'dropdown',
    responsive: 'scrollMaxHeight',
    rowHover: true,
    pagination: true,
    serverSide: true,
    count: totalCount,
    rowsPerPage: pagination.perPage,
    rowsPerPageOptions: [5, 10, 20, 100],
    onChangeRowsPerPage,
    onChangePage,
    selectableRows: 'none',
    textLabels,
    sort: false,
    customSearchRender,
    customToolbar: () => {
      return (
        <CustomToolbar
          icon={<AddIcon />}
          tooltip="Asignar categoría"
          onClick={() => {
            setIsOpenCategories(!isOpenModalCategories)
          }}
        />
      )
    },
  }
  return (
    <>
      <MUIDataTable
        title={
          <Typography variant="h6">
            Lista de categorías
            {isLoading && (
              <CircularProgress
                size={24}
                style={{ marginLeft: 15, position: 'relative', top: 4 }}
              />
            )}
          </Typography>
        }
        data={dataCategories}
        columns={columns}
        options={options}
      />
      <ModalCategoriesImport
        isOpenModalCategories={isOpenModalCategories}
        setIsOpenModalCategories={setIsOpenCategories}
        categoriesSeller={categoriesSeller}
        setCategoriesSeller={setCategoriesSeller}
        setTreeCategories={setTreeCategories}
        idSeller={idSeller}
      />
    </>
  )
}

export default ListCategoriesCommissions
