/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react'

import { Grid, IconButton, Typography } from '@material-ui/core'
import Avatar from '@material-ui/core/Avatar'
import Divider from '@material-ui/core/Divider'
import axios from 'axios'
import MUIDataTable from 'mui-datatables'
import PropTypes from 'prop-types'

import useStyles from './ReportsAccountStyle'

import BackDrop from 'components/BackDrop'
import FilterButtonsPeriod from 'components/FilterButtonsPeriod'
import { VisibilityIcon } from 'components/icons/personalizedIcons'
import { API_FINANCIAL_SUMMARY } from 'constants/urls'
import convertToCurrencyCop from 'utils/convertToCurrencyCop'
import { textLabels } from 'utils/functions'
import { getMonthDate } from 'utils/utils'

export default function PeridosPanel({ handleRowPeriod, setIsLoadingData }) {
  const classes = useStyles()
  const [status, setState] = useState('Todos')
  const [month, setMonth] = useState(getMonthDate())
  const [data, setData] = useState([])
  const [filterDelay, setFilterDelay] = useState(false)
  const addStateFilter = (value) => {
    switch (value) {
      case 0:
        setState('Todos')
        break
      case 1:
        setState('Abierto')
        break
      case 2:
        setState('Pagado')
        break
      case 3:
        setState('Pendiente por pago')
        break
      default:
        setState('Todos')
    }
  }
  const getFinancialSummary = (sellerId = 0, Type = 0) => {
    setIsLoadingData(false)
    addStateFilter(Type)
    setFilterDelay(true)
    axios
      .get(`${API_FINANCIAL_SUMMARY}?SellerId=${sellerId}&Type=${Type}`)
      .then(function (response) {
        setData(response.data.data)
        console.log(response.data.data)
        setFilterDelay(false)
      })
      .catch(function (error) {
        console.log(error)
        setFilterDelay(false)
      })
  }
  useEffect(() => {
    getFinancialSummary()
  }, [])

  const columns = [
    {
      name: 'id',
      options: { display: false },
    },
    {
      name: 'startDatePeriod',
      label: 'PERIODO',
      options: {
        customBodyRender: (value) => {
          return (
            <Typography variant="subtitle2">
              {`${month[new Date(value).getMonth()]} ${new Date(value).getFullYear()}`}
            </Typography>
          )
        },
      },
    },
    {
      name: 'total',
      label: 'PAGO',
      options: {
        customBodyRender: (value) => {
          return <div>{`$ ${convertToCurrencyCop(value)} COP`}</div>
        },
      },
    },
    {
      name: 'status',
      label: 'ESTADO',
      options: {
        customBodyRender: (value, tableMeta) => {
          return (
            <>
              <Avatar
                alt="Remy Sharp"
                className={
                  tableMeta.rowData[3] === 'Abierto'
                    ? classes.smallGrey
                    : tableMeta.rowData[3] === 'Pagado'
                    ? classes.smallSuccess
                    : classes.smallWarning
                }
              >
                {' '}
              </Avatar>
            </>
          )
        },
      },
    },
    {
      options: {
        customBodyRender: (value, tableMeta) => {
          console.log(tableMeta.rowData[2])
          return (
            <>
              <IconButton
                onClick={() => {
                  handleRowPeriod(tableMeta.rowData[0])
                }}
              >
                <VisibilityIcon />
              </IconButton>
            </>
          )
        },
      },
    },
  ]

  const options = {
    responsive: 'scroll',
    search: false,
    filterType: 'dropdown',
    selectableRows: 'none',
    rowHover: true,
    filter: false,
    sort: false,
    print: false,
    serverSide: true,
    textLabels: textLabels(filterDelay),
    fixedSelectColumn: false,
    download: false,
    viewColumns: false,
    pagination: false,
  }

  return (
    <>
      <BackDrop open={filterDelay} />
      <Grid container className={classes.alignToSectionPanel} md={6}>
        <Typography variant="subtitle2">Filtrar por:</Typography>
        <Grid container direction="row" className={classes.filterPosition}>
          <FilterButtonsPeriod
            label="Todos"
            active={status === 'Todos'}
            onClick={() => {
              getFinancialSummary()
            }}
          />
          <FilterButtonsPeriod
            label="Abierto"
            active={status === 'Abierto'}
            onClick={() => {
              getFinancialSummary(0, 1)
            }}
          />
          <FilterButtonsPeriod
            label="Pendiente de pago"
            active={status === 'Pendiente por pago'}
            onClick={() => {
              getFinancialSummary(0, 3)
            }}
          />
          <FilterButtonsPeriod
            label="Pagado"
            active={status === 'Pagado'}
            onClick={() => {
              getFinancialSummary(0, 2)
            }}
          />
        </Grid>
        <MUIDataTable
          data={data.map((item) => {
            return [item.id, item.startDatePeriod, item.total, item.status]
          })}
          columns={columns}
          options={options}
        />
      </Grid>
    </>
  )
}
PeridosPanel.propTypes = {
  handleRowPeriod: PropTypes.func.isRequired,
}
