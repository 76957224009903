/* eslint-disable react/prop-types */
import React, { useState } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { FormGroup, Grid, InputLabel, Switch, TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'

import Button from 'components/CustomButtons/Button'
import useCustomDialogAlert from 'components/useCustomDialogAlert'

const useStyles = makeStyles((theme) => ({
  gridButtons: {
    marginTop: 20,
    gap: '20px',
  },
  btnBackgroundError: {
    width: 150,
    height: 35,
    borderRadius: '50px',
    border: '1px solid #6064ff',
    color: '#6064ff',
    fontWeight: '500',
    backgroundColor: '#ffffff',
    '&:hover': {
      color: '#FF40FC',
      border: '1px solid #FF40FC',
      backgroundColor: '#ffffff',
    },
  },
  btnBackgroundPrimary: {
    width: 150,
    height: 35,
    borderRadius: '50px',
    border: 'none',
    backgroundColor: '#5e44ff',
    color: 'white',
    fontWeight: '500',
    '&:hover': {
      fontWeight: 'bolder',
      backgroundColor: '#ef0bef',
      boxShadow: '-7px 7px 0px #000000',
      border: '2px solid #000000',
      borderRadius: '50px',
      opacity: '1',
    },
  },
  labelInputs: {
    fontWeight: 'bold',
    marginBottom: '10px',
    marginTop: '10px',
  },
}))

const schema = yup.object().shape({
  name: yup.string().required('Campo requerido'),
  value: yup.string().required('Campo requerido'),
})

const ModalConfigurationSetting = ({
  setting,
  setSetting,
  toEdit,
  onCancel,
  createUpdateSetting,
}) => {
  const classes = useStyles()

  const [alert, setAlert] = useState(null)

  const { register, handleSubmit, errors } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
  })


  return (
    <form>
      <Grid container direction="column">
        <FormGroup>
          <InputLabel error={!!errors.name} className={classes.labelInputs}>
            Nombre:
          </InputLabel>
          <TextField
            id="name"
            value={setting.name}
            variant="outlined"
            inputRef={register}
            name="name"
            error={!!errors.name}
            helperText={errors?.name?.message}
            onChange={(event) =>
              setSetting({
                ...setting,
                name: event.target.value,
              })
            }
          />
        </FormGroup>

        <FormGroup>
          <InputLabel className={classes.labelInputs}>Valor:</InputLabel>
          <TextField
            id="value"
            value={setting.value || ''}
            variant="outlined"
            inputRef={register}
            name="value"
            onChange={(event) =>
              setSetting({
                ...setting,
                value: event.target.value,
              })
            }
          />
        </FormGroup>
      </Grid>
      <Grid container justify="center" className={classes.gridButtons}>
        <Button
          className={classes.btnBackgroundError}
          onClick={() => {
            onCancel()
          }}
        >
          Cancelar
        </Button>
        <Button
          className={classes.btnBackgroundPrimary}
          color="primary"
          onClick={() => createUpdateSetting()}
        >
          {toEdit ? 'Actualizar' : 'Guardar'}
        </Button>
      </Grid>
      {alert}
    </form>
  )
}

export default ModalConfigurationSetting
