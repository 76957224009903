import React, { useEffect, useState } from 'react'

import {
  Button,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  makeStyles,
  TextField,
} from '@material-ui/core'
import Card from '@material-ui/core/Card'
import Typography from '@material-ui/core/Typography'
import { useForm } from 'react-hook-form'

import CouponAlert from './CouponAlert'

import { EyeIconHidden, EyeIconShow, LockIcon } from 'components/icons/personalizedIcons'
import useCouponsInterceptor from 'hook/useCouponsInterceptor'
import styles from 'views/Login/loginPageStyle'

const useStyles = makeStyles(styles)

export default function SimpleCard() {
  const classes = useStyles()
  const [isLoading, setIsLoading] = useState(false)
  const { activateMultipleCoupons } = useCouponsInterceptor()
  const [open, setOpen] = useState(false)
  const [response, setResponse] = useState({
    isSuccessful: false,
    data: {},
    message: '',
  })
  const [showPassword, setShowPassword] = useState(false)
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
  })

  const onSubmit = (data) => {
    setIsLoading(true)
    const formData = new FormData()
    formData.append('SupplierKey', data.SupplierKey)
    formData.append('CouponsFile', data.CouponsFile[0])
    activateMultipleCoupons(formData).then((res) => {
      setResponse(res)
      setOpen(true)
      setIsLoading(false)
      reset()
    })
  }

  const handleLoaded = (_) => {
    window.grecaptcha.ready((_) => {
      window.grecaptcha
        .execute('6Lcirj4hAAAAADY4dljRUO2sDZ03KUfsjwgjQhMT', { action: 'homepage' })
        .then((token) => {
          // ...
        })
    })
  }

  useEffect(() => {
    // Add reCaptcha
    const script = document.createElement('script')
    script.src =
      'https://www.google.com/recaptcha/enterprise.js?render=6Lcirj4hAAAAADY4dljRUO2sDZ03KUfsjwgjQhMT'
    script.addEventListener('load', handleLoaded)
    document.body.appendChild(script)
  }, [])

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        backgroundColor: '#cccc',
      }}
    >
      <Card style={{ padding: '20px' }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container direction="column">
            <Typography
              variant="h5"
              component="h2"
              style={{ fontWeight: 'bold', textAlign: 'center', marginBottom: '20px' }}
            >
              Activacion de cupones
            </Typography>
            <TextField
              type={showPassword ? 'text' : 'password'}
              className={classes.input}
              id="SupplierKey"
              variant="outlined"
              label="Clave de proveedor"
              name="SupplierKey"
              error={errors?.SupplierKey && errors.SupplierKey.message}
              helperText={errors?.SupplierKey && errors.SupplierKey.message}
              inputRef={register({ required: 'La clave de proveedor es requerida' })}
              InputProps={{
                autoComplete: 'off',
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowPassword(!showPassword)}>
                      {showPassword ? <EyeIconHidden /> : <EyeIconShow />}
                    </IconButton>
                  </InputAdornment>
                ),
                startAdornment: (
                  <InputAdornment position="start" className={classes.inputAdornmentIcon}>
                    <LockIcon />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              type="file"
              className={classes.input}
              id="couponsFile"
              variant="outlined"
              label="Archivo de cupones"
              name="CouponsFile"
              error={errors?.CouponsFile && errors.CouponsFile.message}
              helperText={errors?.CouponsFile && errors.CouponsFile.message}
              inputProps={{
                accept:
                  '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
              }}
              InputLabelProps={{
                shrink: true,
              }}
              inputRef={register({ required: 'El archivo de cupones es requerido' })}
            />
            {/* <Button variant="contained" color="primary" className={classes.btnAccept} type="submit">
              {isLoading ? (
                <CircularProgress size={24} className={classes.buttonProgress} />
              ) : (
                'Activar'
              )}
            </Button> */}
            <Button
              variant="contained"
              color="primary"
              type="submit"
              className={classes.btnAccept}
              data-sitekey="6Lcirj4hAAAAADY4dljRUO2sDZ03KUfsjwgjQhMT"
              data-callback="onSubmit"
              data-action="submit"
            >
              {isLoading ? (
                <CircularProgress size={24} className={classes.buttonProgress} />
              ) : (
                'Activar'
              )}
            </Button>
          </Grid>
        </form>
      </Card>
      <CouponAlert open={open} handleClose={() => setOpen(false)} response={response} />
      <div
        className="g-recaptcha"
        data-sitekey="6Lcirj4hAAAAADY4dljRUO2sDZ03KUfsjwgjQhMT"
        data-size="invisible"
      />
    </div>
  )
}
