import React, { useState, useContext } from 'react'

import { Button, Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'

import styles from './styles'

import { DROPSHIPPER } from 'constants/rolesConst'
import { routesLink } from 'constants/routesConsts'
import useAuthContext from 'contextApi/AuthContext'

const useStyles = makeStyles(styles)

export default function WelcomeSellerModal({
  handlerClosed,
  setOpenPasswordModal,
  setOpenAssociationModal,
  setOpenTermsAndConditionsModal,
}) {
  const classes = useStyles()
  const { userLoggedin } = useContext(useAuthContext)
  const history = useHistory()
  const [isComplete, setIsComplete] = useState(false)

  const changePassword = () => {
    handlerClosed(false)
    setOpenPasswordModal(true)
  }
  const changeTermsAndConditions = () => {
    handlerClosed(false)
    setOpenTermsAndConditionsModal(true)
  }
  const updateData = () => {
    handlerClosed(false)
    setOpenAssociationModal(true)
  }

  const createWarehouse = () => {
    handlerClosed(false)
    history.push(`${routesLink.storeCommission}`)
  }

  React.useEffect(() => {
    if (
      userLoggedin.passwordAlreadyChanged &&
      userLoggedin.isHadWarehouse &&
      userLoggedin.picture &&
      userLoggedin.isHadTermsAndConditions
    ) {
      setIsComplete(true)
      handlerClosed(false)
    }
  }, [userLoggedin])

  return (
    <>
      <Grid container direction="column" justifyContent="center" alignItems="center">
        <Grid item md="11">
          <Typography variant="h4">¡Bienvenido al sellercenter de luegopago!</Typography>
        </Grid>
        <Grid item md="11" className={classes.spacing}>
          <Typography variant="body1">
            Para poder continuar debes cumplir con los siguientes requisitos.
          </Typography>
        </Grid>
        <Grid item md="12" className={[classes.spacing, classes.widthFull]}>
          <Grid container direction="row" justifyContent="space-around" alignItems="center">
            {userLoggedin.role === DROPSHIPPER && !userLoggedin.passwordAlreadyChanged && (
              <Grid item>
                <Button
                  className={classes.btnBackgroundPrimary}
                  variant="contained"
                  color="secondary"
                  onClick={() => changePassword()}
                >
                  Cambiar contraseña
                </Button>
              </Grid>
            )}
            {userLoggedin.role === DROPSHIPPER && !userLoggedin.picture && (
              <Grid item>
                <Button
                  className={classes.btnBackgroundPrimary}
                  variant="contained"
                  color="secondary"
                  onClick={() => updateData()}
                >
                  Completar Informacion
                </Button>
              </Grid>
            )}
            {userLoggedin.role === DROPSHIPPER && !userLoggedin.isHadWarehouse && (
              <Grid item>
                <Button
                  className={classes.btnBackgroundPrimary}
                  variant="contained"
                  color="secondary"
                  onClick={() => createWarehouse()}
                >
                  Crear bodega
                </Button>
              </Grid>
            )}
            {/* TODO: Validar con el AccessToken si el usuario ya creó terminos y condiciones */}
            {/* TODO: Crear boton para abrir el modal donde se ingresen terminos y condiciones */}
            {!userLoggedin.isHadTermsAndConditions && (
              <Grid item>
                <Button
                  className={classes.btnBackgroundPrimary}
                  variant="contained"
                  color="secondary"
                  onClick={() => changeTermsAndConditions()}
                >
                  Agregar terminos y condiciones
                </Button>
              </Grid>
            )}
            {isComplete && (
              <Grid item>
                <Button
                  className={classes.btnBackgroundPrimary}
                  variant="contained"
                  color="secondary"
                  onClick={() => handlerClosed(false)}
                >
                  cerrar
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}
