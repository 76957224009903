import React from 'react'

import { CircularProgress, DialogContent, DialogTitle } from '@material-ui/core'
import MUIDataTable from 'mui-datatables'

import { textLabels } from 'utils/functions'

const columns = [
  {
    name: 'sellerId',
    label: 'ID',
  },
  {
    name: 'commercialName',
    label: 'Tienda',
  },
]

const ModalCancelationSellers = ({ sellers, cancelationInfo, handleSellersChange }) => {
  const { indexes } = cancelationInfo
  if (sellers.length < 1) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <CircularProgress variant="indeterminate" disableShrink size={40} thickness={4} />
      </div>
    )
  }
  return (
    <div>
      <DialogTitle>Seleccione al menos una tienda para continuar</DialogTitle>
      <DialogContent>
        <MUIDataTable
          data={sellers}
          columns={columns}
          options={{
            print: false,
            filter: false,
            download: false,
            viewColumns: false,
            customToolbar: () => <div />,
            rowsSelected: indexes,
            onRowSelectionChange: (current, all, rowsSelected) => {
              handleSellersChange(rowsSelected)
            },
            customToolbarSelect: () => <div />,
            textLabels: textLabels(sellers),
          }}
        />
      </DialogContent>
    </div>
  )
}

export default ModalCancelationSellers
