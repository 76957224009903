/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable consistent-return */
import React, { useState, useEffect, useContext } from 'react'

import { Grid, Button, Typography } from '@material-ui/core'
import Axios from 'axios'
import MUIDataTable from 'mui-datatables'

import ModalCategoriesImport from '../../UploadProducts/ModalCategoriesImport'
import PanelProducts from '../PanelProducts'
import useStyles from '../products.styles'

import BackDrop from 'components/BackDrop'
import useCustomDialogAlert from 'components/useCustomDialogAlert'
import { API_STORE_INFO } from 'constants/urls'
import useAuthContext from 'contextApi/AuthContext'

const CategoryShopifySync = ({
  columnsCategory,
  listCategoriesShopify,
  setListCategoriesShopify,
  isOpenCategories,
  setIsOpenCategories,
  rowcategoryshopify,
  isOpenPanalProducts,
  setIsOpenPanalProducts,
  setRowcategoryshopify,
  handleBack,
  saveProduct,
  openModalCategories,
  setDialogAlertVariation,
  getAttributes,
  allProductShopifySelectedNum,
  getAttributesShopify,
  activeStep,
  setActiveStep,
}) => {
  const classes = useStyles()
  const { userLoggedin } = useContext(useAuthContext)
  const showDialogAlert = useCustomDialogAlert()
  const [isLoading, setIsLoading] = useState(false)
  const [warehouse, setWarehouse] = useState([])
  const [valueWarehouse, setValueWarehouse] = useState({})
  const options = {
    responsive: 'scroll',
    search: false,
    filterType: 'dropdown',
    selectableRows: 'none',
    rowHover: true,
    filter: false,
    sort: false,
    print: false,
    serverSide: true,
    pagination: false,
    fixedSelectColumn: false,
    download: false,
    viewColumns: false,
    textLabels: {
      body: {
        noMatch: `No hay registros`,
      },
      pagination: {
        next: 'Página siguiente',
        previous: 'Página anterior',
        rowsPerPage: 'Filas por página:',
        displayRows: 'de',
      },
      toolbar: {
        search: 'Buscar',
        downloadCsv: 'Descargar CSV',
      },
      selectedRows: {
        text: 'Fila(s) Seleccionada(s)',
      },
    },
    downloadOptions: {
      filename: 'Productos.csv',
      separator: ',',
    },
    // customToolbarSelect: (selectedRows, displayData) => {
    //   return (
    //     <Grid container className={classes.toolBarSelect}>
    //       {/* {showMenuItemsStatu()} */}
    //       <Button
    //         color="primary"
    //         variant="contained"
    //         className={classes.btnApply}
    //         onClick={(event) => openModalCategories(event, selectedRows)}
    //       >
    //         Categorías
    //       </Button>
    //     </Grid>
    //   )
    // },
  }
  const handleNext = () => {
    const selectedCategory = listCategoriesShopify.find((category) => !category.categoryLugoPago)
    if (activeStep === 1 && selectedCategory) {
      showDialogAlert(
        true,
        '¡Advertencia!',
        `Homologa todas las categorias para continuar `,
        'Cerrar'
      )
      return
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }
  const getWarehouse = () => {
    setIsLoading(true)
    const { sellerId } = userLoggedin
    Axios.get(`${API_STORE_INFO}/Warehouse/${sellerId}`)
      .then((response) => {
        setWarehouse(response.data.data)
        setIsLoading(false)
      })
      .catch(() => {
        setIsLoading(false)
      })
  }
  const closeVariationForm = () => {
    setIsOpenPanalProducts(false)
  }
  useEffect(() => {
    getWarehouse()
  }, [])
  const defaultProps = {
    options: warehouse,
    getOptionLabel: (option) => (option.title === null ? '' : option.title),
  }
  return (
    <>
      <BackDrop open={isLoading} />
      {isOpenCategories && (
        <ModalCategoriesImport
          isOpenModalCategories={isOpenCategories}
          setIsOpenModalCategories={setIsOpenCategories}
          listCategoriesShopify={listCategoriesShopify}
          setListCategoriesShopify={setListCategoriesShopify}
          rowcategoryshopify={rowcategoryshopify}
          setRowcategoryshopify={setRowcategoryshopify}
          setDialogAlertVariation={setDialogAlertVariation}
          getAttributes={getAttributes}
          allProductShopifySelectedNum={allProductShopifySelectedNum}
          getAttributesShopify={getAttributesShopify}
        />
      )}
      {isOpenPanalProducts && (
        <PanelProducts
          defaultProps={defaultProps}
          valueWarehouse={valueWarehouse}
          setValueWarehouse={setValueWarehouse}
          onClose={closeVariationForm}
        />
      )}
      <Typography variant="subtitle1" className={classes.marginMessage}>
        Categorias: Asocia las categorías correspondientes y atributos de tus productos.
      </Typography>
      <Grid container direction="column" className={classes.divContent}>
        <div style={{ maxWidth: '100%' }}>
          <MUIDataTable
            data={listCategoriesShopify.map((item) => {
              return [
                item.shopifyCategory,
                item.categoryLugoPago
                  ? item.categoryLugoPago[item.categoryLugoPago.length - 1].name
                  : '',
              ]
            })}
            columns={columnsCategory}
            options={options}
          />
        </div>
      </Grid>
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        className={classes.divContent}
      >
        <Button
          className={classes.btnBackgroundPrimary}
          variant="contained"
          color="secondary"
          onClick={handleBack}
        >
          Anterior
        </Button>
        <Button
          className={classes.btnBackgroundPrimary}
          variant="contained"
          color="secondary"
          onClick={handleNext}
        >
          Continuar
        </Button>
        {/* <Button
          className={classes.btnBackgroundPrimary}
          variant="contained"
          color="secondary"
          onClick={saveProduct}
        >
          Guardar productos
        </Button> */}
      </Grid>
    </>
  )
}
export default CategoryShopifySync
