/* eslint-disable react/prop-types */
import React, { useState } from 'react'

import {
  Button,
  CircularProgress,
  Grid,
  InputLabel,
  TextField,
  Typography,
} from '@material-ui/core'
import { green } from '@material-ui/core/colors'
import Modal from '@material-ui/core/Modal'
import { makeStyles } from '@material-ui/core/styles'
import axios from 'axios'
import { useForm } from 'react-hook-form'

import useCustomDialogAlert from 'components/useCustomDialogAlert'
import { USER_FORGOT_PASSWORD } from 'constants/urls'

function getModalStyle() {
  const top = 40
  const left = 50

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  }
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: 530,
    height: 398,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(3, 4, 3, 4),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  form: {
    height: 160,
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    marginTop: 10,
  },
  spanError: {
    color: 'red',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  btnCancel: {
    width: 152,
    marginRight: 10,
    backgroundColor: theme.palette.grey.main,

    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  btnAceptar: {
    width: 152,
    color: theme.palette.white.main,

    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  topInputLabel: {
    marginBottom: 4,
    fontSize: 16,
  },
  text: {
    padding: '0 60px 0 60px',
    textAlign: 'center',
  },
  divButtons: {
    marginTop: 15,
  },
}))

export default function RecoveryPasswordModal({ open, setOpen }) {
  const classes = useStyles()
  const [modalStyle] = useState(getModalStyle)
  const showDialogAlert = useCustomDialogAlert()
  const { register, handleSubmit, errors } = useForm({ mode: 'onChange' })
  const [isLoading, setIsLoading] = useState(false)

  const handleClose = () => {
    setOpen(false)
  }

  const onSubmit = async (data) => {
    setIsLoading(true)

    await axios({
      url: `${USER_FORGOT_PASSWORD}`,
      method: 'post',
      data: { email: data.email },
    })
      .then(() => {
        setIsLoading(false)
        showDialogAlert(
          true,
          '¡Envío exitoso!',
          `Se envió correo para restablecer contraseña`,
          'Cerrar',
          '',
          true
        )
        handleClose()
      })
      .catch(function () {
        setIsLoading(false)
        // Asi ocurra error se debe mostrar este mensaje, el back lo solicitó
        showDialogAlert(
          true,
          '¡Envío exitoso!',
          `Se envió correo para restablecer contraseña`,
          'Cerrar',
          '',
          true
        )
        handleClose()
      })
  }

  return (
    <Modal disableBackdropClick disableEscapeKeyDown open={open} onClose={handleClose}>
      <div style={modalStyle} className={classes.paper}>
        <Typography className={classes.title} variant="h5">
          Restablece tu contraseña
        </Typography>
        <Typography className={classes.text} variant="body1">
          Ingresa el correo electrónico asociado a tu cuenta para enviarte el link y puedas
          restablecerla.
        </Typography>

        <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
          <InputLabel className={classes.topInputLabel} error={errors.email}>
            Correo electrónico
          </InputLabel>
          <TextField
            name="email"
            variant="outlined"
            error={errors.email}
            inputRef={register({
              required: 'Required',
              pattern: /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i,
            })}
          />
          {errors.email && <span className={classes.spanError}>Ingrese un correo válido</span>}
          <Grid container justify="center" className={classes.divButtons}>
            <Button
              variant="contained"
              color="secondary"
              className={classes.btnCancel}
              disabled={isLoading}
              onClick={handleClose}
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              color="secondary"
              className={classes.btnAceptar}
              type="submit"
              disabled={isLoading}
            >
              Enviar
            </Button>
          </Grid>
          {isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
        </form>
      </div>
    </Modal>
  )
}
