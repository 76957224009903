import React, { useState, useEffect } from 'react'

import { Fab, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { FormatListBulletedOutlined } from '@material-ui/icons'
import axios from 'axios'
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'

import { WhatsappIcon } from 'components/icons/personalizedIcons'
import PrivateRoute from 'components/PrivateRoute'
import { routeBase } from 'constants/environments.constants'
import { FEATURES_FLAGS } from 'constants/urls'
import { AuthContextProvider } from 'contextApi/AuthContext'
import ItinerariesContextProvider from 'contextApi/ItineraryContext'
import { ProductProvider } from 'contextApi/ProductContext'
import AdminLayout from 'layouts/Admin'
import AuthorizationShopify from 'views/AuthorizationShopify/AuthorizationShopify'
import CreateBonus from 'views/Bonus/CreateBonus'
import ThanksBonus from 'views/Bonus/ThanksBonus'
import ConfirmUser from 'views/ConfirmUser'
import CouponActivation from 'views/Coupons/CouponActivation'
import InConstruction from 'views/InConstruction'
import LoginPage from 'views/Login'
import RecoveredPassword from 'views/RecoveredPassword'
import Shopify from 'views/Shopify'
import DetailSellerStore from 'views/StoreDetail/DetailSellerStore'
import ThanksRegister from 'views/StoreDetail/ThanksRegister'
import RegisterStore from 'views/StoreRegister/RegisterStore'

const useStyles = makeStyles((theme) => ({
  marginFloat: {
    zIndex: 9999,
    background: '#4dc247',
    backgroundColor: '#4dc247',
    fill: 'white',
    position: 'fixed',
    bottom: '100px',
    left: '25px',
    width: '60px',
    height: '60px',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
}))

const App = () => {
  const NC_WEB_COMPONENT = routeBase[process.env.REACT_APP_ENVIRONMENT].NOTIFICATION_CONFIGURATOR
  const classes = useStyles()
  // link daniela restrepo 57 3156176510
  const [url, setUrl] = useState('https://wa.link/x3786z')
  const [curtains, setCurtains] = useState(false)
  const getfeatureFlag = async () => {
    await axios
      .get(`${FEATURES_FLAGS}`)
      .then((response) => {
        const dataFilter = response.data.find((data) => data.name === 'UnderConstruction')
        setCurtains(dataFilter?.value === 'true')
      })
      .catch(() => {
        setCurtains(false)
      })
  }
  useEffect(() => {
    getfeatureFlag()
    const script = document.createElement('script')
    script.src = `${NC_WEB_COMPONENT}`
    document.head.appendChild(script)
    if (window.location.pathname === '/registerstore')
      // link daniel ordoñes 57 3102665647
      setUrl('https://wa.link/zzz70s')
  }, [window.location])

  return (
    <>
      {!curtains ? (
        <>
          <Grid item xs={1}>
            <a href={url} target="_blank" rel="noreferrer">
              <Fab
                size="medium"
                color="inherit"
                aria-label="add"
                component="div"
                className={classes.marginFloat}
              >
                <WhatsappIcon />
              </Fab>
            </a>
          </Grid>
          <Router>
            <AuthContextProvider>
              <ProductProvider>
                <ItinerariesContextProvider>
                  <Switch>
                    <PrivateRoute path="/admin" component={AdminLayout} />
                    <Route path="/" exact component={LoginPage} />
                    <Route path="/login" component={LoginPage} />
                    <Route
                      path="/Account/resetpassword/:userEmail/:code"
                      component={RecoveredPassword}
                    />
                    <Route path="/Account/Confirm/:userEmail/:code" component={ConfirmUser} />
                    <Route path="/register/Detail/:email" component={DetailSellerStore} />
                    <Route path="/register/thanks" component={ThanksRegister} />
                    <Route path="/bonos/thanks" component={ThanksBonus} />
                    <Route path="/bonos/:nit" component={CreateBonus} />
                    <Route path="/shopify-authorization" component={AuthorizationShopify} />
                    <Route path="/shopify" component={Shopify} />
                    <Route path="/registerStore" component={RegisterStore} />
                    <Route path="/coupon-activation" component={CouponActivation} />
                    <Redirect from="/" to="/admin/orders" />
                  </Switch>
                </ItinerariesContextProvider>
              </ProductProvider>
            </AuthContextProvider>
          </Router>
        </>
      ) : (
        <InConstruction />
      )}
    </>
  )
}

export default App
