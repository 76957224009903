import React, { useContext, useEffect, useState } from 'react'

import { Grid } from '@material-ui/core'
import AES from 'crypto-js/aes'

import { routeBase } from 'constants/environments.constants'
import useAuthContext from 'contextApi/AuthContext'

export default function Integrations() {
  const { userLoggedin } = useContext(useAuthContext)
  const [encryptedIdSeller, setEncryptedIdSeller] = useState('')
  useEffect(() => {
    setEncryptedIdSeller(
      AES.encrypt(
        userLoggedin?.sellerId,
        routeBase[process.env.REACT_APP_ENVIRONMENT].integrations.secretKey
      )
    )
    const scriptIntegration = document.createElement('script')
    scriptIntegration.src =
      'https://luegopago.blob.core.windows.net/luegopago-uploads/Shopifywidget/DEV/index.js'
    document.body.appendChild(scriptIntegration)
  }, [])

  return (
    <Grid container direction="column" justifyContent="flex-start" alignItems="center">
      <link
        rel="stylesheet"
        href="https://luegopago.blob.core.windows.net/luegopago-uploads/Shopifywidget/DEV/index.css"
      />
      <div
        style={{ width: '100%' }}
        id="connect_widget"
        data-seller-id="U2FsdGVkX18QNOUSFZ5XbLJ3szklV0716LB0xOshGdQ="
        data-token="c2VsbGVyY2VudGVyMQ==.MTMyYjc2YjktYzgxYS00OGViLTk4NmQtZGYwMGJmMjYzZWNj"
      />
    </Grid>
  )
}
