import React, { useContext, useEffect, useState } from 'react'

import { Grid } from '@material-ui/core'
import Axios from 'axios'

import useStyles from '../generalConfig.styles'

import ListWarehouses from './warehousesComponents/ListWarehouses'
import NewWareHouseForm from './warehousesComponents/NewWarehouseForm'

import Button from 'components/CustomButtons/Button'
import useCustomDialogAlert from 'components/useCustomDialogAlert'
import { API_STORE_INFO, API_LOCATIONS } from 'constants/urls'
import useAuthContext from 'contextApi/AuthContext'

export default function Warehouses({ dataSeller, getModel, setIsLoading }) {
  const showDialogAlert = useCustomDialogAlert()
  const classes = useStyles()
  const { userLoggedin } = useContext(useAuthContext)
  const [data, setData] = useState([])
  const [selectedWarehouse, setSelectedWarehouse] = useState(null)
  const [addWarehouse, setAddWarehouse] = useState(null)

  const [options, setOptions] = useState({
    principalCountry: [],
    principalCity: [],
    principalDepartment: [],
    returnCity: [],
    returnDepartment: [],
  })

  const getCountry = async () => {
    await Axios.get(`${API_STORE_INFO}/GetCountryList`)
      .then((res) => {
        setOptions((prevState) => ({
          ...prevState,
          principalCountry: res.data,
        }))
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const getDepartments = async () => {
    await Axios.get(`${API_LOCATIONS}/Departments`)
      .then((res) => {
        setOptions((prevState) => ({
          ...prevState,
          principalDepartment: res.data,
          returnDepartment: res.data,
        }))
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const getWarehouses = async () => {
    setIsLoading(true)
    await Axios.get(`${API_STORE_INFO}/Warehouse/${userLoggedin.sellerId}`)
      .then((response) => {
        setIsLoading(false)
        setData(response.data.data)
      })
      .catch((e) => {
        setIsLoading(false)
        console.log(e)
      })
  }

  const activateAddWarehouse = () => {
    if (
      dataSeller.sellerIntegrations &&
      dataSeller.sellerIntegrations.length > 0 &&
      data.length >= 1
    ) {
      showDialogAlert(true, '¡Advertencia!', `Solo puedes tener una bodega`, 'Cerrar')
    } else {
      setSelectedWarehouse(null)
      setAddWarehouse(true)
    }
  }
  const selectWarehouse = (id) => {
    const found = data.find((element) => element.id === id)
    setAddWarehouse(null)
    setSelectedWarehouse(found)
  }

  useEffect(() => {
    setData(dataSeller.warehouses)
    getCountry()
    getDepartments()
  }, [])

  return (
    <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={3}>
      <Grid item md={12}>
        <Grid container direction="row" justify="flex-end" alignItems="stretch">
          <Button
            className={classes.btnBackgroundPrimary}
            onClick={activateAddWarehouse}
            color="primary"
            round
          >
            Añadir nueva bodega
          </Button>
        </Grid>
      </Grid>
      <Grid item xs={12} xl={6}>
        <ListWarehouses
          selectWarehouse={selectWarehouse}
          data={data}
          setData={setData}
          setIsLoading={setIsLoading}
        />
      </Grid>
      {selectedWarehouse || addWarehouse ? (
        <Grid item xs={12} xl={6}>
          <NewWareHouseForm
            setIsLoading={setIsLoading}
            selectedWarehouse={selectedWarehouse}
            sellerId={userLoggedin.sellerId}
            getModel={getModel}
            getWarehouses={getWarehouses}
            options={options}
            setOptions={setOptions}
          />
        </Grid>
      ) : null}
    </Grid>
  )
}
