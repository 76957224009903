/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'

import {
  Button,
  DialogActions,
  Grid,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
} from '@material-ui/core'
import { useForm } from 'react-hook-form'

import InputField from 'components/InputField'
import useStyles from 'views/Products/products.styles'

const SaleBasicInfo = ({ info, discountTypes, handleBack, handleNext }) => {
  const isEdit = info?.id
  const [discountType, setDiscountType] = useState(info.discountType)
  const {
    register,
    handleSubmit,
    setValue,
    errors,
    trigger,
    formState: { isValid },
  } = useForm({
    mode: 'onChange',
    defaultValues: info,
  })
  const classes = useStyles()

  const onSubmit = (data) => {
    if (isEdit) {
      const formData = {
        ...data,
        saleCode: info.saleCode,
        discountType,
        saleValue: Number(data.saleValue),
      }
      handleNext(formData)
    } else {
      const formData = {
        ...data,
        discountType,
        saleValue: Number(data.saleValue),
      }
      handleNext(formData)
    }
  }

  const loadData = () => {
    if (info.discountType) {
      setDiscountType(() => info.discountType)
      trigger()
    }
  }

  useEffect(() => {
    if (info.id) {
      loadData()
    }
  }, [info.id])

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <InputField
        label="Descripcion"
        name="saleName"
        error={errors?.saleName && errors.saleName.message}
        ref={register({ required: 'La descripción de la promoción es requerido' })}
      />
      <Grid container direction="row" spacing={3}>
        <Grid item xs={12}>
          <InputField
            label="Código de identificación"
            name="saleCode"
            disabled={isEdit}
            onBlur={(e) => {
              let code = e.target.value
              if (code.length > 0) {
                code = code.toUpperCase().replace(/\s/g, '')
                setValue('saleCode', code.toUpperCase())
                trigger('saleCode')
              }
            }}
            error={errors.saleCode && errors.saleCode.message}
            ref={register({
              validate: {
                required: (value) => value.length > 0 || 'El código de la promoción es requerida',
                noSpecialCharacters: (value) => {
                  return (
                    /^[a-zA-Z0-9]+$/.test(value) ||
                    'El código de la promoción no puede contener caracteres especiales'
                  )
                },
              },
            })}
          />
        </Grid>
      </Grid>
      <Grid container direction="row" spacing={3}>
        <Grid item xs={6}>
          <InputField label="Tipo de descuento">
            <Select
              name="discountType"
              value={discountType}
              variant="outlined"
              onChange={(event) => {
                setDiscountType(event.target.value)
                setValue('saleValue', null)
              }}
              fullWidth
            >
              {discountTypes.map((type) => (
                <MenuItem key={type.id} value={type.uniqueId}>
                  {type.name}
                </MenuItem>
              ))}
            </Select>
          </InputField>
        </Grid>
        <Grid item xs={6}>
          <InputField
            label="Valor de descuento"
            error={errors.saleValue && errors.saleValue.message}
          >
            <OutlinedInput
              startAdornment={
                discountType === 1 && <InputAdornment position="start">$</InputAdornment>
              }
              name="saleValue"
              disabled={!discountType}
              placeholder={!discountType && 'Seleccione un tipo de descuento'}
              endAdornment={
                discountType === 2 && <InputAdornment position="start">%</InputAdornment>
              }
              type="number"
              inputRef={register({
                validate: {
                  required: (value) => value > 0 || 'El valor de descuento es requerido',
                  between: (value) => {
                    if (discountType === 1) {
                      return value > 0 || 'El valor de descuento debe ser mayor a 0'
                    }
                    return (
                      (value >= 0 && value <= 100) ||
                      'El valor de descuento debe estar entre 0 y 100%'
                    )
                  },
                },
              })}
            />
          </InputField>
        </Grid>
      </Grid>
      <DialogActions>
        <Button disabled className={classes.btnBackgroundErrorPriceSmall} onClick={handleBack}>
          Atrás
        </Button>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          disabled={!isValid || !discountType}
          className={classes.btnNewProductsmall}
        >
          Siguiente
        </Button>
      </DialogActions>
    </form>
  )
}

export default SaleBasicInfo
