import axios from 'axios'

import { API_INTEGRATIONS } from 'constants/urls'

// eslint-disable-next-line import/prefer-default-export
export const getIntegrations = async (setIntegrations, setIsLoading) => {
  setIsLoading(true)
  axios
    .get(`${API_INTEGRATIONS}`)
    .then(function (response) {
      setIntegrations(response.data.data)
    })
    .catch(function (error) {
      setIsLoading(false)
      console.log(error)
    })
}
