import React, { useState } from 'react'

import { Grid, Typography, Divider } from '@material-ui/core'

import styles from './valueAssumed.styles'

const ValueAssumed = ({ tariff }) => {
  const classes = styles()

  return (
    <>
      <Grid container direction="row" justify="flex-start" alignItems="flex-start">
        <Typography variant="subtitle2" component="h2" className={classes.titleShipping}>
          Tenga en cuenta en la tarifa de envío
        </Typography>
        <Typography className={classes.infoTarif}>
          Al momento de la compra el valor de la <span className={classes.spanTarif}> tarifa tendrá adicional el 1%</span> sobre el
          valor declarado, para mensajería de <span className={classes.spanTarif}>1kg a 5 kg</span> la tarifa mínima equivale a<span className={classes.spanTarif}> $350</span>, para
          mercancía de  <span className={classes.spanTarif}>5.1 kg a 30 kg</span>  si el valor declarado (asegurado) es inferior a <span className={classes.spanTarif}>$550.000</span> la
          tarifa mínima equivale a <span className={classes.spanTarif}>$5.550</span>
        </Typography>
      </Grid>
      <Grid className={classes.gridInfo}>
        {tariff.map((index) => (
          <>
            <Grid
              container
              justify="space-between"
              alignItems="center"
              className={classes.containerGrid}
            >
              <Typography className={classes.pos}>{index.category}</Typography>
              <Typography className={classes.pos}>
                {index.minimumValue}
                {index.maximumValue && index.minimumValue !== index.maximumValue
                  ? ` - ${index.maximumValue}`
                  : null}
              </Typography>
            </Grid>
          </>
        ))}
      </Grid>
    </>
  )
}

export default ValueAssumed
