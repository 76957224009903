import { makeStyles } from '@material-ui/core/styles'

import styles from 'assets/jss/material-dashboard-pro-react/views/dashboardStyle'

export default makeStyles((theme) => ({
  ...styles,
  ordenItems: {
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  buttonHoverRed: {
    minWidth: 180,
    height: '56px',
    borderRadius: '50px',
    border: '1px solid #6064ff',
    color: '#6064ff',
    fontWeight: '500',
    backgroundColor: '#ffffff',
    '&:hover': {
      color: '#FF40FC',
      border: '1px solid #FF40FC',
      backgroundColor: '#ffffff',
    },
  },
  buttonshoverGreen: {
    minWidth: 180,
    height: '56px',
    borderRadius: '50px',
    border: 'none',
    backgroundColor: '#5e44ff',
    color: 'white',
    fontWeight: '500',
    '&:hover': {
      fontWeight: 'bolder',
      backgroundColor: '#ef0bef',
      boxShadow: '-7px 7px 0px #000000',
      border: '2px solid #000000',
      borderRadius: '50px',
      opacity: '1',
    },
  },
  marginContentContent: {
    marginTop: '1vh',
    marginBottom: '1vh',
  },
  marginTitleContent: {
    marginTop: '1vh',
    paddingLeft: '14px',
  },
  divCancelOptions: {
    margin: '2.5%',
    overflowY: 'auto',
    maxHeight: '418px',
    display: 'grid',
    width: '100%',
    gridTemplateColumns: 'repeat(1, 1fr)',
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: 'repeat(1, 1fr)',
      overflowY: 'unset',
    },
  },
  reason: {
    borderRadius: 6,
    minHeight: 240,
    maxHeight: 240,
    overflow: 'auto !important',
    width: '100%',
    marginTop: 5,
    resize: 'none',
  },
  contentGuide: {
    overflowX: 'hidden',
  },
}))
