import React from 'react'

import { Grid, Typography } from '@material-ui/core'

import styles from '../generalConfig.styles'

import { LuegopagoIconWhite } from 'components/icons/personalizedIcons'

const Footer = ({ email }) => {
  const classes = styles()
  return (
    <Grid container direction="column" justifyContent="space-between" alignItems="center">
      <div className={classes.LogoFooter}>{email && <LuegopagoIconWhite black /> }</div>
      <Typography className={classes.termsAndConditionsFooter}>
        Términos y condiciones | Ayuda/PQR | Contacto Copyright © 2021 LuegopaGO.com
      </Typography>
    </Grid>
  )
}

export default Footer
