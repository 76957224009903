import React, { useState, useEffect, useContext } from 'react'

import { Button, Grid, Link, Typography } from '@material-ui/core'
import Axios from 'axios'
import { useRouteMatch } from 'react-router-dom'

import ModalCategoriesImport from './ModalCategoriesImport'
import useStyles from './uploads.styles'

import BackDrop from 'components/BackDrop'
import DialogAlert from 'components/DialogAlert'
import DropZone from 'components/DropZone'
import {
  API_STORE_INFO,
  API_UPLOAD_PRODUCTS,
  // API_UPDATE_PRODUCTS,
  API_PRODUCTS_UPDATE_BY_FILE,
  MICROSERVICES_PRODUCTS,
} from 'constants/urls'
import useAuthContext from 'contextApi/AuthContext'
import { useProduct } from 'contextApi/ProductContext'
import { getUnique, hideEmail } from 'utils/utils'

export default function UploadProducts() {
  const isUpdateProduct = useRouteMatch('/admin/update-products')?.isExact
  const [loading, setLoading] = useState(false)
  const { userLoggedin } = useContext(useAuthContext)
  const { product, handleCancel, warehouses, setWarehouses } = useProduct()

  const [IsOpenModalCategories, setIsOpenModalCategories] = useState(false)
  const [files, setFiles] = useState([])
  const [anyFileLoaded, setAnyFileLoaded] = useState(false)
  const classes = useStyles()
  const [dialogAlert, setDialogAlert] = useState({
    open: false,
    title: '',
    message: '',
    actionConfirm: null,
    textButtonConfirm: '',
    successful: false,
  })
  const handleChangeTemplateSent = () => {
    setDialogAlert({
      open: false,
    })
  }
  const exportAllProducts = async () => {
    try {
      setLoading(true)
      const response = await Axios({
        url: `${MICROSERVICES_PRODUCTS}/downloadFile`,
        method: 'GET',
        responseType: 'blob',
      })
      if (response) {
        if (response.status === 200) {
          console.log('response', response)
          setDialogAlert({
            open: true,
            title: 'Plantilla enviada',
            message: (
              <p>
                Hemos enviado a tu correo electrónico
{' '}
                <Typography variant="subtitle3">{hideEmail(userLoggedin.email)}</Typography>
{' '}
la
                plantilla para que puedas cargar tus productos.
</p>
            ),
            actionConfirm: () => handleChangeTemplateSent(),
            textButtonConfirm: 'Cerrar',
          })
        }

        setLoading(false)
      }
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  const openModalCategories = (event) => {
    event.preventDefault()
    product.categories = []
    if (isUpdateProduct) {
      exportAllProducts()
      return true
    }
    setIsOpenModalCategories(true)
  }

  const getWarehouse = async () => {
    setLoading(true)
    const { sellerId } = userLoggedin
    await Axios.get(`${API_STORE_INFO}/Warehouse/${sellerId}`)
      .then((response) => {
        if (response) {
          setLoading(false)
          setWarehouses(response.data.data) // Save warehouses in product context
        }
      })
      .catch(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    if (warehouses.length === 0) {
      getWarehouse()
    }
  }, [])

  const handleCloseDialog = () => {
    setDialogAlert({ ...dialogAlert, open: false })
  }

  const validarArchivos = (archivos) => {
    if (files.length === 1 && archivos.length > 0) {
      return setDialogAlert({
        open: true,
        title: '¡Advertencia!',
        message: `Ha excedido el número máximo de plantillas.`,
        successful: false,
        actionConfirm: () => {
          handleCloseDialog()
        },
        textButtonConfirm: 'Cerrar',
      })
    }
    const archivosSinDuplicar = getUnique([...archivos, ...files], 'path')
    setFiles(archivosSinDuplicar)
  }

  const onSubmit = async (event) => {
    event.preventDefault()
    if (
      !files.length ||
      !files.some((file) => {
        const fileSplit = file.name.split('.')
        return fileSplit[fileSplit.length - 1].toLowerCase() === 'xlsx'
      })
    ) {
      setDialogAlert({
        open: true,
        title: '¡Advertencia!',
        message: `Debes agregar mínimo un archivo .xlsx.`,
        successful: false,
        actionConfirm: () => {
          handleCloseDialog()
        },

        textButtonConfirm: 'Cerrar',
      })
      return
    }
    setLoading(true)
    const formData = new FormData()
    formData.append('file', files[0])
    try {
      const respuesta = await Axios({
        // Antiguo endpoint para actualizar productos: API_UPDATE_PRODUCTS
        url: isUpdateProduct ? API_PRODUCTS_UPDATE_BY_FILE : API_UPLOAD_PRODUCTS,
        method: 'post',
        data: formData,
        timeout: 60 * 1000 * 120, // 120 minutos antes de finalizar la petición
        config: {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Access-Control-Allow-Origin': '*',
          },
        },
      })
      if (respuesta) {
        setLoading(false)
      }
      console.log('Respuesta importación productos: ', respuesta)

      if (respuesta.status === 201 && respuesta.data.data && respuesta.data.data !== '') {
        setAnyFileLoaded(true)
        setDialogAlert({
          open: true,
          title: '¡Carga en proceso!',
          message: 'Tus productos se están procesando',
          actionConfirm: () => {
            handleCloseDialog()
          },
          textButtonConfirm: 'Cerrar',
          successful: true,
        })
        setFiles([])
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
    }
  }

  return (
    <>
      <DialogAlert
        open={dialogAlert.open}
        title={dialogAlert.title}
        message={dialogAlert.message}
        handleClose={handleCloseDialog}
        textButtonConfirm={dialogAlert.textButtonConfirm}
        actionConfirm={dialogAlert.actionConfirm}
        successful={dialogAlert.successful}
      />
      <form noValidate autoComplete="off" onSubmit={onSubmit}>
        <BackDrop open={loading} />
        <Typography variant="subtitle1" align="left" style={{ marginBottom: 20 }}>
          {isUpdateProduct ? 'Actualizar productos' : 'Importar productos'}
        </Typography>

        <Grid container direction="row" justify="center" alignItems="center">
          <Grid item sm={6}>
            <Grid container direction="column" justify="center">
              <DropZone
                archivos={files}
                setArchivos={setFiles}
                loading={loading}
                validarArchivos={validarArchivos}
              />

              <Grid
                container
                direction="row"
                style={{ marginBottom: '7%' }}
                justify="space-between"
                alignItems="center"
              >
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={(event) => openModalCategories(event)}
                  className={classes.btnDownAndChargeFile}
                >
                  {isUpdateProduct ? 'Enviar plantilla' : 'Descargar plantilla'}
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  type="submit"
                  className={classes.btnDownAndChargeFile}
                  disabled={loading}
                >
                  Cargar archivo
                </Button>
              </Grid>
              <Typography variant="body1" className={classes.infoToLoadImages}>
                Para cargar las imágenes asociadas a los productos relacionados en el archivo,
                recomendamos subirlas en el siguiente enlace:
{' '}
                <>
                  <Link
                    color="primary"
                    underline="always"
                    className={classes.redirectLink}
                    href="https://postimages.org/es/"
                    target="blank"
                  >
                    Gestión de Imágenes
                  </Link>
                </>
              </Typography>
              <Grid
                container
                direction="row"
                style={{ marginTop: '5%', marginBottom: '7%' }}
                justify="center"
                alignItems="center"
              >
                {anyFileLoaded ? (
                  <Button
                    variant="contained"
                    className={classes.btnGoToProdcuts}
                    onClick={handleCancel}
                  >
                    Ir a productos
                  </Button>
                ) : null}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
      {IsOpenModalCategories && (
        <ModalCategoriesImport
          isOpenModalCategories={IsOpenModalCategories}
          setIsOpenModalCategories={setIsOpenModalCategories}
        />
      )}
    </>
  )
}
