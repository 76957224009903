import React, { useState } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { FormGroup, Grid, InputLabel, TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'

import Button from 'components/CustomButtons/Button'
import useCustomDialogAlert from 'components/useCustomDialogAlert'

const useStyles = makeStyles((theme) => ({
  gridButtons: {
    marginTop: 20,
    gap: '20px',
  },
  btnBackgroundError: {
    background: theme.palette.secondary.main,
    width: 150,
    height: 35,
    borderRadius: 6,

    '&:hover': {
      background: theme.palette.error.main,
    },
  },
  btnBackgroundPrimary: {
    background: theme.palette.secondary.main,
    width: 150,
    height: 35,
    borderRadius: 6,

    '&:hover': {
      background: theme.palette.primary.main,
    },
  },
}))

const schema = yup.object().shape({
  name: yup.string().required('Campo requerido'),
})

function ModalCreatePlatform(props) {
  const { getNewPlatform, onCancel } = props
  const classes = useStyles()
  const showDialogAlert = useCustomDialogAlert()
  const [platform, setplatform] = useState({ id: '', name: '' })

  const { register, handleSubmit, errors } = useForm({
    defaultValues: {
      id: platform.id,
      name: platform.name,
    },
    mode: 'onChange',
    resolver: yupResolver(schema),
  })

  const onSubmit = () => {
    if (platform.name === '') {
      showDialogAlert(true, '¡Advertencia!', `Llena todos los campos`, 'Cerrar')
      return false
    }
    if (platform.name.length > 55) {
      showDialogAlert(
        true,
        '¡Advertencia!',
        `La nombre debe de tener menos de 55 caracteres`,
        'Cerrar'
      )
      return false
    }
    getNewPlatform(platform)
    return true
  }
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container direction="column">
        <FormGroup>
          <InputLabel>Plataforma:</InputLabel>
          <TextField
            id="name"
            value={platform.name}
            variant="outlined"
            inputRef={register}
            name="name"
            error={!!errors.name}
            helperText={errors?.name?.message}
            onChange={(event) =>
              setplatform({
                ...platform,
                name: event.target.value,
              })
            }
          />
        </FormGroup>
      </Grid>
      <Grid container justify="center" className={classes.gridButtons}>
        <Button color="error" onClick={onCancel}>
          Cancelar
        </Button>
        <Button color="secondary" type="submit">
          Guardar
        </Button>
      </Grid>
    </form>
  )
}

export default ModalCreatePlatform
