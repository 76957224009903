/* eslint-disable react/prop-types */
import React, { useEffect } from 'react'

import {
  FormControl,
  FormGroup,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Button,
} from '@material-ui/core'

import useStyles from '../../../products.styles'

import { useProduct } from 'contextApi/ProductContext'

const AttributesVariant = ({
  variation,
  setVariation,
  fieldValidation,
  setFieldValidation,
  changeTabs,
  onClose,
}) => {
  const classes = useStyles()
  const { product, isApprove } = useProduct()
  const attributesCheckedsOptions = product.categoriesWithAtributtes[
    product.categoriesWithAtributtes.length - 1
  ].atributtes.filter((a) => a.isChecked === true)
  useEffect(() => {
    if (variation.type === 'edit' && variation.attributes.length > 0) {
      variation.attributes.map((itemSelect) => {
        const tempVal = fieldValidation
        const notHaveOption = itemSelect.value?.length === 0
        const existProperty = Object.keys(tempVal).find((value) => value === itemSelect.name)
        if (!existProperty && !notHaveOption) {
          tempVal[itemSelect.name] = false
        } else if (existProperty === itemSelect.name && !notHaveOption) {
          tempVal[itemSelect.name] = false
        } else if (!existProperty && notHaveOption) {
          tempVal[itemSelect.name] = true
        }
        setFieldValidation(tempVal)
        return false
      })
    } else {
      product.attributes.map((item) => {
        const tempVal = fieldValidation
        const notHaveOption = item.value?.length === 0
        const existProperty = Object.keys(tempVal).find((value) => value === item.name)
        if (!existProperty && !notHaveOption) {
          tempVal[item.name] = true
        } else if (existProperty === item.name && !notHaveOption) {
          tempVal[item.name] = false
        } else if (!existProperty && notHaveOption) {
          tempVal[item.name] = true
        }
        setFieldValidation(tempVal)
        return false
      })
    }
  }, [product.attributes])

  const handleChange = (e) => {
    const temp = { ...variation }
    // let tempSelect = null
    const index = temp.attributes.findIndex((attr) => attr.name === e.target.name)

    if (index !== -1) temp.attributes.splice(index, 1)

    temp.attributes.push({
      // id: parseInt(e.target.name),
      // name: tempSelect,
      name: e.target.name,
      value: [e.target.value],
    })
    temp.attributes.map(() => {
      const tempVal = fieldValidation
      // tempVal[tempSelect] = e.target.value === ''
      tempVal[e.target.name] = e.target.value === ''
      setFieldValidation(tempVal)
    })

    setVariation(temp)
    // }
  }

  return (
    <>
      <Grid container justify="flex-start" alignItems="flex-start">
        <Grid className={classes.divAtributtesForm}>
          {attributesCheckedsOptions &&
            attributesCheckedsOptions.map((item) => {
              const valueResult = variation.attributes.find((attr) => attr.name === item.name)
              return (
                <FormGroup style={{ width: '80%' }}>
                  <InputLabel
                    error={fieldValidation[item.name] || false}
                    className={classes.inputName}
                  >
                    {item.name}
                  </InputLabel>
                  {item.value &&
                    (item.value.length === 0 ? (
                      <TextField
                        fullWidth
                        id={item.name}
                        name={item.name}
                        key={item.name}
                        onChange={handleChange}
                        error={fieldValidation[item.name] || false}
                        variant="outlined"
                        disabled={isApprove}
                        defaultValue={valueResult?.value ? valueResult?.value[0] : ''}
                      />
                    ) : (
                      <FormControl variant="outlined" error={fieldValidation[item.name]}>
                        <Select
                          id={`${item.name}`}
                          name={`${item.name}`}
                          key={item.name}
                          onChange={handleChange}
                          disabled={isApprove}
                          value={valueResult?.value.length > 0 ? valueResult?.value[0] : undefined}
                        >
                          {item.value.map((label, index) => {
                            return (
                              <MenuItem key={index} value={label}>
                                {label}
                              </MenuItem>
                            )
                          })}
                        </Select>
                        {fieldValidation[item.name] && (
                          <FormHelperText>Campo obligatorio</FormHelperText>
                        )}
                      </FormControl>
                    ))}
                </FormGroup>
              )
            })}
        </Grid>
      </Grid>
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        className={classes.gridButtonsInfoBasic}
      >
        <Button
          className={classes.btnBackgroundPrimary}
          variant="contained"
          color="secondary"
          onClick={() => changeTabs(0)}
        >
          Anterior
        </Button>
        <Button className={classes.btnBackgroundError} onClick={() => onClose()}>
          Cancelar
        </Button>
        <Button
          className={classes.btnBackgroundPrimary}
          variant="contained"
          color="secondary"
          type="submit"
          onClick={() => changeTabs(2)}
        >
          Continuar
        </Button>
      </Grid>
    </>
  )
}

export default AttributesVariant
