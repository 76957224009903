import React, { useState } from 'react'

import {
  Grid,
  Typography,
  Button,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
} from '@material-ui/core'
import Axios from 'axios'

import BoxCertificate from '../../StoreDetail/components/BoxCertificate'
import styles from '../generalConfig.styles'

import DialogAlert from 'components/DialogAlert'
import { API_STORE_INFO } from 'constants/urls'

const FILES_ACCEPTED = 'application/pdf, image/png, image/jpg, image/jpeg'

const CertificatesStep = ({
  setData,
  data,
  setIsLoading,
  sellerId,
  onChangetabs,
  isRegistered,
  getModel,
}) => {
  const classes = styles()
  const [dialogAlert, setDialogAlert] = useState({
    open: false,
    title: '',
    message: '',
    actionConfirm: null,
    textButtonConfirm: '',
    textButtonCancel: '',
    successful: false,
  })

  const [errorsCertificates, setErrorsCertificates] = useState({
    fileRut: null,
    fileCommercialRegister: null,
    fileLegRepDocumentCopy: null,
    fileBankCertificate: null,
  })
  const [opeModalDeclaration, setOpenModalDeclaration] = useState(false)
  const [checkDeclaration, setCheckDeclaration] = useState(false)

  const validateTypeFile = (type) => {
    const regexFileType = /application\/(pdf)|image\/(png|jpe?g)/g
    if (!regexFileType.test(type)) {
      setDialogAlert({
        ...dialogAlert,
        open: true,
        title: '¡Advertencia!',
        message: `Sólo se permiten archivos .pdf, .png, .jpg, .jpeg`,
        textButtonCancel: 'Cerrar',
      })
    }
    return false
  }

  const validateNameFile = (name) => {
    const regexFileType = /[^A-Za-z0-9-_". ]/g

    if (regexFileType.test(name)) {
      setDialogAlert({
        ...dialogAlert,
        open: true,
        title: '¡Advertencia!',
        message: `Sólo se permiten archivos sin caracteres especiales.`,
        textButtonCancel: 'Cerrar',
      })
    }
    return false
  }

  const uploadRUT = (e) => {
    const certificate = e.target.files
    if (!certificate[0]) {
      setDialogAlert({
        ...dialogAlert,
        open: true,
        title: '¡Advertencia!',
        message: `Debes seleccionar un archivo`,
        textButtonCancel: 'Cerrar',
      })
      return true
    }
    validateTypeFile(certificate[0].type)
    validateNameFile(certificate[0].name)

    setData({
      ...data,
      nameRut: certificate[0].name,
      fileRut: certificate[0],
    })
    setErrorsCertificates({ ...errorsCertificates, fileRut: null })
  }
  const onClose = () => {
    onChangetabs(0)
  }

  const uploadCommercialRegister = (e) => {
    const certificate = e.target.files
    if (!certificate[0]) {
      setDialogAlert({
        ...dialogAlert,
        open: true,
        title: '¡Advertencia!',
        message: `Debes seleccionar un archivo`,
        textButtonCancel: 'Cerrar',
      })
      return true
    }
    validateTypeFile(certificate[0].type)
    validateNameFile(certificate[0].name)

    setData({
      ...data,
      nameCommercialRegister: certificate[0].name,
      fileCommercialRegister: certificate[0],
    })
    setErrorsCertificates({ ...errorsCertificates, fileCommercialRegister: null })
  }
  const uploadLegRepDocumentCopy = (e) => {
    const certificate = e.target.files
    if (!certificate[0]) {
      setDialogAlert({
        ...dialogAlert,
        open: true,
        title: '¡Advertencia!',
        message: `Debes seleccionar un archivo`,
        textButtonCancel: 'Cerrar',
      })
      return true
    }
    validateTypeFile(certificate[0].type)
    validateNameFile(certificate[0].name)

    setData({
      ...data,
      nameLegRepDocumentCopy: certificate[0].name,
      fileLegRepDocumentCopy: certificate[0],
    })
    setErrorsCertificates({ ...errorsCertificates, fileLegRepDocumentCopy: null })
  }
  const uploadFileBankCertificate = (e) => {
    const certificate = e.target.files
    if (!certificate[0]) {
      setDialogAlert({
        ...dialogAlert,
        open: true,
        title: '¡Advertencia!',
        message: `Debes seleccionar un archivo`,
        textButtonCancel: 'Cerrar',
      })
      return true
    }
    validateTypeFile(certificate[0].type)
    validateNameFile(certificate[0].name)

    setData({
      ...data,
      nameBankCertificate: certificate[0].name,
      fileBankCertificate: certificate[0],
    })
    setErrorsCertificates({ ...errorsCertificates, fileBankCertificate: null })
  }
  const handleCloseDialogAlert = () => {
    setDialogAlert({ ...dialogAlert, open: false })
  }
  const closeModalDeclaration = () => {
    setOpenModalDeclaration(false)
  }
  const handleChangeDeclaration = (event) => {
    setCheckDeclaration(event.target.checked)
  }
  const handleChange = () => {
    setOpenModalDeclaration(true)
  }

  const save = async () => {
    const formData = new FormData()

    setIsLoading(true)
    formData.append('sellerId', sellerId)
    formData.append('FileRut', data.fileRut)
    formData.append('fileBankCertificate', data.fileBankCertificate)
    formData.append('FileLegRepDocumentCopy', data.fileLegRepDocumentCopy)
    formData.append('FileCommercialRegister', data.fileCommercialRegister)
    try {
      const response = await Axios({
        url: `${API_STORE_INFO}`,
        method: !isRegistered ? 'post' : 'put',
        data: formData,

        config: {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      })
      if (response) {
        setIsLoading(false)
        if (response.status === 200) {
          setDialogAlert({
            ...dialogAlert,
            open: true,
            title: '¡Cambio exitoso!',
            message: `Se ha guardado correctamente la información`,
            textButtonCancel: 'Cerrar',
          })
          getModel()
        }
      }
    } catch (error) {
      setIsLoading(false)
      console.error(error)
    }

    return true
  }

  const onSubmit = () => {
    if (
      (!data?.fileRut && !data?.urlRut) ||
      (data.personalData?.typeOfPersonId === 18 &&
        !data?.fileCommercialRegister &&
        !data?.urlCommercialRegister) ||
      (!data?.fileLegRepDocumentCopy && !data?.urlLegRepDocumentCopy) ||
      (!data?.fileBankCertificate && !data?.bankCertificate)
    ) {
      setErrorsCertificates(
        data?.personalData?.typeOfPersonId === 18
          ? {
              fileRut: !data?.fileRut && !data?.urlRut,
              fileLegRepDocumentCopy: !data?.fileLegRepDocumentCopy && !data?.urlLegRepDocumentCopy,
              fileBankCertificate: !data?.fileBankCertificate && !data?.bankCertificate,
            }
          : {
              fileRut: !data?.fileRut && !data?.urlRut,
              fileCommercialRegister: !data?.fileCommercialRegister && !data?.urlCommercialRegister,
              fileLegRepDocumentCopy: !data?.fileLegRepDocumentCopy && !data?.urlLegRepDocumentCopy,
              fileBankCertificate: !data?.fileBankCertificate && !data?.bankCertificate,
            }
      )
      setDialogAlert({
        ...dialogAlert,
        open: true,
        title: '¡Advertencia!',
        message: `Los archivos son obligatorios.`,
        textButtonCancel: 'Cerrar',
      })
    } else {
      save()
    }
  }

  return (
    <>
      <DialogAlert
        open={dialogAlert.open}
        title={dialogAlert.title}
        handleClose={handleCloseDialogAlert}
        message={dialogAlert.message}
        textButtonConfirm={dialogAlert.textButtonConfirm}
        actionConfirm={dialogAlert.actionConfirm}
        textButtonCancel={dialogAlert.textButtonCancel}
        successful={dialogAlert.successful}
      />
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        fullWidth
        maxWidth="xl"
        classes={{ paperWidthXl: classes.modalVariationForm }}
        onClose={closeModalDeclaration}
        open={opeModalDeclaration}
      >
        <DialogTitle className={classes.title} disableTypography>
          <Typography variant="h6" style={{ textAlign: 'center' }}>
            Declaración de fondos
          </Typography>
        </DialogTitle>
        <DialogContent className={classes.variantContent}>
          <Grid container alignItems="center" style={{ marginTop: 10 }}>
            <Typography variant="paragraph">
              Declaro de manera voluntaria y dando certeza de que lo aquí consignado es información
              veraz y verificable, lo siguiente : (I) Que los ingresos y los recursos utilizados en
              la relación comercial no proviene de ninguna actividad ilícita de las contempladas en
              el código penal colombiano o en cualquier otra norma que lo modifique o adicione, ni
              serán utilizados para efectos de financiación de actividades terroristas o cualquier
              otra conducta delictiva, de acuerdo con las normas penales vigentes en Colombia, (II)
              Que los socios y administradores de la sociedad no han sido incluidos en listas de
              control de riesgo de lavado de activos y financiación del terrorismo nacionales o
              internacionales, entre las que se encuentran la lista de la Oficina de Control de
              Activos en el Exterior-OFAC emitida por la oficina del tesoro de los estados Unidos de
              América y la lista de sanciones del Consejo de Seguridad de la organización de las
              Naciones Unidas, entre otras; (III) Que no se realizaran transacciones con terceros
              provenientes de actividades ilícitas que involucren a los destinatarios dela presente
              declaración. Autorizo a LUEGOPAGO.COM S.A.S a realizar consultas a través de cualquier
              medio, por sí mismo a través de un proveedor, para efectuar las verificaciones
              necesarias con el fin de corroborar la información aquí consignada tanto a la compañía
              que represento, como a todos los miembros y administradores.
            </Typography>
            <Grid container justify="center" className={classes.gridButtonsInfoBasic}>
              <Button
                className={classes.btnBackgroundDeclaration}
                variant="contained"
                color="secondary"
                type="submit"
                onClick={() => closeModalDeclaration()}
              >
                Aceptar
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>

      <Grid container item md={12}>
        <Typography variant="subtitle1">Adjunta los siguientes documentos</Typography>
      </Grid>

      <Grid
        container
        direction="column"
        GridJustification="flex-start"
        spacing={3}
        xs={12}
        sm={10}
        lg={6}
        style={{ marginTop: '3%', marginBottom: '3%' }}
      >
        <Grid item md={12}>
          <BoxCertificate
            error={errorsCertificates?.fileRut}
            title="RUT (Vigente por 1 año)"
            certificateSaved={data.urlRut}
            certificateSelected={data.nameRut}
            selectFile={() => document.getElementById('fileRut').click()}
          />

          <input
            accept={FILES_ACCEPTED}
            className={classes.input}
            onChange={uploadRUT}
            id="fileRut"
            name="fileRut"
            type="file"
            hidden
          />
        </Grid>
        <>
          <Grid item md={12}>
            <BoxCertificate
              error={errorsCertificates?.fileCommercialRegister}
              title={
                data?.personalData?.typeOfPersonId === 18
                  ? 'Cámara de comercio (Si aplica, No mayor a 30 días)'
                  : 'Cámara de comercio (No mayor a 30 días)'
              }
              certificateSaved={data.urlCommercialRegister}
              certificateSelected={data.fileCommercialRegister}
              selectFile={() => document.getElementById('fileCommercialRegister').click()}
            />

            <input
              accept={FILES_ACCEPTED}
              className={classes.input}
              onChange={uploadCommercialRegister}
              id="fileCommercialRegister"
              name="fileCommercialRegister"
              type="file"
              hidden
            />
          </Grid>

          <Grid item md={12}>
            <BoxCertificate
              error={errorsCertificates?.fileLegRepDocumentCopy}
              title={
                data?.personalData?.typeOfPersonId === 18
                  ? 'Copia del documento'
                  : 'Copia documento representante legal'
              }
              certificateSaved={data.urlLegRepDocumentCopy}
              certificateSelected={data.fileLegRepDocumentCopy}
              selectFile={() => document.getElementById('fileLegRepDocumentCopy').click()}
            />
            <input
              accept={FILES_ACCEPTED}
              className={classes.input}
              onChange={uploadLegRepDocumentCopy}
              id="fileLegRepDocumentCopy"
              name="fileLegRepDocumentCopy"
              type="file"
              hidden
            />
          </Grid>
        </>

        <Grid item md={12}>
          <BoxCertificate
            error={errorsCertificates?.fileBankCertificate}
            title="Certificado bancario"
            certificateSaved={data.bankCertificate}
            certificateSelected={data.fileBankCertificate}
            selectFile={() => document.getElementById('fileBankCertificate').click()}
          />
          <input
            accept={FILES_ACCEPTED}
            className={classes.input}
            onChange={uploadFileBankCertificate}
            id="fileBankCertificate"
            name="fileBankCertificate"
            type="file"
            hidden
          />
        </Grid>
        <Grid item xs={12}>
          <FormGroup className={classes.formDeclaration}>
            <FormControlLabel
              className={classes.formInputLabel}
              control={<Checkbox checked={checkDeclaration} onChange={handleChangeDeclaration} />}
            />
            <a className={classes.formPop} onClick={handleChange}>
              Aceptación de declaración de fondos
            </a>
          </FormGroup>
        </Grid>
      </Grid>

      <Grid
        container
        justify="center"
        alignItems="center"
        md={12}
        className={classes.divButtonsGeneral}
      >
        <Button onClick={() => onClose()} className={classes.buttonCancel}>
          Cancelar
        </Button>
        <Button
          className={classes.buttons}
          onClick={onSubmit}
          variant="contained"
          color="secondary"
          disabled={!checkDeclaration}
        >
          Guardar
        </Button>
      </Grid>
    </>
  )
}

export default CertificatesStep
