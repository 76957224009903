import React, { useState } from 'react'

import { Grid, Typography, Button, FormGroup, InputLabel } from '@material-ui/core'

import useStyles from '../products.styles'

import DialogAlert from 'components/DialogAlert'

const LoadProducts = ({ saveProduct, allProductShopifySelectedNum, exportProduct }) => {
  const classes = useStyles()
  const [dialogAlertTemplate, setDialogAlertTemplate] = useState({
    open: false,
    title: '',
    message: '',
    actionConfirm: null,
    textButtonConfirm: '',
    textButtonCancel: '',
  })
  const handleCloseDialogAlert = () => {
    setDialogAlertTemplate({ ...dialogAlertTemplate, open: false })
  }
  return (
    <>
      <DialogAlert
        open={dialogAlertTemplate.open}
        title={dialogAlertTemplate.title}
        handleClose={handleCloseDialogAlert}
        personalized
        message={dialogAlertTemplate.message}
        textButtonConfirm={dialogAlertTemplate.textButtonConfirm}
        actionConfirm={dialogAlertTemplate.actionConfirm}
        textButtonCancel={dialogAlertTemplate.textButtonCancel}
      >
        <Grid container direction="column" justifyContent="flex-start" alignItems="center">
          <Typography variant="h5" className={classes.loadSpacing}>
            ¡Carga exitosa!
          </Typography>
          <Typography variant="body1" className={classes.loadSpacing}>
            Se sincronizó correctamente los productos desde shopify
          </Typography>
          <Button
            className={classes.btnTemplate}
            variant="contained"
            color="secondary"
            type="submit"
            onClick={exportProduct}
          >
            Descargar plantilla
          </Button>
        </Grid>
      </DialogAlert>
      <Grid container direction="column" justifyContent="flex-start" alignItems="center">
        <Typography variant="subtitle1" className={classes.loadSpacing}>
          Listado de productos desde shopify
        </Typography>
        <Typography variant="body1" className={classes.loadSpacing}>
          Se va a sincronizar {allProductShopifySelectedNum.length} productos
        </Typography>
        <Button
          className={classes.btnBackgroundPrimary}
          variant="contained"
          color="secondary"
          onClick={saveProduct}
        >
          Sincronizar productos
        </Button>
      </Grid>
    </>
  )
}
export default LoadProducts
