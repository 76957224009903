import React from 'react'

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  IconContent: {
    zIndex: 10,
    position: 'absolute',
    width: '30px',
    height: '30px',
    left: '2px',
    top: '-3px',
    borderRadius: '100%',
  },
}))

const IconErrorImages = ({ children }) => {
  const classes = useStyles()

  return <div className={classes.IconContent}>{children}</div>
}

export default IconErrorImages
