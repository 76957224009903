const getOptionsTables = (
  pagination,
  total,
  isLoading,
  setAllRowsSelectedContext,
  setTypeAction,
  allRowsSelectedContext,
  changePageTable,
  changePerPageTable
) => {
  return {
    responsive: 'scroll',
    search: false,
    filterType: 'dropdown',
    selectableRows: 'multiple',
    rowHover: true,
    filter: false,
    sort: false,
    print: false,
    rowsPerPage: pagination.perPage,
    page: pagination.page,
    serverSide: true,
    count: total,
    fixedSelectColumn: false,
    download: false,
    viewColumns: false,
    textLabels: {
      body: {
        noMatch: `${isLoading ? 'Cargando ...' : 'No hay registros'}`,
      },
      pagination: {
        next: 'Página',
        previous: 'Páginaina siguie anterior',
        rowsPerPage: 'Filas por página:',
        displayRows: 'de',
      },
      selectedRows: {
        text: 'Fila(s) Seleccionada(s)',
      },
    },
    onRowSelectionChange: (currentRowsSelected, allRowsSelected, rowsSelected) => {
      if (rowsSelected.length === 0) setTypeAction(0)
      setAllRowsSelectedContext(rowsSelected)
    },
    rowsSelected: allRowsSelectedContext,
    onTableChange: (action, tableState) => {
      switch (action) {
        case 'changePage':
          changePageTable(tableState.page === 0 ? 1 : tableState.page)
          break
        case 'changeRowsPerPage':
          changePerPageTable(tableState.rowsPerPage)
          break
        default:
          break
      }
    },
  }
}

export default getOptionsTables
