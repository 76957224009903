import React, { useState, useEffect } from 'react'

import DateFnsUtils from '@date-io/date-fns'
import {
  Button,
  FormControl,
  FormGroup,
  Grid,
  InputLabel,
  InputAdornment,
  Typography,
  MenuItem,
  Select,
  TextField,
  Card,
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import axios from 'axios'
import { es } from 'date-fns/locale'
import moment from 'moment'
import { useForm } from 'react-hook-form'

import useStyles from './transactionStyle'

import BackDrop from 'components/BackDrop'
import DialogAlert from 'components/DialogAlert'
import { CalendarIcon } from 'components/icons/personalizedIcons'

import {
  refreshTokenConciliator,
  getTokenConciliator,
  getConciliatorInform,
  reg,
} from 'services/conciliator/conciliator.service'
const FormDateConciliator = () => {
  const classes = useStyles()

  const [formDataAccounts, setFormDataAccounts] = useState({
    searchParam: '',
    startDate: '',
    endDate: '',
  })

  const [dateError, setDateError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [message, setMessage] = useState('')
  const [searchParam, setSearchParam] = useState([
    {
      nameOfParam: 'ordenes no generadas',
      searchParam: 'ordersNotGenerated',
    },
    {
      nameOfParam: 'ordenes fallidas',
      searchParam: 'OrderFail',
    },
  ])
  const [dialogData, setDialogData] = useState({
    open: false,
    title: '',
    message: '',
    actionConfirm: null,
    textButtonConfirm: '',
    textButtonCancel: '',
  })
  const updateTokenConciliator = () => {
    refreshTokenConciliator().then((t) => {
      localStorage.setItem('conciliator_token', t.data.data.token)
    })
  }

  useEffect(() => {
    console.log('getTokenConciliator', getTokenConciliator)
    updateTokenConciliator()
  }, [])

  const { handleSubmit } = useForm()

  const handleCloseDialogData = () => {
    setDialogData({ ...dialogData, open: false })
    setIsLoading(false)
  }

  const onSubmit = async () => {
    const { startDate, endDate, searchParam } = formDataAccounts
    if (startDate === '' && endDate === '') {
      setDateError(true)
      return
    }
    if (getTokenConciliator() === null) {
      console.log('getTokenConciliator', getTokenConciliator)
      refreshTokenConciliator()
    }
    if (moment(startDate) <= moment(endDate)) {
      setDateError(false)
      setMessage('')
    } else {
      setDateError(true)
      setMessage('La fecha inicial debe de ser menor a la final')
      return
    }
    if (searchParam === '') {
      setMessage('Debes seleccionar una busqueda')
      return
    }
    setDateError(false)
    setIsLoading(true)

    const objectDataForQueryExcel = {
      startDate: startDate,
      endDate: endDate,
      filterBy: searchParam,
    }
    getConciliatorInform(objectDataForQueryExcel)
      .then((response) => {
        console.log('response', response)
        if (response.status === 203) {
          setDialogData({
            open: true,
            title: 'Conciliador',
            textButtonConfirm: 'Aceptar',
            textButtonCancel: null,
          })
        } else if (response.status === 200) {
          const link = document.createElement('a')
          link.href = window.URL.createObjectURL(new Blob([response.data], { type: 'text/xlsx' }))
          link.setAttribute('download', `Conciliador_${new Date().toLocaleDateString()}.xlsx`)
          document.body.appendChild(link)
          link.click()
        } else if (response.status === 201) {
          const link = document.createElement('a')
          link.href = window.URL.createObjectURL(new Blob([response.data], { type: 'text/xlsx' }))
          link.setAttribute('download', `Conciliador_${new Date().toLocaleDateString()}.xlsx`)
          document.body.appendChild(link)
          link.click()
        }
        setIsLoading(false)
      })
      .catch((e) => {
        setIsLoading(false)
        console.log(e)
      })
  }

  return (
    <>
      <DialogAlert
        open={dialogData.open}
        title={dialogData.title}
        handleClose={handleCloseDialogData}
        personalized
        textButtonConfirm={dialogData.textButtonConfirm}
        message={dialogData.message}
        actionConfirm={dialogData.actionConfirm}
        textButtonCancel={dialogData.textButtonCancel}
      >
        <Grid container justify="center" direction="column" className={classes.gridInfo}>
          <Typography variant="body1">
            Este rango de fechas no tiene transacciones para mostrar.
          </Typography>
        </Grid>
      </DialogAlert>
      <Typography variant="subtitle1" align="left">
        Informe Conciliador
      </Typography>
      <Typography variant="body1">
        Selecciona una fecha para descargar un informe con las ordenes fallidas canceladas por el
        conciliador
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container alignItems="flex-start" spacing={4} className={classes.inputTop}>
          <BackDrop open={isLoading} />
        </Grid>
        <Grid container alignItems="flex-start" spacing={4} className={classes.inputTop}>
          <FormGroup className={classes.inputTop}>
            <InputLabel className={classes.labelSize}>Selecciona un tipo de busqueda</InputLabel>
            <Autocomplete
              id="seachSeller"
              options={searchParam}
              getOptionLabel={(option) => option.nameOfParam}
              style={{ width: 'auto' }}
              autoComplete
              includeInputInList
              renderInput={(params) => (
                <>
                  <TextField {...params} variant="outlined" fullWidth />
                </>
              )}
              onChange={(event, NewValue) => {
                setFormDataAccounts({
                  ...formDataAccounts,
                  searchParam: NewValue.searchParam,
                })
              }}
            />
          </FormGroup>
          <FormGroup className={classes.inputTop}>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
              <InputLabel error={dateError} className={classes.labelSize}>
                Fecha inicial
              </InputLabel>
              <DatePicker
                id="startDate"
                value={formDataAccounts.startDate ? formDataAccounts.startDate : null}
                error={dateError}
                onChange={(value) => {
                  const date = new Date(value)
                  const month =
                    date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
                  setFormDataAccounts({
                    ...formDataAccounts,
                    startDate: `${date.getFullYear()}-${month}-${date.getDate()} 00:00:00`,
                  })
                }}
                format="yyyy-MM-dd"
                inputVariant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <CalendarIcon />
                    </InputAdornment>
                  ),
                }}
                disablePast={false}
                KeyboardButtonProps={{ style: { padding: 0 } }}
              />
            </MuiPickersUtilsProvider>
          </FormGroup>

          <FormGroup className={classes.inputTop}>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
              <InputLabel error={dateError} className={classes.labelSize}>
                Fecha final
              </InputLabel>
              <DatePicker
                id="endDate"
                value={formDataAccounts.endDate ? new Date(formDataAccounts.endDate) : null}
                error={dateError}
                onChange={(value) => {
                  const date = new Date(value)
                  const month =
                    date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
                  setFormDataAccounts({
                    ...formDataAccounts,
                    endDate: `${date.getFullYear()}-${month}-${date.getDate()} 23:59:59`,
                  })
                }}
                format="yyyy-MM-dd"
                inputVariant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <CalendarIcon />
                    </InputAdornment>
                  ),
                }}
                disablePast={false}
                KeyboardButtonProps={{ style: { padding: 0 } }}
              />
            </MuiPickersUtilsProvider>
          </FormGroup>
        </Grid>

        <Typography variant="subtitle2" className={classes.messageError}>
          {message}
        </Typography>

        <Grid container alignItems="flex-start" spacing={2} className={classes.inputTop}>
          <Button
            className={classes.btnBackgroundPrimary}
            id="generateReport"
            name="generateReport"
            disabled={isLoading}
            variant="contained"
            color="secondary"
            onClick={() => {
              onSubmit()
            }}
          >
            Descargar Informe
          </Button>
        </Grid>
      </form>
    </>
  )
}

export default FormDateConciliator
