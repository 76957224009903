export const AVAILABLE_CHANGES = [
  {
    uniqueId: 1,
    availableChanges: [
      {
        decition: true,
        uniqueId: 30,
      },
      {
        decition: false,
        uniqueId: 21,
      },
    ],
  },
  {
    uniqueId: 2,
    availableChanges: [
      {
        decition: true,
        uniqueId: 31,
      },
      {
        decition: false,
        uniqueId: 40,
      },
    ],
  },
]

export const ORDER_STATUSES = [
  { id: 1, name: 'Procesando' },
  { id: 2, name: 'Listo para enviar' },
  { id: 3, name: 'Enviado' },
  { id: 4, name: 'Completado' },
  { id: 5, name: 'Entrega fallida' },
  { id: 6, name: 'Devuelto' },
  { id: 7, name: 'Pendiente' },
]

export const RETURN_OPTIONS = [
  { id: 1, name: 'Garantía' },
  { id: 3, name: 'Retracto' },
]

export const STATUS_CHANGES = [
  {
    uniqueId: 20,
    name: 'En Revisión',
  },
  {
    uniqueId: 50,
    name: 'Completado',
  },
  {
    uniqueId: 40,
    name: 'Rechazado Luegopago',
  },
  {
    uniqueId: 60,
    name: 'Archivado',
  },
]
