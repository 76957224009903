import { makeStyles } from '@material-ui/core/styles'

import buttonStyle from 'assets/jss/material-dashboard-pro-react/components/buttonStyle'
import switchStyles from 'assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch'

export default makeStyles((theme) => ({
  ...switchStyles,
  root: {
    flexGrow: 1,
    backgroundColor: '#FAFAFA',
  },

  divContent: {
    paddingLeft: 30,
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  btnBackgroundPrimary: {
    width: '248px',
    height: '56px',
    borderRadius: '50px',
    border: 'none',
    backgroundColor: '#5e44ff',
    color: 'white',
    fontWeight: '500',
    '&:hover': {
      fontWeight: 'bolder',
      backgroundColor: '#ef0bef',
      boxShadow: '-7px 7px 0px #000000',
      border: '2px solid #000000',
      borderRadius: '50px',
      opacity: '1',
    },

  },
  btnOutlinedBackgroundPrimary: {
    marginBottom: 4,
    height: 52,
    fontSize: 14,
    width: 248,
    color: '#5E44FF',
    borderColor: '#5E44FF',
    '&:hover': {
      color: '#2AA0C6',
      borderColor: '#2AA0C6',
    },
  },
  btnSecondary: {
    marginBottom: 4,
    height: 52,
    fontSize: 14,
    width: 248,
    '&:hover': {
      color: '#5E44FF',
      borderColor: '#5E44FF',
    },
  },
  btnBackgroundError: {
    height: 52,
    fontSize: 14,
    '&:hover': {
      color: theme.palette.error.main,
      backgroundColor: '#00000000',
    },
  },
  formControl: {
    margin: theme.spacing(1),
    width: '200px ',
  },
  logo: {
    marginLeft: '50%',
    transform: 'transLate(-50%)',
    width: 480,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  inputBorder: {
    borderColor: '#FF0000 !important',
    borderWidth: '1px !important',
  },
  button: {
    margin: theme.spacing(1),
  },
  buttonAction: {
    marginLeft: 10,
  },
  ...buttonStyle,
  chip: {
    marginLeft: 30,
  },
  card: {
    minHeight: 100,
    padding: 20,
  },
  alert: { position: 'fixed', zIndex: 1, marginBottom: 20 },
  gridList: {
    flexWrap: 'nowrap',
    width: '100%',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
  },
  gridListIndividual: {
    width: '100%',
    // height: 450,
  },
  iconDelete: {
    color: 'rgba(244, 67, 54)',
  },

  iconAdd: {
    marginTop: '10%',
  },
  rootList: {
    width: '850px',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    // overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  rootListIndividual: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  input: {
    display: 'none',
  },
  imageProduct: {
    width: '150px',
    marginLeft: '25%',
  },
  cardList: {
    flexWrap: 'nowrap',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
  },
  imageTitle: {
    color: 'white',
  },
  inputTop: {
    marginTop: '20px',
  },
  tableContainer: {
    width: 'auto',
  },
  center: {
    margin: `${theme.spacing(1)}px auto`,
  },
  margin: {
    margin: theme.spacing(1),
  },
  topInputLabel: {
    margin: '0 0 7px 0',
    fontSize: 14,
  },
  divTitle: {
    borderBottom: '1px solid #949494',
    paddingBottom: '1px',
    marginBottom: '30px',
    webkitBoxShadow: '0px 2px 5px 1px rgba(0,0,0,0.36)',
    mozBoxShadow: '0px 2px 5px 1px rgba(0,0,0,0.36)',
    boxShadow: '0px 2px 5px -2px rgba(0,0,0,0.36)',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  buttonMargin: {
    marginRight: 8,
  },
  buttonSave: {
    marginTop: 12,
    marginRight: 69,
  },
  typeletra: {
    fontSize: 20,
  },
  gridQuestionsMarketing: {
    gridGap: '1px',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gridTemplateRows: 'repeat(4, auto)',
  },
  paddincheck: {
    marginLeft: 19,
  },
  divTermsAndConditions: {
    marginBottom: '2%',
  },
  labelInputTop: {
    margin: '7px 0',
  },
  labelInputAnyTop: {
    marginBottom: '7px',
  },
  marginInfo: {
    marginLeft: '8px',
  },
  infoBussiness: {
    marginLeft: '15px',
  },
  infoIdentification: {
    marginLeft: '18px',
    marginTop: '4%',
    width: '45%',
  },
  gridSpacing: {
    width: '90%',
    marginLeft: 10,
  },
  hoverPurple: {
    '&:hover': {
      borderColor: '#5E44FF',
      color: '#5E44FF',
    },
  },
  typePerson: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 150,
    '&:hover': {
      cursor: 'pointer',
      borderColor: '#5E44FF',
      color: '#5E44FF',
      boxShadow: '0px 0px 2px 1px #5E44FF',
    },
  },

  rootStepIcon: {
    fontSize: 40,
  },
  completed: {
    color: 'white',
  },
  active: {
    color: 'white',
  },
  text: {
    color: 'white',
  },
  header: {
    height: '50%',
    background: '#5E44FF',
    padding: '10px 0 10px 0',
  },
  termsAndConditions: {
    marginBottom: '1%',
    color: '#FFFFFF',
    fontSize: '10px',
  },
  bienvenidoSeller: {
    color: '#FFFFFF',
    marginTop: '1%',
    marginBottom: '2px',
    fontSize: '22px',
  },
  Logo: {
    maxWidth: '380px',
  },
  main: {
    backgroundColor: '#FAFAFA',
    display: 'flex',
    minHeight: '100vh',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'stretch',
  },
  termsAndConditionsFooter: {
    color: theme.palette.black.main,
    marginBottom: '1%',
    fontSize: '10px',
  },
  wrapInput: {
    marginTop: '5%',
    minWidth: 286,
    maxWidth: 319,
  },
  wrapInputPersonType: {
    width: 280,
    marginBottom: 292,
  },
  gridInfoAndForm: {
    columnGap: '30px',
    marginTop: '5%',
  },
  autocompleteMargin: {
    marginTop: '-17px',
  },
  errorCertificate: {
    boxShadow: '0px 0px 2px 1px red',
  },
  form: {
    marginTop: '5%',
    padding: '0 25%',
    [theme.breakpoints.down('lg')]: {
      padding: '24px 15%',
    },
    [theme.breakpoints.down('md')]: {
      padding: '24px 10%',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '24px 5%',
    },
  },
  footer: {
    backgroundColor: 'black',
    color: 'white',
    paddingTop: '2%',
    paddingLeft: '10%',
    paddingBottom: '3%',
    paddingRight: '3%',
    marginTop: '5%',
    [theme.breakpoints.down('xs')]: {
      height: 'auto',
    },
  },
  white: {
    color: 'white',
  },
  btnAceptar: {
    backgroundColor: '#5E44FF',
    minWidth: 286,
    maxWidth: 319,
    height: 44,
    color: 'white',
    '&:hover': {
      backgroundColor: '#EF16EF',
      boxShadow: 'none',
    },
    [theme.breakpoints.down('sm')]: {
      minWidth: '100%',
      marginBottom: '10%',
    },
  },
  paymetPaper: {
    height: 71,
    marginBottom: '2%',
  },
  quotaPaper: {
    height: 110,
    marginBottom: '2%',
  },
}))
