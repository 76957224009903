import React from 'react'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core'

import useStyles from 'views/Products/products.styles'

const SaleAlert = ({ isOpen, handleClose, response }) => {
  const classes = useStyles()
  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {response.isSuccessful ? 'Operacion exitosa' : 'Operacion fallida'}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {response.isSuccessful
            ? 'La promoción se ha creado correctamente'
            : 'Ocurrió un error inesperado, intente nuevamente o comuniquese con soporte'}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          color="primary"
          className={classes.btnBackgroundErrorPriceSmall}
        >
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default SaleAlert
