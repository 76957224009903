/* eslint-disable no-plusplus */
import axios from 'axios'

import { IMEGES_PROCESSOR, IMEGES_PROCESSOR_API_KEY } from 'constants/urls'

export const compareProcessedImages = (newProcessing, oldProcessing) => {
  if (oldProcessing === undefined) {
    return newProcessing
  }
  const result = {}
  result.hasIssues = false
  Object.keys(newProcessing).forEach((key) => {
    if (key === 'hasIssues') {
      result.hasIssues = newProcessing[key] || oldProcessing[key]
    } else if (key === 'files') {
      result[key] = [
        ...oldProcessing[key],
        ...newProcessing[key].filter((file) => Object.keys(file).length > 0),
      ]
    } else {
      result[key] = oldProcessing[key] !== undefined ? newProcessing[key] : oldProcessing[key]
    }
  })

  return result
}

export const processImage = async (images, listImagesOk, imagesProcessed, productSku, sellerId) => {
  const newImages = images.filter((item) => item.file)
  const countWithoutFile = images.length - newImages.length
  const formData = new FormData()
  formData.append('ApiKey', IMEGES_PROCESSOR_API_KEY)
  formData.append('ProductSku', productSku)
  formData.append('SellerId', sellerId)
  listImagesOk.forEach((image) => {
    if (image.isPrincipal) {
      formData.append('MainFile', image.file)
    } else {
      formData.append(`files`, image.file)
    }
  })
  const result = await axios({
    url: IMEGES_PROCESSOR,
    method: 'post',
    data: formData,
  })

  for (let index = 0; index < countWithoutFile; index++) {
    result.data.data.files.unshift({})
  }
  const data = compareProcessedImages(result.data.data, imagesProcessed)

  return data
}
