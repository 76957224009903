import React from 'react'

import SweetAlert from 'react-bootstrap-sweetalert'

import Button from 'components/CustomButtons/Button'

export default function sweetAlert(props) {
  const {
    title,
    cancelStyle,
    confirmStyle,
    buttonColor,
    buttonConfirmText,
    buttonCancelText,
    onConfirm,
    onCancel,
  } = props

  return (
    <SweetAlert
      showCancel
      onConfirm={() => {
        onConfirm()
      }}
      onCancel={() => {
        onCancel()
      }}
      confirmBtnText={<Button color={buttonColor}>{buttonConfirmText}</Button>}
      cancelBtnText={<Button color={cancelStyle}>{buttonCancelText}</Button>}
      confirmBtnBsStyle={confirmStyle}
      cancelBtnBsStyle={cancelStyle}
      title={title}
    />
  )
}
