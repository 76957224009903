import React, { useContext, useState } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  MenuItem,
  TextField,
  FormGroup,
  InputLabel,
} from '@material-ui/core'
import axios from 'axios'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'

import styles from './modalAddUser.styles'

import BackDrop from 'components/BackDrop'
import useCustomDialogAlert from 'components/useCustomDialogAlert'
import { API_USERS } from 'constants/urls'
import useAuthContext from 'contextApi/AuthContext'

const schema = yup.object().shape({
  name: yup.string().typeError('Debe ingresar un nombre').required('Campo requerido'),
  email: yup
    .string()
    .required('Campo requerido')
    .email('Email no válido')
    .typeError('Debe ingresar un email válido'),
})
const ModalAddUsers = ({ userInfo, onCancel, getUsers }) => {
  const classes = styles()
  const showDialogAlert = useCustomDialogAlert()
  const { userLoggedin } = useContext(useAuthContext)
  const [isLoading, setIsLoading] = useState(false)
  const [model, setModel] = useState(userInfo)
  const [modelValidate, setModelValidate] = useState({
    email: false,
    name: false,
    isActive: false,
    role: false,
  })
  const { register, handleSubmit, errors } = useForm({
    defaultValues: {},
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: yupResolver(schema),
  })
  const setValidateError = (fields) => {
    const obj = { ...modelValidate }

    fields.map((key) => {
      obj[key] = true
    })
    setModelValidate(obj)
  }

  const onSubmit = async (event) => {
    // FIXME: commented error save user
    // event.preventDefault()
    const fieldsValidated = []
    Object.keys(model).map(function (objectKey) {
      const value = model[objectKey]

      if (objectKey === 'name' || objectKey === 'email') {
        if (!value) {
          fieldsValidated.push(objectKey)
        }
      }
    })
    if (fieldsValidated.length > 0) {
      setValidateError(fieldsValidated)
      showDialogAlert(true, '¡Advertencia!', `Los campos no pueden estar vacíos`, 'Cerrar')
      return false
    }
    setIsLoading(true)
    delete model.id
    delete model.role
    model.username = model.email
    model.sellerId = userLoggedin.sellerId
    if (userInfo.id) {
      try {
        const result = await axios.put(`${API_USERS}`, {
          id: userInfo.id,
          email: model.email,
          name: model.name,
          isActive: model.isActive,
        })

        if (result.status === 200) {
          showDialogAlert(
            true,
            '¡Cambio exitoso!',
            `Registro actualizado correctamente`,
            'Cerrar',
            '',
            true
          )
          getUsers()
          onCancel()
        }
        if (result) setIsLoading(false)
      } catch (error) {
        setIsLoading(false)
      }
    } else {
      try {
        const result = await axios.post(`${API_USERS}/register`, {
          ...model,
          role: 'seller',
        })
        if (result.status === 201) {
          showDialogAlert(
            true,
            '¡Cambio exitoso!',
            `Usuario creado satisfactoriamente`,
            'Cerrar',
            '',
            true
          )
          getUsers()
          onCancel()
        }
        if (result) setIsLoading(false)
      } catch (error) {
        setIsLoading(false)
      }
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <BackDrop open={isLoading} />
      <Grid
        container
        className={classes.root}
        direction="column"
        alignItems="center"
        justify="center"
      >
        <FormGroup className={classes.widthGroup}>
          <InputLabel className={classes.labelSize}>Roles</InputLabel>
          <TextField id="role" select fullWidth variant="outlined" disabled>
            <MenuItem key={0} value={0}>
              Seleccione un rol
            </MenuItem>
          </TextField>
        </FormGroup>

        <FormGroup className={classes.widthGroup}>
          <InputLabel error={!!errors.name} className={classes.labelSize}>
            Nombre
          </InputLabel>
          <TextField
            id="name"
            name="name"
            inputRef={register}
            error={!!errors.name}
            variant="outlined"
            label=""
            fullWidth
            value={model.name || ''}
            helperText={errors?.name?.message}
            onChange={(e) => setModel({ ...model, name: e.target.value })}
          />
        </FormGroup>

        <FormGroup className={classes.widthGroup}>
          <InputLabel error={!!errors.email} className={classes.labelSize}>
            Correo electrónico
          </InputLabel>
          <TextField
            id="email"
            name="email"
            error={!!errors.email}
            variant="outlined"
            fullWidth
            type="email"
            inputRef={register}
            helperText={errors?.email?.message}
            value={model.email || ''}
            onChange={(e) => setModel({ ...model, email: e.target.value })}
          />
        </FormGroup>
      </Grid>
      <FormGroup row className={classes.marginLeftCheck}>
        <FormControlLabel
          control={
            <Checkbox
              checked={model.isActive}
              onChange={({ target }) => setModel({ ...model, isActive: target.checked })}
              color="primary"
            />
          }
          label="Activo"
        />
      </FormGroup>
      <Grid container justify="center" className={classes.divButtons}>
        <Button className={classes.btnBackgroundError} disabled={isLoading} onClick={onCancel}>
          Cancelar
        </Button>
        <Button
          className={classes.btnBackgroundPrimary}
          variant="contained"
          color="secondary"
          type="submit"
          // onClick={onSubmit}
          disabled={isLoading}
        >
          {model.id ? 'Actualizar' : 'Guardar'}
        </Button>
      </Grid>
    </form>
  )
}

export default ModalAddUsers
