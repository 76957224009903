import { makeStyles } from '@material-ui/core/styles'

export default makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  section1: {
    margin: theme.spacing(3, 2),
  },
  section2: {
    margin: theme.spacing(2),
  },
  section3: {
    margin: theme.spacing(3, 1, 1),
  },
  grey: {
    color: theme.palette.grey,
    backgroundColor: theme.palette.grey,
  },
  smallSuccess: {
    width: theme.spacing(1.5),
    height: theme.spacing(1.5),
    color: theme.palette.getContrastText(theme.palette.success.main),
    backgroundColor: theme.palette.success.main,
  },
  smallWarning: {
    width: theme.spacing(1.5),
    height: theme.spacing(1.5),
    color: theme.palette.getContrastText(theme.palette.warning.main),
    backgroundColor: theme.palette.warning.main,
  },
  smallGrey: {
    width: theme.spacing(1.5),
    height: theme.spacing(1.5),
    color: theme.palette.getContrastText(theme.palette.grey.main),
    backgroundColor: theme.palette.grey.main,
  },
  heightPanel: {
    height: '662px',
  },
  marginBox: {
    marginRight: 15,
  },
  labelIcon: {
    marginRight: theme.spacing(1),
  },

  labelText: {
    textAlign: 'left',
    flexGrow: 1,
  },
  labelTextCop: {
    textAlign: 'left',
    flexGrow: 1,
  },
  marginGrid: {
    marginTop: 34,
  },
  orderextra: {
    maxWidth: '100%',
  },
  responsedown: {
    [theme.breakpoints.up('md')]: {
      textAlign: 'right',
    },
  },
  letterSpacing: {
    fontSize: 12,
  },
  alignToSectionPanel: {
    padding: '8px',
  },
  labelInputTop: {
    marginTop: '2%',
    marginBottom: 6,
  },
  btnBackgroundPrimary: {
    width: '248px',
    height: '56px',
    borderRadius: '50px',
    border: 'none',
    margin: '20px 0',
    backgroundColor: '#5e44ff',
    color: 'white',
    fontWeight: '500',
    '&:hover': {
      fontWeight: 'bolder',
      backgroundColor: '#ef0bef',
      boxShadow: '-7px 7px 0px #000000',
      border: '2px solid #000000',
      borderRadius: '50px',
      opacity: '1',
    }
  },
  btnreturnsWarehouse: {
    background: theme.palette.secondary.main,
    height: 35,
    borderRadius: 6,
    width: '100%',

    '&:hover': {
      background: theme.palette.primary.main,
    },
  },
  marginInfo: {
    margin: '0 0px',
  },
  infoWarehouses: {
    width: '100%',
  },
  formInput: {
    width: '47%',
    marginButton: 4,
    textAlign: 'left',
  },
  componentRight: {
    gap: '10px',
  },
  groupInfo: {
    paddingLeft: 20,
  },
  spacingComponent: {
    marginTop: 10,
  },
  listActivated: {
    position: 'relative',
    overflow: 'auto',
    maxHeight: '672px',
    textAlign: 'center',
    width: '100%',
    '& > li:nth-child(odd)': {
      backgroundColor: '#F5F5F5',
    },
  },
  titleDiv: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}))
