/* eslint-disable react/prop-types */
import React, { useEffect } from 'react'

import { esES } from '@material-ui/core/locale'
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles'

const theme = createMuiTheme(
  {
    palette: {
      primary: {
        main: '#6064FF',
        black: '#000000',
        green: '#3ad399',
      },
      secondary: {
        light: '#F1F1F1',
        main: '#52479C',
        pink: '#FF40FC',
        blue: '#6064FF',
      },
      error: {
        main: '#f35b69',
      },
      warning: {
        main: '#ff9800',
      },
      info: {
        main: '#93b3df',
      },
      success: {
        main: '#7dc790',
      },
      white: {
        main: '#fff',
      },
      grey: {
        opacity: '#F6F6F6',
        light: '#E8E8E8',
        main: '#b4b4b5',
        dark: '#7A7A7A',
      },
      black: {
        main: '#0D0C0E',
      },
      green: {
        main: '#77BD7C',
      },
      dimGrey: {
        main: '#7A7A7A',
      },
      mintGreen: {
        main: '#abf29d',
      },
      Gray: {
        main: '#e5e5e5',
      },
      opacity: {
        main: '#F5F5F5',
      },
    },
    typography: {
      fontSize: 14,
      fontWeightLight: 300,
      fontWeightRegular: 400,
      fontWeightBold: 500,
    },
    overrides: {
      MUIDataTable: {
        root: {
          backgroundColor: '#FF000',
          maxHeight: '100%',
        },
        paper: {
          boxShadow: 'none',
          width: '100%',
        },
        responsiveScroll: {
          maxHeight: '100% !important',
        },
      },
      MUIDataTableHeadCell: {
        fixedHeader: {
          textAlign: 'left',
          fontSize: 12,
          padding: 10,
        },
      },
      MuiTableCell: {
        root: {
          padding: 5,
          fontSize: 16,
        },
      },
      MUIDataTableBodyRow: {
        root: {
          height: 69,
          '&:nth-child(odd)': {
            backgroundColor: '#F5F5F5',
          },
        },
      },
      MuiTypography: {
        root: {
          fontWeight: 'normal',
          textDecoration: 'none',
          textTransform: 'none',
          color: '#0D0C0E',
        },
        h4: {
          fontWeight: 500,
          fontFamily: 'Red Hat Display Light',
        },
        h5: {
          fontSize: 30,
          fontWeight: 500,
          fontFamily: 'Red Hat Display Light',
        },
        subtitle1: {
          fontSize: 18,
          fontWeight: 500,
          fontFamily: 'Red Hat Display Bold',
        },
        subtitle2: {
          fontSize: 16,
          fontWeight: 500,
          fontFamily: 'Red Hat Display Light',
        },
        subtitle3: {
          fontSize: 16,
          fontWeight: 500,
          fontFamily: 'Red Hat Display Bold',
        },
        body1: {
          fontSize: 16,
          fontFamily: 'Red Hat Display Black',
        },
        body2: {
          fontSize: 13,
          fontFamily: 'Red Hat Display Light',
        },
        body3: {
          fontSize: 16,
          fontFamily: 'Red Hat Display Black',
        },
        body4: {
          fontSize: 13,
          fontFamily: 'Red Hat Display Regular',
        },
        paragraph: {
          fontFamily: 'Red Hat Display SemiBold',
          fontSize: 16,
        },
        text: {
          fontFamily: 'Red Hat Display Regular',
          fontSize: 16,
        },
        gutterBottom: {
          marginLeft: '2px',
          marginBottom: '1px',
        },
        overline: {
          marginLeft: '2px',
          marginBottom: '1px',
        },
        h6: {
          fontFamily: 'Red Hat Display Bold',
          fontSize: 24,
          fontWeight: 500,
          color: '#6064FF',
        },
      },
      MuiCheckbox: {
        root: {
          color: '#707070 !important',
        },
      },
      MuiFormControl: {
        root: {
          marginBottom: '10px',
        },
      },
      MuiOutlinedInput: {
        root: {
          backgroundColor: 'white',
          color: 'black',
          borderRadius: 8,
          height: 52,
          padding: '1.5px 16px !important',
          fontFamily: 'Red Hat Display Regular',
        },
        notchedOutline: {
          border: '2px solid #7A7A7A',
          borderRadius: 10,
        },
        input: {
          padding: 7,
          fontFamily: 'Red Hat Display Regular',
        },
      },
      MuiButton: {
        root: {
          textTransform: 'none',
          fontSize: 14,
          borderRadius: 10,
          fontFamily: 'Red Hat Display Bold',
        },
        outlined: {
          border: '2px solid #0000003b',
        },
      },
      MuiFormLabel: {
        root: {
          color: '#0D0C0E',
          fontSize: 16,
        },
      },
      MuiSelect: {
        root: {
          fontSize: 14,
        },
      },
      MuiDivider: {
        root: {
          width: '100%',
        },
      },
      MuiTableRow: {
        root: {
          height: 69,
          '&:nth-child(odd)': {
            backgroundColor: '#F5F5F5',
          },
        },
      },
    },
    MuiTableCell: {
      root: {
        fontSize: 28,
        padding: 10,
        fontWeight: 800,
      },
    },
    MuiSwitch: {
      track: {
        backgroundColor: 'red',
      },
    },
  },
  esES
)

const ThemeAppProvider = ({ children, src }) => {
  useEffect(() => {
    const script = document.createElement('script')
    script.setAttribute('src', src)
    document.body.appendChild(script)
  }, [])

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>
}

export default ThemeAppProvider
