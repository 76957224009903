import React, { useState, useContext } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { Button, InputLabel, TextField, Typography, IconButton, Grid } from '@material-ui/core'
import InputAdornment from '@material-ui/core/InputAdornment'
import { makeStyles } from '@material-ui/core/styles'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import axios from 'axios'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'

import styles from './styles'

import BackDrop from 'components/BackDrop'
import { EyeIconShow } from 'components/icons/personalizedIcons'
import useCustomDialogAlert from 'components/useCustomDialogAlert'
import { USER_CHANGE_PASSWORD } from 'constants/urls'
import useAuthContext from 'contextApi/AuthContext'

const useStyles = makeStyles(styles)
const schema = yup.object().shape({
  currentPassword: yup.string().required('Campo requerido'),
  newPassword: yup
    .string()
    .required('Campo requerido')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?.&¡'/%,()#{};_¿])[A-Za-z\d@$!%*?.&¡'/%,()#{};_¿]{8,}$/g,
      'La contraseña no cumple con los requisitos mínimos'
    ),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('newPassword'), null], 'Debe coincidir con la contraseña'),
})

export default function ChangePassword({ handlerClosed, cancelPasswordModal }) {
  const classes = useStyles()
  const showDialogAlert = useCustomDialogAlert()
  const { userLoggedin, setLoggedin } = useContext(useAuthContext)
  const { register, handleSubmit, errors } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
  })

  const [colorCondition, serColorCondition] = useState(false)

  const [loading, setLoading] = useState(false)
  const [lblMessage, setLblMessage] = useState('')

  const [model, setModel] = useState({
    currentPassword: '',
    newPassword: '',
    confirmNewPassword: '',
  })
  const [security, setSecurity] = useState({
    showCurrentPassword: false,
    showPassword: false,
    showPasswordConfirm: false,
  })
  const DEFAULT_USER = userLoggedin.name
  // Set new user if rol is seller or selleradmin
  const USER_ROL = {
    Seller: userLoggedin.email,
    SellerAdmin: userLoggedin.name,
  }

  const onSubmit = async () => {
    if (!model.newPassword || !model.confirmNewPassword || !model.currentPassword) {
      showDialogAlert(true, '¡Advertencia!', `Llena todos los campos`, 'Cerrar')
      return false
    }
    const patt1 = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?.&¡'/%,()#{};_¿])[A-Za-z\d@$!%*?.&¡'/%,()#{};_¿]{8,}$/g
    const result = patt1.test(model.newPassword)

    if (!result) {
      showDialogAlert(
        true,
        '¡Advertencia!',
        `La contraseña no cumple con los requisitos mínimos`,
        'Cerrar'
      )
      serColorCondition(true)
      return false
    }
    serColorCondition(false)

    if (model.newPassword !== model.confirmNewPassword) {
      showDialogAlert(true, '¡Advertencia!', `Las contraseñas no coinciden.`, 'Cerrar')
      return false
    }
    setLoading(true)
    await axios
      .post(`${USER_CHANGE_PASSWORD}`, {
        userName: USER_ROL[userLoggedin.role] || DEFAULT_USER,
        currentPassword: model.currentPassword,
        newPassword: model.newPassword,
        confirmNewPassword: model.confirmNewPassword,
      })
      .then((response) => {
        setLoading(false)
        if (response?.status || response) {
          const dataUser = userLoggedin
          dataUser.passwordAlreadyChanged = true
          setLoggedin(dataUser)
          showDialogAlert(
            true,
            '¡Cambio exitoso!',
            `¡La contraseña fue cambiada con exito!`,
            'Cerrar',
            '',
            true,
            cancelPasswordModal
          )
          setModel({ ...model, currentPassword: '', newPassword: '', confirmPassword: '' })
        }
      })
      .catch((e) => {
        setLoading(false)
        if (
          e.response.data.errors.includes(
            'Restablecimiento de contraseña fallido, intenta de nuevo.'
          )
        ) {
          showDialogAlert(
            true,
            '¡Advertencia!',
            `Restablecimiento de contraseña fallido, intenta de nuevo.`,
            'Cerrar'
          )
        } else if (
          e.response.data.errors.includes(
            'Ingresa una contraseña nueva y diferente a las que ya has usado'
          )
        ) {
          showDialogAlert(
            true,
            '¡Advertencia!',
            `Ingresa una contraseña nueva y diferente a las que ya has usado.`,
            'Cerrar'
          )
        } else if (
          e.response.data.errors.includes('Cambio de contraseña fallido, intenta de nuevo.')
        ) {
          showDialogAlert(
            true,
            '¡Advertencia!',
            `Cambio de contraseña fallido, intenta de nuevo.`,
            'Cerrar'
          )
        } else if (
          e.response.data.errors.includes(
            'Ingrese una contraseña actual válida e inténtalo de nuevo'
          )
        ) {
          showDialogAlert(
            true,
            '¡Advertencia!',
            `Ingrese una contraseña actual válida e inténtalo de nuevo.`,
            'Cerrar'
          )
        } else {
          showDialogAlert(true, '¡Advertencia!', `Ha ocurrido un error.`, 'Cerrar')
        }
        return false
      })

    return true
  }

  const handleClickCurrentPassword = () => {
    setSecurity({ ...security, showCurrentPassword: !security.showCurrentPassword })
  }

  const handleClickShowPassword = () => {
    setSecurity({ ...security, showPassword: !security.showPassword })
  }

  const handleClickShowConfirmNewPassword = () => {
    setSecurity({ ...security, showPasswordConfirm: !security.showPasswordConfirm })
  }

  const preventDefault = (e) => {
    e.preventDefault()
  }

  return (
    <>
      <BackDrop open={loading} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <InputLabel htmlFor="currentPassword" className={classes.labelInputCurrent}>
          Contraseña actual
        </InputLabel>

        <TextField
          className={classes.input}
          id="currentPassword"
          name="currentPassword"
          inputRef={register}
          error={!!errors.currentPassword}
          helperText={errors?.currentPassword?.message}
          variant="outlined"
          formControlProps={{
            fullWidth: true,
          }}
          type={security.showCurrentPassword ? 'text' : 'password'}
          InputProps={{
            onChange: ({ target }) => {
              setLblMessage('')
              setModel({ ...model, currentPassword: target.value })
            },
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleClickCurrentPassword}>
                  {security.showCurrentPassword ? <EyeIconShow /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),

            autoComplete: 'off',
          }}
        />
        <InputLabel htmlFor="newPassword" className={classes.labelInputPassword}>
          Nueva contraseña
        </InputLabel>

        <TextField
          className={classes.input}
          id="newPassword"
          name="newPassword"
          inputRef={register}
          error={!!errors.newPassword}
          helperText={errors?.newPassword?.message}
          variant="outlined"
          formControlProps={{
            fullWidth: true,
          }}
          type={security.showPassword ? 'text' : 'password'}
          InputProps={{
            onChange: ({ target }) => {
              setLblMessage('')
              setModel({ ...model, newPassword: target.value })
            },
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleClickShowPassword}>
                  {security.showPassword ? <EyeIconShow /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),

            autoComplete: 'off',
          }}
        />
        <InputLabel htmlFor="confirmNewPassword" className={classes.labelInputConfirm}>
          Confirmar nueva contraseña
        </InputLabel>

        <TextField
          className={classes.input}
          id="confirmNewPassword"
          name="confirmNewPassword"
          inputRef={register}
          error={!!errors.confirmNewPassword}
          helperText={errors?.confirmNewPassword?.message}
          variant="outlined"
          formControlProps={{
            fullWidth: true,
          }}
          type={security.showPasswordConfirm ? 'text' : 'password'}
          InputProps={{
            onChange: ({ target }) => {
              setLblMessage('')
              setModel({ ...model, confirmNewPassword: target.value })
            },
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleClickShowConfirmNewPassword}>
                  {security.showPasswordConfirm ? <EyeIconShow /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
            autoComplete: 'off',
          }}
        />

        <Typography
          className={classes.textConfirmPassword}
          variant="subtitle2"
          color={colorCondition ? 'error' : 'initial'}
        >
          Recuerda que la contraseña debe contener al menos 8 caracteres, 1 letra mayúscula, 1
          número y 1 carácter especial.
        </Typography>

        <Typography className={classes.caption} variant="caption">
          {lblMessage}
        </Typography>

        <Grid container justify="center" className={classes.divButtons}>
          <Grid item>
            <Button
              variant="contained"
              color="secondary"
              className={classes.btnBackgroundError}
              onClick={() => cancelPasswordModal()}
              disabled={loading}
            >
              Cancelar
            </Button>
          </Grid>
          <Button
            variant="contained"
            color="secondary"
            className={classes.btnBackgroundPrimary}
            type="submit"
            disabled={loading}
          >
            Cambiar
          </Button>
        </Grid>
      </form>
    </>
  )
}
