import { makeStyles } from '@material-ui/core/styles'

export default makeStyles((theme) => ({
  table: {
    background: 'white',
  },
  center: {
    margin: 'auto',
    position: 'relative',
  },
  delete: {
    background: '#bd081c',
  },
  icon: {
    color: 'white',
  },
  padding: {
    marginTop: '1vw',
  },
  root: {
    paddingTop: '1vw',
  },
  cancel: {
    textAlign: 'right',
  },
  success: {
    float: 'right',
  },
  spaccingButtons: {
    marginTop: '1rem',
    position: 'relative',
    textAlign: 'center',
  },
  buttonSuccess: {
    background: '#4caf50',
    color: 'white',
  },
  buttonDanger: {
    background: '#cc2127',
    color: 'white',
  },
  labelInput: {
    marginBottom: 8,
    fontWeight: 'bold',
  },
  input: {
    width: '100%',
    marginTop: 8,
    marginBottom: 19,

    '&:input': {
      padding: 5,
    },
  },
}))
