import React, { useState } from 'react'

import {
  Grid,
  List,
  ListItem,
  ListItemText,
  Divider,
  Typography,
  ListSubheader,
  Tooltip,
} from '@material-ui/core'
import Axios from 'axios'

import useStyles from './warehousesStyle'


import Button from 'components/CustomButtons/Button'
import DialogAlert from 'components/DialogAlert'
import { EditIcon, DeleteIcon } from 'components/icons/personalizedIcons'
import useCustomDialogAlert from 'components/useCustomDialogAlert'
import { API_STORE_INFO } from 'constants/urls'

export default function ListWarehouses({ selectWarehouse, data, setData, setIsLoading }) {
  const classes = useStyles()
  const showDialogAlert = useCustomDialogAlert()
  const [dialogAlert, setDialogAlert] = useState({
    open: false,
    title: '',
    message: '',
    actionConfirm: null,
    textButtonConfirm: '',
    textButtonCancel: '',
  })

  const [selectedWarehouse, setSelectedWarehouse] = useState(null)

  const handleDeleteBodega = async (idBodega) => {
    const newBodega = [...data]

    setDialogAlert({
      open: true,
      title: '¡Advertencia!',
      message: `¿Está seguro que desea eliminar esta bodega?`,
      actionConfirm: async () => {
        if (idBodega) {
          setIsLoading(true)
          await Axios.delete(`${API_STORE_INFO}/WarehouseId`, {
            data: {
              id: idBodega,
            },
          })
            .then((response) => {
              setIsLoading(false)
              if (response.status === 200) {
                newBodega.map((warehouse, index) => {
                  if (warehouse.id === idBodega) {
                    newBodega.splice(index, 1)
                  }
                })

                setData(newBodega)
                selectWarehouse(null)
                showDialogAlert(
                  true,
                  '¡Cambio exitoso!',
                  `Se eliminó la bodega correctamente `,
                  'Cerrar',
                  '',
                  true
                )
              }
            })
            .catch((e) => {
              setIsLoading(false)
              console.log(e)
            })
        }
      },
      textButtonConfirm: 'Aceptar',
      textButtonCancel: 'Cancelar',
    })
  }

  const renderListItem = () =>
    data.map((item, index) => {
      return (
        <>
          <ListItem selected={selectedWarehouse === index} key={item.id}>
            <ListItemText>
              <Grid>
                <Grid container justify="space-evenly" alignItems="center">
                  <Grid className={classes.titleDiv} item md={5} xs={5}>
                    <Typography variant="button">{item.title}</Typography>
                  </Grid>
                  <Grid item md={5} xs={5}>
                    <Typography variant="button">{item.contactName}</Typography>
                  </Grid>

                  <Grid item md={2} xs={2}>
                    <Tooltip title="Editar bodega">
                      <Button
                        onClick={() => {
                          setSelectedWarehouse(index)
                          selectWarehouse(item.id)
                        }}
                        simple
                        justIcon
                        round
                        color="linkedin"
                      >
                        <EditIcon />
                      </Button>
                    </Tooltip>
                    <Tooltip title="Eliminar bodega">
                      <Button
                        onClick={() => handleDeleteBodega(item.id)}
                        simple
                        justIcon
                        round
                        color="pinterest"
                      >
                        <DeleteIcon />
                      </Button>
                    </Tooltip>
                  </Grid>
                </Grid>
              </Grid>
            </ListItemText>
          </ListItem>
        </>
      )
    })
  const handleCloseDialogAlert = () => {
    setDialogAlert({ ...dialogAlert, open: false })
  }
  return (
    <>
      <DialogAlert
        open={dialogAlert.open}
        title={dialogAlert.title}
        handleClose={handleCloseDialogAlert}
        message={dialogAlert.message}
        textButtonConfirm={dialogAlert.textButtonConfirm}
        actionConfirm={dialogAlert.actionConfirm}
        textButtonCancel={dialogAlert.textButtonCancel}
      />
      <Grid container className={classes.alignToSectionPanel}>
        <List
          style={{
            position: 'relative',
            overflow: 'auto',
            maxHeight: '672px',
          }}
          subheader={
            <ListItemText divider>
              <ListSubheader>
                <Grid>
                  <Grid md={12} container>
                    <Grid md={12} item>
                      <Typography variant="subtitle1">Listado de bodegas</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </ListSubheader>
            </ListItemText>
          }
        />
        <Divider light />
        <List className={classes.listActivated}>
          {data?.length > 0 ? (
            renderListItem()
          ) : (
            <Typography variant="h6">No hay bodegas</Typography>
          )}
        </List>
      </Grid>
    </>
  )
}
