import React, { useState, useEffect } from 'react'

import { Typography, Grid, TextField, InputLabel, Tooltip, FormGroup } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import EditIcon from '@material-ui/icons/Edit'
import Autocomplete from '@material-ui/lab/Autocomplete'
import axios from 'axios'
import MUIDataTable from 'mui-datatables'

import useStyles from './ConfugurationGeneral.styles'

import BackDrop from 'components/BackDrop'
import Card from 'components/Card/Card'
import CardBody from 'components/Card/CardBody'
import Button from 'components/CustomButtons/Button'
import CustomToolbar from 'components/CustomToolbarMuiDatatable/CustomToolbarMuiDatatable'
import GridContainer from 'components/Grid/GridContainer'
import ModalConfigurationGeneral from 'components/ModalConfigurationGeneral'
import ModalConfigurationSetting from 'components/ModalConfigurationSetting'
import ModalContainer from 'components/ModalContainer'
import { SETTING_CONFIGURATION } from 'constants/urls'
import textLabels from 'utils/MUIDataTableTextLabels'

// const dataState = [
//   { id: '1', name: 'iva', value: 'activo' },
//   { id: '1', name: 'iva', value: 'activo' },
//   { id: '1', name: 'iva', value: 'activo' },
// ]
export default function ConfugurationGeneral() {
  const classes = useStyles()

  const [isOpen, setIsOpen] = useState(null)
  const [isOpenSetting, setIsOpenSetting] = useState(null)
  const [configurationSettingEdit, setConfigurationSettingEdit] = useState(null)
  const [settingEdit, setSettingEdit] = useState({})
  const [isLoading, setIsLoading] = useState(null)
  const [addInfo, setAddInfo] = useState({
    id: '',
    name: '',
  })
  const [setting, setSetting] = useState({
    id: '',
    name: '',
    value: '',
    settingGroupId: '',
  })
  const [dataState, setDataState] = useState([])
  const [settingsGroups, setSettingsGroups] = useState([])
  const options = {
    print: false,
    filter: false,
    download: false,
    viewColumns: false,
    filterType: 'dropdown',
    responsive: 'scrollMaxHeight',
    rowHover: true,
    pagination: true,
    serverSide: true,
    selectableRows: 'none',
    textLabels,
    sort: false,
    customToolbar: () => {
      return (
        <CustomToolbar
          icon={<AddIcon />}
          tooltip="Asignar flag"
          onClick={() => {
            if (addInfo.id && addInfo.id !== '') {
              setIsOpen(!isOpen)
              setSettingEdit(null)
            }
          }}
        />
      )
    },
  }
  const closeModal = () => {
    setIsOpenSetting(false)
  }
  const closeModalSetting = () => {
    setSetting({
      id: '',
      name: '',
      value: '',
      settingGroupId: '',
    })
    setIsOpen(false)
  }
  const setSettingEdition = (item) => {
    setSettingEdit(item)
    setSetting({
      id: item[0] || '',
      name: item[1] || '',
      value: item[2] || '',
      settingGroupId: addInfo.id || '',
    })
    setIsOpen(true)
  }
  const columns = [
    {
      name: 'id',
      options: { display: false },
    },
    {
      name: 'name',
      label: 'Nombre',
    },
    {
      name: 'value',
      label: 'Valor',
    },
    {
      name: '',
      label: 'Acciones',
      options: {
        customBodyRender: (value, tableMeta) => {
          return (
            <>
              <Tooltip title="Editar">
                <Button
                  onClick={(event) => {
                    event.preventDefault()
                    setSettingEdition(tableMeta.rowData)
                  }}
                  color="linkedin"
                  justIcon
                  round
                >
                  <EditIcon />
                </Button>
              </Tooltip>
            </>
          )
        },
      },
    },
  ]
  const defaultProps = {
    options: settingsGroups,
    getOptionLabel: (option) => option.name || '',
  }

  const GetSettingsByGroup = (id) => {
    setIsLoading(true)
    axios
      .get(`${SETTING_CONFIGURATION}/GetSettingsByGroup/${id}`)
      .then(function (response) {
        setDataState(response.data)
        setIsLoading(false)
      })
      .catch(function (error) {
        console.log(error)
        setIsLoading(false)
      })
  }
  const GetAllSettingsGroups = () => {
    setIsLoading(true)
    axios
      .get(`${SETTING_CONFIGURATION}/GetAllSettingsGroups`)
      .then(function (response) {
        setSettingsGroups(response.data)
        setIsLoading(false)
      })
      .catch(function (error) {
        setIsLoading(false)
        console.log(error)
      })
  }
  const createUpdateSetting = () => {
    if (addInfo.id && addInfo.id !== '') {
      const formDataSetting = new FormData()
      formDataSetting.append(`ID`, setting.id)
      formDataSetting.append(`Name`, setting.name)
      formDataSetting.append(`Value`, setting.value)
      formDataSetting.append(`SettingGroupId`, addInfo.id)
      axios
        .post(`${SETTING_CONFIGURATION}/CreateUpdateSetting`, formDataSetting)
        .then(function (response) {
          GetSettingsByGroup(addInfo.id)
          closeModalSetting()
          console.log(response)
        })
        .catch(function (error) {
          setIsLoading(false)
          console.log(error)
        })
    }
  }
  const createUpdateSettingsGroup = () => {
    if (addInfo.name && addInfo.name !== '') {
      const formData = new FormData()
      formData.append(`ID`, addInfo.id)
      formData.append(`Name`, addInfo.name)
      setIsLoading(true)
      axios
        .post(`${SETTING_CONFIGURATION}/CreateUpdateSettingsGroup`, formData)
        .then(function (response) {
          GetAllSettingsGroups()
          GetSettingsByGroup(response.data.id)
          setAddInfo({
            id: '',
            name: '',
          })
          closeModal()
          setIsLoading(false)
          console.log(response)
        })
        .catch(function (error) {
          setIsLoading(false)
          console.log(error)
        })
    }
  }
  useEffect(() => {
    GetAllSettingsGroups()
  }, [])
  return (
    <>
      <BackDrop open={isLoading} />
      <Typography variant="h6" className={classes.titleConfig}>
        Configuración general
      </Typography>
      <Grid item md={11} xs={12}>
        <Card className={classes.card}>
          <CardBody>
            <GridContainer container spacing={10}>
              <FormGroup style={{ marginLeft: '30px', width: '60%' }}>
                <Autocomplete
                  {...defaultProps}
                  id="seachSeller"
                  value={addInfo}
                  className={classes.select}
                  style={{ width: 'auto', fontFamily: 'Red Hat Display Regular' }}
                  autoComplete
                  includeInputInList
                  renderInput={(params) => (
                    <>
                      <InputLabel className={classes.titleConfig}>
                        Buscar configuración de grupos
                      </InputLabel>
                      <TextField
                        {...params}
                        className={classes.select}
                        style={{ fontFamily: 'Red Hat Display Regular' }}
                        variant="outlined"
                        fullWidth
                      />
                    </>
                  )}
                  onChange={(event, NewValue) => {
                    event.preventDefault()
                    if (NewValue?.id) {
                      setAddInfo({ id: NewValue.id, name: NewValue.name })
                      GetSettingsByGroup(NewValue.id)
                    }
                  }}
                />
              </FormGroup>
              <Button
                style={{ marginTop: '35px', float: 'right', marginLeft: '10px' }}
                variant="contained"
                color="secondary"
                onClick={() => {
                  setAddInfo({ id: '', name: '' })
                  setIsOpenSetting(true)
                  setConfigurationSettingEdit(null)
                }}
              >
                Agregar
              </Button>
              <Button
                style={{ marginTop: '35px', float: 'right', marginLeft: '10px' }}
                variant="contained"
                color="error"
                onClick={() => {
                  if (addInfo.id !== '') {
                    setIsOpenSetting(true)
                    setConfigurationSettingEdit(true)
                  }
                }}
              >
                Editar
              </Button>
            </GridContainer>
          </CardBody>
        </Card>
      </Grid>
      <MUIDataTable data={dataState} columns={columns} options={options} />
      <ModalContainer
        xl={8}
        md={6}
        sm={8}
        open={isOpenSetting}
        onClose={closeModal}
        title={`${configurationSettingEdit ? 'Actualizar' : 'Agregar nueva'} configuración`}
      >
        <ModalConfigurationGeneral
          addInfo={addInfo}
          setAddInfo={setAddInfo}
          onCancel={closeModal}
          createUpdateSettingsGroup={createUpdateSettingsGroup}
        />
      </ModalContainer>

      <ModalContainer
        xl={8}
        md={6}
        sm={8}
        open={isOpen}
        onClose={closeModalSetting}
        title={`${settingEdit ? 'Actualizar' : 'Agregar nuevo'} registro configuración`}
      >
        <ModalConfigurationSetting
          setting={setting}
          setSetting={setSetting}
          toEdit={settingEdit}
          onCancel={closeModalSetting}
          createUpdateSetting={createUpdateSetting}
        />
      </ModalContainer>
    </>
  )
}
