import * as yup from 'yup'

// eslint-disable-next-line import/prefer-default-export
export const schema = yup.object().shape({
  height: yup
    .number()
    .positive('El valor debe ser un número positivo.')
    .typeError('El alto no es valido')
    .required('Campo requerido'),
  width: yup
    .number()
    .positive('El valor debe ser un número positivo.')
    .typeError('El ancho no es valido')
    .required('Campo requerido'),
  length: yup
    .number()
    .positive('El valor debe ser un número positivo.')
    .typeError('El largo no es valido')
    .required('Campo requerido'),
})
