import React, { useEffect, useState } from 'react'

import DateFnsUtils from '@date-io/date-fns'
import {
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import axios from 'axios'
import { format } from 'date-fns'
import deLocale from 'date-fns/locale/es'
import MUIDataTable from 'mui-datatables'

import { filterArrayNulls } from '../../utils/functions'

import useStyles from './accountig.styles'

import Card from 'components/Card/Card'
import CardBody from 'components/Card/CardBody'
import Button from 'components/CustomButtons/Button'
// Template Components
import GridContainer from 'components/Grid/GridContainer'
import useCustomDialogAlert from 'components/useCustomDialogAlert'
// Start Constants
import { API_FINANCIAL_SUMMARY, API_SELLERS } from 'constants/urls'
import textLabels from 'utils/MUIDataTableTextLabels'

export default function FinancialSummary() {
  const defaultdate = new Date()
  const defaultStartDate = new Date()
  defaultStartDate.setDate(defaultdate.getDate() - 30)
  const classes = useStyles()
  const [searchInfo, setSearchInfo] = useState({
    id: 0,
    type: 0,
    nameSeller: 'Todos',
    startDate: defaultStartDate.toISOString(),
    endDate: defaultdate.toISOString(),
  })
  const [data, setData] = useState([])

  const [sellers, setSellers] = useState([])
  const [isLoading, setIsLoading] = useState([])

  const showDialogAlert = useCustomDialogAlert()

  const getSellers = async (total = 30000) => {
    await axios
      .get(`${API_SELLERS}?sellerFilterType=1&pageSize=${total}`)
      .then((response) => {
        setIsLoading(false)
        if (response) {
          if (response.data.pagination.totalCount > parseInt(total)) {
            getSellers(response.pagination.totalCount)
          } else {
            setSellers(filterArrayNulls(response.data.data, 'userName'))
          }
        }
      })
      .catch(() => {
        setIsLoading(false)
      })
  }

  useEffect(() => {
    getSellers()
  }, [])

  const searchData = async (e) => {
    e.preventDefault()
    if (searchInfo.type === 0) {
      showDialogAlert(true, '¡Advertencia!', `Los campos no pueden estar vacíos`, 'Cerrar')
      return false
    }
    try {
      setIsLoading(true)
      const response = await axios.get(
        `${API_FINANCIAL_SUMMARY}/${searchInfo.id}/${searchInfo.type}/${searchInfo.startDate}/${searchInfo.endDate}`
      )

      if (response) {
        if (response.status === 200) {
          setIsLoading(false)
          setData(response.data)
        }
      }
    } catch (error) {
      setIsLoading(false)
      console.error(error)
    }
    return true
  }

  const columns = [
    {
      name: 'transDate',
      label: 'TransDate',
    },
    {
      name: 'accountType',
      label: 'AccountType',
    },
    {
      name: 'ledgerDimension',
      label: 'LedgerDimension',
    },
    {
      name: 'defaultDimension',
      label: 'DefaultDimension',
    },
    {
      name: 'txt',
      label: 'Txt',
    },
    {
      name: 'amountCurDebit',
      label: 'AmountCurDebit',
    },
    {
      name: 'amountCurCredit',
      label: 'AmountCurCredit',
    },
    {
      name: 'offsetAccountType',
      label: 'OffsetAccountType',
    },
    {
      name: 'offsetLedgerDimension',
      label: 'OffsetLedgerDimension',
    },
    {
      name: 'postingProfile',
      label: 'PostingProfile',
    },
    {
      name: 'dueDate',
      label: 'DueDate',
      options: { download: searchInfo.type === 2 },
    },

    {
      name: 'documentNum',
      label: 'DocumentNum',
    },
    {
      name: 'lineNum',
      label: 'LineNum',
    },
    {
      name: 'voucher',
      label: 'Voucher',
    },
    {
      name: 'journalNum',
      label: 'JournalNum',
    },
  ]

  const fileName = () => {
    let text = `Resumen-financiero-${searchInfo.nameSeller}-`

    if (searchInfo.type === 1) {
      text += 'pagos-'
    } else if (searchInfo.type === 2) {
      text += 'completados-'
    }
    text += `${format(new Date(searchInfo.startDate), 'MM/dd/yyyy')}-${format(
      new Date(searchInfo.endDate),
      'MM/dd/yyyy'
    )}.csv`

    return text
  }

  const options = {
    print: false,
    filter: false,
    sort: false,
    download: searchInfo.type !== 0,
    viewColumns: false,
    filterType: 'dropdown',
    responsive: 'scrollMaxHeight',
    rowHover: true,
    selectableRows: 'none',
    textLabels,
    downloadOptions: {
      filename: fileName,
    },
  }

  return (
    <>
      <Grid container justify="center">
        <Grid item md={12} xs={12}>
          <Card className={classes.card}>
            <CardBody>
              <GridContainer
                container
                spacing={2}
                direction="row"
                justify="space-around"
                alignItems="center"
              >
                <Grid item md={2}>
                  <Autocomplete
                    id="seachSeller"
                    options={sellers}
                    getOptionLabel={(option) => (option.name ? option.name : option.email)}
                    autoComplete
                    defualtValue={sellers[0]}
                    includeInputInList
                    onChange={(event, newValue) => {
                      setSearchInfo({
                        ...searchInfo,
                        id: newValue.id,
                        nameSeller: newValue.name,
                      })
                    }}
                    renderInput={(params) => (
                      <TextField {...params} fullWidth label="Buscar seller" />
                    )}
                  />
                </Grid>
                <Grid item md={2}>
                  <FormControl className={classes.formControl}>
                    <InputLabel id="selectType">Tipo de documento</InputLabel>
                    <Select
                      labelId="selectType"
                      id="type"
                      fullWidth
                      displayEmpty
                      className={classes.selectEmpty}
                      // value={age}
                      onChange={(event) => {
                        setSearchInfo({
                          ...searchInfo,
                          type: event.target.value,
                        })
                      }}
                    >
                      <MenuItem value={0}>
                        <em>Seleccione una opción</em>
                      </MenuItem>
                      <MenuItem value={1}>Fase 1</MenuItem>
                      <MenuItem value={2}>Fase 2</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item md={2}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils} locale={deLocale}>
                    <KeyboardDatePicker
                      disableToolbar
                      variant="inline"
                      format="dd/MM/yyyy"
                      margin="normal"
                      id="DateEnd"
                      label="Fecha Inicio"
                      fullWidth
                      value={searchInfo.startDate}
                      onChange={(value) => {
                        setSearchInfo({
                          ...searchInfo,
                          startDate: value.toISOString(),
                        })
                      }}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item md={2}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils} locale={deLocale}>
                    <KeyboardDatePicker
                      disableToolbar
                      variant="inline"
                      format="dd/MM/yyyy"
                      margin="normal"
                      id="DateStart"
                      label="Fecha fin"
                      fullWidth
                      value={searchInfo.endDate}
                      onChange={(value) => {
                        setSearchInfo({
                          ...searchInfo,
                          endDate: value.toISOString(),
                        })
                      }}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>

                <Grid item md={2}>
                  <Button onClick={searchData} disabled={isLoading} color="secondary">
                    <SearchIcon />
                  </Button>
                </Grid>
              </GridContainer>
            </CardBody>
          </Card>
        </Grid>
        {/* start productos table */}

        <Grid item md={12} xs={12}>
          <MUIDataTable
            title={
              <Typography variant="h6">
                Resumen financiero
                {isLoading && (
                  <CircularProgress
                    size={24}
                    style={{ marginLeft: 15, position: 'relative', top: 4 }}
                  />
                )}
              </Typography>
            }
            options={options}
            data={data.map((item) => {
              return [
                format(new Date(item.transDate), 'yyyy-MM-dd'),
                item.accountType,
                item.ledgerDimension,
                item.defaultDimension,

                item.txt,
                item.amountCurDebit,
                item.amountCurCredit,
                item.offsetAccountType,
                item.offsetLedgerDimension,
                item.postingProfile,
                item.dueDate && item.accountType !== 'Contabilidad'
                  ? format(new Date(item.dueDate), 'yyyy-MM-dd')
                  : null,
                item.documentNum,
                item.lineNum,
                item.voucher,
                item.journalNum,
              ]
            })}
            columns={columns}
          />
        </Grid>
      </Grid>
      {alert}
    </>
  )
}
