/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react'

import { Step, StepConnector, StepLabel, Stepper, withStyles } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogTitle from '@material-ui/core/DialogTitle'
import axios from 'axios'
import moment from 'moment'

import ModalCancelationConfirm from './ModalCancelationConfirm'
import ModalCancelationReason from './ModalCancelationReason'
import ModalCancelationSellers from './ModalCancelationSellers'
import ModalinputDate from './ModalinputDate'

import useCustomDialogAlert from 'components/useCustomDialogAlert'
import { VIRTUAL_ORDERS } from 'constants/commercialName'
import { API_ORDERS } from 'constants/urls'
import { translateNameFiltertoType } from 'utils/filterState'
import useStyles from 'views/Products/products.styles'

const CircleConnector = withStyles({
  alternativeLabel: {
    top: 20,
    left: 'calc(-50% + 30px)',
    right: 'calc(50% + 30px)',
  },
  active: {
    '& $line': {
      borderColor: '#6064FF',
    },
  },
  completed: {
    '& $line': {
      borderColor: '#6064FF',
    },
  },
  line: {
    borderColor: '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1,
  },
})(StepConnector)

function getStepsComplete() {
  return ['Seleccione el seller que desee completar', 'Fecha de completado', 'Confirmar']
}
function getStepsProcessing() {
  return ['Seleccione el seller que desee procesar', 'Fecha para procesar', 'Confirmar']
}
function getStepsCancelled() {
  return [
    'Seleccione el seller que desee cancelar',
    'Escriba la razón de la cancelacion',
    'Fecha de cancelación',
    'Confirmar',
  ]
}
function getStepsRefunded() {
  return ['Seleccione el seller que desee devolver', 'Confirmar']
}

const ModalCancelationStepper = ({
  open,
  handleClose,
  infoOrder,
  setIsLoadingBackDrop,
  filterBy,
  filterData,
  getModels,
}) => {
  function getStepContent(step, step1props, step2props, step3props, step4props) {
    if (infoOrder.typeId !== 5) {
      switch (step) {
        case 0:
          return <ModalCancelationSellers {...step1props} />
        case 1:
          return (
            <ModalinputDate
              {...step3props}
              title={infoOrder.typeId === 7 ? 'Fecha para procesar' : 'Fecha de completado'}
            />
          )
        case 2:
          return <ModalCancelationConfirm {...step4props} typeid={infoOrder.typeId} />
        default:
          return 'Confirma el proceso'
      }
    } else {
      switch (step) {
        case 0:
          return <ModalCancelationSellers {...step1props} />
        case 1:
          return <ModalCancelationReason {...step2props} />
        case 2:
          return <ModalinputDate {...step3props} title="Fecha de cancelación" />
        case 3:
          return <ModalCancelationConfirm {...step4props} />
        default:
          return 'Unknown step'
      }
    }
  }
  const showDialogAlert = useCustomDialogAlert()
  const classes = useStyles()
  const [activeStep, setActiveStep] = useState(0)
  const [sellers, setSellers] = useState([])
  let steps = getStepsRefunded()

  if (infoOrder.typeId === 6) {
    steps = getStepsRefunded()
  } else if (infoOrder.typeId === 4) {
    steps = getStepsComplete()
  } else if (infoOrder.typeId === 7) {
    steps = getStepsProcessing()
  } else {
    steps = getStepsCancelled()
  }
  const date = new Date()
  const month = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
  const hours = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours()
  const minutes = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes()
  const seconds = date.getSeconds() < 10 ? `0${date.getSeconds()}` : date.getSeconds()
  const dateCancel = moment(
    `${date.getFullYear()}-${month}-${
      date.getDate() < 10 ? `0${date.getDate()}` : `${date.getDate()}`
    }T${hours}:${minutes}:${seconds}`
  )
    .format()
    .replace('-05:00', '')
  const [inputDate, setInputDate] = useState(dateCancel)
  const [cancelationInfo, setCancelationInfo] = useState({
    filterType: null,
    type: null,
    orderId: [],
    cancelOption: 34,
    reason: '',
    seller: [],
    indexes: [],
  })

  const getSellersList = async () => {
    await axios
      .get(
        `${API_ORDERS}/GetSellersId?numberOrder=${infoOrder.ordersId}&orderFilterType=${infoOrder.actualTypeId}`
      )
      .then((res) => {
        if (res.status === 200) {
          setSellers(res.data)
          let sellersOrders = res.data
          if (infoOrder.actualTypeId === translateNameFiltertoType('Devuelto')) {
            sellersOrders = sellersOrders.filter(
              (seller) => seller.commercialName.trim() === VIRTUAL_ORDERS
            )
          }
          setSellers(sellersOrders)
          if (sellersOrders.length === 1) {
            setCancelationInfo({
              ...cancelationInfo,
              indexes: [0],
              seller: res.data,
              filterType: infoOrder.actualTypeId,
              type: infoOrder.typeId,
              orderId: infoOrder.ordersId,
            })
            setActiveStep(1)
          }
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const handleSellersChange = (rowsSelected) => {
    console.log(rowsSelected)
    setCancelationInfo({
      ...cancelationInfo,
      indexes: rowsSelected,
      seller: sellers.filter((item) => rowsSelected.includes(sellers.indexOf(item))),
    })
  }

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const handleCloseModal = () => {
    handleClose({
      open: false,
      typeId: null,
      ordersId: [],
      actualTypeId: null,
    })
    setCancelationInfo({
      orderId: '',
      indexes: [],
      seller: [],
      reason: '',
    })
    setSellers([])
    setActiveStep(0)
    localStorage.removeItem('cancelationOptionList')
  }

  const handleConfirm = async () => {
    let sellersIds = cancelationInfo.seller.map((item) => item.sellerId)
    if (infoOrder.actualTypeId === translateNameFiltertoType('Devuelto')) {
      sellersIds = cancelationInfo.seller
        .filter((item) => item.commercialName.trim() === VIRTUAL_ORDERS)
        .map((itemId) => itemId.sellerId)
    }
    setIsLoadingBackDrop(true)
    const modelData = {
      filterType: cancelationInfo.filterType,
      type: cancelationInfo.type,
      orderIdList: cancelationInfo.orderId,
      reasonOrderCancellation: cancelationInfo.cancelOption || 34,
      reason: cancelationInfo.cancelOption === 37 ? cancelationInfo.reason : '',
      sellerIds: sellersIds,
      updateDate: inputDate,
    }
    await axios
      .post(`${API_ORDERS}/UpdateOrderList`, modelData)
      .then((res) => {
        if (res.status === 200) {
          setIsLoadingBackDrop(false)
          showDialogAlert(
            true,
            '¡Cambio exitoso!',
            `Se ha cambiado el estado de la orden ${infoOrder.ordersId} a ${
              infoOrder.typeId === 6
                ? 'Devuelto'
                : infoOrder.typeId === 4
                ? 'completado'
                : infoOrder.typeId === 7
                ? 'procesando'
                : 'cancelado'
            }`,
            'Cerrar',
            '',
            true
          )
        }
      })
      .then(() => {
        filterBy([], false, filterData)
        getModels(filterData)
        handleCloseModal()
      })
      .catch(() => {
        showDialogAlert(
          true,
          '¡Error!',
          'Ocurrio un error al cambiar el estado de la orden',
          'Cerrar',
          '',
          true
        )
        setIsLoadingBackDrop(false)
        handleCloseModal()
      })
  }

  useEffect(() => {
    if (open) {
      getSellersList()
      setCancelationInfo({
        ...cancelationInfo,
        filterType: infoOrder.actualTypeId,
        type: infoOrder.typeId,
        orderId: infoOrder.ordersId,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [infoOrder.ordersId])

  return (
    <Dialog open={open} keepMounted onClose={handleCloseModal} fullWidth maxWidth="md">
      <Stepper activeStep={activeStep} alternativeLabel connector={<CircleConnector />}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel
              classes={{
                label: classes.labelStepLabel,
                active: classes.activeStepLabel,
                completed: classes.completedStepLabel,
              }}
              StepIconProps={{
                classes: {
                  root: classes.rootStepIcon,
                  text: classes.text,
                  active: classes.active,
                  completed: classes.completed,
                },
              }}
            >
              {label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
      <div>
        {activeStep === steps.length ? (
          <DialogTitle>Cancelacion en proceso</DialogTitle>
        ) : (
          <>
            {getStepContent(
              activeStep,
              { sellers, cancelationInfo, handleSellersChange },
              { cancelationInfo, setCancelationInfo },
              { inputDate, setInputDate },
              { cancelationInfo }
            )}
            <DialogActions>
              <Button
                disabled={activeStep === 0}
                onClick={handleBack}
                className={classes.backButton}
              >
                Atras
              </Button>
              <Button
                variant="contained"
                className={classes.btnBackgroundErrorPriceSmall}
                onClick={handleCloseModal}
              >
                Cancelar
              </Button>

              {activeStep === steps.length - 1 ? (
                <Button className={classes.btnNewProductsmall} onClick={handleConfirm}>
                  Confirmar
                </Button>
              ) : (
                <Button
                  className={classes.btnNewProductsmall}
                  onClick={handleNext}
                  disabled={activeStep === 0 && !cancelationInfo.seller.length > 0}
                >
                  Siguiente
                </Button>
              )}
            </DialogActions>
          </>
        )}
      </div>
    </Dialog>
  )
}

export default ModalCancelationStepper
