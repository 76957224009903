import { makeStyles } from '@material-ui/core/styles'

import styles from 'assets/jss/material-dashboard-pro-react/views/dashboardStyle'

export default makeStyles((theme) => ({
  ...styles,
  title: {
    fontSize: '25px',
    fontWeight: 'bold',
    paddingTop: '4%',
    paddingRight: '6%',
  },

  form: {
    minWidth: 600,
  },

  titleCard: {
    marginTop: '0',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
  },
  titleProduct: {
    fontSize: '18px',
    fontWeight: 'bold',
  },
  headerTextCard: {
    width: '60%',
  },
  buttons: {
    marginBottom: '20px',
    gap: '12px',
  },
  padding: {
    marginTop: '1vw',
    marginBottom: '1vw',
  },
  center: {
    margin: 'auto',
    position: 'relative',
  },
  gridPadding: {
    paddingLeft: '15%',
    paddingRight: '15%',
  },
  buttonHoverRed: {
    minWidth: 180,
    height: '56px',
    borderRadius: '50px',
    border: '1px solid #6064ff',
    color: '#6064ff',
    fontWeight: '500',
    backgroundColor: '#ffffff',
    '&:hover': {
      color: '#FF40FC',
      border: '1px solid #FF40FC',
      backgroundColor: '#ffffff',
    },
  },
  buttonshoverGreen: {
    minWidth: 180,
    height: '56px',
    borderRadius: '50px',
    border: 'none',
    backgroundColor: '#5e44ff',
    color: 'white',
    fontWeight: '500',
    '&:hover': {
      fontWeight: 'bolder',
      backgroundColor: '#ef0bef',
      boxShadow: '-7px 7px 0px #000000',
      border: '2px solid #000000',
      borderRadius: '50px',
      opacity: '1',
    },
  },
  labelSize: {
    fontSize: 15,
    marginBottom: 8,
  },
  numGuia: {
    position: 'unset',
    transform: 'none',
    marginBottom: 8,
  },
}))
