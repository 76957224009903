/* eslint-disable react/jsx-indent */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-plusplus */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useEffect } from 'react'

import DateFnsUtils from '@date-io/date-fns'
import {
  Button,
  Grid,
  Typography,
  makeStyles,
  TableRow,
  TableCell,
  TableFooter,
  TextField,
  Tooltip,
  IconButton,
  MenuItem,
  Menu,
  Checkbox,
  FormControlLabel,
  Link,
  Paper,
  Select,
  InputBase,
  withStyles,
  Chip,
} from '@material-ui/core'
import { green, red } from '@material-ui/core/colors'
import { Autorenew, CheckOutlined, CloseOutlined, LocalShipping } from '@material-ui/icons'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import axios from 'axios'
import { es } from 'date-fns/locale'
import MUIDataTable from 'mui-datatables'
import { useHistory } from 'react-router-dom'

import AttachmentsModal from './AttachmentsModal'
import DecitionsModal from './DecitionsModal'
import EvidencesModal from './EvidencesModal'
import RefundModal from './RefundModal'
import StatusModal from './StatusModal'

import BackDrop from 'components/BackDrop'
import { EyeIcon, DownloadIcon, SearchIcon } from 'components/icons/personalizedIcons'
import InputField from 'components/InputField'
import useCustomDialogAlert from 'components/useCustomDialogAlert'
import {
  AVAILABLE_CHANGES,
  ORDER_STATUSES,
  RETURN_OPTIONS,
  STATUS_CHANGES,
} from 'constants/returnsConst'
import { SUPER_ADMIN, DROPSHIPPER } from 'constants/rolesConst'
import { routesLink } from 'constants/routesConsts'
import { API_SELLERS } from 'constants/urls'
import useAuthContext from 'contextApi/AuthContext'
import {
  getByTabAndSellerId,
  getStatusesAdmin,
  changeStatuses,
  getRefundPayments,
  sendRefundPayment,
  changeStatusWithoutAction,
  requestMoreInformation,
} from 'services/Returns'
import convertToCurrencyCop from 'utils/convertToCurrencyCop'
import { textLabels, filterArrayNulls } from 'utils/functions'

const ITEM_HEIGHT = 48

const useStyles = makeStyles((theme) => ({
  btnActive: {
    fontWeight: 400,
    backgroundColor: theme.palette.secondary.pink,
    color: theme.palette.white.main,
    border: 0,
    height: 40,
    borderRadius: 5,
    fontFamily: 'Red Hat Display Bold',

    '&:hover': {
      backgroundColor: theme.palette.secondary.pink,
    },
  },
  btnNotActive: {
    fontWeight: 400,
    height: 40,
    border: '1px solid black',
    borderRadius: 5,
    color: theme.palette.black.main,
    fontFamily: 'Red Hat Display Bold',
  },
  fltContainer: {
    position: 'relative',
  },
  quantityLabel: {
    position: 'absolute',
    top: '-10px',
    right: '-10px',
    borderRadius: '50%',
    background: '#6064FF',
    color: 'white',
    lineHeight: '0.5',
    padding: '10px',
    fontSize: '10px',
    fontWeight: 'bold',
    zIndex: 1,
  },
  filterInput: {
    margin: 0,
    minWidth: '270px',
  },
}))

const translateDate = (date) => {
  return new Date(date).toLocaleDateString('es-ES', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  })
}

export const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
    alignItems: 'start',
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}))(InputBase)

const Returns = () => {
  const showDialogAlert = useCustomDialogAlert()
  const [filterActive, setFilterActive] = useState()
  const [statusWOAction, setStatusWOAction] = useState({
    isOpen: false,
    returnInfo: null,
    newStatus: '',
  })
  const classes = useStyles()
  const [loading, setLoading] = useState()
  const [tableData, setTableData] = useState({
    data: [],
    totalRows: 0,
  })
  const [sellerFiltered, setSellerFiltered] = useState()
  const [tabs, setTabs] = useState([])
  const history = useHistory()

  const [statusList, setStatusList] = useState([])
  const [refundPayments, setRefundPayments] = useState([])
  const [sellers, setSellers] = useState([])
  const [confirmationData, setConfirmationData] = useState()
  const [evidencesData, setEvidencesData] = useState()
  const [isOpenChange, setIsOpenChange] = useState({
    isOpen: false,
    decition: true,
  })
  const [isOpenRefundModal, setIsOpenRefundModal] = useState({
    isOpen: false,
    data: {},
  })
  const [isEvidenceOpen, setIsEvidenceOpen] = useState(false)
  const [isOpenAttachments, setIsOpenAttachments] = useState({
    isOpen: false,
    files: [],
  })
  const [request, setRequest] = useState({
    tabs: [10],
    sellerId: null,
    filters: [],
    orderType: 0,
    page: 1,
    size: 10,
  })
  const [customFilter, setCustomFilter] = useState({
    type: 0,
    value: null,
  })
  const [filterList, setFilterList] = useState([])

  const { userLoggedin, updateReturnsCount } = useContext(useAuthContext)
  const [date, setDate] = useState({
    initialDate: null,
    finalDate: new Date(),
  })

  const getReturnsByFilter = async (data) => {
    setLoading(true)
    const requestData = { ...data }
    if (data.dateFrom && data.dateTo) {
      requestData.dateFrom = data.dateFrom
      requestData.dateTo = data.dateTo
    }
    try {
      const result = await getByTabAndSellerId({
        ...requestData,
        orderType: 0,
      })
      setTableData({
        data: result.data,
        totalRows: result.totalRows,
      })
    } catch (err) {
      console.log(err)
    } finally {
      setLoading(false)
    }
  }

  const getTabStatuses = async () => {
    setLoading(true)
    try {
      const { data } = await getStatusesAdmin()
      setTabs(data)
      setFilterActive(filterActive ?? data[0])
      const filtered = []
      data.forEach((element) => {
        filtered.push(...element.children)
      })
      setStatusList(filtered)
    } catch (err) {
      console.log(err)
    } finally {
      setLoading(false)
    }
  }

  const getRefundPaymentsList = async () => {
    setLoading(true)
    try {
      const { data } = await getRefundPayments()
      setRefundPayments(data)
    } catch (err) {
      console.log(err)
    } finally {
      setLoading(false)
    }
  }

  const getSellers = (total = 30000) => {
    axios
      .get(`${API_SELLERS}?sellerFilterType=1&pageSize=${total}`)
      .then((response) => {
        setLoading(false)
        if (response) {
          // eslint-disable-next-line radix
          if (response.data.pagination.totalCount > parseInt(total)) {
            getSellers(response.pagination.totalCount)
          } else {
            const filterData = filterArrayNulls(response.data.data, 'userName')
            setSellers(filterData)
          }
        }
      })
      .catch(() => {
        setLoading(false)
      })
  }

  const getInitialLoad = async () => {
    await getTabStatuses()
    await getRefundPaymentsList()
    if (userLoggedin.role === SUPER_ADMIN) {
      getSellers()
    }
  }

  const onCheckStatusChange = async (data, decition, comments) => {
    setLoading(true)
    const parentStatus = AVAILABLE_CHANGES.find((item) => item.uniqueId === filterActive.uniqueId)
    const childStatus = parentStatus.availableChanges.find((item) => item.decition === decition)
    const detailStatus = statusList.find((item) => item.uniqueId === childStatus.uniqueId)
    const body = {
      returnId: data.returnId,
      detailsStatuses: data.detailIds.map((detailId) => ({
        detailId,
        detailStatus,
        description: comments,
      })),
      user: {
        name: userLoggedin.name,
        email: userLoggedin.email,
      },
    }
    changeStatuses(body).then((response) => {
      if (response.isSuccessful) {
        showDialogAlert(
          true,
          'Cambio de estado',
          'El cambio de estado se realizó correctamente',
          'Aceptar',
          ''
        )
      } else {
        showDialogAlert(true, 'Cambio de estado', response.message, 'Aceptar', '')
      }
      getReturnsByFilter(request).then(() => {
        getTabStatuses()
        updateReturnsCount()
        setLoading(false)
      })
    })
  }

  const handleRefundPayment = async (
    returnId,
    returnDetailId,
    refundType,
    refundUniqueId,
    productName
  ) => {
    setLoading(true)
    const data = {
      returnId,
      returnDetailId,
      refundType,
      refundUniqueId,
    }

    const response = await sendRefundPayment(data)
    if (!response.isSuccessful) {
      if (refundUniqueId === 1) {
        setIsOpenRefundModal({
          isOpen: true,
          data: { ...data, productName },
        })
      } else {
        showDialogAlert(
          true,
          'Reembolso fallido',
          'Ha ocurrido un problema al realizar el reembolso, por favor intente nuevamente o comuníquese con el administrador.',
          'Aceptar',
          ''
        )
      }
      setLoading(false)
    } else {
      showDialogAlert(
        true,
        refundUniqueId === 1 ? 'Producto creado nuevamente' : 'Solicitud de reembolso creada',
        refundUniqueId === 1
          ? 'El producto fue creado nuevamente en la orden sujeta a la solicitud de devolución, puede revisar la orden para verificar el cambio. Si presenta algún inconveniente por favor comuníquese con el administrador.'
          : 'El reembolso de dinero será solicitado a la entidad financiera involucrada, por favor espere a que el proceso sea completado. Si presenta algún inconveniente por favor comuníquese con el administrador.',
        'Aceptar',
        ''
      )
      getReturnsByFilter(request).then(() => {
        setLoading(false)
      })
    }
  }

  const handleNewFilter = (e) => {
    if (e) {
      e.preventDefault()
    }
    if (customFilter.value || (customFilter.type === 4 && date.initialDate && date.finalDate)) {
      // eslint-disable-next-line no-nested-ternary
      const name = customFilter.type === 1 ? 'Orden' : 'Estado de orden'
      const addFilter = filterList.some((f) => f.type === customFilter.type)
      if (customFilter.type === 4) {
        setRequest((prev) => ({
          ...prev,
          page: 1,
          dateFrom: date.initialDate,
          dateTo: date.finalDate,
        }))
      } else if (addFilter) {
        const newFilters = filterList.filter((f) => f.type !== customFilter.type)
        setFilterList([...newFilters, { name, type: customFilter.type, value: customFilter.value }])
        setRequest((prev) => ({
          ...prev,
          page: 1,
          filters: [...newFilters, { type: customFilter.type, value: String(customFilter.value) }],
        }))
      } else {
        setFilterList([...filterList, { name, type: customFilter.type, value: customFilter.value }])
        setRequest({
          ...request,
          page: 1,
          filters: [...filterList, { type: customFilter.type, value: String(customFilter.value) }],
        })
      }
      setCustomFilter({
        value: '',
        type: 0,
      })
    }
  }

  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleDecitionClick = (tableMeta, decition) => {
    const returnData = tableData.data.find((item) =>
      item.returnDetails.find((detail) => detail.id === tableMeta.rowData[0])
    )
    const returnDetail = returnData.returnDetails.find((item) => item.id === tableMeta.rowData[0])

    const ultData = {
      returnId: returnData.returnId,
      detailIds: [returnDetail.id],
      returnInfo: returnData,
      productStatusId: returnDetail.productStatusId,
    }
    setConfirmationData(ultData)
    setIsOpenChange({
      isOpen: true,
      decition,
    })
  }

  const handleStatusChangeWOAction = (returnData, newStatus, description) => {
    setLoading(true)
    changeStatusWithoutAction({
      returnId: returnData.returnId,
      detailsStatuses: [
        {
          description,
          detailId: returnData.returnDetails.id,
          detailStatus: newStatus,
        },
      ],
      user: {
        name: userLoggedin.name,
        email: userLoggedin.email,
      },
    }).then((response) => {
      if (response.isSuccessful) {
        showDialogAlert(
          true,
          'Cambio de estado',
          'El cambio de estado se realizó correctamente',
          'Aceptar',
          ''
        )
      } else {
        showDialogAlert(true, 'Cambio de estado', response.message, 'Aceptar', '')
      }
      getReturnsByFilter(request).then(() => {
        getTabStatuses()
        updateReturnsCount()
        setLoading(false)
      })
    })
  }

  const handleRequestInfo = async (data) => {
    setLoading(true)
    requestMoreInformation({
      ...data,
      user: userLoggedin.firstName,
    })
      .then((res) => {
        if (res.isSuccessful) {
          showDialogAlert(
            true,
            'Cambio de estado',
            'El cambio de estado se realizó correctamente',
            'Aceptar',
            ''
          )
        } else {
          showDialogAlert(true, 'Cambio de estado', res.message, 'Aceptar', '')
        }
        getReturnsByFilter(request).then(() => {
          getTabStatuses()
          updateReturnsCount()
          setLoading(false)
        })
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    getInitialLoad()
  }, [])

  useEffect(() => {
    getReturnsByFilter(request)
  }, [request])

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
      <BackDrop open={loading} />
      <Typography variant="h6" style={{ color: '#6064FF' }}>
        Listado de devoluciones
      </Typography>
      <Grid container spacing={3} alignItems="center">
        {tabs.map((tab) => {
          if (tab.uniqueId === 6 && userLoggedin.role !== SUPER_ADMIN) {
            return null
          }
          return (
            <Grid item key={tab.name}>
              <div className={classes.fltContainer}>
                {tab.returnQuantity > 0 && (
                  <div className={classes.quantityLabel}>{tab.returnQuantity}</div>
                )}
                {filterActive?.uniqueId === tab.uniqueId ? (
                  <Button className={classes.btnActive} variant="outlined">
                    {tab.name}
                  </Button>
                ) : (
                  <Button
                    className={classes.btnNotActive}
                    variant="outlined"
                    onClick={() => {
                      setFilterActive(tab)
                      setRequest({
                        ...request,
                        page: 1,
                        tabs: tab.children.map((t) => t.uniqueId),
                        filters: filterList.map((f) => ({
                          type: f.type,
                          value: String(f.value),
                        })),
                      })
                    }}
                  >
                    {tab.name}
                  </Button>
                )}
              </div>
            </Grid>
          )
        })}
      </Grid>
      <br />
      <Grid container direction="column" xs={12}>
        <Grid item xs={12}>
          <span>Filtros</span>
          <br />
          <Paper component="ul" style={{ width: '100%', marginBottom: '3px' }}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="flex-end"
              xs={12}
            >
              <Grid item>
                <Grid container alignItems="center">
                  <Grid item>
                    <Select
                      variant="outlined"
                      value={customFilter.type}
                      style={{ width: '150px' }}
                      onChange={(e) => {
                        setCustomFilter({
                          type: e.target.value,
                          value: null,
                        })
                      }}
                    >
                      <MenuItem value={0} disabled>
                        Filtros
                      </MenuItem>
                      <MenuItem value={1}>Número de orden</MenuItem>
                      <MenuItem value={2}>Estado de orden</MenuItem>
                      <MenuItem value={3}>Razón de devolución</MenuItem>
                      <MenuItem value={4}>Fecha de solicitud</MenuItem>
                      {userLoggedin.role === SUPER_ADMIN && <MenuItem value={5}>Tienda</MenuItem>}
                    </Select>
                  </Grid>
                  {customFilter.type === 4 ? (
                    <>
                      <Grid item>
                        <DateTimePicker
                          className={classes.filterInput}
                          autoOk
                          fullWidth
                          name="initialDate"
                          inputVariant="outlined"
                          placeholder="Desde"
                          maxDate={date.finalDate || new Date()}
                          onChange={(initDate) => {
                            setDate({ ...date, initialDate: initDate })
                          }}
                          value={date.initialDate}
                        />
                      </Grid>
                      -
                      <Grid item>
                        <DateTimePicker
                          className={classes.filterInput}
                          autoOk
                          fullWidth
                          name="finalDate"
                          inputVariant="outlined"
                          placeholder="Hasta"
                          maxDate={new Date()}
                          onChange={(finalDate) => {
                            setDate({ ...date, finalDate: finalDate })
                          }}
                          value={date.finalDate}
                        />
                      </Grid>
                    </>
                  ) : customFilter.type === 5 ? (
                    <Autocomplete
                      id="seachSeller"
                      options={sellers}
                      getOptionLabel={(option) => (option.name ? option.name : option.email)}
                      autoComplete
                      includeInputInList
                      value={sellerFiltered}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          fullWidth
                          className={classes.filterInput}
                        />
                      )}
                      onChange={(event, NewValue) => {
                        event.preventDefault()
                        if (NewValue?.id) {
                          setSellerFiltered(NewValue)
                          setRequest((prev) => ({
                            ...prev,
                            page: 1,
                            sellerId: NewValue.id,
                          }))
                        }
                      }}
                    />
                  ) : [2, 3].includes(customFilter.type) ? (
                    <Grid item>
                      <Select
                        variant="outlined"
                        inputProps={{ className: classes.filterInput }}
                        value={customFilter.value}
                        onChange={(e) => {
                          setCustomFilter({
                            ...customFilter,
                            value: e.target.value,
                          })
                        }}
                      >
                        <MenuItem value="" disabled>
                          Seleccione
                        </MenuItem>
                        {customFilter.type === 2
                          ? ORDER_STATUSES.map((status) => (
                              <MenuItem key={status.id} value={status.id}>
                                {status.name}
                              </MenuItem>
                            ))
                          : RETURN_OPTIONS.map((option) => (
                              <MenuItem key={option.id} value={option.id}>
                                {option.name}
                              </MenuItem>
                            ))}
                      </Select>
                    </Grid>
                  ) : (
                    <Grid item>
                      <InputField
                        className={classes.filterInput}
                        name="filterInput"
                        type="number"
                        value={customFilter.value}
                        disabled={customFilter.type === 0}
                        onChange={(e) => {
                          setCustomFilter({
                            ...customFilter,
                            value: e.target.value,
                          })
                        }}
                        placeholder={customFilter.type === 0 ? 'Seleccione un filtro' : ''}
                      />
                    </Grid>
                  )}
                  {customFilter.type !== 5 && (
                    <IconButton onClick={handleNewFilter}>
                      <SearchIcon />
                    </IconButton>
                  )}
                </Grid>
              </Grid>
              <Grid item>
                <Grid container direction="row" alignItems="center" spacing={1}>
                  {request.dateTo && request.dateFrom && (
                    <Grid item>
                      <li>
                        <Chip
                          label={`FECHA DE SOLICITUD: ${translateDate(request.dateFrom)}
                           - ${translateDate(request.dateTo)}`}
                          onDelete={() => {
                            const requestWODate = { ...request }
                            delete requestWODate.dateFrom
                            delete requestWODate.dateTo
                            setDate({
                              initialDate: null,
                              finalDate: new Date(),
                            })
                            setRequest({
                              ...requestWODate,
                              page: 1,
                            })
                          }}
                        />
                      </li>
                    </Grid>
                  )}
                  {request.sellerId && (
                    <Grid item>
                      <li>
                        <Chip
                          label={`TIENDA: ${
                            sellers.find((seller) => seller.id === request.sellerId).name
                          }`}
                          onDelete={() => {
                            const requestWODate = { ...request }
                            delete requestWODate.sellerId
                            setSellerFiltered(null)
                            setRequest({
                              ...requestWODate,
                              page: 1,
                            })
                          }}
                        />
                      </li>
                    </Grid>
                  )}
                  {filterList.map(({ type, value, name }) => (
                    <Grid item key={type}>
                      <li>
                        <Chip
                          label={`${String(name).toLocaleUpperCase()}: ${String(
                            // eslint-disable-next-line no-nested-ternary
                            type === 2
                              ? ORDER_STATUSES.find((s) => s.id === value)?.name
                              : type === 3
                              ? RETURN_OPTIONS.find((o) => o.id === value)?.name
                              : value
                          ).toLocaleUpperCase()}`}
                          onDelete={() => {
                            setFilterList(filterList.filter((filter) => filter.type !== type))
                            setRequest({
                              ...request,
                              page: 1,
                              filters: filterList.filter((filter) => filter.type !== type),
                            })
                          }}
                        />
                      </li>
                    </Grid>
                  ))}
                  <Grid item>
                    <IconButton
                      onClick={() => {
                        getReturnsByFilter(request)
                      }}
                    >
                      <Autorenew />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <MUIDataTable
            data={tableData.data}
            options={{
              print: false,
              filter: false,
              download: false,
              viewColumns: false,
              serverSide: true,
              expandableRows: true,
              search: false,
              sort: false,
              count: tableData.totalRows,
              onChangePage: (currentPage) => {
                setRequest((prevState) => ({
                  ...prevState,
                  page: currentPage + 1,
                }))
              },
              onChangeRowsPerPage: (rows) => {
                setRequest((prevState) => ({
                  ...prevState,
                  size: rows,
                }))
              },
              page: request.page - 1,
              tableBodyMaxHeight: '70vh',
              tableBodyHeight: '65vh',
              expandableRowsHeader: false,
              textLabels: textLabels(loading),
              selectableRows: 'none',
              fixedHeader: true,
              renderExpandableRow: (rowData) => {
                const colSpan = rowData.length + 1
                const { returnDetails } = tableData.data.find((row) => row.returnId === rowData[0])
                return (
                  <TableRow>
                    <TableCell
                      colSpan={colSpan}
                      style={{ backgroundColor: 'white', padding: '2%', paddingTop: '5px' }}
                    >
                      <MUIDataTable
                        options={{
                          print: false,
                          filter: false,
                          download: false,
                          viewColumns: false,
                          search: false,
                          sort: false,
                          selectableRows: returnDetails.length > 1 ? 'multiple' : 'none',
                          textLabels: textLabels(loading),
                          customFooter: () => <TableFooter />,
                        }}
                        data={returnDetails}
                        columns={[
                          {
                            name: 'id',
                            label: '',
                            options: {
                              display: false,
                            },
                          },
                          {
                            name: 'orderDetailId',
                            label: '',
                            options: {
                              display: 'hidden',
                              customHeadLabelRender: () => {
                                return (
                                  <th style={{ fontFamily: 'Red Hat Display Black', fontSize: 13 }}>
                                    LINEA DE PRODUCTO
                                  </th>
                                )
                              },
                            },
                          },
                          {
                            name: 'productName',
                            label: '',
                            options: {
                              customHeadLabelRender: () => {
                                return (
                                  <th style={{ fontFamily: 'Red Hat Display Black', fontSize: 13 }}>
                                    PRODUCTO
                                  </th>
                                )
                              },
                              customBodyRender: (value) => {
                                return (
                                  <Link
                                    href={`${routesLink.products}?product=${value}`}
                                    color="secondary"
                                    onClick={(event) => {
                                      event.preventDefault()
                                      history.push(`${routesLink.products}?product=${value}`)
                                    }}
                                  >
                                    <Typography variant="body1" style={{ marginLeft: '5%' }}>
                                      {value}
                                    </Typography>
                                  </Link>
                                )
                              },
                            },
                          },
                          {
                            name: 'productStatusName',
                            label: '',
                            options: {
                              customHeadLabelRender: () => {
                                return (
                                  <th style={{ fontFamily: 'Red Hat Display Black', fontSize: 13 }}>
                                    ESTADO DE ORDEN
                                  </th>
                                )
                              },
                              customBodyRender: (value) => {
                                return (
                                  <div style={{ fontFamily: 'Red Hat Display Regular' }}>
                                    {value || 'N/A'}
                                  </div>
                                )
                              },
                            },
                          },
                          {
                            name: 'refundSelected',
                            label: '',
                            options: {
                              customHeadLabelRender: () => {
                                return (
                                  <th style={{ fontFamily: 'Red Hat Display Black', fontSize: 13 }}>
                                    METODO DE REEMBOLSO PREFERIDO
                                  </th>
                                )
                              },
                              customBodyRender: (value) => {
                                return (
                                  <div style={{ fontFamily: 'Red Hat Display Regular' }}>
                                    {value.type}
                                  </div>
                                )
                              },
                            },
                          },
                          {
                            name: 'productPrice',
                            label: '',
                            options: {
                              customHeadLabelRender: () => {
                                return (
                                  <th style={{ fontFamily: 'Red Hat Display Black', fontSize: 13 }}>
                                    VALOR UNITARIO
                                  </th>
                                )
                              },
                              customBodyRender: (value) => {
                                return (
                                  <div style={{ fontFamily: 'Red Hat Display Regular' }}>
                                    {`$${convertToCurrencyCop(Number(value))}`}
                                  </div>
                                )
                              },
                            },
                          },
                          {
                            name: 'attachments',
                            label: '',
                            options: {
                              customHeadLabelRender: () => (
                                <th style={{ fontFamily: 'Red Hat Display Black', fontSize: 13 }}>
                                  OBSERVACIONES
                                </th>
                              ),

                              customBodyRender: (value, tableMeta) => {
                                return (
                                  <Grid container direction="row" spacing={1}>
                                    <Grid item>
                                      <Tooltip title="Ver observaciones">
                                        <IconButton
                                          style={{
                                            margin: '0 0 0 40%',
                                          }}
                                          onClick={() => {
                                            const returnData = tableData.data.find((item) =>
                                              item.returnDetails.find(
                                                (detail) => detail.id === tableMeta.rowData[0]
                                              )
                                            )
                                            const returnDetail = returnData.returnDetails.find(
                                              (item) => item.id === tableMeta.rowData[0]
                                            )
                                            setEvidencesData(() => ({
                                              returnDetail,
                                              returnData,
                                            }))
                                            setIsEvidenceOpen(true)
                                          }}
                                        >
                                          <EyeIcon />
                                        </IconButton>
                                      </Tooltip>
                                    </Grid>
                                  </Grid>
                                )
                              },
                            },
                          },
                          {
                            name: 'download',
                            label: '',
                            options: {
                              customHeadLabelRender: () => (
                                <th style={{ fontFamily: 'Red Hat Display Black', fontSize: 13 }}>
                                  ADJUNTOS
                                </th>
                              ),
                              customBodyRender: (value, tableMeta) => {
                                return (
                                  <Grid container direction="row" spacing={1}>
                                    <Grid item>
                                      <Tooltip title="Descargar adjuntos">
                                        <IconButton
                                          style={{
                                            margin: '0 0 0 40%',
                                          }}
                                          disabled={tableMeta.rowData[6].length === 0}
                                          onClick={() => {
                                            const returnData = tableData.data.find((item) =>
                                              item.returnDetails.find(
                                                (detail) => detail.id === tableMeta.rowData[0]
                                              )
                                            )
                                            const returnDetail = returnData.returnDetails.find(
                                              (item) => item.id === tableMeta.rowData[0]
                                            )
                                            setIsOpenAttachments({
                                              isOpen: true,
                                              files: returnDetail.attachments,
                                            })
                                          }}
                                        >
                                          <DownloadIcon />
                                        </IconButton>
                                      </Tooltip>
                                    </Grid>
                                  </Grid>
                                )
                              },
                            },
                          },
                          {
                            name: 'guideReturn',
                            label: '',
                            options: {
                              display: returnDetails[0].guideReturn?.guideUrl ? 'true' : 'false',
                              customHeadLabelRender: () => (
                                <th style={{ fontFamily: 'Red Hat Display Black', fontSize: 13 }}>
                                  GUÍA DE DEVOLUCIÓN
                                </th>
                              ),
                              customBodyRender: (value) => {
                                return (
                                  <Grid container direction="row" spacing={1}>
                                    <Grid item>
                                      <Tooltip title="Descargar guía">
                                        <IconButton
                                          style={{
                                            borderRadius: '1rem',
                                          }}
                                          onClick={() => {
                                            window.open(value?.guideUrl, '_blank')
                                          }}
                                        >
                                          <LocalShipping />
                                          <Typography
                                            variant="body1"
                                            style={{
                                              marginLeft: '5%',
                                              fontFamily: 'Red Hat Display Regular',
                                            }}
                                          >
                                            {value?.number}
                                          </Typography>
                                        </IconButton>
                                      </Tooltip>
                                    </Grid>
                                  </Grid>
                                )
                              },
                            },
                          },
                          {
                            name: 'returnDate',
                            label: 'Fecha de recogida',
                            options: {
                              display: returnDetails[0].guideReturn?.guideUrl ? 'true' : 'false',
                              customHeadLabelRender: () => (
                                <th style={{ fontFamily: 'Red Hat Display Black', fontSize: 13 }}>
                                  FECHA DE RECOGIDA
                                </th>
                              ),
                              customBodyRender: (value, tableMeta) => {
                                const guideReturn = tableMeta.rowData[8]
                                const pickUpDate = guideReturn?.pickUpDate?.split(' ')[0]
                                const pickUpRangeTime = guideReturn?.pickUpRangeTime
                                const guideDate = pickUpDate
                                  ? `${pickUpDate} | ${pickUpRangeTime}`
                                  : 'N/A'
                                return (
                                  <div style={{ fontFamily: 'Red Hat Display Regular' }}>
                                    {guideDate}
                                  </div>
                                )
                              },
                            },
                          },
                          {
                            name: 'decitions',
                            label: '',
                            options: {
                              display: [1, 2].includes(filterActive.uniqueId),
                              customHeadLabelRender: () => (
                                <th style={{ fontFamily: 'Red Hat Display Black', fontSize: 13 }}>
                                  DECISION
                                </th>
                              ),
                              customBodyRender: (value, tableMeta) => {
                                const disabled =
                                  (userLoggedin.role === SUPER_ADMIN &&
                                    filterActive.uniqueId === 1) ||
                                  (userLoggedin.role === DROPSHIPPER && filterActive.uniqueId === 2)
                                return (
                                  <Grid container direction="row" spacing={1}>
                                    <Tooltip title="Aprobar">
                                      <IconButton
                                        disabled={disabled}
                                        onClick={() => {
                                          handleDecitionClick(tableMeta, true)
                                        }}
                                      >
                                        <CheckOutlined
                                          style={{ color: disabled ? 'gray' : green[500] }}
                                        />
                                      </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Rechazar">
                                      <IconButton
                                        disabled={disabled}
                                        onClick={() => {
                                          handleDecitionClick(tableMeta, false)
                                        }}
                                      >
                                        <CloseOutlined
                                          style={{ color: disabled ? 'gray' : red[500] }}
                                        />
                                      </IconButton>
                                    </Tooltip>
                                  </Grid>
                                )
                              },
                            },
                          },
                          {
                            name: 'productReceivedByCarrier',
                            label: '',
                            options: {
                              display: 'false',
                            },
                          },
                          {
                            name: 'productDeliveredByCarrier',
                            label: '',
                            options: {
                              display: 'false',
                            },
                          },
                          {
                            name: 'carrier',
                            label: 'TRANSPORTADORA',
                            options: {
                              display: [3].includes(filterActive.uniqueId) && false,
                              customHeadLabelRender: () => (
                                <th style={{ fontFamily: 'Red Hat Display Black', fontSize: 13 }}>
                                  TRANSPORTADORA
                                </th>
                              ),
                              customBodyRender: (value, tableMeta) => {
                                return (
                                  <Grid container direction="column">
                                    <Grid item>
                                      <FormControlLabel
                                        disabled
                                        control={<Checkbox checked={tableMeta.rowData[5]} />}
                                        label="Producto recogido"
                                      />
                                    </Grid>
                                    <Grid item>
                                      <FormControlLabel
                                        disabled
                                        control={<Checkbox checked={tableMeta.rowData[6]} />}
                                        label="Producto entregado"
                                      />
                                    </Grid>
                                  </Grid>
                                )
                              },
                            },
                          },
                          {
                            name: 'approveActions',
                            label: '',
                            options: {
                              display:
                                (filterActive.uniqueId === 2 &&
                                  userLoggedin.role === SUPER_ADMIN) ||
                                filterActive.uniqueId === 3,
                              customHeadLabelRender: () => (
                                <th style={{ fontFamily: 'Red Hat Display Black', fontSize: 13 }} />
                              ),
                              customBodyRender: (value, tableMeta) => {
                                const returnData = tableData.data.find((item) =>
                                  item.returnDetails.find(
                                    (detail) => detail.id === tableMeta.rowData[0]
                                  )
                                )
                                const returnDetail = returnData.returnDetails.find(
                                  (item) => item.id === tableMeta.rowData[0]
                                )
                                return (
                                  <div>
                                    <IconButton
                                      aria-label="more"
                                      aria-controls="long-menu"
                                      aria-haspopup="true"
                                      onClick={handleClick}
                                    >
                                      <MoreVertIcon />
                                    </IconButton>
                                    <Menu
                                      id="long-menu"
                                      anchorEl={anchorEl}
                                      keepMounted
                                      open={open}
                                      onClose={handleClose}
                                      PaperProps={{
                                        style: {
                                          maxHeight: ITEM_HEIGHT * 4.5,
                                          width: '38ch',
                                          boxShadow: '-6px 6px #E8E8E8',
                                          fontFamily: 'Red Hat Display Regular',
                                        },
                                      }}
                                    >
                                      {filterActive.uniqueId === 2 && (
                                        <MenuItem
                                          onClick={() => {
                                            handleRequestInfo({
                                              returnId: returnData.returnId,
                                              returnDetailId: returnDetail.id,
                                            })
                                            handleClose()
                                          }}
                                        >
                                          Solicitar mas evidencias
                                        </MenuItem>
                                      )}
                                      {filterActive.uniqueId === 3 &&
                                        refundPayments.map((option) => {
                                          if (
                                            (option.uniqueId === 1 &&
                                              returnDetail.reasonReturn.id === 3) ||
                                            (option.uniqueId === 2 &&
                                              returnDetail.reasonReturn.id === 1)
                                          )
                                            return null
                                          return (
                                            <MenuItem
                                              key={option.id}
                                              onClick={() => {
                                                handleRefundPayment(
                                                  returnData.returnId,
                                                  returnDetail.id,
                                                  option.type,
                                                  option.uniqueId,
                                                  returnDetail.productName
                                                )
                                                handleClose()
                                              }}
                                            >
                                              {option.type}
                                            </MenuItem>
                                          )
                                        })}
                                      {userLoggedin.role === SUPER_ADMIN &&
                                        (filterActive.uniqueId === 2 ? (
                                          <MenuItem
                                            onClick={() => {
                                              setStatusWOAction({
                                                returnInfo: {
                                                  ...returnData,
                                                  returnDetails: returnDetail,
                                                },
                                                isOpen: true,
                                                newStatus: {
                                                  uniqueId: 60,
                                                  name: 'Archivado',
                                                },
                                              })
                                              handleClose()
                                            }}
                                          >
                                            Mover a Archivado
                                          </MenuItem>
                                        ) : (
                                          STATUS_CHANGES.map((option) => {
                                            return (
                                              <MenuItem
                                                key={option.uniqueId}
                                                onClick={() => {
                                                  setStatusWOAction({
                                                    returnInfo: {
                                                      ...returnData,
                                                      returnDetails: returnDetail,
                                                    },
                                                    isOpen: true,
                                                    newStatus: option,
                                                  })
                                                  handleClose()
                                                }}
                                              >
                                                {`Mover a ${option.name}`}
                                              </MenuItem>
                                            )
                                          })
                                        ))}
                                    </Menu>
                                  </div>
                                )
                              },
                            },
                          },
                        ]}
                      />
                    </TableCell>
                  </TableRow>
                )
              },
            }}
            columns={[
              {
                name: 'returnId',
                label: '',
                options: { display: false },
              },
              {
                name: 'orderNumber',
                label: '',
                options: {
                  customHeadLabelRender: () => {
                    return (
                      <th style={{ fontFamily: 'Red Hat Display Black', fontSize: 13 }}>
                        NÚMERO DE ORDEN
                      </th>
                    )
                  },
                  customBodyRender: (value) => {
                    return (
                      <Link
                        href={`${routesLink.viewOrder}/${value}`}
                        color="secondary"
                        onClick={(event) => {
                          event.preventDefault()
                          history.push(`${routesLink.viewOrder}/${value}`)
                        }}
                      >
                        <Typography variant="body1" style={{ marginLeft: '5%' }}>
                          {value}
                        </Typography>
                      </Link>
                    )
                  },
                },
              },
              {
                name: 'orderPaymentMethods',
                label: '',
                options: {
                  customHeadLabelRender: () => {
                    return (
                      <th style={{ fontFamily: 'Red Hat Display Black', fontSize: 13 }}>
                        MEDIO DE PAGO USADO
                      </th>
                    )
                  },
                },
              },
              {
                name: 'returnDetails',
                label: '',
                options: {
                  customHeadLabelRender: () => {
                    return (
                      <th style={{ fontFamily: 'Red Hat Display Black', fontSize: 13 }}>
                        RAZÓN DE DEVOLUCION
                      </th>
                    )
                  },
                  customBodyRender: (value) => {
                    return (
                      <div>
                        {`${value[0].reasonReturn.type}: ${value[0].reasonReturn.optionSelected}`}
                      </div>
                    )
                  },
                },
              },
              {
                name: 'sellerName',
                label: '',
                options: {
                  display: userLoggedin.role === SUPER_ADMIN,
                  customHeadLabelRender: () => {
                    return (
                      <th style={{ fontFamily: 'Red Hat Display Black', fontSize: 13 }}>TIENDA</th>
                    )
                  },
                },
              },
              {
                name: 'status',
                label: '',
                options: {
                  customHeadLabelRender: () => {
                    return (
                      <th style={{ fontFamily: 'Red Hat Display Black', fontSize: 13 }}>
                        ESTADO DE DEVOLUCIÓN
                      </th>
                    )
                  },
                  customBodyRender: (value, tableMeta) => {
                    const uniqueStatusNames = [
                      ...new Set(tableMeta.rowData[3].map((item) => item.status.name)),
                    ]
                    const statusString = uniqueStatusNames.join(' - ')
                    return statusString
                  },
                },
              },
            ]}
          />
        </Grid>
      </Grid>

      <EvidencesModal
        returnInfo={evidencesData}
        isOpen={isEvidenceOpen}
        handleClose={() => {
          setIsEvidenceOpen(false)
        }}
      />
      <AttachmentsModal
        isOpen={isOpenAttachments.isOpen}
        files={isOpenAttachments.files}
        handleClose={() => {
          setIsOpenAttachments({ isOpen: false, files: [] })
        }}
      />
      <StatusModal
        onCheck={({ returnInfo, comment, newStatus }) => {
          handleStatusChangeWOAction(returnInfo, newStatus, comment)
        }}
        isOpen={statusWOAction.isOpen}
        handleClose={() => {
          setStatusWOAction((prev) => ({
            ...prev,
            isOpen: false,
          }))
        }}
        returnInfo={statusWOAction.returnInfo}
        newStatus={statusWOAction.newStatus}
      />
      <DecitionsModal
        returnInfo={confirmationData}
        isOpen={isOpenChange.isOpen}
        decition={isOpenChange.decition}
        onCheck={onCheckStatusChange}
        handleClose={() =>
          setIsOpenChange({
            isOpen: false,
            decition: true,
          })
        }
      />
      <RefundModal
        isOpen={isOpenRefundModal.isOpen}
        data={isOpenRefundModal.data}
        handleClose={() => setIsOpenRefundModal({ isOpen: false, data: {} })}
        secondRefundOption={refundPayments.find((item) => item.uniqueId === 2)}
        showDialogAlert={showDialogAlert}
      />
    </MuiPickersUtilsProvider>
  )
}

export default Returns
