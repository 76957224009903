import React from 'react'

import CircularProgress from '@material-ui/core/CircularProgress'
import PropTypes from 'prop-types'

import useStyles from './styles'

import Button from 'components/CustomButtons/Button'

const ButtonLoading = ({ color, label, isLoading, onClick, disabled, type }) => {
  const classes = useStyles()

  return (
    <div className={classes.wrapper}>
      <Button
        disabled={isLoading ? true : disabled}
        className={classes.btnBackground}
        onClick={onClick}
        color={isLoading ? null : color}
        type={type}
      >
        {label}
      </Button>
      {isLoading && <CircularProgress size={26} className={classes.buttonProgress} />}
    </div>
  )
}

ButtonLoading.defaultProps = {
  disabled: false,
  onClick: () => null,
  isLoading: false,
  color: 'secondary',
  label: 'Label Button',
}

ButtonLoading.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  isLoading: PropTypes.bool,
  color: PropTypes.string,
  label: PropTypes.string,
}

export default ButtonLoading
