import { makeStyles } from '@material-ui/core/styles'

import styles from 'assets/jss/material-dashboard-pro-react/views/dashboardStyle'

export default makeStyles((theme) => ({
  ...styles,
  ordenItems: {
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    '@media (max-width: 600px)': {
      '& .MuiDialog-paper': {
        width: '100%',
      },
      '& .MuiDialogContent-root': {
        padding: '0 !important',
      },
    },
    '@media (min-width: 601px) and (max-width: 1024px)': {
      '& .MuiDialog-paper': {
        width: '80%',
      },
    },
    '@media (min-width: 1025px)': {
      '& .MuiDialog-paper': {
        width: '35%',
      },
    },
  },
  buttonHoverRed: {
    minWidth: 180,
    marginBottom: '1.5vh',
    background: theme.palette.grey.dark,

    '&:hover': {
      background: theme.palette.error.main,
    },
  },
  buttonshoverGreen: {
    minWidth: 180,
    fill: theme.palette.white.main,
    marginBottom: '1.5vh',
    '&:hover': {
      background: theme.palette.primary.main,
    },
  },
  btnBackgroundPrimary: {
    minWidth: '100px',
    padding: '8px, 20px, 10px, 20px',
    borderRadius: '50px',
    border: 'none',
    margin: '20px 0',
    backgroundColor: '#5e44ff',
    color: 'white',
    fontWeight: '500',
    '&:hover': {
      fontWeight: 'bolder',
      backgroundColor: '#ef0bef',
      boxShadow: '-7px 7px 0px #000000',
      border: '2px solid #000000',
      borderRadius: '50px',
      opacity: '1',
    },
  },
  btnBackgroundError: {
    minWidth: '100px',
    padding: '8px, 20px, 10px, 20px',
    borderRadius: '50px',
    border: '1px solid #6064ff',
    color: '#6064ff',
    fontWeight: '500',
    backgroundColor: '#ffffff',
    '&:hover': {
      color: '#FF40FC',
      border: '1px solid #FF40FC',
      backgroundColor: '#ffffff',
    },
  },
  marginContentContent: {
    marginTop: '1vh',
    marginBottom: '1vh',
  },
  marginTitleContent: {
    marginTop: '1vh',
    paddingLeft: '14px',
  },
}))
