/* eslint-disable react/prop-types */
import React from 'react'

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Button,
  Grid,
} from '@material-ui/core'
import PropTypes from 'prop-types'

import useStyles from './DialogAlert.styles'

import { SuccessfulIcon } from 'components/icons/personalizedIcons'

function DialogAlert({
  open,
  title,
  handleClose,
  message,
  personalized,
  textButtonConfirm,
  actionConfirm,
  actionCancel,
  textButtonCancel,
  children,
  successful,
}) {
  const classes = useStyles()
  return (
    <Dialog
      open={open}
      disableBackdropClick
      disableEscapeKeyDown
      onClose={handleClose}
      className={classes.ordenItems}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <Grid container direction="row" justify="center" alignItems="center">
          {successful ? (
            <Grid item>
              {' '}
              <SuccessfulIcon />
            </Grid>
          ) : null}
          <Grid item>
            {' '}
            <Typography variant="body1" className={classes.marginTitleContent}>
              {title}
            </Typography>
          </Grid>
        </Grid>
      </DialogTitle>
      {personalized ? (
        <DialogContent>{children}</DialogContent>
      ) : message ? (
        <DialogContent>
          <Typography variant="paragraph" className={classes.marginContentContent}>
            {message}
          </Typography>
        </DialogContent>
      ) : null}
      <DialogActions className={classes.ordenItems}>
        {textButtonCancel ? (
          <Button
            onClick={() => (actionCancel ? actionCancel() : handleClose())}
            variant="contained"
            color="secondary"
            className={classes.btnBackgroundError}
          >
            {textButtonCancel}
          </Button>
        ) : null}
        {textButtonConfirm ? (
          <Button
            onClick={() => {
              let reponse = true
              if (actionConfirm) {
                reponse = actionConfirm()
              }
              if (reponse) handleClose()
            }}
            variant="contained"
            color="secondary"
            className={classes.btnBackgroundPrimary}
          >
            {textButtonConfirm}
          </Button>
        ) : null}
      </DialogActions>
    </Dialog>
  )
}

DialogAlert.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  message: PropTypes.string,
  textButtonConfirm: PropTypes.string,
  actionConfirm: PropTypes.func,
  textButtonCancel: PropTypes.string,
  personalized: PropTypes.bool,
}

DialogAlert.defaultProps = {
  message: undefined,
  textButtonConfirm: undefined,
  actionConfirm: undefined,
  textButtonCancel: undefined,
  personalized: null,
}
export default DialogAlert