import React, { useState } from 'react'

import { Grid, Typography } from '@material-ui/core'
import axios from 'axios'

import BalancesSection from './BalancesSection'
import FormFecha from './FormFecha'
import PeriodsPanel from './PeriodsPanel'
import useStyles from './ReportsAccountStyle'

import { API_FINANCIAL_SUMMARY } from 'constants/urls'

export default function ContentAccounStatus() {
  const [data, setData] = useState([])
  const [isLoadingData, setIsLoadingData] = useState(false)
  const classes = useStyles()
  const handleRowPeriod = (id) => {
    setIsLoadingData(true)
    axios
      .get(`${API_FINANCIAL_SUMMARY}/GetGeneralBalance?SellerId=0&PeriodId=${id}`)
      .then(function (response) {
        setData(response.data.data)
        console.log(response.data.data)
      })
      .catch(function (error) {
        console.log(error)
      })
    console.log(id)
  }

  return (
    <>
      <Typography variant="h6" align="left">
        Informes estado de la cuenta
      </Typography>
      {/* <FormFecha /> */}
      <Grid container className={classes.contentPanelAndBalance}>
        <PeriodsPanel setIsLoadingData={setIsLoadingData} handleRowPeriod={handleRowPeriod} />
        {isLoadingData && <BalancesSection data={data} />}
      </Grid>
    </>
  )
}
