import React, { useEffect, useRef } from 'react'

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  container: {
    fontFamily: '"Roboto", "Helvetica", "Arial", "sans-serif"',
    padding: '10px 10px',
    maxHeight: '600px',
    scrollBehavior: 'smooth',
    overflowY: 'auto',
    margin: '0 16px',
    '& ul': {
      position: 'relative',
      listStyle: 'none',
      padding: 0,
      margin: 0,
      '&:before': {
        content: '""',
        position: 'absolute',
        top: '-10px',
        left: '-20px',
        height: '100%',
      },
    },
  },
  box: {
    margin: '50px 0',
    padding: '10px 20px',
    background: 'rgba(0, 0, 0, 0)',
    position: 'relative',
    borderRadius: '5px',
    boxSizing: 'border-box',
    boxShadow: '-6px 6px #E8E8E8',
    '& > span': {
      content: '""',
      position: 'absolute',
      top: '-10px',
      left: '-20px',
      height: '100%',
      border: '1px solid #ccc',
      '&:before': {
        top: '-5px',
        content: '""',
        position: 'absolute',
        left: '-5px',
        width: '10px',
        height: '10px',
        border: '2px solid #ccc',
        background: '#6064FF',
        borderRadius: '100%',
      },
    },
    '& .title': {
      fontSize: '1rem',
      textTransform: 'uppercase',
      marginTop: '10px',
    },
    '& .sub-title': {
      fontSize: '0.9rem',
      color: '#aaa',
    },
    '& .info': {
      margin: '15px 0',
    },
    '& .time span': {
      position: 'absolute',
      fontSize: '0.8rem',
      color: '#aaa',
      top: '-20px',
      left: '0',
    },
  },
}))

const Timeline = ({ comments }) => {
  const classes = useStyles()
  const containerRef = useRef(null)

  useEffect(() => {
    const container = containerRef.current
    container.scrollTop = container.scrollHeight
  }, [comments])

  return (
    <div className={classes.container} ref={containerRef}>
      <ul>
        {comments.map(({ user, date, description, afterValue, type }, i) => (
          <li className={classes.box} key={i}>
            {/* <span /> */}
            <div className="title">{user}</div>
            <div className="sub-title">{type === 'note' ? '' : afterValue}</div>
            <div className="info">
              <p>{description}</p>
            </div>
            <div className="time">
              <span>
                {new Date(date).toLocaleDateString('es-ES', {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                  hour: 'numeric',
                  minute: 'numeric',
                })}
              </span>
            </div>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default Timeline
