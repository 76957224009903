import { makeStyles } from '@material-ui/core/styles'

export default makeStyles((theme) => ({
  table: {
    background: 'white',
  },
  center: {
    margin: 'auto',
    position: 'relative',
  },
  delete: {
    background: '#bd081c',
  },
  icon: {
    color: 'white',
  },
  padding: {
    marginTop: '1vw',
  },
  root: {
    paddingTop: '1vw',
  },
  cancel: {
    textAlign: 'right',
  },
  success: {
    float: 'right',
  },
  spaccingButtons: {
    marginTop: '2vw',
    position: 'relative',
    textAlign: 'center',
  },
  buttonSuccess: {
    background: '#4caf50',
    color: 'white',
  },
  inputSearchCommission: {
    width: '60%',
    fontFamily: 'Red Hat Display Regular',
  },
  btnSearchCommission: {
    margin: '0px',
  },
  buttonDanger: {
    background: '#cc2127',
    color: 'white',
  },
}))
