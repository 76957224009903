/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from 'react'

import DateFnsUtils from '@date-io/date-fns'
import {
  Checkbox,
  FormGroup,
  Grid,
  InputLabel,
  TextField,
  InputAdornment,
  Button,
  Typography,
} from '@material-ui/core'
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers'
import CurrencyTextField from '@unicef/material-ui-currency-textfield'
import Axios from 'axios'
import { es } from 'date-fns/locale'
import { parseInt } from 'lodash'
import moment from 'moment'

import useStyles from '../../../products.styles'

import BackDrop from 'components/BackDrop'
import { CalendarIcon } from 'components/icons/personalizedIcons'
import useCustomDialogAlert from 'components/useCustomDialogAlert'
import { PROMOTIONS, PROMOTIONS_IVA } from 'constants/promotions'
import { API_PRODUCTS, FEATURES_FLAGS } from 'constants/urls'
import { useProduct } from 'contextApi/ProductContext'
import convertToCurrencyCop from 'utils/convertToCurrencyCop'
import { onKeyPressJustNumbers, onlyNumber } from 'utils/utils'

const PriceVariant = ({
  setVariation,
  variation,
  fieldValidation,
  setFieldValidation,
  fechaError,
  setFechaError,
  changeTabs,
  onClose,
}) => {
  const classes = useStyles()
  const { isApprove, product, setProduct } = useProduct()
  const [isActivePromotionPrice, setIsActivePromotionPrice] = useState(
    !!variation.isActivePromotionPrice
  )
  const showDialogAlert = useCustomDialogAlert()
  const [promotionPercent, setPromotionPercent] = useState(0)
  const [isLoading, setIsLoading] = useState()
  const [promotionCopyIva, setPromotionCopyIva] = useState()
  const [pricePromotionUser, setPricePromotionUser] = useState(0)
  useEffect(() => {
    const value = variation.promotions.find((valuePromo) => valuePromo.name === PROMOTIONS)?.value
    const ivaPromo = variation.promotions.find((promo) => promo.name === PROMOTIONS_IVA)?.value
    setIsActivePromotionPrice(value)
    const valueInitial = product.regularPrice
    if (value) {
      setPricePromotionUser(variation.regularPrice - parseInt(value))
      const operation = Math.trunc((value * 100) / variation.regularPrice)
      setPromotionPercent(operation > 0 ? operation : 0)
    }
    if (ivaPromo) {
      const operation = Math.ceil(
        (100 * product.regularPrice) / (product.regularPrice - ivaPromo) - 100
      )
      const calculateIva = Math.ceil(valueInitial / (1 + operation / 100))
      variation.iva_discount_value = calculateIva
      variation.iva_discount_percentage = operation
      variation.is_iva_discount = true
      setVariation({
        ...variation,
        is_iva_discount: true,
        iva_discount_percentage: operation,
        iva_discount_value: calculateIva,
      })
    }
  }, [variation.regularPrice])

  useEffect(() => {
    setVariation({
      ...variation,
      isActivePromotionPrice: isActivePromotionPrice,
    })
  }, [promotionPercent, variation.promotions[0]?.value, isActivePromotionPrice])
  const onBlurDate = () => {
    const promotionVariation = variation.promotions.find((dateFrom) => dateFrom.name === PROMOTIONS)
    if (moment(promotionVariation?.dateOnSaleTo) > moment(promotionVariation?.dateOnSaleFrom)) {
      setFechaError(false)
    } else {
      setFechaError(true)
    }
  }
  // función para aplicar el porcentage
  const aplicateIvaDiscount = () => {
    variation.promotions = promotionCopyIva
    // variation.promotions = promotionCopyIva
    const valueInitial = variation.regularPrice

    variation.promotions.map((dato) => {
      if (dato.name == PROMOTIONS_IVA) {
        const calculateIva = Math.ceil(valueInitial / (1 + variation.iva_discount_percentage / 100))
        dato.value = variation.regularPrice - calculateIva
        variation.iva_discount_value = calculateIva
        setVariation({
          ...variation,
          iva_discount_value: calculateIva,
          is_iva_discount: variation.is_iva_discount,
        })
      }
      return dato
    })
  }

  const getFeaturesFlags = () => {
    setIsLoading(true)
    Axios.get(`${FEATURES_FLAGS}`)
      .then((response) => {
        const obajectFeatureFlag = response.data.find((ivaDay) => ivaDay.id === 155)
        const dateFeatureFlag = obajectFeatureFlag.value.split(',')
        const promotion = {
          name: PROMOTIONS_IVA,
          value: 0,
          dateOnSaleFrom: moment(dateFeatureFlag[0]).format(),
          dateOnSaleTo: moment(dateFeatureFlag[1]).format(),
        }
        variation.is_iva_discount = true
        setPromotionCopyIva([...variation.promotions, promotion])
        setIsLoading(false)
      })
      .catch(function (error) {
        setIsLoading(false)
        console.log(error)
      })
  }
  const onChangePercenIva = (event) => {
    if (!event.target.checked) {
      const valueInitial = variation.regularPrice
      const copyPromotion = variation.promotions.filter(
        (promotion) => promotion.name !== PROMOTIONS_IVA
      )

      setVariation({
        ...variation,
        regularPrice: valueInitial,
        is_iva_discount: event.target.checked,
        iva_discount_percentage: event.target.checked,
        iva_discount_value: event.target.checked,
        promotions: copyPromotion,
      })
      return false
    }
    setVariation({
      ...variation,
      is_iva_discount: event.target.checked,
    })
    variation.is_iva_discount = event.target.checked
    getFeaturesFlags()
  }
  const preventDefault = (e) => {
    e.preventDefault()
  }
  const onChangeActivePromotionPrice = (event) => {
    setIsActivePromotionPrice(event.target.checked)
    setVariation({
      ...variation,
      isActivePromotionPrice: event.target.checked,
    })
    if (!event.target.checked) {
      setPricePromotionUser(0)
      const copyPromotion = variation.promotions.filter(
        (promotion) => promotion.name !== PROMOTIONS
      )
      const copyiva = variation.promotions.filter((promotion) => promotion.name !== PROMOTIONS_IVA)
      setPromotionPercent(0)
      setVariation({
        ...variation,
        promotions: copyPromotion,
      })
      if (isActivePromotionPrice && !!copyiva) {
        const valueInitial = variation.regularPrice

        variation.promotions.map((dato) => {
          if (dato.name == PROMOTIONS_IVA) {
            const calculateIva = Math.ceil(
              valueInitial / (1 + variation.iva_discount_percentage / 100)
            )
            dato.value = calculateIva
            setVariation({
              ...variation,
              iva_discount_value: calculateIva,
              promotions: [{ ...copyiva, value: calculateIva }],
            })
          }
          return dato
        })
      }
    }
  }

  return (
    <>
      <BackDrop open={isLoading} />

      <Grid
        container
        direction="column"
        justify="space-between"
        alignItems="flex-start"
        spacing={2}
      >
        <Grid className={classes.gridPriceInputs}>
          <FormGroup className={classes.formGroupPriceStep}>
            <InputLabel
              className={classes.labelSize}
              error={!variation?.regularPrice ? !!fieldValidation.regularPrice : null}
            >
              Precio ($)
            </InputLabel>
            <CurrencyTextField
              currencySymbol="$"
              textAlign="left"
              decimalPlaces={0}
              minimumValue={0}
              outputFormat="string"
              onChange={(event) => {
                if (event.target.value !== '') {
                  setFieldValidation({
                    ...fieldValidation,
                    regularPrice: false,
                  })
                } else {
                  setFieldValidation({
                    ...fieldValidation,
                    regularPrice: true,
                  })
                }
                if (onlyNumber(parseInt(event.target.value.split(',').join(''), 10))) {
                  setVariation({
                    ...variation,
                    regularPrice: event.target.value.split(',').join(''),
                  })
                  setProduct({
                    ...product,
                    regularPrice: event.target.value.split(',').join(''),
                  })
                  return true
                }

                setVariation({
                  ...variation,
                  regularPrice: event.target.value,
                })
                setProduct({
                  ...product,
                  regularPrice: event.target.value,
                })
                return false
              }}
              value={variation?.regularPrice || 0}
              variant="outlined"
              name="regularPrice"
              id="regularPrice"
              error={!variation?.regularPrice ? !!fieldValidation.regularPrice : null}
              onKeyPress={onKeyPressJustNumbers}
              disabled={isApprove}
            />
            {fieldValidation.regularPrice && !variation?.regularPrice ? (
              <span className={classes.spanError}>Este campo es obligatorio</span>
            ) : null}
            {variation.iva_discount_value !== 0 &&
              variation.is_iva_discount &&
              variation.iva_discount_value && (
                <Typography variant="body1" className={classes.priceTitle}>
                  El precio le va quedar en&nbsp;
                  <Typography variant="subtitle1" className={classes.pricePercent}>
                    {` $${convertToCurrencyCop(variation.iva_discount_value)}`}
                  </Typography>
                </Typography>
              )}
          </FormGroup>

          <FormGroup className={classes.formGroupPriceStepCheckBox}>
            <Grid container direction="row" alignItems="flex-start" style={{ marginTop: '-1px' }}>
              <Checkbox
                checked={isActivePromotionPrice}
                onChange={onChangeActivePromotionPrice}
                id="checkTransport"
                name="checkedA"
                style={{ marginLeft: '5px' }}
              />
              <InputLabel className={classes.labelCheckBox}>Activar precio de promoción</InputLabel>
            </Grid>
          </FormGroup>
          <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
            <FormGroup className={classes.formGroupPriceStepCheckBox}>
              <Grid container direction="row" alignItems="flex-start" spacing={5}>
                <Checkbox
                  checked={variation.is_iva_discount || false}
                  onChange={onChangePercenIva}
                  id="is_iva_discount"
                  name="is_iva_discount"
                  style={{ marginLeft: '10px' }}
                />
                <InputLabel className={classes.labelCheckBox}>Descuento IVA</InputLabel>
              </Grid>
            </FormGroup>
            {variation.is_iva_discount && (
              <>
                <FormGroup className={classes.formGroupPriceStep}>
                  <InputLabel className={classes.labelSize}>% IVA</InputLabel>
                  <TextField
                    id="iva_discount_percentage"
                    name="iva_discount_percentage"
                    onChange={(event) => {
                      if (
                        onlyNumber(event.target.value) &&
                        event.target.value >= 0 &&
                        event.target.value <= 19
                      ) {
                        setVariation({
                          ...variation,
                          iva_discount_percentage: parseInt(event.target.value, 10),
                        })
                        return true
                      }
                      return false
                    }}
                    value={parseInt(variation.iva_discount_percentage, 10) || 0}
                    variant="outlined"
                    onKeyPress={onKeyPressJustNumbers}
                    onCut={preventDefault}
                    onCopy={preventDefault}
                    onPaste={preventDefault}
                  />
                </FormGroup>
                <Button
                  className={classes.btnBackgroundPrimary}
                  variant="contained"
                  color="secondary"
                  disabled={isLoading}
                  onClick={() => {
                    if (!variation.iva_discount_percentage || variation.regularPrice === '') {
                      showDialogAlert(
                        true,
                        '¡Advertencia!',
                        `Por favor ingrese un precio e iva.`,
                        'Cerrar'
                      )
                      setFechaError(true)
                    } else {
                      aplicateIvaDiscount()
                    }
                  }}
                >
                  {!isLoading ? 'Aplicar' : 'Aplicando descuento...'}
                </Button>
              </>
            )}
          </Grid>
          {isActivePromotionPrice && (
            <>
              <FormGroup className={classes.formGroupPriceStep} style={{ marginTop: '10px' }}>
                <InputLabel className={classes.labelSize}>Promoción (%)</InputLabel>
                <TextField
                  id="promotionPercent"
                  inputProps={{
                    maxlength: 3,
                  }}
                  onChange={(event) => {
                    if (event.target.value !== '') {
                      setFieldValidation({
                        ...fieldValidation,
                        sale_price: false,
                      })
                    } else {
                      setFieldValidation({
                        ...fieldValidation,
                        sale_price: true,
                      })
                    }
                    if (
                      onlyNumber(event.target.value) &&
                      event.target.value >= 0 &&
                      event.target.value <= 99
                    ) {
                      if (promotionPercent === 0) {
                        setPromotionPercent(event.target.value.replace('0', ''))
                      } else {
                        setPromotionPercent(event.target.value)
                      }
                      const promoIva = variation.promotions.find((iv) => iv.name === PROMOTIONS_IVA)
                      const promo = variation.promotions.find((iv) => iv.name === PROMOTIONS)
                      let copyPromociones = []
                      const objectPromotion = {
                        name: PROMOTIONS,
                        value: Math.trunc(
                          variation.regularPrice -
                            (variation.regularPrice -
                              variation.regularPrice * (event.target.value / 100))
                        ).toString(),
                      }
                      setPricePromotionUser(
                        (
                          variation.regularPrice -
                          variation.regularPrice * (event.target.value / 100)
                        ).toString()
                      )
                      if (!promoIva && !promo) {
                        copyPromociones = [objectPromotion]
                      } else if (!promoIva) {
                        copyPromociones = [
                          { ...promo, name: objectPromotion.name, value: objectPromotion.value },
                        ]
                      } else if (promo || promoIva) {
                        copyPromociones = [{ ...objectPromotion }, promoIva]
                      }
                      variation.promotions = [...copyPromociones]
                      setVariation({
                        ...variation,
                        promotions: [...copyPromociones],
                      })

                      return true
                    }
                    return false
                  }}
                  value={promotionPercent !== 0 ? promotionPercent : '0'}
                  variant="outlined"
                  disabled={isApprove}
                  onKeyPress={onKeyPressJustNumbers}
                />
              </FormGroup>

              <FormGroup className={classes.formGroupPriceStep} style={{ marginTop: '10px' }}>
                <InputLabel
                  className={classes.labelSize}
                  error={!(pricePromotionUser !== '') ? !!fieldValidation.sale_price : null}
                >
                  Precio en promoción ($)
                </InputLabel>
                <CurrencyTextField
                  currencySymbol="$"
                  textAlign="left"
                  decimalPlaces={0}
                  outputFormat="string"
                  disabled={isApprove}
                  onChange={(event) => {
                    if (onlyNumber(parseInt(event.target.value.split(',').join(''), 10))) {
                      if (
                        Number(event.target.value.split(',').join('')) <=
                        Number(variation.regularPrice)
                      ) {
                        setPromotionPercent(
                          Math.trunc(
                            ((variation.regularPrice -
                              parseInt(event.target.value.split(',').join(''), 10)) /
                              variation.regularPrice) *
                              100
                          )
                        )
                      } else {
                        setPromotionPercent(0)
                      }
                      const copyPromotion = variation.promotions.find(
                        (promotion) => promotion.name === PROMOTIONS
                      )
                      const copyPromotionIva = variation.promotions.find(
                        (promotionIva) => promotionIva.name === PROMOTIONS_IVA
                      )
                      const objectPromotion = {
                        ...copyPromotion,
                        name: PROMOTIONS,
                        value: product.regularPrice - event.target.value.split(',').join(''),
                      }
                      setPricePromotionUser(event.target.value.split(',').join(''))
                      let promotions = []
                      if (copyPromotionIva) {
                        promotions = [objectPromotion, copyPromotionIva]
                      } else {
                        promotions = [objectPromotion]
                      }
                      variation.promotions = [...promotions]
                      setVariation({
                        ...variation,
                        promotions: [...promotions],
                      })
                      return true
                    }
                    return false
                  }}
                  value={pricePromotionUser !== '' ? pricePromotionUser : ''}
                  variant="outlined"
                  name="sale_price"
                  error={!(pricePromotionUser !== '') ? !!fieldValidation.sale_price : null}
                  onKeyPress={onKeyPressJustNumbers}
                />
                {fieldValidation.sale_price && !(pricePromotionUser !== '') ? (
                  <span className={classes.spanError}>Este campo es obligatorio</span>
                ) : null}
              </FormGroup>

              <FormGroup className={classes.formGroupPriceStep}>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
                  <InputLabel error={fechaError?.startDate} className={classes.labelSize}>
                    Fecha inicial de promoción
                  </InputLabel>
                  <DatePicker
                    id="dateOnSaleFrom"
                    value={
                      variation.promotions.find((iv) => iv.name === PROMOTIONS)?.dateOnSaleFrom
                        ? new Date(
                            moment(
                              variation.promotions.find((iv) => iv.name === PROMOTIONS)
                                .dateOnSaleFrom
                            ).add(5, 'h')
                          )
                        : null
                    }
                    onBlur={onBlurDate}
                    error={fechaError?.startDate}
                    disabled={isApprove}
                    onChange={(value) => {
                      const date = new Date(value)
                      const month =
                        date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
                      const promoIva = variation.promotions.find((iv) => iv.name === PROMOTIONS_IVA)
                      const promo = variation.promotions.find((iv) => iv.name === PROMOTIONS)
                      let copyPromociones = []
                      const objectPromotion = {
                        name: PROMOTIONS,
                        dateOnSaleFrom: moment(
                          `${date.getFullYear()}-${month}-${
                            date.getDate() < 10 ? `0${date.getDate()}` : `${date.getDate()}`
                          }T00:00:00`
                        )
                          .format()
                          .replace('-05:00', ''),
                      }
                      if (!promoIva && !promo) {
                        copyPromociones = [objectPromotion]
                      } else if (!promoIva) {
                        copyPromociones = [
                          {
                            ...promo,
                            name: objectPromotion.name,
                            dateOnSaleFrom: objectPromotion.dateOnSaleFrom,
                          },
                        ]
                      } else if (promo || promoIva) {
                        copyPromociones = [{ ...promo, ...objectPromotion }, promoIva]
                      }
                      const cdcd = copyPromociones
                      variation.promotions = [...copyPromociones]
                      setVariation({
                        ...variation,
                        promotions: [...copyPromociones],
                      })
                    }}
                    format="yyyy-MM-dd"
                    inputVariant="outlined"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <CalendarIcon />
                        </InputAdornment>
                      ),
                    }}
                    KeyboardButtonProps={{ style: { padding: 0 } }}
                  />
                </MuiPickersUtilsProvider>
              </FormGroup>

              <FormGroup className={classes.formGroupPriceStep}>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
                  <InputLabel error={fechaError.endDate} className={classes.labelSize}>
                    Fecha final de promoción
                  </InputLabel>

                  <DatePicker
                    id="dateOnSaleTo"
                    value={
                      variation.promotions.find((dateFrom) => dateFrom.name === PROMOTIONS)
                        ?.dateOnSaleTo
                        ? new Date(
                            variation.promotions.find(
                              (dateFrom) => dateFrom.name === PROMOTIONS
                            )?.dateOnSaleTo
                          )
                        : null
                    }
                    onBlur={onBlurDate}
                    disabled={isApprove}
                    error={fechaError?.endDate}
                    onChange={(value) => {
                      const date = new Date(value)
                      const month =
                        date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
                      const promoIva = variation.promotions.find((iv) => iv.name === PROMOTIONS_IVA)
                      const promo = variation.promotions.find((iv) => iv.name === PROMOTIONS)
                      let copyPromociones = []
                      const objectPromotion = {
                        name: PROMOTIONS,
                        dateOnSaleTo: moment(
                          `${date.getFullYear()}-${month}-${
                            date.getDate() < 10 ? `0${date.getDate()}` : `${date.getDate()}`
                          }T23:59:59`
                        )
                          .format()
                          .replace('-05:00', ''),
                      }
                      if (!promoIva && !promo) {
                        copyPromociones = [objectPromotion]
                      } else if (!promoIva) {
                        copyPromociones = [
                          {
                            ...promo,
                            name: objectPromotion.name,
                            dateOnSaleTo: objectPromotion.dateOnSaleTo,
                          },
                        ]
                      } else if (promo || promoIva) {
                        copyPromociones = [{ ...promo, ...objectPromotion }, promoIva]
                      }
                      variation.promotions = [...copyPromociones]
                      setVariation({
                        ...variation,
                        promotions: [...copyPromociones],
                      })
                    }}
                    format="yyyy-MM-dd"
                    inputVariant="outlined"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <CalendarIcon />
                        </InputAdornment>
                      ),
                    }}
                    KeyboardButtonProps={{ style: { padding: 0 } }}
                  />
                </MuiPickersUtilsProvider>
              </FormGroup>
            </>
          )}
        </Grid>
      </Grid>
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        className={classes.gridButtonsInfoBasic}
      >
        <Button
          className={classes.btnBackgroundPrimary}
          variant="contained"
          color="secondary"
          onClick={() => changeTabs(1)}
        >
          Anterior
        </Button>
        <Button
          className={classes.btnBackgroundError}
          disabled={isLoading}
          onClick={() => onClose()}
        >
          Cancelar
        </Button>
        <Button
          className={classes.btnBackgroundPrimary}
          variant="contained"
          color="secondary"
          type="submit"
          onClick={() => changeTabs(3)}
        >
          Continuar
        </Button>
      </Grid>
    </>
  )
}

export default PriceVariant
