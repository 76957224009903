import axios from 'axios'

import { CONCILIATOR_USER, CONCILIATOR_PASSWORD, CONCILIATOR_URL } from 'constants/urls'

export const refreshTokenConciliator = () => {
  console.log('refreshTokenConciliator')
  return axios.post(`${CONCILIATOR_URL}/auth`, {
    serviceName: CONCILIATOR_USER,
    password: CONCILIATOR_PASSWORD,
  })
}
export const getTokenConciliator = () => {
  console.log('getTokenConciliator')
  return localStorage.getItem('conciliator_token')
}

export const getConciliatorInform = (formData) => {
  return axios.post(`${CONCILIATOR_URL}/conciliator/getExcel`, formData, {
    responseType: 'blob',
  })
}
