import React, { useEffect, useState } from 'react'

import { Dialog, DialogContent, IconButton, StepConnector } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import Stepper from '@material-ui/core/Stepper'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'

import CouponAlert from '../CouponAlert'

import BasicInfoStep from './BasicInfoStep'
import MultiInfoStep from './MultiInfoStep'
import OverviewStep from './OverviewStep'
import RulesStep from './RulesStep'
import UsageInfoStep from './UsageInfoStep'

import useCustomDialogAlert from 'components/useCustomDialogAlert'
import { VOUCHER } from 'constants/rolesConst'
import useCouponsInterceptor from 'hook/useCouponsInterceptor'
import useStyles from 'views/Products/products.styles'

const CircleConnector = withStyles({
  alternativeLabel: {
    top: 20,
    left: 'calc(-50% + 30px)',
    right: 'calc(50% + 30px)',
  },
  active: {
    '& $line': {
      borderColor: '#6064FF',
    },
  },
  completed: {
    '& $line': {
      borderColor: '#6064FF',
    },
  },
  line: {
    borderColor: '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1,
  },
})(StepConnector)

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
})

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
})

function getSteps(isVoucher = false) {
  const steps = ['Fechas y uso', 'Definición de reglas', 'Resumen de la operación']
  steps.unshift(isVoucher ? 'Información básica del bono' : 'Información básica del cupón')
  return steps
}

function getStepContent(
  stepIndex,
  handleBack,
  handleSubmitStep,
  info,
  discountTypes,
  useTypes,
  ruleConditions,
  ruleTypes,
  rules,
  addRule,
  removeRule,
  coupons,
  isEdit,
  type,
  sellerInfo
) {
  switch (stepIndex) {
    case 0:
      if (type === 2) {
        return (
          <MultiInfoStep
            info={info}
            discountTypes={discountTypes}
            handleBack={handleBack}
            handleSubmitStep={handleSubmitStep}
            sellerInfo={sellerInfo}
          />
        )
      }
      return (
        <BasicInfoStep
          handleBack={handleBack}
          handleSubmitStep={handleSubmitStep}
          info={info}
          discountTypes={discountTypes}
          coupons={coupons}
          isEdit={isEdit}
          sellerInfo={sellerInfo}
        />
      )
    case 1:
      return (
        <UsageInfoStep
          handleBack={handleBack}
          handleSubmitStep={handleSubmitStep}
          info={info}
          useTypes={useTypes}
          creationType={type}
          sellerInfo={sellerInfo}
        />
      )
    case 2:
      return (
        <RulesStep
          handleBack={handleBack}
          handleSubmitStep={handleSubmitStep}
          info={info}
          ruleConditions={ruleConditions}
          ruleTypes={ruleTypes}
          useTypes={useTypes}
          rules={rules}
          addRule={addRule}
          removeRule={removeRule}
          sellerInfo={sellerInfo}
        />
      )
    case 3:
      return (
        <OverviewStep
          handleBack={handleBack}
          handleSubmitStep={handleSubmitStep}
          info={info}
          ruleConditions={ruleConditions}
          ruleTypes={ruleTypes}
          useTypes={useTypes}
          rules={rules}
          sellerInfo={sellerInfo}
          isMultiple={type === 2}
        />
      )
    default:
      return 'Unknown stepIndex'
  }
}

export default function CouponsStepper({
  open,
  handleClose,
  editInfo,
  getCoupons,
  supplierId,
  setIsLoading,
  coupons,
  sellerInfo,
  discountTypes,
  useTypes,
  ruleConditions,
  ruleTypes,
}) {
  const isVoucher = sellerInfo.role === VOUCHER
  const classes = useStyles()
  const [activeStep, setActiveStep] = useState(0)
  const [info, setInfo] = useState({})
  const steps = getSteps(isVoucher)
  const [rules, setRules] = useState([])
  const [openAlert, setOpenAlert] = useState(false)
  const [response, setResponse] = useState({
    isSuccessful: false,
    data: null,
  })
  const { createCoupon, updateCoupon, createMultipleCoupons } = useCouponsInterceptor()
  const showDialogAlert = useCustomDialogAlert()
  const [isEdit, setIsEdit] = useState(false)

  const idToUniqueUseType = (id) => {
    return useTypes.find((type) => type.id === id)?.uniqueId
  }

  const handleBack = (data) => {
    if (data && idToUniqueUseType(data.useType) === 6 && activeStep === steps.length - 1) {
      setActiveStep((prevActiveStep) => prevActiveStep - 2)
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1)
    }
  }

  const handleReset = () => {
    setActiveStep(0)
  }

  const addRule = (newRule) => {
    setRules([...rules, newRule])
  }

  const removeRule = (index) => {
    setRules(rules.filter((item, i) => i !== index))
  }

  const handleCloseAlert = () => {
    handleClose()
    setOpenAlert(false)
  }

  const handleSubmitStep = (data) => {
    setIsLoading(true)
    if (editInfo?.id) {
      const editData = { ...data, supplierId, sellerId: sellerInfo.sellerId }
      updateCoupon(editData, rules)
        .then((res) => {
          if (res.isSuccessful) {
            showDialogAlert(
              true,
              `${isVoucher ? 'Bonos' : 'Cupones'} Actualizados`,
              `Los ${isVoucher ? 'bonos' : 'cupones'} se actualizaron correctamente`,
              'Cerrar',
              '',
              true
            )
            handleClose()
            getCoupons()
            setIsLoading(false)
          } else {
            showDialogAlert(
              true,
              `Error al actualizar el ${isVoucher ? 'bono' : 'cupón'}`,
              'Ocurrió un error inesperado, intente nuevamente o comuniquese con soporte',
              'Cerrar',
              '',
              false
            )
          }
          setIsLoading(false)
        })
        .catch((err) => {
          console.log('Error updating coupon', err)
          setIsLoading(false)
        })
    } else if (open.type === 2) {
      const multipleData = [{ ...data, supplierId }]
      if (isVoucher) {
        multipleData[0].sellerId = sellerInfo.sellerId
      }
      createMultipleCoupons(multipleData).then((res) => {
        setResponse(res)
        if (res.isSuccessful) {
          getCoupons()
        }
        setOpenAlert(true)
        setIsLoading(false)
      })
    } else {
      const createData = { ...data, supplierId, sellerId: sellerInfo.sellerId }
      createCoupon(createData)
        .then((res) => {
          if (res.isSuccessful) {
            showDialogAlert(
              true,
              `${isVoucher ? 'Bono' : 'Cupón'} creado`,
              `El ${isVoucher ? 'bono' : 'cupón'} se creó correctamente`,
              'Cerrar',
              '',
              true
            )
            handleClose()
            getCoupons()
          } else if (res.message) {
            showDialogAlert(
              true,
              `Error al crear el ${isVoucher ? 'bono' : 'cupón.'}`,
              res.message,
              'Cerrar',
              '',
              false
            )
          } else {
            showDialogAlert(
              true,
              `Error al crear el ${isVoucher ? 'bono' : 'cupón'}`,
              'Ocurrió un error inesperado, intente nuevamente o comuniquese con soporte',
              'Cerrar',
              '',
              false
            )
          }
          setIsLoading(false)
        })
        .catch((err) => {
          console.log('Error creating coupon', err)
        })
    }
  }
  const handleNext = (data) => {
    if (activeStep === steps.length - 2) {
      setInfo({ ...info, rules: rules })
    } else {
      setInfo({ ...info, ...data })
    }
    if (data && idToUniqueUseType(data.useType) === 6 && activeStep === steps.length - 3) {
      setActiveStep((prevActiveStep) => prevActiveStep + 2)
    } else if (activeStep === steps.length - 1) {
      handleSubmitStep(data)
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1)
    }
  }

  useEffect(() => {
    if (editInfo.id) {
      setIsEdit(true)
      setInfo(editInfo)
      setRules(editInfo.rules)
    }
    return () => {
      setInfo({})
      setRules([])
      setActiveStep(0)
      setIsEdit(false)
    }
  }, [open])

  return (
    <Dialog open={open.open} onClose={handleClose} fullWidth maxWidth="lg">
      <DialogContent>
        <DialogTitle onClose={handleClose}>
          <Stepper activeStep={activeStep} alternativeLabel connector={<CircleConnector />}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel
                  classes={{
                    label: classes.labelStepLabel,
                    active: classes.activeStepLabel,
                    completed: classes.completedStepLabel,
                  }}
                  StepIconProps={{
                    classes: {
                      root: classes.rootStepIcon,
                      text: classes.text,
                      active: classes.active,
                      completed: classes.completed,
                    },
                  }}
                >
                  {label}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </DialogTitle>
        <div>
          {activeStep === steps.length ? (
            <div>
              <Typography className={classes.instructions}>All steps completed</Typography>
              <Button onClick={handleReset}>Reset</Button>
            </div>
          ) : (
            getStepContent(
              activeStep,
              handleBack,
              handleNext,
              info,
              discountTypes,
              useTypes,
              ruleConditions,
              ruleTypes,
              rules,
              addRule,
              removeRule,
              coupons,
              isEdit,
              open.type,
              sellerInfo
            )
          )}
        </div>
      </DialogContent>
      <CouponAlert open={openAlert} handleClose={handleCloseAlert} response={response} />
    </Dialog>
  )
}
