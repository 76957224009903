/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react'

import {
  Card,
  Chip,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  InputBase,
  MenuItem,
  Paper,
  Select,
  Switch,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
  makeStyles,
  withStyles,
} from '@material-ui/core'
import { Autorenew } from '@material-ui/icons'
import { format } from 'date-fns'
import { es } from 'date-fns/locale'
import { set } from 'lodash'
import MUIDataTable from 'mui-datatables'

import SaleModal from './SaleModal'

import BackDrop from 'components/BackDrop'
import Button from 'components/CustomButtons/Button'
import { PencilIcon, SearchIcon } from 'components/icons/personalizedIcons'
import { SUPER_ADMIN, DROPSHIPPER } from 'constants/rolesConst'
import AuthContext from 'contextApi/AuthContext'
import {
  getSales,
  getRuleTypes,
  getConditions,
  getDiscountTypes,
  getConditionsTypes,
  changeSaleStatus,
} from 'services/Sales'
import { textLabels } from 'utils/functions'

const useStyles = makeStyles(() => ({
  button: {
    width: '248px',
    height: '56px',
    borderRadius: '50px',
    border: 'none',
    margin: '20px 0',
    backgroundColor: '#5e44ff',
    color: 'white',
    fontWeight: '500',
    '&:hover': {
      fontWeight: 'bolder',
      backgroundColor: '#ef0bef',
      boxShadow: '-7px 7px 0px #000000',
      border: '2px solid #000000',
      borderRadius: '50px',
      opacity: '1',
    },
  },
}))

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    width: '250px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}))(InputBase)

const LuegoSwitch = withStyles({
  switchBase: {
    color: '#7e7e7e',
    '&$checked': {
      color: '#6064FF',
    },
    '&$checked + $track': {
      backgroundColor: '#6064FF',
    },
  },
  checked: {},
  track: {},
})(Switch)

const SalesManagement = () => {
  const [firstLoad, setFirstLoad] = useState(true)
  const classes = useStyles()
  const { userLoggedin } = useContext(AuthContext)
  const [isLoading, setIsLoading] = useState(false)
  const [createModal, setCreateModal] = useState(false)
  const [discountTypes, setDiscountTypes] = useState([])
  const [ruleTypes, setRuleTypes] = useState([])
  // const [conditionTypes, setConditionTypes] = useState([])
  const [conditions, setConditions] = useState([])
  const [sales, setSales] = useState([])
  const [request, setRequest] = useState({
    page: 1,
    pageSize: 10,
    sellerId: userLoggedin.sellerId,
    isAdmin: false,
    filters: [],
    orderType: 1,
  })
  const [editInfo, setEditInfo] = useState({})
  const [newFilter, setNewFilter] = useState({
    value: '',
    type: 0,
  })
  const [filters, setFilters] = useState([])
  const handleNewFilter = (e) => {
    if (e) {
      e.preventDefault()
    }
    if (newFilter.value.length > 0) {
      // eslint-disable-next-line no-nested-ternary
      const name = newFilter.type === 1 ? 'Código' : newFilter.type === 2 ? 'Estado' : 'Descripción'
      const addFilter = filters.some((f) => f.type === newFilter.type)
      if (addFilter) {
        const newFilters = filters.filter((f) => f.type !== newFilter.type)
        setFilters([...newFilters, { name, type: newFilter.type, value: newFilter.value }])
        setRequest({
          ...request,
          page: 1,
          filters: [...newFilters, { type: newFilter.type, value: newFilter.value }],
        })
      } else {
        setFilters([...filters, { name, type: newFilter.type, value: newFilter.value }])
        setRequest({
          ...request,
          page: 1,
          filters: [...filters, { type: newFilter.type, value: newFilter.value }],
        })
      }
      setNewFilter({
        value: '',
        type: 0,
      })
      setRequest({
        ...request,
        page: 1,
        filters: [...filters, { type: newFilter.type, value: newFilter.value }],
      })
    }
  }

  const getInitialData = async () => {
    try {
      setIsLoading(true)
      await Promise.all([
        getSales(request),
        getRuleTypes(),
        getConditions(),
        getDiscountTypes(),
        // getConditionsTypes(),
      ]).then((values) => {
        setSales(values[0])
        setRuleTypes(values[1])
        setConditions(values[2])
        setDiscountTypes(values[3])
        // setConditionTypes(values[4])
      })
    } catch (error) {
      console.error('Error al obtener los datos iniciales:', error)
    } finally {
      setIsLoading(false)
    }
  }

  const changeTablePage = (page) => {
    setRequest({ ...request, page })
  }

  const changeTableSize = (pageSize) => {
    setRequest({ ...request, pageSize })
  }

  const reloadTable = () => {
    setIsLoading(true)
    getSales(request).then((data) => {
      setSales(data)
      setIsLoading(false)
    })
  }

  useEffect(() => {
    if (firstLoad) {
      getInitialData()
      setFirstLoad(false)
    } else {
      reloadTable()
    }
  }, [request])

  return (
    <div>
      <BackDrop open={isLoading} />
      <Typography variant="h6" style={{ color: '#6064FF' }}>
        Listado de promociones
      </Typography>
      {userLoggedin.role === DROPSHIPPER && (
        <Grid container spacing={3} xs={12} justifyContent="flex-end">
          <Grid item>
            <Button
              className={classes.button}
              onClick={() => {
                setCreateModal(true)
              }}
            >
              Crear promoción
            </Button>
          </Grid>
        </Grid>
      )}
      <Paper component="ul" style={{ width: '100%', marginBottom: '3px' }}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-end"
          xs={12}
        >
          <Grid item>
            <label>Filtros</label>
            <Grid container>
              <Select
                variant="outlined"
                value={newFilter.type}
                input={<BootstrapInput />}
                style={{ width: '150px' }}
                onChange={(e) => {
                  setNewFilter({
                    type: e.target.value,
                    value: '',
                  })
                }}
              >
                <MenuItem value={0} disabled>
                  Filtros
                </MenuItem>
                <MenuItem value={1}>Código</MenuItem>
                <MenuItem value={2}>Estado</MenuItem>
                <MenuItem value={3}>Descripción</MenuItem>
              </Select>
              {newFilter.type === 2 ? (
                <Select
                  variant="outlined"
                  value={newFilter.value}
                  input={<BootstrapInput />}
                  onChange={(e) => {
                    setNewFilter({
                      ...newFilter,
                      value: e.target.value,
                    })
                  }}
                >
                  <MenuItem value="" disabled>
                    Seleccione
                  </MenuItem>
                  <MenuItem value="true">Activo</MenuItem>
                  <MenuItem value="false">Inactivo</MenuItem>
                </Select>
              ) : (
                <BootstrapInput
                  name="filterInput"
                  type={newFilter.type === 4 ? 'number' : 'text'}
                  value={newFilter.value}
                  disabled={newFilter.type === 0}
                  onChange={(e) => {
                    setNewFilter({
                      ...newFilter,
                      value: e.target.value,
                    })
                  }}
                  placeholder={newFilter.type === 0 ? 'Seleccione un filtro' : ''}
                />
              )}
              <IconButton onClick={handleNewFilter}>
                <SearchIcon />
              </IconButton>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container direction="row" alignItems="center" spacing={1}>
              {filters.map(({ type, value, name }) => (
                <Grid item key={type}>
                  <li>
                    <Chip
                      label={`${String(name).toLocaleUpperCase()}: ${String(
                        // eslint-disable-next-line no-nested-ternary
                        value === 'true' ? 'Activo' : value === 'false' ? 'Inactivo' : value
                      ).toLocaleUpperCase()}`}
                      onDelete={() => {
                        setFilters(filters.filter((filter) => filter.type !== type))
                        setRequest({
                          ...request,
                          page: 1,
                          filters: filters.filter((filter) => filter.type !== type),
                        })
                      }}
                    />
                  </li>
                </Grid>
              ))}
              <Grid item>
                <IconButton onClick={reloadTable}>
                  <Autorenew />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
      <MUIDataTable
        data={sales.data}
        columns={[
          {
            name: 'id',
            label: 'ID',
            options: {
              display: false,
            },
          },
          {
            name: 'saleName',
            label: 'Nombre',
            options: {
              display: false,
              customHeadLabelRender: () => (
                <th style={{ fontFamily: 'Red Hat Display Black', fontSize: 13 }}>NOMBRE</th>
              ),
            },
          },
          {
            name: 'saleCode',
            label: 'Código',
            options: {
              customHeadLabelRender: () => (
                <th style={{ fontFamily: 'Red Hat Display Black', fontSize: 13 }}>CÓDIGO</th>
              ),
            },
          },
          {
            name: 'discountType',
            label: 'Tipo de descuento',
            options: {
              display: false,
            },
          },
          {
            name: 'saleValue',
            label: 'Descuento',
            options: {
              customHeadLabelRender: () => (
                <th style={{ fontFamily: 'Red Hat Display Black', fontSize: 13 }}>DESCUENTO</th>
              ),
              customBodyRender: (value, tableMeta) => {
                return tableMeta.rowData[3] === 1 ? `$${value}` : `${value}%`
              },
            },
          },
          {
            name: 'initialDate',
            label: 'Fecha de inicio',
            options: {
              customHeadLabelRender: () => (
                <th style={{ fontFamily: 'Red Hat Display Black', fontSize: 13 }}>DESDE</th>
              ),
              customBodyRender: (value) => format(new Date(value), 'PPPpp', { locale: es }),
            },
          },
          {
            name: 'finalDate',
            label: 'Fecha final',
            options: {
              customHeadLabelRender: () => (
                <th style={{ fontFamily: 'Red Hat Display Black', fontSize: 13 }}>HASTA</th>
              ),
              customBodyRender: (value) => format(new Date(value), 'PPPpp', { locale: es }),
            },
          },
          {
            name: 'sellerId',
            label: 'Vendedor',
            options: {
              display: userLoggedin.role === SUPER_ADMIN,
              customHeadLabelRender: () => (
                <th style={{ fontFamily: 'Red Hat Display Black', fontSize: 13 }}>VENDEDOR</th>
              ),
            },
          },
          {
            name: 'status',
            label: 'Estado',
            options: {
              customHeadLabelRender: () => (
                <th style={{ fontFamily: 'Red Hat Display Black', fontSize: 13 }}>ESTADO</th>
              ),
              customBodyRender: (value, tableMeta) => (
                <FormControlLabel
                  control={
                    <LuegoSwitch
                      checked={value}
                      onChange={() => {
                        setIsLoading(true)
                        changeSaleStatus({
                          saleCode: tableMeta.rowData[2],
                          status: !value,
                        })
                          .then(() => {
                            reloadTable()
                          })
                          .catch((err) => {
                            console.log(err)
                            setIsLoading(false)
                          })
                      }}
                      name="checkedB"
                      color="primary"
                    />
                  }
                  style={{ margin: 0 }}
                  label={value ? 'Activo' : 'Inactivo'}
                  labelPlacement="bottom"
                />
              ),
            },
          },
          {
            name: 'actions',
            label: 'Editar',
            options: {
              filter: false,
              customHeadLabelRender: () => (
                <th style={{ fontFamily: 'Red Hat Display Black', fontSize: 13 }}>EDITAR</th>
              ),
              customBodyRender: (value, tableMeta) => {
                return (
                  <div>
                    <Tooltip title="Editar">
                      <IconButton
                        onClick={() => {
                          setEditInfo(() =>
                            sales.data.find((item) => item.id === tableMeta.rowData[0])
                          )
                          setCreateModal(true)
                        }}
                      >
                        <PencilIcon />
                      </IconButton>
                    </Tooltip>
                  </div>
                )
              },
            },
          },
        ]}
        options={{
          print: false,
          filter: false,
          download: false,
          viewColumns: false,
          serverSide: true,
          expandableRows: true,
          search: false,
          sort: false,
          count: sales.totalRows,
          page: request.page,
          tableBodyMaxHeight: userLoggedin.role === DROPSHIPPER ? '50vh' : '63vh',
          expandableRowsHeader: false,
          textLabels: textLabels(isLoading),
          selectableRows: 'none',
          fixedHeader: true,
          onTableChange: (action, tableState) => {
            switch (action) {
              case 'changePage':
                changeTablePage(tableState.page)
                break
              case 'changeRowsPerPage':
                changeTableSize(tableState.rowsPerPage)
                break
              default:
                break
            }
          },
          renderExpandableRow: (rowData) => {
            const colSpan = rowData.length + 1
            const { saleName, isCumulative, rules, percentLuegopago, percentSeller } =
              sales.data.find((item) => item.id === rowData[0])
            return (
              <TableRow>
                <TableCell
                  colSpan={colSpan}
                  style={{ backgroundColor: 'white', padding: '2%', paddingTop: '5px' }}
                >
                  <Card variant="outlined">
                    <Grid container justifyContent="space-evenly">
                      <Grid item>
                        <Typography variant="subtitle1">Información Básica</Typography>
                        <Typography variant="body1">Descripcion de la promoción:</Typography>
                        <p>{saleName}</p>
                        <Typography variant="body1">Valor asumido por Luegopago:</Typography>
                        <p>{percentLuegopago}%</p>
                        <Typography variant="body1">Valor asumido por la tienda:</Typography>
                        <p>{percentSeller}%</p>
                        <Typography variant="body1">Acumulable:</Typography>
                        <p>{isCumulative ? 'Si' : 'No'}</p>
                      </Grid>
                      <Divider orientation="vertical" flexItem />
                      <Grid item xs={6}>
                        <Typography variant="subtitle1">Reglas</Typography>
                        <MUIDataTable
                          options={{
                            print: false,
                            filter: false,
                            download: false,
                            viewColumns: false,
                            search: false,
                            sort: false,
                            selectableRows: false,
                            customFooter: () => null,
                            textLabels: {
                              body: {
                                noMatch: 'No hay reglas para esta promoción',
                              },
                            },
                          }}
                          columns={[
                            {
                              name: 'ruleTypeName',
                              label: 'Tipo',
                              options: {
                                customHeadLabelRender: () => (
                                  <th style={{ fontFamily: 'Red Hat Display Black', fontSize: 13 }}>
                                    Tipo
                                  </th>
                                ),
                              },
                            },
                            {
                              name: 'conditionName',
                              label: 'Condición',
                              options: {
                                customHeadLabelRender: () => (
                                  <th style={{ fontFamily: 'Red Hat Display Black', fontSize: 13 }}>
                                    Condición
                                  </th>
                                ),
                              },
                            },
                            {
                              name: 'value',
                              label: 'Valor',
                              options: {
                                customHeadLabelRender: () => (
                                  <th style={{ fontFamily: 'Red Hat Display Black', fontSize: 13 }}>
                                    Valor
                                  </th>
                                ),
                              },
                            },
                          ]}
                          data={rules}
                        />
                      </Grid>
                    </Grid>
                  </Card>
                </TableCell>
              </TableRow>
            )
          },
        }}
      />
      <SaleModal
        editData={editInfo}
        discountTypes={discountTypes}
        isOpen={createModal}
        ruleTypes={ruleTypes}
        conditionTypes={conditions}
        handleClose={() => {
          setCreateModal(false)
          setEditInfo({})
        }}
        onSubmitChanges={() => {
          setCreateModal(false)
          reloadTable()
        }}
        setIsLoading={setIsLoading}
      />
    </div>
  )
}

export default SalesManagement
