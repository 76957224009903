import React, { useState } from 'react'

import { Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import FilterButtons from 'components/FilterButtons'
import SearchBox from 'components/SearchBox'

const useStyles = makeStyles(() => ({
  container: {
    marginBottom: 30,
  },
  listActions: {
    width: 151,
  },
  divActionsAndFilters: {
    display: 'flex',
    gap: '15px',
  },
  divStates: {
    display: 'flex',
    columnGap: '10px',
  },
}))

const FiltersBox = ({
  initialSearchText = '',
  handlerSearch,
  setFiltersApi,
  getModels,
  filterBy,
  filters,
  filtered,
  setFiltered,
}) => {
  const classes = useStyles()
  const [avalaibleSetTextValueEmpty, setAvalaibleSetTextValueEmpty] = useState(false)

  const showStatusChips = () => {
    const chipData = (
      <FilterButtons
        key={0}
        label={filters.all ? `${filters.all} Todos` : `${0} Todos`}
        onClick={() => {
          getModels(10, 0, false)
          setAvalaibleSetTextValueEmpty(true)
          setFiltered({
            typeFilter: '',
            totalFilter: '',
          })
        }}
        active={filtered.typeFilter === ''}
      />
    )

    const chips = [].map((status) => {
      return (
        <FilterButtons
          key={status.name}
          label={status.quantity ? `${status.quantity} ${status.name}` : `${0}  ${status.name}`}
          onClick={() => filterBy(status.name)}
        />
      )
    })

    const chipVisibles = (
      <FilterButtons
        key={2}
        label={filters.visibles ? `${filters.visibles} Visibles` : `${0} Visibles`}
        onClick={() => {
          setAvalaibleSetTextValueEmpty(true)
          setFiltersApi(3, filters.visibles)
        }}
        active={filtered.typeFilter === 3}
      />
    )

    const chipStockQuantity = (
      <FilterButtons
        key={4}
        label={filters.outOfStock ? `${filters.outOfStock} Agotados` : `${0} Agotados`}
        onClick={() => {
          setAvalaibleSetTextValueEmpty(true)
          setFiltersApi(2, filters.outOfStock)
        }}
        active={filtered.typeFilter === 2}
      />
    )
    const chipEnable = (
      <FilterButtons
        key={5}
        label={filters.inactive ? `${filters.inactive} Nuevos` : `${0} Nuevos`}
        onClick={() => {
          setAvalaibleSetTextValueEmpty(true)
          setFiltersApi(5, filters.inactive)
        }}
        active={filtered.typeFilter === 5}
      />
    )
    return [chipData, ...chips, chipVisibles, chipStockQuantity, chipEnable]
  }

  return (
    <Grid container justify="space-between" alignItems="flex-end" className={classes.container}>
      <Grid className={classes.divActionsAndFilters}>
        <Grid>
          <Typography variant="subtitle1">Filtrar por:</Typography>
          <Grid className={classes.divStates}>{showStatusChips()}</Grid>
        </Grid>
      </Grid>
      <SearchBox
        initialSearchText={initialSearchText}
        handlerSearch={handlerSearch}
        avalaibleSetTextValueEmpty={avalaibleSetTextValueEmpty}
        setAvalaibleSetTextValueEmpty={setAvalaibleSetTextValueEmpty}
        setFiltersApi={getModels}
      />
    </Grid>
  )
}

export default FiltersBox
