/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable consistent-return */
/* eslint-disable react/prop-types */
import React, { useState, useEffect, useContext } from 'react'

import {
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Grid,
  Divider,
  Breadcrumbs,
  GridList,
  Link,
  Typography,
  Button,
  TextField,
  Tooltip,
} from '@material-ui/core'
import CheckIcon from '@material-ui/icons/Check'
import ChevronRight from '@material-ui/icons/ChevronRight'
import CloseIcon from '@material-ui/icons/Close'
import Axios from 'axios'
import { useRouteMatch } from 'react-router-dom'

import CategoriesSelected from './CategoriesSelected'
import CategorPercentage from './CategorPercentage'
import useStyles from './products.styles'
import ValidateCategoriesVariable from './validateCategorieVariable'
import Style from './validateCategorieVariable.style'

import BackDrop from 'components/BackDrop'
import CategoryMetadata from 'components/Categories/CategoryMetadata'
import DialogAlert from 'components/DialogAlert'
import { PencilIcon } from 'components/icons/personalizedIcons'
import useCustomDialogAlert from 'components/useCustomDialogAlert'
import { SUPER_ADMIN } from 'constants/rolesConst'
import {
  API_PRODUCTS,
  API_CATEGORIES,
  API_PRODUCTS_TEMPLATE,
  API_PRODUCTS_UPDATE,
} from 'constants/urls'
import useAuthContext from 'contextApi/AuthContext'
import { useProduct } from 'contextApi/ProductContext'
import {
  apicategoriesPercentById,
  apicategoriesPercentByIdSeller,
} from 'services/commissions/commissions.services'

const CategoriesListBreadCrumb = ({
  isTemplate,
  isAdd,
  isEdit,
  setIsLoadingBackDrop,
  onCancel,
  listCategoriesShopify,
  setListCategoriesShopify,
  rowcategoryshopify,
  setDialogAlertVariation,
  getAttributes,
  allProductShopifySelectedNum,
  getAttributesShopify,
  setCategoriesSeller,
  setTreeCategories,
  idSeller,
  setIsOpenModalCategories,
  listHeight = '48vh',
}) => {
  const isProduct = useRouteMatch('/admin/products')?.isExact
  const isImportProduct = useRouteMatch('/admin/import-products')?.isExact
  const isShopifyProducts = useRouteMatch('/admin/shopify-products')?.isExact
  const isAdminCategory = useRouteMatch('/admin/configSeller')?.isExact
  const iscategoryAdmin = useRouteMatch('/admin/categories')?.isExact
  const isUpdateProduct = useRouteMatch('/admin/update-products')?.isExact
  const showDialogAlert = useCustomDialogAlert()
  const { categorylocal, userLoggedin } = useContext(useAuthContext)
  const classes = Style()
  const styles = useStyles()
  const {
    product,
    setProduct,
    handleNext,
    handleCancel,
    isBackToCategories,
    setIsBackToCategories,
    warehouses,
    isApprove,
  } = useProduct()
  const [dialogAlert, setDialogAlert] = useState({
    open: false,
    title: '',
    message: '',
    actionConfirm: null,
    textButtonConfirm: '',
    successful: false,
  })
  const [categories, setCategories] = useState([])
  const [isLastLevelSelected, setIsLastLevelSelected] = useState(true)
  const [proccesToAddCategorie, setProccesToAddCategorie] = useState(false)
  const [categoriesBreadCrumb, setCategoriesBreadCrumb] = useState([])
  const [isLoadingCategories, setIsLoadingCategories] = useState(false)
  const [editPercent, setEditPercent] = useState(false)
  const [percentCategory, setPercentCategory] = useState('')
  const changeCategories = () => {
    if (rowcategoryshopify?.data?.length) {
      const copyData = listCategoriesShopify
      rowcategoryshopify.data.forEach((row) => {
        const dataCategory = copyData[row.index]
        const categoryLugoPago = categoriesBreadCrumb
        const matchCategory = {
          idShopify: dataCategory.idShopify,
          shopifyCategory: dataCategory.shopifyCategory,
          categoryLugoPago: categoryLugoPago,
        }
        copyData.splice(row.index, 1, matchCategory)
        const newData = copyData
        setListCategoriesShopify(newData)
      })
      onCancel()
      return
    }
    const copyData = listCategoriesShopify
    const dataCategory = listCategoriesShopify[rowcategoryshopify]
    const categoryLugoPago = categoriesBreadCrumb
    const matchCategory = {
      idShopify: dataCategory.idShopify,
      shopifyCategory: dataCategory.shopifyCategory,
      categoryLugoPago: categoryLugoPago,
    }
    copyData.splice(rowcategoryshopify, 1, matchCategory)
    const newData = copyData
    const idCategory = newData[rowcategoryshopify].categoryLugoPago
    setListCategoriesShopify(newData)
    if (allProductShopifySelectedNum[rowcategoryshopify].variants[0].option1 !== 'Default Title') {
      getAttributes([idCategory[idCategory.length - 1].id])
      getAttributesShopify(dataCategory.idShopify)
      setDialogAlertVariation({
        open: true,
      })
    }

    onCancel()
  }
  const addCategories = () => {
    const categorieToAdd = categoriesBreadCrumb.find(
      (categorie) => categorie.id === categoriesBreadCrumb[categoriesBreadCrumb.length - 1].id
    )
    if (categorieToAdd) {
      const newArray = []
      categoriesBreadCrumb.forEach((item) => {
        newArray.push({
          id: item.id,
          name: item.name,
          slug: item.slug,
        })
      })

      setProccesToAddCategorie(true)
      if (isTemplate) {
        setProduct({ ...product, categories: [...product.categories, newArray] })
      } else {
        setProduct({ ...product, categories: newArray })
      }
    }
  }

  const getCategoriesByIdEndpoint = (id, name, isBreadCrumb, percentage, slug, metaData) => {
    setIsLoadingBackDrop(true)
    setEditPercent(false)
    setPercentCategory('')
    Axios.get(`${API_CATEGORIES}/CategoriesByParentId/${id}`)
      .then(function (response) {
        const categoryById = response.data.data
        const foundCategorieBreadCrumb = categoriesBreadCrumb.find(
          (categorie) => categorie.id === id
        )
        if (!foundCategorieBreadCrumb || isBreadCrumb) {
          setIsLoadingBackDrop(false)
          if (isBreadCrumb) {
            setIsLastLevelSelected(false)
            if (categoriesBreadCrumb.length === 0) {
              product.categories.map((categorie) => {
                categoriesBreadCrumb.push(categorie)
                setIsLoadingBackDrop(false)
                return true
              })
            }
            const untilNumber = categoriesBreadCrumb.findIndex(
              (categorie) => categorie.name === name
            )
            const newArray = categoriesBreadCrumb.slice(0, untilNumber + 1)
            categoriesBreadCrumb.splice(0, categoriesBreadCrumb.length)
            setCategoriesBreadCrumb(newArray)
          }

          if (categoryById) {
            if (categoryById.length > 0) {
              if (!isBreadCrumb) {
                categoriesBreadCrumb.push({
                  id: id,
                  name: name,
                  slug: slug,
                  percentage: percentage,
                  metaData,
                })
                setCategoriesBreadCrumb([...categoriesBreadCrumb])
              }
              setCategories([])
              setCategories(categoryById)
              setIsLoadingBackDrop(false)
            } else {
              if (!isBreadCrumb) {
                if (isLastLevelSelected && isTemplate)
                  categoriesBreadCrumb.splice(
                    categoriesBreadCrumb.length - 1,
                    categoriesBreadCrumb.length
                  )
                categoriesBreadCrumb.push({
                  id: id,
                  name: name,
                  slug: slug,
                  percentage: percentage,
                  metaData,
                })
                setCategoriesBreadCrumb([...categoriesBreadCrumb])
              }
              setIsLastLevelSelected(true)
              addCategories()
              setIsLoadingBackDrop(false)
            }
            setIsLoadingCategories(false)
            return true
          }
        }
        setIsLoadingCategories(false)
      })
      .catch(function (error) {
        setIsLoadingCategories(false)
      })
  }
  const getCategoriesById = async (e, id, name, percentage = 0, isBreadCrumb, slug, metaData) => {
    getCategoriesByIdEndpoint(id, name, isBreadCrumb, percentage, slug, metaData)
  }

  const renderListCategories = (categoriesFromData) =>
    categoriesFromData.map((category) => {
      return (
        <>
          <ListItem
            key={category.id}
            role={undefined}
            dense
            button
            onClick={(e) =>
              getCategoriesById(
                e,
                category.id,
                category.name,
                category.percentage,
                false,
                category.slug,
                {
                  id: category.id,
                  name: category.name ?? '',
                  title: category.title ?? '',
                  description: category.description ?? '',
                  bodyText: category.bodyText ?? '',
                  categoryQuestions: category.categoryQuestions ?? [],
                  relatedLinks: category.relatedLinks ?? [],
                }
              )
            }
          >
            <ListItemText
              id={category.id}
              primary={<Typography variant="paragraph">{category.name}</Typography>}
              disableTypography
            />
            <ListItemSecondaryAction
              onClick={(e) =>
                getCategoriesById(
                  e,
                  category.id,
                  category.name,
                  category.percentage,
                  false,
                  category.slug,
                  {
                    id: category.id,
                    name: category.name ?? '',
                    title: category.title ?? '',
                    description: category.description ?? '',
                    bodyText: category.bodyText ?? '',
                    categoryQuestions: category.categoryQuestions ?? [],
                    relatedLinks: category.relatedLinks ?? [],
                  }
                )
              }
            >
              <IconButton edge="end" aria-label="next">
                <ChevronRight fontSize="small" />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
          <Divider variant="fullWidth" component="li" />
        </>
      )
    })
  const getCategoryEndPoint = () => {
    setIsLoadingBackDrop(true)
    Axios.get(`${API_CATEGORIES}`)
      .then(function (response) {
        setIsLoadingBackDrop(false)
        setCategories(response.data)
      })
      .catch(function (error) {
        // handle error
        setIsLoadingBackDrop(true)
      })
  }

  const getCategories = async () => {
    getCategoryEndPoint()
  }

  const renderBreadCrumb = () =>
    categoriesBreadCrumb.map((categorie, index) => {
      return (
        <>
          <Link
            component={categoriesBreadCrumb.length - 1 === index ? 'typography' : 'button'}
            color="inherit"
            underline={categoriesBreadCrumb.length - 1 === index ? 'none' : 'always'}
            onClick={
              categoriesBreadCrumb.length - 1 === index
                ? null
                : (e) =>
                    getCategoriesById(
                      e,
                      categorie.id,
                      categorie.name,
                      categorie.percentage,
                      true,
                      categorie.slug,
                      categorie.metaData
                    )
            }
          >
            {categorie.name}
          </Link>
        </>
      )
    })
  const exportProduct = async () => {
    try {
      const newArrayCategoriesChild = []
      product.categories.map((categorie) => {
        return categorie.map((child, index) => {
          if (index === categorie.length - 1) {
            return newArrayCategoriesChild.push({ id: child.id, name: child.name })
          }
        })
      })
      setIsLoadingBackDrop(true)
      const response = await Axios({
        url: `${API_PRODUCTS}/ExportProductsWithWarehouse/${userLoggedin.sellerId}`,
        method: 'POST',
        data: {
          categoriesExport: newArrayCategoriesChild,
        },
        responseType: 'blob',
      })
      if (response) {
        if (response.status === 200) {
          const link = document.createElement('a')
          link.href = window.URL.createObjectURL(new Blob([response.data]))
          link.setAttribute('download', `exportaciónMasiva${Date.now()}.xlsx`)
          document.body.appendChild(link)
          link.click()
          setIsLoadingBackDrop(false)
          onCancel()
        }
      }
    } catch (error) {
      setIsLoadingBackDrop(false)
    }
  }

  const onConfirm = async () => {
    try {
      const newArrayCategoriesChild = []
      product.categories.map((categorie) => {
        return categorie.map((child, index) => {
          if (index === categorie.length - 1) {
            return newArrayCategoriesChild.push({ categoryId: child.id })
          }
        })
      })
      setIsLoadingBackDrop(true)
      const response = await Axios({
        url: `${isUpdateProduct ? API_PRODUCTS_UPDATE : API_PRODUCTS_TEMPLATE}`,
        method: 'POST',
        data: newArrayCategoriesChild,
        responseType: 'blob',
      })
      if (response) {
        if (response.status === 200) {
          const link = document.createElement('a')
          link.href = window.URL.createObjectURL(new Blob([response.data]))
          link.setAttribute('download', `Plantilla${Date.now()}.xlsx`)
          document.body.appendChild(link)
          link.click()
          setIsLoadingBackDrop(false)
          onCancel()
        }
      }
    } catch (error) {
      setIsLoadingBackDrop(false)
    }
  }
  const renderBackToListEndPoint = () => {
    if (!isTemplate) {
      product.categories = []
    }
    setProccesToAddCategorie(true)
    categoriesBreadCrumb.splice(0, categoriesBreadCrumb.length)
    setIsLastLevelSelected(false)
    getCategories()
  }
  const renderBackToList = () => {
    if (!isTemplate) {
      product.categories = []
    }
    setProccesToAddCategorie(true)
    categoriesBreadCrumb.splice(0, categoriesBreadCrumb.length)
    setIsLastLevelSelected(false)
    getCategories()
  }
  const handleCloseDialog = () => {
    setDialogAlert({ ...dialogAlert, open: false })
  }

  const renderFirstCategoriesLevel = () => {
    setEditPercent(false)
    setPercentCategory('')
    categoriesBreadCrumb.splice(0, categoriesBreadCrumb.length)
    if (!isTemplate) {
      product.categories = []
    }
    let array = localStorage.getItem('arrayCategories')
    array = JSON.parse(array)
    if (array) {
      setCategories(array)
    } else {
      setIsLastLevelSelected(false)
      getCategories()
    }
  }

  useEffect(() => {
    renderBreadCrumb()
  }, [categories, categoriesBreadCrumb, categorylocal])

  useEffect(() => {
    if (iscategoryAdmin) {
      getCategoryEndPoint()
      setIsLastLevelSelected(false)
      return
    }
    if (isAdd) {
      if (!isBackToCategories) {
        product.categories = []
        getCategories()
        setIsLastLevelSelected(false)
      } else {
        setIsLastLevelSelected(true)
        setIsBackToCategories(false)
      }
    } else if (isEdit) {
      setIsLastLevelSelected(true)
    } else {
      setIsLastLevelSelected(true)
    }
  }, [categorylocal])
  const categoriesPercentModified = (categoryModified) => {
    setIsLoadingCategories(true)
    setPercentCategory('')
    apicategoriesPercentByIdSeller(categoryModified)
      .then((response) => {
        showDialogAlert(
          true,
          '¡Cambio exitoso!',
          `Se ha guardado correctamente la información`,
          'Cerrar',
          '',
          true
        )
        setIsOpenModalCategories(false)
        setEditPercent(false)
        setIsLoadingCategories(false)
      })
      .catch(() => {
        setIsLoadingCategories(false)
      })
  }
  const finishCategory = (category) => {
    const categoriesSellerId = {
      id: category.id,
      percent: percentCategory,
    }
    const categoryModified = {
      sellerId: idSeller.id,
      categories: [categoriesSellerId],
    }
    categoriesPercentModified(categoryModified)
  }
  const updeteFinishCategory = (category) => {
    const categoryModified = {
      id: category.id,
      percent: percentCategory,
    }
    setIsLoadingCategories(true)
    apicategoriesPercentById(categoryModified)
      .then((response) => {
        setPercentCategory('')
        setEditPercent(false)
        setIsLoadingCategories(false)
        showDialogAlert(true, '¡Comisión actualizada correctamente!', null, 'Cerrar', '', true)
      })
      .catch(() => {
        setIsLoadingCategories(false)
      })
  }
  return (
    <>
      <BackDrop open={isLoadingCategories} />
      <DialogAlert
        open={dialogAlert.open}
        title={dialogAlert.title}
        message={dialogAlert.message}
        handleClose={handleCloseDialog}
        textButtonConfirm={dialogAlert.textButtonConfirm}
        actionConfirm={dialogAlert.actionConfirm}
        successful={dialogAlert.successful}
      />
      <Typography className={classes.instructions}>
        {!isLastLevelSelected || (!isTemplate && !isLastLevelSelected) ? (
          <>
            <BackDrop open={categorylocal} />
            <Grid container direction="column">
              {isTemplate ? (
                <>
                  <Typography variant="subtitle1" className={classes.alignHeaderListTitleTemplate}>
                    Selecciona una opción
                  </Typography>
                  {product.categories.length > 0 && (
                    <Link
                      color="inherit"
                      component="button"
                      onClick={(e) => {
                        e.preventDefault()
                        setIsLastLevelSelected(true)
                        setProccesToAddCategorie(true)
                      }}
                      underline="always"
                      className={classes.showCategoriesSelected}
                    >
                      Ver categorías agregadas
                    </Link>
                  )}
                </>
              ) : (
                <Typography variant="subtitle1" className={classes.alignHeaderListTitle}>
                  Selecciona una opción
                </Typography>
              )}
              <Breadcrumbs
                maxItems={5}
                className={!isTemplate && classes.alignHeaderBreadCrumb}
                separator={<ChevronRight fontSize="small" />}
                aria-label="breadcrumb"
              >
                {categoriesBreadCrumb.length > 0 && (
                  // eslint-disable-next-line jsx-a11y/anchor-is-valid
                  <Link
                    color="inherit"
                    component="button"
                    underline="always"
                    onClick={() => {
                      setEditPercent(false)
                      setPercentCategory('')
                      categoriesBreadCrumb.splice(0, categoriesBreadCrumb.length)
                      if (!isTemplate) {
                        product.categories = []
                      }
                      let array = localStorage.getItem('arrayCategories')
                      array = JSON.parse(array)
                      if (array) {
                        setCategories(array)
                      } else {
                        getCategories()
                      }
                    }}
                  >
                    Categorías
                  </Link>
                )}
                {categoriesBreadCrumb.length > 0 && renderBreadCrumb()}
                {categoriesBreadCrumb.length > 0 && userLoggedin.role === SUPER_ADMIN && (
                  <div>
                    <Tooltip title="Editar">
                      <IconButton
                        className={classes.btnEdit}
                        onClick={(event) => {
                          event.preventDefault()
                          setEditPercent(true)
                        }}
                      >
                        <PencilIcon />
                      </IconButton>
                    </Tooltip>
                  </div>
                )}
              </Breadcrumbs>
            </Grid>
            {editPercent ? (
              <div className={`${classes.alignHeaderBreadCrumb} ${classes.inputPercentCategory}`}>
                <Grid container xs={12}>
                  <Grid item>
                    <Grid container xs={12} alignItems="center">
                      <Grid item>
                        <label>Porcentaje de comisión: </label>
                        <TextField
                          fullWidth
                          id="percent"
                          name="percent"
                          // helperText={<p className={classes.messageError}>{messageError}</p>}
                          value={percentCategory || ''}
                          variant="outlined"
                          onChange={(event) => {
                            setPercentCategory(event.target.value)
                          }}
                        />
                      </Grid>
                      <Grid item>
                        <Tooltip title="Guardar">
                          <IconButton
                            className={classes.btnEdit}
                            onClick={(event) => {
                              event.preventDefault()
                              if (isAdminCategory) {
                                finishCategory(
                                  categoriesBreadCrumb[categoriesBreadCrumb.length - 1]
                                )
                              } else {
                                updeteFinishCategory(
                                  categoriesBreadCrumb[categoriesBreadCrumb.length - 1]
                                )
                              }
                            }}
                          >
                            <CheckIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Cancelar">
                          <IconButton
                            className={classes.btnDelete}
                            onClick={(event) => {
                              event.preventDefault()
                              setEditPercent(false)
                              setPercentCategory('')
                            }}
                          >
                            <CloseIcon />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <hr style={{ margin: '20px 0' }} />
                    <CategoryMetadata
                      categoryData={categoriesBreadCrumb[categoriesBreadCrumb.length - 1].metaData}
                      onClose={renderFirstCategoriesLevel}
                    />
                  </Grid>
                </Grid>
              </div>
            ) : (
              <GridList cellHeight="auto" cols={1}>
                <Grid container direction="row" alignItems="center">
                  <List
                    className={
                      isTemplate ? classes.rootListImportProduct : classes.rootListAddProduct
                    }
                    style={{
                      maxHeight: listHeight,
                    }}
                  >
                    {categories && categories.length > 0 && renderListCategories(categories)}
                  </List>
                </Grid>
              </GridList>
            )}
          </>
        ) : (
          <>
            {isTemplate && isLastLevelSelected ? (
              <CategoriesSelected
                renderBackToList={renderBackToList}
                getCategoriesById={getCategoriesById}
              />
            ) : (
              <>
                {iscategoryAdmin || isAdminCategory ? (
                  <>
                    <CategorPercentage
                      setIsLastLevelSelected={setIsLastLevelSelected}
                      renderBackToList={renderBackToListEndPoint}
                      getCategoriesById={getCategoriesById}
                      categoriesBreadCrumb={categoriesBreadCrumb}
                      idSeller={idSeller}
                      setIsOpenModalCategories={setIsOpenModalCategories}
                      categoriesPercentModified={categoriesPercentModified}
                    />
                    {categoriesBreadCrumb[categoriesBreadCrumb.length - 1] && (
                      <CategoryMetadata
                        categoryData={
                          categoriesBreadCrumb[categoriesBreadCrumb.length - 1].metaData
                        }
                        onClose={renderFirstCategoriesLevel}
                      />
                    )}
                  </>
                ) : (
                  <ValidateCategoriesVariable
                    setIsLastLevelSelected={setIsLastLevelSelected}
                    renderBackToList={renderBackToList}
                    getCategoriesById={getCategoriesById}
                    isApprove={isApprove}
                  />
                )}
              </>
            )}
          </>
        )}
      </Typography>
      {isTemplate ? (
        <>
          {!isShopifyProducts ? (
            <>
              {!isAdminCategory && (
                <Button
                  onClick={(event) => {
                    event.preventDefault()
                    if (warehouses.length === 0) {
                      setDialogAlert({
                        open: true,
                        title: '¡Advertencia!',
                        message: `Agregue bodegas`,
                        successful: false,
                        actionConfirm: () => {
                          handleCloseDialog()
                        },

                        textButtonConfirm: 'Cerrar',
                      })
                      return
                    }
                    if (isProduct && !isImportProduct) {
                      exportProduct()
                    } else if (isImportProduct || isUpdateProduct) {
                      onConfirm()
                    }
                  }}
                  disabled={product.categories.length <= 0}
                  variant="contained"
                  className={classes.btnDownloadCategorie}
                >
                  Descargar archivo
                </Button>
              )}
            </>
          ) : (
            <Button
              onClick={(event) => {
                event.preventDefault()
                changeCategories()
              }}
              disabled={product.categories.length <= 0}
              variant="contained"
              className={classes.btnDownloadCategorie}
            >
              {isShopifyProducts ? 'Finalizar' : 'Cambiar categorías'}
            </Button>
          )}
          {product.categories.length > 0 &&
            proccesToAddCategorie &&
            isLastLevelSelected &&
            !isShopifyProducts && (
              <Button
                onClick={(event) => {
                  event.preventDefault()
                  renderBackToList()
                }}
                className={[classes.leftChangeCategorie, classes.btnChangeCategorie]}
              >
                Agregar otra categoría
              </Button>
            )}
        </>
      ) : (
        <>
          {!iscategoryAdmin && !isAdminCategory && (
            <Grid
              container
              alignItems="center"
              justifyContent="center"
              className={styles.gridButtons}
            >
              <Button className={styles.btnBackgroundError} onClick={handleCancel}>
                {!isApprove ? 'Cancelar' : 'No hacer nada'}
              </Button>
              {isLastLevelSelected && (
                <Button
                  className={styles.btnBackgroundPrimary}
                  variant="contained"
                  color="secondary"
                  onClick={handleNext}
                >
                  Continuar
                </Button>
              )}
            </Grid>
          )}
        </>
      )}
    </>
  )
}

export default CategoriesListBreadCrumb
