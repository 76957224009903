import React from 'react'

import { Typography, Card, CardContent, Grid, Fab } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    background: 'rgba(0, 0, 0, 0)',
    boxShadow: 'none',
  },
  marginFloat: {
    background: 'white',
    pointerEvents: 'none',
    padding: 10,
  },
  responsiveWidth: {
    minWidth: 360,
    [theme.breakpoints.down('sm')]: {
      minWidth: 325,
    },
  },
  numberWidth: {
    minWidth: 280,
  },
}))

const Info = ({ icon, text, withFloat, number, center }) => {
  const classes = useStyles()
  return (
    <div>
      <Card className={[classes.root, number ? classes.numberWidth : classes.responsiveWidth]}>
        <CardContent>
          <Grid
            container
            direction={withFloat || number ? 'row' : 'column'}
            justify={!center ? 'space-between' : 'space-evenly'}
          >
            <Grid item xs={withFloat || number ? 1 : 12} style={{ margin: 'auto 0 auto 0' }}>
              {withFloat ? (
                <Fab size="large" color="inherit" aria-label="add" className={classes.marginFloat}>
                  {icon}
                </Fab>
              ) : (
                <Typography style={{ fill: 'black' }}>{icon}</Typography>
              )}
            </Grid>
            <Grid item xs={withFloat || number ? 9 : 12}>
              <Typography variant="body1">{text}</Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  )
}

export default Info
