import { green, red } from '@material-ui/core/colors'

import { cardTitle, whiteColor } from 'assets/jss/material-dashboard-pro-react'

const loginPageStyle = (theme) => ({
  container: {
    zIndex: '4',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      paddingBottom: '100px',
    },
    backgroundColor: theme.palette.secondary.light,
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  containerLeft: {
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      paddingBottom: '100px',
    },
    backgroundColor: theme.palette.secondary.pink,
    height: '100vh',
  },
  containerLogin: {
    maxHeight: '100vh',
    backgroundColor: theme.palette.secondary.light,
    overflow: 'hidden',
  },
  form: {
    width: 400,
    margin: '20% 0 20% 0',
    [theme.breakpoints.down('sm')]: {
      marginTop: '55%',
    },
  },
  logo: {
    width: '100%',
  },
  cardAlert: {
    width: '50%',
  },
  cardTitle: {
    ...cardTitle,
    color: whiteColor,
  },
  textCenter: {
    textAlign: 'center',
    marginBottom: 10,
  },

  justifyContentCenter: {
    width: '100%',
  },
  bodycard: {
    width: '100%',
  },
  customButtonClass: {
    '&,&:focus,&:hover': {
      color: red,
    },
    marginLeft: '5px',
    marginRight: '5px',
  },

  inputAdornment: {
    marginRight: '18px',
  },
  cardHidden: {
    opacity: '0',
    transform: 'translate3d(0, -60px, 0)',
  },
  cardHeader: {
    marginBottom: '20px',
  },
  socialLine: {
    padding: '0.9375rem 0',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    marginTop: 3,
  },
  recoverPassword: {
    color: '#000000',
    fontSize: 16,
    textDecoration: 'underline !important',
    margin: '0 auto',
    '&:hover': {
      cursor: 'pointer',
      color: theme.palette.secondary.pink,
    },
  },
  btnAccept: {
    width: '248px',
    height: '56px',
    borderRadius: '50px',
    border: 'none',
    margin: '20px auto',
    backgroundColor: '#5e44ff',
    color: 'white',
    fontWeight: '500',
    '&:hover': {
      fontWeight: 'bolder',
      backgroundColor: '#ef0bef',
      boxShadow: '-7px 7px 0px #000000',
      border: '2px solid #000000',
      borderRadius: '50px',
      opacity: '1',
    },
  },
  input: {
    width: '100%',
    marginTop: 0,
    marginBottom: 20,
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    border: '1px solid #E8E8E8',
    borderRadius: '8px',
    [theme.breakpoints.down('sm')]: {
      width: '90%',
      marginLeft: 14,
    },
    '&:input': {
      padding: 5,
    },
  },
  typographyDescription: {
    marginTop: '50%',
  },
  labelInput: {
    marginBottom: 8,
    color: '#000000',
    fontFamily: 'Red Hat Display Bold',
    fontSize: '16px',
    [theme.breakpoints.down('sm')]: {
      width: '90%',
      marginLeft: 14,
    },
  },
  loginPosition: {
    width: '100%',
  },
  loginImg: {
    position: 'absolute',
    top: '21%',
    left: '25%',
    [theme.breakpoints.down('lg')]: {
      width: '76%',
      top: '27%',
    },
  },
  loginParkles: {
    position: 'absolute',
    top: '43%',
    left: '92%',
    [theme.breakpoints.down('lg')]: {
      top: '46%',
      left: '86%',
    },
  },
  loginTitle: {
    color: theme.palette.white.main,
    margin: '20% 0 0 15%',
    fontSize: '12.6rem',
    fontWeight: 900,
    width: '585px',
    lineHeight: '8.81rem',
    fontFamily: 'Red Hat Display Black',
    [theme.breakpoints.down('lg')]: {
      fontSize: '8rem',
      lineHeight: '7rem',
    },
  },
})

export default loginPageStyle
