import React from 'react'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core'

import useStyles from 'views/Products/products.styles'

const CouponAlert = ({ open, handleClose, response }) => {
  const classes = useStyles()
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {response.isSuccessful ? 'Operacion exitosa' : 'Operacion fallida'}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {response.isSuccessful
            ? 'Si desea, puede descargar el archivo para conocer el resultado de la activacion de cupones'
            : 'Ocurrió un error inesperado, intente nuevamente o comuniquese con soporte'}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          color="primary"
          className={classes.btnBackgroundErrorPriceSmall}
        >
          Cerrar
        </Button>
        {response.isSuccessful && (
          <a href={response.data.fileUrl} target="_blank" rel="noreferrer">
            <Button className={classes.btnNewProductsmall}>Descargar</Button>
          </a>
        )}
      </DialogActions>
    </Dialog>
  )
}

export default CouponAlert
