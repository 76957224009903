import { makeStyles } from '@material-ui/core/styles'
import { white } from 'material-ui/styles/colors'

export default makeStyles((theme) => ({
  card: {
    marginTop: '5%',
  },
  cardTitle: {
    textAlign: 'center',
    marginTop: '0',
    minHeight: 'auto',
    fontWeight: '300',
    marginBottom: '3px',
    textDecoration: 'none',
  },
  parragraf: {
    paddingLeft: '10%',
    paddingRight: '10%',
  },
  form: {
    display: 'flex',
    flexWrap: 'wrap',
    // maxWidth: 600,
    // gap: '12px 30px',
  },
  spanError: {
    color: 'red',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  emailSent: {
    marginTop: -4,
    paddingLeft: '10%',
    paddingRight: '10%',
  },
  inputWhite: {
    background: 'white',
    color: 'black',
    borderRadius: 10,
    minWidth: 286,
    maxWidth: 319,
  },
  topInputLabel: {
    marginBottom: 7,
    fontSize: 16,
    color: 'white',
  },
  btnAceptar: {
    backgroundColor: '#EF16EF',
    minWidth: 286,
    maxWidth: 319,
    height: 44,
    marginBottom: 20,
    width: '100%',
    color: 'white',
    '&:hover': {
      backgroundColor: '#2AA0C6',
      boxShadow: 'none',
    },
    [theme.breakpoints.down('sm')]: {
      minWidth: '100%',
      marginBottom: '10%',
    },
  },
  btnAceptarSecond: {
    backgroundColor: '#2AA0C6',
    minWidth: 286,
    maxWidth: 319,
    height: 44,
    color: 'white',
    '&:hover': {
      backgroundColor: '#EF16EF',
      boxShadow: 'none',
    },
    [theme.breakpoints.down('sm')]: {
      minWidth: '100%',
      marginBottom: '10%',
    },
  },
  gridInfo: {
    rowGap: '20px',
    maxWidth: 600,
  },
  wrapInput: {
    marginTop: '5%',
    minWidth: 286,
    maxWidth: 319,
  },
  wrapInputPersonType: {
    width: 280,
    marginBottom: 292,
  },
  gridInfoAndForm: {
    columnGap: '30px',
    marginTop: '5%',
  },
  container: {
    padding: '70px 50px 0 50px',
    rowGap: '40px',
    flexWrap: 'nowrap',
  },
  divImageFooter: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    marginBottom: 0,
    height: 202,
    overflow: 'hidden',
    zIndex: -1,
  },
  autocompleteMargin: {
    marginTop: '-17px',
  },
  mainContainer: {
    backgroundColor: '#5E44FF',
    width: '100%',
    position: 'relative',
    padding: '3% 10% 0px 15%',
    [theme.breakpoints.down('lg')]: {
      padding: '3% 20% 0px 15%',
    },
    [theme.breakpoints.down('md')]: {
      padding: '3% 20% 0px 15%',
    },

    '&::after': {
      content: '""',
      position: 'absolute',
      width: 163,
      height: '80%',
      backgroundColor: '#EF16EF',
      right: '20%',
      bottom: 0,
      zIndex: 0,
      transform: 'skew(-45deg, 0)',
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
  },
  sendInfo: {
    backgroundColor: '#FAFAFA',
    width: '100%',
    padding: '2.5% 10% 2.5% 14%',
    [theme.breakpoints.down('lg')]: {
      padding: '2.5% 10% 2.5% 14%',
    },
    [theme.breakpoints.down('md')]: {
      padding: '2.5% 0% 2.5% 15%',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '2.5% 10% 10% 10%',
    },
  },
  divCreditInfo: {
    width: '100%',
    padding: '0 10% 0% 10%',

    [theme.breakpoints.down('lg')]: {
      padding: '0% 0% 0% 1%',
    },
    [theme.breakpoints.down('md')]: {
      padding: '0 0% 0% 0%',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '0 0% 10% 5%',
    },
  },
  divPayLater: {
    marginTop: '2%',
    marginLeft: '5%',
    [theme.breakpoints.down('md')]: {
      marginLeft: '10%',
    },
  },

  creditInfo: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
    rowGap: '2%',
    marginTop: '2%',
    [theme.breakpoints.down('lg')]: {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: 'repeat(1, 1fr)',
    },
  },
  conectInfo: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 0.5fr)',
    rowGap: '2%',
    marginTop: '2%',
    marginLeft: '10%',
    [theme.breakpoints.down('lg')]: {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: 'repeat(1, 1fr)',
    },
  },

  imgGirl: {
    width: 350,
    marginTop: 0,
  },
  divHowWorks: {
    backgroundColor: '#FAFAFA',
    width: '100%',
    margin: 0,
    height: 'auto',
    padding: '0 10% 0 0',
    [theme.breakpoints.down('lg')]: {
      padding: '0% 10% 0% 0%',
    },
    [theme.breakpoints.down('md')]: {
      padding: '0% 10% 0% 0%',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '0 0 1% 0',
    },
  },

  borderLeftBlue: {
    display: 'flex',
    alignItems: 'center',
    padding: ' 0 14% 0 5%',
    background: '#5E44FF',
    width: 300,
    height: 230,
    float: 'left',
    position: 'relative',
    '&::after': {
      right: 0,
      width: 0,
      bottom: 0,
      height: 0,
      content: '""',
      background: '#FAFAFA',
      borderTop: '230px solid #5E44FF',
      borderLeft: '0px solid lightpink',
      borderRight: '200px solid #FAFAFA',
      borderBottom: '0px solid #FAFAFA',
      position: 'absolute',
      [theme.breakpoints.down('sm')]: {
        borderRight: '120px solid #FAFAFA',
      },
    },
  },
  divHowConect: {
    backgroundColor: '#FAFAFA',
    width: '100%',
    padding: '2.5% 10% 2.5% 14%',
    [theme.breakpoints.down('lg')]: {
      padding: '2.5% 10% 2.5% 14%',
    },
    [theme.breakpoints.down('md')]: {
      padding: '2.5% 10% 2.5% 10%',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '5% 15% 10% 10%',
    },
  },
  footer: {
    backgroundColor: 'black',
    color: 'white',
    paddingTop: '2%',
    paddingLeft: '10%',
    paddingBottom: '3%',
    paddingRight: '3%',
    [theme.breakpoints.down('xs')]: {
      height: 'auto',
    },
  },
  white: {
    color: 'white',
  },
  formInput: {
    color: 'white',
  },
  secondForm: {
    backgroundColor: 'white',
    width: '100%',
    height: 300,
    zIndex: 2,
    position: 'relative',
    padding: '3% 10% 0px 20%',
    [theme.breakpoints.down('lg')]: {
      padding: '3% 10% 0px 10%',
    },
    [theme.breakpoints.down('md')]: {
      padding: '3% 0% 0px 0%',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '5% 15% 10% 10%',
    },

    '&::after': {
      content: '""',
      position: 'absolute',
      width: 110,
      backgroundColor: '#5e44ff',
      left: '20%',
      bottom: 0,
      top: 0,
      zIndex: -1,
      transform: 'skew(-45deg, 0)',
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
  },
}))
