import React from 'react'

import { Grid, Typography } from '@material-ui/core'

import styles from '../generalConfig.styles'

import { LuegopagoIconWhite } from 'components/icons/personalizedIcons'

const Header = () => {
  const classes = styles()
  return (
    <Grid
      container
      direction="column"
      justifyContent="space-between"
      alignItems="center"
      className={classes.header}
    >
      <Typography className={classes.termsAndConditions}>
        Términos y condiciones | Ayuda/PQR | Contacto
      </Typography>
      <div className={classes.Logo}>
        <LuegopagoIconWhite />
      </div>
      <Typography className={classes.bienvenidoSeller}>
        Bienvenido a nuestro Seller Center
      </Typography>
    </Grid>
  )
}

export default Header
