import React, { useState, useEffect, useContext } from 'react'

import {
  Button,
  Grid,
  Link,
  Typography,
  TextField,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core'
import Axios from 'axios'
import CSVFileValidator from 'csv-file-validator'

import ModalCategoriesImport from './ModalCategoriesImport'
import useStyles from './uploads.styles'

import BackDrop from 'components/BackDrop'
import DialogAlert from 'components/DialogAlert'
import DropZoneMarketing from 'components/DropZoneMarketing'
import { UPLOAD_FILE_MARKETING } from 'constants/urls'
import useAuthContext from 'contextApi/AuthContext'
import { useProduct } from 'contextApi/ProductContext'
import {
  loadCampaignsMarketing,
  refreshTokenMCS,
  getTokenMCS,
} from 'services/marketingcampaingns/marketing.service'
import { getUnique } from 'utils/utils'

const emails = ['no-reply@sistecredito.luegopago.com', 'no-reply@luegopago.com']
const names = ['Sistecrédito', 'Luegopago']
export default function UploadMails() {
  const [loading, setLoading] = useState(false)
  const { userLoggedin } = useContext(useAuthContext)
  const { product, handleCancel, warehouses, setWarehouses } = useProduct()
  const [IsOpenModalCategories, setIsOpenModalCategories] = useState(false)
  const [files, setFiles] = useState([])
  const [anyFileLoaded, setAnyFileLoaded] = useState(false)
  const classes = useStyles()
  const [dialogAlert, setDialogAlert] = useState({
    open: false,
    title: '',
    message: '',
    actionConfirm: null,
    textButtonConfirm: '',
    successful: false,
  })

  const [fromName, setFromName] = useState('')
  const [fromMail, setFromMail] = useState('')
  const [sudject, setSudject] = useState('')
  const [templateId, setTemplateId] = useState('')
  const openModalCategories = (event) => {
    event.preventDefault()
    product.categories = []
    setIsOpenModalCategories(false)
  }

  const refreshTokenMC = () => {
    refreshTokenMCS().then((t) => {
      localStorage.setItem('luegopago_marketing_campaigns_token', t.data.data.token)
    })
  }

  useEffect(() => {
    // if (warehouses.length === 0) {
    //   // getWarehouse()
    // }
    refreshTokenMC()
  }, [])
  const onChange = (name, value) => {}

  const handleCloseDialog = () => {
    setDialogAlert({ ...dialogAlert, open: false })
  }

  const validarArchivos = (archivos) => {
    const config = {
      headers: [
        {
          name: 'Correo electrónico',
          inputName: 'MAIL',
          required: true,
          requiredError: function (headerName, rowNumber, columnNumber) {
            return `El ${headerName} es requerido en la fila ${rowNumber} / column ${columnNumber} `
          },
        },
        {
          name: 'Nombre',
          inputName: 'NOMBRE',
          required: true,
          requiredError: function (headerName, rowNumber, columnNumber) {
            return `El ${headerName} es requerido en la fila ${rowNumber} / column ${columnNumber} `
          },
        },
      ],
      isHeaderNameOptional: true,
      isColumnIndexAlphabetic: false,
    }

    if (files.length === 1 && archivos.length > 0) {
      setDialogAlert({
        open: true,
        title: '¡Advertencia!',
        message: `Ha excedido el número máximo de plantillas.`,
        successful: false,
        actionConfirm: () => {
          handleCloseDialog()
        },
        textButtonConfirm: 'Cerrar',
      })
    } else {
      CSVFileValidator(archivos[0], config)
        .then((csvData) => {
          let messageError = ''
          if (csvData.inValidData.length > 0) {
            if (csvData.inValidData[0].message.includes('mismatch')) {
              messageError = `El número de campos no coincide: se esperaban 2 campos pero se analizó 1. En la fila ${csvData.inValidData[0].rowIndex}`
              setDialogAlert({
                open: true,
                title: '¡Error!',
                message: messageError,
                successful: false,
                actionConfirm: () => {
                  handleCloseDialog()
                },
                textButtonConfirm: 'Cerrar',
              })
            } else {
              messageError = csvData.inValidData[0].message
              setDialogAlert({
                open: true,
                title: '¡Error!',
                message: messageError,
                successful: false,
                actionConfirm: () => {
                  handleCloseDialog()
                },
                textButtonConfirm: 'Cerrar',
              })
            }
          } else if (archivos[0].size > 10000000) {
            messageError = 'El tamaño máximo es de 10MB'
            setDialogAlert({
              open: true,
              title: '¡Error!',
              message: messageError,
              successful: false,
              actionConfirm: () => {
                handleCloseDialog()
              },
              textButtonConfirm: 'Cerrar',
            })
          } else {
            const archivosSinDuplicar = getUnique([...archivos, ...files], 'path')
            setFiles(archivosSinDuplicar)
          }
        })
        .catch((err) => {})
    }
  }

  const onSubmit = async (event) => {
    event.preventDefault()
    if (getTokenMCS() === null) {
      refreshTokenMC()
    }
    if (
      !files.length ||
      !files.some((file) => {
        const fileSplit = file.name.split('.')
        return fileSplit[fileSplit.length - 1].toLowerCase() === 'csv'
      })
    ) {
      setDialogAlert({
        open: true,
        title: '¡Advertencia!',
        message: `Debes agregar mínimo un archivo en .csv.`,
        successful: false,
        actionConfirm: () => {
          handleCloseDialog()
        },
        textButtonConfirm: 'Cerrar',
      })
      return
    }
    setLoading(true)
    const formData = new FormData()
    formData.append(`file`, files[0])
    formData.append(`sudject`, sudject)
    formData.append(`FromName`, fromName)
    formData.append(`templateInfo`, templateId)
    formData.append(`From`, fromMail)
    loadCampaignsMarketing(formData)
      .then((response) => response)
      .then((result) => {
        if (result.status === 201) {
          setAnyFileLoaded(true)
          setDialogAlert({
            open: true,
            title: '¡Carga exitosa!',
            message: `El archivo ${files[0].path} se ha cargado exitosamente`,
            actionConfirm: () => {
              handleCloseDialog()
            },
            textButtonConfirm: 'Cerrar',
            successful: true,
          })
          setFiles([])
          setLoading(false)
        }
      })
      .catch(() => setLoading(false))
  }
  return (
    <>
      <DialogAlert
        open={dialogAlert.open}
        title={dialogAlert.title}
        message={dialogAlert.message}
        handleClose={handleCloseDialog}
        textButtonConfirm={dialogAlert.textButtonConfirm}
        actionConfirm={dialogAlert.actionConfirm}
        successful={dialogAlert.successful}
      />
      <form autoComplete="off" onSubmit={(e) => onSubmit(e)}>
        <BackDrop open={loading} />
        <Typography variant="subtitle1" align="left" style={{ marginBottom: 20 }}>
          Enviar campañas de Marketing
        </Typography>

        <Grid container direction="row" justify="center" alignItems="center">
          <Grid item sm={6}>
            <Grid container direction="column" justify="center">
              <DropZoneMarketing
                archivos={files}
                setArchivos={setFiles}
                loading={loading}
                customOnChange={onChange}
                validarArchivos={validarArchivos}
              />
              <div>
                <Grid
                  container
                  direction="row"
                  style={{ marginBottom: '10%' }}
                  justify="space-between"
                  alignItems="center"
                >
                  <InputLabel
                    style={{ marginBottom: '2%', marginTop: '2%' }}
                    className={classes.topInputLabel}
                  >
                    Template ID
                  </InputLabel>
                  <TextField
                    required
                    variant="outlined"
                    fullWidth
                    name="template Id"
                    type="text"
                    id="outlined-required"
                    autoComplete="From"
                    autoFocus
                    value={templateId}
                    onChange={(event) => {
                      setTemplateId(event.target.value)
                    }} // whenever the text field change, you save the value in state
                  />

                  <InputLabel
                    style={{ marginBottom: '2%', marginTop: '2%' }}
                    className={classes.topInputLabel}
                  >
                    Subject
                  </InputLabel>
                  <TextField
                    required
                    variant="outlined"
                    fullWidth
                    name="sudject"
                    label=""
                    type="text"
                    id="outlined-required"
                    autoComplete="sudject"
                    value={sudject}
                    onChange={(event) => {
                      setSudject(event.target.value)
                    }} // whenever the text field change, you save the value in state
                  />
                  <InputLabel
                    style={{ marginBottom: '2%', marginTop: '2%' }}
                    className={classes.topInputLabel}
                  >
                    From Mail
                  </InputLabel>
                  <Select
                    variant="outlined"
                    required
                    fullWidth
                    name="From"
                    type="text"
                    id="outlined-required"
                    autoComplete="From"
                    value={fromMail}
                    onChange={(event) => {
                      setFromMail(event.target.value)
                    }}
                  >
                    {emails.map((email) => (
                      <MenuItem key={email} value={email}>
                        {email}
                      </MenuItem>
                    ))}
                  </Select>
                  <InputLabel
                    style={{ marginBottom: '2%', marginTop: '2%' }}
                    className={classes.topInputLabel}
                  >
                    From Name
                  </InputLabel>
                  <Select
                    variant="outlined"
                    required
                    fullWidth
                    name="From"
                    type="text"
                    id="outlined-required"
                    autoComplete="From"
                    value={fromName}
                    onChange={(event) => {
                      setFromName(event.target.value)
                    }}
                  >
                    {names.map((name) => (
                      <MenuItem key={name} value={name}>
                        {name}
                      </MenuItem>
                    ))}
                  </Select>
                  <Button
                    fullWidth
                    variant="contained"
                    className={classes.btnBackgroundPrimary}
                    type="submit"
                  >
                    CARGAR FORMULARIO
                  </Button>
                </Grid>
                <Grid
                  container
                  direction="row"
                  style={{ marginBottom: '7%' }}
                  justify="space-between"
                  alignItems="center"
                />
              </div>
            </Grid>
          </Grid>
        </Grid>
      </form>
      {IsOpenModalCategories && (
        <ModalCategoriesImport
          isOpenModalCategories={IsOpenModalCategories}
          setIsOpenModalCategories={setIsOpenModalCategories}
        />
      )}
    </>
  )
}
