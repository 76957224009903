import React, { useEffect, useState } from 'react'

import { Button, Grid, IconButton, Typography, Tooltip, TextField } from '@material-ui/core'
import CheckIcon from '@material-ui/icons/Check'
import ClearIcon from '@material-ui/icons/Clear'
import axios from 'axios'
import MUIDataTable from 'mui-datatables'
import { useHistory } from 'react-router-dom'

import useStyles from '../products.styles'

import BackDrop from 'components/BackDrop'
import { EyeIcon } from 'components/icons/personalizedIcons'
import useCustomDialogAlert from 'components/useCustomDialogAlert'
import { routesLink } from 'constants/routesConsts'
import { API_BASE } from 'constants/urls'
import { useProduct } from 'contextApi/ProductContext'

const Approvals = () => {
  const { setAllRowsSelectedContext, allRowsSelectedContext } = useProduct()
  const classes = useStyles()
  const history = useHistory()
  const [isLoading, setIsLoading] = useState(false)
  const [typeAction, setTypeAction] = useState(0)
  const [data, setData] = useState([])
  const [totalApprovals, settotalApprovals] = useState(0)
  const [pagination, setPagination] = useState({
    page: 0,
    perPage: 10,
    prevPage: '',
    nextPage: '',
  })

  const showDialogAlert = useCustomDialogAlert()
  const pendingApprovals = async (perPage = 10, page = 1) => {
    setPagination({
      ...pagination,
      page: 0,
      perPage: 10,
    })
    setAllRowsSelectedContext([])
    setIsLoading(true)
    let url = ''
    url = `${API_BASE}/Store/pendingApprovals?page=${page}&pagesize=${perPage}`
    await axios
      .get(url)
      .then((response) => {
        setIsLoading(false)
        if (response.data?.data && response.data?.data.length > 0) {
          setData(response.data?.data || [])
          settotalApprovals(response.data.pagination?.totalCount)
        } else {
          setData([])
        }
        setPagination({
          page: page === 0 ? 0 : page - 1,
          perPage: perPage,
        })
      })
      .catch((e) => {
        setIsLoading(false)
        console.log(e)
      })
  }

  const Approve = async (sellerId) => {
    await axios
      .post(
        `${API_BASE}/Store/approvePendingChanges`,
        typeof sellerId === 'object' ? sellerId : [sellerId]
      )
      .then((response) => {
        setIsLoading(false)
        pendingApprovals()
      })
      .catch((e) => {
        setIsLoading(false)
        console.log(e)
      })
  }

  const RejectApproval = async (sellerId) => {
    await axios
      .post(
        `${API_BASE}/Store/rejectPendingChanges`,
        typeof sellerId === 'object' ? sellerId : [sellerId]
      )
      .then((response) => {
        setIsLoading(false)
        pendingApprovals()
      })
      .catch((e) => {
        setIsLoading(false)
        console.log(e)
      })
  }
  const handleChangeSelectAccion = async (
    e,
    selectedRows,
    displayData,
    typeSelected = typeAction
  ) => {
    e.preventDefault()
    if (typeSelected === '') {
      return false
    }
    const idSellersApprovals = displayData.map((seller) => {
      return seller.data[0]
    })
    if (typeSelected === 0) {
      showDialogAlert(
        true,
        `Aprobar`,
        `¿Esta seguro que desea aprobar ${idSellersApprovals.length || 1} modificaciónes?`,
        'Aceptar',
        'Cancelar',
        false,
        () => Approve(idSellersApprovals)
      )
    } else {
      showDialogAlert(
        true,
        `Rechazar`,
        `¿Esta seguro que desea rechazar ${idSellersApprovals.length || 1} modificaciónes?`,
        'Aceptar',
        'Cancelar',
        false,
        () => RejectApproval(idSellersApprovals)
      )
    }
    return true
  }

  useEffect(() => {
    pendingApprovals()
  }, [])

  const viewApprovals = (idSellerApproval) => {
    history.push(`${routesLink.pendingApproval}/${idSellerApproval}`)
  }

  const returnToStores = () => {
    history.push(`${routesLink.stores}`)
  }

  const changePageTable = (paginationPage) => {
    let pageInt = Number(paginationPage) + 1
    if (paginationPage === 1 && paginationPage === pagination.page) {
      pageInt = 1
    }
    setPagination({
      ...pagination,
      page: pageInt - 1,
      prevPage: pageInt - 1,
      nextPage: pageInt + 1,
    })
    pendingApprovals(pagination.perPage, pageInt)
  }

  const changePerPageTable = (value) => {
    setPagination({ ...pagination, perPage: value })
    pendingApprovals(value, pagination.page === 0 ? 1 : pagination.page)
  }

  const columns = [
    {
      name: 'id',
      options: {
        display: false,
        download: false,
      },
    },
    {
      name: 'typeApproval',
      label: 'TIPO DE APROBACION',
    },
    {
      name: 'email',
      label: 'EMAIL TIENDA',
    },
    {
      name: 'name',
      label: 'NOMBRE TIENDA',
    },
    {
      name: 'created',
      label: 'FECHA DE CREACIÓN',
    },

    {
      name: '',
      label: '',
      options: {
        download: false,
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          return (
            <>
              <Tooltip title="ver">
                <IconButton
                  className={classes.btnEdit}
                  onClick={(event) => {
                    event.preventDefault()
                    viewApprovals(tableMeta.rowData[0])
                  }}
                >
                  <EyeIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Aprobar">
                <IconButton
                  className={classes.btnEdit}
                  disabled={allRowsSelectedContext.length > 0}
                  onClick={() => Approve(tableMeta.rowData[0])}
                >
                  <CheckIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Rechazar">
                <IconButton
                  className={classes.btnDelete}
                  disabled={allRowsSelectedContext.length > 0}
                  onClick={() => RejectApproval(tableMeta.rowData[0])}
                >
                  <ClearIcon />
                </IconButton>
              </Tooltip>
            </>
          )
        },
      },
    },
  ]
  const showMenuItemsStatu = () => {
    const optionsAction = [
      <option value="">Seleccione</option>,
      <option value="0">Aprobar </option>,
      <option value="1">Rechazar </option>,
    ]

    const actionsMenu = (
      <>
        <TextField
          id="actions"
          select
          className={classes.actionsButton}
          onChange={(e) => {
            e.preventDefault()
            setTypeAction(Number(e.target.value))
          }}
          SelectProps={{
            native: true,
          }}
          variant="outlined"
        >
          {optionsAction}
        </TextField>
      </>
    )
    return <>{actionsMenu}</>
  }

  const options = {
    responsive: 'scroll',
    search: false,
    filterType: 'dropdown',
    selectableRows: 'multiple',
    rowHover: true,
    filter: false,
    sort: false,
    print: false,
    page: pagination.page,
    serverSide: true,
    fixedSelectColumn: false,
    download: false,
    count: totalApprovals,
    viewColumns: false,
    rowsPerPageOptions: [10, 15, 100],
    textLabels: {
      body: {
        noMatch: `${isLoading ? 'Cargando ...' : 'No hay registros'}`,
      },
      pagination: {
        next: 'Página siguiente',
        previous: 'Página anterior',
        rowsPerPage: 'Filas por página:',
        displayRows: 'de',
      },
      selectedRows: {
        text: 'Fila(s) Seleccionada(s)',
      },
    },
    onRowSelectionChange: (currentRowsSelected, allRowsSelected, rowsSelected) => {
      if (rowsSelected.length === 0) setTypeAction(0)
      setAllRowsSelectedContext(rowsSelected)
    },
    rowsSelected: allRowsSelectedContext,
    onTableChange: (action, tableState) => {
      switch (action) {
        case 'changePage':
          changePageTable(tableState.page === 0 ? 1 : tableState.page)
          document.querySelector('#main').scrollTop = 0
          break
        case 'changeRowsPerPage':
          changePerPageTable(tableState.rowsPerPage)
          break
        default:
          break
      }
    },
    customToolbarSelect: (selectedRows, displayData) => {
      return (
        <Grid container className={classes.toolBarSelect}>
          {showMenuItemsStatu()}
          <Button
            color="primary"
            variant="contained"
            className={classes.btnApply}
            onClick={(e) => {
              handleChangeSelectAccion(e, selectedRows, displayData)
            }}
          >
            Aplicar acción
          </Button>
        </Grid>
      )
    },
  }

  return (
    <>
      <Grid container justify="space-between">
        <Typography variant="h6">Aprobaciones: </Typography>
        <Grid className={classes.divButtonsHeader}>
          <Button
            onClick={returnToStores}
            className={classes.btnNewProduct}
            variant="contained"
            color="secondary"
          >
            Regresar a las tiendas
          </Button>
        </Grid>
      </Grid>
      <Grid container direction="column" className={classes.divContent}>
        <MUIDataTable
          data={data.map((item) => {
            return [item.sellerId, 'Términos y Condiciones', item.email, item.storeName, item.date]
          })}
          columns={columns}
          options={options}
        />
        <BackDrop open={isLoading} />
      </Grid>
    </>
  )
}
export default Approvals
