import { makeStyles } from '@material-ui/core/styles'

export default makeStyles((theme) => ({
  headerTextCategories: 'MuiFormLabel-root MuiInputLabel-root MuiInputLabel-animated',
  rootView: {
    height: 120,
    flexGrow: 1,
    maxWidth: 400,
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    margin: 0,
    color: theme.palette.text.secondary,
    '&:hover > $content': {
      backgroundColor: theme.palette.action.hover,
    },
    '&:focus > $content, &$selected > $content': {
      backgroundColor: `var(--tree-view-bg-color, ${theme.palette.grey[400]})`,
      color: 'var(--tree-view-color)',
    },
    '&:focus > $content $label, &:hover > $content $label, &$selected > $content $label': {
      backgroundColor: 'transparent',
    },
  },
  content: {
    color: theme.palette.text.secondary,
    borderTopRightRadius: theme.spacing(2),
    borderBottomRightRadius: theme.spacing(2),
    paddingRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    '$expanded > &': {
      fontWeight: theme.typography.fontWeightRegular,
    },
  },
  group: {
    marginLeft: 0,
    '& $content': {
      paddingLeft: theme.spacing(2),
    },
  },
  expanded: {},
  selected: {},
  label: {
    fontWeight: 'inherit',
    color: 'inherit',
  },
  labelRoot: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0.5, 0),
  },
  labelIcon: {
    marginRight: theme.spacing(1),
  },
  labelText: {
    fontWeight: 'inherit',
    flexGrow: 1,
  },
  rootList: {
    width: '100%',
    height: '100%',
    maxWidth: 540,
    maxHeight: 540,
    backgroundColor: theme.palette.background.paper,
  },
  alignHeaderListTitle: {
    marginLeft: '25%',
  },
  alignHeaderBreadCrumb: {
    marginLeft: '25%',
  },
  backdrop: {
    zIndex: 10000,
    color: '#fff',
  },
  contentDialog: {
    width: '50%',
  },
  closeButton: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
}))
