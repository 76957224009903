import { makeStyles } from '@material-ui/core/styles'

export default makeStyles((theme) => ({
  fullHeigh: {
    height: '341px',
  },
  positionElements: {
    marginTop: '15px',
  },
  buttonPrimary: {
    height: '56px',
    borderRadius: '50px',
    border: 'none',
    backgroundColor: '#5e44ff',
    color: 'white',
    fontFamily: 'Red Hat Display Bold',
    fontWeight: '500',
    '&:hover': {
      fontWeight: 'bolder',
      backgroundColor: '#ef0bef',
      boxShadow: '-7px 7px 0px #000000',
      border: '2px solid #000000',
      borderRadius: '50px',
      opacity: '1',
    },
  },
  leftChangeCategorie: {
    marginLeft: '20px',
  },
  rootListAddProduct: {
    width: '100%',
    overflowY: 'auto',
    backgroundColor: theme.palette.background.paper,
  },
  rootListImportProduct: {
    width: '100%',
    height: '100%',
    maxHeight: 540,
    backgroundColor: theme.palette.background.paper,
  },
  alignHeaderListTitleTemplate: {
    marginBottom: '3vh',
  },
  alignHeaderListTitle: {
    marginLeft: '0',
  },
  showCategoriesSelected: {
    position: 'absolute',
    right: '5vh',
    top: '5vh',
  },

  alignHeaderBreadCrumb: {
    marginLeft: '0',
    marginTop: '15px',
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  addCategoriesButton: {
    marginTop: '0.5rem',
  },
  container: {
    gap: '40px',
    marginBottom: 60,
  },
  modalCategoriesImport: {
    height: '50vh',
    alignContent: 'start',
    flexFlow: 'column',
    overflow: 'inherit',
    position: 'relative',
  },
  positionBreadCrumb: {
    marginTop: '10px',
  },
  deleteIcon: {
    position: 'absolute',
    right: 0,
    marginTop: '-1.5vh',
  },
  btnChangeCategorie: {
    height: '56px',
    borderRadius: '50px',
    border: 'none',
    backgroundColor: '#5e44ff',
    color: 'white',
    marginTop: 8,
    fontFamily: 'Red Hat Display Bold',
    fontWeight: '500',
    '&:hover': {
      fontWeight: 'bolder',
      backgroundColor: '#ef0bef',
      boxShadow: '-7px 7px 0px #000000',
      border: '2px solid #000000',
      borderRadius: '50px',
      opacity: '1',
    },
  },
  btnDownloadCategorie: {
    height: '56px',
    borderRadius: '50px',
    border: 'none',
    backgroundColor: '#5e44ff',
    color: 'white',
    fontFamily: 'Red Hat Display Bold',
    marginTop: 8,
    fontWeight: '500',
    '&:hover': {
      fontWeight: 'bolder',
      backgroundColor: '#ef0bef',
      boxShadow: '-7px 7px 0px #000000',
      border: '2px solid #000000',
      borderRadius: '50px',
      opacity: '1',
    },
  },
  justify: {
    justifyContent: 'space-between',
    padding: '20px 8px',
  },
  justifyCategories: {
    justifyContent: 'space-between',
    background: theme.palette.Gray.main,
    padding: '20px 8px',
  },
  borderTable: {
    width: '100%',
    display: 'block',
    border: '1px',
  },
  colorswitch: {
    color: 'red',
  },
  editPercentage: {
    display: 'flex',
    alignItems: 'center',
  },
  inputPercentCategory: {
    width: '100%',
    display: 'flex',
  },
}))
