/* eslint-disable react-hooks/rules-of-hooks */
import axios from 'axios'

import { API_SELLERS, API_LOCATION } from 'constants/urls'

// eslint-disable-next-line import/prefer-default-export
export const getSellerFormOptions = async (
  setPersonsTypes,
  setIdentificationsTypes,
  setIsLoading
) => {
  setIsLoading(true)
  axios
    .get(`${API_SELLERS}/SellerFormOptions`)
    .then(function (response) {
      setPersonsTypes(response.data.typesOfPerson)
      setIdentificationsTypes(response.data.identificationTypes)
      setIsLoading(false)
    })
    .catch(function (error) {
      // handle error
      setIsLoading(false)
      console.log(error)
    })
}

export const checkEmail = (
  email,
  setEmailVerification,
  setDialogAlertEmail,
  redirection,
  setValueTypePerson
) => {
  setEmailVerification(true)
  axios
    .get(`${API_SELLERS}/Validate/${email}`)
    .then(function (response) {
      // handle success
      setValueTypePerson(response?.data.typeOfPersonId)
      setEmailVerification(false)
      if (response.data.isSecondForm) {
        setDialogAlertEmail({
          open: true,
          title: '¡Advertencia!',
          message: `Ya te encuentras registrado en nuestra plataforma`,
          actionConfirm: () => redirection(),
          textButtonConfirm: 'Aceptar',
          textButtonCancel: null,
        })
      }
    })
    .catch(function (error) {
      setEmailVerification(false)

      // handle error
      console.log(error)
    })
}

export const getSellerForm = (setIsLoading, setTypePerson, setUpdateFrequency) => {
  setIsLoading(true)
  axios
    .get(`${API_SELLERS}/SellerFormOptions`)
    .then(function (response) {
      setTypePerson(response.data.typesOfPerson)
      setUpdateFrequency(response.data.inventoryUpdateFrequency)
      setIsLoading(false)
    })
    .catch(function (error) {
      // handle error
      setIsLoading(false)
      console.log(error)
    })
}

// eslint-disable-next-line import/prefer-default-export
export const getDepartments = async (setDepartaments, setIsLoading) => {
  setIsLoading(true)
  axios
    .get(`${API_LOCATION}/Departments`)
    .then(function (response) {
      setDepartaments(response.data)
      setIsLoading(false)
    })
    .catch(function (error) {
      // handle error
      setIsLoading(false)
      console.log(error)
    })
}
export const getCities = async (setCites, idDepartament, setIsLoading) => {
  setIsLoading(true)

  axios
    .get(`${API_LOCATION}/Cities?departmentId=${idDepartament}`)
    .then(function (response) {
      setCites(response.data)
      setIsLoading(false)
    })
    .catch(function (error) {
      setIsLoading(false)

      // handle error
      console.log(error)
    })
}
export const getCustomerType = async (setRegimesTypes, setIsLoading, typeOfPersonId) => {
  setIsLoading(true)

  axios
    .get(`${API_SELLERS}/CustomerType`)
    .then(function (response) {
      if (typeOfPersonId === 18) {
        setRegimesTypes(response.data.data.filter((data) => data.id !== 4))
      } else if (typeOfPersonId === 19) {
        setRegimesTypes(response.data.data.filter((data) => data.id === 4 || data.id === 2))
      }
      setIsLoading(false)
    })
    .catch(function (error) {
      setIsLoading(false)

      // handle error
      console.log(error)
    })
}
