import axios from 'axios'

import { UPLOAD_FILE_MARKETING, USER_MARKETING, PASSWORD_MARKETING } from 'constants/urls'

export const refreshTokenMCS = () => {
  return axios.post(`${UPLOAD_FILE_MARKETING}/auth`, {
    user: USER_MARKETING,
    password: PASSWORD_MARKETING,
  })
}
export const getTokenMCS = () => {
  return localStorage.getItem('luegopago_marketing_campaigns_token')
}

export const loadCampaignsMarketing = (formData) => {
  return axios.post(`${UPLOAD_FILE_MARKETING}/upload`, formData)
}
