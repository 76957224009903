import { makeStyles } from '@material-ui/core/styles'
import { Palette } from '@material-ui/icons'

import ThemeAppProvider from 'ThemeAppProvider'

export default makeStyles((theme) => ({
  dropZone: {
    cursor: 'pointer',
    border: 'dashed darkgray',
    marginBottom: '4vh',
    minHeight: 194,
    background: theme.palette.grey.opacity,
  },
  isDragActive: {
    backgroundColor: 'lightgray',
  },
  iconUpload: {
    width: 80,
    height: 46,
  },
  divDropIcon: {
    height: 100,
  },
  deleteFileIcon: {
    position: 'absolute',
    right: 0,
    top: 0,
  },
  middleDivider: {
    marginTop: '4vh',
  },
}))
