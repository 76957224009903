import React from 'react'

import { Grid, Typography, Box, Divider } from '@material-ui/core'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import TreeItem from '@material-ui/lab/TreeItem'
import TreeView from '@material-ui/lab/TreeView'

import useStyles from '../ReportsAccountStyle'

import CardBody from 'components/Card/CardBody.js'
import convertToCurrencyCop from 'utils/convertToCurrencyCop'

const timeArea = {
  textAlign: 'right',
}
const Stocktaking = ({ dataOrders }) => {
  const { finallyBalances } = dataOrders
  const classes = useStyles()
  return (
    <>
      <Grid
        container
        direction="column"
        justify="flex-start"
        alignItems="flex-start"
        className={classes.marginGrid}
      >
        <Typography variant="subtitle2" className={classes.widthInitialBalance}>
          Balance
        </Typography>
        <Grid
          container
          justify="space-between"
          alignItems="flex-start"
          className={classes.InitialBalancePadding}
        >
          <Typography align="left" className={classes.widthCloseInitialBalance}>
            Balance final
          </Typography>
          <Typography align="right" className={classes.widthValueInitialBalance}>
            {`$${convertToCurrencyCop(Number(finallyBalances || 0))} COP`}
          </Typography>
        </Grid>
      </Grid>
      <Divider className={classes.divider} />
    </>
  )
}
export default Stocktaking
