import { routeBase } from './environments.constants'

export const API_URL_BASE =
  routeBase[process.env.REACT_APP_ENVIRONMENT].url || routeBase.default.url

export const API_URL_BASE_MICROSERVICES_PRODUCTS =
  routeBase[process.env.REACT_APP_ENVIRONMENT].urlMicroservicesProducts || routeBase.default.url

export const API_URL_BASE_USER_MANAGEMENT =
  routeBase[process.env.REACT_APP_ENVIRONMENT].urlUserManagement || routeBase.default.url

export const API_URL_BASE_MICROSERVICES_ORDERS =
  routeBase[process.env.REACT_APP_ENVIRONMENT].urlMicroservicesOrders || routeBase.default.url
export const API_URL_BASE_MICROSERVICES_IMEGES_PROCESSOR =
  routeBase[process.env.REACT_APP_ENVIRONMENT].imageProcessor || routeBase.default.url
export const API_URL_BASE_MICROSERVICES_IMEGES_PROCESSOR_API_KEY =
  routeBase[process.env.REACT_APP_ENVIRONMENT].imageProcessorApiKey || routeBase.default.url

// url base seller center
export const FRONT = routeBase[process.env.REACT_APP_ENVIRONMENT].routeFront || routeBase.default
// url Base de shopify
export const API_URL_BASE_SHOPIFY =
  routeBase[process.env.REACT_APP_ENVIRONMENT].routeShopify.url || routeBase.default

// url Base from payments
export const API_URL_BASE_PAYMENTS =
  routeBase[process.env.REACT_APP_ENVIRONMENT].PAYMENTS.PAYMENTS_API || routeBase.default

// url Base From Travel
export const API_URL_BASE_TRAVEL = routeBase[process.env.REACT_APP_ENVIRONMENT].TRAVEL.TRAVEL_API

export const API_BASE = `${API_URL_BASE}/api`
export const URL_BACKEND_USER_MANAGEMENT = `${API_URL_BASE_USER_MANAGEMENT}/api/v1`
export const LOGIN_USER = `${URL_BACKEND_USER_MANAGEMENT}/Auth/1/login`
export const URL_USER_MANAGEMENT = `${URL_BACKEND_USER_MANAGEMENT}/Users/1`
export const USER_VERIFY_TWO_FACTOR_TOKEN = `${URL_USER_MANAGEMENT}/VerifyTwoFactorToken`
export const USER_FORGOT_PASSWORD = `${URL_USER_MANAGEMENT}/ForgotPassword`
export const USER_FORGOT_PASSWORD_CONFIRM = `${URL_USER_MANAGEMENT}/ForgotPasswordConfirm`
export const USER_CHANGE_PASSWORD = `${URL_USER_MANAGEMENT}/ChangePassword`
export const USER_CONFIRM_ACCOUNT = `${URL_USER_MANAGEMENT}/ConfirmAccount`

export const API_LOGOUT = `${API_URL_BASE}/logout`
export const API_TOKEN = `${API_URL_BASE}/Sessions/GetToken`
export const API_CATEGORIES_PERCENT = `${API_BASE}/CategoriesPercent`

export const API_ORDERS = `${API_BASE}/Orders`
export const API_LOCATION = `${API_BASE}/Locations`
export const API_EDIT_ORDER = `${API_ORDERS}/EditOrder`

export const API_UPDATE_PRODUCTS = `${API_BASE}/Products/UpdateProducts`
export const API_PRODUCTS = `${API_BASE}/Products`

export const API_PRODUCTS_UPDATE = `${API_BASE}/Products/ImportProductUpdate`
export const API_SELLERS = `${API_BASE}/Sellers`
export const API_SELLERS_EMAIL_REGISTER = `${API_BASE}/Sellers/PostSendEmailRegister`
export const API_STORE_INFO = `${API_BASE}/Store`
export const API_CATEGORIES = `${API_BASE}/Categories`
export const API_CATEGORIES_SELLER = `${API_BASE}/CategoriesSeller`
export const API_CATEGORIES_ATTRIBUTE = `${API_BASE}/CategoryAttribute`
export const API_FINANCIAL_SUMMARY = `${API_BASE}/FinancialSummary`
export const API_DASHBOARD = `${API_BASE}/Dashboard`
export const API_DAYPAY = `${API_BASE}/SellerSettings/payday`
export const API_SELLER_RANKING = `${API_BASE}/SellerSettings/payday`
export const API_COMMISIONS = `${API_BASE}/Commission`
export const API_COMMISIONS_SELLER = `${API_BASE}/SellerCommissions`
export const API_PREVIOUS_PRODUCT = `${API_BASE}/PreviousVersionProduct`
export const API_USERS = `${API_BASE}/Users`
export const API_LOCATIONS = `${API_BASE}/Locations`
export const REGISTER_SELLER = `${API_URL_BASE}/registerStore`
export const CATEGORIES_ALL = `${API_BASE}/Categories/All`
export const SETTING_CONFIGURATION = `${API_BASE}/SettingsConfiguration`
export const VOUCHER = `${API_BASE}/Voucher`
export const FEATURES_FLAGS = `${API_BASE}/SettingsConfiguration/GetFeaturesFlags`
export const QUOTE_SHIPPING = `${API_PRODUCTS}/QuoteShipping`
export const API_INTEGRATIONS = `${API_BASE}/Integration`
export const EXPORT_ORDERS_SELLER = `${API_ORDERS}/ExportOrdersBySeller`
export const PHONE_NUMBER_WAREHOUSE = `${API_STORE_INFO}/PhoneNumberWarehouse`
export const { CONCILIATOR_URL } = routeBase[process.env.REACT_APP_ENVIRONMENT]
export const { CONCILIATOR_USER } = routeBase[process.env.REACT_APP_ENVIRONMENT]
export const { CONCILIATOR_PASSWORD } = routeBase[process.env.REACT_APP_ENVIRONMENT]
// ROUTE FOR MAIL UPLOAD FILES
export const UPLOAD_FILE_MARKETING =
  routeBase[process.env.REACT_APP_ENVIRONMENT].marketingCampaign ||
  routeBase.default.marketingCampaign
export const USER_MARKETING =
  routeBase[process.env.REACT_APP_ENVIRONMENT].MARKETING_CAMPAING_USER ||
  routeBase.default.MARKETING_CAMPAING_USER
export const PASSWORD_MARKETING =
  routeBase[process.env.REACT_APP_ENVIRONMENT].MARKETING_CAMPAING_PASSWORD ||
  routeBase.default.MARKETING_CAMPAING_PASSWORD
// ROUTES PARA SHOPIFY
export const SHOPIFY_ALL = `${API_URL_BASE_SHOPIFY}/api/shopify`
export const SHOPIFY_START_AUTHORIZE = `${API_URL_BASE_SHOPIFY}/api/shopify/auth`
export const SHOPIFY_API_EXCHANGE_ACCESS_TOKEN = `${API_URL_BASE_SHOPIFY}/api/shopify/exchangeAccessToken`
export const SHOPIFY_AUTH_CALLBACK = `${FRONT}/login?ecommerce=shopify`
export const ECOMMERCE_SHOPIFY = `${API_URL_BASE_SHOPIFY}/api/me/ecommerce`

// Coupons Routes
export const COUPONS_BASE =
  routeBase[process.env.REACT_APP_ENVIRONMENT].coupons || routeBase.default.coupons
export const API_COUPON = `${COUPONS_BASE}/coupon`
export const API_RULE = `${COUPONS_BASE}/rule`
export const API_SUPPLIER = `${COUPONS_BASE}/Supplier`
export const API_MULTIPLE_COUPONS = `${COUPONS_BASE}/MultipleCoupons`
// ROUTES PAYMENTS
export const PAYMENTS_API_BASE = `${API_URL_BASE_PAYMENTS}/api/`
export const PAYMENTS_API_CREATE_TOKEN = `${PAYMENTS_API_BASE}Auth/CreateToken`
export const PAYMENTS_API_GET_METHODS_PLATFORM = `${PAYMENTS_API_BASE}Parameters/serviceParameters`
export const PAYMENTS_API_CREATE_NEW_PLATFORM = `${PAYMENTS_API_BASE}Parameters/create`
export const PAYMENTS_API_UPDATE_METHODS_PLATFORM = `${PAYMENTS_API_BASE}Parameters/update`

// ROUTES TRAVEL
export const TRAVEL_API_BASE = `${API_URL_BASE_TRAVEL}/api/`
export const TRAVEL_API_CREATE_TOKEN = `${TRAVEL_API_BASE}travel/auth`
export const TRAVEL_API_GET_ITINERARIES = `${TRAVEL_API_BASE}travel/itineraryPendingReview/`
export const TRAVEL_API_CHANGE_STATUS_ITINERARY = `${TRAVEL_API_BASE}travel/itineraryChangeStatus/`
export const TRAVEL_API_USER = routeBase[process.env.REACT_APP_ENVIRONMENT].TRAVEL.USER
export const TRAVEL_API_PASSWORD = routeBase[process.env.REACT_APP_ENVIRONMENT].TRAVEL.PASSWORD

// Microservicio de productos
export const MICROSERVICES_PRODUCTS = `${API_URL_BASE_MICROSERVICES_PRODUCTS}`
export const API_PRODUCTS_TEMPLATE = `${MICROSERVICES_PRODUCTS}/downloadTemplate`
export const API_UPLOAD_PRODUCTS = `${MICROSERVICES_PRODUCTS}/uploadByFile`
export const API_UPDATE_MASSIVE_PRODUCTS = `${MICROSERVICES_PRODUCTS}/updateMassive`
export const API_DELETE_MASSIVE_PRODUCTS = `${MICROSERVICES_PRODUCTS}/deleteMassive`
export const API_PRODUCTS_UPDATE_BY_FILE = `${MICROSERVICES_PRODUCTS}/updateByFile`

// Microservicio de Ordenes

export const MICROSERVICES_ORDERS = `${API_URL_BASE_MICROSERVICES_ORDERS}`
export const API_PENDING_CONTENT_DIGITAL = `${MICROSERVICES_ORDERS}/OrderPendingContentDigital`
export const API_ORDER_PENDING_REVIEW = `${MICROSERVICES_ORDERS}/OrderPendingReview`

// images processor

export const IMEGES_PROCESSOR = `${API_URL_BASE_MICROSERVICES_IMEGES_PROCESSOR}`
export const IMEGES_PROCESSOR_API_KEY = `${API_URL_BASE_MICROSERVICES_IMEGES_PROCESSOR_API_KEY}`

// Categorizador
export const API_CATEGORIZER = `${routeBase[process.env.REACT_APP_ENVIRONMENT].categorizer}`

export const API_SOAT = `${routeBase[process.env.REACT_APP_ENVIRONMENT].soat}`
export const API_PAYMENTS = `${routeBase[process.env.REACT_APP_ENVIRONMENT].payments}`

export const CLUDFLARE_IP_URL = `${routeBase[process.env.REACT_APP_ENVIRONMENT].cloudflareIp}`
