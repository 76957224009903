import React, { useEffect, useState } from 'react'

import {
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Radio,
  RadioGroup,
  TextareaAutosize,
  Typography,
} from '@material-ui/core'
import axios from 'axios'

import useStyles from 'components/ModalConfirmActionOrder/ModalConfirmActionOrder.styles'
import { API_ORDERS } from 'constants/urls'

const ModalCancelationReason = ({ cancelationInfo, setCancelationInfo }) => {
  const classes = useStyles()
  const { seller, cancelOption, reason } = cancelationInfo
  const [reasonsList, setReasonsList] = useState([])

  const getCancelationOptionList = async () => {
    await axios
      .get(`${API_ORDERS}/GetReasonOrderCancellation`)
      .then((res) => {
        if (res.status === 200) {
          localStorage.setItem('cancelationOptionList', JSON.stringify(res.data.data))
          setReasonsList(res.data.data)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const handleSelectCancelOption = (e) => {
    setCancelationInfo({
      ...cancelationInfo,
      cancelOption: Number(e.target.value),
    })
  }

  useEffect(() => {
    if (localStorage.getItem('cancelationOptionList')) {
      setReasonsList(JSON.parse(localStorage.getItem('cancelationOptionList')))
    } else {
      getCancelationOptionList()
    }
  }, [seller])

  return (
    <div>
      <DialogTitle>¡Advertencia!</DialogTitle>
      <DialogContent style={{ overflowX: 'hidden' }}>
        <Typography variant="body1">Escriba el motivo de cancelacion de las ordenes</Typography>
        <Grid className={classes.divCancelOptions}>
          <Typography align="left" variant="subtitle2">
            Motivo de cancelación:
          </Typography>
          <FormControl component="fieldset">
            <RadioGroup
              name="optionCancel"
              value={cancelOption || 34}
              onChange={handleSelectCancelOption}
            >
              {reasonsList.map(({ id, name }) => {
                return (
                  <FormControlLabel id={id} key={id} value={id} control={<Radio />} label={name} />
                )
              })}
            </RadioGroup>
          </FormControl>
        </Grid>
        {cancelOption === 37 && (
          <>
            <TextareaAutosize
              maxLength={250}
              minRows={3}
              value={reason}
              className={classes.reason}
              placeholder="Escriba el motivo de cancelación"
              onChange={(e) => {
                setCancelationInfo({ ...cancelationInfo, reason: e.target.value })
              }}
            />
            <FormHelperText style={{ color: reason.length < 250 ? 'black' : 'red' }}>
              {reason.length}
              /250
            </FormHelperText>
          </>
        )}
      </DialogContent>
    </div>
  )
}

export default ModalCancelationReason
