/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'

import {
  Avatar,
  Box,
  CircularProgress,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  TextareaAutosize,
  TextField,
  Typography,
} from '@material-ui/core'
import { red } from '@material-ui/core/colors'
import { makeStyles } from '@material-ui/core/styles'
import { Delete } from '@material-ui/icons'
import CheckIcon from '@material-ui/icons/Check'
import PriorityHighIcon from '@material-ui/icons/PriorityHigh'
import axios from 'axios'
import { uniqueId } from 'lodash'
import { red500, green500 } from 'material-ui/styles/colors'
import MUIDataTable from 'mui-datatables'
import { useForm } from 'react-hook-form'

import BackDrop from 'components/BackDrop'
import Button from 'components/CustomButtons/Button'
import DialogModal from 'components/DialogModal'
import { PencilIcon } from 'components/icons/personalizedIcons'
import InputField from 'components/InputField'
import RelatedLinkForm, { actions } from 'components/RelatedLinkForm'
import useCustomDialogAlert from 'components/useCustomDialogAlert'
import { API_CATEGORIES } from 'constants/urls'
import { textLabels } from 'utils/functions'

const useStyles = makeStyles({
  iconButton: {
    transition: 'all 0.2s',
    '&:hover': {
      backgroundColor: red[100],
      color: red[500],
    },
  },
  box: {
    display: 'flex',
    flexDirection: 'column',
    padding: '10px 20px',
    gap: '8px',
    background: 'rgba(0, 0, 0, 0)',
    position: 'relative',
    borderRadius: '5px',
    boxSizing: 'border-box',
    boxShadow: '-6px 6px #E8E8E8',
  },
  outlinedButton: {
    transition: 'all 0.2s',
    border: '1px solid #6064FF',
    borderRadius: '1000px',
    color: '#6064FF',
    padding: '8px 40px',
    '&:hover': {
      borderColor: '#FF40FC',
      color: '#FF40FC',
    },
  },
  filledButton: {
    transition: 'all 0.2s',
    backgroundColor: '#6064FF',
    color: 'white',
    padding: '8px 40px',
    borderRadius: '1000px',
    '&:hover': {
      backgroundColor: '#FF40FC',
    },
  },
  textButton: {
    transition: 'all 0.2s',
    color: '#6064FF',
    padding: '8px 40px',
    borderRadius: '1000px',
    '&:hover': {
      color: '#FF40FC',
    },
  },
  helperText: {
    fontFamily: 'Red Hat Display Regular',
    textAlign: 'right',
    fontSize: '14px',
    color: green500,
  },
  helperTextError: {
    fontFamily: 'Red Hat Display Regular',
    textAlign: 'right',
    fontSize: '14px',
    color: red500,
  },
  textTruncate: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginRight: '60px',
  },
})

const CategoryMetadata = ({ categoryData, onClose }) => {
  const [loading, setLoading] = useState(false)
  const showDialogAlert = useCustomDialogAlert()
  const classes = useStyles()
  const metadataForm = useForm({
    defaultValues: { ...categoryData },
    mode: 'onChange',
  })
  const [bodyText, setBodyText] = useState(categoryData.bodyText ?? '')
  const [description, setDescription] = useState(categoryData.description ?? '')
  const faqForm = useForm({
    defaultValues: {
      question: '',
    },
  })
  const [faqList, setFaqList] = useState(
    categoryData.categoryQuestions.map((item, index) => ({
      ...item,
      uniqueId: `${index}-${new Date().toISOString()}`,
    }))
  )
  const [response, setResponse] = useState('')
  const [isEditing, setIsEditing] = useState(false)

  const [relatedLinkList, setRelatedLinkList] = useState(
    categoryData.relatedLinks.map((item, index) => ({
      ...item,
      uniqueId: `${index}-${new Date().toISOString()}`,
      name: item.link.split(';')[1],
      url: item.link.split(';')[0],
    }))
  )
  const [configRelatedLinks, setConfigRelatedLinks] = useState({
    isOpen: false,
    linkInfo: {},
  })

  const onSubmitFAQ = (data) => {
    const id = data.id.length > 0 ? Number(data.id) : 0
    const existingItemIndex = faqList.findIndex((item) => item.uniqueId === data.uniqueId)
    if (existingItemIndex !== -1 && isEditing) {
      const updatedItem = {
        ...faqList[existingItemIndex],
        ...data,
        response,
        id,
      }

      const updatedFaqList = [...faqList]
      updatedFaqList[existingItemIndex] = updatedItem

      setFaqList(updatedFaqList)
      setIsEditing(false)
    } else {
      const faqData = {
        ...data,
        id,
        uniqueId: `${faqList.length}-${new Date().toISOString()}`,
        response,
      }
      setFaqList((prev) => [...prev, faqData])
    }
    faqForm.reset()
    setResponse('')
  }

  const handleEditQuestion = (question) => {
    faqForm.setValue('id', question.id)
    faqForm.setValue('uniqueId', question.uniqueId)
    faqForm.setValue('question', question.question)
    setResponse(question.response)
    setIsEditing(true)
  }

  const handleRemoveQuestion = (index) => {
    const updatedFAQList = [...faqList]
    updatedFAQList.splice(index, 1)
    setFaqList(updatedFAQList)
  }

  const onSaveCategoryMetadata = async () => {
    setLoading(true)
    const relatedLinksFormatted = relatedLinkList.map((item) => {
      if (item.action) {
        return {
          id: item.id,
          link: `${item.url};${item.name}`,
          action: item.action,
        }
      }
      return {
        id: item.id,
        link: `${item.url};${item.name}`,
      }
    })
    const updatedData = {
      ...metadataForm.getValues(),
      categoryId: categoryData.id,
      bodyText,
      description,
      questions: faqList.map(({ id, question, response: itemResponse }) => ({
        id,
        question,
        response: itemResponse,
      })),
      relatedLinks: relatedLinksFormatted,
    }
    const res = await axios.put(
      `${API_CATEGORIES}/${categoryData.id}?applyToChildren=false`,
      updatedData
    )
    if (res.data) {
      onClose()
      showDialogAlert(
        true,
        '¡Cambio exitoso!',
        `La categoría se ha actualizado correctamente`,
        'Aceptar',
        '',
        true
      )
    } else {
      showDialogAlert(
        true,
        '¡Advertencia!',
        `Ocurrió un error, intentelo nuevamente mas tarde`,
        'Cerrar'
      )
    }
    setLoading(false)
  }

  const validateMetadataForm = () => {
    return (
      description.length < 120 ||
      description.length > 160 ||
      metadataForm.watch('title').length > 75 ||
      metadataForm.watch('title').length < 45 ||
      bodyText.length < 150
    )
  }
  const validateQuestion = () => faqForm.watch('question').length <= 0 || response.length <= 0

  useEffect(() => {
    Object.entries(categoryData).forEach(([key, value]) => {
      metadataForm.setValue(key, value)
    })
  }, [categoryData])

  return (
    <Grid container xs={12} md={12} spacing={2}>
      <BackDrop open={loading} />
      <Grid item xs={12} md={6}>
        <form className={classes.box} style={{ gap: '18px' }}>
          <Typography
            style={{
              fontSize: '20px',
              marginBottom: '10px',
            }}
          >
            Metadatos de &quot;
            {categoryData.name}
            &quot;
          </Typography>
          <input type="number" name="id" ref={metadataForm.register()} hidden />
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <label>Título: </label>
              <CircularProgressWithLabel
                value={metadataForm.watch('title').length}
                min={45}
                max={75}
              />
            </div>
            <TextField
              variant="outlined"
              name="title"
              placeholder="Meta Título de la categoría"
              type="text"
              style={{
                margin: 0,
              }}
              error={metadataForm.errors?.title && metadataForm.errors.title.message}
              inputRef={metadataForm.register({
                required: true,
                min: 45,
                max: 75,
              })}
            />
            {metadataForm.watch('title') && (
              <span
                className={
                  metadataForm.watch('title').length > 75 || metadataForm.watch('title').length < 45
                    ? classes.helperTextError
                    : classes.helperText
                }
              >
                {metadataForm.watch('title').length > 75
                  ? 'Título muy extenso, debe contener máximo 75 caracteres.'
                  : metadataForm.watch('title').length < 45 &&
                    'Título muy corto, debe contener mínimo 45 caracteres.'}
              </span>
            )}
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <label>Descripción: </label>
              <CircularProgressWithLabel value={description.length} min={120} max={160} />
            </div>
            <TextareaAutosize
              minRows={4}
              value={description}
              style={{
                maxHeight: 100,
                overflow: 'auto !important',
                width: '100%',
                padding: '15px',
                fontFamily: 'Red Hat Display Regular',
                border: '2px solid #7A7A7A',
                borderRadius: '10px',
                borderColor: 'rgba(0, 0, 0, 0.23)',
                resize: 'none',
              }}
              placeholder="Meta Descripción de la categoría"
              onChange={(e) => {
                setDescription(e.target.value)
              }}
            />
            {description && (
              <span
                className={
                  description.length < 120 || description.length > 160
                    ? classes.helperTextError
                    : classes.helperText
                }
              >
                {description.length > 160
                  ? 'Descripción muy extensa, debe contener máximo 160 caracteres.'
                  : description.length < 120 &&
                    'Descripción muy corta, debe contener minimo 120 caracteres.'}
              </span>
            )}
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <label>Texto principal: </label>
              <CircularProgressWithLabel value={bodyText.length} min={150} max={10000} />
            </div>
            <TextareaAutosize
              minRows={4}
              value={bodyText}
              style={{
                maxHeight: 100,
                overflow: 'auto !important',
                width: '100%',
                padding: '15px',
                fontFamily: 'Red Hat Display Regular',
                border: '2px solid #7A7A7A',
                borderRadius: '10px',
                borderColor: 'rgba(0, 0, 0, 0.23)',
                resize: 'none',
              }}
              placeholder="Texto principal de la categoría"
              onChange={(e) => {
                setBodyText(e.target.value)
              }}
            />
            {bodyText && (
              <span
                className={bodyText.length < 150 ? classes.helperTextError : classes.helperText}
              >
                {bodyText.length < 150 && 'Texto muy corto, debe contener mínimo 150 caracteres.'}
              </span>
            )}
          </div>
        </form>
      </Grid>
      <Grid item xs={12} md={6}>
        <form onSubmit={faqForm.handleSubmit(onSubmitFAQ)} className={classes.box}>
          <Typography
            style={{
              fontSize: '20px',
              marginBottom: '10px',
            }}
          >
            Añadir preguntas y respuestas
          </Typography>

          <input type="number" name="id" ref={faqForm.register()} hidden />
          <input type="text" name="uniqueId" ref={faqForm.register()} hidden />
          <InputField
            name="question"
            label="Pregunta"
            type="text"
            placeholder="¿Qué información es relevante para esta categoría?"
            ref={faqForm.register({
              required: true,
            })}
          />
          <div>
            <label>Respuesta:</label>
            <TextareaAutosize
              minRows={3}
              value={response}
              style={{
                minHeight: '193.5px',
                maxHeight: '193.5px',
                overflow: 'auto !important',
                width: '100%',
                padding: '15px',
                fontFamily: 'Red Hat Display Regular',
                border: '2px solid #7A7A7A',
                borderRadius: '10px',
                borderColor: 'rgba(0, 0, 0, 0.23)',
                resize: 'none',
              }}
              placeholder="Usa la mayor cantidad de palabras claves posible para aumentar la visibilidad de esta categoría"
              onChange={(e) => {
                setResponse(e.target.value)
              }}
            />
          </div>
          <Button
            type="submit"
            className={classes.outlinedButton}
            style={{
              alignSelf: 'end',
            }}
            disabled={validateQuestion()}
          >
            {isEditing ? 'Modificar' : 'Agregar'}
          </Button>
        </form>
      </Grid>
      {faqList.length > 0 && (
        <Grid item xs={12}>
          <div className={classes.box}>
            <Typography
              style={{
                fontSize: '20px',
                marginBottom: '10px',
              }}
            >
              Preguntas
            </Typography>
            <List dense>
              {faqList.map((item, index) => (
                <ListItem key={item.uniqueId}>
                  <ListItemAvatar>
                    <Avatar style={{ backgroundColor: '#6064FF' }}>{index + 1}</Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={item.question}
                    secondary={item.response}
                    secondaryTypographyProps={{ className: classes.textTruncate }}
                  />
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      style={{
                        marginRight: '8px',
                      }}
                      onClick={() => {
                        handleEditQuestion(item)
                      }}
                    >
                      <PencilIcon />
                    </IconButton>
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() => {
                        handleRemoveQuestion(index)
                      }}
                    >
                      <Delete />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
          </div>
        </Grid>
      )}
      <Grid item xs={12}>
        <div className={classes.box}>
          <Grid container direction="row" justifyContent="space-between">
            <Grid item>
              <Typography
                style={{
                  fontSize: '20px',
                  marginBottom: '10px',
                }}
              >
                Links relacionados
              </Typography>
            </Grid>
            <Grid item>
              <Button
                type="button"
                onClick={() => {
                  setConfigRelatedLinks({
                    isOpen: true,
                    linkInfo: {},
                  })
                }}
                className={classes.outlinedButton}
              >
                Agregar
              </Button>
            </Grid>
          </Grid>
          <MUIDataTable
            data={relatedLinkList.filter((item) => item.action !== actions.DELETE)}
            columns={[
              {
                name: 'id',
                label: 'ID',
                options: {
                  display: false,
                },
              },
              {
                name: 'uniqueId',
                label: 'uniqueId',
                options: {
                  display: false,
                },
              },
              {
                name: 'name',
                label: 'Nombre',
              },
              {
                name: 'url',
                label: 'URL',
              },
              {
                name: 'actions',
                label: 'Acciones',
                options: {
                  customBodyRender: (value, tableMeta) => {
                    return (
                      <Grid container spacing={3} direction="row" alignItems="center">
                        <Grid item>
                          <IconButton
                            edge="end"
                            aria-label="edit"
                            onClick={() => {
                              const linkInfo = relatedLinkList.find(
                                (item) => item.uniqueId === tableMeta.rowData[1]
                              )
                              setConfigRelatedLinks({
                                isOpen: true,
                                linkInfo,
                              })
                            }}
                          >
                            <PencilIcon />
                          </IconButton>
                        </Grid>
                        <Grid item>
                          <IconButton
                            edge="end"
                            aria-label="delete"
                            onClick={() => {
                              const linkInfo = relatedLinkList.find(
                                (item) => item.uniqueId === tableMeta.rowData[1]
                              )
                              setRelatedLinkList((prev) =>
                                prev.map((item) =>
                                  item.uniqueId === linkInfo.uniqueId
                                    ? { ...item, action: actions.DELETE }
                                    : item
                                )
                              )
                            }}
                          >
                            <Delete />
                          </IconButton>
                        </Grid>
                      </Grid>
                    )
                  },
                },
              },
            ]}
            options={{
              textLabels: textLabels(false),
              search: false,
              download: false,
              print: false,
              filter: false,
              viewColumns: false,
              selectableRows: 'none',
            }}
          />
        </div>
      </Grid>
      <DialogModal
        isOpen={configRelatedLinks.isOpen}
        handleClose={() => {
          setConfigRelatedLinks({
            isOpen: false,
            linkInfo: {},
          })
        }}
        title="Agregar link relacionado"
      >
        <RelatedLinkForm
          linkInfo={configRelatedLinks.linkInfo}
          onSubmit={(linkInfoSubmitted) => {
            if (!linkInfoSubmitted.uniqueId) {
              setRelatedLinkList((prev) => [
                ...prev,
                {
                  ...linkInfoSubmitted,
                  uniqueId: `${relatedLinkList.length}-${new Date().toISOString()}`,
                  action: actions.ADD,
                },
              ])
            } else {
              const updatedRelatedLinkList = [...relatedLinkList]
              const index = updatedRelatedLinkList.findIndex(
                (item) => item.uniqueId === linkInfoSubmitted.uniqueId
              )
              updatedRelatedLinkList[index] = linkInfoSubmitted
              setRelatedLinkList(updatedRelatedLinkList)
            }
            setConfigRelatedLinks({
              isOpen: false,
              linkInfo: {},
            })
          }}
        />
      </DialogModal>
      <Grid
        container
        spacing={3}
        alignItems="center"
        justifyContent="flex-end"
        style={{ marginTop: '20px' }}
      >
        <Grid items>
          <Button
            className={classes.textButton}
            style={{
              alignSelf: 'end',
            }}
            onClick={onClose}
          >
            Cancelar
          </Button>
        </Grid>
        <Grid item>
          <Button
            className={classes.filledButton}
            style={{
              alignSelf: 'end',
            }}
            disabled={validateMetadataForm()}
            onClick={onSaveCategoryMetadata}
          >
            Guardar Metadatos
          </Button>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default CategoryMetadata

function CircularProgressWithLabel({ value, min, max, ...rest }) {
  const percentage = value > min ? 100 : (value * 100) / min
  return (
    <div
      style={{
        display: 'flex',
        gap: '4px',
      }}
    >
      <Typography
        variant="caption"
        component="div"
        style={{
          marginTop: '1.5px',
          fontSize: '0.7rem',
          color: value >= min ? (value > max ? 'orange' : 'green') : '#6064FF',
        }}
      >
        {value}
      </Typography>
      <Box position="relative" display="inline-flex">
        <CircularProgress
          variant="determinate"
          size={20}
          style={{
            color: value >= min ? (value > max ? 'orange' : 'green') : '#6064FF',
          }}
          value={percentage}
          {...rest}
        />
        <Box
          top={0}
          left={0}
          bottom={0}
          right={0}
          position="absolute"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          {value >= min && value <= max ? (
            <CheckIcon
              style={{
                fontSize: '1rem',
                marginBottom: '3px',
                color: 'green',
              }}
            />
          ) : (
            <PriorityHighIcon
              style={{
                fontSize: '1rem',
                marginBottom: '3px',
                color: value >= min ? (value > max ? 'orange' : 'green') : '#6064FF',
              }}
            />
          )}
        </Box>
      </Box>
    </div>
  )
}
