import React from 'react'

import { Button, DialogActions, Grid, Typography } from '@material-ui/core'
import MUIDataTable from 'mui-datatables'

import useStyles from 'views/Products/products.styles'

const SaleOverviewInfo = ({ info, handleNext, handleBack, ruleConditions, ruleTypes, rules }) => {
  const classes = useStyles()
  return (
    <div>
      <p>
        ¡Estás a punto de
        {info?.id ? ' modificar ' : ' crear '}
        una promoción . Verifica los datos antes de continuar!
      </p>
      <br />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="subtitle1">Nombre de la promoción</Typography>
          <p>{info.saleName}</p>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="subtitle1">Codigo de la promoción</Typography>
          <p>{info.saleCode}</p>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="subtitle1">Valor de la promoción</Typography>
          <p>
            {info.discountType === 1 && '$ '}
            {info.saleValue}
            {info.discountType === 2 && ' %'}
          </p>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="subtitle1">Fecha inicial</Typography>
          <p>
            {new Date(info.initialDate).toLocaleDateString('es-ES', {
              year: 'numeric',
              month: 'long',
              day: 'numeric',
              hour: 'numeric',
              minute: 'numeric',
              hour12: true,
            })}
          </p>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="subtitle1">Fecha final</Typography>
          <p>
            {new Date(info.finalDate).toLocaleDateString('es-ES', {
              year: 'numeric',
              month: 'long',
              day: 'numeric',
              hour: 'numeric',
              minute: 'numeric',
              hour12: true,
            })}
          </p>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="subtitle1">¿Es acumulable con otras promociones?</Typography>
          <p>{info.isCumulative ? 'Si' : 'No'}</p>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle1">Reglas</Typography>
          <MUIDataTable
            options={{
              search: false,
              print: false,
              download: false,
              viewColumns: false,
              filter: false,
              selectableRows: 'none',
              pagination: false,
              tableBodyMaxHeight: '300px',
              responsive: 'vertical',
              textLabels: {
                body: {
                  noMatch: `No hay reglas para esta promoción`,
                },
              },
            }}
            columns={[
              {
                name: 'ruleTypeId',
                label: 'Tipo de regla',
                options: {
                  customBodyRender: (value) => ruleTypes.find((item) => item.id === value).name,
                },
              },
              {
                name: 'conditionId',
                label: 'Condición',
                options: {
                  customBodyRender: (value) =>
                    ruleConditions.find((item) => item.id === value).name,
                },
              },
              { name: 'value', label: 'Valor' },
            ]}
            data={rules}
          />
        </Grid>
      </Grid>
      <DialogActions>
        <Button onClick={() => handleBack(info)} className={classes.btnBackgroundErrorPriceSmall}>
          Atrás
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            handleNext(info)
          }}
          className={classes.btnNewProductsmall}
        >
          {info?.id ? 'Actualizar ' : 'Crear '}
        </Button>
      </DialogActions>
    </div>
  )
}

export default SaleOverviewInfo
