/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react'

import IconButton from '@material-ui/core/IconButton'
import InputBase from '@material-ui/core/InputBase'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'

import { SearchIcon } from 'components/icons/personalizedIcons'
import { SUPER_ADMIN, DROPSHIPPER } from 'constants/rolesConst'
import useAuthContext from 'contextApi/AuthContext'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 410,
    height: 48,
    border: '2px solid #7A7A7A',
    borderRadius: 8,
    marginTop: 15,
    boxShadow: 'none',
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    fontFamily: 'Red Hat Display Regular',
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}))

function SearchBox({
  initialSearchText = '',
  placeholder,
  handlerSearch,
  avalaibleSetTextValueEmpty,
  setAvalaibleSetTextValueEmpty,
  setFiltersApi,
}) {
  const { userLoggedin } = useContext(useAuthContext)
  const classes = useStyles()
  const roleUser = userLoggedin.role
  const [searchText, setSearchText] = useState()

  const search = () => {
    setAvalaibleSetTextValueEmpty(false)
    handlerSearch(searchText.trim())
  }
  useEffect(() => {
    if (avalaibleSetTextValueEmpty) {
      setSearchText('')
    }
  }, [searchText, avalaibleSetTextValueEmpty])

  useEffect(() => {
    if (initialSearchText) {
      setSearchText(initialSearchText)
    }
  }, [initialSearchText])

  return (
    <Paper className={classes.root}>
      <InputBase
        className={classes.input}
        placeholder={placeholder}
        onKeyUp={(e) => {
          e.preventDefault()
          if (e.key === 'Enter' && searchText !== '') {
            search()
          } else if (searchText === '' && roleUser === SUPER_ADMIN) {
            setFiltersApi(null, 11)
          } else if (searchText === '' && roleUser === DROPSHIPPER) {
            setFiltersApi(10, 0, false)
          }
        }}
        onChange={(e) => {
          setAvalaibleSetTextValueEmpty(false)
          setSearchText(e.target.value.trimStart())
        }}
        value={searchText}
      />
      <IconButton
        onClick={(e) => {
          e.preventDefault()
          search()
        }}
        className={classes.iconButton}
        aria-label="search"
      >
        <SearchIcon />
      </IconButton>
    </Paper>
  )
}

SearchBox.propTypes = {
  placeholder: PropTypes.string,
  handlerSearch: PropTypes.func.isRequired,
}

SearchBox.defaultProps = {
  placeholder: '¿Qué estás buscando?',
}

export default SearchBox
