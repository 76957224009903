/* eslint-disable react/prop-types */
import React, { useContext, useEffect, useState } from 'react'

import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  MenuItem,
  Select,
  Button,
  Typography,
  FormControlLabel,
  Switch,
  TextField,
  FormGroup,
  InputLabel,
} from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import axios from 'axios'
import MUIDataTable from 'mui-datatables'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'

import ConsolidateForm from './consolidateForm'
import useStyles from './modalProducts.styles'
import ModalTransportGuide from './modalTransportGuide'

import BackDrop from 'components/BackDrop'
import DialogAlert from 'components/DialogAlert'
import useCustomDialogAlert from 'components/useCustomDialogAlert'
import { API_STORE_INFO, API_ORDERS, PHONE_NUMBER_WAREHOUSE } from 'constants/urls'
import useAuthContext from 'contextApi/AuthContext'
import textLabels from 'utils/MUIDataTableTextLabels'
import { onKeyPressJustNumbers } from 'utils/utils'

const ModalProductsReadyToSend = ({ orders, onClose, getFilters, getModels }) => {
  const classes = useStyles()

  const [isLoadingBackDrop, setIsLoadingBackDrop] = useState(false)
  const [productsToGuide, setProductsToGuide] = useState([])
  const [allIndexRowsSelected, setAllIndexsRowsSelected] = useState([])
  const [isConsolidate, setIsConsolidate] = useState(false)
  const [pendingOrders, setPendingOrders] = useState([])
  const [openModalDeliveryDriver, setOpenModalDeliveryDriver] = useState(false)
  const [currentOrder, setCurrentOrder] = useState({
    id: parseInt(orders[0], 10),
    isComplete: false,
  })
  const [modelOrders, setModelOrders] = useState([])
  const [currentProducts, setCurrentProducts] = useState([])
  const [consolidateInfo, setConsolidateInfo] = useState([])
  const [originwarehouses, setOriginwarehouses] = useState(0)
  const [isOpenModalTransportGuide, setIsOpenModalTransportGuide] = useState(false)
  const [isOpenModalConsolidate, setIsOpenModalConsolidate] = useState(false)
  const [dialogAlertChangeOrigin, setDialogAlertChangeOrigin] = useState({
    open: false,
    title: '',
    message: '',
    actionConfirm: null,
    textButtonConfirm: '',
    textButtonCancel: '',
    idProduct: 0,
    newOrigin: 0,
  })
  const [isFastDelivery, setIsFastDelivery] = useState(false)
  const [warehouses, setWarehouses] = useState([])
  const [warehouseSelected, setWarehouseSelected] = useState({})
  const [phoneNumber, setPhoneNumber] = useState('')
  const showDialogAlert = useCustomDialogAlert()

  const { userLoggedin } = useContext(useAuthContext)
  const { register, handleSubmit, errors } = useForm({
    mode: 'onBlur',
  })

  const handleChangeModalFastDelivery = () => {
    setIsFastDelivery(true)
    setDialogAlertChangeOrigin({
      open: false,
    })
    setOpenModalDeliveryDriver(true)
  }
  const getProductsByOrderId = async (orderId) => {
    setIsLoadingBackDrop(true)
    await axios
      .post(`${API_ORDERS}/ProductsOrders`, {
        idList: [orderId],
      })
      .then((response) => {
        if (response.status === 200) {
          setIsLoadingBackDrop(false)
          setModelOrders(response.data)
          if (response.data.length > 0) {
            const { products } = response.data[0]
            if (products?.length > 0 && products[0]?.origin)
              setOriginwarehouses(products[0]?.origin || 0)
            if (products?.length > 0 && products[0]?.fastDelivery) {
              setDialogAlertChangeOrigin({
                open: true,
                title: 'Envío rápido',
                message: `Para tu orden puedes utilizar la opción de Envío Rápido, ¿Deseas activarla?`,
                actionConfirm: () => handleChangeModalFastDelivery(),
                textButtonConfirm: 'Sí, ¡De una!',
                textButtonCancel: 'No quiero',
              })
            }
          }
          const productsByOrderId = response.data.find(
            (item) => item.orderId === parseInt(orderId, 10)
          )
          if (productsByOrderId !== undefined) {
            setCurrentProducts(productsByOrderId.products.filter((item) => !item.isReadyToSend))
          }
        }
      })
      .catch(() => {
        setIsLoadingBackDrop(false)
      })
  }

  const getWarehousesBySellerId = async (sellerId) => {
    await axios
      .get(`${API_STORE_INFO}/Warehouse/${sellerId}`)
      .then((response) => {
        if (response.status === 200) {
          setWarehouses(response.data.data)
        }
      })
      .catch(() => {})
  }

  useEffect(() => {
    getWarehousesBySellerId(userLoggedin.sellerId)
    // Create array with orders and add state isComplete
    const ordersPending = orders.map((orderId) => ({
      id: parseInt(orderId, 10),
      isComplete: false,
    }))

    setPendingOrders(ordersPending)
    setCurrentOrder(ordersPending[0])
  }, [orders, userLoggedin.sellerId])

  useEffect(() => {
    getProductsByOrderId(currentOrder.id)
  }, [currentOrder])

  const onRowSelectionChange = (currentRowsSelected, allRowsSelected, rowsSelected) => {
    setAllIndexsRowsSelected(rowsSelected)
  }

  const openModalConsolidate = () => {
    setIsOpenModalConsolidate(!isOpenModalConsolidate)
  }
  const validationsSelections = (temp, indexRow) => {
    if (temp.length > 0) {
      const lastElement = temp[temp.length - 1]

      if (currentProducts[indexRow].transporterLuegopaGo === lastElement.transporterLuegopaGo) {
        if (parseInt(currentProducts[indexRow].origin, 10) === parseInt(lastElement.origin, 10)) {
          return true
        }
        setDialogAlertChangeOrigin({
          open: true,
          title: '¡Advertencia!',
          message: `usted está intentando seleccionar productos con orígenes diferentes, esta acción no esta permitida`,
          actionConfirm: null,
          textButtonConfirm: 'Aceptar',
          textButtonCancel: null,
        })

        return false
      }
      setDialogAlertChangeOrigin({
        open: true,
        title: '¡Advertencia!',
        message: `Usted está intentando seleccionar productos con transportadoras diferentes, esta acción no esta permitida`,
        actionConfirm: null,
        textButtonConfirm: 'Aceptar',
        textButtonCancel: null,
      })

      return false
    }
    return true
  }
  const getWarehouseObject = (id) => {
    const warehouseFinded = warehouses.find((warehouse) => {
      return warehouse.id === parseInt(id, 10)
    })
    setWarehouseSelected(warehouseFinded)
  }
  const openConsolidate = () => {
    const temp = []
    let isCorrect = false
    let count = 0
    modelOrders.map((index) => {
      if (index.orderId === currentOrder.id) {
        currentProducts.map(() => {
          if (count < allIndexRowsSelected.length) {
            const indexRow = allIndexRowsSelected[count]
            if (currentProducts[indexRow].transporterLuegopaGo) {
              if (validationsSelections(temp, indexRow)) {
                count += 1
                isCorrect = true
                return temp.push(currentProducts[indexRow])
              }
              isCorrect = false
              return false
            }
            setDialogAlertChangeOrigin({
              open: true,
              title: '¡Advertencia!',
              message: `Solo se puede consolidar productos con transportadora a cargo de LuegopaGO`,
              actionConfirm: null,
              textButtonConfirm: 'Aceptar',
              textButtonCancel: null,
            })

            isCorrect = false
            return false
          }
          return true
        })
      }
      return true
    })

    if (temp.length > 0 && isCorrect) {
      getWarehouseObject(temp[0].origin)
      setIsConsolidate(true)
      setConsolidateInfo(temp)
      setDialogAlertChangeOrigin({
        open: true,
        title: '¡Advertencia!',
        message: `Recuerde que puede consolidar Sólo los productos que tengan el mismo origen y el despacho sera en una misma unidad de empaque.`,
        actionConfirm: () => openModalConsolidate(),
        textButtonConfirm: 'Aceptar',
        textButtonCancel: 'Cancelar',
      })
    }
  }

  const customToolbarSelect = () => (
    <>
      {allIndexRowsSelected.length >= 2 ? (
        <div className=" tooltip">
          <div className="blob">
            <span className="tooltiptext">Consolida tus pedidos</span>
          </div>
          <Button
            onClick={() => {
              openConsolidate()
            }}
            variant="contained"
            color="secondary"
            className={classes.buttonshoverGreen}
          >
            Consolidar
          </Button>
        </div>
      ) : null}
    </>
  )

  const handleChangeOrigin = (newIdProduct, newOrigin) => {
    setDialogAlertChangeOrigin({
      open: false,
    })
    const temp = currentProducts
    temp.map((product) => {
      if (product.id === newIdProduct) {
        // eslint-disable-next-line no-param-reassign
        product.origin = newOrigin.value
        showDialogAlert(
          true,
          '¡Cambio exitoso!',
          `Su información ha sido guardada de manera exitosa`,
          'Cerrar',
          '',
          true
        )
      }
      return product
    })

    setCurrentProducts(temp)
  }

  const showDialogChangeOrigin = (idProduct, nameProduct, newOrigin) => {
    setDialogAlertChangeOrigin({
      open: true,
      title: '¡Advertencia!',
      message: `¿Está seguro que desea cambiar el origen del producto ${nameProduct} a ${newOrigin.children}? Esta acción podría generar cambios en el costo del envío.`,
      actionConfirm: () => handleChangeOrigin(idProduct, newOrigin),
      textButtonConfirm: 'Aceptar',
      textButtonCancel: 'Cancelar',
      idProduct,
      newOrigin,
    })
  }

  const columns = [
    { name: 'id', label: 'id', options: { display: false } },
    { name: 'name', label: 'Nombre' },
    { name: 'sku', label: 'SKU' },
    { name: 'quantity', label: 'Cantidad' },
    {
      name: 'origin',
      label: 'Origen',
      options: {
        customBodyRender: (value, tableMeta) => {
          return tableMeta.rowData[5] ? (
            <Select
              name="origin"
              value={value}
              onChange={(e, newValue) =>
                showDialogChangeOrigin(tableMeta.rowData[0], tableMeta.rowData[1], newValue.props)
              }
            >
              {warehouses.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.title}
                </MenuItem>
              ))}
            </Select>
          ) : (
            warehouses.map((option) => {
              if (option.id === parseInt(tableMeta.rowData[4], 10)) {
                return option.title
              }
              return ''
            })
          )
        },
      },
    },
    {
      name: 'transporterLuegopaGo',
      label: 'Transportadora Luegopago',
      options: {
        customBodyRender: (value, tableMeta) => {
          return tableMeta.rowData[5] ? 'Si' : 'No'
        },
      },
    },
    {
      name: 'fastDelivery',
      label: 'Entrega rápida',
      options: {
        customBodyRender: (value, tableMeta) => {
          return tableMeta.rowData[6] ? (
            <FormControlLabel
              className={classes.positionElements}
              control={
                <Switch
                  className={classes.colorswitch}
                  checked={isFastDelivery || false}
                  onChange={(event) => {
                    if (event.target.checked) setOpenModalDeliveryDriver(true)
                    setIsFastDelivery(!isFastDelivery)
                  }}
                  name="fastDelivery"
                  color="primary"
                  size="small"
                />
              }
              label={<Typography>¿Enviar el producto por entrega rápida?</Typography>}
            />
          ) : (
            <Typography>No aplica</Typography>
          )
        },
      },
    },
  ]

  const options = {
    print: false,
    download: false,
    sort: false,
    viewColumns: false,
    filter: false,
    pagination: true,
    rowsPerPageOptions: [10],
    search: false,
    responsive: 'scrollMaxHeight',
    textLabels,
    tableBodyMaxHeight: '100%',
    onRowSelectionChange,
    customToolbarSelect,
    rowsSelected: allIndexRowsSelected,
    setCellHeaderProps: () => ({
      style: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'row-reverse',
        borderBottom: 'none',
      },
    }),
    onTableChange: (action) => {
      switch (action) {
        case 'changePage':
          document.querySelector('#main').scrollTop = 0
          break
        case 'changeRowsPerPage':
          break
        default:
          break
      }
    },
  }

  const openModalTransportGuide = () => {
    if (allIndexRowsSelected.length === 0) {
      setDialogAlertChangeOrigin({
        open: true,
        title: '¡Advertencia!',
        message: `Debe seleccionar al menos un producto`,
        textButtonConfirm: 'Aceptar',
        textButtonCancel: null,
        actionConfirm: null,
      })
      return false
    }
    // Get info with the indexs selecteds
    const temp = []
    let isCorrect = false
    let enableToOpen = false
    let count = 0
    modelOrders.map((index) => {
      if (index.orderId === currentOrder.id) {
        currentProducts.map(() => {
          if (count < allIndexRowsSelected.length) {
            const indexRow = allIndexRowsSelected[count]
            if (validationsSelections(temp, indexRow)) {
              if (currentProducts[indexRow].transporterLuegopaGo) enableToOpen = true
              count += 1
              isCorrect = true
              return temp.push(currentProducts[indexRow])
            }
            isCorrect = false
            return false
          }
          return true
        })
      }
      return true
    })
    if (temp.length > 0 && isCorrect) {
      if (!enableToOpen) {
        setProductsToGuide(temp)
        return setIsOpenModalTransportGuide(true)
      }
      getWarehouseObject(temp[0].origin)
      setConsolidateInfo(temp)
      setIsConsolidate(false)
      openModalConsolidate()
    }
  }

  const handleCloseDialogChangeOrigin = () => {
    setDialogAlertChangeOrigin({ ...dialogAlertChangeOrigin, open: false })
  }

  const closeModalTransportGuide = () => {
    setIsOpenModalTransportGuide(false)
  }
  const changeProductsAsReadyToSend = (products) => {
    let isAllProductsReadyToSend = true
    // Change isReadyToSend to true of products
    const tempProducts = currentProducts.map((item) => {
      const tempItem = item
      if (products.some((e) => e.orderDetailId === tempItem.orderDetailId))
        tempItem.isReadyToSend = true
      // Valide if all products of current order are ready to sent
      if (tempItem.isReadyToSend === false) isAllProductsReadyToSend = false
      return tempItem
    })
    setCurrentProducts(tempProducts.filter((item) => !item.isReadyToSend))
    setAllIndexsRowsSelected([])
    // if all products of current order are ready to sent changer the current order (the useEfect do they work)
    if (!isAllProductsReadyToSend) {
      getFilters()
      return false
    }
    const tempOrders = pendingOrders.map((item) => {
      const tempItem = item
      if (tempItem.id === currentOrder.id) tempItem.isComplete = true
      return tempItem
    })
    setPendingOrders(tempOrders)
    const newCurrentOrder = pendingOrders.find((item) => !item.isComplete)
    if (newCurrentOrder !== undefined) {
      getFilters()
      setCurrentOrder(newCurrentOrder)
    } else {
      getFilters()
      getModels()
      // if all orders are complete, close de modal and return to orders
      onClose()
    }

    return true
  }
  const onCloseDeliveryDriver = () => {
    setIsFastDelivery(false)
    setOpenModalDeliveryDriver(false)
    setPhoneNumber('')
  }

  const message = (
    <div className={classes.backdropSnackbar}>
      <p>
        - Podrás realizar cambios del origen por producto, si la transportadora está a cargo de
        luegopaGo, aunque esto pueda que genere cambios en los costos
      </p>
      <p>- Debe asegurarse que los productos que consolidará se encuentren en el mismo origen.</p>
    </div>
  )
  const onSubmit = async () => {
    if (phoneNumber !== '' && originwarehouses !== 0) {
      const infoWarehouse = {
        warehouseId: originwarehouses,
        phoneNumber,
      }
      setIsLoadingBackDrop(true)
      await axios
        .put(`${PHONE_NUMBER_WAREHOUSE}/${originwarehouses}`, infoWarehouse)
        .then((response) => {
          if (response.status === 200) {
            setIsLoadingBackDrop(false)
            setOpenModalDeliveryDriver(false)
            setDialogAlertChangeOrigin({
              open: true,
              title: '¡Número del repartidor!',
              message: `Número actualizado correctamente`,
              actionConfirm: null,
              textButtonConfirm: 'Aceptar',
              textButtonCancel: null,
            })
          }
        })
        .catch(() => {
          setIsLoadingBackDrop(false)
        })
    }
  }
  return (
    <>
      <BackDrop open={isLoadingBackDrop} />
      {isOpenModalTransportGuide && (
        <ModalTransportGuide
          currentOrder={currentOrder.id}
          setIsLoadingBackDrop={setIsLoadingBackDrop}
          isLoadingBackDrop={isLoadingBackDrop}
          productsToGuide={productsToGuide}
          changeProductsAsReadyToSend={changeProductsAsReadyToSend}
          onClose={closeModalTransportGuide}
          orders={orders}
        />
      )}
      {isOpenModalConsolidate && (
        <ConsolidateForm
          currentOrder={currentOrder.id}
          productsToGuide={consolidateInfo}
          setIsLoadingBackDrop={setIsLoadingBackDrop}
          onClose={openModalConsolidate}
          warehouseSelected={warehouseSelected}
          orders={orders}
          isConsolidate={isConsolidate}
          changeProductsAsReadyToSend={changeProductsAsReadyToSend}
          isFastDelivery={isFastDelivery}
        />
      )}

      <DialogAlert
        open={dialogAlertChangeOrigin.open}
        title={dialogAlertChangeOrigin.title}
        handleClose={handleCloseDialogChangeOrigin}
        message={dialogAlertChangeOrigin.message}
        textButtonConfirm={dialogAlertChangeOrigin.textButtonConfirm}
        actionConfirm={dialogAlertChangeOrigin.actionConfirm}
        textButtonCancel={dialogAlertChangeOrigin.textButtonCancel}
      />

      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        fullWidth
        maxWidth="xl"
        classes={{ paperWidthXl: classes.modalProducts }}
        onClose={onClose}
        open
      >
        <Alert classes={{ message: classes.fullWidth }} icon={false} color="default">
          {message}
        </Alert>
        <DialogTitle className={classes.title} disableTypography>
          <Typography variant="h6">
            Información de pedido: N.
            {currentOrder.id}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <div className={classes.parent}>
            <MUIDataTable data={currentProducts} columns={columns} options={options} />
          </div>

          <Grid container justify="center" className={classes.buttonsProduct}>
            <Button
              className={classes.buttonHoverRed}
              onClick={() => onClose()}
              variant="contained"
              color="secondary"
            >
              Cancelar
            </Button>
            <Button
              className={classes.buttonshoverGreen}
              variant="contained"
              color="secondary"
              onClick={() => openModalTransportGuide()}
            >
              Guía
            </Button>
          </Grid>
        </DialogContent>
      </Dialog>
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="xl"
        classes={{ paperWidthXl: classes.modalDeliveryDriver }}
        onClose={onCloseDeliveryDriver}
        open={openModalDeliveryDriver}
      >
        <form onSubmit={handleSubmit(onSubmit)} className={classes.content}>
          <Typography variant="h6" className={classes.typographyDeliveryDriver}>
            Información de contacto
          </Typography>
          <Typography variant="subtitle1" className={classes.typographyDeliveryDriver}>
            Déjanos un número para que el repartidor pueda contactarte por si alguna novedad
          </Typography>
          <FormGroup>
            <InputLabel className={classes.labelSize}>Contacto para el repartidor</InputLabel>
            <TextField
              id="phoneNumber"
              className={classes.fieldDeliveryDriver}
              name="phoneNumber"
              variant="outlined"
              onChange={(event) => {
                setPhoneNumber(event.target.value)
              }}
              onKeyPress={onKeyPressJustNumbers}
              value={phoneNumber}
              type="tel"
              error={errors?.phoneNumber && errors?.phoneNumber?.message}
              helperText={errors?.phoneNumber?.message}
              inputRef={register({
                required: 'El número es requerido',
                minLength: {
                  value: 4,
                  message: 'Debe de tener un mínimo de 4 digitos.',
                },
                maxLength: {
                  value: 10,
                  message: 'Debe de tener un máximo de 10 digitos.',
                },
              })}
            />
          </FormGroup>
          <Grid container justify="center" className={classes.buttonsProduct}>
            <Button
              className={classes.buttonHoverRed}
              onClick={() => onCloseDeliveryDriver()}
              variant="contained"
              color="secondary"
            >
              Cancelar
            </Button>
            <Button
              className={classes.buttonshoverGreen}
              variant="contained"
              type="submit"
              color="secondary"
            >
              Enviar
            </Button>
          </Grid>
        </form>
      </Dialog>
    </>
  )
}

ModalProductsReadyToSend.propTypes = {
  orders: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string])).isRequired,
  onClose: PropTypes.func.isRequired,
}

export default ModalProductsReadyToSend
