/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react'

import { TextField } from '@material-ui/core'

const style = {
  label: { color: 'red' },
  input: { borderColor: 'red', outline: 'none', width: '100%', margin: '0' },
  helperText: {
    color: 'red',
    fontSize: '12px',
    fontStyle: 'italic',
  },
}

const InputField = React.forwardRef(({ label, error, register, children, ...rest }, ref) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      {label && <label style={error && style.label}>{label}:</label>}
      {children || (
        <TextField
          variant="outlined"
          fullWidth
          style={error && style.input}
          inputRef={ref}
          {...rest}
        />
      )}
      <span style={style.helperText}>{error}</span>
    </div>
  )
})

export default InputField
