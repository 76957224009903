/* eslint-disable react/no-unused-prop-types */
import React, { useState } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import {
  Button,
  CircularProgress,
  Grid,
  TextField,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  InputLabel,
  FormGroup,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'

import styles from './modalActiveStore.styles'

import BackDrop from 'components/BackDrop'
import useCustomDialogAlert from 'components/useCustomDialogAlert'
import { justNumbersString } from 'utils/utils'

const schema = yup.object().shape({
  username: yup
    .string()
    .typeError('El campo solo permite letras y números')
    .required('Campo requerido')
    .min(3, 'Mínimo 3 caracteres')
    .max(5, 'Máximo 5 caracteres'),
})
const ModalActiveStore = ({ sellerToActive, activeStore, getSellers, onCancel }) => {
  const classes = styles()
  const showDialogAlert = useCustomDialogAlert()
  const [isLoading, setIsLoading] = useState(false)
  const [user, setUser] = useState({
    name: '',
  })

  const { register, errors } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
  })

  const handleBlur = (event) => {
    setUser({ name: event.target.value })
  }
  const onChange = (event) => {
    if (event.target.value.length <= 6) {
      setUser({ name: event.target.value })
    } else {
      return false
    }
  }
  const onSubmit = async (data) => {
    if (user.name === '') {
      showDialogAlert(true, '¡Advertencia!', `El campo es requerido`, 'Cerrar')
      return false
    }

    if (user.name.length >= 6) {
      showDialogAlert(true, '¡Advertencia!', `El campo no permite más de 5 caracteres`, 'Cerrar')
      return false
    }
    if (user.name.length < 3) {
      showDialogAlert(true, '¡Advertencia!', `El campo no permite mínimo 3 caracteres`, 'Cerrar')
      return false
    }
    activeStore(sellerToActive.id, 0, false, user.name)
  }

  return (
    <>
      <Dialog disableBackdropClick disableEscapeKeyDown fullWidth open>
        <DialogTitle disableTypography>
          <Typography variant="h6">Activar tienda</Typography>
        </DialogTitle>
        <DialogContent className={classes.variantContent}>
          <form autoComplete="off">
            <BackDrop open={isLoading} />
            <Grid container direction="column" justify="space-between">
              <FormGroup>
                <InputLabel error={!!errors.username} className={classes.labelSize}>
                  Nombre de usuario: *
                </InputLabel>
              </FormGroup>
              <TextField
                name="username"
                id="username"
                inputRef={register}
                variant="outlined"
                error={!!errors.username}
                helperText={errors?.username?.message}
                onChange={onChange}
                onBlur={handleBlur}
                onKeyPress={justNumbersString}
              />

              <Grid container justify="center" className={classes.divBottoms}>
                <Button
                  variant="contained"
                  color="secondary"
                  disabled={isLoading}
                  onClick={onCancel}
                  className={[
                    classes.addAtributtes,
                    classes.btnBackgroundPrimary,
                    classes.btnActivatedCancel,
                  ]}
                >
                  Cancelar
                </Button>
                {isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={onSubmit}
                  disabled={isLoading}
                  className={[classes.addAtributtes, classes.btnBackgroundPrimary]}
                >
                  Activar
                </Button>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </>
  )
}

ModalActiveStore.propTypes = {
  idSellerToActive: PropTypes.number.isRequired,
  onCancel: PropTypes.func.isRequired,
}

export default ModalActiveStore
