import React, { useContext, useState, useEffect } from 'react'

import { Box, Grid, Typography } from '@material-ui/core'
import Axios from 'axios'
import PropTypes from 'prop-types'

import useStyles from './generalConfig.styles'
import CertificatesStep from './storeProfileSteps/CertificatesStep'
import CommisionStep from './storeProfileSteps/CommisionStep'
import GeneralStep from './storeProfileSteps/GeneralStep'
import Warehouses from './storeProfileSteps/Warehouses'

import BackDrop from 'components/BackDrop'
import Tabs from 'components/Tabs'
import { SELLER } from 'constants/rolesConst'
import { API_STORE_INFO } from 'constants/urls'
import useAuthContext from 'contextApi/AuthContext'

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      style={{ width: '100%' }}
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.arrayOf(PropTypes.object).isRequired,
  value: PropTypes.string.isRequired,
  index: PropTypes.string.isRequired,
}

export default function Storeprofile({ showStoreCommission }) {
  const classes = useStyles()
  const { userLoggedin, setLoggedin } = useContext(useAuthContext)

  // states
  const [tabs, setTabs] = useState([
    { value: 0, name: 'Información general' },
    { value: 1, name: 'Información de despacho' },
    { value: 2, name: 'Comisiones' },
    { value: 3, name: 'Documentos legales' },
  ])
  const [value, setValue] = useState(0)

  const [data, setData] = useState({})
  const [isSellerAdmin, setIsSellerAdmin] = useState(true)
  const [isRegistered, setIsRegistered] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  // functions
  const changeTabs = (tabValue) => {
    setValue(tabValue)
  }

  const getModel = async () => {
    setIsLoading(true)
    await Axios.get(`${API_STORE_INFO}/${userLoggedin.sellerId}`)
      .then((response) => {
        setIsLoading(false)
        if (response.status === 200) {
          if (response.data.isNotRegisted) {
            setIsRegistered(false)
            setData({
              sellerId: userLoggedin.sellerId,
              storeName: userLoggedin.sellerName,
              storeEmail: userLoggedin.email,
              warehouses: [
                {
                  title: 'Bodega ',
                  contactName: '',
                  phoneNumber: '',
                  warehouseAddress: '',
                  postalCode: '',
                  cityId: '',
                  departament: '',
                  returnAddress: '',
                  ciyReturn: '',
                  departamentReturn: '',
                  countryId: 47,
                  accountAdministratorEmail: '',
                  operationalContactEmail: '',
                  operationalContactPhone: '',
                  contactSacEmail: '',
                  contactSacPhone: '',
                  contactFinancialEmail: '',
                  contactFinancialPhone: '',
                },
              ],
            })
          } else {
            setIsRegistered(true)
            setData(response.data)
            const dataUser = userLoggedin
            dataUser.picture = response.data.storeLogo
            setLoggedin(dataUser)
          }

          if (showStoreCommission && userLoggedin.role !== SELLER) {
            setValue(2)
          }
          if (!userLoggedin.isHadWarehouse) {
            setValue(1)
          }
        }
      })
      .catch(() => {
        setIsLoading(false)
      })
  }

  useEffect(() => {
    getModel()
    if (userLoggedin.role === SELLER) {
      setIsSellerAdmin(false)
      setTabs([
        { value: 0, name: 'Información general' },
        { value: 1, name: 'Información de despacho' },
        { value: 3, name: 'Documentos legales' },
      ])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={classes.root}>
      <BackDrop open={isLoading} />
      <Typography variant="h6">Configuración de la tienda</Typography>
      <Grid container justify="center">
        <Tabs value={value} tabs={tabs} onChange={changeTabs} />
        <TabPanel value={value} index={0}>
          <GeneralStep
            data={data}
            getModel={getModel}
            isRegistered={isRegistered}
            setIsLoading={setIsLoading}
            isSellerAdmin={isSellerAdmin}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Warehouses
            dataSeller={data}
            getModel={getModel}
            sellerId={userLoggedin.sellerId}
            setIsLoading={setIsLoading}
          />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <CommisionStep data={data} isRegistered={isRegistered} sellerId={userLoggedin.sellerId} />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <CertificatesStep
            data={data}
            setData={setData}
            getModel={getModel}
            isRegistered={isRegistered}
            setIsLoading={setIsLoading}
            sellerId={userLoggedin.sellerId}
            onChangetabs={changeTabs}
          />
        </TabPanel>
      </Grid>
    </div>
  )
}
