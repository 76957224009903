import React, { useState, useContext } from 'react'

import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

import CategoriesListBreadCrumb from '../Products/categoriesListBreadCrumb'
import Style from '../Products/categoriestreeView.style'

import BackDrop from 'components/BackDrop'
import { DROPSHIPPER, SELLER } from 'constants/rolesConst'
import useAuthContext from 'contextApi/AuthContext'

const USERS_TEMPLATE = [DROPSHIPPER, SELLER]
const ModalCategoriesImport = ({
  isOpenModalCategories,
  setIsOpenModalCategories,
  listCategoriesShopify,
  setListCategoriesShopify,
  rowcategoryshopify,
  setRowcategoryshopify,
  setDialogAlertVariation,
  getAttributes,
  allProductShopifySelectedNum,
  getAttributesShopify,
  categoriesSeller,
  setCategoriesSeller,
  setTreeCategories,
  idSeller,
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const { userLoggedin } = useContext(useAuthContext)
  const classes = Style()
  const onCancel = () => {
    setIsOpenModalCategories(false)
  }

  return (
    <>
      <BackDrop open={isLoading} />
      <Dialog
        open={isOpenModalCategories}
        disableBackdropClick
        fullWidth
        maxWidth="md"
        disableEscapeKeyDown
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle>
          <IconButton aria-label="close" className={classes.closeButton} onClick={onCancel}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <CategoriesListBreadCrumb
            isTemplate={USERS_TEMPLATE.includes(userLoggedin.role)}
            isAdd
            isEdit={false}
            setIsLoadingBackDrop={setIsLoading}
            onCancel={onCancel}
            listCategoriesShopify={listCategoriesShopify}
            setListCategoriesShopify={setListCategoriesShopify}
            rowcategoryshopify={rowcategoryshopify}
            setDialogAlertVariation={setDialogAlertVariation}
            getAttributes={getAttributes}
            allProductShopifySelectedNum={allProductShopifySelectedNum}
            getAttributesShopify={getAttributesShopify}
            setCategoriesSeller={setCategoriesSeller}
            setTreeCategories={setTreeCategories}
            idSeller={idSeller}
            setIsOpenModalCategories={setIsOpenModalCategories}
          />
        </DialogContent>
        <DialogActions />
      </Dialog>
    </>
  )
}

export default ModalCategoriesImport
