/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react'

import {
  FormControl,
  Grid,
  Input,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core'
import axios from 'axios'

import useStyles from './editComisions.styles'

import BackDrop from 'components/BackDrop'
import Button from 'components/CustomButtons/Button'
import useCustomDialogAlert from 'components/useCustomDialogAlert'
import { API_COMMISIONS, API_COMMISIONS_SELLER } from 'constants/urls'

const ModalAssignmentCommisions = ({ userId, getAllComissions, onCancel }) => {
  const classes = useStyles()
  const [addInfo, setAddInfo] = useState({
    sellerId: userId,
    commissionId: 0,
    value: null,
  })
  const [alert, setAlert] = useState(null)
  const [commisions, setCommisions] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const showDialogAlert = useCustomDialogAlert()
  const [isOpenCategories, setIsOpenCategories] = useState(false)
  const getCommsions = async () => {
    await axios
      .get(`${API_COMMISIONS}`)
      .then((response) => {
        setIsLoading(false)
        if (response) {
          setCommisions(response.data.data)
        }
      })
      .catch(() => {
        setIsLoading(false)
      })
  }
  useEffect(() => {
    getCommsions()
  }, [])

  const onSubmit = async () => {
    if (addInfo.commissionId === null || addInfo.value === null) {
      showDialogAlert(true, '¡Advertencia!', `Llena todos los campos`, 'Cerrar')
      return false
    }
    if (addInfo.value > 100 || addInfo.value < 0) {
      showDialogAlert(
        true,
        '¡Advertencia!',
        `El valor no debe ser mayor a 100% ni poner números negativos`,
        'Cerrar'
      )
      return false
    }
    try {
      setIsLoading(true)
      const res = await axios.post(`${API_COMMISIONS_SELLER}`, addInfo)
      if (res) {
        onCancel()
        getAllComissions()
        setIsLoading(false)
        showDialogAlert(
          true,
          '¡Cambio exitoso!',
          `Se ha asignado correctamente la comisión`,
          'Cerrar',
          '',
          true
        )
        setTimeout(() => {
          setAlert(null)
        }, 500)
      }
    } catch (error) {
      setIsLoading(false)
      console.error(error)
    }
  }

  return (
    <Grid container xl={12}>
      <BackDrop open={isLoading} />
      <Grid md={8} xs={10} className={classes.center}>
        <Grid md={12} sm={8} xs={12} className={classes.center}>
          <InputLabel htmlFor="selectCommision" className={classes.labelInput}>
            Seleccione la comision
          </InputLabel>

          <Select
            value={addInfo.commissionId}
            id="selectCommision"
            variant="outlined"
            className={classes.input}
          >
            <MenuItem value="0" key="0">
              Seleccione una comisión
            </MenuItem>
            {commisions.length > 0 ? (
              commisions.map((item) => (
                <MenuItem
                  value={item.id}
                  name={item.value}
                  key={item.id}
                  onClick={() => {
                    setAddInfo({ sellerId: userId, commissionId: item.id, value: item.value })
                  }}
                >
                  {item.name}
                </MenuItem>
              ))
            ) : (
              <MenuItem>No existen comisiones</MenuItem>
            )}
          </Select>

          <InputLabel htmlFor="commissionValue" className={classes.labelInput}>
            Valor
          </InputLabel>

          <TextField
            className={classes.input}
            id="commissionValue"
            formControlProps={{
              fullWidth: true,
            }}
            value={addInfo.value}
            variant="outlined"
            InputProps={{
              type: 'number',
              name: 'value',
              onChange: (e) => {
                setAddInfo({ ...addInfo, value: e.target.value })
              },
            }}
          />
        </Grid>
        <Grid
          container
          xl={12}
          sm={8}
          md={12}
          xs={12}
          justify="center"
          className={classes.spaccingButtons}
        >
          <Grid item xl={4} sm={5} md={4} xs={6}>
            <Button
              color="pinterest"
              onClick={() => {
                onCancel()
              }}
            >
              Cancelar
            </Button>
          </Grid>
          <Grid item xl={4} sm={6} md={4} xs={6}>
            <Button color="success" onClick={onSubmit} type="button">
              Guardar
            </Button>
          </Grid>
        </Grid>
      </Grid>
      {alert}
    </Grid>
  )
}

export default ModalAssignmentCommisions
