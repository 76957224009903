/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'

import { Button, Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'

import styles from './index.Styles'

import { routesLink } from 'constants/routesConsts'

const useStyles = makeStyles(styles)

export default function InContruction() {
  const classes = useStyles()
  const history = useHistory()

  const handleClick = () => {
    history.push(routesLink.orders)
  }

  return (
    <Grid className={classes.container}>
      <Typography variant="h4">En construcción</Typography>
      <Typography className={classes.text} variant="body2">
        Te ofrecemos disculpas en estos momentos estamos trabajando en ello.
      </Typography>

      <Button
        className={classes.btnBackgroundPrimary}
        color="secondary"
        variant="contained"
        onClick={handleClick}
      >
        Regresar a pedidos
      </Button>
    </Grid>
  )
}
