/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react'

import { Typography } from '@material-ui/core'
import { red } from '@material-ui/core/colors'
import { makeStyles } from '@material-ui/core/styles'
import { uniqueId } from 'lodash'
import { red500, green500 } from 'material-ui/styles/colors'
import { useForm } from 'react-hook-form'

import Button from 'components/CustomButtons/Button'
import InputField from 'components/InputField'

const useStyles = makeStyles({
  outlinedButton: {
    transition: 'all 0.2s',
    border: '1px solid #6064FF',
    borderRadius: '1000px',
    color: '#6064FF',
    padding: '8px 40px',
    '&:hover': {
      borderColor: '#FF40FC',
      color: '#FF40FC',
    },
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '16px',
  },
})

export const actions = {
  ADD: 1,
  UPDATE: 2,
  DELETE: 3,
}

const RelatedLinkForm = ({ linkInfo, onSubmit }) => {
  const classes = useStyles()
  const {
    handleSubmit,
    register,
    formState: { isValid },
  } = useForm({
    defaultValues: linkInfo,
    mode: 'onChange',
  })

  const onSubmitLinks = (data) => {
    onSubmit({
      ...data,
      id: data.id ? Number(data.id) : 0,
      action: linkInfo.id && linkInfo.id !== 0 ? actions.UPDATE : actions.ADD,
    })
  }

  return (
    <form onSubmit={handleSubmit(onSubmitLinks)} className={classes.formContainer}>
      <input type="number" name="id" ref={register()} hidden />
      <input type="text" name="uniqueId" ref={register()} hidden />
      <input type="number" name="action" ref={register()} hidden />
      <InputField
        name="name"
        label="Nombre"
        type="text"
        placeholder="Así se mostrará en la página"
        ref={register({
          required: true,
        })}
      />
      <div>
        <InputField
          name="url"
          label="URL"
          type="text"
          placeholder="A este link se redirigirá al usuario"
          ref={register({
            required: true,
          })}
        />
      </div>
      <Button
        type="submit"
        className={classes.outlinedButton}
        style={{
          alignSelf: 'end',
        }}
        disabled={!isValid}
      >
        {linkInfo.id ? 'Modificar' : 'Agregar'}
      </Button>
    </form>
  )
}

export default RelatedLinkForm
