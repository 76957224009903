import React, { useState, useEffect } from 'react'

import { Grid, Typography, Tooltip, Checkbox, TextField } from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import axios from 'axios'
import MUIDataTable from 'mui-datatables'

import useStyles from '../Stores/generalConfig.styles'

import ModalBrands from './ModalBrands'

import BackDrop from 'components/BackDrop'
import Button from 'components/CustomButtons/Button'
import { DefaultImage } from 'components/icons/personalizedIcons'
import ImageTooltip from 'components/ImageTooltip'
import ModalContainer from 'components/ModalContainer'
import useCustomDialogAlert from 'components/useCustomDialogAlert'
import { API_PRODUCTS, API_BASE } from 'constants/urls'

const Brands = () => {
  const classes = useStyles()
  const [isOpen, setIsOpen] = useState(null)
  const showDialogAlert = useCustomDialogAlert()
  const [name, setName] = useState('')
  const [addDisabled, setAddDisabled] = useState(false)
  const [pagination, setPagination] = useState({
    page: 0,
    perPage: 10,
    prevPage: '',
    nextPage: '',
    typeFilter: 0, // All: 0, Active : 1, Inactive: 2
  })
  const [totalBrands, setTotalBrands] = useState(0)

  const [data, setData] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [toEdit, settoEdit] = useState({})
  const getData = async (searchText = '', page = 1, perPage = 10) => {
    setIsLoading(true)
    await axios
      .get(`${API_BASE}/Brands/list?Page=${page}&PageSize=${perPage}&Search=${searchText}`)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          setData(response.data.data)
          setPagination({
            page: page === 0 ? 0 : page - 1,
            perPage: perPage,
          })
          setTotalBrands(response.data.pagination.totalCount)
        }
        setIsLoading(false)
      })
      .catch((e) => {
        setIsLoading(false)
        console.log(e)
      })
  }

  const enableOrDisable = async (e, tableMeta) => {
    setIsLoading(true)
    await axios
      .put(`${API_BASE}/Brands/id?id=${tableMeta.rowData[0]}`, {
        id: tableMeta.rowData[0],
        name: tableMeta.rowData[2],
        imageSrc: tableMeta.rowData[1],
        isActive: !e,
      })
      .then((response) => {
        const images = response?.data?.data
        if (images) {
          setIsLoading(false)
          setName('')
          getData()
        }
      })
      .catch((e) => {
        setIsLoading(false)
        showDialogAlert(
          true,
          '¡Advertencia!',
          `Ha ocurrido un error al ${e ? 'desactivar' : 'activar'} la marca, ${e.message}`,
          'Cerrar'
        )
      })
  }

  const handleCloseModal = () => {
    setIsOpen(false)
  }

  const columns = [
    {
      name: 'id',
      options: {
        display: false,
        download: false,
      },
    },
    {
      options: {
        customBodyRender: (value, tableMeta) => {
          return tableMeta.rowData[1] && tableMeta.rowData[1] !== 'string' ? (
            <>
              <ImageTooltip image={tableMeta.rowData[1]} showImage />
            </>
          ) : (
            <DefaultImage />
          )
        },
      },
    },
    {
      name: 'name',
      label: 'Nombre de la Marca',
    },
    {
      name: 'isActive',
      label: 'Activo ',
      options: {
        customBodyRender: (e, tableMeta) => {
          return (
            <Checkbox
              onChange={() => enableOrDisable(e, tableMeta)}
              checked={tableMeta.rowData[3]}
            />
          )
        },
      },
    },
    {
      name: '',
      label: 'Acciones',
      options: {
        customBodyRender: (value, tableMeta) => {
          return (
            <>
              <Tooltip title="Editar">
                <Button
                  onClick={(event) => {
                    event.preventDefault()
                    setIsOpen(true)
                    settoEdit(tableMeta.rowData)
                  }}
                  justIcon
                  round
                >
                  <EditIcon />
                </Button>
              </Tooltip>
            </>
          )
        },
      },
    },
  ]

  useEffect(() => {
    getData()
  }, [])

  const changePageTable = (paginationpage) => {
    let pageInt = Number(paginationpage) + 1
    if (paginationpage === 1 && paginationpage === pagination.page) {
      pageInt = 1
    }
    getData('', pageInt, pagination.perPage)
  }

  const changePerPagetable = (perPage) => {
    setPagination({ ...pagination, perPage: perPage })
    getData('', pagination.page === 0 ? 1 : pagination.page, perPage)
  }

  const options = {
    responsive: 'scroll',
    search: false,
    filterType: 'dropdown',
    selectableRows: false,
    rowHover: true,
    filter: false,
    sort: false,
    print: false,
    page: pagination.page,
    serverSide: true,
    count: totalBrands,
    fixedSelectColumn: false,
    download: false,
    viewColumns: false,
    rowsPerPageOptions: [10, 15, 100],
    textLabels: {
      body: {
        noMatch: `${isLoading ? 'Cargando ...' : 'No hay registros'}`,
      },
      pagination: {
        next: 'Página siguiente',
        previous: 'Página anterior',
        rowsPerPage: 'Filas por página:',
        displayRows: 'de',
      },
    },
    onTableChange: (action, tableState) => {
      switch (action) {
        case 'changePage':
          changePageTable(tableState.page === 0 ? 1 : tableState.page)
          document.querySelector('#main').scrollTop = 0
          break
        case 'changeRowsPerPage':
          changePerPagetable(tableState.rowsPerPage)
          break
        default:
          break
      }
    },
  }

  const SaveBrand = async (brand) => {
    const newData = {
      id: 0,
      name: name,
      imageSrc: brand.src,
      isActive: true,
    }
    await axios({
      url: `${API_BASE}/Brands`,
      method: 'post',
      data: newData,
    })
      .then(function (response) {
        const images = response?.data?.data
        if (images) {
          setName('')
          getData()
        }
      })
      .catch((e) => {
        setIsLoading(false)
        showDialogAlert(
          true,
          '¡Advertencia!',
          `Ha ocurrido un error al guardar las imágenes, ${e.message}`,
          'Cerrar'
        )
      })
  }

  const forImagesUpload = async (e, edit = true) => {
    const imagesToSave = e
    if (imagesToSave) {
      setIsLoading(true)
      let imagesSaved = []
      const formData = new FormData()
      formData.append(`files`, imagesToSave[0])
      await axios({
        url: `${API_PRODUCTS}/UploadFiles`,
        method: 'post',
        data: formData,
      })
        .then((response) => {
          const images = response?.data?.data
          if (images) {
            imagesSaved = images
          }
          setIsLoading(false)
        })
        .catch(() => {
          setIsLoading(false)
          showDialogAlert(
            true,
            '¡Advertencia!',
            `Ha ocurrido un error al guardar la imágene, valida que la imagen cumpla los requisitos.`,
            'Cerrar'
          )
        })

      if (!edit) {
        SaveBrand(imagesSaved[0])
      } else {
        return imagesSaved[0].src
      }
    }
  }

  const searchBrands = () => {
    getData(name.trim())
    const results = data.filter((e) => e.name.toLowerCase() === name.toLowerCase())
    // eslint-disable-next-line no-unused-expressions
    results.length > 0 ? setAddDisabled(true) : setAddDisabled(false)
    return !addDisabled
  }

  useEffect(() => {
    searchBrands()
  }, [name])
  const clickImage = () => {
    if (name.trim() === '') {
      setIsLoading(false)
      showDialogAlert(
        true,
        '¡Advertencia!',
        `Para agregar una marca, primero debes agregar le un nombre`,
        'Cerrar'
      )
      setName('')
    } else if (searchBrands()) {
      document.getElementById(`upload-Image`).click()
    }
  }
  return (
    <>
      <Grid container justify="space-between" className={classes.titleStores}>
        <Typography variant="h6">Marcas</Typography>
      </Grid>

      <Grid container direction="row" className={classes.divContent}>
        <Grid item md={11} lg={6}>
          <Grid container item md={5}>
            <Typography>Agregar marca</Typography>
          </Grid>
          <Grid container direction="row">
            <TextField
              id="name"
              variant="outlined"
              onChange={(e) => setName(e.target.value)}
              placeholder="Nombre de la marca"
              value={name}
              name="name"
            />
            <input
              accept=".png,.jpg,.jpeg"
              className={classes.input}
              onChange={(e) => forImagesUpload(e.target.files, false)}
              id="upload-Image"
              type="file"
              hidden
            />
            <Button
              onClick={() => clickImage()}
              className={classes.btnAdd}
              color="secondary"
              variant="contained"
              disabled={addDisabled}
            >
              Agregar imagen
            </Button>
          </Grid>
        </Grid>
        <BackDrop open={isLoading} />
      </Grid>
      <Grid container direction="column" className={classes.divContent}>
        <MUIDataTable
          data={data.map((item) => {
            return [item.id, item.imageSrc, item.name, item.isActive]
          })}
          columns={columns}
          options={options}
        />
        <ModalContainer
          xl={8}
          md={4}
          sm={4}
          open={isOpen}
          onClose={handleCloseModal}
          title="Actualizar"
        >
          <ModalBrands
            toEdit={toEdit}
            onCancel={handleCloseModal}
            forImagesUpload={forImagesUpload}
            getData={getData}
            setIsLoading={setIsLoading}
          />
        </ModalContainer>
      </Grid>
    </>
  )
}

export default Brands
