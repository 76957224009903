import { green } from '@material-ui/core/colors'
import { makeStyles } from '@material-ui/core/styles'

export default makeStyles((theme) => ({
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  btnBackgroundPrimary: {
    borderRadius: '50px',
    border: 'none',
    margin: '20px 0',
    backgroundColor: '#5e44ff',
    color: 'white',
    fontWeight: '500',
    '&:hover': {
      fontWeight: 'bolder',
      backgroundColor: '#ef0bef',
      boxShadow: '-7px 7px 0px #000000',
      border: '2px solid #000000',
      borderRadius: '50px',
      opacity: '1',
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  bottom: {
    color: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  top: {
    color: '#6064FF',
    animationDuration: '550ms',
    position: 'relative',
    left: 0,
  },
  circle: {
    strokeLinecap: 'round',
  },
  btnDownAndChargeFile: {
    width: '248px',
    height: '56px',
    borderRadius: '50px',
    border: 'none',
    backgroundColor: '#5e44ff',
    color: 'white',
    marginBottom: '20px',
    fontWeight: '500',
    '&:hover': {
      fontWeight: 'bolder',
      backgroundColor: '#ef0bef',
      boxShadow: '-7px 7px 0px #000000',
      border: '2px solid #000000',
      borderRadius: '50px',
      opacity: '1',
    },
  },
  btnGoToProdcuts: {
    minWidth: 180,
    maxWidth: 246,
    fill: theme.palette.white.main,
    background: theme.palette.grey.dark,
    color: theme.palette.white.main,
    marginTop: '5vh',
    '&:hover': {
      background: theme.palette.primary.main,
      color: theme.palette.white.main,
    },
  },
  redirectLink: {
    '&:hover': {
      fontWeight: 'bold',
      color: theme.palette.black.main,
    },
  },
  topInputLabel: {
    fontFamily: 'Red Hat Display Bold',
    margingTop: '10px',
    marginBottom: '10px',
  },
}))
