/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react'

import { TextField, Typography, Grid } from '@material-ui/core'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import { makeStyles } from '@material-ui/core/styles'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'

import BackDrop from 'components/BackDrop'
import CategorySuggestions from 'components/CategorySuggestions'
import Button from 'components/CustomButtons/Button'
import StyledRadio from 'components/StyledRadio'
import { PRODUCT_CONDITIONS, PRODUCT_TYPES } from 'constants/productsConstants'
import { useProduct } from 'contextApi/ProductContext'

const useStyles = makeStyles({
  root: {
    margin: '0 10%',
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  button: {
    width: '248px',
    height: '40px',
    borderRadius: '50px',
    border: 'none',
    margin: '20px 0',
    backgroundColor: '#6064FF',
    color: 'white',
    fontWeight: '500',
    '&:hover': {
      fontWeight: 'bolder',
      backgroundColor: '#ef0bef',
      boxShadow: '-7px 7px 0px #000000',
      border: '2px solid #000000',
      borderRadius: '50px',
    },
  },
  buttonText: {
    color: '#6064FF',
    fontWeight: '500',
    '&:hover': {
      fontWeight: 'bolder',
      color: '#ef0bef',
    },
  },
  optionsBox: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '15px',
    marginTop: '15px',
  },
})

const ProductCategorization = () => {
  const { product, setProduct, handleNext } = useProduct()
  const classes = useStyles()
  const [isLoading, setIsLoading] = useState(false)
  const [productName, setProductName] = useState('')
  const [productType, setProductType] = useState('')
  const [productCondition, setProductCondition] = useState({})
  const [categories, setCategories] = useState([])
  const [isOpen, setIsOpen] = useState(false)

  const handleChangeProductType = (value) => {
    setProductType(value)
  }

  const handleChangeProductCondition = (value) => {
    setProductCondition(value)
  }

  const handleFinishStep = () => {
    const productData = {
      ...product,
      name: productName,
      type: productType,
      categories,
    }

    if (['simple', 'variable'].includes(productType)) {
      productData.isUsed = productCondition.isUsed
      productData.isReconditioned = productCondition.isReconditioned
    } else {
      delete productData.isUsed
      delete productData.isReconditioned
      productData.stockQuantity = productType === 'virtual' ? product.stockQuantity ?? 0 : 1000
      productData.dimensions = {
        height: '0',
        length: '0',
        width: '0',
        weight: '0',
      }
    }
    setProduct(productData)
    handleNext()
  }

  useEffect(() => {
    setProductName(product.name)
    setProductType(product.type)
    if (['simple', 'variable'].includes(productType)) {
      const selectedCondition =
        PRODUCT_CONDITIONS.find(
          (item) =>
            item.value.isUsed === product.isUsed &&
            item.value.isReconditioned === product.isReconditioned
        )?.value ?? PRODUCT_CONDITIONS[0].value
      setProductCondition(selectedCondition)
    } else {
      setProductCondition(null)
    }
    setCategories(product.categories)
  }, [product.name, product.type, product.isUsed, product.isReconditioned, product.categories])

  const stepCompleted =
    !productName ||
    !productType ||
    (['simple', 'variable'].includes(productType) && !productCondition) ||
    categories.length === 0

  return (
    <div className={classes.root}>
      <BackDrop open={isLoading} />
      <Typography variant="subtitle1">Tipo de producto</Typography>
      <div className={classes.optionsBox}>
        {PRODUCT_TYPES.map((item) => (
          <StyledRadio
            key={item.value}
            label={item.label}
            description={item.description}
            disabled={product.id !== '0'}
            value={item.value}
            selected={productType === item.value}
            handleChange={handleChangeProductType}
            icon={item.icon(productType === item.value, product.id !== '0')}
          />
        ))}
      </div>
      <br />
      {['simple', 'variable'].includes(productType) && (
        <>
          <div>
            <Typography variant="subtitle1">Condición de tu producto</Typography>
            <div className={classes.optionsBox}>
              {PRODUCT_CONDITIONS.map((item) => (
                <StyledRadio
                  key={item.label}
                  label={item.label}
                  value={item.value}
                  selected={productCondition === item.value}
                  handleChange={handleChangeProductCondition}
                  icon={item.icon(productCondition === item.value)}
                />
              ))}
            </div>
          </div>
          <br />
        </>
      )}
      <Typography variant="subtitle1">Información Básica</Typography>
      <br />
      <Typography variant="paragraph">Nombre del producto:</Typography>
      <TextField
        fullWidth
        id="name"
        name="name"
        variant="outlined"
        placeholder="Camiseta blanca, manga corta, sin estampados..."
        value={productName}
        onChange={(e) => {
          setProductName(e.target.value)
          if (categories.length > 0) {
            setCategories([])
          }
        }}
      />
      {categories.length > 0 && productName.length > 0 ? (
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
          {categories.map((category) => (
            <Typography key={category.name} variant="paragraph" color="textPrimary">
              {category.name}
            </Typography>
          ))}
          <Button
            className={classes.buttonText}
            onClick={() => {
              setIsOpen(true)
            }}
          >
            Cambiar categoría
          </Button>
        </Breadcrumbs>
      ) : (
        <Typography variant="paragraph" color="inherit">
          Por favor, presione en “Categorizar” para ver las categorías sugeridas para este producto,
          o en su defecto seleccionarla manualmente
        </Typography>
      )}
      <Grid container justifyContent="center">
        <Button
          className={classes.button}
          onClick={() => {
            if (categories.length > 0) {
              handleFinishStep()
            } else {
              setIsOpen(true)
            }
          }}
          disabled={categories.length > 0 ? stepCompleted : productName.length < 3}
        >
          {categories.length > 0 ? 'Continuar' : 'Categorizar'}
        </Button>
      </Grid>
      <CategorySuggestions
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        name={productName}
        setIsLoading={setIsLoading}
        onFinish={(category) => {
          setCategories(category)
        }}
      />
    </div>
  )
}

export default ProductCategorization
