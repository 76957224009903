import { makeStyles } from '@material-ui/core/styles'

export default makeStyles((theme) => ({
  inputTop: {
    marginTop: 12,
    marginLeft: 12,
    marginRigth: 13,
  },
  messageError: {
    marginTop: 20,
    marginBottom: 20,
  },

  container: {
    paddingLeft: '15px',
    paddingRight: '15px',
  },
  btnBackgroundPrimary: {
    marginLeft: 5,
    minWidth: 180,
    maxWidth: 246,
    fill: theme.palette.white.main,
    borderRadius: '50px',
    border: 'none',
    margin: '20px 0',
    backgroundColor: '#5e44ff',
    color: 'white',
    fontWeight: '500',
    '&:hover': {
      fontWeight: 'bolder',
      backgroundColor: '#ef0bef',
      boxShadow: '-7px 7px 0px #000000',
      border: '2px solid #000000',
      borderRadius: '50px',
      opacity: '1',
    },
  },
  labelSize: {
    marginTop: 15,
    marginBottom: 15,
    fontFamily: 'Red Hat Display Bold',
  },
}))
