/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'

import {
  Backdrop,
  Fade,
  Grid,
  Modal,
  Typography,
  makeStyles,
  IconButton,
  Tooltip,
} from '@material-ui/core'
import ImageList from '@material-ui/core/ImageList'
import ImageListItem from '@material-ui/core/ImageListItem'
import ImageListItemBar from '@material-ui/core/ImageListItemBar'
import { Close, HelpOutline, Visibility } from '@material-ui/icons'
import GetApp from '@material-ui/icons/GetApp'

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: '16px',
    width: '90%',
    maxWidth: '800px',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4),
  },
  body: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  evidencesWrapper: {
    border: '1px solid #e0e0e0',
    borderRadius: '8px',
    padding: '10px 20px',
  },
  root: {
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  imageList: {
    flexWrap: 'nowrap',
  },
  imageListItem: {
    maxHeight: '150px',
  },
  imageItem: {
    objectFit: 'contain',
  },
  title: {
    color: 'white',
  },
  titleBar: {
    background: 'white',
  },
  expandedImage: {
    width: '100%',
    height: '350px',
    objectFit: 'contain',
  },
  anchorButton: {
    textDecoration: 'none',
    fontSize: '14px',
    fontWeight: 'bold',
    margin: '20px 0',
  },
}))
const AttachmentsModal = ({ files, isOpen, handleClose }) => {
  const [selectedImage, setSelectedImage] = useState()
  const classes = useStyles()

  useEffect(() => {
    if (isOpen && files.length > 0) {
      const firstFileType = files[0]?.type || ''
      const firstFileURL = files[0]?.url || ''

      setSelectedImage(
        firstFileType.includes('image')
          ? firstFileURL
          : 'https://luegopago.blob.core.windows.net/luegopago-uploads/sellerCenter/sellerOrders/video_placeholder.jpg'
      )
    }
  }, [isOpen, files])

  const handleImageSelect = (imageUrl) => {
    setSelectedImage(imageUrl)
  }

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={isOpen}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={isOpen}>
        <div className={classes.paper}>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Grid container alignItems="center">
                <Grid item>
                  <Typography variant="h6" style={{ color: 'black', margin: '25px auto' }}>
                    Descarga de evidencias
                  </Typography>
                </Grid>
                <Grid item>
                  <Tooltip
                    title="Las imágenes se pueden previsualizar en el navegador, pero los videos deben descargarse para su reproducción, ya que no pueden reproducirse directamente en el navegador."
                    interactive
                    placement="top-start"
                    PopperProps={{
                      style: {
                        fontSize: '50px',
                      },
                    }}
                  >
                    <IconButton aria-label="delete" className={classes.margin} size="small">
                      <HelpOutline fontSize="inherit" />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <IconButton onClick={handleClose}>
                <Close style={{ color: 'black' }} />
              </IconButton>
            </Grid>
          </Grid>
          <div className={classes.evidencesWrapper}>
            <div className={classes.root}>
              <img
                src={selectedImage}
                alt="Evidencia de devolución"
                className={classes.expandedImage}
              />
              <ImageList cols={4.5} className={classes.imageList}>
                {files.map((item) => (
                  <ImageListItem key={item.url} className={classes.imageListItem}>
                    {item.type.includes('image') ? (
                      <img src={item.url} alt={item.url} className={classes.imageItem} />
                    ) : (
                      <img
                        src="https://luegopago.blob.core.windows.net/luegopago-uploads/sellerCenter/sellerOrders/video_placeholder.jpg"
                        alt={item.url}
                        className={classes.imageItem}
                      />
                    )}
                    <ImageListItemBar
                      title={
                        item.type.includes('image') && (
                          <IconButton onClick={() => handleImageSelect(item.url)}>
                            <Visibility className={classes.title} />
                          </IconButton>
                        )
                      }
                      actionIcon={
                        <a href={item.url} download className={classes.anchorButton}>
                          <IconButton>
                            <GetApp className={classes.title} />
                          </IconButton>
                        </a>
                      }
                    />
                  </ImageListItem>
                ))}
              </ImageList>
            </div>
          </div>
        </div>
      </Fade>
    </Modal>
  )
}

export default AttachmentsModal
