import React from 'react'

import { useParams } from 'react-router-dom'

import LoginPage from 'views/Login'

export default function RecoveredPassword() {
  const { userEmail, code } = useParams()

  return <LoginPage userEmail={userEmail} code={code} />
}
