import routes from 'routes/routesBackOffice'

const routesByRoleService = (role) => {
  if (!role) return []
  const allRoutes = [...routes.all]
  const routesByRole = routes[role.toLowerCase()]
  
  if (routesByRole && routesByRole.length) {
    allRoutes.push(...routesByRole)
  }
  return allRoutes
}

export default routesByRoleService
