/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react'

import { Grid, Button } from '@material-ui/core'

import useStyles from '../../../products.styles'
import ImagesProduct from '../../ImagesProduct'

import useCustomDialogAlert from 'components/useCustomDialogAlert'

const ImagesVariant = ({ variation, setVariation, onSave, changeTabs, onClose }) => {
  const showDialogAlert = useCustomDialogAlert()
  const [imagesList, setImagesList] = useState([])
  const [imagesProcessed, setImagesProcessed] = useState()
  const [imagesProcessedDisabled, setImagesProcessedDisabled] = useState(true)
  const saveVariation = () => {
    if (variation.images.length === 0) {
      showDialogAlert(true, '¡Advertencia!', `Debe ingresar una imagen para la variación`, 'Cerrar')
      return
    }
    onSave()
  }
  const isLoading = false
  const classes = useStyles()

  useEffect(() => {
    if (!imagesProcessed) {
      setImagesProcessedDisabled(false)
    } else {
      const filteredFiles = imagesProcessed.files.filter(
        (item) => item.codeIssue !== undefined && item.codeIssue !== 0
      )
      setImagesProcessedDisabled(filteredFiles.length > 0)
    }
  }, [imagesProcessed, imagesProcessed?.files, imagesList])

  return (
    <>
      <Grid>
        <ImagesProduct
          isProductStepper={false}
          saveAndPublish={null}
          setVariation={setVariation}
          variation={variation}
          setImageProcessor={setImagesProcessed}
          setImagesListVariant={setImagesList}
        />
      </Grid>
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        className={classes.gridButtonsInfoBasic}
      >
        <Button
          className={classes.btnBackgroundPrimary}
          variant="contained"
          color="secondary"
          onClick={() => changeTabs(2)}
        >
          Anterior
        </Button>
        <Button
          disabled={isLoading}
          onClick={() => onClose()}
          className={classes.btnBackgroundError}
        >
          Cancelar
        </Button>
        <Button
          disabled={imagesProcessedDisabled || isLoading}
          variant="contained"
          onClick={() => saveVariation()}
          color="secondary"
          className={classes.btnBackgroundPrimary}
        >
          Guardar
        </Button>
      </Grid>
    </>
  )
}

export default ImagesVariant
