import React, { useState } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { Button, InputLabel, TextField, Typography, IconButton, Grid } from '@material-ui/core'
import InputAdornment from '@material-ui/core/InputAdornment'
import { makeStyles } from '@material-ui/core/styles'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import axios from 'axios'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'

import styles from './loginPageStyle'

import { EyeIconShow } from 'components/icons/personalizedIcons'
import useCustomDialogAlert from 'components/useCustomDialogAlert'
import { USER_FORGOT_PASSWORD_CONFIRM } from 'constants/urls'

const useStyles = makeStyles(styles)
const schema = yup.object().shape({
  newPassword: yup
    .string()
    .required('Campo requerido')
    .matches(
      /^(?=\w*\d)(?=\w*[A-Z])(?=\w*[a-z])(?=\w*[!@#$%^&*+(),|º_[;'/.?":{}<>])(?=\w*[0-9])\S{8,}$/g,
      'La contraseña no cumple con los requisitos mínimos'
    ),
  confirPassword: yup
    .string()
    .oneOf([yup.ref('newPassword'), null], 'Debe coincidir con la contraseña'),
})

export default function ConfirmEmail({ userEmail, code, handlerClosed }) {
  const classes = useStyles()
  const showDialogAlert = useCustomDialogAlert()
  const { register, handleSubmit, errors } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
  })

  const [colorCondition, serColorCondition] = useState(false)

  const [loading, setLoading] = useState(false)
  const [lblMessage, setLblMessage] = useState('')

  const [model, setModel] = useState({
    newPassword: '',
    confirPassword: '',
  })
  const [security, setSecurity] = useState({
    password: '',
    showPassword: false,
    showPasswordConfirm: false,
  })


  const onSubmit = async () => {
    if (!model.newPassword || !model.confirPassword) {
      showDialogAlert(true, '¡Advertencia!', `Llena todos los campos`, 'Cerrar')
      return false
    }
    const patt1 = /^(?=\w*\d)(?=\w*[A-Z])(?=\w*[a-z])(?=\w*[!@#$%^&*+(),|º_[;'/.?":{}<>])(?=\w*[0-9])\S{8,}$/g
    const result = patt1.test(model.newPassword)

    if (!result) {
      showDialogAlert(
        true,
        '¡Advertencia!',
        `La contraseña no cumple con los requisitos mínimos`,
        'Cerrar'
      )
      serColorCondition(true)
      return false
    }
    serColorCondition(false)

    if (model.newPassword !== model.confirPassword) {
      showDialogAlert(true, '¡Advertencia!', `Las contraseñas no coinciden.`, 'Cerrar')
      return false
    }
    setLoading(true)
    await axios
      .post(`${USER_FORGOT_PASSWORD_CONFIRM}`, {
        code: code,
        newPassword: model.newPassword,
        email: userEmail,
      })
      .then((response) => {
        setLoading(false)
        if (response.status === 200) {
          showDialogAlert(
            true,
            '¡Cambio exitoso!',
            `¡La contraseña fue cambiada con exito!`,
            'Cerrar',
            '',
            true
          )
          handlerClosed()
          setModel({ ...model, newPassword: '', confirPassword: '' })
        }
      })
      .catch((e) => {
        setLoading(false)
        if (
          e.response.data.errors.includes(
            'Restablecimiento de contraseña fallido, intenta de nuevo.'
          )
        ) {
          showDialogAlert(
            true,
            '¡Advertencia!',
            `Restablecimiento de contraseña fallido, intenta de nuevo.`,
            'Cerrar'
          )
        } else if (
          e.response.data.errors.includes(
            'Ingresa una contraseña nueva y diferente a las que ya has usado'
          )
        ) {
          showDialogAlert(
            true,
            '¡Advertencia!',
            `Ingresa una contraseña nueva y diferente a las que ya has usado.`,
            'Cerrar'
          )
        } else {
          showDialogAlert(true, '¡Advertencia!', `Ha ocurrido un error.`, 'Cerrar')
        }
        return false
      })

    return true
  }
  const handleClickShowPassword = () => {
    setSecurity({ ...security, showPassword: !security.showPassword })
  }

  const handleClickShowConfirmPassword = () => {
    setSecurity({ ...security, showPasswordConfirm: !security.showPasswordConfirm })
  }

  const preventDefault = (e) => {
    e.preventDefault()
  }

  return (
    <form className={classes.containerConfirm} onSubmit={handleSubmit(onSubmit)}>
      <InputLabel htmlFor="newPassword" className={classes.labelInput}>
        Nueva contraseña
      </InputLabel>

      <TextField
        className={classes.input}
        id="newPassword"
        name="newPassword"
        inputRef={register}
        error={!!errors.newPassword}
        helperText={errors?.newPassword?.message}
        variant="outlined"
        formControlProps={{
          fullWidth: true,
        }}
        onPaste={preventDefault}
        type={security.showPassword ? 'text' : 'password'}
        InputProps={{
          onChange: ({ target }) => {
            setLblMessage('')
            setModel({ ...model, newPassword: target.value })
          },
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={handleClickShowPassword}>
                {security.showPassword ? <EyeIconShow /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),

          autoComplete: 'off',
        }}
      />
      <InputLabel htmlFor="confirPassword" className={classes.labelInput}>
        Confirmar nueva contraseña
      </InputLabel>

      <TextField
        className={classes.input}
        id="confirPassword"
        name="confirPassword"
        inputRef={register}
        error={!!errors.confirPassword}
        helperText={errors?.confirPassword?.message}
        variant="outlined"
        formControlProps={{
          fullWidth: true,
        }}
        onPaste={preventDefault}
        type={security.showPasswordConfirm ? 'text' : 'password'}
        InputProps={{
          onChange: ({ target }) => {
            setLblMessage('')
            setModel({ ...model, confirPassword: target.value })
          },
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={handleClickShowConfirmPassword}>
                {security.showPasswordConfirm ? <EyeIconShow /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),

          autoComplete: 'off',
        }}
      />

      <Typography
        className={classes.textConfirmPassword}
        variant="subtitle1"
        color={colorCondition ? 'error' : 'initial'}
      >
        Recuerda que la contraseña debe contener al menos 8 caracteres, 1 letra mayúscula, 1 número
        y 1 carácter especial.
      </Typography>

      <Typography className={classes.caption} variant="caption">
        {lblMessage}
      </Typography>

      <Grid container justify="center" className={classes.divButtons}>
        <Button
          className={classes.btnBackgroundError}
          variant="contained"
          color="secondary"
          onClick={handlerClosed}
        >
          Cancelar
        </Button>
        <Button
          variant="contained"
          color="secondary"
          className={classes.btnBackgroundPrimary}
          type="submit"
          disabled={loading}
        >
          Restablecer
        </Button>
      </Grid>
    </form>
  )
}
