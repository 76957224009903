import React, { useState } from 'react'

import { Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import FilterButtons from 'components/FilterButtons'
import SearchBox from 'components/SearchBox'

const useStyles = makeStyles(() => ({
  container: {
    marginBottom: 30,
  },
  listActions: {
    width: 151,
  },
  divActionsAndFilters: {
    display: 'flex',
    gap: '15px',
  },
  divStates: {
    display: 'flex',
    columnGap: '10px',
  },
}))

const FiltersProducts = ({
  handlerSearch,
  setFiltersApi,
  filtered,
  setFiltered,
  pendingApprovals,
}) => {
  const classes = useStyles()
  const [avalaibleSetTextValueEmpty, setAvalaibleSetTextValueEmpty] = useState(false)
  const showStatusChips = () => {
    const chipNews = (
      <FilterButtons
        key={2}
        label="Nuevos"
        onClick={() => {
          setFiltersApi(null, 2)
          setFiltered({ typeFilter: 2, totalFilter: '' })
        }}
        active={filtered.typeFilter === 2}
      />
    )

    const chipEdited = (
      <FilterButtons
        key={3}
        label="Editados"
        onClick={() => {
          pendingApprovals()
          setFiltered({ typeFilter: 10, totalFilter: '' })
        }}
        active={filtered.typeFilter === 10}
      />
    )
    const chipAll = (
      <FilterButtons
        key={4}
        label="Todos"
        onClick={() => {
          setFiltered({ typeFilter: 11, totalFilter: '' })
          setFiltersApi(null, 11)
        }}
        active={filtered.typeFilter === 11}
      />
    )
    return [chipNews, chipEdited, chipAll]
  }

  return (
    <Grid container justify="space-between" alignItems="flex-end" className={classes.container}>
      <Grid className={classes.divActionsAndFilters}>
        <Grid>
          <Typography variant="subtitle1">Filtrar por:</Typography>
          <Grid className={classes.divStates}>{showStatusChips()}</Grid>
        </Grid>
      </Grid>
      {filtered.typeFilter === 11 && (
        <SearchBox
          handlerSearch={handlerSearch}
          avalaibleSetTextValueEmpty={avalaibleSetTextValueEmpty}
          setAvalaibleSetTextValueEmpty={setAvalaibleSetTextValueEmpty}
          setFiltersApi={setFiltersApi}
        />
      )}
    </Grid>
  )
}

export default FiltersProducts
