/* eslint-disable import/no-unresolved */
import React, { useContext } from 'react'

import { Button, TextField } from '@material-ui/core'
import axios from 'axios'
import { useForm } from 'react-hook-form'

import useCustomDialogAlert from 'components/useCustomDialogAlert'
import { API_STORE_INFO } from 'constants/urls'
import AuthContext from 'contextApi/AuthContext'

const TACModal = ({ cancelTACModal }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()
  const { userLoggedin, setLoggedin } = useContext(AuthContext)
  const showDialogAlert = useCustomDialogAlert()
  const updateTAC = (data) => {
    const formData = new FormData()
    formData.append('termsAndConditions', data.termsAndConditions)
    formData.append('sellerId', userLoggedin.sellerId)
    axios
      .put(`${API_STORE_INFO}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((res) => {
        if (res.status === 200) {
          cancelTACModal()
          const dataUser = userLoggedin
          dataUser.isHadTermsAndConditions = true
          setLoggedin(dataUser)
          showDialogAlert(
            true,
            '¡Cambio exitoso!',
            `Se ha guardado correctamente la información`,
            'Cerrar',
            '',
            true
          )
        } else {
          showDialogAlert(
            true,
            '¡Advertencia!',
            `Algunos de los correos digitados no son válido`,
            'Cerrar'
          )
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  return (
    <form onSubmit={handleSubmit(updateTAC)}>
      <h3 className="-mt-3">Términos y Condiciones</h3>
      <span style={{ color: 'red', padding: '5px 0 15px 0' }}>
        {errors?.termsAndConditions?.message}
      </span>
      <TextField
        type="text"
        className="border mb-4"
        style={{
          width: 550,
          borderRadius: '10px 10px 0px 0px',
          padding: '5px',
          background: '#F5F5F5',
        }}
        placeholder="Agrega tus términos y condiciones aquí"
        multiline
        name="termsAndConditions"
        inputRef={register({
          required: {
            value: true,
            message: 'Este campo es requerido*',
          },
        })}
      />
      <br />
      <Button
        type="Button"
        style={{
          color: 'white',
          fontWeight: '500',
          borderRadius: '50px',
          backgroundColor: '#5e44ff',
          marginRight: '15px',
          width: '240px',
          height: '50px',
          border: '1px solid #6064ff',
          fontSize: '18',
        }}
        onClick={cancelTACModal}
        variant="contained"
        color="secondary"
      >
        Cancelar
      </Button>
      <Button
        type="submit"
        style={{
          color: 'white',
          fontWeight: '500',
          borderRadius: '50px',
          backgroundColor: '#5e44ff',
          width: '240px',
          height: '50px',
          border: '1px solid #6064ff',
          fontSize: '18',
        }}
        variant="contained"
        color="secondary"
      >
        Actualizar
      </Button>
    </form>
  )
}

export default TACModal
