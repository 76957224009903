/* eslint-disable no-use-before-define */
import React, { useState, useEffect } from 'react'

import { Grid, Typography, Switch } from '@material-ui/core'
import { Delete } from '@material-ui/icons'
import AddIcon from '@material-ui/icons/Add'
import EditIcon from '@material-ui/icons/Edit'
import MUIDataTable from 'mui-datatables'

import useStyles from './payments.styles'

import BackDrop from 'components/BackDrop'
import Button from 'components/CustomButtons/Button'
import CustomToolbar from 'components/CustomToolbarMuiDatatable/CustomToolbarMuiDatatable'
import ModalContainer from 'components/ModalContainer/ModalContainer'
import ModalCreatePlatform from 'components/ModalPaymentMethods/ModalCreatePlatform'
import ModalUpdatePaymentMethodStatus from 'components/ModalPaymentMethods/ModalUpdatePaymentMethodStatus'
import useCustomDialogAlert from 'components/useCustomDialogAlert'
import {
  refreshToken,
  getEnabledMethodsPlatform,
  createPlatformNew,
  updateMethodStateService,
} from 'services/paymentsServices/payments.service'

// eslint-disable-next-line import/prefer-default-export
export const EnablingMethods = () => {
  const [BuildOject, setBuildOject] = useState({})
  const [DataObject, setDataObject] = useState({})
  let buildData = []
  let updateplatformData = {}
  const [InitialDate, setInitialDate] = useState([])
  const [BuildData, setBuildData] = useState([])
  const [ColumnsData, setColumsData] = useState([])
  const [OperationPerform, setOperationPerform] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const style = useStyles()
  const [alert, setAlert] = useState(null)
  const showDialogAlert = useCustomDialogAlert()

  const converSwitch = (switchItem, key) => {
    return (
      <Switch
        color="Secondary"
        checked={switchItem}
        disabled={false}
        onChange={() => {
          updateplatformData[key] = !updateplatformData[key]

          const updateplatformBuild = converObje(updateplatformData)
          setBuildOject(updateplatformBuild)
          setDataObject(updateplatformData)
        }}
      />
    )
  }

  const converObje = (Data) => {
    const prueba = {}
    const claves = Object.keys(Data)
    for (let i = 0; i < claves.length; i += 1) {
      const clave = claves[i]
      if (clave === 'service') {
        prueba.service = Data[clave]
      } else if (clave === 'serviceName') {
        prueba.serviceName = Data[clave]
      } else {
        prueba[clave] = converSwitch(Data[clave], clave)
      }
    }
    return prueba
  }

  const loadData = (data) => {
    let listMethods = []
    setInitialDate(data)
    buildData = data.map((state) => {
      const newObj = converObje(state)
      return newObj
    })
    listMethods = Object.keys(data[0])
    listMethods.splice(0, 1)
    const buildColumns = listMethods.map((item) => {
      const newColum = {
        name: `${item}`,
        label: `${item}`,
        options: {
          setCellProps: () => ({ style: { 'text-align': 'center' } }),
          setCellHeaderProps: () => ({
            style: {
              'text-align': 'center',
              width: '11%',
              'font-family': 'Red Hat Display Black',
              'font-size': '14px',
            },
          }),
          filter: true,
          sort: false,
          filterOptions: { fullWidth: true },
        },
      }
      return newColum
    })
    setColumsData(buildColumns)
    setBuildData(buildData)
  }

  const getMethodEnabeds = () => {
    setIsLoading(true)
    getEnabledMethodsPlatform({ ServiceName: 'ALL' })
      .then((resMethods) => {
        loadData(resMethods.data.data)
        setIsLoading(false)
      })
      .catch((err) => {
        setAlert(
          showDialogAlert(true, '¡Advertencia!', `servicio no disponible en éste momento`, 'Cerrar')
        )
        setIsOpen(false)
      })
  }

  const getJWT = () => {
    setIsLoading(true)
    refreshToken()
      .then((res) => {
        localStorage.setItem('luegopago_data_token', res.data.data.token)
        getMethodEnabeds()
      })
      .catch((err) => {
        setAlert(
          showDialogAlert(
            true,
            '¡Advertencia!',
            `No es posible autenticar con el servicio de pagos`,
            'Cerrar'
          )
        )
      })
  }

  const loadNewPlatform = (platform) => {
    createPlatformNew({ serviceName: platform.name })
      .then((res) => {
        setAlert(
          showDialogAlert(true, '¡Advertencia!', `La plataforma se agregó con éxito`, 'Cerrar')
        )
        setIsOpen(false)
        getMethodEnabeds()
      })
      .catch((err) => {
        setAlert(
          showDialogAlert(true, '¡Advertencia!', `servicio no disponible en éste momento`, 'Cerrar')
        )
        setIsOpen(false)
      })
  }

  const updateMethodState = () => {
    updateMethodStateService(DataObject)
      .then((res) => {
        setAlert(
          showDialogAlert(
            true,
            '¡Advertencia!',
            `Los metodos de pago se actualizaron con éxito`,
            'Cerrar'
          )
        )
        setIsOpen(false)
        getMethodEnabeds()
      })
      .catch((err) => {
        setAlert(
          showDialogAlert(true, '¡Advertencia!', `servicio no disponible en éste momento`, 'Cerrar')
        )
        setIsOpen(false)
      })
  }

  const loadUpdateStatesPlatform = (platform) => {
    const newKey = 'serviceName'
    const oldKey = 'service'
    let updateplatformBuild = {}
    updateplatformData = InitialDate.find((item) => item.service === platform)
    updateplatformBuild = converObje(updateplatformData)
    updateplatformBuild[newKey] = updateplatformBuild[oldKey]
    delete updateplatformBuild[oldKey]
    setBuildOject(updateplatformBuild)
    setIsOpen(true)
    setOperationPerform('update')
    updateplatformData[newKey] = updateplatformData[oldKey]
    delete updateplatformData[oldKey]
  }

  useEffect(() => {
    getJWT()
  }, [])

  const initialColums = [
    {
      name: 'service',
      label: 'Plataforma',
      options: {
        setCellProps: () => ({ style: { 'text-align': 'left', 'font-size': '14px' } }),
        filter: true,
        sort: true,
        setCellHeaderProps: () => ({
          style: {
            'text-align': 'left',
            width: '23%',
            'font-family': 'Red Hat Display Black',
            'font-size': '14px',
          },
        }),
      },
    },
  ]
  const finalColums = [
    {
      name: 'actions',
      label: 'Acciones',
      options: {
        setCellProps: () => ({ style: { 'text-align': 'center' } }),
        setCellHeaderProps: () => ({
          style: {
            'text-align': 'center',
            width: '11%',
            'font-family': 'Red Hat Display Black',
            'font-size': '14px',
          },
        }),
        filterOptions: { fullWidth: true },
        filter: true,
        sort: false,
        customBodyRender: (e, tableMeta) => {
          return (
            <>
              <Button
                onClick={() => loadUpdateStatesPlatform(tableMeta.rowData[0])}
                color="linkedin"
                round
                justIcon
              >
                <EditIcon />
              </Button>
            </>
          )
        },
      },
    },
  ]
  const columns = initialColums.concat(ColumnsData).concat(finalColums)
  const options = {
    responsive: 'vertical',
    customToolbar: () => {
      return (
        <CustomToolbar
          icon={<AddIcon />}
          tooltip="Agregar nueva plataforma"
          onClick={() => {
            setIsOpen(!isOpen)
            setOperationPerform('create')
          }}
        />
      )
    },
    print: false,
    sort: false,
    download: false,
    viewColumns: true,
    selectableRows: 'none',
    filter: false,
    pagination: false,
    serverSide: true,
    search: false,
    columns: 'center',
    resizableColumns: false,
    sortThirdClickReset: true,
    sortDescFirst: true,
  }
  const handleCloseModal = () => {
    setIsOpen(false)
    getMethodEnabeds()
  }

  return (
    <>
      <BackDrop open={isLoading} />
      <Grid container xl={12} md={12} sm={12}>
        <Grid xs={11} md={11} sm={12} className={style.center}>
          <Typography variant="h6" style={{ color: '#6064FF', 'margin-top': '0px' }}>
            Formas de pago
          </Typography>
          <MUIDataTable options={options} columns={columns} data={BuildData} />
          <ModalContainer
            xl={8}
            md={6}
            sm={8}
            open={isOpen}
            onClose={handleCloseModal}
            title={`${
              OperationPerform === 'create'
                ? 'Crear nueva plataforma'
                : 'Enciende y apaga tus formas de pago'
            }`}
          >
            {OperationPerform === 'create' ? (
              <ModalCreatePlatform getNewPlatform={loadNewPlatform} onCancel={handleCloseModal} />
            ) : (
              <ModalUpdatePaymentMethodStatus
                buildData={BuildOject}
                onCancel={handleCloseModal}
                updateMethodState={updateMethodState}
              />
            )}
          </ModalContainer>
        </Grid>
        {alert}
      </Grid>
    </>
  )
}
