import React, { useEffect, useState } from 'react'

import { Checkbox, Grid, TextField, Typography } from '@material-ui/core'
import { Delete } from '@material-ui/icons'
import AddIcon from '@material-ui/icons/Add'
import CloseIcon from '@material-ui/icons/Close'
import EditIcon from '@material-ui/icons/Edit'
import axios from 'axios'
import MUIDataTable from 'mui-datatables'

import useStyles from './accountig.styles'

import SweetAlert from 'components/Alert/SweetAlert'
import BackDrop from 'components/BackDrop'
import Button from 'components/CustomButtons/Button'
import CustomToolbar from 'components/CustomToolbarMuiDatatable/CustomToolbarMuiDatatable'
import ModalCommisions from 'components/ModalCommisions'
import ModalContainer from 'components/ModalContainer'
import useCustomDialogAlert from 'components/useCustomDialogAlert'
import { API_COMMISIONS } from 'constants/urls'
import textLabels from 'utils/MUIDataTableTextLabels'

export default function Commisions() {
  const style = useStyles()
  const showDialogAlert = useCustomDialogAlert()

  const [data, setData] = useState([])
  const [alert, setAlert] = useState(null)
  const [isOpen, setIsOpen] = useState(null)
  const [totalCount, setTotalCount] = useState()
  const [pagination, setPagination] = useState({
    page: 1,
    perPage: 5,
    prevPage: '',
    nextPage: '',
  })
  const [searchText, setSearchText] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [commissionEdit, setCommissionEdit] = useState({})

  const getCommissions = async () => {
    setIsLoading(true)

    await axios
      .get(
        `${API_COMMISIONS}/${pagination.perPage}/${pagination.page}/${
          searchText === '' ? ' /' : searchText
        }`
      )
      .then((response) => {
        if (response.status === 200) {
          setTotalCount(response.data.pagination.totalCount)
          setData(response.data.data)
        }
        setIsLoading(false)
      })
      .catch((e) => {
        setIsLoading(false)
        console.log(e)
      })
  }

  useEffect(() => {
    getCommissions()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination]) // If pagination change update the component

  const setEdition = (item) => {
    setCommissionEdit(item)
    setIsOpen(true)
  }

  const cancelDelete = () => {
    setAlert(null)
  }

  const handleCloseModal = (commission) => {
    if (!commissionEdit && commission) {
      if (commission.name !== '' || commission.description !== '' || commission.value !== 0) {
        setAlert(
          <SweetAlert
            onConfirm={() => {
              setAlert(false)
              setIsOpen(false)
            }}
            onCancel={cancelDelete}
            buttonConfirmText="Aceptar"
            buttonColor="secondary"
            buttonCancelText="Volver"
            cancelStyle="error"
            title="Se perderá la información ingresada. ¿Está seguro de salir?"
          />
        )
      } else {
        setIsOpen(false)
      }
    } else {
      getCommissions()
      setIsOpen(false)
    }
  }

  const deleteCommision = async (id) => {
    await axios
      .delete(`${API_COMMISIONS}/${id}`)
      .then((response) => {
        if (response.status === 200) {
          getCommissions()
          setAlert(
            showDialogAlert(
              true,
              '¡Cambio exitoso!',
              `La comisión se eliminó con éxito`,
              'Cerrar',
              '',
              true
            )
          )
        }
      })
      .catch((e) => {
        console.log(e)
      })

    setTimeout(() => {
      setAlert(null)
    }, 500)
  }

  const notificationToResponse = (id) => {
    setAlert(
      <SweetAlert
        onConfirm={() => {
          deleteCommision(id)
        }}
        onCancel={cancelDelete}
        buttonConfirmText="Eliminar"
        buttonColor="success"
        buttonCancelText="Cancelar"
        confirmStyle="primary"
        cancelStyle="danger"
        title="¿Desea eliminar esta comisión?"
        focusCancelBtn
      />
    )
  }
  const handleChange = (e, tableMeta) => {
    const { rowData } = tableMeta
    const data = {
      id: rowData[0],
      name: rowData[1],
      description: rowData[2],
      value: rowData[3],
      code: rowData[4],
      isActive: !rowData[5],
    }
    setIsLoading(true)
    axios({
      method: 'put',
      url: `${API_COMMISIONS}`,
      data: data,
    })
      .then((response) => {
        if (response.status === 200) {
          getCommissions()
          setAlert(
            showDialogAlert(true, '¡Advertencia!', `La comisión se actualizó con éxito`, 'Cerrar')
          )
        } else {
          setIsLoading(false)
        }
      })
      .catch((e) => {
        setIsLoading(false)
        console.log(e)
      })
  }

  const columns = [
    { name: 'id', label: 'id', options: { display: false } },
    { name: 'name', label: 'Nombre' },
    {
      name: 'description',
      label: 'Descripción',
      options: {
        customBodyRender: (e, tableMeta) => {
          return <Typography component="p">{tableMeta.rowData[2]}</Typography>
        },
      },
    },
    {
      name: 'value',
      label: 'Valor ',
      options: {
        customBodyRender: (e, tableMeta) => {
          const text = `${tableMeta.rowData[3]} %`
          return <Typography component="p">{text}</Typography>
        },
      },
    },
    {
      name: 'code',
      label: 'Código',
    },
    {
      name: 'isActive',
      label: 'Activo ',
      options: {
        customBodyRender: (e, tableMeta) => {
          return (
            <Checkbox onChange={() => handleChange(e, tableMeta)} checked={tableMeta.rowData[5]} />
          )
        },
      },
    },
    {
      name: 'actions',
      label: 'Acciones',
      options: {
        customBodyRender: (e, tableMeta) => {
          return (
            <>
              <Button onClick={() => setEdition(tableMeta.rowData)} color="linkedin" round justIcon>
                <EditIcon />
              </Button>
              <Button
                color="pinterest"
                round
                justIcon
                onClick={() => notificationToResponse(tableMeta.rowData[0])}
              >
                <Delete />
              </Button>
            </>
          )
        },
      },
    },
  ]

  const onChangeRowsPerPage = (pageSize) => {
    setPagination({
      ...pagination,
      perPage: pageSize,
    })
  }

  const onChangePage = async (page) => {
    setPagination({
      ...pagination,
      page: page + 1,
      prevPage: page,
      nextPage: page + 2,
    })
    document.querySelector('#main').scrollTop = 0
  }

  const handleChangeSearch = (e) => {
    setSearchText(e.currentTarget.value)
  }

  const handlerSearch = async () => {
    if (!searchText || searchText === ' ') return false
    setIsLoading(true)
    await getCommissions()
    return true
  }

  const customSearchRender = (searchTexto, handleSearch, hideSearch) => {
    return (
      <Grid container alignItems="baseline">
        <TextField
          placeholder="Ingrese la comisión"
          type="text"
          id="searchText"
          onChange={handleChangeSearch}
          value={searchText}
          style={{ fontFamily: 'Red Hat Display Regular' }}
          className={style.inputSearchCommission}
        />

        <Grid>
          <Button className={style.btnSearchCommission} color="secondary" onClick={handlerSearch}>
            Buscar
          </Button>

          <Button color="pinterest" justIcon onClick={hideSearch} style={{ marginLeft: '.5rem' }}>
            <CloseIcon />
          </Button>
        </Grid>
      </Grid>
    )
  }

  return (
    <>
      <BackDrop open={isLoading} />
      <Grid container xl={12} md={12} sm={12}>
        <Grid xs={11} md={11} sm={12} className={style.center}>
          <MUIDataTable
            title={<Typography component="h6">LISTA DE COMISIONES</Typography>}
            options={{
              print: false,
              sort: false,
              selectableRows: 'none',
              download: false,
              viewColumns: false,
              customToolbar: () => {
                return (
                  <CustomToolbar
                    icon={<AddIcon />}
                    tooltip="Crear comisiones"
                    onClick={() => {
                      setIsOpen(!isOpen)
                      setCommissionEdit(null)
                    }}
                  />
                )
              },
              filter: false,
              responsive: 'scrollMaxHeight',
              textLabels,
              pagination: true,
              serverSide: true,
              rowsPerPage: pagination.perPage,
              rowsPerPageOptions: [5, 10, 20, 100],
              count: totalCount,
              onChangeRowsPerPage,
              onChangePage,
              customSearchRender,
            }}
            data={data}
            columns={columns}
          />
          <ModalContainer
            xl={8}
            md={6}
            sm={8}
            open={isOpen}
            onClose={handleCloseModal}
            title={`${commissionEdit ? 'Actualizar' : 'Crear'} comisión`}
          >
            <ModalCommisions toEdit={commissionEdit} onCancel={handleCloseModal} />
          </ModalContainer>
        </Grid>
        {alert}
      </Grid>
    </>
  )
}
