// apiConfig.js

import axios from 'axios'
import Cookies from 'js-cookie'

import { COOKIE_ACCESS_TOKEN_NAME } from 'constants/cookiesConsts'
import { routeBase } from 'constants/environments.constants'

const API_BASE_URL =
  routeBase[process.env.REACT_APP_ENVIRONMENT].returns || routeBase.default.returns

const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
})

export const getStatuses = async () => {
  try {
    const response = await axiosInstance.get('/Return/GetStatuses', {
      headers: {
        Authorization: `Bearer ${Cookies.get(COOKIE_ACCESS_TOKEN_NAME)}`,
      },
    })
    return response.data
  } catch (error) {
    console.error('Error al obtener los estados:', error)
    throw error
  }
}

export const getStatusesAdmin = async () => {
  try {
    const response = await axiosInstance.get('/Return/GetStatusesAdmin', {
      headers: {
        Authorization: `Bearer ${Cookies.get(COOKIE_ACCESS_TOKEN_NAME)}`,
      },
    })
    return response.data
  } catch (error) {
    console.error('Error al obtener el conteo de devoluciones:', error)
    throw error
  }
}

export const getByTabAndSellerId = async (request) => {
  try {
    const response = await axiosInstance.post('/Return/GetByTabAndSellerId', request, {
      headers: {
        Authorization: `Bearer ${Cookies.get(COOKIE_ACCESS_TOKEN_NAME)}`,
      },
    })
    return response.data
  } catch (error) {
    console.error('Error al obtener los items por pestaña y vendedor:', error)
    throw error
  }
}

export const changeStatuses = async (data) => {
  try {
    const response = await axiosInstance.patch('/Return/ChangeStatus', data, {
      headers: {
        Authorization: `Bearer ${Cookies.get(COOKIE_ACCESS_TOKEN_NAME)}`,
      },
    })
    return response.data
  } catch (error) {
    console.error('Error al cambiar el estado del item:', error)
    throw error
  }
}

export const getRefundPayments = async () => {
  try {
    const response = await axiosInstance.get('/RefundOptions/GetRefundPayments', {
      headers: {
        Authorization: `Bearer ${Cookies.get(COOKIE_ACCESS_TOKEN_NAME)}`,
      },
    })
    return response.data
  } catch (error) {
    console.error('Error al obtener los pagos de reembolso:', error)
    throw error
  }
}

export const sendRefundPayment = async (data) => {
  try {
    const response = await axiosInstance.post('/Return/SendRefundPayment', data, {
      headers: {
        Authorization: `Bearer ${Cookies.get(COOKIE_ACCESS_TOKEN_NAME)}`,
      },
    })
    return response.data
  } catch (error) {
    console.error('Error al enviar el pago de reembolso:', error)
    throw error
  }
}

export const replaceProduct = async (data) => {
  try {
    const response = await axiosInstance.post('/Return/ReplaceProduct', data, {
      headers: {
        Authorization: `Bearer ${Cookies.get(COOKIE_ACCESS_TOKEN_NAME)}`,
      },
    })
    return response.data
  } catch (error) {
    console.error('Error al reemplazar el producto:', error)
    throw error
  }
}

export const getReturnsCount = async () => {
  try {
    const response = await axiosInstance.get('/Return/GetRetunsCount', {
      headers: {
        Authorization: `Bearer ${Cookies.get(COOKIE_ACCESS_TOKEN_NAME)}`,
      },
    })
    return response.data
  } catch (error) {
    console.error('Error al obtener el conteo de devoluciones:', error)
    throw error
  }
}

export const postNote = async (body) => {
  try {
    const { data } = await axiosInstance.post('/Return/AddNote', body, {
      headers: {
        Authorization: `Bearer ${Cookies.get(COOKIE_ACCESS_TOKEN_NAME)}`,
      },
    })

    return data
  } catch (error) {
    console.error('Error al agregar observación:', error)
    throw error
  }
}

export const changeStatusWithoutAction = async (data) => {
  try {
    const response = await axiosInstance.patch('/Return/ChangeStatusWithoutAction', data, {
      headers: {
        Authorization: `Bearer ${Cookies.get(COOKIE_ACCESS_TOKEN_NAME)}`,
      },
    })
    return response.data
  } catch (error) {
    console.error('Error al cambiar el estado del item:', error)
    throw error
  }
}

export const requestMoreInformation = async (data) => {
  try {
    const response = await axiosInstance.post('/Return/RequestMoreInformation', data, {
      headers: {
        Authorization: `Bearer ${Cookies.get(COOKIE_ACCESS_TOKEN_NAME)}`,
      },
    })
    return response.data
  } catch (error) {
    console.error('Error al solicitar mas información', error)
    throw error
  }
}
