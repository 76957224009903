import { green } from '@material-ui/core/colors'

const styles = (theme) => ({
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    marginTop: 3,
  },
  input: {
    width: '100%',
    marginTop: 0,
    marginBottom: 19,

    '&:input': {
      padding: 5,
    },
  },

  labelInputCurrent: {
    marginBottom: 8,
    marginRight: '401px',
  },
  labelInputPassword: {
    marginBottom: 8,
    marginRight: '401px',
  },
  labelInputConfirm: {
    marginBottom: 8,
    marginRight: '316px',
  },
  divButtons: {
    marginTop: 15,
    columnGap: 15,
  },
  textConfirmPassword: {
    marginTop: 15,
    textAlign: 'center',
    mrginBottom: '20px',
  },
  btnBackgroundPrimary: {
    width: '248px',
    height: '56px',
    borderRadius: '50px',
    border: 'none',
    margin: '20px auto',
    backgroundColor: '#5e44ff',
    color: 'white',
    fontWeight: '500',
    '&:hover': {
      fontWeight: 'bolder',
      backgroundColor: '#ef0bef',
      boxShadow: '-7px 7px 0px #000000',
      border: '2px solid #000000',
      borderRadius: '50px',
      opacity: '1',
    },

  },
  topInputLabel: {
    textAlign: 'left',
    marginBottom: 5,
  },

  spacing: {
    marginTop: '5%',
  },

  widthFull: {
    width: '100%',
  },
})

export default styles
