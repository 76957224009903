import {
  API_UPDATE_MASSIVE_PRODUCTS,
  MICROSERVICES_PRODUCTS,
  API_DELETE_MASSIVE_PRODUCTS,
} from 'constants/urls'
import {
  postApproveAndRejectProducts,
  deleteProductById,
  getProductsAdmin,
} from 'fetchAxios/productsSellerAdmin/product.sellerAdmin'

const approve = async (productList, type, idSeller) => {
  const url = `${API_UPDATE_MASSIVE_PRODUCTS}?sellerIdRequest=${idSeller}`
  const result = await postApproveAndRejectProducts(productList, url)
  let message = ''
  if (result && (result.status === 200 || result.status === 201)) {
    message =
      type === 0
        ? `¡Se aprobó correctamente ${productList.length > 1 ? 'los productos!' : 'el producto!'}`
        : '¡Se rechazó correctamente el producto!'
  }
  return { result, message }
}

const approvePending = async (productProperties) => {
  const url = `${MICROSERVICES_PRODUCTS}/approvePendingChanges`
  const result = await postApproveAndRejectProducts(productProperties, url)
  let message
  if (result) {
    message =
      productProperties.length > 1
        ? '¡Se aprobaron correctamente los productos!'
        : '¡Se aprobó correctamente el producto!'
  }

  return { result, message }
}

const rejectPendingPending = async (productProperties, value) => {
  const productRejects = productProperties.map((product) => {
    return {
      productId: product?.id ? product.id : product,
      description: value,
    }
  })
  const url = `${MICROSERVICES_PRODUCTS}/rejectPendingChanges`
  const result = await postApproveAndRejectProducts(productRejects, url)
  let message = ''
  if (result.status === 200) {
    message = `${
      productRejects.length > 1 ? '¡Se rechazaron los productos!' : '¡Se rechaza el producto!'
    }`
  }
  return { result, message }
}
const updateMassive = async (productProperties, idSeller) => {
  const url = `${API_UPDATE_MASSIVE_PRODUCTS}?sellerIdRequest=${idSeller}`
  const result = await postApproveAndRejectProducts(productProperties, url)
  let message = ''
  if (result.status === 200 || result.status === 201) {
    message = 'Se actualizó correctamente el producto'
  }
  return { result, message }
}

const deleteMassive = async (productProperties, idSeller) => {
  const url = `${API_DELETE_MASSIVE_PRODUCTS}?sellerIdRequest=${idSeller}`
  const result = await postApproveAndRejectProducts(productProperties, url)
  let message = ''
  if (result.status === 200 || result.status === 201) {
    message = 'Se eliminaron los productos correctamente'
  }
  return { result, message }
}
const deleteById = async (productsList, idSeller) => {
  const url = `${MICROSERVICES_PRODUCTS}/${productsList}?sellerIdRequest=${idSeller}`
  const result = await deleteProductById(productsList, url)
  let message = ''
  if (result.status === 200 || result.status === 204) {
    message = `El producto se eliminó correctamente`
  } else {
    message = `El producto no se eliminó correctamente`
  }
  return { result, message }
}
const getProducts = async (type, filtered, idSeller, page, perPage, text) => {
  let url = ''
  if (type === 10) {
    url = `${MICROSERVICES_PRODUCTS}/GetModifiedProducts/?sellerId=${idSeller}&pageNumber=${page}&pageSize=${perPage}`
  } else if (type || filtered.typeFilter) {
    if (text !== '') {
      url = `${MICROSERVICES_PRODUCTS}/?Page=${page}&PageSize=${perPage}&sellerid=${idSeller}&FilterBy=${type}&SearchBy=${text}`
    } else if (type === 11) {
      url = `${MICROSERVICES_PRODUCTS}/?page=${page}&pagesize=${perPage}&sellerid=${idSeller}`
    } else {
      url = `${MICROSERVICES_PRODUCTS}/?page=${page}&pagesize=${perPage}&sellerid=${idSeller}&FilterBy=${5}`
    }
  }

  const result = await getProductsAdmin(url)
  if (result.status === 200) {
    if (result.data?.data && result.data?.data.length > 0) {
      result.data.data.sort(
        (a, b) => new Date(b.dateCreated).getTime() - new Date(a.dateCreated).getTime()
      )
      result.data.data.sort(
        (a, b) => new Date(b.dateModified).getTime() - new Date(a.dateModified).getTime()
      )
    }
    return result
  }
}

export {
  approve,
  approvePending,
  rejectPendingPending,
  updateMassive,
  deleteMassive,
  deleteById,
  getProducts,
}
