import React, { createContext, useState } from 'react'

export const ItinerariesContext = createContext({
  loadFilters: () => {},
  filters: [],
  loadStateItineraries: () => {},
  allItineraries: [],
})

const ItinerariesContextProvider = ({ children }) => {
  const [filters, setFilters] = useState([])
  const [allItineraries, setAllItineraries] = useState([])

  const loadFilters = (newfilters) => {
    setFilters(newfilters)
  }

  const loadStateItineraries = (Itineraries) => {
    setAllItineraries(Itineraries)
  }

  return (
    <ItinerariesContext.Provider
      value={{ filters, loadFilters, loadStateItineraries, allItineraries }}
    >
      {children}
    </ItinerariesContext.Provider>
  )
}

export default ItinerariesContextProvider
