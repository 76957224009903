import React, { useState, useEffect } from 'react'

import { Grid, FormGroup, TextField, CircularProgress, InputLabel } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import Autocomplete from '@material-ui/lab/Autocomplete'
import axios from 'axios'
import { useForm } from 'react-hook-form'

import useStyles from './accountig.styles'

import BackDrop from 'components/BackDrop'
import Card from 'components/Card/Card'
import CardBody from 'components/Card/CardBody'
import Button from 'components/CustomButtons/Button'
import GridContainer from 'components/Grid/GridContainer'
import ListCategoriesCommissions from 'components/ListCategoriesCommissions'
import ListCommissions from 'components/ListCommissions'
import useCustomDialogAlert from 'components/useCustomDialogAlert'
import { API_SELLERS, API_CATEGORIES, API_CATEGORIES_SELLER, API_DAYPAY } from 'constants/urls'
import { filterArrayNulls } from 'utils/functions'
import { onlyNumber } from 'utils/utils'

export default function ConfigSeller() {
  const classes = useStyles()
  const [isLoading, setIsLoading] = useState(false)
  const [dayPay, setDayPay] = useState({
    sellerId: '',
    days: '',
  })
  const [commissionCap, setCommissionCap] = useState('')
  const [dataCategories, setDataCategories] = useState([])
  const [sellers, setSellers] = useState([])
  const [idSeller, setIdSeller] = useState({
    id: '',
  })
  const pagination = {
    page: 1,
    pageSize: 10,
    typeFilter: 1,
    perPage: 10,
    prevPage: '',
    nextPage: '',
  }
  const [isSearch, setIsSearch] = useState(false)
  const showDialogAlert = useCustomDialogAlert()
  const getSellers = async (total = 30000) => {
    await axios
      .get(`${API_SELLERS}?sellerFilterType=1&pageSize=${total}`)
      .then((response) => {
        setIsLoading(false)
        if (response) {
          // eslint-disable-next-line radix
          if (response.data.pagination.totalCount > parseInt(total)) {
            getSellers(response.pagination.totalCount)
          } else {
            const filterData = filterArrayNulls(response.data.data, 'userName')
            setSellers(filterData)
          }
        }
      })
      .catch(() => {
        setIsLoading(false)
      })
  }
  const getDayPay = async () => {
    await axios
      .get(`${API_DAYPAY}/${idSeller.id}`)
      .then((response) => {
        if (
          response.status === 400 &&
          response?.errors[0] === 'No hay configuracion para este vendedor.'
        ) {
          showDialogAlert(
            true,
            '¡Advertencia!',
            `No hay configuracion para este vendedor.`,
            'Cerrar'
          )
        }

        if (response) {
          setDayPay({
            sellerId: response.data.sellerId,
            days: response.data.value,
          })
        }
      })
      .catch(() => {
        setIsLoading(false)
        setDayPay({
          sellerId: 0,
          days: '',
        })
      })
  }
  const {
    register,
    handleSubmit,
    setValue,
    errors,
    formState: { isValid },
  } = useForm({
    mode: 'onChange',
  })

  const getSellerRanking = (sellerId) => {
    setIsLoading(true)
    axios
      .get(`${API_SELLERS}/GetSellerRating?sellerId=${sellerId}`)
      .then(({ data: { data } }) => {
        setIsLoading(false)
        setValue('sellerRanking', data.rating)
      })
      .catch(() => {
        setIsLoading(false)
      })
  }

  const changeSellerRanking = (data) => {
    setIsLoading(true)
    const body = {
      sellerId: idSeller.id,
      rating: Number(data.sellerRanking),
    }
    axios
      .post(`${API_SELLERS}/SetSellerRating`, body)
      .then((response) => {
        if (response) {
          setIsLoading(false)
          showDialogAlert(
            true,
            '¡Cambio exitoso!',
            `Se ha actualizado correctamente`,
            'Cerrar',
            '',
            true
          )
        }
      })
      .catch(() => {
        setIsLoading(false)
      })
  }
  const getCategoriesSeller = async (pageSize = null, page = null) => {
    await axios
      .get(
        `${API_CATEGORIES_SELLER}/${idSeller.id}?pageSize=${pageSize || pagination.pageSize}&page=${
          page || pagination.page
        }`
      )
      .then((response) => {
        if (response) {
          setDataCategories(response.data.data)
          setIsSearch(true)
          getSellerRanking(idSeller.id)
          setIsLoading(false)
        }
      })
      .catch(() => {
        setIsLoading(false)
      })
  }
  const getCategories = () => {
    setIsLoading(true)
    axios
      .get(`${API_CATEGORIES}`)
      .then(() => {
        setIsLoading(false)
      })
      .catch(() => {
        setIsLoading(false)
      })
  }

  useEffect(() => {
    getCategories()
    getSellers()
  }, [])

  const searchSellerInfo = async () => {
    if (idSeller.id === '') {
      showDialogAlert(true, '¡Advertencia!', `No ha seleccionado el seller`, 'Cerrar')
      return false
    }
    setIsLoading(true)
    getCategoriesSeller()
    getDayPay()
    return true
  }

  const onsubmitDayPay = async (e) => {
    e.preventDefault()
    if (idSeller.id === '') {
      showDialogAlert(true, '¡Advertencia!', `No ha seleccionado el seller`, 'Cerrar')
      return false
    }
    if (!dayPay.days) {
      showDialogAlert(true, '¡Advertencia!', `Asigne un valor de Días de Pago`, 'Cerrar')
      return false
    }
    const res = await axios.post(`${API_DAYPAY}`, {
      sellerId: idSeller.id,
      days: dayPay.days,
    })
    if (res) {
      if (res.status === 200 || res.status === 201) {
        showDialogAlert(
          true,
          '¡Cambio exitoso!',
          `Se ha actualizado correctamente`,
          'Cerrar',
          '',
          true
        )
        searchSellerInfo()
      }
    }
    return true
  }

  const onSubmitCommissionCap = async (e) => {
    e.preventDefault()
    console.log(commissionCap)
    if (idSeller.id === '') {
      showDialogAlert(true, '¡Advertencia!', `No ha seleccionado el seller`, 'Cerrar')
      return false
    }
    if (!commissionCap) {
      showDialogAlert(true, '¡Advertencia!', `Asigne un valor para el tope de comisión`, 'Cerrar')
      return false
    }
    return true
  }

  return (
    <>
      <Grid container justify="center">
        <BackDrop open={isLoading} />
        <Grid item md={11} xs={12}>
          <Card className={classes.card}>
            <CardBody>
              <GridContainer container spacing={10}>
                <FormGroup style={{ marginLeft: '30px', width: '60%' }}>
                  <Autocomplete
                    id="seachSeller"
                    options={sellers}
                    getOptionLabel={(option) => (option.name ? option.name : option.email)}
                    style={{ width: 'auto' }}
                    autoComplete
                    includeInputInList
                    renderInput={(params) => (
                      <>
                        <InputLabel style={{ fontFamily: 'Red Hat Display Bold', marginBottom: '10px' }}>
                          Buscar seller para hacer negociación:
                        </InputLabel>
                        <TextField {...params} variant="outlined" fullWidth />
                      </>
                    )}
                    onChange={(event, NewValue) => {
                      event.preventDefault()
                      setCommissionCap('')
                      if (NewValue?.id) {
                        setIdSeller({ ...idSeller, id: NewValue.id })
                      } else {
                        setIdSeller({ ...idSeller, id: '' })
                      }
                    }}
                  />
                </FormGroup>
                <Button
                  disabled={isLoading}
                  onClick={(e) => {
                    e.preventDefault()
                    searchSellerInfo()
                  }}
                  className={`${classes.btnPrimary} ${classes.btnMargin}`}
                  style={{ marginTop: '22px' }}
                >
                  <SearchIcon />
                </Button>
                {isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
              </GridContainer>
            </CardBody>
          </Card>
        </Grid>
        {/* start productos table */}
        {isSearch ? (
          <>
            <Grid item xs={12} md={11}>
              <Grid
                container
                direction="row"
                justifyContent="space-evenly"
                style={{ marginTop: '2%' }}
                alignItems="flex-start"
                spacing={1}
              >
                <Grid item xs={12} md={4}>
                  <Card className={classes.card}>
                    <CardBody>
                      <Grid container direction="row" justify="center" alignItems="center">
                        <Grid item xs={12}>
                          <InputLabel htmlFor="height">Días de pago:</InputLabel>
                          <TextField
                            fullWidth
                            type="text"
                            id="days"
                            min={0}
                            max={365}
                            variant="outlined"
                            style={{ marginTop: '15px' }}
                            onChange={(e) => {
                              if (onlyNumber(e.target.value)) {
                                if (e.target.value > 365) return false
                                setDayPay({ days: e.target.value })
                                return true
                              }
                              return false
                            }}
                            value={dayPay.days}
                          />
                        </Grid>
                        <Grid item xl={8} md={12} sm={12} xs={12}>
                          <Button
                            style={{ marginTop: '20px', float: 'right' }}
                            className={classes.btnPrimary}
                            onClick={(e) => onsubmitDayPay(e)}
                          >
                            Actualizar
                          </Button>
                        </Grid>
                      </Grid>
                    </CardBody>
                  </Card>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Card className={classes.card}>
                    <CardBody>
                      <Grid container direction="row" justify="center" alignItems="center">
                        <Grid item xs={12}>
                          <InputLabel htmlFor="height">Tope de Comisión:</InputLabel>
                          <TextField
                            fullWidth
                            type="text"
                            id="tope_de_comision"
                            min={0}
                            variant="outlined"
                            style={{ marginTop: '15px' }}
                            onChange={(e) => {
                              if (onlyNumber(e.target.value)) {
                                setCommissionCap(e.target.value)
                                return true
                              }
                              return false
                            }}
                            value={commissionCap}
                          />
                        </Grid>
                        <Grid item xl={6} md={12} sm={12} xs={12}>
                          <Button
                            style={{ marginTop: '20px', float: 'right' }}
                            className={classes.btnPrimary}
                            onClick={(e) => onSubmitCommissionCap(e)}
                          >
                            Actualizar
                          </Button>
                        </Grid>
                      </Grid>
                    </CardBody>
                  </Card>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Card className={classes.card}>
                    <CardBody>
                      <Grid container direction="row" justify="center" alignItems="center">
                        <form
                          onSubmit={handleSubmit(changeSellerRanking)}
                          style={{ width: '100%' }}
                        >
                          <Grid item xs={12}>
                            <InputLabel htmlFor="sellerRanking">Puntuación del seller:</InputLabel>
                            <TextField
                              fullWidth
                              type="number"
                              id="sellerRanking"
                              variant="outlined"
                              style={{ marginTop: '15px' }}
                              name="sellerRanking"
                              inputRef={register({
                                validate: {
                                  between: (value) => {
                                    return (
                                      (value >= 0 && value <= 100) ||
                                      'La puntuacion debe estar entre 0 y 100%'
                                    )
                                  },
                                  required: (value) => value >= 0 || 'La puntuacion es requerido',
                                },
                              })}
                            />
                            <span
                              style={{
                                color: 'red',
                                fontSize: '12px',
                                fontStyle: 'italic',
                              }}
                            >
                              {errors?.sellerRanking && errors.sellerRanking.message}
                            </span>
                          </Grid>
                          <Grid item xl={6} md={12} sm={12} xs={12}>
                            <Button
                              disabled={!isValid}
                              style={{ marginTop: '20px', float: 'right' }}
                              className={classes.btnPrimary}
                              type="submit"
                            >
                              Actualizar
                            </Button>
                          </Grid>
                        </form>
                      </Grid>
                    </CardBody>
                  </Card>
                </Grid>
              </Grid>
            </Grid>

            <Grid container justify="center" spacing={4}>
              <Grid item md={11} xs={12}>
                <ListCategoriesCommissions
                  idSeller={idSeller}
                  setIsSearch={setIsSearch}
                  dataCategories={dataCategories}
                  setDataCategories={setDataCategories}
                />
              </Grid>
              <Grid item md={11} xs={12}>
                <ListCommissions idSeller={idSeller} />
              </Grid>
            </Grid>
          </>
        ) : null}
      </Grid>
    </>
  )
}
