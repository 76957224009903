import React, { useState } from 'react'

import { Button, InputBase, Paper, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import ClearIcon from '@material-ui/icons/Clear'
import SearchIcon from '@material-ui/icons/Search'

import FilterButtons from 'components/FilterButtons'

const useStyles = makeStyles((theme) => ({
  divBuscador: {
    border: `1px solid ${theme.palette.primary.main}`,
    display: 'flex',
    padding: '2px 4px',
    width: 400,
    height: 45,
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      width: 240,
    },
    [theme.breakpoints.down('md')]: {
      width: 250,
    },
  },
  input: {
    flex: 1,
    marginLeft: theme.spacing(1),
    fontSize: '14px',
    fontFamily: 'Red Hat Display Regular',
  },
}))
const FiltersItineraries = ({ runFilter, activeFilter, filters, dispatchValue }) => {
  const classes = useStyles()
  const [valueToLook, setValueToLook] = useState('')
  const getFilterButtons = () => {
    return filters.map((f, i) => {
      return (
        <FilterButtons
          key={i + 1}
          label={`${f.quantity} ${f.description}`}
          onClick={() => {
            runFilter(f.name)
          }}
          active={activeFilter === f.name}
        />
      )
    })
  }

  return (
    <div className="lupa_seller_filtersitinerary_container">
      <div className="lupa_seller_filtersitinerary_secFilters">
        <Typography variant="subtitle1" style={{ marginLeft: '5px' }}>
          Filtrar por:
        </Typography>
        <div className="lupa_seller_filtersitinerary_theFilters">{getFilterButtons()}</div>
      </div>
      <div className="lupa_seller_filtersitinerary_secSearch">
        <Paper
          onKeyUp={(e) => {
            e.preventDefault()
            if (e.key === 'Enter' && valueToLook !== '') {
              dispatchValue(valueToLook)
            }
          }}
          className={classes.divBuscador}
        >
          <InputBase
            autoFocus
            type="number"
            value={valueToLook}
            placeholder="Número de itinerario"
            className={classes.input}
            onChange={(event) => {
              setValueToLook(event.target.value.trimStart())
            }}
            onKeyPress={(event) => {
              return event.key.match(/[0-9]/) && valueToLook.length < 7
                ? true
                : event.preventDefault()
            }}
            inputProps={{ maxlength: '7', type: 'number' }}
          />
          {valueToLook !== '' && (
            <ClearIcon
              style={{ marginRight: '2px', cursor: 'pointer' }}
              onClick={() => {
                setValueToLook('')
                dispatchValue('clear')
              }}
            />
          )}
          <Button
            color="primary"
            size="small"
            startIcon={<SearchIcon />}
            onClick={(e) => {
              e.preventDefault()
              if (valueToLook !== '') {
                dispatchValue(valueToLook)
              }
            }}
          >
            Buscar
          </Button>
        </Paper>
      </div>
    </div>
  )
}

export default FiltersItineraries
