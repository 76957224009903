import axios from 'axios'

import {
  TRAVEL_API_CREATE_TOKEN,
  TRAVEL_API_GET_ITINERARIES,
  TRAVEL_API_USER,
  TRAVEL_API_PASSWORD,
  TRAVEL_API_CHANGE_STATUS_ITINERARY,
} from 'constants/urls'

const useServiceTravel = {
  getJwt() {
    return axios.post(TRAVEL_API_CREATE_TOKEN, {
      user: TRAVEL_API_USER,
      password: TRAVEL_API_PASSWORD,
    })
  },
  getItineraries(category) {
    return axios.get(`${TRAVEL_API_GET_ITINERARIES}${category}`)
  },
  itineraryChangeStatus(id, newState) {
    return axios.post(`${TRAVEL_API_CHANGE_STATUS_ITINERARY}`, {
      status: newState,
      itineraryTravelDataId: id,
    })
  },
}

export default useServiceTravel
