import { makeStyles } from '@material-ui/core/styles'

import buttonStyle from 'assets/jss/material-dashboard-pro-react/components/buttonStyle'
import switchStyles from 'assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch'

export default makeStyles((theme) => ({
  ...switchStyles,
  root: {
    flexGrow: 1,
  },
  rootChips: {
    listStyle: 'none',
    margin: 0,
    marginTop: 4,
  },
  chipsAttributes: {
    marginBottom: '3px',
  },

  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  formControl: {
    margin: theme.spacing(1),
    width: '200px ',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  inputBorder: {
    borderColor: '#FF0000 !important',
    borderWidth: '1px !important',
  },
  gridPriceInputs: {
    maxWidth: 600,
    display: 'flex',
    flexWrap: 'wrap',
    gap: '20px',
  },
  button: {
    margin: theme.spacing(1),
  },
  buttonAction: {
    marginLeft: 10,
  },
  ...buttonStyle,
  chip: {
    paddingTop: 17,
    paddingBottom: 17,
    marginLeft: 30,
    fontWeight: 'bold',
    fontSize: 15,
  },
  avatar: {
    padding: 17,
  },
  card: {
    minHeight: 100,
    padding: 20,
  },
  alert: { position: 'fixed', zIndex: 1, marginBottom: 20 },
  gridList: {
    flexWrap: 'nowrap',
    width: '100%',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
  },
  gridListIndividual: {
    width: '100%',
  },
  iconDelete: {
    color: 'rgba(244, 67, 54)',
  },
  rootList: {
    width: '850px',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    backgroundColor: theme.palette.background.paper,
  },
  rootListIndividual: {
    display: 'flex',
    flexWrap: 'wrap',
    backgroundColor: theme.palette.background.paper,
    marginTop: '4%',
    width: '100%',
  },
  input: {
    display: 'none',
  },
  imageProduct: {
    width: '150px',
    marginLeft: '25%',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  cardList: {
    flexWrap: 'nowrap',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
  },
  imageTitle: {
    color: 'white',
  },
  inputTop: {
    marginTop: '20px',
    minHeight: 'calc(100vh - 311px)',
  },
  gridAtributtes: {
    width: '90%',
    // marginLeft: '4%',
  },
  tableContainer: {
    width: 'auto',
  },
  rootDiv: {
    width: '100%',
    marginTop: '15px',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  typeBotton: {
    marginBottom: 19,
  },
  btnNewProduct: {
    minWidth: 246,
    height: 48,
    fill: theme.palette.white.main,

    '&:hover': {
      background: theme.palette.primary.main,
    },
  },
  btnBackgroundPrimary: {
    minWidth: 180,
    maxWidth: 246,
    fill: theme.palette.white.main,
    marginRight: 5,
    marginLeft: 5,
    '&:hover': {
      background: theme.palette.primary.main,
    },
  },
  btnTemplate: {
    fill: theme.palette.white.main,
    marginRight: 5,
    marginLeft: 5,
    '&:hover': {
      background: theme.palette.primary.main,
    },
    width: '80%',
  },
  btnBackgroundAssociation: {
    minWidth: 180,
    maxWidth: 246,
    marginTop: 19,
    fill: theme.palette.white.main,
    marginRight: 5,
    marginLeft: 5,
    '&:hover': {
      background: theme.palette.primary.main,
    },
  },
  btnBackgroundError: {
    minWidth: 180,
    color: theme.palette.white.main,
    background: theme.palette.grey.dark,
    '&:hover': {
      background: theme.palette.error.main,
      color: theme.palette.white.main,
    },
  },
  btnBackgroundErrorPrice: {
    color: theme.palette.white.main,
    background: theme.palette.grey.dark,
    '&:hover': {
      background: theme.palette.error.main,
      color: theme.palette.white.main,
    },
  },
  btnBackgroundGreen: {
    minWidth: 180,
    fill: theme.palette.white.main,

    '&:hover': {
      background: theme.palette.green.main,
    },
  },
  spanError: {
    color: theme.palette.error.main,
  },
  gridButtons: {
    marginTop: '30vh',
    gap: '20px',
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(4),
    minHeight: 'calc(100vh - 311px)',
  },
  cardImgTop: {
    width: '100%',
    borderTopLeftRadius: 'calc(.25rem - 1px)',
    borderTopRightRadius: 'calc(.25rem - 1px)',
  },
  cardImgBottom: {
    width: '100%',
    borderBottomRightRadius: 'calc(.25rem - 1px)',
    borderBottomLeftRadius: 'calc(.25rem - 1px)',
  },
  cardImgOverlay: {
    position: 'absolute',
    top: '0',
    right: '0',
    bottom: '0',
    left: '0',
    padding: '1.25rem',
  },
  cardImg: {
    width: '100%',
    borderRadius: 'calc(.25rem - 1px)',
  },
  cardImages: {
    width: 200,
    height: 200,
  },
  cardImageEmpty: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.palette.grey.dark,
    fill: theme.palette.grey.dark,
    width: 200,
    height: 200,
    padding: '66px 42px',
    boxShadow: '0px 3px 6px #0000001a',
    borderRadius: 12,
  },
  divWarehouseDetailProduct: {
    width: '100%',
  },
  labelVariable: {
    margin: 0,
    fontSize: '0.75rem',
  },
  optionsCard: {
    width: 10,
  },
  buttonOption: {
    minWidth: '40px',
    padding: 0,
  },
  divLoadImages: {
    minWidth: '20px',
    marginBottom: 20,
    marginRight: 60,
    marginLeft: 40,
  },

  titleManageImages: {
    fontWeight: 'bold',
    marginBottom: '15px',
    fontSize: '15px',
  },
  priceTitle: {
    width: '100%',
  },
  descriptionProduct: {
    width: '100%',
  },
  gridContainer: {
    marginTop: 10,
    marginBottom: 2,
    with: '40%',
  },
  girdInfoProduct: {
    marginTop: 10,
    marginBottom: 2,
  },
  formGroupPriceStep: {
    width: 290,
  },
  formeditPriceProduct: {
    padding: '15px',
  },
  formGroupPriceStepCheckBox: {
    width: 290,
    display: 'flex',
    flexDirection: 'columns',
    justifyContent: 'center',
  },
  imageLinearGrid: {
    paddingRight: 45,
  },
  descriptionInfoBasic: {
    borderRadius: 6,
    minHeight: 240,
    maxHeight: 240,
    width: '100%',
    marginTop: 5,
    resize: 'none',
  },
  autocompleteDetailProduct: {
    marginTop: 0,
  },
  labelSize: {
    fontSize: 15,
    marginBottom: 8,
  },
  typeValue: {
    marginTop: 20,
  },
  buttonCalculate: {
    width: 246,
  },
  detailSend: {
    font: 'normal normal bold 16px/25px Poppins',
    textAlign: 'left',
  },
  formInput: {
    width: '45%',
    padding: 0,
    marginBottom: 10,
  },
  formInputAttributes: {
    width: '100%',
    padding: 0,
    marginBottom: 10,
  },
  formInputInfo: {
    width: '25%',
    padding: 0,
    margin: '0px 5px 10px 0px',
  },
  headerDetail: {
    width: '45%',
    [theme.breakpoints.down('md')]: {
      width: '95%',
    },
  },
  headerDetailInfo: {
    marginTop: 12,
  },
  buttonAddPart: {
    marginTop: 15,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  adjustTable: {
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
  },
  ProductPartTypo: {
    width: '95%',
    marginBottom: 8,
    fontWeight: '700',
  },

  autocompletePadding: {
    padding: '0 4px !important',
  },
  detailContent: {
    width: '100%',
    marginBottom: 20,
  },
  justifyContent: {
    display: 'contents',
  },
  rootStepIcon: {
    color: '#E8E8E8',
  },
  active: {
    color: 'black !important',
  },
  completed: {
    color: `${theme.palette.primary.green} !important`,
  },
  text: {
    fill: theme.palette.white.main,
  },
  labelStepLabel: {
    color: '#E8E8E8',
  },
  activeStepLabel: {
    color: 'black !important',
  },
  completedStepLabel: {
    color: `${theme.palette.primary.green} !important`,
  },
  iconButton: {
    background: theme.palette.secondary.light,
    width: 50,
    height: 50,
    fill: theme.palette.white.main,
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  divButtonsHeader: {
    display: 'flex',
    flexWrap: 'nowrap',
    columnGap: '10px',
  },
  divContent: {
    marginTop: 10,
  },
  divTop: {
    marginTop: '2%',
  },
  addAtributtes: {
    marginRight: '2%',
    marginLeft: '2%',
  },
  modalVariationForm: {
    width: '50%',
    height: '70%',
  },
  formVariant: {
    height: '100%',
  },
  center: {
    margin: ' 0 auto 0 auto',
  },
  variantContent: {
    height: '85%',
    display: 'flex',
    flexDirection: 'column',
  },
  divAtributtesSelection: {
    overflowY: 'auto',
    maxHeight: '418px',
    display: 'grid',
    width: '100%',
    gridTemplateColumns: 'repeat(1, 1fr)',
    rowGap: '10px',
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
  },
  btnApply: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.white.main,
    width: 200,
    marginLeft: 10,
    height: 38,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  divAtributtesForm: {
    display: 'grid',
    width: '60%',
    gridTemplateColumns: 'repeat(3, 1fr)',
    rowGap: '10px',
  },
  divListVariations: {
    width: '70%',
    marginLeft: 40,
    marginTop: '-1%',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      marginTop: '5%',
    },
  },
  divLoadAttributes: {
    minWidth: 270,
    maxWidth: 280,
    marginBottom: 20,
    marginRight: 60,
    marginLeft: 40,
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  containerAttrVariations: {
    flexWrap: 'noWrap',
    [theme.breakpoints.down('md')]: {
      flexWrap: 'Wrap',
    },
  },
  dividerVerticalAttributes: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },

  imgMiniProduct: {
    width: 44,
    height: 44,
    borderRadius: '50%',
  },
  containerImages: {
    gap: '30px',
    paddingLeft: 15,
    width: 700,
  },
  textNewImage: {
    fontSize: 14,
    color: theme.palette.grey.dark,
    textAlign: 'center',
  },
  InfoProductWrap: {
    flexWrap: 'wrap',
  },
  formInputDivider: {
    width: '100%',
    height: '2px',
    fontWeight: '100',
    margin: '15px 0 27px 0',
  },
  btnDelete: {
    '&:hover': {
      background: theme.palette.error.main,
      fill: theme.palette.white.main,
    },
  },
  btnEdit: {
    '&:hover': {
      background: theme.palette.primary.main,
      fill: theme.palette.white.main,
    },
  },
  divAddUrl: {
    marginTop: 15,
  },
  divListImages: {
    marginLeft: 40,
  },
  labelInput: {
    marginBottom: 8,
  },
  downloadButton: {
    fill: theme.palette.grey.dark,
    color: theme.palette.white.main,
    backgroundColor: theme.palette.grey.dark,
    '&:hover': {
      background: theme.palette.primary.main,
      color: theme.palette.white.main,
      fill: theme.palette.primary.main,
    },
  },
  gridButtonsAttributesOptions: {
    columnGap: 20,
    marginTop: '3%',
  },
  buttonOptionCategory: {
    marginRight: 5,
  },
  formInputWarehouses: {
    width: '100%',
    marginTop: 15,
  },
  labelSizeWarehouses: {
    fontSize: 15,
    marginBottom: 8,
    textAlign: 'left',
    marginLeft: 7,
  },
  widthAtttribute: {
    width: '25%',
  },
  loadSpacing: {
    marginBottom: 20,
  },
  tableCell: {
    background: '#dddddd',
    opacity: '20%',
  },
  divButtonsSkuRepeated: {
    marginTop: 7,
  },
  marginMessage: {
    marginBottom: 7,
  },
}))
