// apiConfig.js

import axios from 'axios'
import Cookies from 'js-cookie'

import { COOKIE_ACCESS_TOKEN_NAME } from 'constants/cookiesConsts'
import { routeBase } from 'constants/environments.constants'

const API_BASE_URL = routeBase[process.env.REACT_APP_ENVIRONMENT].sales || routeBase.default.sales

const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
})

export const updateSale = async ({ data, saleCode }) => {
  try {
    const response = await axiosInstance.put(`/Sale/UpdateSale?SaleCode=${saleCode}`, data, {
      headers: {
        Authorization: `Bearer ${Cookies.get(COOKIE_ACCESS_TOKEN_NAME)}`,
      },
    })
    return response.data
  } catch (error) {
    console.error('Error al actualizar la venta:', error)
    throw error
  }
}

export const getSalesApplied = async () => {
  try {
    const response = await axiosInstance.get('/Sale/GetSalesApplied', {
      headers: {
        Authorization: `Bearer ${Cookies.get(COOKIE_ACCESS_TOKEN_NAME)}`,
      },
    })
    return response.data
  } catch (error) {
    console.error('Error al obtener las ventas aplicadas:', error)
    throw error
  }
}

export const getSales = async (data) => {
  try {
    const response = await axiosInstance.post('/Sale/GetSales', data, {
      headers: {
        Authorization: `Bearer ${Cookies.get(COOKIE_ACCESS_TOKEN_NAME)}`,
      },
    })
    return response.data
  } catch (error) {
    console.error('Error al obtener las ventas:', error)
    throw error
  }
}

export const getSaleHistory = async ({ registerId }) => {
  try {
    const response = await axiosInstance.get(`/Sale/GetSaleHistory?registerId=${registerId}`, {
      headers: {
        Authorization: `Bearer ${Cookies.get(COOKIE_ACCESS_TOKEN_NAME)}`,
      },
    })
    return response.data
  } catch (error) {
    console.error('Error al obtener el historial de la venta:', error)
    throw error
  }
}

export const getSaleByCode = async ({ saleCode }) => {
  try {
    const response = await axiosInstance.get(`/Sale/GetSaleByCode?saleCode=${saleCode}`, {
      headers: {
        Authorization: `Bearer ${Cookies.get(COOKIE_ACCESS_TOKEN_NAME)}`,
      },
    })
    return response.data
  } catch (error) {
    console.error('Error al obtener la venta por código:', error)
    throw error
  }
}

export const getDiscountTypes = async () => {
  try {
    const response = await axiosInstance.get('/Sale/GetDiscountTypes', {
      headers: {
        Authorization: `Bearer ${Cookies.get(COOKIE_ACCESS_TOKEN_NAME)}`,
      },
    })
    return response.data
  } catch (error) {
    console.error('Error al obtener los tipos de descuento:', error)
    throw error
  }
}

export const createSale = async (data) => {
  try {
    const response = await axiosInstance.post('/Sale/CreateSale', data, {
      headers: {
        Authorization: `Bearer ${Cookies.get(COOKIE_ACCESS_TOKEN_NAME)}`,
      },
    })
    return response.data
  } catch (error) {
    console.error('Error al crear la venta:', error)
    throw error
  }
}

export const changeSaleStatus = async (data) => {
  try {
    const response = await axiosInstance.patch('/Sale/ChangeSaleStatus', data, {
      headers: {
        Authorization: `Bearer ${Cookies.get(COOKIE_ACCESS_TOKEN_NAME)}`,
      },
    })
    return response.data
  } catch (error) {
    console.error('Error al cambiar el estado de la venta:', error)
    throw error
  }
}

export const getRules = async () => {
  try {
    const response = await axiosInstance.get('/Rule/GetRules', {
      headers: {
        Authorization: `Bearer ${Cookies.get(COOKIE_ACCESS_TOKEN_NAME)}`,
      },
    })
    return response.data
  } catch (error) {
    console.error('Error al obtener las reglas:', error)
    throw error
  }
}

export const getRuleTypes = async () => {
  try {
    const response = await axiosInstance.get('/Rule/GetRuleTypes', {
      headers: {
        Authorization: `Bearer ${Cookies.get(COOKIE_ACCESS_TOKEN_NAME)}`,
      },
    })
    return response.data
  } catch (error) {
    console.error('Error al obtener los tipos de regla:', error)
    throw error
  }
}

export const getConditions = async () => {
  try {
    const response = await axiosInstance.get('/Rule/GetConditions', {
      headers: {
        Authorization: `Bearer ${Cookies.get(COOKIE_ACCESS_TOKEN_NAME)}`,
      },
    })
    return response.data
  } catch (error) {
    console.error('Error al obtener las condiciones:', error)
    throw error
  }
}

export const getConditionsTypes = async () => {
  try {
    const response = await axiosInstance.get('/Rule/GetConditionsTypes', {
      headers: {
        Authorization: `Bearer ${Cookies.get(COOKIE_ACCESS_TOKEN_NAME)}`,
      },
    })
    return response.data
  } catch (error) {
    console.error('Error al obtener los tipos de condiciones:', error)
    throw error
  }
}
