const dateFormatUtil = (unformattedDate) => {
  const newDate = new Date(unformattedDate)
  const year = newDate.getFullYear()
  const month = newDate.getMonth() + 1 < 10 ? `0${newDate.getMonth() + 1}` : newDate.getMonth() + 1
  const day = newDate.getDate() < 10 ? `0${newDate.getDate()}` : newDate.getDate()
  const hours = newDate.getHours() < 10 ? `0${newDate.getHours()}` : newDate.getHours()
  const minutes = newDate.getMinutes() < 10 ? `0${newDate.getMinutes()}` : newDate.getMinutes()
  const seconds = newDate.getSeconds() < 10 ? `0${newDate.getSeconds()}` : newDate.getSeconds()
  const milliseconds =
    newDate.getMilliseconds() < 10 ? `0${newDate.getMilliseconds()}` : newDate.getMilliseconds()
  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}`
}

export default dateFormatUtil
