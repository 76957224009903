import { makeStyles } from '@material-ui/core/styles'

export default makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  section1: {
    margin: theme.spacing(3, 2),
  },
  section2: {
    margin: theme.spacing(2),
  },
  section3: {
    margin: theme.spacing(3, 1, 1),
  },
  grey: {
    color: theme.palette.grey,
    backgroundColor: theme.palette.grey,
  },
  smallSuccess: {
    width: theme.spacing(1.5),
    height: theme.spacing(1.5),
    color: theme.palette.mintGreen,
    backgroundColor: theme.palette.mintGreen.main,
  },
  smallWarning: {
    width: theme.spacing(1.5),
    height: theme.spacing(1.5),
    color: theme.palette.dimGrey,
    backgroundColor: theme.palette.error.main,
  },
  smallGrey: {
    width: theme.spacing(1.5),
    height: theme.spacing(1.5),
    color: theme.palette.dimGrey,
    backgroundColor: theme.palette.dimGrey.main,
  },
  heightPanel: {
    height: '662px',
  },
  marginBox: {
    marginRight: 15,
  },
  labelIcon: {
    marginRight: theme.spacing(1),
  },
  labelText: {
    textAlign: 'left',
    flexGrow: 1,
  },
  labelTextCop: {
    textAlign: 'left',
    flexGrow: 1,
  },
  marginGrid: {
    marginTop: 34,
  },
  orderextra: {
    maxWidth: '100%',
  },
  responsedown: {
    [theme.breakpoints.up('md')]: {
      textAlign: 'right',
    },
  },
  letterSpacing: {
    fontSize: 16,
  },
  letterSpacingRoot: {
    fontSize: 16,
    fontWeight: 800,
  },
  alignToSectionPanel: {
    padding: '10px',
    alignContent: 'baseline',
  },
  contentPanelAndBalance: {
    padding: '23px',
  },
  filterPosition: {
    columnGap: 4,
    marginBottom: 21,
  },
  TopPosition: {
    padding: '8px',
    color: theme.palette.black.main,
    background: theme.palette.opacity.main,
  },
  totalPrice: {
    padding: '8px',
    color: theme.palette.white.main,
    background: theme.palette.black.main,
    height: 39,
    // marginTop: 17,
  },
  greyTextStatus: {
    color: theme.palette.dimGrey.main,
  },
  successTextStatus: {
    color: theme.palette.mintGreen.main,
  },
  warningTextStatus: {
    color: theme.palette.error.main,
  },
  widthPeriod: {
    width: '50%',
  },
  widthInitialBalance: {
    width: '100%',
  },
  widthCloseInitialBalance: {
    width: '70%',
  },
  widthPayment: {
    width: '70%',
    color: theme.palette.white.main,
  },
  InitialBalancePadding: {
    paddingLeft: '15px',
    marginBottom: 3,
  },
  widthValueInitialBalance: {
    width: '30%',
  },
  widthValuePyment: {
    width: '30%',
    color: theme.palette.white.main,
  },
  widthValueRigth: {
    width: '30%',
    marginBottom: 5,
    // marginBottom: 5,
  },
  divider: {
    marginBottom: 15,
    marginTop: 4,
  },
  inputTop: {
    marginTop: 12,
  },
  formGroupPriceStep: {
    marginLeft: 4,
  },
  btnBackgroundPrimary: {
    marginLeft: 5,
    minWidth: 180,
    maxWidth: 246,
    fill: theme.palette.white.main,

    '&:hover': {
      background: theme.palette.primary.main,
    },
  },
}))
