/* eslint-disable no-use-before-define */
/* eslint-disable no-unreachable */
/* eslint-disable array-callback-return */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useContext } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import {
  Grid,
  Backdrop,
  CircularProgress,
  Box,
  Stepper,
  withStyles,
  StepConnector,
  Step,
  StepLabel,
  Typography,
  Button,
} from '@material-ui/core'
import axios from 'axios'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'

import { checkEmail, getSellerForm, getCities } from '../../services/departamentAndCities/services'

import styles from './generalConfig.styles'
import LegalDocuments from './steps/LegalDocuments'
import PersonalData from './steps/PersonalData'

import DialogAlert from 'components/DialogAlert'
import useCustomDialogAlert from 'components/useCustomDialogAlert'
import { COMMERCIAL_ADVISOR } from 'constants/rolesConst'
import { routesLink } from 'constants/routesConsts'
import { API_SELLERS } from 'constants/urls'
import useAuthContext from 'contextApi/AuthContext'
import { registerSeller } from 'services/sellers/sellers.services'

const CircleConnector = withStyles({
  alternativeLabel: {
    top: 20,
    left: 'calc(-50% + 30px)',
    right: 'calc(50% + 30px)',
  },
  active: {
    '& $line': {
      borderColor: '#3ad399',
    },
  },
  completed: {
    '& $line': {
      borderColor: '#3ad399',
    },
  },
  line: {
    borderColor: '#eaeaf0',
    borderTopWidth: 4,
    borderRadius: 1,
  },
})(StepConnector)
function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      style={{ width: '100%' }}
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.arrayOf(PropTypes.object).isRequired,
  value: PropTypes.string.isRequired,
  index: PropTypes.string.isRequired,
}

const StepperDetail = ({ id, email, lockEmail }) => {
  const history = useHistory()
  const showDialogAlert = useCustomDialogAlert()
  const [activeStep, setActiveStep] = useState(0)
  const [valueTypePerson, setValueTypePerson] = useState(0)
  const [isAdmin, setIsAdmin] = useState(false)
  const classes = styles()
  const [tabs, setTabs] = useState([
    { value: 0, name: 'Informacion Básica' },
    { value: 1, name: 'Requisitos de la empresa' },
  ])
  const { userLoggedin } = useContext(useAuthContext)
  const [typePerson, setTypePerson] = useState([])
  const [data, setData] = useState({
    personalData: {
      typeOfPersonId: null,
      address: '',
      fisrtName: '',
      secondName: '',
      lastName: '',
      city: '',
      commercialName: '',
      department: '',
      identification: '',
      storeEmail: '',
      storeName: '',
      storePhone: '',
      identificationTypeId: 0,
      sellerTypeId: 0,
      departmentId: 0,
      cityId: 0,
      person: true,
    },
    dataCertificate: {
      nameRut: '',
      nameCommercialRegister: '',
      nameLegRepDocumentCopy: '',
      nameBankCertificate: '',
      urlRut: '',
      urlCommercialRegister: '',
      urlLegRepDocumentCopy: '',
      urlBankCertificate: '',
      fileRut: '',
      fileCommercialRegister: '',
      fileLegRepDocumentCopy: '',
      fileBankCertificate: '',
    },
    integrations: [],
  })

  const [errorsCertificates, setErrorsCertificates] = useState({
    fileRut: null,
    fileCommercialRegister: null,
    fileLegRepDocumentCopy: null,
    fileBankCertificate: null,
  })
  const [emailVerification, setEmailVerification] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [cites, setCites] = useState([])
  const [updateFrequency, setUpdateFrequency] = useState([])
  const [dialogAlert, setDialogAlert] = useState({
    open: false,
    title: '',
    message: '',
    actionConfirm: null,
    textButtonConfirm: '',
    textButtonCancel: '',
  })
  const [dialogAlertEmail, setDialogAlertEmail] = useState({
    open: false,
    title: '',
    message: '',
    actionConfirm: null,
    textButtonConfirm: '',
    textButtonCancel: '',
  })
  const isCommercialAdvisor = userLoggedin?.role === COMMERCIAL_ADVISOR
  const getSteps = () => {
    return ['Datos personales', 'Tus documentos legales']
  }
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }
  const getStoreInfo = async () => {
    if (userLoggedin?.role !== COMMERCIAL_ADVISOR || id) {
      setIsLoading(true)
      await axios
        .get(`${API_SELLERS}/${id}`)
        .then((response) => {
          const dataSeller = response.data.data
          setData({
            ...data,
            personalData: {
              ...data.personalData,
              isWeightGrams: dataSeller.isWeightGrams,
              typeOfPersonId: dataSeller.typeOfPersonId,
              address: dataSeller.address || '',
              fisrtName: dataSeller.fisrtName || '',
              secondName: dataSeller.secondName || '',
              lastName: dataSeller.lastName || '',
              city: '',
              commercialName: dataSeller.commercialName || '',
              department: '',
              identification: dataSeller.identification || '',
              storeEmail: dataSeller.storeEmail || '',
              storeName: dataSeller.storeName || '',
              storePhone: dataSeller.storePhone || '',
              identificationTypeId: dataSeller.identificationTypeId || 0,
              sellerTypeId: dataSeller.sellerTypeId || 0,
              departmentId: dataSeller.departmentId || 0,
              cityId: dataSeller.cityId || 0,
              person: true,
            },
            dataCertificate: {
              ...data.dataCertificate,
              urlRut: dataSeller.urlRut || '',
              urlCommercialRegister: dataSeller.urlCommercialRegister || '',
              urlLegRepDocumentCopy: dataSeller.urlLegRepDocumentCopy || '',
              urlBankCertificate: dataSeller.bankCertificate || '',
            },
            integrations: dataSeller.sellerIntegrationsResponse || [],
            status: dataSeller.statusId,
          })
          setIsLoading(false)
        })
        .catch(() => {
          setIsLoading(false)
        })
    }
  }
  const redirection = () => {
    history.push('/login')
  }
  useEffect(() => {
    if (!email) {
      setIsAdmin(true)
      getStoreInfo()
      getSellerForm(setIsLoading, setTypePerson, setUpdateFrequency)
      getCities(setCites, data.personalData.departmentId, setIsLoading)
    } else {
      const tabsArray = [...tabs]
      tabsArray.shift()
      setTabs(tabsArray)
      checkEmail(email, setEmailVerification, setDialogAlertEmail, redirection, setValueTypePerson)
      getSellerForm(setIsLoading, setTypePerson, setUpdateFrequency)
      setData({
        ...data,
        personalData: {
          ...data.personalData,
          storeEmail: email || '',
        },
      })
    }
  }, [])
  const recorridObjec = (valueHook, formData) => {
    Object.keys(valueHook).map(function (objectKey) {
      const value = valueHook[objectKey]
      if (value !== '') {
        formData.append(objectKey, value)
      }
    })
  }
  const recorridIntegrations = (valueHook, formData) => {
    valueHook.map(function (objectKey) {
      formData.append('Integrations', objectKey.id)
    })
  }
  const handleReset = () => {
    setActiveStep(0)
  }
  const onSubmitData = async (e) => {
    setIsLoading(true)
    const formData = new FormData()
    let urlData = ''
    if (!email) {
      formData.append('id', id)
      urlData = `${API_SELLERS}/Detail`
    } else {
      urlData = `${API_SELLERS}`
    }
    recorridObjec(data.personalData, formData)
    recorridIntegrations(data.integrations, formData)
    if (!data.integrations.some((integration) => integration.id === 1))
      formData.delete('isWeightGrams')

    formData.append('FileRut', data.dataCertificate.fileRut)
    formData.append('fileBankCertificate', data.dataCertificate.fileBankCertificate)
    formData.append('FileLegRepDocumentCopy', data.dataCertificate.fileLegRepDocumentCopy)
    formData.append('FileCommercialRegister', data.dataCertificate.fileCommercialRegister)
    if (!isCommercialAdvisor) {
      axios({
        url: urlData,
        method: email ? 'post' : 'put',
        data: formData,
        config: {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      })
        .then((response) => {
          if (response.status === 200) {
            showDialogAlert(
              true,
              '¡Cambio exitoso!',
              `La tienda se ha actualizado correctamente`,
              'Cerrar',
              '',
              true
            )
            if (id) {
              history.push('/admin/stores')
            }
            if (email) {
              history.push('/register/thanks')
            }
          }
          setIsLoading(false)
        })
        .catch((error) => {
          if (email)
            showDialogAlert(true, '¡Advertencia!', `El email ya se encuentra registrado`, 'Cerrar')
          setIsLoading(false)
          if (email) {
            setDialogAlert({
              open: true,
              title: '¡Error!',
              message: `Ha ocurrido un error. valide los datos`,
              actionConfirm: () => handleCloseDialogAlert(),
              textButtonConfirm: 'Aceptar',
              textButtonCancel: null,
            })
          }
        })
    } else {
      registerSeller(formData)
        .then((response) => {
          if (response.status === 200) {
            showDialogAlert(
              true,
              'Registro exitoso!',
              `La tienda se ha registrado correctamente`,
              'Cerrar',
              '',
              true
            )

            history.push(`${routesLink.stores}`)
          }
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const handleCloseDialogAlert = () => {
    setDialogAlert({ ...dialogAlert, open: false })
    setIsLoading(false)
  }
  const handleCloseDialogAlertEmail = () => {
    setDialogAlertEmail({ ...dialogAlertEmail, open: false })
    setIsLoading(false)
  }
  const getStepContent = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return (
          <PersonalData
            data={data}
            setData={setData}
            typePerson={typePerson}
            valueTypePerson={valueTypePerson}
            handleNext={handleNext}
            cites={cites}
            setCites={setCites}
            isAdmin={isAdmin}
            lockEmail={lockEmail}
          />
        )
      case 1:
        return (
          <LegalDocuments
            data={data}
            setData={setData}
            errorsCertificates={errorsCertificates}
            setErrorsCertificates={setErrorsCertificates}
            handleBack={handleBack}
            onSubmitData={onSubmitData}
            isAdmin={isAdmin}
            isCommercial={isCommercialAdvisor}
          />
        )

      default:
        return 'stepIndex desconocido'
    }
  }
  const steps = getSteps()
  return (
    <div>
      <Backdrop className={classes.backdrop} open={isLoading || emailVerification}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <DialogAlert
        open={dialogAlertEmail.open}
        title={dialogAlertEmail.title}
        handleClose={handleCloseDialogAlertEmail}
        message={dialogAlertEmail.message}
        textButtonConfirm={dialogAlertEmail.textButtonConfirm}
        actionConfirm={dialogAlertEmail.actionConfirm}
        textButtonCancel={dialogAlertEmail.textButtonCancel}
      />
      <DialogAlert
        open={dialogAlert.open}
        title={dialogAlert.title}
        handleClose={handleCloseDialogAlert}
        message={dialogAlert.message}
        textButtonConfirm={dialogAlert.textButtonConfirm}
        actionConfirm={dialogAlert.actionConfirm}
        textButtonCancel={dialogAlert.textButtonCancel}
      />

      <Grid
        className={classes.form}
        direction="Column"
        justify="space-between"
        alignItems="flex-start"
      >
        <Stepper
          activeStep={activeStep}
          alternativeLabel
          connector={<CircleConnector />}
          style={{ backgroundColor: 'transparent' }}
        >
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel
                classes={{
                  label: classes.labelStepLabel,
                  active: classes.activeStepLabel,
                  completed: classes.completedStepLabel,
                }}
                StepIconProps={{
                  classes: {
                    root: classes.rootStepIcon,
                    text: classes.text,
                    active: classes.active,
                    completed: classes.completed,
                  },
                }}
              >
                {label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
        <div>
          {activeStep === steps.length ? (
            <div>
              <Typography className={classes.instructions}>Todos los pasos completados</Typography>
              <Button onClick={handleReset}>Reiniciar</Button>
            </div>
          ) : (
            <div>{getStepContent(activeStep)}</div>
          )}
        </div>
      </Grid>
    </div>
  )
}
export default StepperDetail
