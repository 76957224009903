/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-one-expression-per-line */
import React, { useEffect, useState } from 'react'

import { Typography, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import axios from 'axios'

import CategorySelection from 'components/CategorySelection'
import Button from 'components/CustomButtons/Button'
import DialogModal from 'components/DialogModal'
import StyledRadio from 'components/StyledRadio'
import { API_CATEGORIES, API_CATEGORIZER } from 'constants/urls'

const useStyles = makeStyles({
  button: {
    width: '248px',
    height: '40px',
    borderRadius: '50px',
    border: 'none',
    margin: '20px 0',
    backgroundColor: '#6064FF',
    color: 'white',
    fontWeight: '500',
    '&:hover': {
      fontWeight: 'bolder',
      backgroundColor: '#ef0bef',
      boxShadow: '-7px 7px 0px #000000',
      border: '2px solid #000000',
      borderRadius: '50px',
    },
  },
  buttonOutlined: {
    width: '248px',
    height: '40px',
    margin: '20px 0',
    borderRadius: '50px',
    border: '1px solid #6064FF',
    color: '#6064FF',
    fontWeight: '500',
    transition: 'all 0.3s ease-in-out',
    '&:hover': {
      fontWeight: 'bolder',
      color: '#FF40FC',
      border: '1px solid #FF40FC',
      borderRadius: '50px',
    },
  },
})

const CategorySuggestions = ({ isOpen, setIsOpen, name, setIsLoading, onFinish }) => {
  const classes = useStyles()
  const [selectingCategory, setSelectingCategory] = useState(false)
  const [manualCategory, setManualCategory] = useState([])
  const [categorySuggestions, setCategorySuggestions] = useState([])
  const [categorySelected, setCategorySelected] = useState('')

  const getCategorySuggestions = (productName) => {
    setIsLoading(true)
    axios
      .post(`${API_CATEGORIZER}/predictKNN`, {
        text: productName,
      })
      .then(({ data }) => {
        setIsLoading(false)
        setIsOpen(true)
        setCategorySuggestions(data.prediction)
      })
  }

  const transformSuggestion = (suggestion) => {
    if (typeof suggestion === 'string') {
      setIsLoading(true)
      axios
        .post(`${API_CATEGORIES}/CategoriesByTreeName?separator=%3E`, [suggestion], {
          headers: {
            accept: 'text/plain',
            'Content-Type': 'application/json',
          },
        })
        .then(({ data }) => {
          setIsLoading(false)
          onFinish(data[0].categories)
          setSelectingCategory(false)
          setIsOpen(false)
        })
    } else {
      onFinish(suggestion)
      setSelectingCategory(false)
      setIsOpen(false)
    }
  }

  useEffect(() => {
    if (isOpen) {
      getCategorySuggestions(name)
    }
  }, [isOpen])

  return (
    <DialogModal
      isOpen={isOpen}
      handleClose={() => {
        setSelectingCategory(false)
        setIsOpen(false)
      }}
      title="Categorización"
    >
      <div
        style={{
          padding: '0 16px',
        }}
      >
        {selectingCategory ? (
          <CategorySelection
            setIsLoading={setIsLoading}
            onFinishSelection={(category) => {
              setSelectingCategory(false)
              setCategorySelected(category)
              setManualCategory(category)
            }}
          />
        ) : (
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <Typography variant="paragraph">
                Tu producto coincide con estas categorías, Elige la categoría que sea correcta o
                elige otra manualmente.
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="subtitle1">Producto:</Typography>
              <Typography variant="paragraph">“{name}”</Typography>
            </Grid>
            <Grid item>
              <Grid container direction="column" spacing={1}>
                {categorySuggestions.map((item) => (
                  <Grid item key={item}>
                    <StyledRadio
                      variant="category"
                      value={item}
                      label="Categoría recomendada"
                      description={item.replaceAll('>', ' > ')}
                      selected={categorySelected === item}
                      handleChange={(value) => {
                        setCategorySelected(value)
                      }}
                    />
                  </Grid>
                ))}
                {manualCategory.length > 0 && (
                  <Grid item>
                    <StyledRadio
                      variant="category"
                      value={manualCategory}
                      label="Categoría seleccionada manualmente"
                      description={manualCategory
                        .reduce((acc, item) => `${acc} > ${item.name}`, '')
                        .substring(3)}
                      selected={categorySelected === manualCategory}
                      handleChange={(value) => {
                        setCategorySelected(value)
                      }}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        )}
        <Grid
          container
          direction="row"
          justifyContent={!selectingCategory ? 'space-between' : 'flex-end'}
        >
          <Button
            className={classes.buttonOutlined}
            onClick={() => {
              setSelectingCategory(!selectingCategory)
            }}
          >
            {selectingCategory ? 'Cancelar' : 'Elegir otra categoría'}
          </Button>
          {!selectingCategory && (
            <Button
              className={classes.button}
              disabled={categorySelected.length <= 0}
              onClick={() => {
                transformSuggestion(categorySelected)
              }}
            >
              Continuar
            </Button>
          )}
        </Grid>
      </div>
    </DialogModal>
  )
}

export default CategorySuggestions
