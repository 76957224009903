/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'

import { Grid, Typography, Breadcrumbs, IconButton, Link } from '@material-ui/core'
import NavigateNextIcon from '@material-ui/icons/ChevronRight'

import Style from './validateCategorieVariable.style'

import { DeleteIcon } from 'components/icons/personalizedIcons'
import { useProduct } from 'contextApi/ProductContext'

const CategoriesSelected = ({ renderBackToList }) => {
  const classes = Style()
  const { product, setProduct } = useProduct()
  const deleteSelectedCategorie = (indexRoot) => {
    const tempCategories = { ...product }
    tempCategories.categories.splice(indexRoot, 1)
    setProduct(tempCategories)
    if (product.categories.length === 0) renderBackToList()
  }

  return (
    <>
      <Typography variant="subtitle1" className={classes.alignHeaderListTitleTemplate}>
        {`Categorías seleccionadas ${product?.categories?.length}`}
      </Typography>
      <Grid container className={classes.modalCategoriesImport}>
        {product.categories.map((categorie, indexRoot) => {
          return (
            <>
              <Breadcrumbs
                maxItems={4}
                className={classes.positionBreadCrumb}
                separator={<NavigateNextIcon fontSize="small" />}
                aria-label="breadcrumb"
              >
                <Link color="initial" component="typography" underline="none" variant="body2">
                  Categorías
                </Link>
                {categorie.map((item, index) => {
                  return (
                    <>
                      <Link
                        variant="body2"
                        component={categorie.length - 1 === index ? 'typography' : 'typography'}
                        color="initial"
                        underline={categorie.length - 1 === index ? 'none' : 'none'}
                      >
                        {item.name}
                      </Link>
                      {index === categorie.length - 1 ? (
                        <IconButton
                          className={classes.deleteIcon}
                          onClick={(e) => {
                            e.preventDefault()
                            deleteSelectedCategorie(indexRoot)
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      ) : null}
                    </>
                  )
                })}
              </Breadcrumbs>
            </>
          )
        })}
      </Grid>
    </>
  )
}

export default CategoriesSelected
