/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'

import { Button, Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'

import styles from './error500Styles'

import { LogoSellerCenter, Error500Image } from 'components/icons/personalizedIcons'
import { routesLink } from 'constants/routesConsts'

const useStyles = makeStyles(styles)

export default function Error500() {
  const classes = useStyles()
  const history = useHistory()

  const handleClick = () => {
    history.push(routesLink.products)
  }

  return (
    <Grid className={classes.container}>
      <LogoSellerCenter />

      <Error500Image />

      <Typography variant="subtitle1">Oops, tenemos un problema</Typography>
      <Typography className={classes.text} variant="body2">
        Estamos trabajando para solucionarlo
      </Typography>

      <Button
        className={classes.btnBackgroundPrimary}
        color="secondary"
        variant="contained"
        onClick={handleClick}
      >
        Regresar a pedidos
      </Button>
    </Grid>
  )
}
