import React, { useEffect, useState } from 'react'

import { Button, Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import axios from 'axios'
import { useHistory, useParams } from 'react-router-dom'

import styles from '../../../components/AssociationSeller/styles'
import useStyles from '../products.styles'

import BackDrop from 'components/BackDrop'
import { routesLink } from 'constants/routesConsts'
import { API_BASE } from 'constants/urls'
import './styles.css'

const useStylesMaterial = makeStyles(styles)
const PendingApproval = () => {
  const classes = useStyles()
  const classesMaterial = useStylesMaterial()
  const history = useHistory()
  const { idSeller } = useParams()
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState([])
  const returnApprovals = () => {
    history.push(`${routesLink.approvals}`)
  }
  const getData = async () => {
    let url = ''
    url = `${API_BASE}/Store/pendingApprovals?page=1&pageSize=10&SellerId=${idSeller}`
    await axios
      .get(url)
      .then((response) => {
        setIsLoading(false)
        if (response.status === 200) {
          setData(response.data.data[0])
        }
      })
      .catch((e) => {
        setIsLoading(false)
        console.log(e)
      })
  }

  const Approvals = async () => {
    await axios
      .post(`${API_BASE}/Store/approvePendingChanges`, [idSeller])
      .then((response) => {
        setIsLoading(false)
        returnApprovals()
        console.log('response', response)
      })
      .catch((e) => {
        setIsLoading(false)
        console.log(e)
      })
  }

  const RejectApproval = async () => {
    await axios
      .post(`${API_BASE}/Store/rejectPendingChanges`, [idSeller])
      .then((response) => {
        setIsLoading(false)
        returnApprovals()
      })
      .catch((e) => {
        setIsLoading(false)
        console.log(e)
      })
  }
  useEffect(() => {
    getData()
  }, [])

  return (
    <>
      <Grid container justify="space-between">
        <Grid>
          <Typography variant="h6">Datos de la tienda</Typography>
          <p className="parrafo">
            Nombre:
            <span className="spannn">{data.storeName}</span>
          </p>
          <p className="parrafo">
            Correo:
            <span className="spannn">{data.email}</span>
          </p>
          <hr />
        </Grid>
        <Grid className={classes.divButtonsHeader}>
          <Button
            onClick={returnApprovals}
            className={classes.btnNewProduct}
            variant="contained"
            color="secondary"
          >
            Regresar
          </Button>
        </Grid>
      </Grid>
      <Grid container direction="column" className={classes.divContent}>
        <Typography variant="h6">Detalles: </Typography>
        <h6 className="title">Términos y Condiciones: </h6>
        <h6 className="title">Actuales:</h6>
        <p className="parrafo">{data.oldTermsAndConditions}</p>
        <h6 className="title">Nuevos:</h6>
        <p className="parrafo">{data.termsAndConditions}</p>
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          className={classesMaterial.divButtons}
        >
          <Grid item>
            <Button
              className={classesMaterial.btnBackgroundError}
              onClick={() => RejectApproval()}
              disabled={isLoading}
            >
              Rechazar
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="secondary"
              className={classesMaterial.btnBackgroundPrimary}
              onClick={() => Approvals()}
              disabled={isLoading}
            >
              Aprobar
            </Button>
          </Grid>
        </Grid>
        <BackDrop open={isLoading} />
      </Grid>
    </>
  )
}

export default PendingApproval
