/* eslint-disable camelcase */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from 'react'

import { Loader } from '@googlemaps/js-api-loader'

import InputField from 'components/GooglePlaces/InputField'
import { routeBase } from 'constants/environments.constants'

const loader = new Loader({
  apiKey: routeBase[process.env.REACT_APP_ENVIRONMENT].googleApiKey,
  libraries: ['places'],
})

const GooglePlaces = React.forwardRef(({ label, handleSelect, ...rest }, ref) => {
  const divRef = useRef(null)
  let autocomplete

  function fillInAddress() {
    const place = autocomplete.getPlace()
    const lat = place.geometry.location.lat()
    const lng = place.geometry.location.lng()
    const address = place.formatted_address
    handleSelect(address.split(',')[0], lat, lng)
  }

  function initAutocomplete() {
    const address = document.getElementById(rest.id)
    // eslint-disable-next-line no-undef
    autocomplete = new google.maps.places.Autocomplete(address, {
      componentRestrictions: {
        country: 'co',
      },
    })
    autocomplete.addListener('place_changed', fillInAddress)
  }

  useEffect(() => {
    loader.load().then(() => {
      initAutocomplete()
    })
  }, [])
  return (
    <div ref={divRef} style={{ display: 'flex', flexDirection: 'column' }}>
      <InputField
        label={label}
        type="text"
        inputRef={ref}
        placeholder="Address"
        autoComplete="off"
        {...rest}
      />
    </div>
  )
})

export default GooglePlaces
