import React, { useEffect } from 'react'

const ConfigNotificationsForm = () => {
  useEffect(() => {
    const NF = document.createElement('notification-configurator')
    document.getElementById('web-component-nc').appendChild(NF)
  }, [])

  return <div id="web-component-nc" />
}

export default ConfigNotificationsForm
