import { green, red } from '@material-ui/core/colors'

import { cardTitle, whiteColor, grayColor } from 'assets/jss/material-dashboard-pro-react'

const loginPageStyle = (theme) => ({
  container: {
    zIndex: '4',
    [theme.breakpoints.down('sm')]: {
      paddingBottom: '100px',
    },
    backgroundColor: theme.palette.white.main,
    width: '100%',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
  },
  containerLeft: {
    zIndex: '4',
    [theme.breakpoints.down('sm')]: {
      paddingBottom: '100px',
    },
    overflow: 'visible',
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'center',
    aligItems: 'flex-end',
    maxHeight: '100vh',
  },
  containerLogin: {
    maxHeight: '100vh',
  },
  form: {
    margin: '20% 0 20% 0',
    maxWidth: 450,
  },
  logo: {
    width: '100%',
  },
  cardTitle: {
    ...cardTitle,
    color: whiteColor,
  },
  textCenter: {
    textAlign: 'center',
    marginBottom: 10,
  },

  justifyContentCenter: {
    width: '100%',
  },
  bodycard: {
    width: '100%',
  },
  customButtonClass: {
    '&,&:focus,&:hover': {
      color: red,
    },
    marginLeft: '5px',
    marginRight: '5px',
  },

  inputAdornment: {
    marginRight: '18px',
  },
  inputAdornmentIcon: {
    color: grayColor[6],
  },
  cardHidden: {
    opacity: '0',
    transform: 'translate3d(0, -60px, 0)',
  },
  cardHeader: {
    marginBottom: '20px',
  },
  socialLine: {
    padding: '0.9375rem 0',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'relative',
    marginTop: -40,
    marginLeft: '50%',
  },
  recoverPassword: {
    color: grayColor[1],
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  btnAccept: {
    width: '100%',
    marginTop: '3%',
    marginBottom: 15,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  input: {
    width: '100%',
    marginTop: 0,
    marginBottom: '2%',

    '&:input': {
      padding: 5,
    },
  },
  typographyDescription: {
    marginTop: '50%',
  },
  labelInput: {
    marginTop: 15,
    marginBottom: 4,
    textAlign: 'left',
  },
  divider: {
    margin: '5% 0 5% 0',
  },
  formVerifyCode: {
    padding: '20px 30px 10px 30px',
    minHeight: 185,
    textAlign: 'left',
  },
  btnBackgroundPrimary: {
    minWidth: '150px',
    height: '50px',
    padding: '8px, 20px, 10px, 20px',
    borderRadius: '50px',
    border: 'none',
    margin: '20px 0',
    backgroundColor: '#5e44ff',
    color: 'white',
    fontWeight: '500',
    '&:hover': {
      fontWeight: 'bolder',
      backgroundColor: '#ef0bef',
      boxShadow: '-7px 7px 0px #000000',
      border: '2px solid #000000',
      borderRadius: '50px',
      opacity: '1',
    },
  },
  btnBackgroundError: {
    minWidth: '150px',
    height: '50px',
    borderRadius: '50px',
    border: '1px solid #6064ff',
    color: '#6064ff',
    fontWeight: '500',
    backgroundColor: '#ffffff',
    '&:hover': {
      color: '#FF40FC',
      border: '1px solid #FF40FC',
      backgroundColor: '#ffffff',
    },
  },
  divButtons: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 15,
    columnGap: 15,
  },
  caption: {
    color: theme.palette.error.main,
  },
  containerConfirm: {
    width: '100%',
    padding: 20,
    display: 'flex',
    flexDirection: 'column',
  },
  textConfirmPassword: {
    marginTop: 15,
    textAlign: 'center',
  },
})

export default loginPageStyle
