/* eslint-disable react/prop-types */
import React from 'react'

import { Button, Grid, makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  divTabs: {
    columnGap: 5,
  },
  tab: {
    borderRadius: '8px 8px 0 0',
    backgroundColor: theme.palette.grey.light,
    color: theme.palette.grey.dark,
    padding: '13px 28px',
    fontSize: 16,
    height: 48,
    marginTop: 10,
  },
  tabActive: {
    borderRadius: '8px 8px 0 0',
    backgroundColor: theme.palette.secondary.pink,
    color: theme.palette.white.main,
    padding: '13px 28px',
    fontSize: 16,
    height: 48,
    marginTop: 10,

    '&:hover': {
      backgroundColor: theme.palette.secondary.pink,
    },
  },
}))

export default function Tabs({ value, tabs, onChange }) {
  const classes = useStyles()

  return (
    <Grid container className={classes.divTabs}>
      {tabs.map((tab) => (
        <Button
          className={tab.value === value ? classes.tabActive : classes.tab}
          onClick={() => onChange(tab.value)}
        >
          {tab.name}
        </Button>
      ))}
    </Grid>
  )
}
