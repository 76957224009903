import React, { useState, useContext } from 'react'

import { Button, InputLabel, FormGroup, Grid, TextareaAutosize } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Axios from 'axios'
import { useForm, Controller } from 'react-hook-form'

import styles from './styles'

import BackDrop from 'components/BackDrop'
import ImageUpload from 'components/CustomUpload/ImageUpload'
import useCustomDialogAlert from 'components/useCustomDialogAlert'
import { API_STORE_INFO } from 'constants/urls'
import useAuthContext from 'contextApi/AuthContext'

const useStyles = makeStyles(styles)

export default function AssociationSeller({ cancelAssociationModal }) {
  const classes = useStyles()
  const showDialogAlert = useCustomDialogAlert()
  const { userLoggedin, setLoggedin } = useContext(useAuthContext)
  const { handleSubmit, errors, control } = useForm({
    mode: 'onChange',
  })
  const [file, setFile] = React.useState(null)
  const [src, setSrc] = useState(userLoggedin.picture || '')
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState({
    description: '',
  })


  const getImage = async () => {
    try {
      const response = await Axios({
        url: `${API_STORE_INFO}/${userLoggedin.sellerId}`,
        method: 'Get',
      })
      if (response) {
        setLoading(false)
        const dataUser = userLoggedin
        dataUser.picture = response.data.storeLogo
        setLoggedin(dataUser)
      }
    } catch (error) {
      setLoading(false)
      console.error(error)
    }
  }

  const onSubmit = async (event) => {
    event.preventDefault()

    if (data.description.trim() === '') {
      showDialogAlert(true, 'Alerta', `Debe ingresar la descripcion de la tienda. `, 'Cerrar', '')
    } else if (!file) {
      showDialogAlert(true, 'Alerta', `Debe ingresar la imagen de la tienda. `, 'Cerrar', '')
    } else {
      const formData = new FormData()

      formData.append('sellerId', userLoggedin.sellerId)
      if (file) {
        formData.append('fileStoreLogo', file)
      }
      formData.append('StoreDescription', data.description)
      try {
        setLoading(true)
        const response = await Axios({
          url: `${API_STORE_INFO}`,
          method: 'put',
          data: formData,
          config: {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          },
        })
        if (response) {
          if (response.status === 200) {
            if (response.data) {
              getImage()
              showDialogAlert(
                true,
                '¡Cambio exitoso!',
                `Se ha guardado correctamente la información`,
                'Cerrar',
                '',
                true,
                cancelAssociationModal
              )
            }
          }
        }
      } catch (error) {
        setLoading(false)
        console.error(error)
      }
    }
  }

  return (
    <>
      <BackDrop open={loading} />

      <form onSubmit={handleSubmit(onSubmit)}>
        <ImageUpload avatar loadImage={src} setFilesComponent={setFile} />
        <br />
        <FormGroup>
          <InputLabel className={classes.topInputLabel} error={errors.fisrtName}>
            Descripcion de la tienda
          </InputLabel>

          <Controller
            control={control}
            name="description"
            // rules={{ maxLength: 250, max: 250 }}
            render={(props) => (
              <TextareaAutosize
                id="description"
                rowsMax={4}
                maxLength={250}
                className={classes.description}
                onChange={(e) =>
                  props.onChange(
                    setData({
                      ...data,
                      description: e.target.value,
                    })
                  )
                }
                value={data?.description || ''}
              />
            )}
          />
          <span className={classes.spanError}>Máximo 250 caracteres</span>
        </FormGroup>

        <Grid container alignItems="center" justifyContent="center" className={classes.divButtons}>
          <Grid item>
            <Button
              className={classes.btnBackgroundError}
              onClick={() => cancelAssociationModal()}
              disabled={loading}
            >
              Cancelar
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="secondary"
              className={classes.btnBackgroundPrimary}
              // type="submit"
              onClick={onSubmit}
              disabled={loading}
            >
              Guardar
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  )
}
